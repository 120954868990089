/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:c7ac63a9-8b0f-4aa6-bc6d-682b16c32e1f",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_6PvMP9dK7",
    "aws_user_pools_web_client_id": "38pn11has8tke1tclo4m067d5i",
    "oauth": {}
};


export default awsmobile;
