import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppInfoService {
    constructor() {}

    public get title() {
        return environment.appName;
    }
}
