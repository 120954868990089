/* eslint-disable @typescript-eslint/naming-convention */
import moment from 'moment';
import XmlWriter from 'xml-writer';
import {
    get as _get,
    each as _each,
    map as _map,
    chunk as _chunk,
    flatten as _flatten,
    isEmpty as _isEmpty,
    toString as _toString,
} from 'lodash';

import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';

import { ProductStatus } from './enums/product-status';
import { environment } from 'src/environments/environment';
import { BaseRequester } from '../common/base-requester';
import { OrderStatus } from './enums/order-status';
import { LazadaDelegateRequest } from './interfaces/lazada-delegate-request';
import { DocType } from './enums/doc-type';

@Injectable({
    providedIn: 'root',
})
export class LazadaOpenService extends BaseRequester {
    private delegateEndpoint = environment.lazadaDelegateEndPoint;

    constructor() {
        super();
    }

    getOrderByStatus(orderStatus: OrderStatus): Observable<unknown> {
        const request: LazadaDelegateRequest = {
            servicePath: '/orders/get',
            parameter: {
                status: orderStatus,
                created_after: moment().subtract(3, 'months').format(),
            },
        };
        return this.requestLazadaDelegate(request);
    }

    getOrderItems(orderIds: number[]): Observable<any[]> {
        if (_isEmpty(orderIds)) {
            return of([]);
        }
        const orderIdChunks: number[][] = _chunk(orderIds, 50);
        const requestObservers: Observable<any>[] = _map(
            orderIdChunks,
            (_orderIds: number[]) => {
                return this.requestLazadaDelegate({
                    servicePath: '/orders/items/get',
                    parameter: {
                        order_ids: `[${_orderIds.toString()}]`,
                    },
                });
            }
        );
        return forkJoin(requestObservers).pipe(
            map((responses: any[]) => {
                return _flatten(responses);
            }, take(1))
        );
    }

    setInvoiceNumber(orderItemId: number): Observable<unknown> {
        const request: LazadaDelegateRequest = {
            method: 'post',
            servicePath: '/order/invoice_number/set',
            parameter: {
                order_item_id: orderItemId,
                invoice_number: `A${orderItemId}`,
            },
        };
        return this.requestLazadaDelegate(request);
    }

    getShipmentProviders(): Observable<unknown> {
        const request: LazadaDelegateRequest = {
            servicePath: '/shipment/providers/get',
            parameter: {},
        };
        return this.requestLazadaDelegate(request);
    }

    setStatusToPackedByMarketplace(
        orderItemIds: number[]
    ): Observable<unknown> {
        const request: LazadaDelegateRequest = {
            method: 'post',
            servicePath: '/order/pack',
            parameter: {
                order_item_ids: `[${orderItemIds.toString()}]`,
                shipping_provider:
                    'Pickup: Flash Express, Delivery: Flash Express',
                delivery_type: 'dropship',
            },
        };
        return this.requestLazadaDelegate(request);
    }

    setStatusToReadyToShip(
        orderItemIds: number[],
        shipmentProvider: string,
        trackingCode: string
    ): Observable<unknown> {
        const request: LazadaDelegateRequest = {
            method: 'post',
            servicePath: '/order/rts',
            parameter: {
                order_item_ids: `[${orderItemIds.toString()}]`,
                delivery_type: 'dropship',
                shipment_provider: shipmentProvider,
                tracking_number: trackingCode,
            },
        };
        return this.requestLazadaDelegate(request);
    }

    getDocument(docType: DocType, orderItemIds: number[]): Observable<unknown> {
        const request: LazadaDelegateRequest = {
            method: 'get',
            servicePath: '/order/document/get',
            parameter: {
                doc_type: docType,
                order_item_ids: `[${orderItemIds.toString()}]`,
            },
        };
        return this.requestLazadaDelegate(request);
    }

    getProducts(productStatus: ProductStatus): Observable<unknown> {
        const productLimit = 50;
        return this.getProductCountByStatus(productStatus).pipe(
            switchMap((productCount: number) => {
                const numRequest: number = Math.ceil(productCount / 50);
                const observableList: Observable<any>[] = [];
                for (let i = 0; i < numRequest; i++) {
                    observableList[i] = this.internalGetProducts(
                        productStatus,
                        productLimit,
                        productLimit * i
                    );
                }
                return forkJoin(observableList);
            }),
            map((responses: any[]) => {
                let allProducts: any[] = [];
                _each(responses, (response) => {
                    const products: any[] = _get(response, 'products');
                    allProducts = allProducts.concat(products);
                });
                return allProducts;
            })
        );
    }

    getProductItem(itemId: number): Observable<unknown> {
        const request: LazadaDelegateRequest = {
            method: 'get',
            servicePath: '/product/item/get',
            parameter: {
                item_id: itemId,
            },
        };
        return this.requestLazadaDelegate(request);
    }

    updateProductVideo(
        itemId: number,
        videoUrl: string,
        firstSellerSku: string
    ): Observable<unknown> {
        const xw = new XmlWriter();
        xw.startDocument()
            .startElement('Request')
            .startElement('Product')
            .writeElement('ItemId', _toString(itemId))
            .startElement('Attributes')
            .writeElement('video', videoUrl)
            .endElement()
            .startElement('Skus')
            .startElement('Sku')
            .writeElement('SellerSku', firstSellerSku)
            .endElement()
            .endElement();
        return this.updateProduct(xw.toString());
    }

    updateProduct(xml: string): Observable<unknown> {
        const request: LazadaDelegateRequest = {
            method: 'post',
            servicePath: '/product/update',
            parameter: {
                payload: xml,
            },
        };
        return this.requestLazadaDelegate(request);
    }

    private internalGetProducts(
        productStatus: ProductStatus,
        productLimit: number,
        offset: number
    ): Observable<unknown> {
        const request: LazadaDelegateRequest = {
            method: 'get',
            servicePath: '/products/get',
            parameter: {
                filter: productStatus,
                limit: productLimit,
                offset,
            },
        };
        return this.requestLazadaDelegate(request);
    }

    private getProductCountByStatus(
        productStatus: ProductStatus
    ): Observable<number> {
        const request: LazadaDelegateRequest = {
            method: 'get',
            servicePath: '/products/get',
            parameter: {
                filter: productStatus,
                limit: 1,
            },
        };
        return this.requestLazadaDelegate(request).pipe(
            map((response) => {
                const totalProduct: number = _get(response, 'total_products');
                return totalProduct;
            })
        );
    }

    private requestLazadaDelegate(
        request: LazadaDelegateRequest
    ): Observable<unknown> {
        return this.internalPostRequest<LazadaDelegateRequest>(
            this.delegateEndpoint,
            request
        ).pipe(
            map((ajaxRes: AjaxResponse) => {
                const responseCodeString: string = _toString(
                    _get(ajaxRes, 'response.code')
                );
                if (responseCodeString === '0') {
                    return _get(ajaxRes, 'response.data');
                } else {
                    const detail = _get(ajaxRes, 'response.detail');
                    if (detail) {
                        console.error(JSON.stringify(detail));
                    }
                    throw new Error(
                        `[${_get(ajaxRes, 'response.code')}] ${_get(
                            ajaxRes,
                            'response.message'
                        )}`
                    );
                }
            }, take(1))
        );
    }
}
