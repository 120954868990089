import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
    CommonModule,
    HashLocationStrategy,
    LocationStrategy,
} from '@angular/common';

import { AppComponent } from './app.component';
import { SideNavOuterToolbarModule, SingleCardModule } from './layouts';
import {
    FooterModule,
    LoginFormModule,
    ProductDetailPopupComponent,
} from './shared/components';
import {
    AuthService,
    ScreenService,
    AppInfoService,
    UnifiedQueryService,
    UnifiedQueryV2Service,
    UnifiedExecService,
    SaleAnalyticService,
    PriceListService,
    SaleService,
    ShopeeOpenServiceV2,
    LastPrintTimeService,
    LexDocService,
    SsmService,
    ThAddressSuggestorService,
    GoogleApiService,
    AbcAnalysisService,
} from './shared/services';
import { DistChartService } from './pages/product-performance/services/distribution-chart.service';
import {
    ShopeeFormatDateTimePipe,
    LazadaFormatDateTimePipe,
    FormatNumberPipe,
    FormatRelativeDatetimePipe,
    FormatRelativeDatetimePipeModule,
    FormatNumberPipeModule,
} from './shared/pipes';
import { ShopeeOrdersComponent } from './pages/orders/shopee-orders';
import { ShopeeDeliveryPopupComponent } from './pages/orders/shopee-orders/shopee-delivery-popup/shopee-delivery-popup.component';
import {
    LazadaOrdersComponent,
    LazadaOrderDetailTemplateComponent,
} from './pages/orders/lazada-orders';
import { QuickOrdersComponent } from './pages/orders/quick-orders/quick-orders.component';
import {
    DxDataGridModule,
    DxFormModule,
    DxToolbarModule,
    DxButtonGroupModule,
    DxTemplateModule,
    DxPopupModule,
    DxBoxModule,
    DxGalleryModule,
    DxButtonModule,
    DxLoadPanelModule,
    DxTabPanelModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxTabsModule,
    DxCheckBoxModule,
    DxFilterBuilderModule,
    DxSwitchModule,
    DxTextAreaModule,
    DxDateBoxModule,
    DxAutocompleteModule,
    DxNumberBoxModule,
    DxPieChartModule,
    DxChartModule,
    DevExtremeModule,
} from 'devextreme-angular';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { SecurePageComponent } from './pages/secure-page/secure-page.component';
import { InventoryListComponent } from './pages/inventory-list/inventory-list.component';
import { SalePlatformComponent } from './pages/sale/sale-platform/sale-platform.component';
import { SaleTimeComponent } from './pages/sale/sale-time/sale-time.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DisplayDataComponent } from './pages/display-data/display-data.component';
import { ApiAuthSettingsComponent } from './pages/settings/api-auth-settings/api-auth-settings.component';
import { LazVdoAdderComponent } from './pages/laz-vdo-adder/laz-vdo-adder.component';
import { QuoteGeneratorComponent } from './pages/quote-generator/quote-generator.component';
import { ProductPerformanceComponent } from './pages/product-performance/product-performance.component';
import { ServiceMonComponent } from './pages/service-mon/service-mon.component';
import { ReorderingComponent } from './pages/reordering/reordering.component';
import { CounterpartyModule } from './pages/counterparty/counterparty.module';
import { OutputTaxModule } from './pages/tax-invoice/output-tax.module';
import { EmbededComponent } from './pages/embeded/embeded.component';
import { ReceiptInfoFillerComponent } from './pages/embeded/pages/receipt-info-filler/receipt-info-filler.component';

Amplify.configure(awsconfig);

@NgModule({
    declarations: [
        LazadaOrderDetailTemplateComponent,
        AppComponent,
        LoginPageComponent,
        SecurePageComponent,
        EmbededComponent,
        HomeComponent,
        ProfileComponent,
        DisplayDataComponent,
        InventoryListComponent,
        SaleTimeComponent,
        SalePlatformComponent,
        ProductDetailPopupComponent,
        LazadaOrdersComponent,
        ShopeeOrdersComponent,
        QuickOrdersComponent,
        ShopeeDeliveryPopupComponent,
        ApiAuthSettingsComponent,
        LazVdoAdderComponent,
        QuoteGeneratorComponent,
        ShopeeFormatDateTimePipe,
        LazadaFormatDateTimePipe,
        ProductPerformanceComponent,
        ServiceMonComponent,
        ReorderingComponent,
        ReceiptInfoFillerComponent,
    ],
    imports: [
        DevExtremeModule,
        BrowserModule,
        SideNavOuterToolbarModule,
        SingleCardModule,
        FooterModule,
        LoginFormModule,
        AppRoutingModule,
        CommonModule,
        DxDataGridModule,
        DxFormModule,
        DxToolbarModule,
        DxButtonGroupModule,
        DxTemplateModule,
        DxPopupModule,
        DxBoxModule,
        DxGalleryModule,
        DxButtonModule,
        DxLoadPanelModule,
        DxTabPanelModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        DxTabsModule,
        DxCheckBoxModule,
        DxFilterBuilderModule,
        DxSwitchModule,
        DxTextAreaModule,
        DxDateBoxModule,
        DxAutocompleteModule,
        DxNumberBoxModule,
        DxPieChartModule,
        DxChartModule,
        CounterpartyModule,
        OutputTaxModule,
        FormatRelativeDatetimePipeModule,
        FormatNumberPipeModule,
    ],
    providers: [
        AuthService,
        ScreenService,
        AppInfoService,
        UnifiedQueryService,
        UnifiedQueryV2Service,
        UnifiedExecService,
        SaleAnalyticService,
        AbcAnalysisService,
        PriceListService,
        SaleService,
        ShopeeOpenServiceV2,
        LexDocService,
        LastPrintTimeService,
        SsmService,
        ThAddressSuggestorService,
        DistChartService,
        GoogleApiService,
        ShopeeFormatDateTimePipe,
        LazadaFormatDateTimePipe,
        FormatRelativeDatetimePipe,
        FormatNumberPipe,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
