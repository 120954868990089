import { Component, OnInit } from '@angular/core';
import 'devextreme/data/odata/store';
@Component({
    selector: 'app-sale-platform',
    templateUrl: './sale-platform.component.html',
    styleUrls: ['./sale-platform.component.scss'],
})
export class SalePlatformComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
