import {
    Component,
    NgModule,
    Output,
    Input,
    EventEmitter,
    ViewChild,
    ElementRef,
    AfterViewInit,
    OnDestroy,
} from '@angular/core';
import {
    DxTreeViewModule,
    DxTreeViewComponent,
    DxTreeViewTypes,
} from 'devextreme-angular/ui/tree-view';
import { navigation } from '../../../app-navigation';
import { trimStart as _trimStart } from 'lodash';
import * as events from 'devextreme/events';
import { Subscription, interval } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-side-navigation-menu',
    templateUrl: './side-navigation-menu.component.html',
    styleUrls: ['./side-navigation-menu.component.scss'],
})
export class SideNavigationMenuComponent implements AfterViewInit, OnDestroy {
    @ViewChild(DxTreeViewComponent, { static: true })
    menu!: DxTreeViewComponent;

    @Output()
    selectedItemChanged = new EventEmitter<DxTreeViewTypes.ItemClickEvent>();

    @Output()
    openMenu = new EventEmitter<any>();

    private _selectedItem!: string;
    @Input()
    set selectedItem(value: string) {
        this._selectedItem = value;
        if (!this.menu.instance) {
            return;
        }
    }

    private _items!: Record<string, unknown>[];
    get items() {
        if (!this._items) {
            this._items = navigation.map((item) => {
                if (item.path && !/^\//.test(item.path)) {
                    item.path = `/${item.path}`;
                }
                return { ...item, expanded: !this._compactMode };
            });
        }

        return this._items;
    }

    private _compactMode = false;
    @Input()
    get compactMode() {
        return this._compactMode;
    }
    set compactMode(val) {
        this._compactMode = val;

        if (!this.menu.instance) {
            return;
        }

        if (val) {
            this.menu.instance.collapseAll();
        } else {
            this.menu.instance.expandItem(this._selectedItem);
        }
    }

    private intervalSubscriber: Subscription;

    constructor(private elementRef: ElementRef, private router: Router) {}

    onItemClick(event: DxTreeViewTypes.ItemClickEvent) {
        this.subscribeHighlightMenu();
        this.selectedItemChanged.emit(event);
    }

    ngAfterViewInit() {
        events.on(this.elementRef.nativeElement, 'dxclick', (e: Event) => {
            this.openMenu.next(e);
        });
        this.subscribeHighlightMenu();
    }

    ngOnDestroy() {
        events.off(this.elementRef.nativeElement, 'dxclick');
        this.intervalSubscriber.unsubscribe();
    }

    private subscribeHighlightMenu() {
        if (this.intervalSubscriber) {
            this.intervalSubscriber.unsubscribe();
        }
        this.intervalSubscriber = interval(50).subscribe(() => {
            if (this.menu.instance) {
                let url = this.router.url;
                if (this.menu && url) {
                    if (url !== '/secure/home') {
                        url = _trimStart(url, '/');
                    }
                    if (!this.menu.instance.selectItem(url)) {
                        this.menu.instance.unselectAll();
                    } else {
                        this.intervalSubscriber.unsubscribe();
                    }
                }
            }
        });
    }
}

@NgModule({
    imports: [DxTreeViewModule],
    declarations: [SideNavigationMenuComponent],
    exports: [SideNavigationMenuComponent],
})
export class SideNavigationMenuModule {}
