import {
    Component,
    AfterViewInit,
    ChangeDetectorRef,
    HostListener,
} from '@angular/core';
import { confirm } from 'devextreme/ui/dialog';
import {
    QuoteParameter,
    Item,
} from 'src/app/shared/services/print-sheet/interfaces/quote-param';
import moment from 'moment';
import {
    debounce as _debounce,
    DebouncedFunc,
    each as _each,
    map as _map,
    toNumber as _toNumber,
    includes as _includes,
    toString as _toString,
    reduce as _reduce,
    isFinite as _isFinite,
    get as _get,
    isString as _isString,
} from 'lodash';
import {
    ThAddressSuggestorService,
    PrintSheetService,
    RenderPersistReportService,
    NotificationService,
    UtilService,
    MessageType,
} from 'src/app/shared/services';
import { FormatNumberPipe } from 'src/app/shared/pipes';
import { MessageService } from './services/message.service';
import {
    getInitialQuoteInput,
    inputMomentFormat,
    ProductInfo,
    QuoteInput,
} from './models/quote-input';
import {
    branchList,
    deliveryCourierList,
    deliveryTermList,
} from './models/input-choices';
import { ReportApiParameter } from 'src/app/shared/services/print-sheet/interfaces/report-api-request';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-quote-generator',
    templateUrl: './quote-generator.component.html',
    styleUrls: ['./quote-generator.component.scss'],
})
export class QuoteGeneratorComponent implements AfterViewInit {
    branchList = branchList;
    deliveryCourierList = deliveryCourierList;
    deliveryTermList = deliveryTermList;

    postCodeSuggestions = [];
    provinceSuggestions = [];
    districtSuggestions = [];
    subDistrictSuggestions = [];

    shareData: {
        fileName: string;
        sharedUrl: string;
    } = null;
    isQuoteExporting = false;
    requestData: string;
    messageSourceWindow: Window = null;

    inputValues: QuoteInput = getInitialQuoteInput();
    onInputsUpdated: DebouncedFunc<() => void>;

    private previousSuggestionSelection = '';

    private sellerInfo = {
        name: 'GenLogic Shop',
        website: 'https://www.genlogic.co.th/',
        address:
            '111/12 ซ.โชคชัยร่วมมิตร แขวงจอมพล เขตุจตุจักร กรุงเทพมหานคร 10900',
        logoUrl: 'https://dm.lnwfile.com/_/dm/_resize/600/600/9y/ph/9f.jpg',
        taxId: '1101402050523',
        email: 'sale@genlogic.co.th',
    };

    private sellerInfoVat = {
        name: 'บริษัท เจนเนอรัล ลอจิค จำกัด',
        website: 'https://www.genlogic.co.th/',
        address:
            '111/12 ซ.โชคชัยร่วมมิตร แขวงจอมพล เขตุจตุจักร กรุงเทพมหานคร 10900',
        logoUrl: 'https://dm.lnwfile.com/_/dm/_resize/600/600/9y/ph/9f.jpg',
        taxId: '0105567017277',
        email: 'sale@genlogic.co.th',
    };

    constructor(
        private cdref: ChangeDetectorRef,
        private thAddressSuggestor: ThAddressSuggestorService,
        private formatNumberPipe: FormatNumberPipe,
        private printSheetService: PrintSheetService,
        private renderPersistService: RenderPersistReportService,
        private messageService: MessageService,
        private utilService: UtilService,
        private notificationService: NotificationService
    ) {
        this.onInputsUpdated = _debounce(this.internalOnInputsUpdated, 200);
        this.onReorder = this.onReorder.bind(this);
    }

    @HostListener('window:message', ['$event'])
    onMessage(event): void {
        const responseStatus: string = _get(event, 'data.status');
        if (responseStatus === 'ready') {
            this.messageSourceWindow = event.source;
        } else if (responseStatus === 'data' && event.data.data) {
            this.messageService.fillInputFromMessage(
                event.data.data,
                this.inputValues
            );
            this.notificationService.pushMessage({
                message: 'Lnwshop data is loaded',
                messageType: MessageType.success,
            });
        } else if (responseStatus === 'error') {
            this.notificationService.pushMessage({
                message: `Lnwshop Data loading failed with responsed error: ${event.data.message}`,
                messageType: MessageType.error,
            });
        }
    }

    requestMessage(): void {
        if (this.messageSourceWindow) {
            console.log('posted message');
            this.messageSourceWindow.postMessage(
                {
                    status: 'request',
                },
                '*'
            );
        } else {
            console.error('No connection');
        }
    }

    ngAfterViewInit(): void {
        this.postCodeSuggestions = this.thAddressSuggestor.getAllZipCodes();
        this.provinceSuggestions = this.thAddressSuggestor.getAllProvinces();
        this.fillInitialValues(this.inputValues);
        this.cdref.detectChanges();
        // DEBUG Message
        // this.messageService.fillInputFromMessage(a, this.inputValues);
    }

    clearForm(): void {
        confirm('Filled data will be reset.', 'Confirm Clear Data ?').then(
            (result: boolean) => {
                if (result) {
                    this.inputValues = getInitialQuoteInput();
                    this.fillInitialValues(this.inputValues);
                    this.notificationService.pushMessage({
                        message: 'Form is cleared.',
                        messageType: MessageType.info,
                    });
                }
            }
        );
    }

    exportPdf(): void {
        if (!this.isQuoteExporting) {
            this.internalOnInputsUpdated();
            const request = this.constructQuoteRequest();
            let tabTitle = 'ใบเสนอราคา';
            if (request.quoteInfo.quoteNo) {
                tabTitle += ` ${request.quoteInfo.quoteNo}`;
            }
            this.isQuoteExporting = true;
            this.printSheetService.printQuote([request], tabTitle).subscribe(
                () => {
                    this.notificationService.pushMessage({
                        message: 'Quote is successfully exported.',
                        messageType: MessageType.success,
                    });
                },
                (error) => {
                    console.error(error);
                    this.notificationService.pushMessage({
                        message: this.utilService.errorToString(error),
                        messageType: MessageType.error,
                    });
                },
                () => {
                    this.isQuoteExporting = false;
                }
            );
        }
    }

    saveAndShare(): void {
        if (!this.isQuoteExporting) {
            this.internalOnInputsUpdated();
            this.shareData = null;
            const request = this.constructQuoteRequest();
            const payload: ReportApiParameter<QuoteParameter> = {
                renderRequest: {
                    data: { pages: [request] },
                    template: {
                        name: environment.quoteTemplateName,
                    },
                },
            };
            this.isQuoteExporting = true;
            this.renderPersistService
                .renderAndGetSharedUrl<QuoteParameter>({
                    renderFunctionName: 'JsReport:production',
                    renderRequestPayload: payload,
                    reportStoreRequest: {
                        fileName: `${request.quoteInfo.quoteNo}`
                            ? `${
                                  this.inputValues.quoteInfo.billingNote
                                      ? 'I'
                                      : 'Q'
                              }${request.quoteInfo.quoteNo}.pdf`
                            : 'untitled.pdf',
                        parents: ['1Yjy5wsCR4AOgh4QpmkGcY_YJXOL3xHNa'],
                    },
                })
                .subscribe(
                    (response) => {
                        this.shareData = {
                            fileName: response.name,
                            sharedUrl: response.webViewLink,
                        };
                        this.notificationService.pushMessage({
                            message: `${response.name} shared URL is now available.`,
                            messageType: MessageType.success,
                        });
                        this.isQuoteExporting = false;
                    },
                    (error) => {
                        console.error(error);
                        this.notificationService.pushMessage({
                            message: this.utilService.errorToString(error),
                            messageType: MessageType.error,
                        });
                        this.isQuoteExporting = false;
                    }
                );
        }
    }

    openSharedUrl(): void {
        const sharedUrl = _get(this.shareData, 'sharedUrl');
        if (sharedUrl) {
            window.open(sharedUrl, '_blank');
        }
    }

    copySharedUrlToClibboard(): void {
        const sharedUrl = _get(this.shareData, 'sharedUrl');
        if (sharedUrl) {
            navigator.clipboard.writeText(sharedUrl).then(
                () => {
                    this.notificationService.pushMessage({
                        message: 'URL copied to clipboard.',
                        messageType: MessageType.success,
                        displayTime: 100,
                    });
                },
                (e) => {
                    console.error(e);
                }
            );
        }
    }

    calculateProductsAmount(rowData: ProductInfo): number {
        const unitPrice = _get(rowData, 'unitPrice');
        const quantity = _get(rowData, 'quantity');
        if (_isFinite(unitPrice) && _isFinite(quantity)) {
            return rowData.unitPrice * rowData.quantity;
        }
        return 0;
    }

    onRemarkAutoFillButtonClicked(e): void {
        let textContent: string = _get(e, 'element.textContent');
        if (textContent) {
            textContent = textContent.substring(2);
            if (textContent) {
                if (!_isString(this.inputValues.quoteInfo.remark)) {
                    this.inputValues.quoteInfo.remark = '';
                }
                this.inputValues.quoteInfo.remark =
                    this.inputValues.quoteInfo.remark.trim();
                if (this.inputValues.quoteInfo.remark) {
                    this.inputValues.quoteInfo.remark += ', ';
                }
                this.inputValues.quoteInfo.remark += textContent;
            }
        }
    }

    onSaved(): void {
        this.generateProductOrderNumber();
        this.onInputsUpdated();
    }

    onReorder(e): void {
        const visibleRows = e.component.getVisibleRows();
        const toIndex = this.inputValues.productItems.indexOf(
            visibleRows[e.toIndex].data
        );

        const fromIndex = this.inputValues.productItems.indexOf(e.itemData);

        this.inputValues.productItems.splice(fromIndex, 1);
        this.inputValues.productItems.splice(toIndex, 0, e.itemData);
        this.generateProductOrderNumber();
    }

    private fillInitialValues(input: QuoteInput): void {
        input.quoteInfo.date = moment().format(inputMomentFormat);
        input.quoteInfo.shippingTerms = deliveryTermList[0] || '';
        input.quoteInfo.isSigned = true;
        this.shareData = null;
    }

    private formatDisplayQuoteDate(input: string): string {
        if (input) {
            const momentObj = moment(input, inputMomentFormat);
            momentObj.locale('th');
            momentObj.add(543, 'year').format('LLLL');
            return momentObj.format('DD MMMM YYYY');
        }
        return '';
    }

    private formatString(input: any): string {
        return _toString(input).trim();
    }

    private formatDecimal(input: any, decimalPoint: number): string {
        const inputStr = _toString(input).trim();
        if (inputStr) {
            return this.formatNumberPipe.transform(inputStr, decimalPoint);
        }
        return inputStr;
    }

    private constructQuoteRequest(): QuoteParameter {
        const totalProductPrice: number =
            _toNumber(this.inputValues.quoteInfo.totalProductPrice) || 0;
        const shippingCost: number =
            _toNumber(this.inputValues.quoteInfo.shippingCost) || 0;
        const requestParam: QuoteParameter = {
            sellerInfo: this.inputValues.quoteInfo.isVat
                ? this.sellerInfoVat
                : this.sellerInfo,
            quoteInfo: {
                billingNote: this.inputValues.quoteInfo.billingNote
                    ? 'true'
                    : '',
                quoteNo: this.formatString(this.inputValues.quoteInfo.quoteNo),
                date: this.formatDisplayQuoteDate(
                    this.inputValues.quoteInfo.date
                ),
                validUntil: this.formatDisplayQuoteDate(
                    this.inputValues.quoteInfo.validUntil
                ),
                customTitle: this.formatString(
                    this.inputValues.quoteInfo.customTitle
                ),
                remark: this.formatString(this.inputValues.quoteInfo.remark),
                deliveryCourier: this.formatString(
                    this.inputValues.quoteInfo.shippingCarrier
                ),
                deliveryTerms: this.formatString(
                    this.inputValues.quoteInfo.shippingTerms
                ),
                hidePaymentMethod: this.inputValues.quoteInfo.hidePaymentMethod
                    ? 'true'
                    : '',
                hideSignatureFields: this.inputValues.quoteInfo
                    .hideSignatureFields
                    ? 'true'
                    : '',
                signed: this.inputValues.quoteInfo.isSigned ? 'true' : '',
                tableTotal: this.formatDecimal(
                    totalProductPrice + shippingCost,
                    2
                ),
                vat: this.inputValues.quoteInfo.isVat
                    ? this.formatDecimal(
                          this.inputValues.quoteInfo.vatAmount || 0,
                          2
                      )
                    : '',
                discount: this.inputValues.quoteInfo.discount
                    ? this.formatDecimal(this.inputValues.quoteInfo.discount, 2)
                    : '',
                shippingCost: shippingCost
                    ? this.formatDecimal(
                          this.inputValues.quoteInfo.shippingCost || 0,
                          2
                      )
                    : '',
                netPrice: this.formatDecimal(
                    this.inputValues.quoteInfo.netPrice || 0,
                    2
                ),
            },
            customerInfo: {
                name: this.formatString(this.inputValues.customerInfo.name),
                address: this.formatString(
                    this.inputValues.customerInfo.useCustomAddress
                        ? this.inputValues.customerInfo.customAddress
                        : this.inputValues.customerInfo.fullAddress
                ),
                taxId: this.formatString(this.inputValues.customerInfo.taxId),
                branch: this.formatString(this.inputValues.customerInfo.branch),
            },
            items: _map<ProductInfo, Item>(
                this.inputValues.productItems,
                (item: ProductInfo): Item => {
                    return {
                        itemNo: this.formatDecimal(item.itemNo, 0),
                        description: this.formatString(item.description),
                        unitPrice: this.formatDecimal(item.unitPrice, 2),
                        quantity: this.formatDecimal(item.quantity, 0),
                        amount: this.formatDecimal(item.amount, 2),
                    };
                }
            ),
        };
        return requestParam;
    }

    private internalOnInputsUpdated(): void {
        this.suggestAddressIfNeeded();
        this.constructFullAddress();
        this.calculatePrices();
        this.cdref.detectChanges();
    }

    private calculatePrices() {
        _each(this.inputValues.productItems, (prduct: ProductInfo) => {
            if (_isFinite(prduct.unitPrice) && _isFinite(prduct.quantity)) {
                prduct.amount = prduct.unitPrice * prduct.quantity;
            } else {
                prduct.amount = 0;
            }
        });
        const productPrice: number = _reduce(
            this.inputValues.productItems,
            (total: number, item) => {
                const amountNum = _toNumber(item.amount);
                if (_isFinite(amountNum)) {
                    return total + item.amount;
                }
                return total;
            },
            0
        );
        this.inputValues.quoteInfo.totalProductPrice = productPrice;

        const shippingCost =
            _toNumber(this.inputValues.quoteInfo.shippingCost) || 0;
        const discount = _toNumber(this.inputValues.quoteInfo.discount) || 0;

        const amtBeforeVat: number = productPrice + shippingCost - discount;

        let vatAmount = 0;
        if (this.inputValues.quoteInfo.isVat) {
            vatAmount = _toNumber(amtBeforeVat) * 0.07 || 0;
            vatAmount = Math.round((vatAmount + Number.EPSILON) * 100) / 100;
        }
        this.inputValues.quoteInfo.vatAmount = vatAmount;

        const netPrice: number = amtBeforeVat + vatAmount;
        this.inputValues.quoteInfo.netPrice = netPrice;
    }

    private suggestAddressIfNeeded(): void {
        const selectedPostCode =
            _toString(this.inputValues.customerInfo.postCode) || '';
        const selectedProvince = this.inputValues.customerInfo.province || '';
        const selectedDistrict = this.inputValues.customerInfo.district || '';

        const currentSuggestionSelection =
            selectedPostCode + selectedProvince + selectedDistrict;

        if (this.previousSuggestionSelection !== currentSuggestionSelection) {
            this.previousSuggestionSelection = currentSuggestionSelection;
            if (selectedPostCode.length === 5) {
                const selectedPostCodeNum = _toNumber(selectedPostCode);
                const province: string =
                    this.thAddressSuggestor.getProvinceByPostCode(
                        selectedPostCodeNum
                    );
                this.provinceSuggestions = [province];

                if (selectedProvince) {
                    const districts: string[] =
                        this.thAddressSuggestor.getDistrictsByPostCode(
                            selectedPostCodeNum
                        );

                    this.districtSuggestions = districts;
                } else {
                    this.districtSuggestions = [];
                }

                if (selectedDistrict) {
                    const subDistricts: string[] =
                        this.thAddressSuggestor.getSubDistrictsByDistrict(
                            selectedPostCodeNum,
                            selectedDistrict
                        );

                    this.subDistrictSuggestions = subDistricts;
                } else {
                    this.subDistrictSuggestions = [];
                }
            } else {
                this.provinceSuggestions =
                    this.thAddressSuggestor.getAllProvinces();
            }
        }
    }

    private constructFullAddress(): void {
        const address1 = this.inputValues.customerInfo.address1?.trim();
        const subDistrict = this.inputValues.customerInfo.subDistrict?.trim();
        const district = this.inputValues.customerInfo.district?.trim();
        const province = this.inputValues.customerInfo.province?.trim();
        const postCode = _toString(
            this.inputValues.customerInfo.postCode
        ).trim();
        let result = '';
        if (address1) {
            result += address1;
        }
        const isBangkok = _includes(province, 'กรุงเทพ');
        if (subDistrict) {
            result += isBangkok ? ' แขวง' : ' ตำบล';
            result += subDistrict;
        }
        if (district) {
            result += isBangkok ? ' เขต' : ' อำเภอ';
            result += district;
        }
        if (province) {
            result += ` จังหวัด${province}`;
        }
        if (postCode) {
            result += ` ${postCode}`;
        }
        result = result.trim();
        this.inputValues.customerInfo.fullAddress = result;
    }

    private generateProductOrderNumber(): void {
        _each(this.inputValues.productItems, (item, index) => {
            item.itemNo = index + 1;
        });
    }
}

/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const a = {
    id: '46351',
    id_display: '46351',
    jtype: 'manage',
    product_quantity: '1',
    net_price: 225,
    price: '225.00',
    lnwpoint: 0,
    lnwpoint_price: 0,
    pay_price: 225,
    cod_flag: 0,
    cod_amount: null,
    lnwpay_flag: 0,
    is_lnwpay: 0,
    is_lnwpay_lite: 0,
    owner_create_flag: 2,
    channel: 'lnwshop',
    lnwmallx_channel: null,
    channel_data: {
        ukey: null,
        kind: 'custom',
        id: null,
        key: null,
        name: '- ไม่ระบุ -',
    },
    referral_data: null,
    shop_channel: false,
    stock_channel_id: '1',
    is_fulfillment: 0,
    is_dealer: 0,
    lang_key: 'th',
    status: 'cart',
    state: 'wait_order',
    stage: 'wait_order',
    suspended: 0,
    detail: null,
    staff_user: {
        id: '1938265',
        name: 'disorn.th',
        type: 'user',
        ip: '183.88.58.84',
        time: '2022-07-31 21:18:28',
    },
    channel_order_id: null,
    state_steps: [
        { key: 'wait_order', time: null, tense: 'present' },
        { key: 'wait_payment', time: null, tense: 'future' },
        { key: 'wait_payment_verify', time: null, tense: 'future' },
        { key: 'wait_send', time: null, tense: 'future' },
        { key: 'completed', time: null, tense: 'future' },
    ],
    real_lnwpay_flag: '0',
    order_time: '2022-07-31 21:18:28',
    cart_time: '2022-07-31 21:18:28',
    order_time_txt: '31 กรกฎาคม 2565',
    expire_time: '2022-08-03 21:18:28',
    expire_time_txt: '3 สิงหาคม 2565 เวลา 21:18 น.',
    cc_url: 'https://v1.lnw.me/order/341932/46351?s=1d7ef4db',
    cc_url_v2: 'https://lnw.me/order/arduino4/46351?s=1d7ef4db',
    first_expired_time: false,
    product_weight: 10,
    payment_time_txt: null,
    payment: null,
    is_no_shipping: 0,
    shipping_type_id: '3',
    shipping_type_text: 'พัสดุด่วนพิเศษ (EMS)',
    shipping_type_provider: ['thailandpost'],
    shipping_type_providers: ['thailandpost'],
    deadline_shipping_time: null,
    shipping_time: null,
    allow_marketing_flag: null,
    prepare_duration: '1',
    prepare_weekday: '1',
    customer: 0,
    receiver: {
        id: '24017050',
        key: 'receiver',
        kind: 'receiver',
        full_name: 'เจษฎา ทวีพิริยะ',
        name: 'เจษฎา ทวีพิริยะ',
        email: 'arduino4.shop@gmail.com',
        mobile: '0859093746',
        address: '58 / 6 หมู่ 4 ถนนสุขุมวิท',
        country_code: null,
        country: null,
        province_id: '12',
        province: 'ระยอง',
        district_id: '144',
        district: 'แกลง',
        subdistrict_id: '1048',
        subdistrict: 'ชากโดน',
        zipcode: '21110',
        telephone: '0859093746',
        latitude: null,
        longitude: null,
        thai_type: 1,
        full_address:
            '58 / 6 หมู่ 4 ถนนสุขุมวิท ตำบลชากโดน อำเภอแกลง จังหวัดระยอง 21110',
        letter_address:
            '58 / 6 หมู่ 4 ถนนสุขุมวิท ตำบลชากโดน อำเภอแกลง จังหวัดระยอง',
    },
    receiver_name: 'เจษฎา ทวีพิริยะ',
    receiver_email: 'arduino4.shop@gmail.com',
    receiver_mobile: '0859093746',
    receive_sms_flag: 0,
    receive_email_flag: 0,
    dealer: null,
    receipt: {
        id: '23385133',
        key: 'guest_receipt',
        kind: 'receipt',
        full_name: 'บริษัท พีเอซี เอเชีย ซัพพลาย จำกัด',
        name: 'บริษัท พีเอซี เอเชีย ซัพพลาย จำกัด',
        address:
            '993/200 หมู่ที่ 6 ตำบลแพรกษา อำเภอเมืองสมุทรปราการ จังหวัดสมุทรปราการ 10280',
        tax_id: '0115557008224',
        branch_id: '00000',
    },
    products: [
        {
            id: '787',
            name: 'กล่องขับมอเตอร์ DC 12-48V PWM DC Motor Speed Controller Meimotor High Power 10A',
            title: 'กล่องขับมอเตอร์ DC 12-48V PWM DC Motor Speed Controller Meimotor High Power 10A',
            quantity: '1',
            detail: '',
            url: 'https://www.arduino4.com/product/787',
            thumbnail:
                'https://dm.lnwfile.com/_/dm/_resize/100/100/wz/sb/8s.jpg',
            price: '210.00',
            amount: '210.00',
            images: 'https://dm.lnwfile.com/_/dm/_resize/200/200/wz/sb/8s.jpg',
            alive: 1,
            parent_id: 0,
            parent_name: null,
            parent_image: null,
            code: 'D77',
        },
    ],
    promotion: [
        {
            position: 'after_shipping',
            name: 'ส่วนลดท้ายบิล',
            discount: '40.0000',
        },
    ],
    discounts: [
        {
            type: 'custom',
            position: 'after_shipping',
            name: 'ส่วนลดท้ายบิล',
            price: '40.0000',
        },
    ],
    after_product_discounts: [],
    after_shipping_discounts: [
        {
            type: 'custom',
            position: 'after_shipping',
            name: 'ส่วนลดท้ายบิล',
            price: '40.0000',
        },
    ],
    product_price: '210.0000',
    discount_price: '40.0000',
    shipping_price: '55.0000',
    note: null,
    can_cancel: false,
    can_remove: true,
    can_refund: false,
    can_restore: false,
    can_inform_payment: true,
    can_edit_expire_time: true,
    can_edit_receiver_contact: true,
    can_edit_dealer_contact: false,
    can_edit_receipt_contact: true,
    can_edit_product: true,
    can_confirm_fulfillment_shipping: false,
    can_hold_fulfillment_shipping: false,
    can_cancel_fulfillment_shipping: false,
    can_shipping: false,
    can_edit_shipping: false,
    can_add_track_code: false,
    can_remove_track_code: false,
    can_edit_shipping_description: false,
    can_undo_sending: false,
    payment_provider_code: null,
    is_lnwpay_refundable: false,
    invoice_valid_flag: '1',
    is_wait_create_invoice: false,
    is_wait_update_invoice: false,
    trackcodes: [],
    shipping_description: null,
    lnwpay_payment_fee: null,
    display_payment_fee: false,
    display_shipping_fee: false,
    display_fulfillment_fee: false,
    display_fee: false,
    is_shipping_type_pubat: false,
};
