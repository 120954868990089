import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { environment } from '../../../../environments/environment';
import { PrintSheetUtils } from './utils';
import { each as _each, map as _map, get as _get } from 'lodash';
import { map } from 'rxjs/operators';
import { FormatNumberPipe } from '../../pipes';
import { BaseRequester } from '../common/base-requester';
import { InvoiceParameter } from './interfaces/invoice-param';
import { ReportApiParameter } from './interfaces/report-api-request';
import { ShopeeShippingLabelParameter } from './interfaces/shopee-shipping-label-param';
import { CustomTemplateParam } from './interfaces/custom-template-param';
import { QuoteParameter } from './interfaces/quote-param';
import { TaxInvoicePrintSheetParameter } from './interfaces/tax-invoice-param';
import { OutputTaxPrintSheetParameter } from './interfaces/output-tax-request';

@Injectable({
    providedIn: 'root',
})
export class PrintSheetService extends BaseRequester {
    constructor(private formatNumberPipe: FormatNumberPipe) {
        super();
    }

    printQuote(
        quoteParameters: QuoteParameter[],
        tabName?: string
    ): Observable<boolean> {
        const parameter: ReportApiParameter<QuoteParameter> = {
            renderRequest: {
                template: {
                    name: environment.quoteTemplateName,
                },
                data: {
                    pages: quoteParameters,
                },
            },
        };
        return this.print(parameter, environment.renderReportEndpoint, tabName);
    }

    printShippingLabel(
        shippingLabelParams: ShopeeShippingLabelParameter[],
        templateName: string,
        scale: number,
        tabName?: string
    ): Observable<boolean> {
        const parameter: ReportApiParameter<ShopeeShippingLabelParameter> = {
            renderRequest: {
                template: {
                    name: templateName,
                    chrome: {
                        scale,
                    },
                },
                data: {
                    pages: shippingLabelParams,
                },
            },
        };
        return this.print(parameter, environment.renderReportEndpoint, tabName);
    }

    printInvoice(
        invoiceParams: InvoiceParameter[],
        tabName?: string
    ): Observable<boolean> {
        const parameter: ReportApiParameter<InvoiceParameter> = {
            renderRequest: {
                template: {
                    name: environment.invoiceReportTemplateName,
                },
                data: {
                    pages: invoiceParams,
                },
            },
        };
        return this.print(parameter, environment.renderReportEndpoint, tabName);
    }

    printTaxInvoice(
        taxInvoiceParams: TaxInvoicePrintSheetParameter[],
        tabName?: string
    ): Observable<boolean> {
        const parameter: ReportApiParameter<TaxInvoicePrintSheetParameter> = {
            renderRequest: {
                template: {
                    name: environment.taxInvoiceReportTemplateName,
                },
                data: {
                    pages: taxInvoiceParams,
                },
            },
        };
        return this.print(
            parameter,
            environment.render2ReportEndpoint,
            tabName
        );
    }

    printOutputTaxReport(
        outputTaxParam: OutputTaxPrintSheetParameter,
        tabName?: string
    ) {
        const parameter: ReportApiParameter<OutputTaxPrintSheetParameter> = {
            renderRequest: {
                template: {
                    name: environment.outputTaxReportTemplateName,
                },
                data: outputTaxParam,
            },
        };
        return this.print(
            parameter,
            environment.render2ReportEndpoint,
            tabName
        );
    }

    printLazadaShippingLabelTemplate(
        htmls: string[],
        tabName: string
    ): Observable<boolean> {
        const requestParam: ReportApiParameter<CustomTemplateParam> = {
            renderRequest: {
                template: {
                    name: environment.lazadaShipingLabelRenderTemplateName,
                    chrome: {
                        scale: 0.68,
                    },
                },
                data: {
                    pages: _map(htmls, (html) => {
                        return {
                            template: html,
                        };
                    }),
                },
            },
        };
        return this.print(
            requestParam,
            environment.renderReportEndpoint,
            tabName
        );
    }

    constructCodDescription(isCod: boolean, totalAmount: number) {
        if (isCod) {
            return `เก็บเงินค่าสินค้า ${this.formatNumberPipe.transform(
                totalAmount,
                2
            )} บาท`;
        } else {
            return 'นำจ่ายโดยไม่ต้องเก็บเงินค่าสินค้า';
        }
    }

    public localCustomHtmlPrint(htmlPages: string[], tabName?: string) {
        if (htmlPages.length > 0) {
            const pdfWindow = window.open('');
            pdfWindow.document.write(
                `<title>${tabName ? tabName : 'Print'} (${
                    htmlPages.length
                })</title>`
            );

            pdfWindow.document.write(
                `<style type='text/css'> body, html {margin: 0; padding: 0; -webkit-print-color-adjust: exact;}</style>`
            );
            _each(htmlPages, (html, i) => {
                pdfWindow.document.write(
                    `<iframe style="border: 0px; frameborder:0; height: 830px; width:100%; zoom: 69%" srcdoc='${html}'></iframe>`
                );
                if (i !== htmlPages.length - 1) {
                    pdfWindow.document.write(
                        '<div style="page-break-after: always;"></div>'
                    );
                }
            });
        }
    }

    private print(
        parameter: ReportApiParameter<any>,
        endpoint: string,
        tabName?: string
    ): Observable<boolean> {
        return this.internalPostRequest(endpoint, parameter).pipe(
            map((response: AjaxResponse) => {
                if (response.response.statusCode === 200) {
                    const pageCount: number = _get(
                        parameter,
                        'renderRequest.data.pages.length',
                        0
                    );
                    const tName = `${
                        tabName || parameter.renderRequest.template.name
                    }${pageCount ? ` (${pageCount})` : ''}`;
                    this.openNewPdfTabFromBase64(tName, response.response.body);
                    return true;
                } else {
                    throw new Error(
                        response.response.errorMessage || response.response
                    );
                }
            })
        );
    }

    private openNewPdfTabFromBase64(tabName: string, b64: string) {
        const pdfWindow = window.open('');
        pdfWindow.document.write(`<title>${tabName}</title>`);
        pdfWindow.document.write(
            `<style type='text/css'> body, html {margin: 0; padding: 0; height: 100%; overflow: hidden;}</style>`
        );
        const blob = PrintSheetUtils.base64ToBlob(b64, 'application/pdf');
        const url = URL.createObjectURL(blob);
        pdfWindow.document.write(
            `<iframe style='border: 0px' width='100%' height='100%' frameborder'0' src='${url}'></iframe>`
        );
    }
}
