import { Injectable } from '@angular/core';
import { AjaxResponse } from 'rxjs/ajax';
import { environment } from '../../../environments/environment';
import { Observable, of, merge } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { set as _set, get as _get } from 'lodash';
import { BaseRequester } from './common/base-requester';

@Injectable({
    providedIn: 'root',
})
export class UnifiedQueryService extends BaseRequester {
    constructor() {
        super();
    }

    public externalQuery(request: UnifiedQueryRequest) {
        return this.query(request, this.externalRequest.bind(this));
    }

    public internalQuery(request: UnifiedQueryRequest) {
        return this.query(request, this.internalRequest.bind(this));
    }

    private externalRequest(
        request: UnifiedQueryRequest,
        lastEvaluatedKey?: unknown
    ): Observable<AjaxResponse> {
        return this.request(
            request,
            environment.unifiedQueryExternalEndPoint,
            false,
            lastEvaluatedKey
        );
    }

    private internalRequest(
        request: UnifiedQueryRequest,
        lastEvaluatedKey?: unknown
    ): Observable<AjaxResponse> {
        return this.request(
            request,
            environment.unifiedQueryInternalEndPoint,
            true,
            lastEvaluatedKey
        );
    }

    private request(
        request: UnifiedQueryRequest,
        endPoint: string,
        isInternal: boolean,
        lastEvaluatedKey?: unknown
    ) {
        if (lastEvaluatedKey) {
            _set(request.parameter, 'ExclusiveStartKey', lastEvaluatedKey);
        }
        if (isInternal) {
            return this.internalPostRequest<UnifiedQueryRequest>(
                endPoint,
                request
            );
        }
        return this.externalPostRequest<UnifiedQueryRequest>(endPoint, request);
    }

    private query(
        request: UnifiedQueryRequest,
        requestFunction: (...args: any) => Observable<AjaxResponse>,
        lastEvaluatedKey?: unknown
    ): Observable<AjaxResponse> {
        return requestFunction(request, lastEvaluatedKey).pipe(
            mergeMap((response: AjaxResponse): Observable<AjaxResponse> => {
                const newLastEvaluatedKey = this.getLastEvaluatedKey(response);
                if (newLastEvaluatedKey) {
                    return merge(
                        of(response),
                        this.query(
                            request,
                            requestFunction,
                            newLastEvaluatedKey
                        )
                    );
                } else {
                    return of(response);
                }
            })
        );
    }

    private getLastEvaluatedKey(response: AjaxResponse): unknown {
        return _get(response, 'response.LastEvaluatedKey');
    }
}

export interface UnifiedQueryRequest {
    operation: 'scan' | 'query';
    parameter: any;
}
