import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ViewChild,
} from '@angular/core';
import { OutputTaxService } from '../../services/output-tax/tax-output.service';
import { OutputTax } from '../../services/output-tax/interfaces/output-tax-request';
import {
    map as _map,
    toString as _toString,
    isFinite as _isFinite,
    get as _get,
    sumBy as _sumBy,
} from 'lodash';
import { DxDataGridComponent, DxTooltipComponent } from 'devextreme-angular';
import {
    MessageType,
    NotificationService,
    PrintSheetService,
    RenderPersistReportService,
    UtilService,
} from 'src/app/shared/services';
import {
    OutputTaxItem,
    OutputTaxPrintSheetParameter,
} from 'src/app/shared/services/print-sheet/interfaces/output-tax-request';
import { monthSelection, sellerInfo } from '../../models/constants';
import { FormatNumberPipe } from 'src/app/shared/pipes';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { on } from 'devextreme/events';
@Component({
    selector: 'app-output-tax-summary',
    templateUrl: './output-tax-summary.component.html',
    styleUrls: ['./output-tax-summary.component.scss'],
})
export class OutputTaxSummaryComponent implements AfterViewInit {
    @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
    @ViewChild('sharedLinkButtonTooltip')
    sharedLinkButtonTooltip: DxTooltipComponent;

    monthSelection = monthSelection;
    sharedLinkHover: string;
    saveReport: boolean;
    selectedYear: number;
    selectedMonth: number;
    selectedMonthDisplay: string;
    isLoading = false;
    isEditorsDirty = true;
    dataSource: OutputTax[] = [];
    shareData = null;

    constructor(
        private cdref: ChangeDetectorRef,
        private outputTaxService: OutputTaxService,
        private formatNumberPipe: FormatNumberPipe,
        private utilService: UtilService,
        private notificationService: NotificationService,
        private printSheetService: PrintSheetService,
        private renderPersistReportService: RenderPersistReportService
    ) {}
    ngAfterViewInit(): void {
        this.fillInitialValues();
        this.loadDataSource();
        this.cdref.detectChanges();
    }

    fillInitialValues(): void {
        const now = new Date();
        this.selectedYear = now.getFullYear();
        this.selectedMonth = now.getMonth() + 1;
    }

    loadDataSource(): void {
        if (
            _isFinite(this.selectedYear) &&
            _isFinite(this.selectedMonth) &&
            !this.isLoading
        ) {
            this.beginLoading();
            this.outputTaxService
                .getOutputTax(this.selectedYear, this.selectedMonth)
                .subscribe(
                    (response: OutputTax[]) => {
                        this.dataSource = response;
                        this.isEditorsDirty = false;
                        this.endLoading();
                    },
                    (error) => {
                        this.endLoading();
                        console.error(error);
                        this.notificationService.pushMessage({
                            message: this.utilService.errorToString(error),
                            messageType: MessageType.error,
                        });
                    }
                );
        }
    }

    onEditorValueChanged(): void {
        this.isEditorsDirty = true;
    }

    onOpenFileButtonClicked(e): void {
        const sharedLink: string = _get(e, 'row.data.fileSharedLink');
        if (sharedLink) {
            window.open(sharedLink, '_blank');
        } else {
            this.notificationService.pushMessage({
                message: 'ไม่มีข้อมูล',
                messageType: MessageType.error,
            });
        }
    }

    onShowReportClicked(): void {
        if (this.saveReport) {
            this.saveAndOpenReportLink();
        } else {
            this.previewReport();
        }
    }

    openSharedUrl(): void {
        const sharedUrl: string = _get(this.shareData, 'sharedUrl');
        if (sharedUrl) {
            window.open(sharedUrl, '_blank');
        }
    }

    copySharedUrlToClibboard(): void {
        const sharedUrl = _get(this.shareData, 'sharedUrl');
        if (sharedUrl) {
            navigator.clipboard.writeText(sharedUrl).then(
                () => {
                    this.notificationService.pushMessage({
                        message: 'URL copied to clipboard.',
                        messageType: MessageType.success,
                        displayTime: 100,
                    });
                },
                (e) => {
                    console.error(e);
                }
            );
        }
    }

    onCellPrepared(e) {
        if (e.rowType === 'data' && e.column.dataField === 'fileSharedLink') {
            const linkElement = e.cellElement.getElementsByTagName('a');
            if (linkElement) {
                on(linkElement, 'mouseover', (arg) => {
                    this.sharedLinkHover = e.data.fileSharedLink;
                    this.sharedLinkButtonTooltip.instance.show(arg.target);
                    console.log('sdaf');
                });
                on(linkElement, 'mouseout', () => {
                    this.sharedLinkButtonTooltip.instance.hide();
                });
            }
        }
    }

    formatDateCell(cellInfo): string {
        const momentObj = moment(cellInfo.valueText, 'YYYYMMDD');
        if (momentObj.isValid()) {
            momentObj.locale('th');
            return momentObj.format('DD MMM YYYY');
        }
        return cellInfo.valueText;
    }

    private previewReport(): void {
        if (!this.isLoading) {
            const param = this.preparePrintSheetParam();
            this.beginLoading();
            this.printSheetService
                .printOutputTaxReport(param, this.constructReportName())
                .subscribe(
                    () => {
                        this.notificationService.pushMessage({
                            message: 'สร้างรายงานสำเร็จ',
                            messageType: MessageType.success,
                        });
                        this.endLoading();
                    },
                    (error) => {
                        console.error(error);
                        this.notificationService.pushMessage({
                            message: this.utilService.errorToString(error),
                            messageType: MessageType.error,
                        });
                        this.endLoading();
                    }
                );
        }
    }

    private saveAndOpenReportLink(): void {
        if (!this.isLoading) {
            const param = this.preparePrintSheetParam();
            this.beginLoading();
            this.renderPersistReportService
                .renderAndGetSharedUrl<OutputTaxPrintSheetParameter>({
                    renderFunctionName: 'jsreport-invoice:production',
                    renderRequestPayload: {
                        renderRequest: {
                            template: {
                                name: environment.outputTaxReportTemplateName,
                            },
                            data: param,
                        },
                    },
                    reportStoreRequest: {
                        fileName: `${this.constructReportName()}.pdf`,
                        parents: ['1DJ-x_9lpGB44dOIFeTjuN6csU85ubOmo'],
                    },
                })
                .subscribe(
                    (response) => {
                        this.shareData = {
                            fileName: response.name,
                            sharedUrl: response.webViewLink,
                        };
                        this.notificationService.pushMessage({
                            message: `${response.name} shared URL is now available.`,
                            messageType: MessageType.success,
                        });
                        this.endLoading();
                    },
                    (error) => {
                        console.error(error);
                        this.notificationService.pushMessage({
                            message: this.utilService.errorToString(error),
                            messageType: MessageType.error,
                        });
                        this.endLoading();
                    }
                );
        }
    }

    private constructReportName(): string {
        return `รายงานภาษีขาย ${this.selectedYear}-${this.selectedMonth}`;
    }

    private preparePrintSheetParam(): OutputTaxPrintSheetParameter {
        const param: OutputTaxPrintSheetParameter = {
            docInfo: {
                title: 'รายงานภาษีขาย',
                taxMonth: this.selectedMonthDisplay,
                taxYear: _toString(this.selectedYear + 543),
                name: sellerInfo.name,
                branchName: sellerInfo.branch,
                taxId: sellerInfo.taxId,
                placeName: sellerInfo.name,
                sumPreTaxAmt: this.calculateTotalPreTaxStr(),
                sumVatAmt: this.calculateTotalVatStr(),
            },
            items: this.getPrintSheetItemParam(),
        };
        return param;
    }

    private getPrintSheetItemParam(): OutputTaxItem[] {
        return _map<OutputTax, OutputTaxItem>(
            this.dataSource,
            (outputTax: OutputTax) => {
                return {
                    invNum: this.formatString(
                        _get(outputTax, 'orderInfo.invNum')
                    ),
                    date: this.formatDisplayDate(
                        _get(outputTax, 'orderInfo.date'),
                        'DD/MM/YY'
                    ),
                    cusName: this.formatString(
                        _get(outputTax, 'customerInfo.name')
                    ),
                    cusTaxId: this.formatString(
                        _get(outputTax, 'customerInfo.taxId')
                    ),
                    cusBranch: this.formatString(
                        _get(outputTax, 'customerInfo.branch')
                    ),
                    preTaxAmt: this.formatDecimal(
                        _get(outputTax, 'orderInfo.amtBeforeVat'),
                        2
                    ),
                    vatAmt: this.formatDecimal(
                        _get(outputTax, 'orderInfo.totalVat'),
                        2
                    ),
                };
            }
        );
    }

    private formatDisplayDate(input: number, format: string): string {
        if (input) {
            const momentObj = moment(_toString(input), 'YYYYMMDD');
            if (momentObj.isValid()) {
                momentObj.locale('th');
                momentObj.add(543, 'year').format('LLLL');
                return momentObj.format(format);
            }
        }
        return '';
    }

    private formatString(input: any): string {
        return _toString(input).trim();
    }

    private calculateTotalPreTaxStr(): string {
        const total: number = _sumBy(this.dataSource, 'orderInfo.amtBeforeVat');
        return this.formatDecimal(total, 2);
    }

    private calculateTotalVatStr(): string {
        const total: number = _sumBy(this.dataSource, 'orderInfo.totalVat');
        return this.formatDecimal(total, 2);
    }

    private formatDecimal(input: any, decimalPoint: number): string {
        const inputStr = _toString(input).trim();
        if (inputStr) {
            return this.formatNumberPipe.transform(inputStr, decimalPoint);
        }
        return inputStr;
    }

    private beginLoading(): void {
        this.isLoading = true;
        this.dataGrid.instance.beginCustomLoading(null);
    }

    private endLoading(): void {
        this.isLoading = false;
        this.dataGrid.instance.endCustomLoading();
    }
}
