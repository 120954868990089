import { Injectable } from '@angular/core';
import { UnifiedQueryV2Service } from 'src/app/shared/services';
import { Counterparty } from '../models';
import { Observable, throwError } from 'rxjs';
import { BaseRequester } from 'src/app/shared/services/common/base-requester';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AjaxRequest, AjaxResponse, ajax } from 'rxjs/ajax';
import { get as _get } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class CounterpartyService extends BaseRequester {
    constructor(private unifiedQueryV2Service: UnifiedQueryV2Service) {
        super();
    }

    getCounterparties(query: any): Observable<Counterparty[]> {
        return this.unifiedQueryV2Service.query<Counterparty>({
            db: 'arduino4',
            col: 'counterparties',
            query: query,
        });
    }

    getDbdCounterpartyDataAlt(taxId: string): Observable<any> {
        const ajaxRequest: AjaxRequest = {
            url: `https://openapi.dbd.go.th/api/v1/juristic_person/${taxId}`,
            method: 'GET',
            responseType: 'json',
        };
        return ajax(ajaxRequest).pipe(
            map((res: AjaxResponse) => {
                if (res.status === 200) {
                    if (_get(res, 'response.status.code') === '1000') {
                        const dataList: any[] = _get(res, 'response.data', []);
                        return dataList;
                    } else if (_get(res, 'response.status.code') === '1004') {
                        return [];
                    }
                    return throwError(`Dbd service error: ${res.responseText}`);
                }
                return throwError(res.responseText);
            })
        );
    }

    getDbdCounterpartyData(taxId: string): Observable<any> {
        return this.internalPostRequest(environment.getDbdCtpEndpoint, {
            taxId,
        }).pipe(
            map((res: AjaxResponse) => {
                if (res.status === 200) {
                    return _get(res, 'response');
                }
                return throwError(res);
            })
        );
    }

    findOrdersByTaxId(taxIds: string[]) {
        return this.internalPostRequest(
            environment.counterpartyGetOrdersByTaxIdEndpoint,
            {
                taxIds,
            }
        ).pipe(
            map((response: AjaxResponse) => {
                if (response.response.errorMessage) {
                    throw new Error(
                        `[${response.response.errorType}]: ${response.response.errorMessage}`
                    );
                } else {
                    return response.response;
                }
            })
        );
    }
}
