import { Injectable } from '@angular/core';
import { AjaxResponse } from 'rxjs/ajax';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseRequester } from '../../../../shared/services/common/base-requester';
import {
    DeleteOutputTaxRequest,
    DeleteParameter,
    SaveOutputTaxRequest,
    OutputTax,
    GetOutputTaxRequest,
    SaveOutputTaxSharedUrlRequest,
} from './interfaces/output-tax-request';
@Injectable({
    providedIn: 'root',
})
export class OutputTaxService extends BaseRequester {
    constructor() {
        super();
    }

    public saveTaxInvoice(parameter: OutputTax): Observable<any> {
        return this.request<SaveOutputTaxRequest>({
            operation: 'save',
            parameter,
        });
    }

    public saveTaxInvoiceSharedUrl(invNum: string, fileSharedLink: string) {
        return this.request<SaveOutputTaxSharedUrlRequest>({
            operation: 'saveInvoiceSharedUrl',
            parameter: {
                invNum,
                fileSharedLink,
            },
        });
    }

    public deleteTaxInvoice(parameter: DeleteParameter): Observable<any> {
        return this.request<DeleteOutputTaxRequest>({
            operation: 'delete',
            parameter,
        });
    }

    public getOutputTax(year: number, month: number): Observable<OutputTax[]> {
        return this.request<GetOutputTaxRequest>({
            operation: 'get',
            parameter: {
                year,
                month,
            },
        });
    }

    private request<T>(request: T): Observable<any> {
        return this.internalPostRequest(
            environment.outputTaxEndpoint,
            request
        ).pipe(
            map((response: AjaxResponse) => {
                if (!response.response.errorMessage) {
                    return response.response;
                } else {
                    throw new Error(
                        response.response.errorMessage || response.response
                    );
                }
            })
        );
    }
}
