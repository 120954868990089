import { Injectable } from '@angular/core';
import { AjaxResponse } from 'rxjs/ajax';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseRequester } from '../common/base-requester';
import {
    RenderPersistRequest,
    RenderPersistResponse,
} from './interfaces/render-persist-request';
@Injectable({
    providedIn: 'root',
})
export class RenderPersistReportService extends BaseRequester {
    constructor() {
        super();
    }

    public renderAndGetSharedUrl<T>(
        request: RenderPersistRequest<T>
    ): Observable<RenderPersistResponse> {
        return this.internalPostRequest(
            environment.renderPersistReportEndpoint,
            request
        ).pipe(
            map((response: AjaxResponse) => {
                if (!response.response.errorMessage) {
                    return response.response;
                } else {
                    throw new Error(
                        response.response.errorMessage || response.response
                    );
                }
            })
        );
    }
}
