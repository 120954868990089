import { NgModule, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { ShopeeFormatDateTimePipe } from './shopee-format-datetime.pipe';

@Pipe({
    name: 'formatRelativeDatetime',
})
export class FormatRelativeDatetimePipe implements PipeTransform {
    constructor(private formatDateTime: ShopeeFormatDateTimePipe) {}

    transform(value: unknown): unknown {
        const parsedObj: unknown = this.formatDateTime.tryParseToMoment(value);
        if (moment.isMoment(parsedObj)) {
            return (parsedObj as moment.Moment).fromNow();
        }
        return parsedObj;
    }
}

@NgModule({
    imports: [],
    declarations: [FormatRelativeDatetimePipe],
    exports: [FormatRelativeDatetimePipe],
})
export class FormatRelativeDatetimePipeModule {}
