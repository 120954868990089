import { Injectable } from '@angular/core';
import { chain as _chain, filter as _filter, find as _find } from 'lodash';

import { ThAddressData } from './th-address-data';
@Injectable({
    providedIn: 'root',
})
export class ThAddressSuggestorService {
    private allZipCodes: number[] = null;
    private allProvinces: string[] = null;

    constructor() {}

    getAllZipCodes(): number[] {
        if (!this.allZipCodes) {
            this.allZipCodes = _chain(ThAddressData.rawData)
                .map('zipcode')
                .uniq()
                .value() as number[];
        }
        return this.allZipCodes;
    }

    getAllProvinces() {
        if (!this.allProvinces) {
            this.allProvinces = _chain(ThAddressData.rawData)
                .map('province')
                .uniq()
                .value() as string[];
        }
        return this.allProvinces;
    }

    getProvinceByPostCode(postCode: number): string {
        const filteredByZipcode: any = _find(ThAddressData.rawData, {
            zipcode: postCode,
        });
        if (filteredByZipcode) {
            return filteredByZipcode.province;
        }
        return '';
    }

    getDistrictsByPostCode(postCode: number) {
        const filteredByZipcode: any[] = _filter(ThAddressData.rawData, {
            zipcode: postCode,
        });
        return _chain(filteredByZipcode).map('district').uniq().value();
    }

    getSubDistrictsByDistrict(postCode: number, district: string) {
        const filtered: any[] = _filter(ThAddressData.rawData, {
            zipcode: postCode,
            district,
        });
        return _chain(filtered).map('subDistrict').uniq().value();
    }

    getPostCode(
        province: string,
        district: string,
        subDistrict: string
    ): string | null {
        const result = _find(ThAddressData.rawData, {
            province,
            district,
            subDistrict,
        });
        if (result) {
            return result.zipcode.toString();
        }
        return null;
    }
}
