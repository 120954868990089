import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { UtilService } from '../services';
import { isNil as _isNil } from 'lodash';

@Pipe({
    name: 'formatNumber',
})
export class FormatNumberPipe implements PipeTransform {
    constructor(private utilService: UtilService) {}

    transform(value: any, ...args: any[]): string {
        if (_isNil(value)) {
            return '';
        }
        const inputNumber = this.utilService.localStringToNumber(value);
        return inputNumber.toLocaleString(undefined, {
            minimumFractionDigits: args[0] || 0,
            maximumFractionDigits: args[0] || 0,
        });
    }
}

@NgModule({
    imports: [],
    declarations: [FormatNumberPipe],
    exports: [FormatNumberPipe],
})
export class FormatNumberPipeModule {}
