import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-lazada-order-detail-template',
    templateUrl: './lazada-order-detail-template.component.html',
    styleUrls: ['./lazada-order-detail-template.component.scss'],
})
export class LazadaOrderDetailTemplateComponent implements OnInit {
    ngOnInit(): void {
        // T
    }
}
