import { Injectable } from '@angular/core';
import { BaseRequester } from '../common/base-requester';
import { switchMap, map } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import {
    get as _get,
    map as _map,
    isEmpty as _isEmpty,
    filter as _filter,
    includes as _includes,
    head as _head,
} from 'lodash';
import { Observable, of, forkJoin, throwError } from 'rxjs';
import { OrderStatus } from './enums/order-status';
import { AddressFlag } from './enums/address-flag';
import { ShopeeDelegateRequest } from './interfaces/shopee-delegate-request';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ShopeeOpenService extends BaseRequester {
    private delegateEndpoint = environment.shopeeDelegateEndPoint;

    constructor() {
        super();
    }

    getOrderSnsByStatus(orderStatus: OrderStatus): Observable<string[]> {
        const request: ShopeeDelegateRequest = {
            servicePath: 'orders/get',
            parameter: {
                order_status: orderStatus, // eslint-disable-line @typescript-eslint/naming-convention
            },
        };
        return this.internalPostRequest<ShopeeDelegateRequest>(
            this.delegateEndpoint,
            request
        ).pipe(
            map((ajaxRes: AjaxResponse) => {
                return _map(_get(ajaxRes, 'response.orders'), 'ordersn');
            })
        );
    }

    getOrderDetail(orderSnList: string[]): Observable<any[]> {
        if (!_isEmpty(orderSnList)) {
            const request: ShopeeDelegateRequest = {
                servicePath: 'orders/detail',
                parameter: {
                    ordersn_list: orderSnList, // eslint-disable-line @typescript-eslint/naming-convention
                },
            };
            return this.internalPostRequest<ShopeeDelegateRequest>(
                this.delegateEndpoint,
                request
            ).pipe(
                switchMap((ajaxRes: AjaxResponse) => {
                    const errors = _get(ajaxRes, 'response.errors');
                    if (!_isEmpty(errors)) {
                        return throwError(_get(ajaxRes, 'response'));
                    }
                    return of(_get(ajaxRes, 'response.orders'));
                })
            );
        } else {
            return of([]);
        }
    }

    getOrderEscrowDetails(orderSnList: string[]): Observable<any[]> {
        if (!_isEmpty(orderSnList)) {
            const observableList: Observable<any>[] = _map(
                orderSnList,
                (orderSn) => {
                    const request: ShopeeDelegateRequest = {
                        servicePath: 'orders/my_income',
                        parameter: {
                            ordersn: orderSn,
                        },
                    };
                    return this.internalPostRequest<ShopeeDelegateRequest>(
                        this.delegateEndpoint,
                        request
                    ).pipe(
                        switchMap((ajaxRes: AjaxResponse) => {
                            const error = _get(ajaxRes, 'response.error');
                            if (error) {
                                return throwError(
                                    _get(ajaxRes, 'response.msg')
                                );
                            }
                            return of(_get(ajaxRes, 'response.order'));
                        })
                    );
                }
            );
            return forkJoin(observableList);
        } else {
            return of([]);
        }
    }

    getOrderByStatusIncludingDetails(
        orderStatus: OrderStatus
    ): Observable<any[]> {
        return this.getOrderSnsByStatus(orderStatus).pipe(
            switchMap((orderSnList: string[]) => {
                return this.getOrderDetail(orderSnList);
            })
        );
    }

    getPickupAddress() {
        const request: ShopeeDelegateRequest = {
            servicePath: 'logistics/address/get',
            parameter: {},
        };
        return this.internalPostRequest<ShopeeDelegateRequest>(
            this.delegateEndpoint,
            request
        ).pipe(
            switchMap((ajaxRes: AjaxResponse) => {
                const error = _get(ajaxRes, 'response.error');
                if (error) {
                    return throwError(_get(ajaxRes, 'response.msg'));
                }
                const addressList = _get(ajaxRes, 'response.address_list');
                const pickupAddress = _filter(addressList, (address) => {
                    return _includes(address.address_flag, AddressFlag.Pickup);
                });
                return of(_head(pickupAddress));
            })
        );
    }

    getTimeSlot(orderSn: string, addressId: number) {
        const request: ShopeeDelegateRequest = {
            servicePath: 'logistics/timeslot/get',
            parameter: {
                ordersn: orderSn,
                address_id: addressId, // eslint-disable-line @typescript-eslint/naming-convention
            },
        };
        return this.internalPostRequest<ShopeeDelegateRequest>(
            this.delegateEndpoint,
            request
        ).pipe(
            switchMap((ajaxRes: AjaxResponse) => {
                const error = _get(ajaxRes, 'response.error');
                if (error) {
                    return throwError(_get(ajaxRes, 'response.msg'));
                }
                return of(_get(ajaxRes, 'response.pickup_time'));
            })
        );
    }

    getParameterForInit(orderSnList: string[]): Observable<AjaxResponse[]> {
        const observableList: Observable<AjaxResponse>[] = _map(
            orderSnList,
            (orderSn: string) => {
                const request: ShopeeDelegateRequest = {
                    servicePath: 'logistics/init_parameter/get',
                    parameter: {
                        ordersn: orderSn,
                    },
                };
                return this.internalPostRequest<ShopeeDelegateRequest>(
                    this.delegateEndpoint,
                    request
                ).pipe(
                    switchMap((ajaxRes: AjaxResponse) => {
                        const error = _get(ajaxRes, 'response.error');
                        if (error) {
                            return throwError(_get(ajaxRes, 'response.msg'));
                        }
                        return of(_get(ajaxRes, 'response'));
                    })
                );
            }
        );
        return forkJoin(observableList);
    }

    initLogisticPickup(
        orderSn: string,
        addressId: number,
        pickupTimeId: string
    ) {
        const request: ShopeeDelegateRequest = {
            servicePath: 'logistics/init',
            parameter: {
                ordersn: orderSn,
                pickup: {
                    address_id: addressId, // eslint-disable-line @typescript-eslint/naming-convention
                    pickup_time_id: pickupTimeId, // eslint-disable-line @typescript-eslint/naming-convention
                },
            },
        };
        return this.internalPostRequest<ShopeeDelegateRequest>(
            this.delegateEndpoint,
            request
        ).pipe(
            switchMap((ajaxRes: AjaxResponse) => {
                const error = _get(ajaxRes, 'response.error');
                if (error) {
                    return throwError(_get(ajaxRes, 'response.msg'));
                }
                return of(_get(ajaxRes, 'response'));
            })
        );
    }

    initLogisticDropOff(orderSn: string) {
        const request: ShopeeDelegateRequest = {
            servicePath: 'logistics/init',
            parameter: {
                ordersn: orderSn,
                dropoff: {},
            },
        };
        return this.internalPostRequest<ShopeeDelegateRequest>(
            this.delegateEndpoint,
            request
        ).pipe(
            switchMap((ajaxRes: AjaxResponse) => {
                const error = _get(ajaxRes, 'response.error');
                if (error) {
                    return throwError(_get(ajaxRes, 'response.msg'));
                }
                return of(_get(ajaxRes, 'response'));
            })
        );
    }
}
