import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BaseRequester } from './common/base-requester';

@Injectable({
    providedIn: 'root',
})
export class UnifiedExecService extends BaseRequester {
    constructor() {
        super();
    }

    requestExec(funcName: string, payload?: any) {
        return this.internalPostRequest<any>(environment.unifiedExecEndPoint, {
            /* eslint-disable @typescript-eslint/naming-convention */
            FunctionName: funcName,
            InvocationType: 'RequestResponse',
            Payload: payload,
        });
    }
}
