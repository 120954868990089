import { Injectable } from '@angular/core';
import { AjaxResponse } from 'rxjs/ajax';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseRequester } from './common/base-requester';

@Injectable({
    providedIn: 'root',
})
export class UnifiedQueryV2Service extends BaseRequester {
    constructor() {
        super();
    }
    query<T>(request: UnifiedQueryRequestV2): Observable<T[]> {
        return this.internalPostRequest<UnifiedQueryRequestV2>(
            environment.unifiedQueryV2EndPoint,
            request
        ).pipe(
            map((response: AjaxResponse) => {
                if (response.response.errorMessage) {
                    throw new Error(
                        `[${response.response.errorType}]: ${response.response.errorMessage}`
                    );
                } else {
                    return response.response;
                }
            })
        );
    }
}

export interface UnifiedQueryRequestV2 {
    db: 'arduino4';
    col: 'inventory' | 'counterparties';
    query: object;
    options?: object;
}
