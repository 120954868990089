import {
    Component,
    AfterViewInit,
    ChangeDetectorRef,
    HostListener,
    ViewChild,
} from '@angular/core';
import { confirm } from 'devextreme/ui/dialog';
import moment, { Moment } from 'moment';
import {
    debounce as _debounce,
    DebouncedFunc,
    each as _each,
    map as _map,
    toNumber as _toNumber,
    includes as _includes,
    toString as _toString,
    reduce as _reduce,
    isFinite as _isFinite,
    get as _get,
    isEmpty as _isEmpty,
    isString as _isString,
    isNumber as _isNumber,
    trim as _trim,
} from 'lodash';
import {
    ThAddressSuggestorService,
    PrintSheetService,
    NotificationService,
    UtilService,
    MessageType,
    RenderPersistReportService,
} from 'src/app/shared/services';
import { MessageService } from '../../services/message.service';
import {
    getInitialInvoiceInput,
    ProductInfo,
    TaxInvoiceInput,
} from '../../models/invoice-input';
import { branchList, sellerInfo } from '../../models/constants';
import {
    Item,
    TaxInvoicePrintSheetParameter,
} from 'src/app/shared/services/print-sheet/interfaces/tax-invoice-param';
import { ReportApiParameter } from 'src/app/shared/services/print-sheet/interfaces/report-api-request';
import { environment } from 'src/environments/environment';
import { DxValidationGroupComponent } from 'devextreme-angular';
import { OutputTaxService } from '../../services/output-tax/tax-output.service';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import {
    OrderItem,
    OutputTax,
} from '../../services/output-tax/interfaces/output-tax-request';
import { map, switchMap } from 'rxjs/operators';
import { ClickEvent } from 'devextreme/ui/button';
import { bahttext } from 'bahttext';

@Component({
    selector: 'app-create-tax-invoice',
    templateUrl: './create-tax-invoice.component.html',
    styleUrls: ['./create-tax-invoice.scss'],
})
export class CreateTaxInvoiceComponent implements AfterViewInit {
    @ViewChild('validationGroup', { static: true })
    validationGroup: DxValidationGroupComponent;

    branchList = branchList;
    postCodeSuggestions = [];
    provinceSuggestions = [];
    districtSuggestions = [];
    subDistrictSuggestions = [];

    shareData: {
        fileName: string;
        sharedUrl: string;
    } = null;
    isExporting = false;
    requestData: string;
    messageSourceWindow: Window = null;

    inputValues: TaxInvoiceInput = getInitialInvoiceInput();
    onInputsUpdated: DebouncedFunc<() => void>;

    private previousSuggestionSelection = '';

    constructor(
        private cdref: ChangeDetectorRef,
        private thAddressSuggestor: ThAddressSuggestorService,
        private printSheetService: PrintSheetService,
        private renderPersistService: RenderPersistReportService,
        private messageService: MessageService,
        private utilService: UtilService,
        private notificationService: NotificationService,
        private outputTaxService: OutputTaxService
    ) {
        this.onInputsUpdated = _debounce(this.internalOnInputsUpdated, 500);
        this.onReorder = this.onReorder.bind(this);
    }

    @HostListener('window:message', ['$event'])
    onMessage(event): void {
        const responseStatus: string = _get(event, 'data.status');
        if (responseStatus === 'ready') {
            this.messageSourceWindow = event.source;
        } else if (responseStatus === 'data' && event.data.data) {
            this.messageService.fillInputFromMessage(
                event.data.data,
                this.inputValues
            );
            this.cdref.detectChanges();
            this.notificationService.pushMessage({
                message: 'Lnwshop data is loaded',
                messageType: MessageType.success,
            });
        } else if (responseStatus === 'error') {
            this.notificationService.pushMessage({
                message: `Lnwshop Data loading failed with responsed error: ${event.data.message}`,
                messageType: MessageType.error,
            });
        }
    }

    requestMessage(): void {
        if (this.messageSourceWindow) {
            this.messageSourceWindow.postMessage(
                {
                    status: 'request',
                },
                '*'
            );
        } else {
            console.error('No connection');
        }
    }

    ngAfterViewInit(): void {
        this.postCodeSuggestions = this.thAddressSuggestor.getAllZipCodes();
        this.provinceSuggestions = this.thAddressSuggestor.getAllProvinces();
        this.fillInitialValues(this.inputValues);
        // DEBUG Message
        // this.messageService.fillInputFromMessage(a, this.inputValues);
        // this.cdref.detectChanges();
    }

    clearForm(): void {
        confirm('Filled data will be reset.', 'Confirm Clear Data ?').then(
            (result: boolean) => {
                if (result) {
                    this.inputValues = getInitialInvoiceInput();
                    this.fillInitialValues(this.inputValues);
                    this.validationGroup.instance?.reset();
                    this.notificationService.pushMessage({
                        message: 'Form is cleared.',
                        messageType: MessageType.info,
                    });
                }
            }
        );
    }

    showPreviewPdf(isCopy = false): void {
        if (!this.isExporting) {
            this.constructFullAddress();
            this.calculatePrices();
            this.cdref.detectChanges();
            const request: TaxInvoicePrintSheetParameter =
                this.constructTaxInvoicePrintSheetRequest(
                    'INV0000000000',
                    isCopy
                );
            let tabTitle = 'ใบกำกับภาษี';
            if (request.orderInfo.invNum) {
                tabTitle = request.orderInfo.invNum;
            }
            this.isExporting = true;
            this.printSheetService
                .printTaxInvoice([request], tabTitle)
                .subscribe(
                    () => {
                        this.isExporting = false;
                        this.notificationService.pushMessage({
                            message: 'Tax invoice is successfully exported.',
                            messageType: MessageType.success,
                        });
                    },
                    (error) => {
                        this.isExporting = false;
                        console.error(error);
                        this.notificationService.pushMessage({
                            message: this.utilService.errorToString(error),
                            messageType: MessageType.error,
                        });
                    }
                );
        }
    }

    saveAndShare(e: ClickEvent): void {
        if (!this.isExporting) {
            this.constructFullAddress();
            this.calculatePrices();
            this.cdref.detectChanges();
            const result = e.validationGroup.validate();
            if (_isEmpty(this.inputValues.productItems)) {
                this.notificationService.pushMessage({
                    message: 'ไม่มีรายการสินค้า',
                    messageType: MessageType.error,
                });
                return;
            } else if (!this.validateProducts()) {
                this.notificationService.pushMessage({
                    message: 'ข้อมูลรายการสินค้าไม่ครบถ้วน',
                    messageType: MessageType.error,
                });
                return;
            } else if (result.isValid) {
                this.shareData = null;
                this.isExporting = true;

                let usingInvNum: string;
                const saveRequest = this.constructInvoiceSaveRequest();
                this.outputTaxService
                    .saveTaxInvoice(saveRequest)
                    .pipe(
                        switchMap((response) => {
                            if (response.status === 200 && response.invNum) {
                                usingInvNum = response.invNum;
                                return this.createTaxInvoiceAndGetSharedUrl(
                                    usingInvNum,
                                    this.inputValues.invoiceInfo.includeCopy
                                );
                            }
                            return throwError('Save invoice failed.');
                        }),
                        map(([originalSharedUrlResponse]) => {
                            this.shareData = {
                                fileName: originalSharedUrlResponse.name,
                                sharedUrl:
                                    originalSharedUrlResponse.webViewLink,
                            };
                        }),
                        switchMap(() => {
                            return this.outputTaxService.saveTaxInvoiceSharedUrl(
                                usingInvNum,
                                this.shareData.sharedUrl
                            );
                        }),
                        switchMap((savedSharedUrlResult) => {
                            if (
                                _get(savedSharedUrlResult, 'modifiedCount', 0) >
                                0
                            ) {
                                return of(true);
                            }
                            return throwError('Shared url cannot be save.');
                        })
                    )
                    .subscribe(
                        () => {
                            this.isExporting = false;
                            this.openSharedUrl();
                            this.notificationService.pushMessage({
                                message: `${this.shareData.fileName} is saved.`,
                                messageType: MessageType.success,
                            });
                        },
                        (error) => {
                            this.isExporting = false;
                            console.error(error);
                            this.notificationService.pushMessage({
                                message: this.utilService.errorToString(error),
                                messageType: MessageType.error,
                            });
                        }
                    );
            }
        }
    }

    private validateProducts() {
        const strRequiredFields = ['sku', 'description'];
        const numRequiredFields = [
            'pid',
            'itemNo',
            'unitPrice',
            'quantity',
            'amount',
        ];

        let isStrValid = true;
        _each(this.inputValues.productItems, (product: ProductInfo) => {
            _each(strRequiredFields, (field: string) => {
                const fieldValue: any = _isString(product[field])
                    ? _trim(product[field])
                    : product[field];
                isStrValid = _isString(fieldValue) && !_isEmpty(fieldValue);
                return isStrValid;
            });
        });

        let isNumValid = true;
        _each(this.inputValues.productItems, (product: ProductInfo) => {
            _each(numRequiredFields, (field: string) => {
                isNumValid =
                    _isNumber(product[field]) && _isFinite(product[field]);
                return isNumValid;
            });
        });

        return isStrValid && isNumValid;
    }

    private createTaxInvoiceAndGetSharedUrl(
        invNum: string,
        includeCopy: boolean
    ): Observable<any> {
        const originalRequest: TaxInvoicePrintSheetParameter =
            this.constructTaxInvoicePrintSheetRequest(invNum);
        const payload: ReportApiParameter<TaxInvoicePrintSheetParameter> = {
            renderRequest: {
                data: { pages: [originalRequest] },
                template: {
                    name: environment.taxInvoiceReportTemplateName,
                },
            },
        };
        const obList: Observable<any>[] = [
            this.renderPersistService.renderAndGetSharedUrl<TaxInvoicePrintSheetParameter>(
                {
                    renderFunctionName: 'jsreport-invoice:production',
                    renderRequestPayload: payload,
                    reportStoreRequest: {
                        fileName: invNum ? `${invNum}.pdf` : 'untitled.pdf',
                        parents: ['1D7z7NjXZmyB8zH_EWb5E8D_rkij3aa4q'],
                    },
                }
            ),
        ];

        if (includeCopy) {
            const copyRequest: TaxInvoicePrintSheetParameter =
                this.constructTaxInvoicePrintSheetRequest(invNum, true);
            const copyPayload: ReportApiParameter<TaxInvoicePrintSheetParameter> =
                {
                    renderRequest: {
                        data: { pages: [copyRequest] },
                        template: {
                            name: environment.taxInvoiceReportTemplateName,
                        },
                    },
                };
            obList.push(
                this.renderPersistService.renderAndGetSharedUrl<TaxInvoicePrintSheetParameter>(
                    {
                        renderFunctionName: 'jsreport-invoice:production',
                        renderRequestPayload: copyPayload,
                        reportStoreRequest: {
                            fileName: invNum
                                ? `${invNum} (สำเนา).pdf`
                                : 'untitled (สำเนา).pdf',
                            parents: ['1Fkw7iMBx_itwsqRb1Z4f0H9nNnuGz9Ll'],
                        },
                    }
                )
            );
        }
        return forkJoin(obList);
    }

    openSharedUrl(): void {
        const sharedUrl = _get(this.shareData, 'sharedUrl');
        if (sharedUrl) {
            window.open(sharedUrl, '_blank');
        }
    }

    copySharedUrlToClibboard(): void {
        const sharedUrl = _get(this.shareData, 'sharedUrl');
        if (sharedUrl) {
            navigator.clipboard.writeText(sharedUrl).then(
                () => {
                    this.notificationService.pushMessage({
                        message: 'URL copied to clipboard.',
                        messageType: MessageType.success,
                        displayTime: 100,
                    });
                },
                (e) => {
                    console.error(e);
                }
            );
        }
    }

    calculateProductsAmount(rowData: ProductInfo): number {
        const unitPrice = _get(rowData, 'unitPrice');
        const quantity = _get(rowData, 'quantity');
        if (_isFinite(unitPrice) && _isFinite(quantity)) {
            return rowData.unitPrice * rowData.quantity;
        }
        return 0;
    }

    onSaved(): void {
        this.generateProductOrderNumber();
        this.onInputsUpdated();
    }

    onReorder(e): void {
        const visibleRows = e.component.getVisibleRows();
        const toIndex = this.inputValues.productItems.indexOf(
            visibleRows[e.toIndex].data
        );
        const fromIndex = this.inputValues.productItems.indexOf(e.itemData);
        this.inputValues.productItems.splice(fromIndex, 1);
        this.inputValues.productItems.splice(toIndex, 0, e.itemData);
        this.generateProductOrderNumber();
    }

    private fillInitialValues(input: TaxInvoiceInput): void {
        input.invoiceInfo.date = moment().toDate();
        this.shareData = null;
        this.cdref.detectChanges();
    }

    private formatDisplayDate(input: Date, abb?: boolean): string {
        if (input) {
            const momentObj = moment(input);
            momentObj.locale('th');
            momentObj.add(543, 'year').format('LLLL');
            const format = abb ? 'DD/MM/YYYY' : 'DD MMMM YYYY';
            return momentObj.format(format);
        }
        return '';
    }

    private formatString(input: any): string {
        return _toString(input).trim();
    }

    private constructInvoiceSaveRequest(): OutputTax {
        const invoiceMoment: Moment = moment(this.inputValues.invoiceInfo.date);
        const param: OutputTax = {
            fileSharedLink: null,
            'sellerInfo.name': sellerInfo.name,
            'sellerInfo.branch': sellerInfo.branch,
            'sellerInfo.taxId': sellerInfo.taxId,
            'sellerInfo.website': sellerInfo.website,
            'sellerInfo.address': sellerInfo.address,
            'sellerInfo.email': sellerInfo.email,
            'customerInfo.name': this.formatString(
                this.inputValues.customerInfo.name
            ),
            'customerInfo.branch': this.formatString(
                this.inputValues.customerInfo.branch
            ),
            'customerInfo.address': this.formatString(
                this.inputValues.customerInfo.useCustomAddress
                    ? this.inputValues.customerInfo.customAddress
                    : this.inputValues.customerInfo.fullAddress
            ),
            'customerInfo.taxId': this.formatString(
                this.inputValues.customerInfo.taxId
            ),
            'orderInfo.invNum': this.inputValues.invoiceInfo.isEdit
                ? this.inputValues.invoiceInfo.invNum
                : null,
            'orderInfo.orderNo': _toNumber(
                this.inputValues.invoiceInfo.refOrderNum
            ),
            'orderInfo.date': invoiceMoment.isValid()
                ? _toNumber(invoiceMoment.format('YYYYMMDD'))
                : null,
            'orderInfo.dateD': invoiceMoment.isValid()
                ? _toNumber(invoiceMoment.format('D'))
                : null,
            'orderInfo.dateM': invoiceMoment.isValid()
                ? _toNumber(invoiceMoment.format('M'))
                : null,
            'orderInfo.dateY': invoiceMoment.isValid()
                ? _toNumber(invoiceMoment.format('YYYY'))
                : null,
            'orderInfo.totalProductPrice':
                this.inputValues.invoiceInfo.totalProductPrice || 0,
            'orderInfo.discount': this.inputValues.invoiceInfo.discount || 0,
            'orderInfo.shippingCost':
                this.inputValues.invoiceInfo.shippingCost || 0,
            'orderInfo.amtBeforeVat':
                this.inputValues.invoiceInfo.amtBeforeVat || 0,
            'orderInfo.totalVat': this.inputValues.invoiceInfo.vatAmount || 0,
            'orderInfo.netPrice': this.inputValues.invoiceInfo.netPrice || 0,
            'orderInfo.remark': this.formatString(
                this.inputValues.invoiceInfo.remark
            ),
            items: _map<ProductInfo, OrderItem>(
                this.inputValues.productItems,
                (item: ProductInfo): OrderItem => {
                    return {
                        pid: item.pid,
                        sku: item.sku,
                        itemNo: item.itemNo,
                        description: this.formatString(item.description),
                        unitPrice: item.unitPrice,
                        quantity: item.quantity,
                        amount: item.amount,
                    };
                }
            ),
        };
        return param;
    }

    private constructTaxInvoicePrintSheetRequest(
        invNum: string,
        isCopy = false
    ): TaxInvoicePrintSheetParameter {
        const title = this.constructTitle(isCopy);
        const requestParam: TaxInvoicePrintSheetParameter = {
            sellerInfo: {
                name: sellerInfo.name,
                branch: sellerInfo.branch,
                taxId: sellerInfo.taxId,
                website: sellerInfo.website,
                address: sellerInfo.address,
                email: sellerInfo.email,
            },
            customerInfo: {
                name: this.formatString(this.inputValues.customerInfo.name),
                branch: this.formatString(this.inputValues.customerInfo.branch),
                address: this.formatString(
                    this.inputValues.customerInfo.useCustomAddress
                        ? this.inputValues.customerInfo.customAddress
                        : this.inputValues.customerInfo.fullAddress
                ),
                taxId: this.formatString(this.inputValues.customerInfo.taxId),
            },
            orderInfo: {
                invNum,
                orderNo: _toString(this.inputValues.invoiceInfo.refOrderNum),
                date: this.formatDisplayDate(this.inputValues.invoiceInfo.date),
                totalExclVat: this.inputValues.invoiceInfo.amtBeforeVat || 0,
                totalVat: this.inputValues.invoiceInfo.vatAmount || 0,
                remark: this.formatString(this.inputValues.invoiceInfo.remark),
                total: this.inputValues.invoiceInfo.totalProductPrice || 0,
                discount: this.inputValues.invoiceInfo.discount || 0,
                shippingCost: this.inputValues.invoiceInfo.shippingCost || 0,
                netPrice: this.inputValues.invoiceInfo.netPrice || 0,
                netPriceInWords: _isFinite(
                    this.inputValues.invoiceInfo.netPrice
                )
                    ? bahttext(this.inputValues.invoiceInfo.netPrice)
                    : '',
                sellerSignDate: this.formatDisplayDate(
                    this.inputValues.invoiceInfo.date,
                    true
                ),
            },
            docInfo: {
                hideSellerSignature:
                    !this.inputValues.invoiceInfo.showSellerSignature,
                hideSignatureFields:
                    !this.inputValues.invoiceInfo.showSignatureFields,
                title1: title.h1,
                title2: title.h2,
                title3: title.h3,
            },
            items: _map<ProductInfo, Item>(
                this.inputValues.productItems,
                (item: ProductInfo): Item => {
                    return {
                        itemNo: item.itemNo,
                        description: this.formatString(item.description),
                        unitPrice: item.unitPrice,
                        quantity: item.quantity,
                        amount: item.amount,
                    };
                }
            ),
        };
        return requestParam;
    }

    private internalOnInputsUpdated(): void {
        this.suggestAddressIfNeeded();
        this.constructFullAddress();
        this.calculatePrices();
        this.cdref.detectChanges();
    }

    private constructTitle(isCopy: boolean) {
        let h1 = '';
        let h2 = '';
        let h3 = isCopy ? 'สำเนา' : 'ต้นฉบับ';

        const h1List = [];
        const h2List = [];
        if (
            this.inputValues.invoiceInfo.isDeliveryNote ||
            this.inputValues.invoiceInfo.isTaxInvoice ||
            this.inputValues.invoiceInfo.isReceipt
        ) {
            if (this.inputValues.invoiceInfo.isDeliveryNote) {
                h1List.push('ใบส่งสินค้า');
                h2List.push('Delivery Note');
            }
            if (this.inputValues.invoiceInfo.isTaxInvoice) {
                h1List.push('ใบกำกับภาษี');
                h2List.push('Tax Invoice');
            }
            if (this.inputValues.invoiceInfo.isReceipt) {
                h1List.push('ใบเสร็จรับเงิน');
                h2List.push('Receipt');
            }
            h1 = h1List.join(' / ');
            h2 = `(${h2List.join(' / ')})`;
        }
        if (h1List.length > 1) {
            h3 = `${h3} (เอกสารออกเป็นชุด)`;
        }

        const userTitle1: string = this.inputValues.invoiceInfo.title1;
        const userTitle2: string = this.inputValues.invoiceInfo.title2;
        const userTitle3: string = this.inputValues.invoiceInfo.title3;
        if (userTitle1) {
            h1 = userTitle1;
        }
        if (userTitle2) {
            h2 = userTitle2;
        }
        if (userTitle3) {
            h3 = userTitle3;
        }
        return {
            h1,
            h2,
            h3,
        };
    }

    private calculatePrices(): void {
        _each(this.inputValues.productItems, (prduct: ProductInfo) => {
            if (_isFinite(prduct.unitPrice) && _isFinite(prduct.quantity)) {
                prduct.amount = prduct.unitPrice * prduct.quantity;
            } else {
                prduct.amount = 0;
            }
        });
        const productPrice: number = _reduce(
            this.inputValues.productItems,
            (total: number, item) => {
                const amountNum = _toNumber(item.amount);
                if (_isFinite(amountNum)) {
                    return total + item.amount;
                }
                return total;
            },
            0
        );
        this.inputValues.invoiceInfo.totalProductPrice = productPrice;

        const shippingCost =
            _toNumber(this.inputValues.invoiceInfo.shippingCost) || 0;
        const discount = _toNumber(this.inputValues.invoiceInfo.discount) || 0;

        const amtBeforeVat: number = productPrice + shippingCost - discount;
        this.inputValues.invoiceInfo.amtBeforeVat = amtBeforeVat;

        let vatAmount = _toNumber(amtBeforeVat) * 0.07 || 0;
        vatAmount = Math.round((vatAmount + Number.EPSILON) * 100) / 100;
        this.inputValues.invoiceInfo.vatAmount = vatAmount;

        const netPrice: number = amtBeforeVat + vatAmount;
        this.inputValues.invoiceInfo.netPrice = netPrice;
    }

    private suggestAddressIfNeeded(): void {
        const selectedPostCode =
            _toString(this.inputValues.customerInfo.postCode) || '';
        const selectedProvince = this.inputValues.customerInfo.province || '';
        const selectedDistrict = this.inputValues.customerInfo.district || '';

        const currentSuggestionSelection =
            selectedPostCode + selectedProvince + selectedDistrict;

        if (this.previousSuggestionSelection !== currentSuggestionSelection) {
            this.previousSuggestionSelection = currentSuggestionSelection;
            if (selectedPostCode.length === 5) {
                const selectedPostCodeNum = _toNumber(selectedPostCode);
                const province: string =
                    this.thAddressSuggestor.getProvinceByPostCode(
                        selectedPostCodeNum
                    );
                this.provinceSuggestions = [province];

                if (selectedProvince) {
                    const districts: string[] =
                        this.thAddressSuggestor.getDistrictsByPostCode(
                            selectedPostCodeNum
                        );

                    this.districtSuggestions = districts;
                } else {
                    this.districtSuggestions = [];
                }

                if (selectedDistrict) {
                    const subDistricts: string[] =
                        this.thAddressSuggestor.getSubDistrictsByDistrict(
                            selectedPostCodeNum,
                            selectedDistrict
                        );

                    this.subDistrictSuggestions = subDistricts;
                } else {
                    this.subDistrictSuggestions = [];
                }
            } else {
                this.provinceSuggestions =
                    this.thAddressSuggestor.getAllProvinces();
            }
        }
    }

    private constructFullAddress(): void {
        const address1 = this.inputValues.customerInfo.address1?.trim();
        const subDistrict = this.inputValues.customerInfo.subDistrict?.trim();
        const district = this.inputValues.customerInfo.district?.trim();
        const province = this.inputValues.customerInfo.province?.trim();
        const postCode = _toString(
            this.inputValues.customerInfo.postCode
        ).trim();
        let result = '';
        if (address1) {
            result += address1;
        }
        const isBangkok = _includes(province, 'กรุงเทพ');
        if (subDistrict) {
            result += isBangkok ? ' แขวง' : ' ตำบล';
            result += subDistrict;
        }
        if (district) {
            result += isBangkok ? ' เขต' : ' อำเภอ';
            result += district;
        }
        if (province) {
            result += ` จังหวัด${province}`;
        }
        if (postCode) {
            result += ` ${postCode}`;
        }
        result = result.trim();
        this.inputValues.customerInfo.fullAddress = result;
    }

    private generateProductOrderNumber(): void {
        _each(this.inputValues.productItems, (item, index) => {
            item.itemNo = index + 1;
        });
    }
}

/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const a = {
    id: '46351',
    id_display: '46351',
    jtype: 'manage',
    product_quantity: '1',
    net_price: 225,
    price: '225.00',
    lnwpoint: 0,
    lnwpoint_price: 0,
    pay_price: 225,
    cod_flag: 0,
    cod_amount: null,
    lnwpay_flag: 0,
    is_lnwpay: 0,
    is_lnwpay_lite: 0,
    owner_create_flag: 2,
    channel: 'lnwshop',
    lnwmallx_channel: null,
    channel_data: {
        ukey: null,
        kind: 'custom',
        id: null,
        key: null,
        name: '- ไม่ระบุ -',
    },
    referral_data: null,
    shop_channel: false,
    stock_channel_id: '1',
    is_fulfillment: 0,
    is_dealer: 0,
    lang_key: 'th',
    status: 'cart',
    state: 'wait_order',
    stage: 'wait_order',
    suspended: 0,
    detail: null,
    staff_user: {
        id: '1938265',
        name: 'disorn.th',
        type: 'user',
        ip: '183.88.58.84',
        time: '2022-07-31 21:18:28',
    },
    channel_order_id: null,
    state_steps: [
        { key: 'wait_order', time: null, tense: 'present' },
        { key: 'wait_payment', time: null, tense: 'future' },
        { key: 'wait_payment_verify', time: null, tense: 'future' },
        { key: 'wait_send', time: null, tense: 'future' },
        { key: 'completed', time: null, tense: 'future' },
    ],
    real_lnwpay_flag: '0',
    order_time: '2022-07-31 21:18:28',
    cart_time: '2022-07-31 21:18:28',
    order_time_txt: '31 กรกฎาคม 2565',
    expire_time: '2022-08-03 21:18:28',
    expire_time_txt: '3 สิงหาคม 2565 เวลา 21:18 น.',
    cc_url: 'https://v1.lnw.me/order/341932/46351?s=1d7ef4db',
    cc_url_v2: 'https://lnw.me/order/arduino4/46351?s=1d7ef4db',
    first_expired_time: false,
    product_weight: 10,
    payment_time_txt: null,
    payment: null,
    is_no_shipping: 0,
    shipping_type_id: '3',
    shipping_type_text: 'พัสดุด่วนพิเศษ (EMS)',
    shipping_type_provider: ['thailandpost'],
    shipping_type_providers: ['thailandpost'],
    deadline_shipping_time: null,
    shipping_time: null,
    allow_marketing_flag: null,
    prepare_duration: '1',
    prepare_weekday: '1',
    customer: 0,
    receiver: {
        id: '24017050',
        key: 'receiver',
        kind: 'receiver',
        full_name: 'เจษฎา ทวีพิริยะ',
        name: 'เจษฎา ทวีพิริยะ',
        email: 'arduino4.shop@gmail.com',
        mobile: '0859093746',
        address: '58 / 6 หมู่ 4 ถนนสุขุมวิท',
        country_code: null,
        country: null,
        province_id: '12',
        province: 'ระยอง',
        district_id: '144',
        district: 'แกลง',
        subdistrict_id: '1048',
        subdistrict: 'ชากโดน',
        zipcode: '21110',
        telephone: '0859093746',
        latitude: null,
        longitude: null,
        thai_type: 1,
        full_address:
            '58 / 6 หมู่ 4 ถนนสุขุมวิท ตำบลชากโดน อำเภอแกลง จังหวัดระยอง 21110',
        letter_address:
            '58 / 6 หมู่ 4 ถนนสุขุมวิท ตำบลชากโดน อำเภอแกลง จังหวัดระยอง',
    },
    receiver_name: 'เจษฎา ทวีพิริยะ',
    receiver_email: 'arduino4.shop@gmail.com',
    receiver_mobile: '0859093746',
    receive_sms_flag: 0,
    receive_email_flag: 0,
    dealer: null,
    receipt: {
        id: '23385133',
        key: 'guest_receipt',
        kind: 'receipt',
        full_name: 'บริษัท พีเอซี เอเชีย ซัพพลาย จำกัด',
        name: 'บริษัท พีเอซี เอเชีย ซัพพลาย จำกัด',
        address:
            '993/200 หมู่ที่ 6 ตำบลแพรกษา อำเภอเมืองสมุทรปราการ จังหวัดสมุทรปราการ 10280',
        tax_id: '0115557008224',
        branch_id: '00000',
    },
    products: [
        {
            id: '787',
            name: 'กล่องขับมอเตอร์ DC 12-48V PWM DC Motor Speed Controller Meimotor High Power 10A',
            title: 'กล่องขับมอเตอร์ DC 12-48V PWM DC Motor Speed Controller Meimotor High Power 10A',
            quantity: '1',
            detail: '',
            url: 'https://www.arduino4.com/product/787',
            thumbnail:
                'https://dm.lnwfile.com/_/dm/_resize/100/100/wz/sb/8s.jpg',
            price: '210.00',
            amount: '210.00',
            images: 'https://dm.lnwfile.com/_/dm/_resize/200/200/wz/sb/8s.jpg',
            alive: 1,
            parent_id: 0,
            parent_name: null,
            parent_image: null,
            code: 'D77',
        },
    ],
    promotion: [
        {
            position: 'after_shipping',
            name: 'ส่วนลดท้ายบิล',
            discount: '40.0000',
        },
    ],
    discounts: [
        {
            type: 'custom',
            position: 'after_shipping',
            name: 'ส่วนลดท้ายบิล',
            price: '40.0000',
        },
    ],
    after_product_discounts: [],
    after_shipping_discounts: [
        {
            type: 'custom',
            position: 'after_shipping',
            name: 'ส่วนลดท้ายบิล',
            price: '40.0000',
        },
    ],
    product_price: '210.0000',
    discount_price: '40.0000',
    shipping_price: '55.0000',
    note: null,
    can_cancel: false,
    can_remove: true,
    can_refund: false,
    can_restore: false,
    can_inform_payment: true,
    can_edit_expire_time: true,
    can_edit_receiver_contact: true,
    can_edit_dealer_contact: false,
    can_edit_receipt_contact: true,
    can_edit_product: true,
    can_confirm_fulfillment_shipping: false,
    can_hold_fulfillment_shipping: false,
    can_cancel_fulfillment_shipping: false,
    can_shipping: false,
    can_edit_shipping: false,
    can_add_track_code: false,
    can_remove_track_code: false,
    can_edit_shipping_description: false,
    can_undo_sending: false,
    payment_provider_code: null,
    is_lnwpay_refundable: false,
    invoice_valid_flag: '1',
    is_wait_create_invoice: false,
    is_wait_update_invoice: false,
    trackcodes: [],
    shipping_description: null,
    lnwpay_payment_fee: null,
    display_payment_fee: false,
    display_shipping_fee: false,
    display_fulfillment_fee: false,
    display_fee: false,
    is_shipping_type_pubat: false,
};
