<dx-load-panel
    #loadPanel
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: '#mainPanel' }"
    [visible]="isExporting"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
>
</dx-load-panel>

<dx-validation-group #validationGroup>
    <div #mainPanel class="main dx-card padding-content">
        <dx-box direction="row" crossAlign="center">
            <dxi-item [ratio]="4">
                <div style="text-align: start">
                    <dx-button
                        [disabled]="isExporting || !messageSourceWindow"
                        stylingMode="outlined"
                        icon="download"
                        type="normal"
                        text="Load Lnwshop Data"
                        (onClick)="requestMessage()"
                    ></dx-button>
                </div>
            </dxi-item>
            <dxi-item [ratio]="9" style="text-align: center">
                <span style="font-size: x-large">ใบกำกับภาษี</span>
            </dxi-item>
            <dxi-item
                [ratio]="3"
                style="padding-right: 10px; text-align: right"
            >
                <dx-button
                    [disabled]="isExporting"
                    stylingMode="outlined"
                    text="Clear"
                    icon="clear"
                    type="danger"
                    width="60%"
                    (onClick)="clearForm()"
                ></dx-button
            ></dxi-item>
            <dxi-item [ratio]="3" style="padding-right: 10px">
                <dx-button
                    [disabled]="isExporting"
                    stylingMode="contained"
                    text="Preview PDF"
                    icon="pdffile"
                    type="normal"
                    width="100%"
                    (onClick)="showPreviewPdf()"
                ></dx-button>
            </dxi-item>
            <dxi-item [ratio]="3">
                <dx-button
                    [disabled]="isExporting"
                    stylingMode="contained"
                    text="{{
                        inputValues.invoiceInfo.isEdit ? 'Edit' : 'Create'
                    }} / Share"
                    icon="{{
                        inputValues.invoiceInfo.isEdit ? 'edit' : 'save'
                    }}"
                    type="default"
                    width="100%"
                    (onClick)="saveAndShare($event)"
                ></dx-button>
            </dxi-item>
        </dx-box>
        <dx-box
            direction="row"
            crossAlign="center"
            style="margin-top: 10px"
            *ngIf="shareData"
        >
            <dxi-item [ratio]="2" style="padding-right: 10px">
                <dx-text-box
                    width="100%"
                    label="File Name"
                    [value]="shareData.fileName"
                    style="margin-bottom: 7px"
                >
                </dx-text-box>
            </dxi-item>
            <dxi-item [ratio]="8" style="padding-right: 10px">
                <dx-text-box
                    width="100%"
                    label="Shared URL"
                    [value]="shareData.sharedUrl"
                    style="margin-bottom: 7px"
                ></dx-text-box>
            </dxi-item>
            <dxi-item [ratio]="2" style="padding-right: 10px">
                <dx-button
                    [disabled]="isExporting"
                    stylingMode="contained"
                    text="Copy"
                    icon="copy"
                    width="100%"
                    (onClick)="copySharedUrlToClibboard()"
                ></dx-button>
            </dxi-item>
            <dxi-item [ratio]="2">
                <dx-button
                    [disabled]="isExporting"
                    stylingMode="contained"
                    text="Open"
                    icon="link"
                    width="100%"
                    (onClick)="openSharedUrl()"
                ></dx-button>
            </dxi-item>
        </dx-box>
        <div class="section-header" style="margin-top: 10px">ข้อมูลผู้ซื้อ</div>
        <div class="flex-container-row">
            <div style="flex-basis: 15%">
                <dx-number-box
                    [showSpinButtons]="false"
                    [showClearButton]="true"
                    label="เลขที่ออเดอร์อ้างอิง"
                    labelMode="floating"
                    [(value)]="inputValues.invoiceInfo.refOrderNum"
                    (onValueChanged)="onInputsUpdated()"
                >
                </dx-number-box>
            </div>
            <div style="flex-basis: 15%">
                <dx-date-box
                    label="วันที่"
                    labelMode="floating"
                    displayFormat="dd MMMM yyyy"
                    [showClearButton]="true"
                    [(value)]="inputValues.invoiceInfo.date"
                    (onValueChanged)="onInputsUpdated()"
                    [calendarOptions]="{ showTodayButton: true }"
                >
                    <dx-validator>
                        <dxi-validation-rule
                            type="required"
                            message="ใส่วันที่ใบกำกับภาษี"
                        ></dxi-validation-rule>
                    </dx-validator>
                </dx-date-box>
            </div>
            <div
                class="checkbox-container"
                style="
                    margin-left: 30px;
                    flex-basis: 5%;
                    justify-content: flex-end;
                "
            >
                <dx-switch
                    [(value)]="inputValues.invoiceInfo.isEdit"
                    (onValueChanged)="onInputsUpdated()"
                >
                </dx-switch>
                &nbsp;
                <span
                    [ngClass]="{
                        'user-input': inputValues.invoiceInfo.isEdit
                    }"
                    >แก้ไขใบกำกับภาษี</span
                >
            </div>
            <div style="flex-basis: 20%; flex-shrink: 1">
                <dx-text-box
                    style="min-width: 100px"
                    [(value)]="inputValues.invoiceInfo.invNum"
                    [showClearButton]="true"
                    labelMode="floating"
                    label="เลขที่ใบกำกับภาษีที่ต้องการแก้ไข"
                    (onValueChanged)="onInputsUpdated()"
                    *ngIf="inputValues.invoiceInfo.isEdit"
                >
                    <dx-validator>
                        <dxi-validation-rule
                            type="required"
                            message="ใส่เลขที่ใบกำกับภาษี"
                        ></dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
            </div>
            <div
                class="checkbox-container"
                style="
                    justify-content: flex-end;
                    flex-basis: 5%;
                    margin-left: 10px;
                "
            >
                <dx-switch
                    [(value)]="inputValues.invoiceInfo.isDeliveryNote"
                    (onValueChanged)="onInputsUpdated()"
                >
                </dx-switch>
                &nbsp;
                <span
                    [ngClass]="{
                        'user-input': inputValues.invoiceInfo.isDeliveryNote
                    }"
                    >ใบส่งของ</span
                >
            </div>
            <div
                class="checkbox-container"
                style="justify-content: flex-end; flex-basis: 5%"
            >
                <dx-switch
                    [(value)]="inputValues.invoiceInfo.isTaxInvoice"
                    (onValueChanged)="onInputsUpdated()"
                >
                </dx-switch>
                &nbsp;
                <span
                    [ngClass]="{
                        'user-input': inputValues.invoiceInfo.isTaxInvoice
                    }"
                    >ใบกำกับภาษี</span
                >
            </div>
            <div
                class="checkbox-container"
                style="
                    justify-content: flex-end;
                    margin-right: 10px;
                    flex-basis: 5%;
                "
            >
                <dx-switch
                    [(value)]="inputValues.invoiceInfo.isReceipt"
                    (onValueChanged)="onInputsUpdated()"
                >
                </dx-switch>
                &nbsp;
                <span
                    [ngClass]="{
                        'user-input': inputValues.invoiceInfo.isReceipt
                    }"
                    >ใบเสร็จรับเงิน</span
                >
            </div>
        </div>
        <div class="flex-container-row">
            <div style="flex-grow: 5">
                <dx-text-box
                    [(value)]="inputValues.customerInfo.name"
                    [showClearButton]="true"
                    labelMode="floating"
                    label="ชื่อ"
                    (onValueChanged)="onInputsUpdated()"
                >
                    <dx-validator>
                        <dxi-validation-rule
                            type="required"
                            message="ใส่ชื่อลูกค้า"
                        ></dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
            </div>
            <div style="flex-grow: 1">
                <dx-text-box
                    [(value)]="inputValues.customerInfo.taxId"
                    [showClearButton]="true"
                    labelMode="floating"
                    label="เลขประจำตัวผู้เสียภาษี"
                    (onValueChanged)="onInputsUpdated()"
                >
                    <dx-validator>
                        <dxi-validation-rule
                            type="required"
                            message="ใส่เลขประจำตัวผู้เสียภาษีลูกค้า"
                        ></dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
            </div>
            <div style="flex-grow: 1">
                <dx-select-box
                    [(value)]="inputValues.customerInfo.branch"
                    [items]="branchList"
                    placeholder="เลือกสาขา"
                    [showClearButton]="true"
                    label="สาขา"
                    labelMode="floating"
                    [acceptCustomValue]="true"
                    (onValueChanged)="onInputsUpdated()"
                >
                    <dx-validator>
                        <dxi-validation-rule
                            type="required"
                            message="เลือกสาขาลูกค้า"
                        ></dxi-validation-rule>
                    </dx-validator>
                </dx-select-box>
            </div>
        </div>

        <div class="section-header">
            <span>ที่อยู่</span>
        </div>

        <div class="checkbox-container" style="margin: 10px; margin-left: 20px">
            <dx-switch
                [(value)]="inputValues.customerInfo.useCustomAddress"
                (onValueChanged)="onInputsUpdated()"
            >
            </dx-switch>
            &nbsp;
            <span
                [ngClass]="{
                    'user-input': inputValues.customerInfo.useCustomAddress
                }"
                >กรอกที่อยู่เอง</span
            >
        </div>
        <div
            class="flex-container-row"
            *ngIf="!inputValues.customerInfo.useCustomAddress"
        >
            <div>
                <dx-autocomplete
                    placeholder="พิมพ์รหัสไปรษณีย์ ..."
                    [showClearButton]="true"
                    [(value)]="inputValues.customerInfo.postCode"
                    labelMode="floating"
                    label="รหัสไปรษณีย์"
                    [dataSource]="postCodeSuggestions"
                    [minSearchLength]="2"
                    [showDropDownButton]="true"
                    searchMode="startswith"
                    (onValueChanged)="onInputsUpdated()"
                >
                </dx-autocomplete>
            </div>
            →
            <div>
                <dx-select-box
                    [(value)]="inputValues.customerInfo.province"
                    placeholder="เลือกจังหวัด"
                    [showClearButton]="true"
                    label="จังหวัด"
                    labelMode="floating"
                    [searchEnabled]="true"
                    [acceptCustomValue]="true"
                    [items]="provinceSuggestions"
                    (onValueChanged)="onInputsUpdated()"
                ></dx-select-box>
            </div>
            →
            <div>
                <dx-select-box
                    [(value)]="inputValues.customerInfo.district"
                    placeholder="เลือกเขต/อำเภอ"
                    [showClearButton]="true"
                    label="เขต/อำเภอ"
                    labelMode="floating"
                    [searchEnabled]="true"
                    [acceptCustomValue]="true"
                    [items]="districtSuggestions"
                    (onValueChanged)="onInputsUpdated()"
                ></dx-select-box>
            </div>
            →
            <div>
                <dx-select-box
                    [(value)]="inputValues.customerInfo.subDistrict"
                    placeholder="เลือกแขวง/ตำบล"
                    [showClearButton]="true"
                    label="แขวง/ตำบล"
                    labelMode="floating"
                    [searchEnabled]="true"
                    [acceptCustomValue]="true"
                    [items]="subDistrictSuggestions"
                    (onValueChanged)="onInputsUpdated()"
                ></dx-select-box>
            </div>
        </div>
        <div
            class="flex-container-row"
            *ngIf="!inputValues.customerInfo.useCustomAddress"
        >
            <div>
                <dx-text-box
                    [(value)]="inputValues.customerInfo.address1"
                    [showClearButton]="true"
                    labelMode="floating"
                    label="บ้านเลขที่ ซอย ถนน"
                    (onValueChanged)="onInputsUpdated()"
                >
                </dx-text-box>
            </div>
        </div>
        <div
            class="flex-container-row"
            *ngIf="!inputValues.customerInfo.useCustomAddress"
        >
            <div>
                <dx-text-area
                    [(value)]="inputValues.customerInfo.fullAddress"
                    [height]="60"
                    labelMode="floating"
                    label="ที่อยู่เต็ม"
                    (onValueChanged)="onInputsUpdated()"
                >
                    <dx-validator>
                        <dxi-validation-rule
                            type="required"
                            message="ใส่ที่อยู่ลูกค้า"
                        >
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-text-area>
            </div>
        </div>

        <div
            class="flex-container-row"
            *ngIf="inputValues.customerInfo.useCustomAddress"
        >
            <div>
                <dx-text-area
                    [(value)]="inputValues.customerInfo.customAddress"
                    [height]="90"
                    labelMode="floating"
                    placeholder="กรอกที่อยู่กำหนดเอง"
                    label="ที่อยู่กำหนดเอง"
                    (onValueChanged)="onInputsUpdated()"
                >
                    <dx-validator>
                        <dxi-validation-rule
                            type="required"
                            message="ใส่ที่อยู่ลูกค้า"
                        >
                        </dxi-validation-rule>
                    </dx-validator>
                </dx-text-area>
            </div>
        </div>

        <div class="section-header">อื่นๆ</div>
        <div class="flex-container-row">
            <div>
                <dx-text-area
                    [(value)]="inputValues.invoiceInfo.remark"
                    [height]="60"
                    labelMode="floating"
                    label="หมายเหตุ"
                    (onValueChanged)="onInputsUpdated()"
                >
                </dx-text-area>
            </div>
        </div>
        <div class="flex-container-row" style="margin-top: 10px">
            <div class="checkbox-container">
                <dx-switch
                    [(value)]="inputValues.invoiceInfo.includeCopy"
                    (onValueChanged)="onInputsUpdated()"
                >
                </dx-switch>
                &nbsp;<span
                    [ngClass]="{
                        'user-input': inputValues.invoiceInfo.includeCopy
                    }"
                    >บันทึกสำเนาเอกสาร</span
                >
            </div>
            <div class="checkbox-container">
                <dx-switch
                    [(value)]="inputValues.invoiceInfo.showSignatureFields"
                    (onValueChanged)="onInputsUpdated()"
                >
                </dx-switch>
                &nbsp;<span
                    [ngClass]="{
                        'user-input':
                            inputValues.invoiceInfo.showSignatureFields
                    }"
                    >เพิ่มช่องลายเซ็นผู้ซื้อและผู้ขาย</span
                >
            </div>
            <div
                class="checkbox-container"
                [style.visibility]="
                    inputValues.invoiceInfo.showSignatureFields
                        ? 'visible'
                        : 'hidden'
                "
            >
                <dx-switch
                    [(value)]="inputValues.invoiceInfo.showSellerSignature"
                    (onValueChanged)="onInputsUpdated()"
                >
                </dx-switch>
                &nbsp;<span
                    [ngClass]="{
                        'user-input':
                            inputValues.invoiceInfo.showSellerSignature
                    }"
                    >เพิ่มลายเซ็นผู้ขาย</span
                >
            </div>
            <div>
                <dx-text-box
                    [(value)]="inputValues.invoiceInfo.title1"
                    [showClearButton]="true"
                    labelMode="floating"
                    label="เปลี่ยนหัวเอกสาร (h1)"
                    (onValueChanged)="onInputsUpdated()"
                >
                </dx-text-box>
            </div>
            <div>
                <dx-text-box
                    [(value)]="inputValues.invoiceInfo.title2"
                    [showClearButton]="true"
                    labelMode="floating"
                    label="เปลี่ยนหัวเอกสาร (h2)"
                    (onValueChanged)="onInputsUpdated()"
                >
                </dx-text-box>
            </div>
            <div>
                <dx-text-box
                    [(value)]="inputValues.invoiceInfo.title3"
                    [showClearButton]="true"
                    labelMode="floating"
                    label="เปลี่ยนหัวเอกสาร (h3)"
                    (onValueChanged)="onInputsUpdated()"
                >
                </dx-text-box>
            </div>
        </div>
        <div class="section-header">รายการสินค้า</div>
        <div class="flex-container-row">
            <dx-data-grid
                #dataGrid
                class="dx-card"
                style="padding-top: 10px"
                keyExpr="itemNo"
                [showBorders]="false"
                [columnAutoWidth]="false"
                [allowColumnResizing]="true"
                [dataSource]="inputValues.productItems"
                (onSaved)="onSaved()"
            >
                <dxo-editing
                    mode="batch"
                    [allowUpdating]="true"
                    [allowDeleting]="true"
                    [allowAdding]="true"
                    [selectTextOnEditStart]="true"
                    newRowPosition="last"
                >
                </dxo-editing>
                <dxo-row-dragging
                    [allowReordering]="true"
                    [onReorder]="onReorder"
                ></dxo-row-dragging>
                <dxi-column
                    dataField="itemNo"
                    caption="No."
                    dataType="number"
                    [width]="80"
                    [allowEditing]="false"
                ></dxi-column>
                <dxi-column
                    dataField="sku"
                    caption="SKU"
                    [width]="80"
                ></dxi-column>
                <dxi-column
                    dataField="pid"
                    caption="PID"
                    dataType="number"
                    [width]="80"
                ></dxi-column>
                <dxi-column
                    dataField="description"
                    caption="Description"
                    [minWidth]="120"
                ></dxi-column>
                <dxi-column
                    dataField="unitPrice"
                    caption="Unit Price"
                    dataType="number"
                    [width]="120"
                    cellTemplate="priceTemplate"
                ></dxi-column>
                <dxi-column
                    dataField="quantity"
                    caption="Quantity"
                    dataType="number"
                    [width]="120"
                    cellTemplate="numTemplate"
                ></dxi-column>
                <dxi-column
                    caption="Amount"
                    dataType="number"
                    [width]="120"
                    [allowEditing]="false"
                    [calculateCellValue]="calculateProductsAmount"
                    cellTemplate="priceTemplate"
                ></dxi-column>
                <div *dxTemplate="let cellData of 'priceTemplate'">
                    {{
                        cellData.value
                            ? (cellData.value | formatNumber : 2)
                            : ""
                    }}
                </div>
                <div *dxTemplate="let cellData of 'numTemplate'">
                    {{
                        cellData.value
                            ? (cellData.value | formatNumber : 0)
                            : ""
                    }}
                </div>
            </dx-data-grid>
        </div>

        <div class="flex-container-row">
            <div class="price-container">
                <span class="label">ราคาสินค้า</span>
                <span>
                    &nbsp;&nbsp;
                    {{
                        inputValues.invoiceInfo.totalProductPrice
                            | formatNumber : 2
                    }}
                    &nbsp;&nbsp;
                </span>
            </div>
            <span style="font-size: x-large">+</span>
            <div>
                <dx-number-box
                    class="align-text-right"
                    [(value)]="inputValues.invoiceInfo.shippingCost"
                    [showSpinButtons]="false"
                    [showClearButton]="true"
                    [format]="{
                        type: 'fixedPoint',
                        precision: 2
                    }"
                    label="ค่าจัดส่ง"
                    labelMode="floating"
                    (onValueChanged)="onInputsUpdated()"
                ></dx-number-box>
            </div>
            <span style="font-size: xx-large">-</span>
            <div>
                <dx-number-box
                    class="align-text-right"
                    [(value)]="inputValues.invoiceInfo.discount"
                    [showSpinButtons]="false"
                    [showClearButton]="true"
                    [format]="{
                        type: 'fixedPoint',
                        precision: 2
                    }"
                    label="ส่วนลด"
                    labelMode="floating"
                    (onValueChanged)="onInputsUpdated()"
                ></dx-number-box>
            </div>
            <span style="font-size: x-large">+</span>
            <div>
                <dx-number-box
                    class="align-text-right"
                    [(value)]="inputValues.invoiceInfo.vatAmount"
                    [showSpinButtons]="false"
                    [showClearButton]="true"
                    [format]="{
                        type: 'fixedPoint',
                        precision: 2
                    }"
                    label="VAT 7%"
                    labelMode="floating"
                    (onValueChanged)="onInputsUpdated()"
                ></dx-number-box>
            </div>
            <span style="font-size: x-large">=</span>
            <div id="net-price-container" class="price-container">
                <span class="label"> ราคาสุทธิ </span>
                <span>
                    &nbsp;&nbsp;
                    {{ inputValues.invoiceInfo.netPrice | formatNumber : 2 }}
                    &nbsp;&nbsp;
                </span>
            </div>
        </div>
    </div>
</dx-validation-group>
<!-- <div>
    <pre>{{ testData }}</pre>
</div>
<div style="margin-top: 10px">
    <pre>{{ requestData }}</pre>
</div> -->
