import { PipeTransform, Pipe } from '@angular/core';
import { FormatDateTimePipe } from './format-datetime.pipe';
import moment from 'moment';

@Pipe({
    name: 'lazadaFormatDateTime',
})
export class LazadaFormatDateTimePipe
    extends FormatDateTimePipe
    implements PipeTransform
{
    transform(value: any, ...args: any[]): any {
        const parsedObj = this.tryParseToMoment(value);
        if (moment.isMoment(parsedObj)) {
            return this.formatMoment(parsedObj, ...args);
        }
        return parsedObj;
    }

    tryParseToMoment(value: any) {
        if (moment.isMoment(value)) {
            return value;
        }
        return moment(value, 'YYYY-MM-DD HH:mm:ss ZZ');
    }
}
