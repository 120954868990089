/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
    UnifiedQueryService,
    UnifiedQueryRequest,
} from './unified-query.service';
import {
    reduce as _reduce,
    isNil as _isNil,
    keys as _keys,
    get as _get,
    concat as _concat,
    toNumber as _toNumber,
    toLower as _toLower,
    trim as _trim,
    isString as _isString,
    isFinite as _isFinite,
    each as _each,
    filter as _filter,
} from 'lodash';
import { AjaxResponse } from 'rxjs/ajax';
import { map, reduce } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import moment, { Moment } from 'moment';

@Injectable({
    providedIn: 'root',
})
export class SaleService {
    constructor(private unifiedQueryService: UnifiedQueryService) {}

    public getSaleByTime(platforms: Platform[]) {
        const attrs = [
            'order_count_1M',
            'order_count_3M',
            'order_count_6M',
            'order_count_YTD',
            'order_count_1Y',
            'order_count_2Y',
            'order_count_3Y',
            'order_count_4Y',
            'order_count_All',
            'pid',
            'platform',
        ];

        const expAttrNames = this.getExpAttrNames(attrs);
        const requests: Observable<AjaxResponse>[] = _reduce(
            platforms,
            (accumulator, platform: Platform) => {
                const request: UnifiedQueryRequest = {
                    operation: 'query',
                    parameter: {
                        TableName: 'arduino4.sale.report',
                        ExpressionAttributeValues: {
                            ':platform': platform,
                        },
                        KeyConditionExpression: 'platform = :platform',
                        ExpressionAttributeNames: expAttrNames,
                        ProjectionExpression: _keys(expAttrNames).join(', '),
                    },
                };
                accumulator.push(
                    this.unifiedQueryService.internalQuery(request)
                );
                return accumulator;
            },
            []
        );
        return forkJoin(requests).pipe<any[]>(
            map((responses: AjaxResponse[]) => {
                return _reduce(
                    responses,
                    (accumulator, response: AjaxResponse) => {
                        const responseItem = _get(response.response, 'Items');
                        if (responseItem) {
                            accumulator = _concat(accumulator, responseItem);
                        }
                        return accumulator;
                    },
                    []
                );
            })
        );
    }

    public getProductSaleAnalyticRecord(): Observable<SaleAnalyticResult> {
        const attrs = [
            'sale_date',
            'pid',
            'platform',
            'sale_price',
            'sale_quantity',
        ];

        const expAttrNames = this.getExpAttrNames(attrs);

        const inventoryObservable = this.getInventoryData();
        const saleRecordObservable = this.unifiedQueryService.internalQuery({
            operation: 'scan',
            parameter: {
                TableName: 'arduino4.sale.record',
                ExpressionAttributeNames: expAttrNames,
                ProjectionExpression: _keys(expAttrNames).join(', '),
            },
        });

        return forkJoin({
            inventory: inventoryObservable.pipe(
                reduce((acc: Map<number, any>, response: AjaxResponse) => {
                    const products = _get(response.response, 'Items');
                    _each(products, (product) => {
                        const pid = _get(product, 'pid');
                        if (_isFinite(pid)) {
                            acc.set(pid, product);
                        }
                    });
                    return acc;
                }, new Map<number, any>())
            ),
            saleRecords: saleRecordObservable.pipe(
                reduce((acc: any[], response: AjaxResponse) => {
                    const rawSaleRecords: any = _get(
                        response.response,
                        'Items'
                    );
                    _each(rawSaleRecords, (rawSaleRecord) => {
                        acc.push(new SaleRecord(rawSaleRecord));
                    });
                    return acc;
                }, [])
            ),
        }).pipe(
            map<any, SaleAnalyticResult>((result) => {
                const productDetailMap: Map<number, any> = result.inventory;
                const saleRecords: SaleRecord[] = result.saleRecords;
                const productAnalyticRecordMap: Map<number, ProductSaleRecord> =
                    new Map();
                const invalidSaleRecords: SaleRecord[] = [];
                const noProductDetailSaleRecords: SaleRecord[] = [];
                _each(saleRecords, (saleRecord: SaleRecord) => {
                    if (saleRecord.isValid()) {
                        const productInfo = productDetailMap.get(
                            saleRecord.pid
                        );
                        if (productInfo) {
                            const productCreationTime: Moment = moment(
                                _get(productInfo, 'created_time', null)
                            );
                            if (productCreationTime.isValid()) {
                                let productRecord =
                                    productAnalyticRecordMap.get(
                                        saleRecord.pid
                                    );
                                if (!productRecord) {
                                    productRecord = new ProductSaleRecord(
                                        saleRecord.pid,
                                        productCreationTime
                                    );
                                }
                                productAnalyticRecordMap.set(
                                    saleRecord.pid,
                                    productRecord
                                );
                                productRecord.addRecord(saleRecord);
                            } else {
                                noProductDetailSaleRecords.push(saleRecord);
                            }
                        } else {
                            noProductDetailSaleRecords.push(saleRecord);
                        }
                    } else {
                        invalidSaleRecords.push(saleRecord);
                    }
                });
                return {
                    productAnalyticRecordMap,
                    productDetailMap,
                    invalidSaleRecords,
                    noProductDetailSaleRecords,
                };
            })
        );
    }

    private getInventoryData(): Observable<AjaxResponse> {
        const attrs = [
            'pid',
            'sku',
            'purl',
            'title',
            'remain',
            'price',
            'stock_status',
            'created_time',
        ];
        const expAttrNames = this.getExpAttrNames(attrs);
        const request: UnifiedQueryRequest = {
            operation: 'scan',
            parameter: {
                TableName: 'arduino4.inventory',
                ExpressionAttributeNames: expAttrNames,
                ProjectionExpression: _keys(expAttrNames).join(', '),
            },
        };

        return this.unifiedQueryService.internalQuery(request);
    }

    private getExpAttrNames(attrNames: string[]) {
        return _reduce<string, any>(
            attrNames,
            (accumulator, attribute: string) => {
                accumulator[`#${attribute}`] = attribute;
                return accumulator;
            },
            {}
        );
    }
}

class SaleRecord {
    pid: number;
    platform: Platform;
    saleDate: Moment;
    salePrice: number;
    saleQuantity: number;

    constructor(rawRecord: any) {
        this.getPid(rawRecord);
        this.getPlatform(rawRecord);
        this.getSaleDate(rawRecord);
        this.getSalePrice(rawRecord);
        this.getSaleQuantity(rawRecord);
    }

    isValid(): boolean {
        const isPidValid: boolean = _isFinite(this.pid) && this.pid !== 0;
        const isPlatformValid = !_isNil(this.platform);
        const isSaleDateValid: boolean =
            !_isNil(this.saleDate) && this.saleDate.isValid();
        const isSalePriceValid: boolean =
            _isFinite(this.salePrice) && this.salePrice !== 0;
        const isSaleQuantityValid: boolean =
            _isFinite(this.saleQuantity) && this.saleQuantity !== 0;
        return (
            isPidValid &&
            isPlatformValid &&
            isSaleDateValid &&
            isSalePriceValid &&
            isSaleQuantityValid
        );
    }

    private getPid(rawRecord: any) {
        const rawPid = _get(rawRecord, 'pid');
        if (_isFinite(rawPid)) {
            this.pid = rawPid;
        } else if (_isString(rawPid)) {
            this.pid = _toNumber(rawPid);
        }
    }

    private getPlatform(rawRecord: any) {
        let platformString: string = _get(rawRecord, 'platform');
        if (platformString && _isString(platformString)) {
            platformString = _toLower(_trim(platformString));
            if (platformString === 'lazada') {
                this.platform = Platform.Lazada;
            } else if (platformString === 'shopee') {
                this.platform = Platform.Shopee;
            } else {
                this.platform = Platform.LnwShop;
            }
        }
    }

    private getSaleDate(rawRecord: any) {
        const dateString: string = _get(rawRecord, 'sale_date');
        if (_isString(dateString)) {
            const saleDate: Moment = moment(dateString);
            if (saleDate.isValid()) {
                this.saleDate = saleDate;
            }
        }
    }

    private getSalePrice(rawRecord: any) {
        const rawPrice = _get(rawRecord, 'sale_price');
        if (_isFinite(rawPrice)) {
            this.salePrice = rawPrice;
        } else if (_isString(rawPrice)) {
            this.salePrice = _toNumber(rawPrice);
        }
    }

    private getSaleQuantity(rawRecord: any) {
        const rawQuantity = _get(rawRecord, 'sale_quantity');
        if (_isFinite(rawQuantity)) {
            this.saleQuantity = rawQuantity;
        } else if (_isString(rawQuantity)) {
            this.saleQuantity = _toNumber(rawQuantity);
        }
    }
}

class ProductSaleRecord {
    readonly pid: number;
    readonly creationMoment: Moment;

    private saleRecords: SaleRecord[] = [];
    constructor(pid: number, creationMoment: Moment) {
        this.pid = pid;
        this.creationMoment = creationMoment;
    }
    addRecord(saleRecord: SaleRecord) {
        if (this.pid === saleRecord.pid) {
            this.saleRecords.push(saleRecord);
        } else {
            throw new Error('PID of the sale record does not match');
        }
    }
    getAvgMonthlyRevenue(lastMonths: number): AvgResult {
        let usingMonths: number = lastMonths;
        const monthsFromCreation = moment().diff(
            this.creationMoment,
            'months',
            true
        );
        if (lastMonths > monthsFromCreation) {
            usingMonths = monthsFromCreation;
        }

        const filteredRecords: SaleRecord[] =
            this.getFilteredRecordsByMonths(usingMonths);
        const totalRevenue = _reduce(
            filteredRecords,
            (accumulator, saleRecord: SaleRecord) => {
                return (
                    accumulator + saleRecord.saleQuantity * saleRecord.salePrice
                );
            },
            0
        );
        return {
            avg: totalRevenue / usingMonths,
            dividedBy: usingMonths,
        };
    }

    private getFilteredRecordsByMonths(lastMonths: number) {
        const millisMonths: number = moment()
            .subtract(lastMonths, 'months')
            .valueOf();
        return _filter(this.saleRecords, (saleRecord: SaleRecord) => {
            return saleRecord.saleDate.valueOf() > millisMonths;
        });
    }
}

export interface SaleAnalyticResult {
    productAnalyticRecordMap: Map<number, ProductSaleRecord>;
    productDetailMap: Map<number, any>;
    invalidSaleRecords: SaleRecord[];
    noProductDetailSaleRecords: SaleRecord[];
}

export interface AvgResult {
    avg: number;
    dividedBy: number;
}

export enum Platform {
    LnwShop = 'LnwShop',
    Lazada = 'Lazada',
    Shopee = 'Shopee',
}
