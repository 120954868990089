export class ThAddressData {
    static rawData = [
        {
            subDistrict: 'คลองท่อมเหนือ',
            district: 'คลองท่อม',
            province: 'กระบี่',
            zipcode: 81120,
        },
        {
            subDistrict: 'คลองท่อมใต้',
            district: 'คลองท่อม',
            province: 'กระบี่',
            zipcode: 81120,
        },
        {
            subDistrict: 'คลองพน',
            district: 'คลองท่อม',
            province: 'กระบี่',
            zipcode: 81170,
        },
        {
            subDistrict: 'ทรายขาว',
            district: 'คลองท่อม',
            province: 'กระบี่',
            zipcode: 81170,
        },
        {
            subDistrict: 'พรุดินนา',
            district: 'คลองท่อม',
            province: 'กระบี่',
            zipcode: 81120,
        },
        {
            subDistrict: 'ห้วยน้ำขาว',
            district: 'คลองท่อม',
            province: 'กระบี่',
            zipcode: 81120,
        },
        {
            subDistrict: 'เพหลา',
            district: 'คลองท่อม',
            province: 'กระบี่',
            zipcode: 81120,
        },
        {
            subDistrict: 'คีรีวง',
            district: 'ปลายพระยา',
            province: 'กระบี่',
            zipcode: 81160,
        },
        {
            subDistrict: 'ปลายพระยา',
            district: 'ปลายพระยา',
            province: 'กระบี่',
            zipcode: 81160,
        },
        {
            subDistrict: 'เขาต่อ',
            district: 'ปลายพระยา',
            province: 'กระบี่',
            zipcode: 81160,
        },
        {
            subDistrict: 'เขาเขน',
            district: 'ปลายพระยา',
            province: 'กระบี่',
            zipcode: 81160,
        },
        {
            subDistrict: 'ดินอุดม',
            district: 'ลำทับ',
            province: 'กระบี่',
            zipcode: 81190,
        },
        {
            subDistrict: 'ดินแดง',
            district: 'ลำทับ',
            province: 'กระบี่',
            zipcode: 81190,
        },
        {
            subDistrict: 'ทุ่งไทรทอง',
            district: 'ลำทับ',
            province: 'กระบี่',
            zipcode: 81190,
        },
        {
            subDistrict: 'ลำทับ',
            district: 'ลำทับ',
            province: 'กระบี่',
            zipcode: 81190,
        },
        {
            subDistrict: 'คลองยา',
            district: 'อ่าวลึก',
            province: 'กระบี่',
            zipcode: 81110,
        },
        {
            subDistrict: 'คลองหิน',
            district: 'อ่าวลึก',
            province: 'กระบี่',
            zipcode: 81110,
        },
        {
            subDistrict: 'นาเหนือ',
            district: 'อ่าวลึก',
            province: 'กระบี่',
            zipcode: 81110,
        },
        {
            subDistrict: 'บ้านกลาง',
            district: 'อ่าวลึก',
            province: 'กระบี่',
            zipcode: 81110,
        },
        {
            subDistrict: 'อ่าวลึกน้อย',
            district: 'อ่าวลึก',
            province: 'กระบี่',
            zipcode: 81110,
        },
        {
            subDistrict: 'อ่าวลึกเหนือ',
            district: 'อ่าวลึก',
            province: 'กระบี่',
            zipcode: 81110,
        },
        {
            subDistrict: 'อ่าวลึกใต้',
            district: 'อ่าวลึก',
            province: 'กระบี่',
            zipcode: 81110,
        },
        {
            subDistrict: 'เขาใหญ่',
            district: 'อ่าวลึก',
            province: 'กระบี่',
            zipcode: 81110,
        },
        {
            subDistrict: 'แหลมสัก',
            district: 'อ่าวลึก',
            province: 'กระบี่',
            zipcode: 81110,
        },
        {
            subDistrict: 'คลองยาง',
            district: 'เกาะลันตา',
            province: 'กระบี่',
            zipcode: 81120,
        },
        {
            subDistrict: 'ศาลาด่าน',
            district: 'เกาะลันตา',
            province: 'กระบี่',
            zipcode: 81150,
        },
        {
            subDistrict: 'เกาะกลาง',
            district: 'เกาะลันตา',
            province: 'กระบี่',
            zipcode: 81120,
        },
        {
            subDistrict: 'เกาะลันตาน้อย',
            district: 'เกาะลันตา',
            province: 'กระบี่',
            zipcode: 81150,
        },
        {
            subDistrict: 'เกาะลันตาใหญ่',
            district: 'เกาะลันตา',
            province: 'กระบี่',
            zipcode: 81150,
        },
        {
            subDistrict: 'พรุเตียว',
            district: 'เขาพนม',
            province: 'กระบี่',
            zipcode: 81140,
        },
        {
            subDistrict: 'สินปุน',
            district: 'เขาพนม',
            province: 'กระบี่',
            zipcode: 80240,
        },
        {
            subDistrict: 'หน้าเขา',
            district: 'เขาพนม',
            province: 'กระบี่',
            zipcode: 81140,
        },
        {
            subDistrict: 'เขาดิน',
            district: 'เขาพนม',
            province: 'กระบี่',
            zipcode: 81140,
        },
        {
            subDistrict: 'เขาพนม',
            district: 'เขาพนม',
            province: 'กระบี่',
            zipcode: 81140,
        },
        {
            subDistrict: 'โคกหาร',
            district: 'เขาพนม',
            province: 'กระบี่',
            zipcode: 80240,
        },
        {
            subDistrict: 'กระบี่น้อย',
            district: 'เมืองกระบี่',
            province: 'กระบี่',
            zipcode: 81000,
        },
        {
            subDistrict: 'กระบี่ใหญ่',
            district: 'เมืองกระบี่',
            province: 'กระบี่',
            zipcode: 81000,
        },
        {
            subDistrict: 'คลองประสงค์',
            district: 'เมืองกระบี่',
            province: 'กระบี่',
            zipcode: 81000,
        },
        {
            subDistrict: 'ทับปริก',
            district: 'เมืองกระบี่',
            province: 'กระบี่',
            zipcode: 81000,
        },
        {
            subDistrict: 'ปากน้ำ',
            district: 'เมืองกระบี่',
            province: 'กระบี่',
            zipcode: 81000,
        },
        {
            subDistrict: 'หนองทะเล',
            district: 'เมืองกระบี่',
            province: 'กระบี่',
            zipcode: 81180,
        },
        {
            subDistrict: 'อ่าวนาง',
            district: 'เมืองกระบี่',
            province: 'กระบี่',
            zipcode: 81000,
        },
        {
            subDistrict: 'เขาคราม',
            district: 'เมืองกระบี่',
            province: 'กระบี่',
            zipcode: 81000,
        },
        {
            subDistrict: 'เขาทอง',
            district: 'เมืองกระบี่',
            province: 'กระบี่',
            zipcode: 81000,
        },
        {
            subDistrict: 'ไสไทย',
            district: 'เมืองกระบี่',
            province: 'กระบี่',
            zipcode: 81000,
        },
        {
            subDistrict: 'คลองขนาน',
            district: 'เหนือคลอง',
            province: 'กระบี่',
            zipcode: 81130,
        },
        {
            subDistrict: 'คลองเขม้า',
            district: 'เหนือคลอง',
            province: 'กระบี่',
            zipcode: 81130,
        },
        {
            subDistrict: 'ตลิ่งชัน',
            district: 'เหนือคลอง',
            province: 'กระบี่',
            zipcode: 81130,
        },
        {
            subDistrict: 'ปกาสัย',
            district: 'เหนือคลอง',
            province: 'กระบี่',
            zipcode: 81130,
        },
        {
            subDistrict: 'ห้วยยูง',
            district: 'เหนือคลอง',
            province: 'กระบี่',
            zipcode: 81130,
        },
        {
            subDistrict: 'เกาะศรีบอยา',
            district: 'เหนือคลอง',
            province: 'กระบี่',
            zipcode: 81130,
        },
        {
            subDistrict: 'เหนือคลอง',
            district: 'เหนือคลอง',
            province: 'กระบี่',
            zipcode: 81130,
        },
        {
            subDistrict: 'โคกยาง',
            district: 'เหนือคลอง',
            province: 'กระบี่',
            zipcode: 81130,
        },
        {
            subDistrict: 'คลองต้นไทร',
            district: 'คลองสาน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10600,
        },
        {
            subDistrict: 'คลองสาน',
            district: 'คลองสาน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10600,
        },
        {
            subDistrict: 'บางลำภูล่าง',
            district: 'คลองสาน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10600,
        },
        {
            subDistrict: 'สมเด็จเจ้าพระยา',
            district: 'คลองสาน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10600,
        },
        {
            subDistrict: 'ทรายกองดิน',
            district: 'คลองสามวา',
            province: 'กรุงเทพมหานคร',
            zipcode: 10510,
        },
        {
            subDistrict: 'ทรายกองดินใต้',
            district: 'คลองสามวา',
            province: 'กรุงเทพมหานคร',
            zipcode: 10510,
        },
        {
            subDistrict: 'บางชัน',
            district: 'คลองสามวา',
            province: 'กรุงเทพมหานคร',
            zipcode: 10510,
        },
        {
            subDistrict: 'สามวาตะวันตก',
            district: 'คลองสามวา',
            province: 'กรุงเทพมหานคร',
            zipcode: 10510,
        },
        {
            subDistrict: 'สามวาตะวันออก',
            district: 'คลองสามวา',
            province: 'กรุงเทพมหานคร',
            zipcode: 10510,
        },
        {
            subDistrict: 'คลองตัน',
            district: 'คลองเตย',
            province: 'กรุงเทพมหานคร',
            zipcode: 10110,
        },
        {
            subDistrict: 'คลองเตย',
            district: 'คลองเตย',
            province: 'กรุงเทพมหานคร',
            zipcode: 10110,
        },
        {
            subDistrict: 'พระโขนง',
            district: 'คลองเตย',
            province: 'กรุงเทพมหานคร',
            zipcode: 10110,
        },
        {
            subDistrict: 'พระโขนง',
            district: 'คลองเตย',
            province: 'กรุงเทพมหานคร',
            zipcode: 10260,
        },
        {
            subDistrict: 'คันนายาว',
            district: 'คันนายาว',
            province: 'กรุงเทพมหานคร',
            zipcode: 10230,
        },
        {
            subDistrict: 'รามอินทรา',
            district: 'คันนายาว',
            province: 'กรุงเทพมหานคร',
            zipcode: 10230,
        },
        {
            subDistrict: 'จตุจักร',
            district: 'จตุจักร',
            province: 'กรุงเทพมหานคร',
            zipcode: 10900,
        },
        {
            subDistrict: 'จอมพล',
            district: 'จตุจักร',
            province: 'กรุงเทพมหานคร',
            zipcode: 10900,
        },
        {
            subDistrict: 'จันทรเกษม',
            district: 'จตุจักร',
            province: 'กรุงเทพมหานคร',
            zipcode: 10900,
        },
        {
            subDistrict: 'ลาดยาว',
            district: 'จตุจักร',
            province: 'กรุงเทพมหานคร',
            zipcode: 10900,
        },
        {
            subDistrict: 'เสนานิคม',
            district: 'จตุจักร',
            province: 'กรุงเทพมหานคร',
            zipcode: 10900,
        },
        {
            subDistrict: 'จอมทอง',
            district: 'จอมทอง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10150,
        },
        {
            subDistrict: 'บางขุนเทียน',
            district: 'จอมทอง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10150,
        },
        {
            subDistrict: 'บางค้อ',
            district: 'จอมทอง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10150,
        },
        {
            subDistrict: 'บางมด',
            district: 'จอมทอง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10150,
        },
        {
            subDistrict: 'ดอนเมือง',
            district: 'ดอนเมือง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10210,
        },
        {
            subDistrict: 'สนามบิน',
            district: 'ดอนเมือง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10210,
        },
        {
            subDistrict: 'สีกัน',
            district: 'ดอนเมือง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10210,
        },
        {
            subDistrict: 'ดินแดง',
            district: 'ดินแดง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10400,
        },
        {
            subDistrict: 'ดุสิต',
            district: 'ดุสิต',
            province: 'กรุงเทพมหานคร',
            zipcode: 10300,
        },
        {
            subDistrict: 'ถนนนครไชยศรี',
            district: 'ดุสิต',
            province: 'กรุงเทพมหานคร',
            zipcode: 10300,
        },
        {
            subDistrict: 'วชิรพยาบาล',
            district: 'ดุสิต',
            province: 'กรุงเทพมหานคร',
            zipcode: 10300,
        },
        {
            subDistrict: 'สวนจิตรลดา',
            district: 'ดุสิต',
            province: 'กรุงเทพมหานคร',
            zipcode: 10300,
        },
        {
            subDistrict: 'สวนจิตรลดา',
            district: 'ดุสิต',
            province: 'กรุงเทพมหานคร',
            zipcode: 10303,
        },
        {
            subDistrict: 'สี่แยกมหานาค',
            district: 'ดุสิต',
            province: 'กรุงเทพมหานคร',
            zipcode: 10300,
        },
        {
            subDistrict: 'คลองชักพระ',
            district: 'ตลิ่งชัน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10170,
        },
        {
            subDistrict: 'ฉิมพลี',
            district: 'ตลิ่งชัน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10170,
        },
        {
            subDistrict: 'ตลิ่งชัน',
            district: 'ตลิ่งชัน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10170,
        },
        {
            subDistrict: 'บางพรม',
            district: 'ตลิ่งชัน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10170,
        },
        {
            subDistrict: 'บางระมาด',
            district: 'ตลิ่งชัน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10170,
        },
        {
            subDistrict: 'บางเชือกหนัง',
            district: 'ตลิ่งชัน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10170,
        },
        {
            subDistrict: 'ทวีวัฒนา',
            district: 'ทวีวัฒนา',
            province: 'กรุงเทพมหานคร',
            zipcode: 10170,
        },
        {
            subDistrict: 'ศาลาธรรมสพน์',
            district: 'ทวีวัฒนา',
            province: 'กรุงเทพมหานคร',
            zipcode: 10170,
        },
        {
            subDistrict: 'ทุ่งครุ',
            district: 'ทุ่งครุ',
            province: 'กรุงเทพมหานคร',
            zipcode: 10140,
        },
        {
            subDistrict: 'บางมด',
            district: 'ทุ่งครุ',
            province: 'กรุงเทพมหานคร',
            zipcode: 10140,
        },
        {
            subDistrict: 'ดาวคะนอง',
            district: 'ธนบุรี',
            province: 'กรุงเทพมหานคร',
            zipcode: 10600,
        },
        {
            subDistrict: 'ตลาดพลู',
            district: 'ธนบุรี',
            province: 'กรุงเทพมหานคร',
            zipcode: 10600,
        },
        {
            subDistrict: 'บางยี่เรือ',
            district: 'ธนบุรี',
            province: 'กรุงเทพมหานคร',
            zipcode: 10600,
        },
        {
            subDistrict: 'บุคคโล',
            district: 'ธนบุรี',
            province: 'กรุงเทพมหานคร',
            zipcode: 10600,
        },
        {
            subDistrict: 'วัดกัลยาณ์',
            district: 'ธนบุรี',
            province: 'กรุงเทพมหานคร',
            zipcode: 10600,
        },
        {
            subDistrict: 'สำเหร่',
            district: 'ธนบุรี',
            province: 'กรุงเทพมหานคร',
            zipcode: 10600,
        },
        {
            subDistrict: 'หิรัญรูจี',
            district: 'ธนบุรี',
            province: 'กรุงเทพมหานคร',
            zipcode: 10600,
        },
        {
            subDistrict: 'บางขุนนนท์',
            district: 'บางกอกน้อย',
            province: 'กรุงเทพมหานคร',
            zipcode: 10700,
        },
        {
            subDistrict: 'บางขุนศรี',
            district: 'บางกอกน้อย',
            province: 'กรุงเทพมหานคร',
            zipcode: 10700,
        },
        {
            subDistrict: 'บ้านช่างหล่อ',
            district: 'บางกอกน้อย',
            province: 'กรุงเทพมหานคร',
            zipcode: 10700,
        },
        {
            subDistrict: 'ศิริราช',
            district: 'บางกอกน้อย',
            province: 'กรุงเทพมหานคร',
            zipcode: 10700,
        },
        {
            subDistrict: 'อรุณอัมรินทร์',
            district: 'บางกอกน้อย',
            province: 'กรุงเทพมหานคร',
            zipcode: 10700,
        },
        {
            subDistrict: 'วัดท่าพระ',
            district: 'บางกอกใหญ่',
            province: 'กรุงเทพมหานคร',
            zipcode: 10600,
        },
        {
            subDistrict: 'วัดอรุณ',
            district: 'บางกอกใหญ่',
            province: 'กรุงเทพมหานคร',
            zipcode: 10600,
        },
        {
            subDistrict: 'คลองจั่น',
            district: 'บางกะปิ',
            province: 'กรุงเทพมหานคร',
            zipcode: 10240,
        },
        {
            subDistrict: 'หัวหมาก',
            district: 'บางกะปิ',
            province: 'กรุงเทพมหานคร',
            zipcode: 10240,
        },
        {
            subDistrict: 'หัวหมาก',
            district: 'บางกะปิ',
            province: 'กรุงเทพมหานคร',
            zipcode: 10250,
        },
        {
            subDistrict: 'ท่าข้าม',
            district: 'บางขุนเทียน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10150,
        },
        {
            subDistrict: 'แสมดำ',
            district: 'บางขุนเทียน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10150,
        },
        {
            subDistrict: 'บางคอแหลม',
            district: 'บางคอแหลม',
            province: 'กรุงเทพมหานคร',
            zipcode: 10120,
        },
        {
            subDistrict: 'บางโคล่',
            district: 'บางคอแหลม',
            province: 'กรุงเทพมหานคร',
            zipcode: 10120,
        },
        {
            subDistrict: 'วัดพระยาไกร',
            district: 'บางคอแหลม',
            province: 'กรุงเทพมหานคร',
            zipcode: 10120,
        },
        {
            subDistrict: 'บางซื่อ',
            district: 'บางซื่อ',
            province: 'กรุงเทพมหานคร',
            zipcode: 10800,
        },
        {
            subDistrict: 'วงศ์สว่าง',
            district: 'บางซื่อ',
            province: 'กรุงเทพมหานคร',
            zipcode: 10800,
        },
        {
            subDistrict: 'บางนาเหนือ',
            district: 'บางนา',
            province: 'กรุงเทพมหานคร',
            zipcode: 10260,
        },
        {
            subDistrict: 'บางนาใต้',
            district: 'บางนา',
            province: 'กรุงเทพมหานคร',
            zipcode: 10260,
        },
        {
            subDistrict: 'บางบอน',
            district: 'บางบอน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10150,
        },
        {
            subDistrict: 'บางบำหรุ',
            district: 'บางพลัด',
            province: 'กรุงเทพมหานคร',
            zipcode: 10700,
        },
        {
            subDistrict: 'บางพลัด',
            district: 'บางพลัด',
            province: 'กรุงเทพมหานคร',
            zipcode: 10700,
        },
        {
            subDistrict: 'บางยี่ขัน',
            district: 'บางพลัด',
            province: 'กรุงเทพมหานคร',
            zipcode: 10700,
        },
        {
            subDistrict: 'บางอ้อ',
            district: 'บางพลัด',
            province: 'กรุงเทพมหานคร',
            zipcode: 10700,
        },
        {
            subDistrict: 'บางรัก',
            district: 'บางรัก',
            province: 'กรุงเทพมหานคร',
            zipcode: 10500,
        },
        {
            subDistrict: 'มหาพฤฒาราม',
            district: 'บางรัก',
            province: 'กรุงเทพมหานคร',
            zipcode: 10500,
        },
        {
            subDistrict: 'สีลม',
            district: 'บางรัก',
            province: 'กรุงเทพมหานคร',
            zipcode: 10500,
        },
        {
            subDistrict: 'สี่พระยา',
            district: 'บางรัก',
            province: 'กรุงเทพมหานคร',
            zipcode: 10500,
        },
        {
            subDistrict: 'สุริยวงศ์',
            district: 'บางรัก',
            province: 'กรุงเทพมหานคร',
            zipcode: 10500,
        },
        {
            subDistrict: 'ท่าแร้ง',
            district: 'บางเขน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10220,
        },
        {
            subDistrict: 'ท่าแร้ง',
            district: 'บางเขน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10230,
        },
        {
            subDistrict: 'อนุสาวรีย์',
            district: 'บางเขน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10220,
        },
        {
            subDistrict: 'บางแค',
            district: 'บางแค',
            province: 'กรุงเทพมหานคร',
            zipcode: 10160,
        },
        {
            subDistrict: 'บางแคเหนือ',
            district: 'บางแค',
            province: 'กรุงเทพมหานคร',
            zipcode: 10160,
        },
        {
            subDistrict: 'บางไผ่',
            district: 'บางแค',
            province: 'กรุงเทพมหานคร',
            zipcode: 10160,
        },
        {
            subDistrict: 'หลักสอง',
            district: 'บางแค',
            province: 'กรุงเทพมหานคร',
            zipcode: 10160,
        },
        {
            subDistrict: 'คลองกุ่ม',
            district: 'บึงกุ่ม',
            province: 'กรุงเทพมหานคร',
            zipcode: 10230,
        },
        {
            subDistrict: 'คลองกุ่ม',
            district: 'บึงกุ่ม',
            province: 'กรุงเทพมหานคร',
            zipcode: 10240,
        },
        {
            subDistrict: 'นวมินทร์',
            district: 'บึงกุ่ม',
            province: 'กรุงเทพมหานคร',
            zipcode: 10230,
        },
        {
            subDistrict: 'นวลจันทร์',
            district: 'บึงกุ่ม',
            province: 'กรุงเทพมหานคร',
            zipcode: 10230,
        },
        {
            subDistrict: 'ปทุมวัน',
            district: 'ปทุมวัน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10330,
        },
        {
            subDistrict: 'รองเมือง',
            district: 'ปทุมวัน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10330,
        },
        {
            subDistrict: 'ลุมพินี',
            district: 'ปทุมวัน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10330,
        },
        {
            subDistrict: 'วังใหม่',
            district: 'ปทุมวัน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10330,
        },
        {
            subDistrict: 'วังใหม่',
            district: 'ปทุมวัน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10110,
        },
        {
            subDistrict: 'วังใหม่',
            district: 'ปทุมวัน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10120,
        },
        {
            subDistrict: 'วังใหม่',
            district: 'ปทุมวัน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10400,
        },
        {
            subDistrict: 'วังใหม่',
            district: 'ปทุมวัน',
            province: 'กรุงเทพมหานคร',
            zipcode: 10500,
        },
        {
            subDistrict: 'ดอกไม้',
            district: 'ประเวศ',
            province: 'กรุงเทพมหานคร',
            zipcode: 10250,
        },
        {
            subDistrict: 'ประเวศ',
            district: 'ประเวศ',
            province: 'กรุงเทพมหานคร',
            zipcode: 10250,
        },
        {
            subDistrict: 'หนองบอน',
            district: 'ประเวศ',
            province: 'กรุงเทพมหานคร',
            zipcode: 10250,
        },
        {
            subDistrict: 'คลองมหานาค',
            district: 'ป้อมปราบศัตรูพ่าย',
            province: 'กรุงเทพมหานคร',
            zipcode: 10100,
        },
        {
            subDistrict: 'บ้านบาตร',
            district: 'ป้อมปราบศัตรูพ่าย',
            province: 'กรุงเทพมหานคร',
            zipcode: 10100,
        },
        {
            subDistrict: 'ป้อมปราบ',
            district: 'ป้อมปราบศัตรูพ่าย',
            province: 'กรุงเทพมหานคร',
            zipcode: 10100,
        },
        {
            subDistrict: 'วัดเทพศิรินทร์',
            district: 'ป้อมปราบศัตรูพ่าย',
            province: 'กรุงเทพมหานคร',
            zipcode: 10100,
        },
        {
            subDistrict: 'วัดโสมนัส',
            district: 'ป้อมปราบศัตรูพ่าย',
            province: 'กรุงเทพมหานคร',
            zipcode: 10100,
        },
        {
            subDistrict: 'สามเสนใน',
            district: 'พญาไท',
            province: 'กรุงเทพมหานคร',
            zipcode: 10400,
        },
        {
            subDistrict: 'ชนะสงคราม',
            district: 'พระนคร',
            province: 'กรุงเทพมหานคร',
            zipcode: 10200,
        },
        {
            subDistrict: 'ตลาดยอด',
            district: 'พระนคร',
            province: 'กรุงเทพมหานคร',
            zipcode: 10200,
        },
        {
            subDistrict: 'บวรนิเวศ',
            district: 'พระนคร',
            province: 'กรุงเทพมหานคร',
            zipcode: 10200,
        },
        {
            subDistrict: 'บางขุนพรหม',
            district: 'พระนคร',
            province: 'กรุงเทพมหานคร',
            zipcode: 10200,
        },
        {
            subDistrict: 'บ้านพานถม',
            district: 'พระนคร',
            province: 'กรุงเทพมหานคร',
            zipcode: 10200,
        },
        {
            subDistrict: 'พระบรมมหาราชวัง',
            district: 'พระนคร',
            province: 'กรุงเทพมหานคร',
            zipcode: 10200,
        },
        {
            subDistrict: 'วังบูรพาภิรมย์',
            district: 'พระนคร',
            province: 'กรุงเทพมหานคร',
            zipcode: 10200,
        },
        {
            subDistrict: 'วัดราชบพิธ',
            district: 'พระนคร',
            province: 'กรุงเทพมหานคร',
            zipcode: 10200,
        },
        {
            subDistrict: 'วัดสามพระยา',
            district: 'พระนคร',
            province: 'กรุงเทพมหานคร',
            zipcode: 10200,
        },
        {
            subDistrict: 'ศาลเจ้าพ่อเสือ',
            district: 'พระนคร',
            province: 'กรุงเทพมหานคร',
            zipcode: 10200,
        },
        {
            subDistrict: 'สำราญราษฎร์',
            district: 'พระนคร',
            province: 'กรุงเทพมหานคร',
            zipcode: 10200,
        },
        {
            subDistrict: 'เสาชิงช้า',
            district: 'พระนคร',
            province: 'กรุงเทพมหานคร',
            zipcode: 10200,
        },
        {
            subDistrict: 'บางจาก',
            district: 'พระโขนง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10260,
        },
        {
            subDistrict: 'คลองขวาง',
            district: 'ภาษีเจริญ',
            province: 'กรุงเทพมหานคร',
            zipcode: 10160,
        },
        {
            subDistrict: 'คูหาสวรรค์',
            district: 'ภาษีเจริญ',
            province: 'กรุงเทพมหานคร',
            zipcode: 10160,
        },
        {
            subDistrict: 'บางจาก',
            district: 'ภาษีเจริญ',
            province: 'กรุงเทพมหานคร',
            zipcode: 10160,
        },
        {
            subDistrict: 'บางด้วน',
            district: 'ภาษีเจริญ',
            province: 'กรุงเทพมหานคร',
            zipcode: 10160,
        },
        {
            subDistrict: 'บางหว้า',
            district: 'ภาษีเจริญ',
            province: 'กรุงเทพมหานคร',
            zipcode: 10160,
        },
        {
            subDistrict: 'บางแวก',
            district: 'ภาษีเจริญ',
            province: 'กรุงเทพมหานคร',
            zipcode: 10160,
        },
        {
            subDistrict: 'ปากคลองภาษีเจริญ',
            district: 'ภาษีเจริญ',
            province: 'กรุงเทพมหานคร',
            zipcode: 10160,
        },
        {
            subDistrict: 'มีนบุรี',
            district: 'มีนบุรี',
            province: 'กรุงเทพมหานคร',
            zipcode: 10510,
        },
        {
            subDistrict: 'แสนแสบ',
            district: 'มีนบุรี',
            province: 'กรุงเทพมหานคร',
            zipcode: 10510,
        },
        {
            subDistrict: 'ช่องนนทรี',
            district: 'ยานนาวา',
            province: 'กรุงเทพมหานคร',
            zipcode: 10120,
        },
        {
            subDistrict: 'บางโพงพาง',
            district: 'ยานนาวา',
            province: 'กรุงเทพมหานคร',
            zipcode: 10120,
        },
        {
            subDistrict: 'ถนนพญาไท',
            district: 'ราชเทวี',
            province: 'กรุงเทพมหานคร',
            zipcode: 10400,
        },
        {
            subDistrict: 'ถนนเพชรบุรี',
            district: 'ราชเทวี',
            province: 'กรุงเทพมหานคร',
            zipcode: 10400,
        },
        {
            subDistrict: 'ทุ่งพญาไท',
            district: 'ราชเทวี',
            province: 'กรุงเทพมหานคร',
            zipcode: 10400,
        },
        {
            subDistrict: 'มักกะสัน',
            district: 'ราชเทวี',
            province: 'กรุงเทพมหานคร',
            zipcode: 10400,
        },
        {
            subDistrict: 'บางปะกอก',
            district: 'ราษฎร์บูรณะ',
            province: 'กรุงเทพมหานคร',
            zipcode: 10140,
        },
        {
            subDistrict: 'ราษฎร์บูรณะ',
            district: 'ราษฎร์บูรณะ',
            province: 'กรุงเทพมหานคร',
            zipcode: 10140,
        },
        {
            subDistrict: 'ขุมทอง',
            district: 'ลาดกระบัง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10520,
        },
        {
            subDistrict: 'คลองสองต้นนุ่น',
            district: 'ลาดกระบัง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10520,
        },
        {
            subDistrict: 'คลองสามประเวศ',
            district: 'ลาดกระบัง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10520,
        },
        {
            subDistrict: 'ทับยาว',
            district: 'ลาดกระบัง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10520,
        },
        {
            subDistrict: 'ลาดกระบัง',
            district: 'ลาดกระบัง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10520,
        },
        {
            subDistrict: 'ลำปลาทิว',
            district: 'ลาดกระบัง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10520,
        },
        {
            subDistrict: 'จรเข้บัว',
            district: 'ลาดพร้าว',
            province: 'กรุงเทพมหานคร',
            zipcode: 10230,
        },
        {
            subDistrict: 'ลาดพร้าว',
            district: 'ลาดพร้าว',
            province: 'กรุงเทพมหานคร',
            zipcode: 10230,
        },
        {
            subDistrict: 'คลองเจ้าคุณสิงห์',
            district: 'วังทองหลาง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10310,
        },
        {
            subDistrict: 'พลับพลา',
            district: 'วังทองหลาง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10310,
        },
        {
            subDistrict: 'วังทองหลาง',
            district: 'วังทองหลาง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10310,
        },
        {
            subDistrict: 'สะพานสอง',
            district: 'วังทองหลาง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10310,
        },
        {
            subDistrict: 'คลองตันเหนือ',
            district: 'วัฒนา',
            province: 'กรุงเทพมหานคร',
            zipcode: 10110,
        },
        {
            subDistrict: 'คลองเตยเหนือ',
            district: 'วัฒนา',
            province: 'กรุงเทพมหานคร',
            zipcode: 10110,
        },
        {
            subDistrict: 'พระโขนงเหนือ',
            district: 'วัฒนา',
            province: 'กรุงเทพมหานคร',
            zipcode: 10110,
        },
        {
            subDistrict: 'พระโขนงเหนือ',
            district: 'วัฒนา',
            province: 'กรุงเทพมหานคร',
            zipcode: 10260,
        },
        {
            subDistrict: 'สวนหลวง',
            district: 'สวนหลวง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10250,
        },
        {
            subDistrict: 'อ่อนนุช',
            district: 'สวนหลวง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10250,
        },
        {
            subDistrict: 'สะพานสูง',
            district: 'สะพานสูง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10240,
        },
        {
            subDistrict: 'สะพานสูง',
            district: 'สะพานสูง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10250,
        },
        {
            subDistrict: 'ราษฎร์พัฒนา',
            district: 'สะพานสูง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10240,
        },
        {
            subDistrict: 'ทับช้าง',
            district: 'สะพานสูง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10250,
        },
        {
            subDistrict: 'จักรวรรดิ์',
            district: 'สัมพันธวงศ์',
            province: 'กรุงเทพมหานคร',
            zipcode: 10100,
        },
        {
            subDistrict: 'ตลาดน้อย',
            district: 'สัมพันธวงศ์',
            province: 'กรุงเทพมหานคร',
            zipcode: 10100,
        },
        {
            subDistrict: 'สัมพันธวงศ์',
            district: 'สัมพันธวงศ์',
            province: 'กรุงเทพมหานคร',
            zipcode: 10100,
        },
        {
            subDistrict: 'ทุ่งมหาเมฆ',
            district: 'สาทร',
            province: 'กรุงเทพมหานคร',
            zipcode: 10120,
        },
        {
            subDistrict: 'ทุ่งวัดดอน',
            district: 'สาทร',
            province: 'กรุงเทพมหานคร',
            zipcode: 10120,
        },
        {
            subDistrict: 'ยานนาวา',
            district: 'สาทร',
            province: 'กรุงเทพมหานคร',
            zipcode: 10120,
        },
        {
            subDistrict: 'คลองถนน',
            district: 'สายไหม',
            province: 'กรุงเทพมหานคร',
            zipcode: 10220,
        },
        {
            subDistrict: 'สายไหม',
            district: 'สายไหม',
            province: 'กรุงเทพมหานคร',
            zipcode: 10220,
        },
        {
            subDistrict: 'ออเงิน',
            district: 'สายไหม',
            province: 'กรุงเทพมหานคร',
            zipcode: 10220,
        },
        {
            subDistrict: 'กระทุ่มราย',
            district: 'หนองจอก',
            province: 'กรุงเทพมหานคร',
            zipcode: 10530,
        },
        {
            subDistrict: 'คลองสิบ',
            district: 'หนองจอก',
            province: 'กรุงเทพมหานคร',
            zipcode: 10530,
        },
        {
            subDistrict: 'คลองสิบสอง',
            district: 'หนองจอก',
            province: 'กรุงเทพมหานคร',
            zipcode: 10530,
        },
        {
            subDistrict: 'คู้ฝั่งเหนือ',
            district: 'หนองจอก',
            province: 'กรุงเทพมหานคร',
            zipcode: 10530,
        },
        {
            subDistrict: 'ลำต้อยติ่ง',
            district: 'หนองจอก',
            province: 'กรุงเทพมหานคร',
            zipcode: 10530,
        },
        {
            subDistrict: 'ลำผักชี',
            district: 'หนองจอก',
            province: 'กรุงเทพมหานคร',
            zipcode: 10530,
        },
        {
            subDistrict: 'หนองจอก',
            district: 'หนองจอก',
            province: 'กรุงเทพมหานคร',
            zipcode: 10530,
        },
        {
            subDistrict: 'โคกแฝด',
            district: 'หนองจอก',
            province: 'กรุงเทพมหานคร',
            zipcode: 10530,
        },
        {
            subDistrict: 'หนองค้างพลู',
            district: 'หนองแขม',
            province: 'กรุงเทพมหานคร',
            zipcode: 10160,
        },
        {
            subDistrict: 'หนองแขม',
            district: 'หนองแขม',
            province: 'กรุงเทพมหานคร',
            zipcode: 10160,
        },
        {
            subDistrict: 'ตลาดบางเขน',
            district: 'หลักสี่',
            province: 'กรุงเทพมหานคร',
            zipcode: 10210,
        },
        {
            subDistrict: 'ทุ่งสองห้อง',
            district: 'หลักสี่',
            province: 'กรุงเทพมหานคร',
            zipcode: 10210,
        },
        {
            subDistrict: 'บางกะปิ',
            district: 'ห้วยขวาง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10310,
        },
        {
            subDistrict: 'สามเสนนอก',
            district: 'ห้วยขวาง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10310,
        },
        {
            subDistrict: 'ห้วยขวาง',
            district: 'ห้วยขวาง',
            province: 'กรุงเทพมหานคร',
            zipcode: 10310,
        },
        {
            subDistrict: 'กลอนโด',
            district: 'ด่านมะขามเตี้ย',
            province: 'กาญจนบุรี',
            zipcode: 71260,
        },
        {
            subDistrict: 'จรเข้เผือก',
            district: 'ด่านมะขามเตี้ย',
            province: 'กาญจนบุรี',
            zipcode: 71260,
        },
        {
            subDistrict: 'ด่านมะขามเตี้ย',
            district: 'ด่านมะขามเตี้ย',
            province: 'กาญจนบุรี',
            zipcode: 71260,
        },
        {
            subDistrict: 'หนองไผ่',
            district: 'ด่านมะขามเตี้ย',
            province: 'กาญจนบุรี',
            zipcode: 71260,
        },
        {
            subDistrict: 'ชะแล',
            district: 'ทองผาภูมิ',
            province: 'กาญจนบุรี',
            zipcode: 71180,
        },
        {
            subDistrict: 'ท่าขนุน',
            district: 'ทองผาภูมิ',
            province: 'กาญจนบุรี',
            zipcode: 71180,
        },
        {
            subDistrict: 'ปิล๊อก',
            district: 'ทองผาภูมิ',
            province: 'กาญจนบุรี',
            zipcode: 71180,
        },
        {
            subDistrict: 'ลิ่นถิ่น',
            district: 'ทองผาภูมิ',
            province: 'กาญจนบุรี',
            zipcode: 71180,
        },
        {
            subDistrict: 'สหกรณ์นิคม',
            district: 'ทองผาภูมิ',
            province: 'กาญจนบุรี',
            zipcode: 71180,
        },
        {
            subDistrict: 'หินดาด',
            district: 'ทองผาภูมิ',
            province: 'กาญจนบุรี',
            zipcode: 71180,
        },
        {
            subDistrict: 'ห้วยเขย่ง',
            district: 'ทองผาภูมิ',
            province: 'กาญจนบุรี',
            zipcode: 71180,
        },
        {
            subDistrict: 'ดอนขมิ้น',
            district: 'ท่ามะกา',
            province: 'กาญจนบุรี',
            zipcode: 71120,
        },
        {
            subDistrict: 'ดอนชะเอม',
            district: 'ท่ามะกา',
            province: 'กาญจนบุรี',
            zipcode: 71130,
        },
        {
            subDistrict: 'ตะคร้ำเอน',
            district: 'ท่ามะกา',
            province: 'กาญจนบุรี',
            zipcode: 71130,
        },
        {
            subDistrict: 'ท่ามะกา',
            district: 'ท่ามะกา',
            province: 'กาญจนบุรี',
            zipcode: 71120,
        },
        {
            subDistrict: 'ท่าเรือ',
            district: 'ท่ามะกา',
            province: 'กาญจนบุรี',
            zipcode: 71130,
        },
        {
            subDistrict: 'ท่าเสา',
            district: 'ท่ามะกา',
            province: 'กาญจนบุรี',
            zipcode: 71120,
        },
        {
            subDistrict: 'ท่าไม้',
            district: 'ท่ามะกา',
            province: 'กาญจนบุรี',
            zipcode: 71120,
        },
        {
            subDistrict: 'พงตึก',
            district: 'ท่ามะกา',
            province: 'กาญจนบุรี',
            zipcode: 71120,
        },
        {
            subDistrict: 'พระแท่น',
            district: 'ท่ามะกา',
            province: 'กาญจนบุรี',
            zipcode: 71130,
        },
        {
            subDistrict: 'ยางม่วง',
            district: 'ท่ามะกา',
            province: 'กาญจนบุรี',
            zipcode: 71120,
        },
        {
            subDistrict: 'สนามแย้',
            district: 'ท่ามะกา',
            province: 'กาญจนบุรี',
            zipcode: 70190,
        },
        {
            subDistrict: 'หนองลาน',
            district: 'ท่ามะกา',
            province: 'กาญจนบุรี',
            zipcode: 71130,
        },
        {
            subDistrict: 'หวายเหนียว',
            district: 'ท่ามะกา',
            province: 'กาญจนบุรี',
            zipcode: 71120,
        },
        {
            subDistrict: 'อุโลกสี่หมื่น',
            district: 'ท่ามะกา',
            province: 'กาญจนบุรี',
            zipcode: 71130,
        },
        {
            subDistrict: 'เขาสามสิบหาบ',
            district: 'ท่ามะกา',
            province: 'กาญจนบุรี',
            zipcode: 71120,
        },
        {
            subDistrict: 'แสนตอ',
            district: 'ท่ามะกา',
            province: 'กาญจนบุรี',
            zipcode: 71130,
        },
        {
            subDistrict: 'โคกตะบอง',
            district: 'ท่ามะกา',
            province: 'กาญจนบุรี',
            zipcode: 71120,
        },
        {
            subDistrict: 'ทุ่งทอง',
            district: 'ท่าม่วง',
            province: 'กาญจนบุรี',
            zipcode: 71110,
        },
        {
            subDistrict: 'ท่าตะคร้อ',
            district: 'ท่าม่วง',
            province: 'กาญจนบุรี',
            zipcode: 71130,
        },
        {
            subDistrict: 'ท่าม่วง',
            district: 'ท่าม่วง',
            province: 'กาญจนบุรี',
            zipcode: 71110,
        },
        {
            subDistrict: 'ท่าล้อ',
            district: 'ท่าม่วง',
            province: 'กาญจนบุรี',
            zipcode: 71000,
        },
        {
            subDistrict: 'บ้านใหม่',
            district: 'ท่าม่วง',
            province: 'กาญจนบุรี',
            zipcode: 71110,
        },
        {
            subDistrict: 'พังตรุ',
            district: 'ท่าม่วง',
            province: 'กาญจนบุรี',
            zipcode: 71110,
        },
        {
            subDistrict: 'ม่วงชุม',
            district: 'ท่าม่วง',
            province: 'กาญจนบุรี',
            zipcode: 71110,
        },
        {
            subDistrict: 'รางสาลี่',
            district: 'ท่าม่วง',
            province: 'กาญจนบุรี',
            zipcode: 71110,
        },
        {
            subDistrict: 'วังขนาย',
            district: 'ท่าม่วง',
            province: 'กาญจนบุรี',
            zipcode: 71110,
        },
        {
            subDistrict: 'วังศาลา',
            district: 'ท่าม่วง',
            province: 'กาญจนบุรี',
            zipcode: 71110,
        },
        {
            subDistrict: 'หนองขาว',
            district: 'ท่าม่วง',
            province: 'กาญจนบุรี',
            zipcode: 71110,
        },
        {
            subDistrict: 'หนองตากยา',
            district: 'ท่าม่วง',
            province: 'กาญจนบุรี',
            zipcode: 71110,
        },
        {
            subDistrict: 'เขาน้อย',
            district: 'ท่าม่วง',
            province: 'กาญจนบุรี',
            zipcode: 71110,
        },
        {
            subDistrict: 'ช่องด่าน',
            district: 'บ่อพลอย',
            province: 'กาญจนบุรี',
            zipcode: 71160,
        },
        {
            subDistrict: 'บ่อพลอย',
            district: 'บ่อพลอย',
            province: 'กาญจนบุรี',
            zipcode: 71160,
        },
        {
            subDistrict: 'หนองกร่าง',
            district: 'บ่อพลอย',
            province: 'กาญจนบุรี',
            zipcode: 71220,
        },
        {
            subDistrict: 'หนองกุ่ม',
            district: 'บ่อพลอย',
            province: 'กาญจนบุรี',
            zipcode: 71160,
        },
        {
            subDistrict: 'หนองรี',
            district: 'บ่อพลอย',
            province: 'กาญจนบุรี',
            zipcode: 71220,
        },
        {
            subDistrict: 'หลุมรัง',
            district: 'บ่อพลอย',
            province: 'กาญจนบุรี',
            zipcode: 71160,
        },
        {
            subDistrict: 'ดอนตาเพชร',
            district: 'พนมทวน',
            province: 'กาญจนบุรี',
            zipcode: 71140,
        },
        {
            subDistrict: 'ดอนเจดีย์',
            district: 'พนมทวน',
            province: 'กาญจนบุรี',
            zipcode: 71140,
        },
        {
            subDistrict: 'ทุ่งสมอ',
            district: 'พนมทวน',
            province: 'กาญจนบุรี',
            zipcode: 71140,
        },
        {
            subDistrict: 'พนมทวน',
            district: 'พนมทวน',
            province: 'กาญจนบุรี',
            zipcode: 71140,
        },
        {
            subDistrict: 'พังตรุ',
            district: 'พนมทวน',
            province: 'กาญจนบุรี',
            zipcode: 71140,
        },
        {
            subDistrict: 'รางหวาย',
            district: 'พนมทวน',
            province: 'กาญจนบุรี',
            zipcode: 71170,
        },
        {
            subDistrict: 'หนองสาหร่าย',
            district: 'พนมทวน',
            province: 'กาญจนบุรี',
            zipcode: 71140,
        },
        {
            subDistrict: 'หนองโรง',
            district: 'พนมทวน',
            province: 'กาญจนบุรี',
            zipcode: 71140,
        },
        {
            subDistrict: 'ด่านแม่แฉลบ',
            district: 'ศรีสวัสดิ์',
            province: 'กาญจนบุรี',
            zipcode: 71250,
        },
        {
            subDistrict: 'ท่ากระดาน',
            district: 'ศรีสวัสดิ์',
            province: 'กาญจนบุรี',
            zipcode: 71250,
        },
        {
            subDistrict: 'นาสวน',
            district: 'ศรีสวัสดิ์',
            province: 'กาญจนบุรี',
            zipcode: 71250,
        },
        {
            subDistrict: 'หนองเป็ด',
            district: 'ศรีสวัสดิ์',
            province: 'กาญจนบุรี',
            zipcode: 71250,
        },
        {
            subDistrict: 'เขาโจด',
            district: 'ศรีสวัสดิ์',
            province: 'กาญจนบุรี',
            zipcode: 71220,
        },
        {
            subDistrict: 'แม่กระบุง',
            district: 'ศรีสวัสดิ์',
            province: 'กาญจนบุรี',
            zipcode: 71250,
        },
        {
            subDistrict: 'ปรังเผล',
            district: 'สังขละบุรี',
            province: 'กาญจนบุรี',
            zipcode: 71240,
        },
        {
            subDistrict: 'หนองลู',
            district: 'สังขละบุรี',
            province: 'กาญจนบุรี',
            zipcode: 71240,
        },
        {
            subDistrict: 'ไล่โว่',
            district: 'สังขละบุรี',
            province: 'กาญจนบุรี',
            zipcode: 71240,
        },
        {
            subDistrict: 'สมเด็จเจริญ',
            district: 'หนองปรือ',
            province: 'กาญจนบุรี',
            zipcode: 71220,
        },
        {
            subDistrict: 'หนองปรือ',
            district: 'หนองปรือ',
            province: 'กาญจนบุรี',
            zipcode: 71220,
        },
        {
            subDistrict: 'หนองปลาไหล',
            district: 'หนองปรือ',
            province: 'กาญจนบุรี',
            zipcode: 71220,
        },
        {
            subDistrict: 'ดอนแสลบ',
            district: 'ห้วยกระเจา',
            province: 'กาญจนบุรี',
            zipcode: 71170,
        },
        {
            subDistrict: 'วังไผ่',
            district: 'ห้วยกระเจา',
            province: 'กาญจนบุรี',
            zipcode: 71170,
        },
        {
            subDistrict: 'สระลงเรือ',
            district: 'ห้วยกระเจา',
            province: 'กาญจนบุรี',
            zipcode: 71170,
        },
        {
            subDistrict: 'ห้วยกระเจา',
            district: 'ห้วยกระเจา',
            province: 'กาญจนบุรี',
            zipcode: 71170,
        },
        {
            subDistrict: 'ช่องสะเดา',
            district: 'เมืองกาญจนบุรี',
            province: 'กาญจนบุรี',
            zipcode: 71190,
        },
        {
            subDistrict: 'ท่ามะขาม',
            district: 'เมืองกาญจนบุรี',
            province: 'กาญจนบุรี',
            zipcode: 71000,
        },
        {
            subDistrict: 'บ้านเก่า',
            district: 'เมืองกาญจนบุรี',
            province: 'กาญจนบุรี',
            zipcode: 71000,
        },
        {
            subDistrict: 'บ้านเหนือ',
            district: 'เมืองกาญจนบุรี',
            province: 'กาญจนบุรี',
            zipcode: 71000,
        },
        {
            subDistrict: 'บ้านใต้',
            district: 'เมืองกาญจนบุรี',
            province: 'กาญจนบุรี',
            zipcode: 71000,
        },
        {
            subDistrict: 'ปากแพรก',
            district: 'เมืองกาญจนบุรี',
            province: 'กาญจนบุรี',
            zipcode: 71000,
        },
        {
            subDistrict: 'ลาดหญ้า',
            district: 'เมืองกาญจนบุรี',
            province: 'กาญจนบุรี',
            zipcode: 71190,
        },
        {
            subDistrict: 'วังด้ง',
            district: 'เมืองกาญจนบุรี',
            province: 'กาญจนบุรี',
            zipcode: 71190,
        },
        {
            subDistrict: 'วังเย็น',
            district: 'เมืองกาญจนบุรี',
            province: 'กาญจนบุรี',
            zipcode: 71000,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'เมืองกาญจนบุรี',
            province: 'กาญจนบุรี',
            zipcode: 71190,
        },
        {
            subDistrict: 'หนองหญ้า',
            district: 'เมืองกาญจนบุรี',
            province: 'กาญจนบุรี',
            zipcode: 71000,
        },
        {
            subDistrict: 'เกาะสำโรง',
            district: 'เมืองกาญจนบุรี',
            province: 'กาญจนบุรี',
            zipcode: 71000,
        },
        {
            subDistrict: 'แก่งเสี้ยน',
            district: 'เมืองกาญจนบุรี',
            province: 'กาญจนบุรี',
            zipcode: 71000,
        },
        {
            subDistrict: 'ทุ่งกระบ่ำ',
            district: 'เลาขวัญ',
            province: 'กาญจนบุรี',
            zipcode: 71210,
        },
        {
            subDistrict: 'หนองนกแก้ว',
            district: 'เลาขวัญ',
            province: 'กาญจนบุรี',
            zipcode: 71210,
        },
        {
            subDistrict: 'หนองประดู่',
            district: 'เลาขวัญ',
            province: 'กาญจนบุรี',
            zipcode: 71210,
        },
        {
            subDistrict: 'หนองปลิง',
            district: 'เลาขวัญ',
            province: 'กาญจนบุรี',
            zipcode: 71210,
        },
        {
            subDistrict: 'หนองฝ้าย',
            district: 'เลาขวัญ',
            province: 'กาญจนบุรี',
            zipcode: 71210,
        },
        {
            subDistrict: 'หนองโสน',
            district: 'เลาขวัญ',
            province: 'กาญจนบุรี',
            zipcode: 71210,
        },
        {
            subDistrict: 'เลาขวัญ',
            district: 'เลาขวัญ',
            province: 'กาญจนบุรี',
            zipcode: 71210,
        },
        {
            subDistrict: 'ท่าเสา',
            district: 'ไทรโยค',
            province: 'กาญจนบุรี',
            zipcode: 71150,
        },
        {
            subDistrict: 'บ้องตี้',
            district: 'ไทรโยค',
            province: 'กาญจนบุรี',
            zipcode: 71150,
        },
        {
            subDistrict: 'ลุ่มสุ่ม',
            district: 'ไทรโยค',
            province: 'กาญจนบุรี',
            zipcode: 71150,
        },
        {
            subDistrict: 'วังกระแจะ',
            district: 'ไทรโยค',
            province: 'กาญจนบุรี',
            zipcode: 71150,
        },
        {
            subDistrict: 'ศรีมงคล',
            district: 'ไทรโยค',
            province: 'กาญจนบุรี',
            zipcode: 71150,
        },
        {
            subDistrict: 'สิงห์',
            district: 'ไทรโยค',
            province: 'กาญจนบุรี',
            zipcode: 71150,
        },
        {
            subDistrict: 'ไทรโยค',
            district: 'ไทรโยค',
            province: 'กาญจนบุรี',
            zipcode: 71150,
        },
        {
            subDistrict: 'กมลาไสย',
            district: 'กมลาไสย',
            province: 'กาฬสินธุ์',
            zipcode: 46130,
        },
        {
            subDistrict: 'ดงลิง',
            district: 'กมลาไสย',
            province: 'กาฬสินธุ์',
            zipcode: 46130,
        },
        {
            subDistrict: 'ธัญญา',
            district: 'กมลาไสย',
            province: 'กาฬสินธุ์',
            zipcode: 46130,
        },
        {
            subDistrict: 'หนองแปน',
            district: 'กมลาไสย',
            province: 'กาฬสินธุ์',
            zipcode: 46130,
        },
        {
            subDistrict: 'หลักเมือง',
            district: 'กมลาไสย',
            province: 'กาฬสินธุ์',
            zipcode: 46130,
        },
        {
            subDistrict: 'เจ้าท่า',
            district: 'กมลาไสย',
            province: 'กาฬสินธุ์',
            zipcode: 46130,
        },
        {
            subDistrict: 'โคกสมบูรณ์',
            district: 'กมลาไสย',
            province: 'กาฬสินธุ์',
            zipcode: 46130,
        },
        {
            subDistrict: 'โพนงาม',
            district: 'กมลาไสย',
            province: 'กาฬสินธุ์',
            zipcode: 46130,
        },
        {
            subDistrict: 'กุดค้าว',
            district: 'กุฉินารายณ์',
            province: 'กาฬสินธุ์',
            zipcode: 46110,
        },
        {
            subDistrict: 'กุดหว้า',
            district: 'กุฉินารายณ์',
            province: 'กาฬสินธุ์',
            zipcode: 46110,
        },
        {
            subDistrict: 'จุมจัง',
            district: 'กุฉินารายณ์',
            province: 'กาฬสินธุ์',
            zipcode: 46110,
        },
        {
            subDistrict: 'นาขาม',
            district: 'กุฉินารายณ์',
            province: 'กาฬสินธุ์',
            zipcode: 46110,
        },
        {
            subDistrict: 'นาโก',
            district: 'กุฉินารายณ์',
            province: 'กาฬสินธุ์',
            zipcode: 46110,
        },
        {
            subDistrict: 'บัวขาว',
            district: 'กุฉินารายณ์',
            province: 'กาฬสินธุ์',
            zipcode: 46110,
        },
        {
            subDistrict: 'สมสะอาด',
            district: 'กุฉินารายณ์',
            province: 'กาฬสินธุ์',
            zipcode: 46110,
        },
        {
            subDistrict: 'สามขา',
            district: 'กุฉินารายณ์',
            province: 'กาฬสินธุ์',
            zipcode: 46110,
        },
        {
            subDistrict: 'หนองห้าง',
            district: 'กุฉินารายณ์',
            province: 'กาฬสินธุ์',
            zipcode: 46110,
        },
        {
            subDistrict: 'เหล่าใหญ่',
            district: 'กุฉินารายณ์',
            province: 'กาฬสินธุ์',
            zipcode: 46110,
        },
        {
            subDistrict: 'เหล่าไฮงาม',
            district: 'กุฉินารายณ์',
            province: 'กาฬสินธุ์',
            zipcode: 46110,
        },
        {
            subDistrict: 'แจนแลน',
            district: 'กุฉินารายณ์',
            province: 'กาฬสินธุ์',
            zipcode: 46110,
        },
        {
            subDistrict: 'ดินจี่',
            district: 'คำม่วง',
            province: 'กาฬสินธุ์',
            zipcode: 46180,
        },
        {
            subDistrict: 'ทุ่งคลอง',
            district: 'คำม่วง',
            province: 'กาฬสินธุ์',
            zipcode: 46180,
        },
        {
            subDistrict: 'นาทัน',
            district: 'คำม่วง',
            province: 'กาฬสินธุ์',
            zipcode: 46180,
        },
        {
            subDistrict: 'นาบอน',
            district: 'คำม่วง',
            province: 'กาฬสินธุ์',
            zipcode: 46180,
        },
        {
            subDistrict: 'เนินยาง',
            district: 'คำม่วง',
            province: 'กาฬสินธุ์',
            zipcode: 46180,
        },
        {
            subDistrict: 'โพน',
            district: 'คำม่วง',
            province: 'กาฬสินธุ์',
            zipcode: 46180,
        },
        {
            subDistrict: 'ฆ้องชัยพัฒนา',
            district: 'ฆ้องชัย',
            province: 'กาฬสินธุ์',
            zipcode: 46130,
        },
        {
            subDistrict: 'ลำชี',
            district: 'ฆ้องชัย',
            province: 'กาฬสินธุ์',
            zipcode: 46130,
        },
        {
            subDistrict: 'เหล่ากลาง',
            district: 'ฆ้องชัย',
            province: 'กาฬสินธุ์',
            zipcode: 46130,
        },
        {
            subDistrict: 'โคกสะอาด',
            district: 'ฆ้องชัย',
            province: 'กาฬสินธุ์',
            zipcode: 46130,
        },
        {
            subDistrict: 'โนนศิลาเลิง',
            district: 'ฆ้องชัย',
            province: 'กาฬสินธุ์',
            zipcode: 46130,
        },
        {
            subDistrict: 'ดงพยุง',
            district: 'ดอนจาน',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'ดอนจาน',
            district: 'ดอนจาน',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'นาจำปา',
            district: 'ดอนจาน',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'ม่วงนา',
            district: 'ดอนจาน',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'สะอาดไชยศรี',
            district: 'ดอนจาน',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'กุงเก่า',
            district: 'ท่าคันโท',
            province: 'กาฬสินธุ์',
            zipcode: 46190,
        },
        {
            subDistrict: 'กุดจิก',
            district: 'ท่าคันโท',
            province: 'กาฬสินธุ์',
            zipcode: 46190,
        },
        {
            subDistrict: 'ดงสมบูรณ์',
            district: 'ท่าคันโท',
            province: 'กาฬสินธุ์',
            zipcode: 46190,
        },
        {
            subDistrict: 'ท่าคันโท',
            district: 'ท่าคันโท',
            province: 'กาฬสินธุ์',
            zipcode: 46190,
        },
        {
            subDistrict: 'นาตาล',
            district: 'ท่าคันโท',
            province: 'กาฬสินธุ์',
            zipcode: 46190,
        },
        {
            subDistrict: 'ยางอู้ม',
            district: 'ท่าคันโท',
            province: 'กาฬสินธุ์',
            zipcode: 46190,
        },
        {
            subDistrict: 'นาคู',
            district: 'นาคู',
            province: 'กาฬสินธุ์',
            zipcode: 46160,
        },
        {
            subDistrict: 'บ่อแก้ว',
            district: 'นาคู',
            province: 'กาฬสินธุ์',
            zipcode: 46160,
        },
        {
            subDistrict: 'ภูแล่นช้าง',
            district: 'นาคู',
            province: 'กาฬสินธุ์',
            zipcode: 46160,
        },
        {
            subDistrict: 'สายนาวัง',
            district: 'นาคู',
            province: 'กาฬสินธุ์',
            zipcode: 46160,
        },
        {
            subDistrict: 'โนนนาจาน',
            district: 'นาคู',
            province: 'กาฬสินธุ์',
            zipcode: 46160,
        },
        {
            subDistrict: 'นามน',
            district: 'นามน',
            province: 'กาฬสินธุ์',
            zipcode: 46230,
        },
        {
            subDistrict: 'ยอดแกง',
            district: 'นามน',
            province: 'กาฬสินธุ์',
            zipcode: 46230,
        },
        {
            subDistrict: 'สงเปลือย',
            district: 'นามน',
            province: 'กาฬสินธุ์',
            zipcode: 46230,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'นามน',
            province: 'กาฬสินธุ์',
            zipcode: 46230,
        },
        {
            subDistrict: 'หลักเหลี่ยม',
            district: 'นามน',
            province: 'กาฬสินธุ์',
            zipcode: 46230,
        },
        {
            subDistrict: 'คลองขาม',
            district: 'ยางตลาด',
            province: 'กาฬสินธุ์',
            zipcode: 46120,
        },
        {
            subDistrict: 'ดอนสมบูรณ์',
            district: 'ยางตลาด',
            province: 'กาฬสินธุ์',
            zipcode: 46120,
        },
        {
            subDistrict: 'นาดี',
            district: 'ยางตลาด',
            province: 'กาฬสินธุ์',
            zipcode: 46120,
        },
        {
            subDistrict: 'นาเชือก',
            district: 'ยางตลาด',
            province: 'กาฬสินธุ์',
            zipcode: 46120,
        },
        {
            subDistrict: 'บัวบาน',
            district: 'ยางตลาด',
            province: 'กาฬสินธุ์',
            zipcode: 46120,
        },
        {
            subDistrict: 'ยางตลาด',
            district: 'ยางตลาด',
            province: 'กาฬสินธุ์',
            zipcode: 46120,
        },
        {
            subDistrict: 'หนองตอกแป้น',
            district: 'ยางตลาด',
            province: 'กาฬสินธุ์',
            zipcode: 46120,
        },
        {
            subDistrict: 'หนองอิเฒ่า',
            district: 'ยางตลาด',
            province: 'กาฬสินธุ์',
            zipcode: 46120,
        },
        {
            subDistrict: 'หัวงัว',
            district: 'ยางตลาด',
            province: 'กาฬสินธุ์',
            zipcode: 46120,
        },
        {
            subDistrict: 'หัวนาคำ',
            district: 'ยางตลาด',
            province: 'กาฬสินธุ์',
            zipcode: 46120,
        },
        {
            subDistrict: 'อิตื้อ',
            district: 'ยางตลาด',
            province: 'กาฬสินธุ์',
            zipcode: 46120,
        },
        {
            subDistrict: 'อุ่มเม่า',
            district: 'ยางตลาด',
            province: 'กาฬสินธุ์',
            zipcode: 46120,
        },
        {
            subDistrict: 'เขาพระนอน',
            district: 'ยางตลาด',
            province: 'กาฬสินธุ์',
            zipcode: 46120,
        },
        {
            subDistrict: 'เว่อ',
            district: 'ยางตลาด',
            province: 'กาฬสินธุ์',
            zipcode: 46120,
        },
        {
            subDistrict: 'โนนสูง',
            district: 'ยางตลาด',
            province: 'กาฬสินธุ์',
            zipcode: 46120,
        },
        {
            subDistrict: 'ร่องคำ',
            district: 'ร่องคำ',
            province: 'กาฬสินธุ์',
            zipcode: 46210,
        },
        {
            subDistrict: 'สามัคคี',
            district: 'ร่องคำ',
            province: 'กาฬสินธุ์',
            zipcode: 46210,
        },
        {
            subDistrict: 'เหล่าอ้อย',
            district: 'ร่องคำ',
            province: 'กาฬสินธุ์',
            zipcode: 46210,
        },
        {
            subDistrict: 'ผาเสวย',
            district: 'สมเด็จ',
            province: 'กาฬสินธุ์',
            zipcode: 46150,
        },
        {
            subDistrict: 'มหาไชย',
            district: 'สมเด็จ',
            province: 'กาฬสินธุ์',
            zipcode: 46150,
        },
        {
            subDistrict: 'ลำห้วยหลัว',
            district: 'สมเด็จ',
            province: 'กาฬสินธุ์',
            zipcode: 46150,
        },
        {
            subDistrict: 'ศรีสมเด็จ',
            district: 'สมเด็จ',
            province: 'กาฬสินธุ์',
            zipcode: 46150,
        },
        {
            subDistrict: 'สมเด็จ',
            district: 'สมเด็จ',
            province: 'กาฬสินธุ์',
            zipcode: 46150,
        },
        {
            subDistrict: 'หนองแวง',
            district: 'สมเด็จ',
            province: 'กาฬสินธุ์',
            zipcode: 46150,
        },
        {
            subDistrict: 'หมูม่น',
            district: 'สมเด็จ',
            province: 'กาฬสินธุ์',
            zipcode: 46150,
        },
        {
            subDistrict: 'แซงบาดาล',
            district: 'สมเด็จ',
            province: 'กาฬสินธุ์',
            zipcode: 46150,
        },
        {
            subDistrict: 'นามะเขือ',
            district: 'สหัสขันธ์',
            province: 'กาฬสินธุ์',
            zipcode: 46140,
        },
        {
            subDistrict: 'นิคม',
            district: 'สหัสขันธ์',
            province: 'กาฬสินธุ์',
            zipcode: 46140,
        },
        {
            subDistrict: 'ภูสิงห์',
            district: 'สหัสขันธ์',
            province: 'กาฬสินธุ์',
            zipcode: 46140,
        },
        {
            subDistrict: 'สหัสขันธ์',
            district: 'สหัสขันธ์',
            province: 'กาฬสินธุ์',
            zipcode: 46140,
        },
        {
            subDistrict: 'โนนน้ำเกลี้ยง',
            district: 'สหัสขันธ์',
            province: 'กาฬสินธุ์',
            zipcode: 46140,
        },
        {
            subDistrict: 'โนนบุรี',
            district: 'สหัสขันธ์',
            province: 'กาฬสินธุ์',
            zipcode: 46140,
        },
        {
            subDistrict: 'โนนศิลา',
            district: 'สหัสขันธ์',
            province: 'กาฬสินธุ์',
            zipcode: 46140,
        },
        {
            subDistrict: 'โนนแหลมทอง',
            district: 'สหัสขันธ์',
            province: 'กาฬสินธุ์',
            zipcode: 46140,
        },
        {
            subDistrict: 'คำสร้างเที่ยง',
            district: 'สามชัย',
            province: 'กาฬสินธุ์',
            zipcode: 46180,
        },
        {
            subDistrict: 'สำราญ',
            district: 'สามชัย',
            province: 'กาฬสินธุ์',
            zipcode: 46180,
        },
        {
            subDistrict: 'สำราญใต้',
            district: 'สามชัย',
            province: 'กาฬสินธุ์',
            zipcode: 46180,
        },
        {
            subDistrict: 'หนองช้าง',
            district: 'สามชัย',
            province: 'กาฬสินธุ์',
            zipcode: 46180,
        },
        {
            subDistrict: 'ดงมูล',
            district: 'หนองกุงศรี',
            province: 'กาฬสินธุ์',
            zipcode: 46220,
        },
        {
            subDistrict: 'ลำหนองแสน',
            district: 'หนองกุงศรี',
            province: 'กาฬสินธุ์',
            zipcode: 46220,
        },
        {
            subDistrict: 'หนองกุงศรี',
            district: 'หนองกุงศรี',
            province: 'กาฬสินธุ์',
            zipcode: 46220,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'หนองกุงศรี',
            province: 'กาฬสินธุ์',
            zipcode: 46220,
        },
        {
            subDistrict: 'หนองสรวง',
            district: 'หนองกุงศรี',
            province: 'กาฬสินธุ์',
            zipcode: 46220,
        },
        {
            subDistrict: 'หนองหิน',
            district: 'หนองกุงศรี',
            province: 'กาฬสินธุ์',
            zipcode: 46220,
        },
        {
            subDistrict: 'หนองใหญ่',
            district: 'หนองกุงศรี',
            province: 'กาฬสินธุ์',
            zipcode: 46220,
        },
        {
            subDistrict: 'เสาเล้า',
            district: 'หนองกุงศรี',
            province: 'กาฬสินธุ์',
            zipcode: 46220,
        },
        {
            subDistrict: 'โคกเครือ',
            district: 'หนองกุงศรี',
            province: 'กาฬสินธุ์',
            zipcode: 46220,
        },
        {
            subDistrict: 'คำบง',
            district: 'ห้วยผึ้ง',
            province: 'กาฬสินธุ์',
            zipcode: 46240,
        },
        {
            subDistrict: 'นิคมห้วยผึ้ง',
            district: 'ห้วยผึ้ง',
            province: 'กาฬสินธุ์',
            zipcode: 46240,
        },
        {
            subDistrict: 'หนองอีบุตร',
            district: 'ห้วยผึ้ง',
            province: 'กาฬสินธุ์',
            zipcode: 46240,
        },
        {
            subDistrict: 'ไค้นุ่น',
            district: 'ห้วยผึ้ง',
            province: 'กาฬสินธุ์',
            zipcode: 46240,
        },
        {
            subDistrict: 'กุดโดน',
            district: 'ห้วยเม็ก',
            province: 'กาฬสินธุ์',
            zipcode: 46170,
        },
        {
            subDistrict: 'คำเหมือดแก้ว',
            district: 'ห้วยเม็ก',
            province: 'กาฬสินธุ์',
            zipcode: 46170,
        },
        {
            subDistrict: 'คำใหญ่',
            district: 'ห้วยเม็ก',
            province: 'กาฬสินธุ์',
            zipcode: 46170,
        },
        {
            subDistrict: 'ทรายทอง',
            district: 'ห้วยเม็ก',
            province: 'กาฬสินธุ์',
            zipcode: 46170,
        },
        {
            subDistrict: 'บึงนาเรียง',
            district: 'ห้วยเม็ก',
            province: 'กาฬสินธุ์',
            zipcode: 46170,
        },
        {
            subDistrict: 'พิมูล',
            district: 'ห้วยเม็ก',
            province: 'กาฬสินธุ์',
            zipcode: 46170,
        },
        {
            subDistrict: 'หัวหิน',
            district: 'ห้วยเม็ก',
            province: 'กาฬสินธุ์',
            zipcode: 46170,
        },
        {
            subDistrict: 'ห้วยเม็ก',
            district: 'ห้วยเม็ก',
            province: 'กาฬสินธุ์',
            zipcode: 46170,
        },
        {
            subDistrict: 'โนนสะอาด',
            district: 'ห้วยเม็ก',
            province: 'กาฬสินธุ์',
            zipcode: 46170,
        },
        {
            subDistrict: 'กุดปลาค้าว',
            district: 'เขาวง',
            province: 'กาฬสินธุ์',
            zipcode: 46160,
        },
        {
            subDistrict: 'กุดสิมคุ้มใหม่',
            district: 'เขาวง',
            province: 'กาฬสินธุ์',
            zipcode: 46160,
        },
        {
            subDistrict: 'คุ้มเก่า',
            district: 'เขาวง',
            province: 'กาฬสินธุ์',
            zipcode: 46160,
        },
        {
            subDistrict: 'สงเปลือย',
            district: 'เขาวง',
            province: 'กาฬสินธุ์',
            zipcode: 46160,
        },
        {
            subDistrict: 'สระพังทอง',
            district: 'เขาวง',
            province: 'กาฬสินธุ์',
            zipcode: 46160,
        },
        {
            subDistrict: 'หนองผือ',
            district: 'เขาวง',
            province: 'กาฬสินธุ์',
            zipcode: 46160,
        },
        {
            subDistrict: 'กลางหมื่น',
            district: 'เมืองกาฬสินธุ์',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'กาฬสินธุ์',
            district: 'เมืองกาฬสินธุ์',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'ขมิ้น',
            district: 'เมืองกาฬสินธุ์',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'นาจารย์',
            district: 'เมืองกาฬสินธุ์',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'บึงวิชัย',
            district: 'เมืองกาฬสินธุ์',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'ภูดิน',
            district: 'เมืองกาฬสินธุ์',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'ภูปอ',
            district: 'เมืองกาฬสินธุ์',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'ลำคลอง',
            district: 'เมืองกาฬสินธุ์',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'ลำปาว',
            district: 'เมืองกาฬสินธุ์',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'ลำพาน',
            district: 'เมืองกาฬสินธุ์',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'หนองกุง',
            district: 'เมืองกาฬสินธุ์',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'หลุบ',
            district: 'เมืองกาฬสินธุ์',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'ห้วยโพธิ์',
            district: 'เมืองกาฬสินธุ์',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'เชียงเครือ',
            district: 'เมืองกาฬสินธุ์',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'เหนือ',
            district: 'เมืองกาฬสินธุ์',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'โพนทอง',
            district: 'เมืองกาฬสินธุ์',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'ไผ่',
            district: 'เมืองกาฬสินธุ์',
            province: 'กาฬสินธุ์',
            zipcode: 46000,
        },
        {
            subDistrict: 'ดอนแตง',
            district: 'ขาณุวรลักษบุรี',
            province: 'กำแพงเพชร',
            zipcode: 62140,
        },
        {
            subDistrict: 'บ่อถ้ำ',
            district: 'ขาณุวรลักษบุรี',
            province: 'กำแพงเพชร',
            zipcode: 62140,
        },
        {
            subDistrict: 'ปางมะค่า',
            district: 'ขาณุวรลักษบุรี',
            province: 'กำแพงเพชร',
            zipcode: 62140,
        },
        {
            subDistrict: 'ป่าพุทรา',
            district: 'ขาณุวรลักษบุรี',
            province: 'กำแพงเพชร',
            zipcode: 62130,
        },
        {
            subDistrict: 'ยางสูง',
            district: 'ขาณุวรลักษบุรี',
            province: 'กำแพงเพชร',
            zipcode: 62130,
        },
        {
            subDistrict: 'วังชะพลู',
            district: 'ขาณุวรลักษบุรี',
            province: 'กำแพงเพชร',
            zipcode: 62140,
        },
        {
            subDistrict: 'วังหามแห',
            district: 'ขาณุวรลักษบุรี',
            province: 'กำแพงเพชร',
            zipcode: 62140,
        },
        {
            subDistrict: 'สลกบาตร',
            district: 'ขาณุวรลักษบุรี',
            province: 'กำแพงเพชร',
            zipcode: 62140,
        },
        {
            subDistrict: 'เกาะตาล',
            district: 'ขาณุวรลักษบุรี',
            province: 'กำแพงเพชร',
            zipcode: 62130,
        },
        {
            subDistrict: 'แสนตอ',
            district: 'ขาณุวรลักษบุรี',
            province: 'กำแพงเพชร',
            zipcode: 62130,
        },
        {
            subDistrict: 'โค้งไผ่',
            district: 'ขาณุวรลักษบุรี',
            province: 'กำแพงเพชร',
            zipcode: 62140,
        },
        {
            subDistrict: 'คลองขลุง',
            district: 'คลองขลุง',
            province: 'กำแพงเพชร',
            zipcode: 62120,
        },
        {
            subDistrict: 'คลองสมบูรณ์',
            district: 'คลองขลุง',
            province: 'กำแพงเพชร',
            zipcode: 62120,
        },
        {
            subDistrict: 'ท่าพุทรา',
            district: 'คลองขลุง',
            province: 'กำแพงเพชร',
            zipcode: 62120,
        },
        {
            subDistrict: 'ท่ามะเขือ',
            district: 'คลองขลุง',
            province: 'กำแพงเพชร',
            zipcode: 62120,
        },
        {
            subDistrict: 'วังบัว',
            district: 'คลองขลุง',
            province: 'กำแพงเพชร',
            zipcode: 62120,
        },
        {
            subDistrict: 'วังยาง',
            district: 'คลองขลุง',
            province: 'กำแพงเพชร',
            zipcode: 62120,
        },
        {
            subDistrict: 'วังแขม',
            district: 'คลองขลุง',
            province: 'กำแพงเพชร',
            zipcode: 62120,
        },
        {
            subDistrict: 'วังไทร',
            district: 'คลองขลุง',
            province: 'กำแพงเพชร',
            zipcode: 62120,
        },
        {
            subDistrict: 'หัวถนน',
            district: 'คลองขลุง',
            province: 'กำแพงเพชร',
            zipcode: 62120,
        },
        {
            subDistrict: 'แม่ลาด',
            district: 'คลองขลุง',
            province: 'กำแพงเพชร',
            zipcode: 62120,
        },
        {
            subDistrict: 'คลองน้ำไหล',
            district: 'คลองลาน',
            province: 'กำแพงเพชร',
            zipcode: 62180,
        },
        {
            subDistrict: 'คลองลานพัฒนา',
            district: 'คลองลาน',
            province: 'กำแพงเพชร',
            zipcode: 62180,
        },
        {
            subDistrict: 'สักงาม',
            district: 'คลองลาน',
            province: 'กำแพงเพชร',
            zipcode: 62180,
        },
        {
            subDistrict: 'โป่งน้ำร้อน',
            district: 'คลองลาน',
            province: 'กำแพงเพชร',
            zipcode: 62180,
        },
        {
            subDistrict: 'ถาวรวัฒนา',
            district: 'ทรายทองวัฒนา',
            province: 'กำแพงเพชร',
            zipcode: 62190,
        },
        {
            subDistrict: 'ทุ่งทราย',
            district: 'ทรายทองวัฒนา',
            province: 'กำแพงเพชร',
            zipcode: 62190,
        },
        {
            subDistrict: 'ทุ่งทอง',
            district: 'ทรายทองวัฒนา',
            province: 'กำแพงเพชร',
            zipcode: 62190,
        },
        {
            subDistrict: 'บึงสามัคคี',
            district: 'บึงสามัคคี',
            province: 'กำแพงเพชร',
            zipcode: 62210,
        },
        {
            subDistrict: 'ระหาน',
            district: 'บึงสามัคคี',
            province: 'กำแพงเพชร',
            zipcode: 62210,
        },
        {
            subDistrict: 'วังชะโอน',
            district: 'บึงสามัคคี',
            province: 'กำแพงเพชร',
            zipcode: 62210,
        },
        {
            subDistrict: 'เทพนิมิต',
            district: 'บึงสามัคคี',
            province: 'กำแพงเพชร',
            zipcode: 62210,
        },
        {
            subDistrict: 'ปางตาไว',
            district: 'ปางศิลาทอง',
            province: 'กำแพงเพชร',
            zipcode: 62120,
        },
        {
            subDistrict: 'หินดาต',
            district: 'ปางศิลาทอง',
            province: 'กำแพงเพชร',
            zipcode: 62120,
        },
        {
            subDistrict: 'โพธิ์ทอง',
            district: 'ปางศิลาทอง',
            province: 'กำแพงเพชร',
            zipcode: 62120,
        },
        {
            subDistrict: 'คลองพิไกร',
            district: 'พรานกระต่าย',
            province: 'กำแพงเพชร',
            zipcode: 62110,
        },
        {
            subDistrict: 'คุยบ้านโอง',
            district: 'พรานกระต่าย',
            province: 'กำแพงเพชร',
            zipcode: 62110,
        },
        {
            subDistrict: 'ถ้ำกระต่ายทอง',
            district: 'พรานกระต่าย',
            province: 'กำแพงเพชร',
            zipcode: 62110,
        },
        {
            subDistrict: 'ท่าไม้',
            district: 'พรานกระต่าย',
            province: 'กำแพงเพชร',
            zipcode: 62110,
        },
        {
            subDistrict: 'พรานกระต่าย',
            district: 'พรานกระต่าย',
            province: 'กำแพงเพชร',
            zipcode: 62110,
        },
        {
            subDistrict: 'วังควง',
            district: 'พรานกระต่าย',
            province: 'กำแพงเพชร',
            zipcode: 62110,
        },
        {
            subDistrict: 'วังตะแบก',
            district: 'พรานกระต่าย',
            province: 'กำแพงเพชร',
            zipcode: 62110,
        },
        {
            subDistrict: 'หนองหัววัว',
            district: 'พรานกระต่าย',
            province: 'กำแพงเพชร',
            zipcode: 62110,
        },
        {
            subDistrict: 'ห้วยยั้ง',
            district: 'พรานกระต่าย',
            province: 'กำแพงเพชร',
            zipcode: 62110,
        },
        {
            subDistrict: 'เขาคีริส',
            district: 'พรานกระต่าย',
            province: 'กำแพงเพชร',
            zipcode: 62110,
        },
        {
            subDistrict: 'จันทิมา',
            district: 'ลานกระบือ',
            province: 'กำแพงเพชร',
            zipcode: 62170,
        },
        {
            subDistrict: 'ช่องลม',
            district: 'ลานกระบือ',
            province: 'กำแพงเพชร',
            zipcode: 62170,
        },
        {
            subDistrict: 'บึงทับแรต',
            district: 'ลานกระบือ',
            province: 'กำแพงเพชร',
            zipcode: 62170,
        },
        {
            subDistrict: 'ประชาสุขสันต์',
            district: 'ลานกระบือ',
            province: 'กำแพงเพชร',
            zipcode: 62170,
        },
        {
            subDistrict: 'ลานกระบือ',
            district: 'ลานกระบือ',
            province: 'กำแพงเพชร',
            zipcode: 62170,
        },
        {
            subDistrict: 'หนองหลวง',
            district: 'ลานกระบือ',
            province: 'กำแพงเพชร',
            zipcode: 62170,
        },
        {
            subDistrict: 'โนนพลวง',
            district: 'ลานกระบือ',
            province: 'กำแพงเพชร',
            zipcode: 62170,
        },
        {
            subDistrict: 'คณฑี',
            district: 'เมืองกำแพงเพชร',
            province: 'กำแพงเพชร',
            zipcode: 62000,
        },
        {
            subDistrict: 'คลองแม่ลาย',
            district: 'เมืองกำแพงเพชร',
            province: 'กำแพงเพชร',
            zipcode: 62000,
        },
        {
            subDistrict: 'ทรงธรรม',
            district: 'เมืองกำแพงเพชร',
            province: 'กำแพงเพชร',
            zipcode: 62000,
        },
        {
            subDistrict: 'ท่าขุนราม',
            district: 'เมืองกำแพงเพชร',
            province: 'กำแพงเพชร',
            zipcode: 62000,
        },
        {
            subDistrict: 'ธำมรงค์',
            district: 'เมืองกำแพงเพชร',
            province: 'กำแพงเพชร',
            zipcode: 62160,
        },
        {
            subDistrict: 'นครชุม',
            district: 'เมืองกำแพงเพชร',
            province: 'กำแพงเพชร',
            zipcode: 62000,
        },
        {
            subDistrict: 'นาบ่อคำ',
            district: 'เมืองกำแพงเพชร',
            province: 'กำแพงเพชร',
            zipcode: 62000,
        },
        {
            subDistrict: 'นิคมทุ่งโพธิ์ทะเล',
            district: 'เมืองกำแพงเพชร',
            province: 'กำแพงเพชร',
            zipcode: 62000,
        },
        {
            subDistrict: 'ลานดอกไม้',
            district: 'เมืองกำแพงเพชร',
            province: 'กำแพงเพชร',
            zipcode: 62000,
        },
        {
            subDistrict: 'วังทอง',
            district: 'เมืองกำแพงเพชร',
            province: 'กำแพงเพชร',
            zipcode: 62000,
        },
        {
            subDistrict: 'สระแก้ว',
            district: 'เมืองกำแพงเพชร',
            province: 'กำแพงเพชร',
            zipcode: 62000,
        },
        {
            subDistrict: 'หนองปลิง',
            district: 'เมืองกำแพงเพชร',
            province: 'กำแพงเพชร',
            zipcode: 62000,
        },
        {
            subDistrict: 'อ่างทอง',
            district: 'เมืองกำแพงเพชร',
            province: 'กำแพงเพชร',
            zipcode: 62000,
        },
        {
            subDistrict: 'เทพนคร',
            district: 'เมืองกำแพงเพชร',
            province: 'กำแพงเพชร',
            zipcode: 62000,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'เมืองกำแพงเพชร',
            province: 'กำแพงเพชร',
            zipcode: 62000,
        },
        {
            subDistrict: 'ไตรตรึงษ์',
            district: 'เมืองกำแพงเพชร',
            province: 'กำแพงเพชร',
            zipcode: 62160,
        },
        {
            subDistrict: 'ลานดอกไม้ตก',
            district: 'โกสัมพีนคร',
            province: 'กำแพงเพชร',
            zipcode: 62000,
        },
        {
            subDistrict: 'เพชรชมภู',
            district: 'โกสัมพีนคร',
            province: 'กำแพงเพชร',
            zipcode: 62000,
        },
        {
            subDistrict: 'โกสัมพี',
            district: 'โกสัมพีนคร',
            province: 'กำแพงเพชร',
            zipcode: 62000,
        },
        {
            subDistrict: 'พานทอง',
            district: 'ไทรงาม',
            province: 'กำแพงเพชร',
            zipcode: 62150,
        },
        {
            subDistrict: 'มหาชัย',
            district: 'ไทรงาม',
            province: 'กำแพงเพชร',
            zipcode: 62150,
        },
        {
            subDistrict: 'หนองคล้า',
            district: 'ไทรงาม',
            province: 'กำแพงเพชร',
            zipcode: 62150,
        },
        {
            subDistrict: 'หนองทอง',
            district: 'ไทรงาม',
            province: 'กำแพงเพชร',
            zipcode: 62150,
        },
        {
            subDistrict: 'หนองแม่แตง',
            district: 'ไทรงาม',
            province: 'กำแพงเพชร',
            zipcode: 62150,
        },
        {
            subDistrict: 'หนองไม้กอง',
            district: 'ไทรงาม',
            province: 'กำแพงเพชร',
            zipcode: 62150,
        },
        {
            subDistrict: 'ไทรงาม',
            district: 'ไทรงาม',
            province: 'กำแพงเพชร',
            zipcode: 62150,
        },
        {
            subDistrict: 'ดูนสาด',
            district: 'กระนวน',
            province: 'ขอนแก่น',
            zipcode: 40170,
        },
        {
            subDistrict: 'น้ำอ้อม',
            district: 'กระนวน',
            province: 'ขอนแก่น',
            zipcode: 40170,
        },
        {
            subDistrict: 'บ้านฝาง',
            district: 'กระนวน',
            province: 'ขอนแก่น',
            zipcode: 40170,
        },
        {
            subDistrict: 'หนองกุงใหญ่',
            district: 'กระนวน',
            province: 'ขอนแก่น',
            zipcode: 40170,
        },
        {
            subDistrict: 'หนองโก',
            district: 'กระนวน',
            province: 'ขอนแก่น',
            zipcode: 40170,
        },
        {
            subDistrict: 'หนองโน',
            district: 'กระนวน',
            province: 'ขอนแก่น',
            zipcode: 40170,
        },
        {
            subDistrict: 'หัวนาคำ',
            district: 'กระนวน',
            province: 'ขอนแก่น',
            zipcode: 40170,
        },
        {
            subDistrict: 'ห้วยยาง',
            district: 'กระนวน',
            province: 'ขอนแก่น',
            zipcode: 40170,
        },
        {
            subDistrict: 'ห้วยโจด',
            district: 'กระนวน',
            province: 'ขอนแก่น',
            zipcode: 40170,
        },
        {
            subDistrict: 'กุดเพียขอม',
            district: 'ชนบท',
            province: 'ขอนแก่น',
            zipcode: 40180,
        },
        {
            subDistrict: 'ชนบท',
            district: 'ชนบท',
            province: 'ขอนแก่น',
            zipcode: 40180,
        },
        {
            subDistrict: 'บ้านแท่น',
            district: 'ชนบท',
            province: 'ขอนแก่น',
            zipcode: 40180,
        },
        {
            subDistrict: 'ปอแดง',
            district: 'ชนบท',
            province: 'ขอนแก่น',
            zipcode: 40180,
        },
        {
            subDistrict: 'วังแสง',
            district: 'ชนบท',
            province: 'ขอนแก่น',
            zipcode: 40180,
        },
        {
            subDistrict: 'ศรีบุญเรือง',
            district: 'ชนบท',
            province: 'ขอนแก่น',
            zipcode: 40180,
        },
        {
            subDistrict: 'ห้วยแก',
            district: 'ชนบท',
            province: 'ขอนแก่น',
            zipcode: 40180,
        },
        {
            subDistrict: 'โนนพะยอม',
            district: 'ชนบท',
            province: 'ขอนแก่น',
            zipcode: 40180,
        },
        {
            subDistrict: 'ขัวเรียง',
            district: 'ชุมแพ',
            province: 'ขอนแก่น',
            zipcode: 40130,
        },
        {
            subDistrict: 'ชุมแพ',
            district: 'ชุมแพ',
            province: 'ขอนแก่น',
            zipcode: 40130,
        },
        {
            subDistrict: 'นาหนองทุ่ม',
            district: 'ชุมแพ',
            province: 'ขอนแก่น',
            zipcode: 40290,
        },
        {
            subDistrict: 'นาเพียง',
            district: 'ชุมแพ',
            province: 'ขอนแก่น',
            zipcode: 40130,
        },
        {
            subDistrict: 'วังหินลาด',
            district: 'ชุมแพ',
            province: 'ขอนแก่น',
            zipcode: 40130,
        },
        {
            subDistrict: 'หนองเขียด',
            district: 'ชุมแพ',
            province: 'ขอนแก่น',
            zipcode: 40290,
        },
        {
            subDistrict: 'หนองเสาเล้า',
            district: 'ชุมแพ',
            province: 'ขอนแก่น',
            zipcode: 40130,
        },
        {
            subDistrict: 'หนองไผ่',
            district: 'ชุมแพ',
            province: 'ขอนแก่น',
            zipcode: 40130,
        },
        {
            subDistrict: 'โนนสะอาด',
            district: 'ชุมแพ',
            province: 'ขอนแก่น',
            zipcode: 40290,
        },
        {
            subDistrict: 'โนนหัน',
            district: 'ชุมแพ',
            province: 'ขอนแก่น',
            zipcode: 40290,
        },
        {
            subDistrict: 'โนนอุดม',
            district: 'ชุมแพ',
            province: 'ขอนแก่น',
            zipcode: 40130,
        },
        {
            subDistrict: 'ไชยสอ',
            district: 'ชุมแพ',
            province: 'ขอนแก่น',
            zipcode: 40130,
        },
        {
            subDistrict: 'กระนวน',
            district: 'ซำสูง',
            province: 'ขอนแก่น',
            zipcode: 40170,
        },
        {
            subDistrict: 'คำแมด',
            district: 'ซำสูง',
            province: 'ขอนแก่น',
            zipcode: 40170,
        },
        {
            subDistrict: 'คูคำ',
            district: 'ซำสูง',
            province: 'ขอนแก่น',
            zipcode: 40170,
        },
        {
            subDistrict: 'บ้านโนน',
            district: 'ซำสูง',
            province: 'ขอนแก่น',
            zipcode: 40170,
        },
        {
            subDistrict: 'ห้วยเตย',
            district: 'ซำสูง',
            province: 'ขอนแก่น',
            zipcode: 40170,
        },
        {
            subDistrict: 'กุดน้ำใส',
            district: 'น้ำพอง',
            province: 'ขอนแก่น',
            zipcode: 40310,
        },
        {
            subDistrict: 'ทรายมูล',
            district: 'น้ำพอง',
            province: 'ขอนแก่น',
            zipcode: 40140,
        },
        {
            subDistrict: 'ท่ากระเสริม',
            district: 'น้ำพอง',
            province: 'ขอนแก่น',
            zipcode: 40140,
        },
        {
            subDistrict: 'น้ำพอง',
            district: 'น้ำพอง',
            province: 'ขอนแก่น',
            zipcode: 40140,
        },
        {
            subDistrict: 'บัวเงิน',
            district: 'น้ำพอง',
            province: 'ขอนแก่น',
            zipcode: 40140,
        },
        {
            subDistrict: 'บัวใหญ่',
            district: 'น้ำพอง',
            province: 'ขอนแก่น',
            zipcode: 40140,
        },
        {
            subDistrict: 'บ้านขาม',
            district: 'น้ำพอง',
            province: 'ขอนแก่น',
            zipcode: 40140,
        },
        {
            subDistrict: 'พังทุย',
            district: 'น้ำพอง',
            province: 'ขอนแก่น',
            zipcode: 40140,
        },
        {
            subDistrict: 'ม่วงหวาน',
            district: 'น้ำพอง',
            province: 'ขอนแก่น',
            zipcode: 40310,
        },
        {
            subDistrict: 'วังชัย',
            district: 'น้ำพอง',
            province: 'ขอนแก่น',
            zipcode: 40140,
        },
        {
            subDistrict: 'สะอาด',
            district: 'น้ำพอง',
            province: 'ขอนแก่น',
            zipcode: 40310,
        },
        {
            subDistrict: 'หนองกุง',
            district: 'น้ำพอง',
            province: 'ขอนแก่น',
            zipcode: 40140,
        },
        {
            subDistrict: 'บ้านฝาง',
            district: 'บ้านฝาง',
            province: 'ขอนแก่น',
            zipcode: 40270,
        },
        {
            subDistrict: 'บ้านเหล่า',
            district: 'บ้านฝาง',
            province: 'ขอนแก่น',
            zipcode: 40270,
        },
        {
            subDistrict: 'ป่ามะนาว',
            district: 'บ้านฝาง',
            province: 'ขอนแก่น',
            zipcode: 40270,
        },
        {
            subDistrict: 'ป่าหวายนั่ง',
            district: 'บ้านฝาง',
            province: 'ขอนแก่น',
            zipcode: 40270,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'บ้านฝาง',
            province: 'ขอนแก่น',
            zipcode: 40270,
        },
        {
            subDistrict: 'โคกงาม',
            district: 'บ้านฝาง',
            province: 'ขอนแก่น',
            zipcode: 40270,
        },
        {
            subDistrict: 'โนนฆ้อง',
            district: 'บ้านฝาง',
            province: 'ขอนแก่น',
            zipcode: 40270,
        },
        {
            subDistrict: 'บ้านแฮด',
            district: 'บ้านแฮด',
            province: 'ขอนแก่น',
            zipcode: 40110,
        },
        {
            subDistrict: 'หนองแซง',
            district: 'บ้านแฮด',
            province: 'ขอนแก่น',
            zipcode: 40110,
        },
        {
            subDistrict: 'โคกสำราญ',
            district: 'บ้านแฮด',
            province: 'ขอนแก่น',
            zipcode: 40110,
        },
        {
            subDistrict: 'โนนสมบูรณ์',
            district: 'บ้านแฮด',
            province: 'ขอนแก่น',
            zipcode: 40110,
        },
        {
            subDistrict: 'บ้านลาน',
            district: 'บ้านไผ่',
            province: 'ขอนแก่น',
            zipcode: 40110,
        },
        {
            subDistrict: 'บ้านไผ่',
            district: 'บ้านไผ่',
            province: 'ขอนแก่น',
            zipcode: 40110,
        },
        {
            subDistrict: 'ป่าปอ',
            district: 'บ้านไผ่',
            province: 'ขอนแก่น',
            zipcode: 40110,
        },
        {
            subDistrict: 'ภูเหล็ก',
            district: 'บ้านไผ่',
            province: 'ขอนแก่น',
            zipcode: 40110,
        },
        {
            subDistrict: 'หนองน้ำใส',
            district: 'บ้านไผ่',
            province: 'ขอนแก่น',
            zipcode: 40110,
        },
        {
            subDistrict: 'หัวหนอง',
            district: 'บ้านไผ่',
            province: 'ขอนแก่น',
            zipcode: 40110,
        },
        {
            subDistrict: 'หินตั้ง',
            district: 'บ้านไผ่',
            province: 'ขอนแก่น',
            zipcode: 40110,
        },
        {
            subDistrict: 'เมืองเพีย',
            district: 'บ้านไผ่',
            province: 'ขอนแก่น',
            zipcode: 40110,
        },
        {
            subDistrict: 'แคนเหนือ',
            district: 'บ้านไผ่',
            province: 'ขอนแก่น',
            zipcode: 40110,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'บ้านไผ่',
            province: 'ขอนแก่น',
            zipcode: 40110,
        },
        {
            subDistrict: 'ขามป้อม',
            district: 'พระยืน',
            province: 'ขอนแก่น',
            zipcode: 40320,
        },
        {
            subDistrict: 'บ้านโต้น',
            district: 'พระยืน',
            province: 'ขอนแก่น',
            zipcode: 40320,
        },
        {
            subDistrict: 'พระบุ',
            district: 'พระยืน',
            province: 'ขอนแก่น',
            zipcode: 40320,
        },
        {
            subDistrict: 'พระยืน',
            district: 'พระยืน',
            province: 'ขอนแก่น',
            zipcode: 40320,
        },
        {
            subDistrict: 'หนองแวง',
            district: 'พระยืน',
            province: 'ขอนแก่น',
            zipcode: 40320,
        },
        {
            subDistrict: 'ลอมคอม',
            district: 'พล',
            province: 'ขอนแก่น',
            zipcode: 40120,
        },
        {
            subDistrict: 'หนองมะเขือ',
            district: 'พล',
            province: 'ขอนแก่น',
            zipcode: 40120,
        },
        {
            subDistrict: 'หนองแวงนางเบ้า',
            district: 'พล',
            province: 'ขอนแก่น',
            zipcode: 40120,
        },
        {
            subDistrict: 'หนองแวงโสกพระ',
            district: 'พล',
            province: 'ขอนแก่น',
            zipcode: 40120,
        },
        {
            subDistrict: 'หัวทุ่ง',
            district: 'พล',
            province: 'ขอนแก่น',
            zipcode: 40120,
        },
        {
            subDistrict: 'เก่างิ้ว',
            district: 'พล',
            province: 'ขอนแก่น',
            zipcode: 40120,
        },
        {
            subDistrict: 'เพ็กใหญ่',
            district: 'พล',
            province: 'ขอนแก่น',
            zipcode: 40120,
        },
        {
            subDistrict: 'เมืองพล',
            district: 'พล',
            province: 'ขอนแก่น',
            zipcode: 40120,
        },
        {
            subDistrict: 'โคกสง่า',
            district: 'พล',
            province: 'ขอนแก่น',
            zipcode: 40120,
        },
        {
            subDistrict: 'โจดหนองแก',
            district: 'พล',
            province: 'ขอนแก่น',
            zipcode: 40120,
        },
        {
            subDistrict: 'โนนข่า',
            district: 'พล',
            province: 'ขอนแก่น',
            zipcode: 40120,
        },
        {
            subDistrict: 'โสกนกเต็น',
            district: 'พล',
            province: 'ขอนแก่น',
            zipcode: 40120,
        },
        {
            subDistrict: 'นาฝาย',
            district: 'ภูผาม่าน',
            province: 'ขอนแก่น',
            zipcode: 40350,
        },
        {
            subDistrict: 'ภูผาม่าน',
            district: 'ภูผาม่าน',
            province: 'ขอนแก่น',
            zipcode: 40350,
        },
        {
            subDistrict: 'วังสวาบ',
            district: 'ภูผาม่าน',
            province: 'ขอนแก่น',
            zipcode: 40350,
        },
        {
            subDistrict: 'ห้วยม่วง',
            district: 'ภูผาม่าน',
            province: 'ขอนแก่น',
            zipcode: 40350,
        },
        {
            subDistrict: 'โนนคอม',
            district: 'ภูผาม่าน',
            province: 'ขอนแก่น',
            zipcode: 40350,
        },
        {
            subDistrict: 'กุดขอนแก่น',
            district: 'ภูเวียง',
            province: 'ขอนแก่น',
            zipcode: 40150,
        },
        {
            subDistrict: 'ดินดำ',
            district: 'ภูเวียง',
            province: 'ขอนแก่น',
            zipcode: 40150,
        },
        {
            subDistrict: 'ทุ่งชมพู',
            district: 'ภูเวียง',
            province: 'ขอนแก่น',
            zipcode: 40150,
        },
        {
            subDistrict: 'นาชุมแสง',
            district: 'ภูเวียง',
            province: 'ขอนแก่น',
            zipcode: 40150,
        },
        {
            subDistrict: 'นาหว้า',
            district: 'ภูเวียง',
            province: 'ขอนแก่น',
            zipcode: 40150,
        },
        {
            subDistrict: 'บ้านเรือ',
            district: 'ภูเวียง',
            province: 'ขอนแก่น',
            zipcode: 40150,
        },
        {
            subDistrict: 'ภูเวียง',
            district: 'ภูเวียง',
            province: 'ขอนแก่น',
            zipcode: 40150,
        },
        {
            subDistrict: 'สงเปือย',
            district: 'ภูเวียง',
            province: 'ขอนแก่น',
            zipcode: 40150,
        },
        {
            subDistrict: 'หนองกุงธนสาร',
            district: 'ภูเวียง',
            province: 'ขอนแก่น',
            zipcode: 40150,
        },
        {
            subDistrict: 'หนองกุงเซิน',
            district: 'ภูเวียง',
            province: 'ขอนแก่น',
            zipcode: 40150,
        },
        {
            subDistrict: 'หว้าทอง',
            district: 'ภูเวียง',
            province: 'ขอนแก่น',
            zipcode: 40150,
        },
        {
            subDistrict: 'เขาน้อย',
            district: 'เวียงเก่า',
            province: 'ขอนแก่น',
            zipcode: 40150,
        },
        {
            subDistrict: 'เมืองเก่าพัฒนา',
            district: 'เวียงเก่า',
            province: 'ขอนแก่น',
            zipcode: 40150,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'เวียงเก่า',
            province: 'ขอนแก่น',
            zipcode: 40150,
        },
        {
            subDistrict: 'กุดเค้า',
            district: 'มัญจาคีรี',
            province: 'ขอนแก่น',
            zipcode: 40160,
        },
        {
            subDistrict: 'คำแคน',
            district: 'มัญจาคีรี',
            province: 'ขอนแก่น',
            zipcode: 40160,
        },
        {
            subDistrict: 'ท่าศาลา',
            district: 'มัญจาคีรี',
            province: 'ขอนแก่น',
            zipcode: 40160,
        },
        {
            subDistrict: 'นาข่า',
            district: 'มัญจาคีรี',
            province: 'ขอนแก่น',
            zipcode: 40160,
        },
        {
            subDistrict: 'นางาม',
            district: 'มัญจาคีรี',
            province: 'ขอนแก่น',
            zipcode: 40160,
        },
        {
            subDistrict: 'สวนหม่อน',
            district: 'มัญจาคีรี',
            province: 'ขอนแก่น',
            zipcode: 40160,
        },
        {
            subDistrict: 'หนองแปน',
            district: 'มัญจาคีรี',
            province: 'ขอนแก่น',
            zipcode: 40160,
        },
        {
            subDistrict: 'โพนเพ็ก',
            district: 'มัญจาคีรี',
            province: 'ขอนแก่น',
            zipcode: 40160,
        },
        {
            subDistrict: 'ซำยาง',
            district: 'สีชมพู',
            province: 'ขอนแก่น',
            zipcode: 40220,
        },
        {
            subDistrict: 'ดงลาน',
            district: 'สีชมพู',
            province: 'ขอนแก่น',
            zipcode: 40220,
        },
        {
            subDistrict: 'นาจาน',
            district: 'สีชมพู',
            province: 'ขอนแก่น',
            zipcode: 40220,
        },
        {
            subDistrict: 'บริบูรณ์',
            district: 'สีชมพู',
            province: 'ขอนแก่น',
            zipcode: 40220,
        },
        {
            subDistrict: 'บ้านใหม่',
            district: 'สีชมพู',
            province: 'ขอนแก่น',
            zipcode: 40220,
        },
        {
            subDistrict: 'ภูห่าน',
            district: 'สีชมพู',
            province: 'ขอนแก่น',
            zipcode: 40220,
        },
        {
            subDistrict: 'วังเพิ่ม',
            district: 'สีชมพู',
            province: 'ขอนแก่น',
            zipcode: 40220,
        },
        {
            subDistrict: 'ศรีสุข',
            district: 'สีชมพู',
            province: 'ขอนแก่น',
            zipcode: 40220,
        },
        {
            subDistrict: 'สีชมพู',
            district: 'สีชมพู',
            province: 'ขอนแก่น',
            zipcode: 40220,
        },
        {
            subDistrict: 'หนองแดง',
            district: 'สีชมพู',
            province: 'ขอนแก่น',
            zipcode: 40220,
        },
        {
            subDistrict: 'กุดธาตุ',
            district: 'หนองนาคำ',
            province: 'ขอนแก่น',
            zipcode: 40150,
        },
        {
            subDistrict: 'ขนวน',
            district: 'หนองนาคำ',
            province: 'ขอนแก่น',
            zipcode: 40150,
        },
        {
            subDistrict: 'บ้านโคก',
            district: 'หนองนาคำ',
            province: 'ขอนแก่น',
            zipcode: 40150,
        },
        {
            subDistrict: 'คึมชาด',
            district: 'หนองสองห้อง',
            province: 'ขอนแก่น',
            zipcode: 40190,
        },
        {
            subDistrict: 'ดงเค็ง',
            district: 'หนองสองห้อง',
            province: 'ขอนแก่น',
            zipcode: 40190,
        },
        {
            subDistrict: 'ดอนดั่ง',
            district: 'หนองสองห้อง',
            province: 'ขอนแก่น',
            zipcode: 40190,
        },
        {
            subDistrict: 'ดอนดู่',
            district: 'หนองสองห้อง',
            province: 'ขอนแก่น',
            zipcode: 40190,
        },
        {
            subDistrict: 'ตะกั่วป่า',
            district: 'หนองสองห้อง',
            province: 'ขอนแก่น',
            zipcode: 40190,
        },
        {
            subDistrict: 'วังหิน',
            district: 'หนองสองห้อง',
            province: 'ขอนแก่น',
            zipcode: 40190,
        },
        {
            subDistrict: 'สำโรง',
            district: 'หนองสองห้อง',
            province: 'ขอนแก่น',
            zipcode: 40190,
        },
        {
            subDistrict: 'หนองสองห้อง',
            district: 'หนองสองห้อง',
            province: 'ขอนแก่น',
            zipcode: 40190,
        },
        {
            subDistrict: 'หนองเม็ก',
            district: 'หนองสองห้อง',
            province: 'ขอนแก่น',
            zipcode: 40190,
        },
        {
            subDistrict: 'หนองไผ่ล้อม',
            district: 'หนองสองห้อง',
            province: 'ขอนแก่น',
            zipcode: 40190,
        },
        {
            subDistrict: 'หันโจด',
            district: 'หนองสองห้อง',
            province: 'ขอนแก่น',
            zipcode: 40190,
        },
        {
            subDistrict: 'โนนธาตุ',
            district: 'หนองสองห้อง',
            province: 'ขอนแก่น',
            zipcode: 40190,
        },
        {
            subDistrict: 'กุดกว้าง',
            district: 'หนองเรือ',
            province: 'ขอนแก่น',
            zipcode: 40210,
        },
        {
            subDistrict: 'จระเข้',
            district: 'หนองเรือ',
            province: 'ขอนแก่น',
            zipcode: 40240,
        },
        {
            subDistrict: 'บ้านกง',
            district: 'หนองเรือ',
            province: 'ขอนแก่น',
            zipcode: 40240,
        },
        {
            subDistrict: 'บ้านผือ',
            district: 'หนองเรือ',
            province: 'ขอนแก่น',
            zipcode: 40240,
        },
        {
            subDistrict: 'บ้านเม็ง',
            district: 'หนองเรือ',
            province: 'ขอนแก่น',
            zipcode: 40210,
        },
        {
            subDistrict: 'ยางคำ',
            district: 'หนองเรือ',
            province: 'ขอนแก่น',
            zipcode: 40240,
        },
        {
            subDistrict: 'หนองเรือ',
            district: 'หนองเรือ',
            province: 'ขอนแก่น',
            zipcode: 40210,
        },
        {
            subDistrict: 'โนนทอง',
            district: 'หนองเรือ',
            province: 'ขอนแก่น',
            zipcode: 40210,
        },
        {
            subDistrict: 'โนนทัน',
            district: 'หนองเรือ',
            province: 'ขอนแก่น',
            zipcode: 40210,
        },
        {
            subDistrict: 'โนนสะอาด',
            district: 'หนองเรือ',
            province: 'ขอนแก่น',
            zipcode: 40210,
        },
        {
            subDistrict: 'ทุ่งโป่ง',
            district: 'อุบลรัตน์',
            province: 'ขอนแก่น',
            zipcode: 40250,
        },
        {
            subDistrict: 'นาคำ',
            district: 'อุบลรัตน์',
            province: 'ขอนแก่น',
            zipcode: 40250,
        },
        {
            subDistrict: 'บ้านดง',
            district: 'อุบลรัตน์',
            province: 'ขอนแก่น',
            zipcode: 40250,
        },
        {
            subDistrict: 'ศรีสุขสำราญ',
            district: 'อุบลรัตน์',
            province: 'ขอนแก่น',
            zipcode: 40250,
        },
        {
            subDistrict: 'เขื่อนอุบลรัตน์',
            district: 'อุบลรัตน์',
            province: 'ขอนแก่น',
            zipcode: 40250,
        },
        {
            subDistrict: 'โคกสูง',
            district: 'อุบลรัตน์',
            province: 'ขอนแก่น',
            zipcode: 40250,
        },
        {
            subDistrict: 'คำม่วง',
            district: 'เขาสวนกวาง',
            province: 'ขอนแก่น',
            zipcode: 40280,
        },
        {
            subDistrict: 'ดงเมืองแอม',
            district: 'เขาสวนกวาง',
            province: 'ขอนแก่น',
            zipcode: 40280,
        },
        {
            subDistrict: 'นางิ้ว',
            district: 'เขาสวนกวาง',
            province: 'ขอนแก่น',
            zipcode: 40280,
        },
        {
            subDistrict: 'เขาสวนกวาง',
            district: 'เขาสวนกวาง',
            province: 'ขอนแก่น',
            zipcode: 40280,
        },
        {
            subDistrict: 'โนนสมบูรณ์',
            district: 'เขาสวนกวาง',
            province: 'ขอนแก่น',
            zipcode: 40280,
        },
        {
            subDistrict: 'ขามป้อม',
            district: 'เปือยน้อย',
            province: 'ขอนแก่น',
            zipcode: 40340,
        },
        {
            subDistrict: 'วังม่วง',
            district: 'เปือยน้อย',
            province: 'ขอนแก่น',
            zipcode: 40340,
        },
        {
            subDistrict: 'สระแก้ว',
            district: 'เปือยน้อย',
            province: 'ขอนแก่น',
            zipcode: 40340,
        },
        {
            subDistrict: 'เปือยน้อย',
            district: 'เปือยน้อย',
            province: 'ขอนแก่น',
            zipcode: 40340,
        },
        {
            subDistrict: 'ดอนช้าง',
            district: 'เมืองขอนแก่น',
            province: 'ขอนแก่น',
            zipcode: 40000,
        },
        {
            subDistrict: 'ดอนหัน',
            district: 'เมืองขอนแก่น',
            province: 'ขอนแก่น',
            zipcode: 40260,
        },
        {
            subDistrict: 'ท่าพระ',
            district: 'เมืองขอนแก่น',
            province: 'ขอนแก่น',
            zipcode: 40260,
        },
        {
            subDistrict: 'บึงเนียม',
            district: 'เมืองขอนแก่น',
            province: 'ขอนแก่น',
            zipcode: 40000,
        },
        {
            subDistrict: 'บ้านค้อ',
            district: 'เมืองขอนแก่น',
            province: 'ขอนแก่น',
            zipcode: 40000,
        },
        {
            subDistrict: 'บ้านทุ่ม',
            district: 'เมืองขอนแก่น',
            province: 'ขอนแก่น',
            zipcode: 40000,
        },
        {
            subDistrict: 'บ้านหว้า',
            district: 'เมืองขอนแก่น',
            province: 'ขอนแก่น',
            zipcode: 40000,
        },
        {
            subDistrict: 'บ้านเป็ด',
            district: 'เมืองขอนแก่น',
            province: 'ขอนแก่น',
            zipcode: 40000,
        },
        {
            subDistrict: 'พระลับ',
            district: 'เมืองขอนแก่น',
            province: 'ขอนแก่น',
            zipcode: 40000,
        },
        {
            subDistrict: 'ศิลา',
            district: 'เมืองขอนแก่น',
            province: 'ขอนแก่น',
            zipcode: 40000,
        },
        {
            subDistrict: 'สาวะถี',
            district: 'เมืองขอนแก่น',
            province: 'ขอนแก่น',
            zipcode: 40000,
        },
        {
            subDistrict: 'สำราญ',
            district: 'เมืองขอนแก่น',
            province: 'ขอนแก่น',
            zipcode: 40000,
        },
        {
            subDistrict: 'หนองตูม',
            district: 'เมืองขอนแก่น',
            province: 'ขอนแก่น',
            zipcode: 40000,
        },
        {
            subDistrict: 'เมืองเก่า',
            district: 'เมืองขอนแก่น',
            province: 'ขอนแก่น',
            zipcode: 40000,
        },
        {
            subDistrict: 'แดงใหญ่',
            district: 'เมืองขอนแก่น',
            province: 'ขอนแก่น',
            zipcode: 40000,
        },
        {
            subDistrict: 'โคกสี',
            district: 'เมืองขอนแก่น',
            province: 'ขอนแก่น',
            zipcode: 40000,
        },
        {
            subDistrict: 'โนนท่อน',
            district: 'เมืองขอนแก่น',
            province: 'ขอนแก่น',
            zipcode: 40000,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'เมืองขอนแก่น',
            province: 'ขอนแก่น',
            zipcode: 40000,
        },
        {
            subDistrict: 'มหาวิทยาลัยขอนแก่น',
            district: 'เมืองขอนแก่น',
            province: 'ขอนแก่น',
            zipcode: 40002,
        },
        {
            subDistrict: 'ก้านเหลือง',
            district: 'แวงน้อย',
            province: 'ขอนแก่น',
            zipcode: 40230,
        },
        {
            subDistrict: 'ทางขวาง',
            district: 'แวงน้อย',
            province: 'ขอนแก่น',
            zipcode: 40230,
        },
        {
            subDistrict: 'ท่านางแนว',
            district: 'แวงน้อย',
            province: 'ขอนแก่น',
            zipcode: 40230,
        },
        {
            subDistrict: 'ท่าวัด',
            district: 'แวงน้อย',
            province: 'ขอนแก่น',
            zipcode: 40230,
        },
        {
            subDistrict: 'ละหานนา',
            district: 'แวงน้อย',
            province: 'ขอนแก่น',
            zipcode: 40230,
        },
        {
            subDistrict: 'แวงน้อย',
            district: 'แวงน้อย',
            province: 'ขอนแก่น',
            zipcode: 40230,
        },
        {
            subDistrict: 'คอนฉิม',
            district: 'แวงใหญ่',
            province: 'ขอนแก่น',
            zipcode: 40330,
        },
        {
            subDistrict: 'แวงใหญ่',
            district: 'แวงใหญ่',
            province: 'ขอนแก่น',
            zipcode: 40330,
        },
        {
            subDistrict: 'โนนทอง',
            district: 'แวงใหญ่',
            province: 'ขอนแก่น',
            zipcode: 40330,
        },
        {
            subDistrict: 'โนนสะอาด',
            district: 'แวงใหญ่',
            province: 'ขอนแก่น',
            zipcode: 40330,
        },
        {
            subDistrict: 'ใหม่นาเพียง',
            district: 'แวงใหญ่',
            province: 'ขอนแก่น',
            zipcode: 40330,
        },
        {
            subDistrict: 'ซับสมบูรณ์',
            district: 'โคกโพธิ์ไชย',
            province: 'ขอนแก่น',
            zipcode: 40160,
        },
        {
            subDistrict: 'นาแพง',
            district: 'โคกโพธิ์ไชย',
            province: 'ขอนแก่น',
            zipcode: 40160,
        },
        {
            subDistrict: 'บ้านโคก',
            district: 'โคกโพธิ์ไชย',
            province: 'ขอนแก่น',
            zipcode: 40160,
        },
        {
            subDistrict: 'โพธิ์ไชย',
            district: 'โคกโพธิ์ไชย',
            province: 'ขอนแก่น',
            zipcode: 40160,
        },
        {
            subDistrict: 'บ้านหัน',
            district: 'โนนศิลา',
            province: 'ขอนแก่น',
            zipcode: 40110,
        },
        {
            subDistrict: 'หนองปลาหมอ',
            district: 'โนนศิลา',
            province: 'ขอนแก่น',
            zipcode: 40110,
        },
        {
            subDistrict: 'เปือยใหญ่',
            district: 'โนนศิลา',
            province: 'ขอนแก่น',
            zipcode: 40110,
        },
        {
            subDistrict: 'โนนศิลา',
            district: 'โนนศิลา',
            province: 'ขอนแก่น',
            zipcode: 40110,
        },
        {
            subDistrict: 'โนนแดง',
            district: 'โนนศิลา',
            province: 'ขอนแก่น',
            zipcode: 40110,
        },
        {
            subDistrict: 'ขลุง',
            district: 'ขลุง',
            province: 'จันทบุรี',
            zipcode: 22110,
        },
        {
            subDistrict: 'ซึ้ง',
            district: 'ขลุง',
            province: 'จันทบุรี',
            zipcode: 22110,
        },
        {
            subDistrict: 'ตกพรม',
            district: 'ขลุง',
            province: 'จันทบุรี',
            zipcode: 22110,
        },
        {
            subDistrict: 'ตรอกนอง',
            district: 'ขลุง',
            province: 'จันทบุรี',
            zipcode: 22110,
        },
        {
            subDistrict: 'ตะปอน',
            district: 'ขลุง',
            province: 'จันทบุรี',
            zipcode: 22110,
        },
        {
            subDistrict: 'บางชัน',
            district: 'ขลุง',
            province: 'จันทบุรี',
            zipcode: 22110,
        },
        {
            subDistrict: 'บ่อ',
            district: 'ขลุง',
            province: 'จันทบุรี',
            zipcode: 22110,
        },
        {
            subDistrict: 'บ่อเวฬุ',
            district: 'ขลุง',
            province: 'จันทบุรี',
            zipcode: 22150,
        },
        {
            subDistrict: 'มาบไพ',
            district: 'ขลุง',
            province: 'จันทบุรี',
            zipcode: 22110,
        },
        {
            subDistrict: 'วังสรรพรส',
            district: 'ขลุง',
            province: 'จันทบุรี',
            zipcode: 22110,
        },
        {
            subDistrict: 'วันยาว',
            district: 'ขลุง',
            province: 'จันทบุรี',
            zipcode: 22110,
        },
        {
            subDistrict: 'เกวียนหัก',
            district: 'ขลุง',
            province: 'จันทบุรี',
            zipcode: 22110,
        },
        {
            subDistrict: 'คลองขุด',
            district: 'ท่าใหม่',
            province: 'จันทบุรี',
            zipcode: 22120,
        },
        {
            subDistrict: 'ตะกาดเง้า',
            district: 'ท่าใหม่',
            province: 'จันทบุรี',
            zipcode: 22120,
        },
        {
            subDistrict: 'ทุ่งเบญจา',
            district: 'ท่าใหม่',
            province: 'จันทบุรี',
            zipcode: 22170,
        },
        {
            subDistrict: 'ท่าใหม่',
            district: 'ท่าใหม่',
            province: 'จันทบุรี',
            zipcode: 22120,
        },
        {
            subDistrict: 'บ่อพุ',
            district: 'ท่าใหม่',
            province: 'จันทบุรี',
            zipcode: 22120,
        },
        {
            subDistrict: 'พลอยแหวน',
            district: 'ท่าใหม่',
            province: 'จันทบุรี',
            zipcode: 22120,
        },
        {
            subDistrict: 'ยายร้า',
            district: 'ท่าใหม่',
            province: 'จันทบุรี',
            zipcode: 22120,
        },
        {
            subDistrict: 'รำพัน',
            district: 'ท่าใหม่',
            province: 'จันทบุรี',
            zipcode: 22170,
        },
        {
            subDistrict: 'สองพี่น้อง',
            district: 'ท่าใหม่',
            province: 'จันทบุรี',
            zipcode: 22120,
        },
        {
            subDistrict: 'สีพยา',
            district: 'ท่าใหม่',
            province: 'จันทบุรี',
            zipcode: 22120,
        },
        {
            subDistrict: 'เขาบายศรี',
            district: 'ท่าใหม่',
            province: 'จันทบุรี',
            zipcode: 22120,
        },
        {
            subDistrict: 'เขาวัว',
            district: 'ท่าใหม่',
            province: 'จันทบุรี',
            zipcode: 22120,
        },
        {
            subDistrict: 'เขาแก้ว',
            district: 'ท่าใหม่',
            province: 'จันทบุรี',
            zipcode: 22170,
        },
        {
            subDistrict: 'โขมง',
            district: 'ท่าใหม่',
            province: 'จันทบุรี',
            zipcode: 22170,
        },
        {
            subDistrict: 'กระแจะ',
            district: 'นายายอาม',
            province: 'จันทบุรี',
            zipcode: 22170,
        },
        {
            subDistrict: 'ช้างข้าม',
            district: 'นายายอาม',
            province: 'จันทบุรี',
            zipcode: 22160,
        },
        {
            subDistrict: 'นายายอาม',
            district: 'นายายอาม',
            province: 'จันทบุรี',
            zipcode: 22160,
        },
        {
            subDistrict: 'วังโตนด',
            district: 'นายายอาม',
            province: 'จันทบุรี',
            zipcode: 22170,
        },
        {
            subDistrict: 'วังใหม่',
            district: 'นายายอาม',
            province: 'จันทบุรี',
            zipcode: 22170,
        },
        {
            subDistrict: 'สนามไชย',
            district: 'นายายอาม',
            province: 'จันทบุรี',
            zipcode: 22170,
        },
        {
            subDistrict: 'ฉมัน',
            district: 'มะขาม',
            province: 'จันทบุรี',
            zipcode: 22150,
        },
        {
            subDistrict: 'ท่าหลวง',
            district: 'มะขาม',
            province: 'จันทบุรี',
            zipcode: 22150,
        },
        {
            subDistrict: 'ปัถวี',
            district: 'มะขาม',
            province: 'จันทบุรี',
            zipcode: 22150,
        },
        {
            subDistrict: 'มะขาม',
            district: 'มะขาม',
            province: 'จันทบุรี',
            zipcode: 22150,
        },
        {
            subDistrict: 'วังแซ้ม',
            district: 'มะขาม',
            province: 'จันทบุรี',
            zipcode: 22150,
        },
        {
            subDistrict: 'อ่างคีรี',
            district: 'มะขาม',
            province: 'จันทบุรี',
            zipcode: 22150,
        },
        {
            subDistrict: 'ทรายขาว',
            district: 'สอยดาว',
            province: 'จันทบุรี',
            zipcode: 22180,
        },
        {
            subDistrict: 'ทับช้าง',
            district: 'สอยดาว',
            province: 'จันทบุรี',
            zipcode: 22180,
        },
        {
            subDistrict: 'ทุ่งขนาน',
            district: 'สอยดาว',
            province: 'จันทบุรี',
            zipcode: 22180,
        },
        {
            subDistrict: 'ปะตง',
            district: 'สอยดาว',
            province: 'จันทบุรี',
            zipcode: 22180,
        },
        {
            subDistrict: 'สะตอน',
            district: 'สอยดาว',
            province: 'จันทบุรี',
            zipcode: 22180,
        },
        {
            subDistrict: 'คลองพลู',
            district: 'เขาคิชฌกูฏ',
            province: 'จันทบุรี',
            zipcode: 22210,
        },
        {
            subDistrict: 'จันทเขลม',
            district: 'เขาคิชฌกูฏ',
            province: 'จันทบุรี',
            zipcode: 22210,
        },
        {
            subDistrict: 'ชากไทย',
            district: 'เขาคิชฌกูฏ',
            province: 'จันทบุรี',
            zipcode: 22210,
        },
        {
            subDistrict: 'ตะเคียนทอง',
            district: 'เขาคิชฌกูฏ',
            province: 'จันทบุรี',
            zipcode: 22210,
        },
        {
            subDistrict: 'พลวง',
            district: 'เขาคิชฌกูฏ',
            province: 'จันทบุรี',
            zipcode: 22210,
        },
        {
            subDistrict: 'คมบาง',
            district: 'เมืองจันทบุรี',
            province: 'จันทบุรี',
            zipcode: 22000,
        },
        {
            subDistrict: 'คลองนารายณ์',
            district: 'เมืองจันทบุรี',
            province: 'จันทบุรี',
            zipcode: 22000,
        },
        {
            subDistrict: 'จันทนิมิต',
            district: 'เมืองจันทบุรี',
            province: 'จันทบุรี',
            zipcode: 22000,
        },
        {
            subDistrict: 'ตลาด',
            district: 'เมืองจันทบุรี',
            province: 'จันทบุรี',
            zipcode: 22000,
        },
        {
            subDistrict: 'ท่าช้าง',
            district: 'เมืองจันทบุรี',
            province: 'จันทบุรี',
            zipcode: 22000,
        },
        {
            subDistrict: 'บางกะจะ',
            district: 'เมืองจันทบุรี',
            province: 'จันทบุรี',
            zipcode: 22000,
        },
        {
            subDistrict: 'พลับพลา',
            district: 'เมืองจันทบุรี',
            province: 'จันทบุรี',
            zipcode: 22000,
        },
        {
            subDistrict: 'วัดใหม่',
            district: 'เมืองจันทบุรี',
            province: 'จันทบุรี',
            zipcode: 22000,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'เมืองจันทบุรี',
            province: 'จันทบุรี',
            zipcode: 22000,
        },
        {
            subDistrict: 'เกาะขวาง',
            district: 'เมืองจันทบุรี',
            province: 'จันทบุรี',
            zipcode: 22000,
        },
        {
            subDistrict: 'แสลง',
            district: 'เมืองจันทบุรี',
            province: 'จันทบุรี',
            zipcode: 22000,
        },
        {
            subDistrict: 'ขุนซ่อง',
            district: 'แก่งหางแมว',
            province: 'จันทบุรี',
            zipcode: 22160,
        },
        {
            subDistrict: 'พวา',
            district: 'แก่งหางแมว',
            province: 'จันทบุรี',
            zipcode: 22160,
        },
        {
            subDistrict: 'สามพี่น้อง',
            district: 'แก่งหางแมว',
            province: 'จันทบุรี',
            zipcode: 22160,
        },
        {
            subDistrict: 'เขาวงกต',
            district: 'แก่งหางแมว',
            province: 'จันทบุรี',
            zipcode: 22160,
        },
        {
            subDistrict: 'แก่งหางแมว',
            district: 'แก่งหางแมว',
            province: 'จันทบุรี',
            zipcode: 22160,
        },
        {
            subDistrict: 'คลองน้ำเค็ม',
            district: 'แหลมสิงห์',
            province: 'จันทบุรี',
            zipcode: 22190,
        },
        {
            subDistrict: 'บางกะไชย',
            district: 'แหลมสิงห์',
            province: 'จันทบุรี',
            zipcode: 22120,
        },
        {
            subDistrict: 'บางสระเก้า',
            district: 'แหลมสิงห์',
            province: 'จันทบุรี',
            zipcode: 22190,
        },
        {
            subDistrict: 'ปากน้ำแหลมสิงห์',
            district: 'แหลมสิงห์',
            province: 'จันทบุรี',
            zipcode: 22130,
        },
        {
            subDistrict: 'พลิ้ว',
            district: 'แหลมสิงห์',
            province: 'จันทบุรี',
            zipcode: 22190,
        },
        {
            subDistrict: 'หนองชิ่ม',
            district: 'แหลมสิงห์',
            province: 'จันทบุรี',
            zipcode: 22130,
        },
        {
            subDistrict: 'เกาะเปริด',
            district: 'แหลมสิงห์',
            province: 'จันทบุรี',
            zipcode: 22130,
        },
        {
            subDistrict: 'คลองใหญ่',
            district: 'โป่งน้ำร้อน',
            province: 'จันทบุรี',
            zipcode: 22140,
        },
        {
            subDistrict: 'ทับไทร',
            district: 'โป่งน้ำร้อน',
            province: 'จันทบุรี',
            zipcode: 22140,
        },
        {
            subDistrict: 'หนองตาคง',
            district: 'โป่งน้ำร้อน',
            province: 'จันทบุรี',
            zipcode: 22140,
        },
        {
            subDistrict: 'เทพนิมิต',
            district: 'โป่งน้ำร้อน',
            province: 'จันทบุรี',
            zipcode: 22140,
        },
        {
            subDistrict: 'โป่งน้ำร้อน',
            district: 'โป่งน้ำร้อน',
            province: 'จันทบุรี',
            zipcode: 22140,
        },
        {
            subDistrict: 'ก้อนแก้ว',
            district: 'คลองเขื่อน',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'คลองเขื่อน',
            district: 'คลองเขื่อน',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'บางตลาด',
            district: 'คลองเขื่อน',
            province: 'ฉะเชิงเทรา',
            zipcode: 24110,
        },
        {
            subDistrict: 'บางเล่า',
            district: 'คลองเขื่อน',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'บางโรง',
            district: 'คลองเขื่อน',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'คลองตะเกรา',
            district: 'ท่าตะเกียบ',
            province: 'ฉะเชิงเทรา',
            zipcode: 24160,
        },
        {
            subDistrict: 'ท่าตะเกียบ',
            district: 'ท่าตะเกียบ',
            province: 'ฉะเชิงเทรา',
            zipcode: 24160,
        },
        {
            subDistrict: 'ท่าทองหลาง',
            district: 'บางคล้า',
            province: 'ฉะเชิงเทรา',
            zipcode: 24110,
        },
        {
            subDistrict: 'บางกระเจ็ด',
            district: 'บางคล้า',
            province: 'ฉะเชิงเทรา',
            zipcode: 24110,
        },
        {
            subDistrict: 'บางคล้า',
            district: 'บางคล้า',
            province: 'ฉะเชิงเทรา',
            zipcode: 24110,
        },
        {
            subDistrict: 'บางสวน',
            district: 'บางคล้า',
            province: 'ฉะเชิงเทรา',
            zipcode: 24110,
        },
        {
            subDistrict: 'ปากน้ำ',
            district: 'บางคล้า',
            province: 'ฉะเชิงเทรา',
            zipcode: 24110,
        },
        {
            subDistrict: 'สาวชะโงก',
            district: 'บางคล้า',
            province: 'ฉะเชิงเทรา',
            zipcode: 24110,
        },
        {
            subDistrict: 'หัวไทร',
            district: 'บางคล้า',
            province: 'ฉะเชิงเทรา',
            zipcode: 24110,
        },
        {
            subDistrict: 'เสม็ดเหนือ',
            district: 'บางคล้า',
            province: 'ฉะเชิงเทรา',
            zipcode: 24110,
        },
        {
            subDistrict: 'เสม็ดใต้',
            district: 'บางคล้า',
            province: 'ฉะเชิงเทรา',
            zipcode: 24110,
        },
        {
            subDistrict: 'ดอนฉิมพลี',
            district: 'บางน้ำเปรี้ยว',
            province: 'ฉะเชิงเทรา',
            zipcode: 24170,
        },
        {
            subDistrict: 'ดอนเกาะกา',
            district: 'บางน้ำเปรี้ยว',
            province: 'ฉะเชิงเทรา',
            zipcode: 24170,
        },
        {
            subDistrict: 'บางขนาก',
            district: 'บางน้ำเปรี้ยว',
            province: 'ฉะเชิงเทรา',
            zipcode: 24150,
        },
        {
            subDistrict: 'บางน้ำเปรี้ยว',
            district: 'บางน้ำเปรี้ยว',
            province: 'ฉะเชิงเทรา',
            zipcode: 24150,
        },
        {
            subDistrict: 'บึงน้ำรักษ์',
            district: 'บางน้ำเปรี้ยว',
            province: 'ฉะเชิงเทรา',
            zipcode: 24170,
        },
        {
            subDistrict: 'ศาลาแดง',
            district: 'บางน้ำเปรี้ยว',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'สิงโตทอง',
            district: 'บางน้ำเปรี้ยว',
            province: 'ฉะเชิงเทรา',
            zipcode: 24150,
        },
        {
            subDistrict: 'หมอนทอง',
            district: 'บางน้ำเปรี้ยว',
            province: 'ฉะเชิงเทรา',
            zipcode: 24150,
        },
        {
            subDistrict: 'โพรงอากาศ',
            district: 'บางน้ำเปรี้ยว',
            province: 'ฉะเชิงเทรา',
            zipcode: 24150,
        },
        {
            subDistrict: 'โยธะกา',
            district: 'บางน้ำเปรี้ยว',
            province: 'ฉะเชิงเทรา',
            zipcode: 24150,
        },
        {
            subDistrict: 'ท่าข้าม',
            district: 'บางปะกง',
            province: 'ฉะเชิงเทรา',
            zipcode: 24130,
        },
        {
            subDistrict: 'ท่าสะอ้าน',
            district: 'บางปะกง',
            province: 'ฉะเชิงเทรา',
            zipcode: 24130,
        },
        {
            subDistrict: 'บางปะกง',
            district: 'บางปะกง',
            province: 'ฉะเชิงเทรา',
            zipcode: 24130,
        },
        {
            subDistrict: 'บางผึ้ง',
            district: 'บางปะกง',
            province: 'ฉะเชิงเทรา',
            zipcode: 24130,
        },
        {
            subDistrict: 'บางวัว',
            district: 'บางปะกง',
            province: 'ฉะเชิงเทรา',
            zipcode: 24130,
        },
        {
            subDistrict: 'บางสมัคร',
            district: 'บางปะกง',
            province: 'ฉะเชิงเทรา',
            zipcode: 24180,
        },
        {
            subDistrict: 'บางเกลือ',
            district: 'บางปะกง',
            province: 'ฉะเชิงเทรา',
            zipcode: 24180,
        },
        {
            subDistrict: 'พิมพา',
            district: 'บางปะกง',
            province: 'ฉะเชิงเทรา',
            zipcode: 24180,
        },
        {
            subDistrict: 'สองคลอง',
            district: 'บางปะกง',
            province: 'ฉะเชิงเทรา',
            zipcode: 24130,
        },
        {
            subDistrict: 'หนองจอก',
            district: 'บางปะกง',
            province: 'ฉะเชิงเทรา',
            zipcode: 24130,
        },
        {
            subDistrict: 'หอมศีล',
            district: 'บางปะกง',
            province: 'ฉะเชิงเทรา',
            zipcode: 24180,
        },
        {
            subDistrict: 'เขาดิน',
            district: 'บางปะกง',
            province: 'ฉะเชิงเทรา',
            zipcode: 24130,
        },
        {
            subDistrict: 'คลองขุด',
            district: 'บ้านโพธิ์',
            province: 'ฉะเชิงเทรา',
            zipcode: 24140,
        },
        {
            subDistrict: 'คลองบ้านโพธิ์',
            district: 'บ้านโพธิ์',
            province: 'ฉะเชิงเทรา',
            zipcode: 24140,
        },
        {
            subDistrict: 'คลองประเวศ',
            district: 'บ้านโพธิ์',
            province: 'ฉะเชิงเทรา',
            zipcode: 24140,
        },
        {
            subDistrict: 'ดอนทราย',
            district: 'บ้านโพธิ์',
            province: 'ฉะเชิงเทรา',
            zipcode: 24140,
        },
        {
            subDistrict: 'ท่าพลับ',
            district: 'บ้านโพธิ์',
            province: 'ฉะเชิงเทรา',
            zipcode: 24140,
        },
        {
            subDistrict: 'บางกรูด',
            district: 'บ้านโพธิ์',
            province: 'ฉะเชิงเทรา',
            zipcode: 24140,
        },
        {
            subDistrict: 'บางซ่อน',
            district: 'บ้านโพธิ์',
            province: 'ฉะเชิงเทรา',
            zipcode: 24140,
        },
        {
            subDistrict: 'บ้านโพธิ์',
            district: 'บ้านโพธิ์',
            province: 'ฉะเชิงเทรา',
            zipcode: 24140,
        },
        {
            subDistrict: 'ลาดขวาง',
            district: 'บ้านโพธิ์',
            province: 'ฉะเชิงเทรา',
            zipcode: 24140,
        },
        {
            subDistrict: 'สนามจันทร์',
            district: 'บ้านโพธิ์',
            province: 'ฉะเชิงเทรา',
            zipcode: 24140,
        },
        {
            subDistrict: 'สิบเอ็ดศอก',
            district: 'บ้านโพธิ์',
            province: 'ฉะเชิงเทรา',
            zipcode: 24140,
        },
        {
            subDistrict: 'หนองตีนนก',
            district: 'บ้านโพธิ์',
            province: 'ฉะเชิงเทรา',
            zipcode: 24140,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'บ้านโพธิ์',
            province: 'ฉะเชิงเทรา',
            zipcode: 24140,
        },
        {
            subDistrict: 'เกาะไร่',
            district: 'บ้านโพธิ์',
            province: 'ฉะเชิงเทรา',
            zipcode: 24140,
        },
        {
            subDistrict: 'เทพราช',
            district: 'บ้านโพธิ์',
            province: 'ฉะเชิงเทรา',
            zipcode: 24140,
        },
        {
            subDistrict: 'แสนภูดาษ',
            district: 'บ้านโพธิ์',
            province: 'ฉะเชิงเทรา',
            zipcode: 24140,
        },
        {
            subDistrict: 'แหลมประดู่',
            district: 'บ้านโพธิ์',
            province: 'ฉะเชิงเทรา',
            zipcode: 24140,
        },
        {
            subDistrict: 'ท่าถ่าน',
            district: 'พนมสารคาม',
            province: 'ฉะเชิงเทรา',
            zipcode: 24120,
        },
        {
            subDistrict: 'บ้านซ่อง',
            district: 'พนมสารคาม',
            province: 'ฉะเชิงเทรา',
            zipcode: 24120,
        },
        {
            subDistrict: 'พนมสารคาม',
            district: 'พนมสารคาม',
            province: 'ฉะเชิงเทรา',
            zipcode: 24120,
        },
        {
            subDistrict: 'หนองยาว',
            district: 'พนมสารคาม',
            province: 'ฉะเชิงเทรา',
            zipcode: 24120,
        },
        {
            subDistrict: 'หนองแหน',
            district: 'พนมสารคาม',
            province: 'ฉะเชิงเทรา',
            zipcode: 24120,
        },
        {
            subDistrict: 'เกาะขนุน',
            district: 'พนมสารคาม',
            province: 'ฉะเชิงเทรา',
            zipcode: 24120,
        },
        {
            subDistrict: 'เขาหินซ้อน',
            district: 'พนมสารคาม',
            province: 'ฉะเชิงเทรา',
            zipcode: 24120,
        },
        {
            subDistrict: 'เมืองเก่า',
            district: 'พนมสารคาม',
            province: 'ฉะเชิงเทรา',
            zipcode: 24120,
        },
        {
            subDistrict: 'ดงน้อย',
            district: 'ราชสาส์น',
            province: 'ฉะเชิงเทรา',
            zipcode: 24120,
        },
        {
            subDistrict: 'บางคา',
            district: 'ราชสาส์น',
            province: 'ฉะเชิงเทรา',
            zipcode: 24120,
        },
        {
            subDistrict: 'เมืองใหม่',
            district: 'ราชสาส์น',
            province: 'ฉะเชิงเทรา',
            zipcode: 24120,
        },
        {
            subDistrict: 'คู้ยายหมี',
            district: 'สนามชัยเขต',
            province: 'ฉะเชิงเทรา',
            zipcode: 24160,
        },
        {
            subDistrict: 'ทุ่งพระยา',
            district: 'สนามชัยเขต',
            province: 'ฉะเชิงเทรา',
            zipcode: 24160,
        },
        {
            subDistrict: 'ท่ากระดาน',
            district: 'สนามชัยเขต',
            province: 'ฉะเชิงเทรา',
            zipcode: 24160,
        },
        {
            subDistrict: 'ลาดกระทิง',
            district: 'สนามชัยเขต',
            province: 'ฉะเชิงเทรา',
            zipcode: 24160,
        },
        {
            subDistrict: 'คลองจุกกระเฌอ',
            district: 'เมืองฉะเชิงเทรา',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'คลองนครเนื่องเขต',
            district: 'เมืองฉะเชิงเทรา',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'คลองนา',
            district: 'เมืองฉะเชิงเทรา',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'คลองหลวงแพ่ง',
            district: 'เมืองฉะเชิงเทรา',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'คลองอุดมชลจร',
            district: 'เมืองฉะเชิงเทรา',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'คลองเปรง',
            district: 'เมืองฉะเชิงเทรา',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'ท่าไข่',
            district: 'เมืองฉะเชิงเทรา',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'บางกะไห',
            district: 'เมืองฉะเชิงเทรา',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'บางขวัญ',
            district: 'เมืองฉะเชิงเทรา',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'บางตีนเป็ด',
            district: 'เมืองฉะเชิงเทรา',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'บางพระ',
            district: 'เมืองฉะเชิงเทรา',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'บางเตย',
            district: 'เมืองฉะเชิงเทรา',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'บางแก้ว',
            district: 'เมืองฉะเชิงเทรา',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'บางไผ่',
            district: 'เมืองฉะเชิงเทรา',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'บ้านใหม่',
            district: 'เมืองฉะเชิงเทรา',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'วังตะเคียน',
            district: 'เมืองฉะเชิงเทรา',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'หนามแดง',
            district: 'เมืองฉะเชิงเทรา',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'หน้าเมือง',
            district: 'เมืองฉะเชิงเทรา',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'โสธร',
            district: 'เมืองฉะเชิงเทรา',
            province: 'ฉะเชิงเทรา',
            zipcode: 24000,
        },
        {
            subDistrict: 'วังเย็น',
            district: 'แปลงยาว',
            province: 'ฉะเชิงเทรา',
            zipcode: 24190,
        },
        {
            subDistrict: 'หนองไม้แก่น',
            district: 'แปลงยาว',
            province: 'ฉะเชิงเทรา',
            zipcode: 24190,
        },
        {
            subDistrict: 'หัวสำโรง',
            district: 'แปลงยาว',
            province: 'ฉะเชิงเทรา',
            zipcode: 24190,
        },
        {
            subDistrict: 'แปลงยาว',
            district: 'แปลงยาว',
            province: 'ฉะเชิงเทรา',
            zipcode: 24190,
        },
        {
            subDistrict: 'ตะเคียนเตี้ย',
            district: 'บางละมุง',
            province: 'ชลบุรี',
            zipcode: 20150,
        },
        {
            subDistrict: 'นาเกลือ',
            district: 'บางละมุง',
            province: 'ชลบุรี',
            zipcode: 20150,
        },
        {
            subDistrict: 'บางละมุง',
            district: 'บางละมุง',
            province: 'ชลบุรี',
            zipcode: 20150,
        },
        {
            subDistrict: 'หนองปรือ',
            district: 'บางละมุง',
            province: 'ชลบุรี',
            zipcode: 20150,
        },
        {
            subDistrict: 'หนองปลาไหล',
            district: 'บางละมุง',
            province: 'ชลบุรี',
            zipcode: 20150,
        },
        {
            subDistrict: 'ห้วยใหญ่',
            district: 'บางละมุง',
            province: 'ชลบุรี',
            zipcode: 20150,
        },
        {
            subDistrict: 'เขาไม้แก้ว',
            district: 'บางละมุง',
            province: 'ชลบุรี',
            zipcode: 20150,
        },
        {
            subDistrict: 'โป่ง',
            district: 'บางละมุง',
            province: 'ชลบุรี',
            zipcode: 20150,
        },
        {
            subDistrict: 'ธาตุทอง',
            district: 'บ่อทอง',
            province: 'ชลบุรี',
            zipcode: 20270,
        },
        {
            subDistrict: 'บ่อกวางทอง',
            district: 'บ่อทอง',
            province: 'ชลบุรี',
            zipcode: 20270,
        },
        {
            subDistrict: 'บ่อทอง',
            district: 'บ่อทอง',
            province: 'ชลบุรี',
            zipcode: 20270,
        },
        {
            subDistrict: 'พลวงทอง',
            district: 'บ่อทอง',
            province: 'ชลบุรี',
            zipcode: 20270,
        },
        {
            subDistrict: 'วัดสุวรรณ',
            district: 'บ่อทอง',
            province: 'ชลบุรี',
            zipcode: 20270,
        },
        {
            subDistrict: 'เกษตรสุวรรณ',
            district: 'บ่อทอง',
            province: 'ชลบุรี',
            zipcode: 20270,
        },
        {
            subDistrict: 'คลองกิ่ว',
            district: 'บ้านบึง',
            province: 'ชลบุรี',
            zipcode: 20220,
        },
        {
            subDistrict: 'บ้านบึง',
            district: 'บ้านบึง',
            province: 'ชลบุรี',
            zipcode: 20170,
        },
        {
            subDistrict: 'มาบไผ่',
            district: 'บ้านบึง',
            province: 'ชลบุรี',
            zipcode: 20170,
        },
        {
            subDistrict: 'หนองชาก',
            district: 'บ้านบึง',
            province: 'ชลบุรี',
            zipcode: 20170,
        },
        {
            subDistrict: 'หนองซ้ำซาก',
            district: 'บ้านบึง',
            province: 'ชลบุรี',
            zipcode: 20170,
        },
        {
            subDistrict: 'หนองบอนแดง',
            district: 'บ้านบึง',
            province: 'ชลบุรี',
            zipcode: 20170,
        },
        {
            subDistrict: 'หนองอิรุณ',
            district: 'บ้านบึง',
            province: 'ชลบุรี',
            zipcode: 20220,
        },
        {
            subDistrict: 'หนองไผ่แก้ว',
            district: 'บ้านบึง',
            province: 'ชลบุรี',
            zipcode: 20220,
        },
        {
            subDistrict: 'กุฎโง้ง',
            district: 'พนัสนิคม',
            province: 'ชลบุรี',
            zipcode: 20140,
        },
        {
            subDistrict: 'ทุ่งขวาง',
            district: 'พนัสนิคม',
            province: 'ชลบุรี',
            zipcode: 20140,
        },
        {
            subDistrict: 'ท่าข้าม',
            district: 'พนัสนิคม',
            province: 'ชลบุรี',
            zipcode: 20140,
        },
        {
            subDistrict: 'นามะตูม',
            district: 'พนัสนิคม',
            province: 'ชลบุรี',
            zipcode: 20140,
        },
        {
            subDistrict: 'นาวังหิน',
            district: 'พนัสนิคม',
            province: 'ชลบุรี',
            zipcode: 20140,
        },
        {
            subDistrict: 'นาเริก',
            district: 'พนัสนิคม',
            province: 'ชลบุรี',
            zipcode: 20140,
        },
        {
            subDistrict: 'บ้านช้าง',
            district: 'พนัสนิคม',
            province: 'ชลบุรี',
            zipcode: 20140,
        },
        {
            subDistrict: 'บ้านเซิด',
            district: 'พนัสนิคม',
            province: 'ชลบุรี',
            zipcode: 20140,
        },
        {
            subDistrict: 'พนัสนิคม',
            district: 'พนัสนิคม',
            province: 'ชลบุรี',
            zipcode: 20140,
        },
        {
            subDistrict: 'วัดหลวง',
            district: 'พนัสนิคม',
            province: 'ชลบุรี',
            zipcode: 20140,
        },
        {
            subDistrict: 'วัดโบสถ์',
            district: 'พนัสนิคม',
            province: 'ชลบุรี',
            zipcode: 20140,
        },
        {
            subDistrict: 'สระสี่เหลี่ยม',
            district: 'พนัสนิคม',
            province: 'ชลบุรี',
            zipcode: 20140,
        },
        {
            subDistrict: 'หนองขยาด',
            district: 'พนัสนิคม',
            province: 'ชลบุรี',
            zipcode: 20140,
        },
        {
            subDistrict: 'หนองปรือ',
            district: 'พนัสนิคม',
            province: 'ชลบุรี',
            zipcode: 20140,
        },
        {
            subDistrict: 'หนองเหียง',
            district: 'พนัสนิคม',
            province: 'ชลบุรี',
            zipcode: 20140,
        },
        {
            subDistrict: 'หน้าพระธาตุ',
            district: 'พนัสนิคม',
            province: 'ชลบุรี',
            zipcode: 20140,
        },
        {
            subDistrict: 'หมอนนาง',
            district: 'พนัสนิคม',
            province: 'ชลบุรี',
            zipcode: 20140,
        },
        {
            subDistrict: 'หัวถนน',
            district: 'พนัสนิคม',
            province: 'ชลบุรี',
            zipcode: 20140,
        },
        {
            subDistrict: 'โคกเพลาะ',
            district: 'พนัสนิคม',
            province: 'ชลบุรี',
            zipcode: 20140,
        },
        {
            subDistrict: 'ไร่หลักทอง',
            district: 'พนัสนิคม',
            province: 'ชลบุรี',
            zipcode: 20140,
        },
        {
            subDistrict: 'บางนาง',
            district: 'พานทอง',
            province: 'ชลบุรี',
            zipcode: 20160,
        },
        {
            subDistrict: 'บางหัก',
            district: 'พานทอง',
            province: 'ชลบุรี',
            zipcode: 20160,
        },
        {
            subDistrict: 'บ้านเก่า',
            district: 'พานทอง',
            province: 'ชลบุรี',
            zipcode: 20160,
        },
        {
            subDistrict: 'พานทอง',
            district: 'พานทอง',
            province: 'ชลบุรี',
            zipcode: 20160,
        },
        {
            subDistrict: 'มาบโป่ง',
            district: 'พานทอง',
            province: 'ชลบุรี',
            zipcode: 20160,
        },
        {
            subDistrict: 'หนองกะขะ',
            district: 'พานทอง',
            province: 'ชลบุรี',
            zipcode: 20160,
        },
        {
            subDistrict: 'หนองตำลึง',
            district: 'พานทอง',
            province: 'ชลบุรี',
            zipcode: 20160,
        },
        {
            subDistrict: 'หนองหงษ์',
            district: 'พานทอง',
            province: 'ชลบุรี',
            zipcode: 20160,
        },
        {
            subDistrict: 'หน้าประดู่',
            district: 'พานทอง',
            province: 'ชลบุรี',
            zipcode: 20160,
        },
        {
            subDistrict: 'เกาะลอย',
            district: 'พานทอง',
            province: 'ชลบุรี',
            zipcode: 20160,
        },
        {
            subDistrict: 'โคกขี้หนอน',
            district: 'พานทอง',
            province: 'ชลบุรี',
            zipcode: 20160,
        },
        {
            subDistrict: 'ทุ่งสุขลา',
            district: 'ศรีราชา',
            province: 'ชลบุรี',
            zipcode: 20230,
        },
        {
            subDistrict: 'บางพระ',
            district: 'ศรีราชา',
            province: 'ชลบุรี',
            zipcode: 20110,
        },
        {
            subDistrict: 'บึง',
            district: 'ศรีราชา',
            province: 'ชลบุรี',
            zipcode: 20230,
        },
        {
            subDistrict: 'บ่อวิน',
            district: 'ศรีราชา',
            province: 'ชลบุรี',
            zipcode: 20230,
        },
        {
            subDistrict: 'ศรีราชา',
            district: 'ศรีราชา',
            province: 'ชลบุรี',
            zipcode: 20110,
        },
        {
            subDistrict: 'สุรศักดิ์',
            district: 'ศรีราชา',
            province: 'ชลบุรี',
            zipcode: 20110,
        },
        {
            subDistrict: 'หนองขาม',
            district: 'ศรีราชา',
            province: 'ชลบุรี',
            zipcode: 20230,
        },
        {
            subDistrict: 'เขาคันทรง',
            district: 'ศรีราชา',
            province: 'ชลบุรี',
            zipcode: 20110,
        },
        {
            subDistrict: 'นาจอมเทียน',
            district: 'สัตหีบ',
            province: 'ชลบุรี',
            zipcode: 20250,
        },
        {
            subDistrict: 'บางเสร่',
            district: 'สัตหีบ',
            province: 'ชลบุรี',
            zipcode: 20250,
        },
        {
            subDistrict: 'พลูตาหลวง',
            district: 'สัตหีบ',
            province: 'ชลบุรี',
            zipcode: 20180,
        },
        {
            subDistrict: 'สัตหีบ',
            district: 'สัตหีบ',
            province: 'ชลบุรี',
            zipcode: 20180,
        },
        {
            subDistrict: 'แสมสาร',
            district: 'สัตหีบ',
            province: 'ชลบุรี',
            zipcode: 20180,
        },
        {
            subDistrict: 'คลองพลู',
            district: 'หนองใหญ่',
            province: 'ชลบุรี',
            zipcode: 20190,
        },
        {
            subDistrict: 'หนองเสือช้าง',
            district: 'หนองใหญ่',
            province: 'ชลบุรี',
            zipcode: 20190,
        },
        {
            subDistrict: 'หนองใหญ่',
            district: 'หนองใหญ่',
            province: 'ชลบุรี',
            zipcode: 20190,
        },
        {
            subDistrict: 'ห้างสูง',
            district: 'หนองใหญ่',
            province: 'ชลบุรี',
            zipcode: 20190,
        },
        {
            subDistrict: 'เขาซก',
            district: 'หนองใหญ่',
            province: 'ชลบุรี',
            zipcode: 20190,
        },
        {
            subDistrict: 'ท่าบุญมี',
            district: 'เกาะจันทร์',
            province: 'ชลบุรี',
            zipcode: 20240,
        },
        {
            subDistrict: 'เกาะจันทร์',
            district: 'เกาะจันทร์',
            province: 'ชลบุรี',
            zipcode: 20240,
        },
        {
            subDistrict: 'ท่าเทววงษ์',
            district: 'เกาะสีชัง',
            province: 'ชลบุรี',
            zipcode: 20120,
        },
        {
            subDistrict: 'คลองตำหรุ',
            district: 'เมืองชลบุรี',
            province: 'ชลบุรี',
            zipcode: 20000,
        },
        {
            subDistrict: 'ดอนหัวฬ่อ',
            district: 'เมืองชลบุรี',
            province: 'ชลบุรี',
            zipcode: 20000,
        },
        {
            subDistrict: 'นาป่า',
            district: 'เมืองชลบุรี',
            province: 'ชลบุรี',
            zipcode: 20000,
        },
        {
            subDistrict: 'บางทราย',
            district: 'เมืองชลบุรี',
            province: 'ชลบุรี',
            zipcode: 20000,
        },
        {
            subDistrict: 'บางปลาสร้อย',
            district: 'เมืองชลบุรี',
            province: 'ชลบุรี',
            zipcode: 20000,
        },
        {
            subDistrict: 'บ้านปึก',
            district: 'เมืองชลบุรี',
            province: 'ชลบุรี',
            zipcode: 20130,
        },
        {
            subDistrict: 'บ้านสวน',
            district: 'เมืองชลบุรี',
            province: 'ชลบุรี',
            zipcode: 20000,
        },
        {
            subDistrict: 'บ้านโขด',
            district: 'เมืองชลบุรี',
            province: 'ชลบุรี',
            zipcode: 20000,
        },
        {
            subDistrict: 'มะขามหย่ง',
            district: 'เมืองชลบุรี',
            province: 'ชลบุรี',
            zipcode: 20000,
        },
        {
            subDistrict: 'สำนักบก',
            district: 'เมืองชลบุรี',
            province: 'ชลบุรี',
            zipcode: 20000,
        },
        {
            subDistrict: 'หนองข้างคอก',
            district: 'เมืองชลบุรี',
            province: 'ชลบุรี',
            zipcode: 20000,
        },
        {
            subDistrict: 'หนองรี',
            district: 'เมืองชลบุรี',
            province: 'ชลบุรี',
            zipcode: 20000,
        },
        {
            subDistrict: 'หนองไม้แดง',
            district: 'เมืองชลบุรี',
            province: 'ชลบุรี',
            zipcode: 20000,
        },
        {
            subDistrict: 'ห้วยกะปิ',
            district: 'เมืองชลบุรี',
            province: 'ชลบุรี',
            zipcode: 20000,
        },
        {
            subDistrict: 'ห้วยกะปิ',
            district: 'เมืองชลบุรี',
            province: 'ชลบุรี',
            zipcode: 20130,
        },
        {
            subDistrict: 'อ่างศิลา',
            district: 'เมืองชลบุรี',
            province: 'ชลบุรี',
            zipcode: 20000,
        },
        {
            subDistrict: 'เสม็ด',
            district: 'เมืองชลบุรี',
            province: 'ชลบุรี',
            zipcode: 20000,
        },
        {
            subDistrict: 'เหมือง',
            district: 'เมืองชลบุรี',
            province: 'ชลบุรี',
            zipcode: 20130,
        },
        {
            subDistrict: 'แสนสุข',
            district: 'เมืองชลบุรี',
            province: 'ชลบุรี',
            zipcode: 20130,
        },
        {
            subDistrict: 'คุ้งสำเภา',
            district: 'มโนรมย์',
            province: 'ชัยนาท',
            zipcode: 17110,
        },
        {
            subDistrict: 'ท่าฉนวน',
            district: 'มโนรมย์',
            province: 'ชัยนาท',
            zipcode: 17110,
        },
        {
            subDistrict: 'วัดโคก',
            district: 'มโนรมย์',
            province: 'ชัยนาท',
            zipcode: 17110,
        },
        {
            subDistrict: 'ศิลาดาน',
            district: 'มโนรมย์',
            province: 'ชัยนาท',
            zipcode: 17110,
        },
        {
            subDistrict: 'หางน้ำสาคร',
            district: 'มโนรมย์',
            province: 'ชัยนาท',
            zipcode: 17170,
        },
        {
            subDistrict: 'อู่ตะเภา',
            district: 'มโนรมย์',
            province: 'ชัยนาท',
            zipcode: 17170,
        },
        {
            subDistrict: 'ไร่พัฒนา',
            district: 'มโนรมย์',
            province: 'ชัยนาท',
            zipcode: 17170,
        },
        {
            subDistrict: 'บ่อแร่',
            district: 'วัดสิงห์',
            province: 'ชัยนาท',
            zipcode: 17120,
        },
        {
            subDistrict: 'มะขามเฒ่า',
            district: 'วัดสิงห์',
            province: 'ชัยนาท',
            zipcode: 17120,
        },
        {
            subDistrict: 'วังหมัน',
            district: 'วัดสิงห์',
            province: 'ชัยนาท',
            zipcode: 17120,
        },
        {
            subDistrict: 'วัดสิงห์',
            district: 'วัดสิงห์',
            province: 'ชัยนาท',
            zipcode: 17120,
        },
        {
            subDistrict: 'หนองขุ่น',
            district: 'วัดสิงห์',
            province: 'ชัยนาท',
            zipcode: 17120,
        },
        {
            subDistrict: 'หนองน้อย',
            district: 'วัดสิงห์',
            province: 'ชัยนาท',
            zipcode: 17120,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'วัดสิงห์',
            province: 'ชัยนาท',
            zipcode: 17120,
        },
        {
            subDistrict: 'ดงคอน',
            district: 'สรรคบุรี',
            province: 'ชัยนาท',
            zipcode: 17140,
        },
        {
            subDistrict: 'ดอนกำ',
            district: 'สรรคบุรี',
            province: 'ชัยนาท',
            zipcode: 17140,
        },
        {
            subDistrict: 'บางขุด',
            district: 'สรรคบุรี',
            province: 'ชัยนาท',
            zipcode: 17140,
        },
        {
            subDistrict: 'ห้วยกรด',
            district: 'สรรคบุรี',
            province: 'ชัยนาท',
            zipcode: 17140,
        },
        {
            subDistrict: 'ห้วยกรดพัฒนา',
            district: 'สรรคบุรี',
            province: 'ชัยนาท',
            zipcode: 17140,
        },
        {
            subDistrict: 'เที่ยงแท้',
            district: 'สรรคบุรี',
            province: 'ชัยนาท',
            zipcode: 17140,
        },
        {
            subDistrict: 'แพรกศรีราชา',
            district: 'สรรคบุรี',
            province: 'ชัยนาท',
            zipcode: 17140,
        },
        {
            subDistrict: 'โพงาม',
            district: 'สรรคบุรี',
            province: 'ชัยนาท',
            zipcode: 17140,
        },
        {
            subDistrict: 'ตลุก',
            district: 'สรรพยา',
            province: 'ชัยนาท',
            zipcode: 17150,
        },
        {
            subDistrict: 'บางหลวง',
            district: 'สรรพยา',
            province: 'ชัยนาท',
            zipcode: 17150,
        },
        {
            subDistrict: 'สรรพยา',
            district: 'สรรพยา',
            province: 'ชัยนาท',
            zipcode: 17150,
        },
        {
            subDistrict: 'หาดอาษา',
            district: 'สรรพยา',
            province: 'ชัยนาท',
            zipcode: 17150,
        },
        {
            subDistrict: 'เขาแก้ว',
            district: 'สรรพยา',
            province: 'ชัยนาท',
            zipcode: 17150,
        },
        {
            subDistrict: 'โพนางดำตก',
            district: 'สรรพยา',
            province: 'ชัยนาท',
            zipcode: 17150,
        },
        {
            subDistrict: 'โพนางดำออก',
            district: 'สรรพยา',
            province: 'ชัยนาท',
            zipcode: 17150,
        },
        {
            subDistrict: 'กุดจอก',
            district: 'หนองมะโมง',
            province: 'ชัยนาท',
            zipcode: 17120,
        },
        {
            subDistrict: 'วังตะเคียน',
            district: 'หนองมะโมง',
            province: 'ชัยนาท',
            zipcode: 17120,
        },
        {
            subDistrict: 'สะพานหิน',
            district: 'หนองมะโมง',
            province: 'ชัยนาท',
            zipcode: 17120,
        },
        {
            subDistrict: 'หนองมะโมง',
            district: 'หนองมะโมง',
            province: 'ชัยนาท',
            zipcode: 17120,
        },
        {
            subDistrict: 'บ้านเชี่ยน',
            district: 'หันคา',
            province: 'ชัยนาท',
            zipcode: 17130,
        },
        {
            subDistrict: 'วังไก่เถื่อน',
            district: 'หันคา',
            province: 'ชัยนาท',
            zipcode: 17130,
        },
        {
            subDistrict: 'สามง่ามท่าโบสถ์',
            district: 'หันคา',
            province: 'ชัยนาท',
            zipcode: 17160,
        },
        {
            subDistrict: 'หนองแซง',
            district: 'หันคา',
            province: 'ชัยนาท',
            zipcode: 17160,
        },
        {
            subDistrict: 'หันคา',
            district: 'หันคา',
            province: 'ชัยนาท',
            zipcode: 17130,
        },
        {
            subDistrict: 'ห้วยงู',
            district: 'หันคา',
            province: 'ชัยนาท',
            zipcode: 17160,
        },
        {
            subDistrict: 'เด่นใหญ่',
            district: 'หันคา',
            province: 'ชัยนาท',
            zipcode: 17130,
        },
        {
            subDistrict: 'ไพรนกยูง',
            district: 'หันคา',
            province: 'ชัยนาท',
            zipcode: 17130,
        },
        {
            subDistrict: 'กะบกเตี้ย',
            district: 'เนินขาม',
            province: 'ชัยนาท',
            zipcode: 17130,
        },
        {
            subDistrict: 'สุขเดือนห้า',
            district: 'เนินขาม',
            province: 'ชัยนาท',
            zipcode: 17130,
        },
        {
            subDistrict: 'เนินขาม',
            district: 'เนินขาม',
            province: 'ชัยนาท',
            zipcode: 17130,
        },
        {
            subDistrict: 'ชัยนาท',
            district: 'เมืองชัยนาท',
            province: 'ชัยนาท',
            zipcode: 17000,
        },
        {
            subDistrict: 'ท่าชัย',
            district: 'เมืองชัยนาท',
            province: 'ชัยนาท',
            zipcode: 17000,
        },
        {
            subDistrict: 'ธรรมามูล',
            district: 'เมืองชัยนาท',
            province: 'ชัยนาท',
            zipcode: 17000,
        },
        {
            subDistrict: 'นางลือ',
            district: 'เมืองชัยนาท',
            province: 'ชัยนาท',
            zipcode: 17000,
        },
        {
            subDistrict: 'บ้านกล้วย',
            district: 'เมืองชัยนาท',
            province: 'ชัยนาท',
            zipcode: 17000,
        },
        {
            subDistrict: 'หาดท่าเสา',
            district: 'เมืองชัยนาท',
            province: 'ชัยนาท',
            zipcode: 17120,
        },
        {
            subDistrict: 'เขาท่าพระ',
            district: 'เมืองชัยนาท',
            province: 'ชัยนาท',
            zipcode: 17000,
        },
        {
            subDistrict: 'เสือโฮก',
            district: 'เมืองชัยนาท',
            province: 'ชัยนาท',
            zipcode: 17000,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'เมืองชัยนาท',
            province: 'ชัยนาท',
            zipcode: 17000,
        },
        {
            subDistrict: 'คอนสวรรค์',
            district: 'คอนสวรรค์',
            province: 'ชัยภูมิ',
            zipcode: 36140,
        },
        {
            subDistrict: 'ช่องสามหมอ',
            district: 'คอนสวรรค์',
            province: 'ชัยภูมิ',
            zipcode: 36140,
        },
        {
            subDistrict: 'บ้านโสก',
            district: 'คอนสวรรค์',
            province: 'ชัยภูมิ',
            zipcode: 36140,
        },
        {
            subDistrict: 'ยางหวาย',
            district: 'คอนสวรรค์',
            province: 'ชัยภูมิ',
            zipcode: 36140,
        },
        {
            subDistrict: 'ศรีสำราญ',
            district: 'คอนสวรรค์',
            province: 'ชัยภูมิ',
            zipcode: 36140,
        },
        {
            subDistrict: 'หนองขาม',
            district: 'คอนสวรรค์',
            province: 'ชัยภูมิ',
            zipcode: 36140,
        },
        {
            subDistrict: 'ห้วยไร่',
            district: 'คอนสวรรค์',
            province: 'ชัยภูมิ',
            zipcode: 36140,
        },
        {
            subDistrict: 'โคกมั่งงอย',
            district: 'คอนสวรรค์',
            province: 'ชัยภูมิ',
            zipcode: 36140,
        },
        {
            subDistrict: 'โนนสะอาด',
            district: 'คอนสวรรค์',
            province: 'ชัยภูมิ',
            zipcode: 36140,
        },
        {
            subDistrict: 'คอนสาร',
            district: 'คอนสาร',
            province: 'ชัยภูมิ',
            zipcode: 36180,
        },
        {
            subDistrict: 'ดงกลาง',
            district: 'คอนสาร',
            province: 'ชัยภูมิ',
            zipcode: 36180,
        },
        {
            subDistrict: 'ดงบัง',
            district: 'คอนสาร',
            province: 'ชัยภูมิ',
            zipcode: 36180,
        },
        {
            subDistrict: 'ทุ่งนาเลา',
            district: 'คอนสาร',
            province: 'ชัยภูมิ',
            zipcode: 36180,
        },
        {
            subDistrict: 'ทุ่งพระ',
            district: 'คอนสาร',
            province: 'ชัยภูมิ',
            zipcode: 36180,
        },
        {
            subDistrict: 'ทุ่งลุยลาย',
            district: 'คอนสาร',
            province: 'ชัยภูมิ',
            zipcode: 36180,
        },
        {
            subDistrict: 'ห้วยยาง',
            district: 'คอนสาร',
            province: 'ชัยภูมิ',
            zipcode: 36180,
        },
        {
            subDistrict: 'โนนคูณ',
            district: 'คอนสาร',
            province: 'ชัยภูมิ',
            zipcode: 36180,
        },
        {
            subDistrict: 'กุดน้ำใส',
            district: 'จัตุรัส',
            province: 'ชัยภูมิ',
            zipcode: 36130,
        },
        {
            subDistrict: 'บ้านกอก',
            district: 'จัตุรัส',
            province: 'ชัยภูมิ',
            zipcode: 36130,
        },
        {
            subDistrict: 'บ้านขาม',
            district: 'จัตุรัส',
            province: 'ชัยภูมิ',
            zipcode: 36130,
        },
        {
            subDistrict: 'ละหาน',
            district: 'จัตุรัส',
            province: 'ชัยภูมิ',
            zipcode: 36130,
        },
        {
            subDistrict: 'ส้มป่อย',
            district: 'จัตุรัส',
            province: 'ชัยภูมิ',
            zipcode: 36130,
        },
        {
            subDistrict: 'หนองบัวบาน',
            district: 'จัตุรัส',
            province: 'ชัยภูมิ',
            zipcode: 36130,
        },
        {
            subDistrict: 'หนองบัวโคก',
            district: 'จัตุรัส',
            province: 'ชัยภูมิ',
            zipcode: 36220,
        },
        {
            subDistrict: 'หนองบัวใหญ่',
            district: 'จัตุรัส',
            province: 'ชัยภูมิ',
            zipcode: 36130,
        },
        {
            subDistrict: 'หนองโดน',
            district: 'จัตุรัส',
            province: 'ชัยภูมิ',
            zipcode: 36130,
        },
        {
            subDistrict: 'ซับใหญ่',
            district: 'ซับใหญ่',
            province: 'ชัยภูมิ',
            zipcode: 36130,
        },
        {
            subDistrict: 'ตะโกทอง',
            district: 'ซับใหญ่',
            province: 'ชัยภูมิ',
            zipcode: 36130,
        },
        {
            subDistrict: 'ท่ากูบ',
            district: 'ซับใหญ่',
            province: 'ชัยภูมิ',
            zipcode: 36130,
        },
        {
            subDistrict: 'บ้านชวน',
            district: 'บำเหน็จณรงค์',
            province: 'ชัยภูมิ',
            zipcode: 36160,
        },
        {
            subDistrict: 'บ้านตาล',
            district: 'บำเหน็จณรงค์',
            province: 'ชัยภูมิ',
            zipcode: 36220,
        },
        {
            subDistrict: 'บ้านเพชร',
            district: 'บำเหน็จณรงค์',
            province: 'ชัยภูมิ',
            zipcode: 36160,
        },
        {
            subDistrict: 'หัวทะเล',
            district: 'บำเหน็จณรงค์',
            province: 'ชัยภูมิ',
            zipcode: 36220,
        },
        {
            subDistrict: 'เกาะมะนาว',
            district: 'บำเหน็จณรงค์',
            province: 'ชัยภูมิ',
            zipcode: 36160,
        },
        {
            subDistrict: 'โคกเพชรพัฒนา',
            district: 'บำเหน็จณรงค์',
            province: 'ชัยภูมิ',
            zipcode: 36160,
        },
        {
            subDistrict: 'โคกเริงรมย์',
            district: 'บำเหน็จณรงค์',
            province: 'ชัยภูมิ',
            zipcode: 36160,
        },
        {
            subDistrict: 'ชีบน',
            district: 'บ้านเขว้า',
            province: 'ชัยภูมิ',
            zipcode: 36170,
        },
        {
            subDistrict: 'ตลาดแร้ง',
            district: 'บ้านเขว้า',
            province: 'ชัยภูมิ',
            zipcode: 36170,
        },
        {
            subDistrict: 'บ้านเขว้า',
            district: 'บ้านเขว้า',
            province: 'ชัยภูมิ',
            zipcode: 36170,
        },
        {
            subDistrict: 'ภูแลนคา',
            district: 'บ้านเขว้า',
            province: 'ชัยภูมิ',
            zipcode: 36170,
        },
        {
            subDistrict: 'ลุ่มลำชี',
            district: 'บ้านเขว้า',
            province: 'ชัยภูมิ',
            zipcode: 36170,
        },
        {
            subDistrict: 'โนนแดง',
            district: 'บ้านเขว้า',
            province: 'ชัยภูมิ',
            zipcode: 36170,
        },
        {
            subDistrict: 'บ้านเต่า',
            district: 'บ้านแท่น',
            province: 'ชัยภูมิ',
            zipcode: 36190,
        },
        {
            subDistrict: 'บ้านแท่น',
            district: 'บ้านแท่น',
            province: 'ชัยภูมิ',
            zipcode: 36190,
        },
        {
            subDistrict: 'สระพัง',
            district: 'บ้านแท่น',
            province: 'ชัยภูมิ',
            zipcode: 36190,
        },
        {
            subDistrict: 'สามสวน',
            district: 'บ้านแท่น',
            province: 'ชัยภูมิ',
            zipcode: 36190,
        },
        {
            subDistrict: 'หนองคู',
            district: 'บ้านแท่น',
            province: 'ชัยภูมิ',
            zipcode: 36190,
        },
        {
            subDistrict: 'บ้านเจียง',
            district: 'ภักดีชุมพล',
            province: 'ชัยภูมิ',
            zipcode: 36260,
        },
        {
            subDistrict: 'วังทอง',
            district: 'ภักดีชุมพล',
            province: 'ชัยภูมิ',
            zipcode: 36260,
        },
        {
            subDistrict: 'เจาทอง',
            district: 'ภักดีชุมพล',
            province: 'ชัยภูมิ',
            zipcode: 36260,
        },
        {
            subDistrict: 'แหลมทอง',
            district: 'ภักดีชุมพล',
            province: 'ชัยภูมิ',
            zipcode: 36260,
        },
        {
            subDistrict: 'กวางโจน',
            district: 'ภูเขียว',
            province: 'ชัยภูมิ',
            zipcode: 36110,
        },
        {
            subDistrict: 'กุดยม',
            district: 'ภูเขียว',
            province: 'ชัยภูมิ',
            zipcode: 36110,
        },
        {
            subDistrict: 'ธาตุทอง',
            district: 'ภูเขียว',
            province: 'ชัยภูมิ',
            zipcode: 36110,
        },
        {
            subDistrict: 'บ้านดอน',
            district: 'ภูเขียว',
            province: 'ชัยภูมิ',
            zipcode: 36110,
        },
        {
            subDistrict: 'บ้านเพชร',
            district: 'ภูเขียว',
            province: 'ชัยภูมิ',
            zipcode: 36110,
        },
        {
            subDistrict: 'บ้านแก้ง',
            district: 'ภูเขียว',
            province: 'ชัยภูมิ',
            zipcode: 36110,
        },
        {
            subDistrict: 'ผักปัง',
            district: 'ภูเขียว',
            province: 'ชัยภูมิ',
            zipcode: 36110,
        },
        {
            subDistrict: 'หนองคอนไทย',
            district: 'ภูเขียว',
            province: 'ชัยภูมิ',
            zipcode: 36110,
        },
        {
            subDistrict: 'หนองตูม',
            district: 'ภูเขียว',
            province: 'ชัยภูมิ',
            zipcode: 36110,
        },
        {
            subDistrict: 'โคกสะอาด',
            district: 'ภูเขียว',
            province: 'ชัยภูมิ',
            zipcode: 36110,
        },
        {
            subDistrict: 'โอโล',
            district: 'ภูเขียว',
            province: 'ชัยภูมิ',
            zipcode: 36110,
        },
        {
            subDistrict: 'วังตะเฆ่',
            district: 'หนองบัวระเหว',
            province: 'ชัยภูมิ',
            zipcode: 36250,
        },
        {
            subDistrict: 'หนองบัวระเหว',
            district: 'หนองบัวระเหว',
            province: 'ชัยภูมิ',
            zipcode: 36250,
        },
        {
            subDistrict: 'ห้วยแย้',
            district: 'หนองบัวระเหว',
            province: 'ชัยภูมิ',
            zipcode: 36250,
        },
        {
            subDistrict: 'โคกสะอาด',
            district: 'หนองบัวระเหว',
            province: 'ชัยภูมิ',
            zipcode: 36250,
        },
        {
            subDistrict: 'โสกปลาดุก',
            district: 'หนองบัวระเหว',
            province: 'ชัยภูมิ',
            zipcode: 36250,
        },
        {
            subDistrict: 'กุดชุมแสง',
            district: 'หนองบัวแดง',
            province: 'ชัยภูมิ',
            zipcode: 36210,
        },
        {
            subDistrict: 'คูเมือง',
            district: 'หนองบัวแดง',
            province: 'ชัยภูมิ',
            zipcode: 36210,
        },
        {
            subDistrict: 'ถ้ำวัวแดง',
            district: 'หนองบัวแดง',
            province: 'ชัยภูมิ',
            zipcode: 36210,
        },
        {
            subDistrict: 'ท่าใหญ่',
            district: 'หนองบัวแดง',
            province: 'ชัยภูมิ',
            zipcode: 36210,
        },
        {
            subDistrict: 'นางแดด',
            district: 'หนองบัวแดง',
            province: 'ชัยภูมิ',
            zipcode: 36210,
        },
        {
            subDistrict: 'วังชมภู',
            district: 'หนองบัวแดง',
            province: 'ชัยภูมิ',
            zipcode: 36210,
        },
        {
            subDistrict: 'หนองบัวแดง',
            district: 'หนองบัวแดง',
            province: 'ชัยภูมิ',
            zipcode: 36210,
        },
        {
            subDistrict: 'หนองแวง',
            district: 'หนองบัวแดง',
            province: 'ชัยภูมิ',
            zipcode: 36210,
        },
        {
            subDistrict: 'กุดเลาะ',
            district: 'เกษตรสมบูรณ์',
            province: 'ชัยภูมิ',
            zipcode: 36120,
        },
        {
            subDistrict: 'บ้านบัว',
            district: 'เกษตรสมบูรณ์',
            province: 'ชัยภูมิ',
            zipcode: 36120,
        },
        {
            subDistrict: 'บ้านยาง',
            district: 'เกษตรสมบูรณ์',
            province: 'ชัยภูมิ',
            zipcode: 36120,
        },
        {
            subDistrict: 'บ้านหัน',
            district: 'เกษตรสมบูรณ์',
            province: 'ชัยภูมิ',
            zipcode: 36120,
        },
        {
            subDistrict: 'บ้านเดื่อ',
            district: 'เกษตรสมบูรณ์',
            province: 'ชัยภูมิ',
            zipcode: 36120,
        },
        {
            subDistrict: 'บ้านเป้า',
            district: 'เกษตรสมบูรณ์',
            province: 'ชัยภูมิ',
            zipcode: 36120,
        },
        {
            subDistrict: 'สระโพนทอง',
            district: 'เกษตรสมบูรณ์',
            province: 'ชัยภูมิ',
            zipcode: 36120,
        },
        {
            subDistrict: 'หนองข่า',
            district: 'เกษตรสมบูรณ์',
            province: 'ชัยภูมิ',
            zipcode: 36120,
        },
        {
            subDistrict: 'หนองโพนงาม',
            district: 'เกษตรสมบูรณ์',
            province: 'ชัยภูมิ',
            zipcode: 36120,
        },
        {
            subDistrict: 'โนนกอก',
            district: 'เกษตรสมบูรณ์',
            province: 'ชัยภูมิ',
            zipcode: 36120,
        },
        {
            subDistrict: 'โนนทอง',
            district: 'เกษตรสมบูรณ์',
            province: 'ชัยภูมิ',
            zipcode: 36120,
        },
        {
            subDistrict: 'นายางกลัก',
            district: 'เทพสถิต',
            province: 'ชัยภูมิ',
            zipcode: 36230,
        },
        {
            subDistrict: 'บ้านไร่',
            district: 'เทพสถิต',
            province: 'ชัยภูมิ',
            zipcode: 36230,
        },
        {
            subDistrict: 'วะตะแบก',
            district: 'เทพสถิต',
            province: 'ชัยภูมิ',
            zipcode: 36230,
        },
        {
            subDistrict: 'ห้วยยายจิ๋ว',
            district: 'เทพสถิต',
            province: 'ชัยภูมิ',
            zipcode: 36230,
        },
        {
            subDistrict: 'โป่งนก',
            district: 'เทพสถิต',
            province: 'ชัยภูมิ',
            zipcode: 36230,
        },
        {
            subDistrict: 'กะฮาด',
            district: 'เนินสง่า',
            province: 'ชัยภูมิ',
            zipcode: 36130,
        },
        {
            subDistrict: 'ตาเนิน',
            district: 'เนินสง่า',
            province: 'ชัยภูมิ',
            zipcode: 36130,
        },
        {
            subDistrict: 'รังงาม',
            district: 'เนินสง่า',
            province: 'ชัยภูมิ',
            zipcode: 36130,
        },
        {
            subDistrict: 'หนองฉิม',
            district: 'เนินสง่า',
            province: 'ชัยภูมิ',
            zipcode: 36130,
        },
        {
            subDistrict: 'กุดตุ้ม',
            district: 'เมืองชัยภูมิ',
            province: 'ชัยภูมิ',
            zipcode: 36000,
        },
        {
            subDistrict: 'ชีลอง',
            district: 'เมืองชัยภูมิ',
            province: 'ชัยภูมิ',
            zipcode: 36000,
        },
        {
            subDistrict: 'ซับสีทอง',
            district: 'เมืองชัยภูมิ',
            province: 'ชัยภูมิ',
            zipcode: 36000,
        },
        {
            subDistrict: 'ท่าหินโงม',
            district: 'เมืองชัยภูมิ',
            province: 'ชัยภูมิ',
            zipcode: 36000,
        },
        {
            subDistrict: 'นาฝาย',
            district: 'เมืองชัยภูมิ',
            province: 'ชัยภูมิ',
            zipcode: 36000,
        },
        {
            subDistrict: 'นาเสียว',
            district: 'เมืองชัยภูมิ',
            province: 'ชัยภูมิ',
            zipcode: 36000,
        },
        {
            subDistrict: 'บุ่งคล้า',
            district: 'เมืองชัยภูมิ',
            province: 'ชัยภูมิ',
            zipcode: 36000,
        },
        {
            subDistrict: 'บ้านค่าย',
            district: 'เมืองชัยภูมิ',
            province: 'ชัยภูมิ',
            zipcode: 36240,
        },
        {
            subDistrict: 'บ้านเล่า',
            district: 'เมืองชัยภูมิ',
            province: 'ชัยภูมิ',
            zipcode: 36000,
        },
        {
            subDistrict: 'รอบเมือง',
            district: 'เมืองชัยภูมิ',
            province: 'ชัยภูมิ',
            zipcode: 36000,
        },
        {
            subDistrict: 'ลาดใหญ่',
            district: 'เมืองชัยภูมิ',
            province: 'ชัยภูมิ',
            zipcode: 36000,
        },
        {
            subDistrict: 'หนองนาแซง',
            district: 'เมืองชัยภูมิ',
            province: 'ชัยภูมิ',
            zipcode: 36000,
        },
        {
            subDistrict: 'หนองไผ่',
            district: 'เมืองชัยภูมิ',
            province: 'ชัยภูมิ',
            zipcode: 36240,
        },
        {
            subDistrict: 'ห้วยต้อน',
            district: 'เมืองชัยภูมิ',
            province: 'ชัยภูมิ',
            zipcode: 36000,
        },
        {
            subDistrict: 'ห้วยบง',
            district: 'เมืองชัยภูมิ',
            province: 'ชัยภูมิ',
            zipcode: 36000,
        },
        {
            subDistrict: 'โคกสูง',
            district: 'เมืองชัยภูมิ',
            province: 'ชัยภูมิ',
            zipcode: 36000,
        },
        {
            subDistrict: 'โนนสำราญ',
            district: 'เมืองชัยภูมิ',
            province: 'ชัยภูมิ',
            zipcode: 36240,
        },
        {
            subDistrict: 'โพนทอง',
            district: 'เมืองชัยภูมิ',
            province: 'ชัยภูมิ',
            zipcode: 36000,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'เมืองชัยภูมิ',
            province: 'ชัยภูมิ',
            zipcode: 36000,
        },
        {
            subDistrict: 'ช่องสามหมอ',
            district: 'แก้งคร้อ',
            province: 'ชัยภูมิ',
            zipcode: 36150,
        },
        {
            subDistrict: 'ท่ามะไฟหวาน',
            district: 'แก้งคร้อ',
            province: 'ชัยภูมิ',
            zipcode: 36150,
        },
        {
            subDistrict: 'นาหนองทุ่ม',
            district: 'แก้งคร้อ',
            province: 'ชัยภูมิ',
            zipcode: 36150,
        },
        {
            subDistrict: 'บ้านแก้ง',
            district: 'แก้งคร้อ',
            province: 'ชัยภูมิ',
            zipcode: 36150,
        },
        {
            subDistrict: 'หนองขาม',
            district: 'แก้งคร้อ',
            province: 'ชัยภูมิ',
            zipcode: 36150,
        },
        {
            subDistrict: 'หนองสังข์',
            district: 'แก้งคร้อ',
            province: 'ชัยภูมิ',
            zipcode: 36150,
        },
        {
            subDistrict: 'หนองไผ่',
            district: 'แก้งคร้อ',
            province: 'ชัยภูมิ',
            zipcode: 36150,
        },
        {
            subDistrict: 'หลุบคา',
            district: 'แก้งคร้อ',
            province: 'ชัยภูมิ',
            zipcode: 36150,
        },
        {
            subDistrict: 'เก่าย่าดี',
            district: 'แก้งคร้อ',
            province: 'ชัยภูมิ',
            zipcode: 36150,
        },
        {
            subDistrict: 'โคกกุง',
            district: 'แก้งคร้อ',
            province: 'ชัยภูมิ',
            zipcode: 36150,
        },
        {
            subDistrict: 'ช่องไม้แก้ว',
            district: 'ทุ่งตะโก',
            province: 'ชุมพร',
            zipcode: 86220,
        },
        {
            subDistrict: 'ตะโก',
            district: 'ทุ่งตะโก',
            province: 'ชุมพร',
            zipcode: 86220,
        },
        {
            subDistrict: 'ทุ่งตะไคร',
            district: 'ทุ่งตะโก',
            province: 'ชุมพร',
            zipcode: 86220,
        },
        {
            subDistrict: 'ปากตะโก',
            district: 'ทุ่งตะโก',
            province: 'ชุมพร',
            zipcode: 86220,
        },
        {
            subDistrict: 'หมู่เกาะศรีอบยา',
            district: 'ทุ่งตะโก',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะขี้นก',
            district: 'ทุ่งตะโก',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะคราม',
            district: 'ทุ่งตะโก',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะคางสือ',
            district: 'ทุ่งตะโก',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะมะพร้าว',
            district: 'ทุ่งตะโก',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะมุก',
            district: 'ทุ่งตะโก',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะยอ',
            district: 'ทุ่งตะโก',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะรางบรรทัด',
            district: 'ทุ่งตะโก',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะสูบ',
            district: 'ทุ่งตะโก',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'คุริง',
            district: 'ท่าแซะ',
            province: 'ชุมพร',
            zipcode: 86140,
        },
        {
            subDistrict: 'ทรัพย์อนันต์',
            district: 'ท่าแซะ',
            province: 'ชุมพร',
            zipcode: 86140,
        },
        {
            subDistrict: 'ท่าข้าม',
            district: 'ท่าแซะ',
            province: 'ชุมพร',
            zipcode: 86140,
        },
        {
            subDistrict: 'ท่าแซะ',
            district: 'ท่าแซะ',
            province: 'ชุมพร',
            zipcode: 86140,
        },
        {
            subDistrict: 'นากระตาม',
            district: 'ท่าแซะ',
            province: 'ชุมพร',
            zipcode: 86140,
        },
        {
            subDistrict: 'รับร่อ',
            district: 'ท่าแซะ',
            province: 'ชุมพร',
            zipcode: 86190,
        },
        {
            subDistrict: 'สลุย',
            district: 'ท่าแซะ',
            province: 'ชุมพร',
            zipcode: 86140,
        },
        {
            subDistrict: 'สองพี่น้อง',
            district: 'ท่าแซะ',
            province: 'ชุมพร',
            zipcode: 86140,
        },
        {
            subDistrict: 'หงษ์เจริญ',
            district: 'ท่าแซะ',
            province: 'ชุมพร',
            zipcode: 86140,
        },
        {
            subDistrict: 'หินแก้ว',
            district: 'ท่าแซะ',
            province: 'ชุมพร',
            zipcode: 86190,
        },
        {
            subDistrict: 'ชุมโค',
            district: 'ปะทิว',
            province: 'ชุมพร',
            zipcode: 86160,
        },
        {
            subDistrict: 'ดอนยาง',
            district: 'ปะทิว',
            province: 'ชุมพร',
            zipcode: 86210,
        },
        {
            subDistrict: 'ทะเลทรัพย์',
            district: 'ปะทิว',
            province: 'ชุมพร',
            zipcode: 86160,
        },
        {
            subDistrict: 'บางสน',
            district: 'ปะทิว',
            province: 'ชุมพร',
            zipcode: 86160,
        },
        {
            subDistrict: 'ปากคลอง',
            district: 'ปะทิว',
            province: 'ชุมพร',
            zipcode: 86210,
        },
        {
            subDistrict: 'สะพลี',
            district: 'ปะทิว',
            province: 'ชุมพร',
            zipcode: 86230,
        },
        {
            subDistrict: 'เกาะซีกง',
            district: 'ปะทิว',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะพระ',
            district: 'ปะทิว',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะยอ',
            district: 'ปะทิว',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะรัง',
            district: 'ปะทิว',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะร้านเป็ด',
            district: 'ปะทิว',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะร้านไก่',
            district: 'ปะทิว',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะเอียง',
            district: 'ปะทิว',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะไข่',
            district: 'ปะทิว',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เขาไชยราช',
            district: 'ปะทิว',
            province: 'ชุมพร',
            zipcode: 86210,
        },
        {
            subDistrict: 'ปังหวาน',
            district: 'พะโต๊ะ',
            province: 'ชุมพร',
            zipcode: 86180,
        },
        {
            subDistrict: 'ปากทรง',
            district: 'พะโต๊ะ',
            province: 'ชุมพร',
            zipcode: 86180,
        },
        {
            subDistrict: 'พระรักษ์',
            district: 'พะโต๊ะ',
            province: 'ชุมพร',
            zipcode: 86180,
        },
        {
            subDistrict: 'พะโต๊ะ',
            district: 'พะโต๊ะ',
            province: 'ชุมพร',
            zipcode: 86180,
        },
        {
            subDistrict: 'ทุ่งคาวัด',
            district: 'ละแม',
            province: 'ชุมพร',
            zipcode: 86170,
        },
        {
            subDistrict: 'ทุ่งหลวง',
            district: 'ละแม',
            province: 'ชุมพร',
            zipcode: 86170,
        },
        {
            subDistrict: 'ละแม',
            district: 'ละแม',
            province: 'ชุมพร',
            zipcode: 86170,
        },
        {
            subDistrict: 'สวนแตง',
            district: 'ละแม',
            province: 'ชุมพร',
            zipcode: 86170,
        },
        {
            subDistrict: 'ครน',
            district: 'สวี',
            province: 'ชุมพร',
            zipcode: 86130,
        },
        {
            subDistrict: 'ด่านสวี',
            district: 'สวี',
            province: 'ชุมพร',
            zipcode: 86130,
        },
        {
            subDistrict: 'ทุ่งระยะ',
            district: 'สวี',
            province: 'ชุมพร',
            zipcode: 86130,
        },
        {
            subDistrict: 'ท่าหิน',
            district: 'สวี',
            province: 'ชุมพร',
            zipcode: 86130,
        },
        {
            subDistrict: 'นาสัก',
            district: 'สวี',
            province: 'ชุมพร',
            zipcode: 86130,
        },
        {
            subDistrict: 'นาโพธิ์',
            district: 'สวี',
            province: 'ชุมพร',
            zipcode: 86130,
        },
        {
            subDistrict: 'ปากแพรก',
            district: 'สวี',
            province: 'ชุมพร',
            zipcode: 86130,
        },
        {
            subDistrict: 'วิสัยใต้',
            district: 'สวี',
            province: 'ชุมพร',
            zipcode: 86130,
        },
        {
            subDistrict: 'สวี',
            district: 'สวี',
            province: 'ชุมพร',
            zipcode: 86130,
        },
        {
            subDistrict: 'เกาะกระ',
            district: 'สวี',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะกุลา',
            district: 'สวี',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะทองแก้ว',
            district: 'สวี',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะบาตร์',
            district: 'สวี',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะพัดหวายเล็ก',
            district: 'สวี',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะพัดหวายใหญ่',
            district: 'สวี',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะยูง',
            district: 'สวี',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะหนู',
            district: 'สวี',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะแมว',
            district: 'สวี',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะแรด',
            district: 'สวี',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เขาค่าย',
            district: 'สวี',
            province: 'ชุมพร',
            zipcode: 86130,
        },
        {
            subDistrict: 'เขาทะลุ',
            district: 'สวี',
            province: 'ชุมพร',
            zipcode: 86130,
        },
        {
            subDistrict: 'ขันเงิน',
            district: 'หลังสวน',
            province: 'ชุมพร',
            zipcode: 86110,
        },
        {
            subDistrict: 'ท่ามะพลา',
            district: 'หลังสวน',
            province: 'ชุมพร',
            zipcode: 86110,
        },
        {
            subDistrict: 'นาขา',
            district: 'หลังสวน',
            province: 'ชุมพร',
            zipcode: 86110,
        },
        {
            subDistrict: 'นาพญา',
            district: 'หลังสวน',
            province: 'ชุมพร',
            zipcode: 86110,
        },
        {
            subDistrict: 'บางน้ำจืด',
            district: 'หลังสวน',
            province: 'ชุมพร',
            zipcode: 86150,
        },
        {
            subDistrict: 'บางมะพร้าว',
            district: 'หลังสวน',
            province: 'ชุมพร',
            zipcode: 86110,
        },
        {
            subDistrict: 'บ้านควน',
            district: 'หลังสวน',
            province: 'ชุมพร',
            zipcode: 86110,
        },
        {
            subDistrict: 'ปากน้ำ',
            district: 'หลังสวน',
            province: 'ชุมพร',
            zipcode: 86150,
        },
        {
            subDistrict: 'พ้อแดง',
            district: 'หลังสวน',
            province: 'ชุมพร',
            zipcode: 86110,
        },
        {
            subDistrict: 'วังตะกอ',
            district: 'หลังสวน',
            province: 'ชุมพร',
            zipcode: 86110,
        },
        {
            subDistrict: 'หลังสวน',
            district: 'หลังสวน',
            province: 'ชุมพร',
            zipcode: 86110,
        },
        {
            subDistrict: 'หาดยาย',
            district: 'หลังสวน',
            province: 'ชุมพร',
            zipcode: 86110,
        },
        {
            subDistrict: 'เกาะพิทักษ์',
            district: 'หลังสวน',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'แหลมทราย',
            district: 'หลังสวน',
            province: 'ชุมพร',
            zipcode: 86110,
        },
        {
            subDistrict: 'ขุนกระทิง',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: 86190,
        },
        {
            subDistrict: 'ตากแดด',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: 86000,
        },
        {
            subDistrict: 'ถ้ำสิงห์',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: 86100,
        },
        {
            subDistrict: 'ทุ่งคา',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: 86100,
        },
        {
            subDistrict: 'ท่าตะเภา',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: 86000,
        },
        {
            subDistrict: 'ท่ายาง',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: 86000,
        },
        {
            subDistrict: 'นาชะอัง',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: 86000,
        },
        {
            subDistrict: 'นาทุ่ง',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: 86000,
        },
        {
            subDistrict: 'บางลึก',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: 86000,
        },
        {
            subDistrict: 'บางหมาก',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: 86000,
        },
        {
            subDistrict: 'บ้านนา',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: 86190,
        },
        {
            subDistrict: 'ปากน้ำ',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: 86120,
        },
        {
            subDistrict: 'วังใหม่',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: 86190,
        },
        {
            subDistrict: 'วังไผ่',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: 86000,
        },
        {
            subDistrict: 'วิสัยเหนือ',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: 86100,
        },
        {
            subDistrict: 'หาดทรายรี',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: 86120,
        },
        {
            subDistrict: 'หาดพันไกร',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: 86000,
        },
        {
            subDistrict: 'เกาะกา',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะกินหลักง้ำ',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะงามเล็ก',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะงามใหญ่',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะทองหลาง',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะทะลุ',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะมะพร้าว',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะมัตโพน',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะมาตรา',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะรังกาจิว',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะละวะ',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะสาก',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะหลักแรด',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะอีแรด',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'เกาะเสม็ด',
            district: 'เมืองชุมพร',
            province: 'ชุมพร',
            zipcode: '',
        },
        {
            subDistrict: 'กันตัง',
            district: 'กันตัง',
            province: 'ตรัง',
            zipcode: 92110,
        },
        {
            subDistrict: 'กันตังใต้',
            district: 'กันตัง',
            province: 'ตรัง',
            zipcode: 92110,
        },
        {
            subDistrict: 'คลองชีล้อม',
            district: 'กันตัง',
            province: 'ตรัง',
            zipcode: 92110,
        },
        {
            subDistrict: 'คลองลุ',
            district: 'กันตัง',
            province: 'ตรัง',
            zipcode: 92110,
        },
        {
            subDistrict: 'ควนธานี',
            district: 'กันตัง',
            province: 'ตรัง',
            zipcode: 92110,
        },
        {
            subDistrict: 'นาเกลือ',
            district: 'กันตัง',
            province: 'ตรัง',
            zipcode: 92110,
        },
        {
            subDistrict: 'บางสัก',
            district: 'กันตัง',
            province: 'ตรัง',
            zipcode: 92110,
        },
        {
            subDistrict: 'บางหมาก',
            district: 'กันตัง',
            province: 'ตรัง',
            zipcode: 92110,
        },
        {
            subDistrict: 'บางเป้า',
            district: 'กันตัง',
            province: 'ตรัง',
            zipcode: 92110,
        },
        {
            subDistrict: 'บ่อน้ำร้อน',
            district: 'กันตัง',
            province: 'ตรัง',
            zipcode: 92110,
        },
        {
            subDistrict: 'ย่านซื่อ',
            district: 'กันตัง',
            province: 'ตรัง',
            zipcode: 92110,
        },
        {
            subDistrict: 'วังวน',
            district: 'กันตัง',
            province: 'ตรัง',
            zipcode: 92110,
        },
        {
            subDistrict: 'เกาะลิบง',
            district: 'กันตัง',
            province: 'ตรัง',
            zipcode: 92110,
        },
        {
            subDistrict: 'โคกยาง',
            district: 'กันตัง',
            province: 'ตรัง',
            zipcode: 92110,
        },
        {
            subDistrict: 'ช่อง',
            district: 'นาโยง',
            province: 'ตรัง',
            zipcode: 92170,
        },
        {
            subDistrict: 'นาข้าวเสีย',
            district: 'นาโยง',
            province: 'ตรัง',
            zipcode: 92170,
        },
        {
            subDistrict: 'นาหมื่นศรี',
            district: 'นาโยง',
            province: 'ตรัง',
            zipcode: 92170,
        },
        {
            subDistrict: 'นาโยงเหนือ',
            district: 'นาโยง',
            province: 'ตรัง',
            zipcode: 92170,
        },
        {
            subDistrict: 'ละมอ',
            district: 'นาโยง',
            province: 'ตรัง',
            zipcode: 92170,
        },
        {
            subDistrict: 'โคกสะบ้า',
            district: 'นาโยง',
            province: 'ตรัง',
            zipcode: 92170,
        },
        {
            subDistrict: 'ทุ่งยาว',
            district: 'ปะเหลียน',
            province: 'ตรัง',
            zipcode: 92180,
        },
        {
            subDistrict: 'ท่าข้าม',
            district: 'ปะเหลียน',
            province: 'ตรัง',
            zipcode: 92120,
        },
        {
            subDistrict: 'ท่าพญา',
            district: 'ปะเหลียน',
            province: 'ตรัง',
            zipcode: 92140,
        },
        {
            subDistrict: 'บางด้วน',
            district: 'ปะเหลียน',
            province: 'ตรัง',
            zipcode: 92140,
        },
        {
            subDistrict: 'บ้านนา',
            district: 'ปะเหลียน',
            province: 'ตรัง',
            zipcode: 92140,
        },
        {
            subDistrict: 'ปะเหลียน',
            district: 'ปะเหลียน',
            province: 'ตรัง',
            zipcode: 92180,
        },
        {
            subDistrict: 'ลิพัง',
            district: 'ปะเหลียน',
            province: 'ตรัง',
            zipcode: 92180,
        },
        {
            subDistrict: 'สุโสะ',
            district: 'ปะเหลียน',
            province: 'ตรัง',
            zipcode: 92120,
        },
        {
            subDistrict: 'เกาะสุกร',
            district: 'ปะเหลียน',
            province: 'ตรัง',
            zipcode: 92120,
        },
        {
            subDistrict: 'แหลมสอม',
            district: 'ปะเหลียน',
            province: 'ตรัง',
            zipcode: 92180,
        },
        {
            subDistrict: 'ทุ่งกระบือ',
            district: 'ย่านตาขาว',
            province: 'ตรัง',
            zipcode: 92140,
        },
        {
            subDistrict: 'ทุ่งค่าย',
            district: 'ย่านตาขาว',
            province: 'ตรัง',
            zipcode: 92140,
        },
        {
            subDistrict: 'นาชุมเห็ด',
            district: 'ย่านตาขาว',
            province: 'ตรัง',
            zipcode: 92140,
        },
        {
            subDistrict: 'ย่านตาขาว',
            district: 'ย่านตาขาว',
            province: 'ตรัง',
            zipcode: 92140,
        },
        {
            subDistrict: 'หนองบ่อ',
            district: 'ย่านตาขาว',
            province: 'ตรัง',
            zipcode: 92140,
        },
        {
            subDistrict: 'เกาะเปียะ',
            district: 'ย่านตาขาว',
            province: 'ตรัง',
            zipcode: 92140,
        },
        {
            subDistrict: 'โพรงจระเข้',
            district: 'ย่านตาขาว',
            province: 'ตรัง',
            zipcode: 92140,
        },
        {
            subDistrict: 'ในควน',
            district: 'ย่านตาขาว',
            province: 'ตรัง',
            zipcode: 92140,
        },
        {
            subDistrict: 'คลองปาง',
            district: 'รัษฎา',
            province: 'ตรัง',
            zipcode: 92160,
        },
        {
            subDistrict: 'ควนเมา',
            district: 'รัษฎา',
            province: 'ตรัง',
            zipcode: 92160,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'รัษฎา',
            province: 'ตรัง',
            zipcode: 92160,
        },
        {
            subDistrict: 'หนองปรือ',
            district: 'รัษฎา',
            province: 'ตรัง',
            zipcode: 92130,
        },
        {
            subDistrict: 'เขาไพร',
            district: 'รัษฎา',
            province: 'ตรัง',
            zipcode: 92160,
        },
        {
            subDistrict: 'ท่าสะบ้า',
            district: 'วังวิเศษ',
            province: 'ตรัง',
            zipcode: 92000,
        },
        {
            subDistrict: 'วังมะปราง',
            district: 'วังวิเศษ',
            province: 'ตรัง',
            zipcode: 92220,
        },
        {
            subDistrict: 'วังมะปรางเหนือ',
            district: 'วังวิเศษ',
            province: 'ตรัง',
            zipcode: 92220,
        },
        {
            subDistrict: 'อ่าวตง',
            district: 'วังวิเศษ',
            province: 'ตรัง',
            zipcode: 92220,
        },
        {
            subDistrict: 'เขาวิเศษ',
            district: 'วังวิเศษ',
            province: 'ตรัง',
            zipcode: 92220,
        },
        {
            subDistrict: 'กะลาเส',
            district: 'สิเกา',
            province: 'ตรัง',
            zipcode: 92150,
        },
        {
            subDistrict: 'นาเมืองเพชร',
            district: 'สิเกา',
            province: 'ตรัง',
            zipcode: 92000,
        },
        {
            subDistrict: 'บ่อหิน',
            district: 'สิเกา',
            province: 'ตรัง',
            zipcode: 92150,
        },
        {
            subDistrict: 'เขาไม้แก้ว',
            district: 'สิเกา',
            province: 'ตรัง',
            zipcode: 92150,
        },
        {
            subDistrict: 'ไม้ฝาด',
            district: 'สิเกา',
            province: 'ตรัง',
            zipcode: 92150,
        },
        {
            subDistrict: 'ตะเสะ',
            district: 'หาดสำราญ',
            province: 'ตรัง',
            zipcode: 92120,
        },
        {
            subDistrict: 'บ้าหวี',
            district: 'หาดสำราญ',
            province: 'ตรัง',
            zipcode: 92120,
        },
        {
            subDistrict: 'หาดสำราญ',
            district: 'หาดสำราญ',
            province: 'ตรัง',
            zipcode: 92120,
        },
        {
            subDistrict: 'ทุ่งต่อ',
            district: 'ห้วยยอด',
            province: 'ตรัง',
            zipcode: 92130,
        },
        {
            subDistrict: 'ท่างิ้ว',
            district: 'ห้วยยอด',
            province: 'ตรัง',
            zipcode: 92130,
        },
        {
            subDistrict: 'นาวง',
            district: 'ห้วยยอด',
            province: 'ตรัง',
            zipcode: 92210,
        },
        {
            subDistrict: 'บางกุ้ง',
            district: 'ห้วยยอด',
            province: 'ตรัง',
            zipcode: 92210,
        },
        {
            subDistrict: 'บางดี',
            district: 'ห้วยยอด',
            province: 'ตรัง',
            zipcode: 92210,
        },
        {
            subDistrict: 'ปากคม',
            district: 'ห้วยยอด',
            province: 'ตรัง',
            zipcode: 92130,
        },
        {
            subDistrict: 'ปากแจ่ม',
            district: 'ห้วยยอด',
            province: 'ตรัง',
            zipcode: 92190,
        },
        {
            subDistrict: 'ลำภูรา',
            district: 'ห้วยยอด',
            province: 'ตรัง',
            zipcode: 92190,
        },
        {
            subDistrict: 'วังคีรี',
            district: 'ห้วยยอด',
            province: 'ตรัง',
            zipcode: 92210,
        },
        {
            subDistrict: 'หนองช้างแล่น',
            district: 'ห้วยยอด',
            province: 'ตรัง',
            zipcode: 92130,
        },
        {
            subDistrict: 'ห้วยนาง',
            district: 'ห้วยยอด',
            province: 'ตรัง',
            zipcode: 92130,
        },
        {
            subDistrict: 'ห้วยยอด',
            district: 'ห้วยยอด',
            province: 'ตรัง',
            zipcode: 92130,
        },
        {
            subDistrict: 'เขากอบ',
            district: 'ห้วยยอด',
            province: 'ตรัง',
            zipcode: 92130,
        },
        {
            subDistrict: 'เขาขาว',
            district: 'ห้วยยอด',
            province: 'ตรัง',
            zipcode: 92130,
        },
        {
            subDistrict: 'เขาปูน',
            district: 'ห้วยยอด',
            province: 'ตรัง',
            zipcode: 92130,
        },
        {
            subDistrict: 'ในเตา',
            district: 'ห้วยยอด',
            province: 'ตรัง',
            zipcode: 92130,
        },
        {
            subDistrict: 'ควนปริง',
            district: 'เมืองตรัง',
            province: 'ตรัง',
            zipcode: 92000,
        },
        {
            subDistrict: 'ทับเที่ยง',
            district: 'เมืองตรัง',
            province: 'ตรัง',
            zipcode: 92000,
        },
        {
            subDistrict: 'นาตาล่วง',
            district: 'เมืองตรัง',
            province: 'ตรัง',
            zipcode: 92000,
        },
        {
            subDistrict: 'นาท่ามเหนือ',
            district: 'เมืองตรัง',
            province: 'ตรัง',
            zipcode: 92190,
        },
        {
            subDistrict: 'นาท่ามใต้',
            district: 'เมืองตรัง',
            province: 'ตรัง',
            zipcode: 92190,
        },
        {
            subDistrict: 'นาบินหลา',
            district: 'เมืองตรัง',
            province: 'ตรัง',
            zipcode: 92170,
        },
        {
            subDistrict: 'นาพละ',
            district: 'เมืองตรัง',
            province: 'ตรัง',
            zipcode: 92000,
        },
        {
            subDistrict: 'นาโต๊ะหมิง',
            district: 'เมืองตรัง',
            province: 'ตรัง',
            zipcode: 92000,
        },
        {
            subDistrict: 'นาโยงใต้',
            district: 'เมืองตรัง',
            province: 'ตรัง',
            zipcode: 92170,
        },
        {
            subDistrict: 'น้ำผุด',
            district: 'เมืองตรัง',
            province: 'ตรัง',
            zipcode: 92000,
        },
        {
            subDistrict: 'บางรัก',
            district: 'เมืองตรัง',
            province: 'ตรัง',
            zipcode: 92000,
        },
        {
            subDistrict: 'บ้านควน',
            district: 'เมืองตรัง',
            province: 'ตรัง',
            zipcode: 92000,
        },
        {
            subDistrict: 'บ้านโพธิ์',
            district: 'เมืองตรัง',
            province: 'ตรัง',
            zipcode: 92000,
        },
        {
            subDistrict: 'หนองตรุด',
            district: 'เมืองตรัง',
            province: 'ตรัง',
            zipcode: 92000,
        },
        {
            subDistrict: 'โคกหล่อ',
            district: 'เมืองตรัง',
            province: 'ตรัง',
            zipcode: 92000,
        },
        {
            subDistrict: 'คลองใหญ่',
            district: 'คลองใหญ่',
            province: 'ตราด',
            zipcode: 23110,
        },
        {
            subDistrict: 'หาดเล็ก',
            district: 'คลองใหญ่',
            province: 'ตราด',
            zipcode: 23110,
        },
        {
            subDistrict: 'ไม้รูด',
            district: 'คลองใหญ่',
            province: 'ตราด',
            zipcode: 23110,
        },
        {
            subDistrict: 'ช้างทูน',
            district: 'บ่อไร่',
            province: 'ตราด',
            zipcode: 23140,
        },
        {
            subDistrict: 'ด่านชุมพล',
            district: 'บ่อไร่',
            province: 'ตราด',
            zipcode: 23140,
        },
        {
            subDistrict: 'นนทรีย์',
            district: 'บ่อไร่',
            province: 'ตราด',
            zipcode: 23140,
        },
        {
            subDistrict: 'บ่อพลอย',
            district: 'บ่อไร่',
            province: 'ตราด',
            zipcode: 23140,
        },
        {
            subDistrict: 'หนองบอน',
            district: 'บ่อไร่',
            province: 'ตราด',
            zipcode: 23140,
        },
        {
            subDistrict: 'เกาะกูด',
            district: 'เกาะกูด',
            province: 'ตราด',
            zipcode: 23000,
        },
        {
            subDistrict: 'เกาะหมาก',
            district: 'เกาะกูด',
            province: 'ตราด',
            zipcode: 23000,
        },
        {
            subDistrict: 'เกาะช้าง',
            district: 'เกาะช้าง',
            province: 'ตราด',
            zipcode: 23170,
        },
        {
            subDistrict: 'เกาะช้างใต้',
            district: 'เกาะช้าง',
            province: 'ตราด',
            zipcode: 23170,
        },
        {
            subDistrict: 'ทุ่งนนทรี',
            district: 'เขาสมิง',
            province: 'ตราด',
            zipcode: 23130,
        },
        {
            subDistrict: 'ท่าโสม',
            district: 'เขาสมิง',
            province: 'ตราด',
            zipcode: 23150,
        },
        {
            subDistrict: 'ประณีต',
            district: 'เขาสมิง',
            province: 'ตราด',
            zipcode: 23150,
        },
        {
            subDistrict: 'วังตะเคียน',
            district: 'เขาสมิง',
            province: 'ตราด',
            zipcode: 23130,
        },
        {
            subDistrict: 'สะตอ',
            district: 'เขาสมิง',
            province: 'ตราด',
            zipcode: 23150,
        },
        {
            subDistrict: 'เขาสมิง',
            district: 'เขาสมิง',
            province: 'ตราด',
            zipcode: 23130,
        },
        {
            subDistrict: 'เทพนิมิต',
            district: 'เขาสมิง',
            province: 'ตราด',
            zipcode: 23150,
        },
        {
            subDistrict: 'แสนตุ้ง',
            district: 'เขาสมิง',
            province: 'ตราด',
            zipcode: 23150,
        },
        {
            subDistrict: 'ชำราก',
            district: 'เมืองตราด',
            province: 'ตราด',
            zipcode: 23000,
        },
        {
            subDistrict: 'ตะกาง',
            district: 'เมืองตราด',
            province: 'ตราด',
            zipcode: 23000,
        },
        {
            subDistrict: 'ท่ากุ่ม',
            district: 'เมืองตราด',
            province: 'ตราด',
            zipcode: 23000,
        },
        {
            subDistrict: 'ท่าพริก',
            district: 'เมืองตราด',
            province: 'ตราด',
            zipcode: 23000,
        },
        {
            subDistrict: 'บางพระ',
            district: 'เมืองตราด',
            province: 'ตราด',
            zipcode: 23000,
        },
        {
            subDistrict: 'วังกระแจะ',
            district: 'เมืองตราด',
            province: 'ตราด',
            zipcode: 23000,
        },
        {
            subDistrict: 'หนองคันทรง',
            district: 'เมืองตราด',
            province: 'ตราด',
            zipcode: 23000,
        },
        {
            subDistrict: 'หนองเสม็ด',
            district: 'เมืองตราด',
            province: 'ตราด',
            zipcode: 23000,
        },
        {
            subDistrict: 'หนองโสน',
            district: 'เมืองตราด',
            province: 'ตราด',
            zipcode: 23000,
        },
        {
            subDistrict: 'ห้วงน้ำขาว',
            district: 'เมืองตราด',
            province: 'ตราด',
            zipcode: 23000,
        },
        {
            subDistrict: 'ห้วยแร้ง',
            district: 'เมืองตราด',
            province: 'ตราด',
            zipcode: 23000,
        },
        {
            subDistrict: 'อ่าวใหญ่',
            district: 'เมืองตราด',
            province: 'ตราด',
            zipcode: 23000,
        },
        {
            subDistrict: 'เนินทราย',
            district: 'เมืองตราด',
            province: 'ตราด',
            zipcode: 23000,
        },
        {
            subDistrict: 'แหลมกลัด',
            district: 'เมืองตราด',
            province: 'ตราด',
            zipcode: 23000,
        },
        {
            subDistrict: 'คลองใหญ่',
            district: 'แหลมงอบ',
            province: 'ตราด',
            zipcode: 23120,
        },
        {
            subDistrict: 'น้ำเชี่ยว',
            district: 'แหลมงอบ',
            province: 'ตราด',
            zipcode: 23120,
        },
        {
            subDistrict: 'บางปิด',
            district: 'แหลมงอบ',
            province: 'ตราด',
            zipcode: 23120,
        },
        {
            subDistrict: 'แหลมงอบ',
            district: 'แหลมงอบ',
            province: 'ตราด',
            zipcode: 23120,
        },
        {
            subDistrict: 'ท่าสองยาง',
            district: 'ท่าสองยาง',
            province: 'ตาก',
            zipcode: 63150,
        },
        {
            subDistrict: 'แม่ต้าน',
            district: 'ท่าสองยาง',
            province: 'ตาก',
            zipcode: 63150,
        },
        {
            subDistrict: 'แม่วะหลวง',
            district: 'ท่าสองยาง',
            province: 'ตาก',
            zipcode: 63150,
        },
        {
            subDistrict: 'แม่สอง',
            district: 'ท่าสองยาง',
            province: 'ตาก',
            zipcode: 63150,
        },
        {
            subDistrict: 'แม่หละ',
            district: 'ท่าสองยาง',
            province: 'ตาก',
            zipcode: 63150,
        },
        {
            subDistrict: 'แม่อุสุ',
            district: 'ท่าสองยาง',
            province: 'ตาก',
            zipcode: 63150,
        },
        {
            subDistrict: 'ตากตก',
            district: 'บ้านตาก',
            province: 'ตาก',
            zipcode: 63120,
        },
        {
            subDistrict: 'ตากออก',
            district: 'บ้านตาก',
            province: 'ตาก',
            zipcode: 63120,
        },
        {
            subDistrict: 'ทุ่งกระเชาะ',
            district: 'บ้านตาก',
            province: 'ตาก',
            zipcode: 63120,
        },
        {
            subDistrict: 'ท้องฟ้า',
            district: 'บ้านตาก',
            province: 'ตาก',
            zipcode: 63120,
        },
        {
            subDistrict: 'สมอโคน',
            district: 'บ้านตาก',
            province: 'ตาก',
            zipcode: 63120,
        },
        {
            subDistrict: 'เกาะตะเภา',
            district: 'บ้านตาก',
            province: 'ตาก',
            zipcode: 63120,
        },
        {
            subDistrict: 'แม่สลิด',
            district: 'บ้านตาก',
            province: 'ตาก',
            zipcode: 63120,
        },
        {
            subDistrict: 'คีรีราษฎร์',
            district: 'พบพระ',
            province: 'ตาก',
            zipcode: 63160,
        },
        {
            subDistrict: 'ช่องแคบ',
            district: 'พบพระ',
            province: 'ตาก',
            zipcode: 63160,
        },
        {
            subDistrict: 'พบพระ',
            district: 'พบพระ',
            province: 'ตาก',
            zipcode: 63160,
        },
        {
            subDistrict: 'รวมไทยพัฒนา',
            district: 'พบพระ',
            province: 'ตาก',
            zipcode: 63160,
        },
        {
            subDistrict: 'วาเล่ย์',
            district: 'พบพระ',
            province: 'ตาก',
            zipcode: 63160,
        },
        {
            subDistrict: 'นาโบสถ์',
            district: 'วังเจ้า',
            province: 'ตาก',
            zipcode: 63180,
        },
        {
            subDistrict: 'ประดาง',
            district: 'วังเจ้า',
            province: 'ตาก',
            zipcode: 63180,
        },
        {
            subDistrict: 'เชียงทอง',
            district: 'วังเจ้า',
            province: 'ตาก',
            zipcode: 63180,
        },
        {
            subDistrict: 'บ้านนา',
            district: 'สามเงา',
            province: 'ตาก',
            zipcode: 63130,
        },
        {
            subDistrict: 'ยกกระบัตร',
            district: 'สามเงา',
            province: 'ตาก',
            zipcode: 63130,
        },
        {
            subDistrict: 'ย่านรี',
            district: 'สามเงา',
            province: 'ตาก',
            zipcode: 63130,
        },
        {
            subDistrict: 'วังจันทร์',
            district: 'สามเงา',
            province: 'ตาก',
            zipcode: 63130,
        },
        {
            subDistrict: 'วังหมัน',
            district: 'สามเงา',
            province: 'ตาก',
            zipcode: 63130,
        },
        {
            subDistrict: 'สามเงา',
            district: 'สามเงา',
            province: 'ตาก',
            zipcode: 63130,
        },
        {
            subDistrict: 'หนองหลวง',
            district: 'อุ้มผาง',
            province: 'ตาก',
            zipcode: 63170,
        },
        {
            subDistrict: 'อุ้มผาง',
            district: 'อุ้มผาง',
            province: 'ตาก',
            zipcode: 63170,
        },
        {
            subDistrict: 'แม่กลอง',
            district: 'อุ้มผาง',
            province: 'ตาก',
            zipcode: 63170,
        },
        {
            subDistrict: 'แม่จัน',
            district: 'อุ้มผาง',
            province: 'ตาก',
            zipcode: 63170,
        },
        {
            subDistrict: 'แม่ละมุ้ง',
            district: 'อุ้มผาง',
            province: 'ตาก',
            zipcode: 63170,
        },
        {
            subDistrict: 'โมโกร',
            district: 'อุ้มผาง',
            province: 'ตาก',
            zipcode: 63170,
        },
        {
            subDistrict: 'ตลุกกลางทุ่ง',
            district: 'เมืองตาก',
            province: 'ตาก',
            zipcode: 63000,
        },
        {
            subDistrict: 'น้ำรึม',
            district: 'เมืองตาก',
            province: 'ตาก',
            zipcode: 63000,
        },
        {
            subDistrict: 'ป่ามะม่วง',
            district: 'เมืองตาก',
            province: 'ตาก',
            zipcode: 63000,
        },
        {
            subDistrict: 'ระแหง',
            district: 'เมืองตาก',
            province: 'ตาก',
            zipcode: 63000,
        },
        {
            subDistrict: 'วังประจบ',
            district: 'เมืองตาก',
            province: 'ตาก',
            zipcode: 63000,
        },
        {
            subDistrict: 'วังหิน',
            district: 'เมืองตาก',
            province: 'ตาก',
            zipcode: 63000,
        },
        {
            subDistrict: 'หนองบัวเหนือ',
            district: 'เมืองตาก',
            province: 'ตาก',
            zipcode: 63000,
        },
        {
            subDistrict: 'หนองบัวใต้',
            district: 'เมืองตาก',
            province: 'ตาก',
            zipcode: 63000,
        },
        {
            subDistrict: 'หนองหลวง',
            district: 'เมืองตาก',
            province: 'ตาก',
            zipcode: 63000,
        },
        {
            subDistrict: 'หัวเดียด',
            district: 'เมืองตาก',
            province: 'ตาก',
            zipcode: 63000,
        },
        {
            subDistrict: 'เชียงเงิน',
            district: 'เมืองตาก',
            province: 'ตาก',
            zipcode: 63000,
        },
        {
            subDistrict: 'แม่ท้อ',
            district: 'เมืองตาก',
            province: 'ตาก',
            zipcode: 63000,
        },
        {
            subDistrict: 'โป่งแดง',
            district: 'เมืองตาก',
            province: 'ตาก',
            zipcode: 63000,
        },
        {
            subDistrict: 'ไม้งาม',
            district: 'เมืองตาก',
            province: 'ตาก',
            zipcode: 63000,
        },
        {
            subDistrict: 'ขะเนจื้อ',
            district: 'แม่ระมาด',
            province: 'ตาก',
            zipcode: 63140,
        },
        {
            subDistrict: 'พระธาตุ',
            district: 'แม่ระมาด',
            province: 'ตาก',
            zipcode: 63140,
        },
        {
            subDistrict: 'สามหมื่น',
            district: 'แม่ระมาด',
            province: 'ตาก',
            zipcode: 63140,
        },
        {
            subDistrict: 'แม่จะเรา',
            district: 'แม่ระมาด',
            province: 'ตาก',
            zipcode: 63140,
        },
        {
            subDistrict: 'แม่ตื่น',
            district: 'แม่ระมาด',
            province: 'ตาก',
            zipcode: 63140,
        },
        {
            subDistrict: 'แม่ระมาด',
            district: 'แม่ระมาด',
            province: 'ตาก',
            zipcode: 63140,
        },
        {
            subDistrict: 'ด่านแม่ละเมา',
            district: 'แม่สอด',
            province: 'ตาก',
            zipcode: 63110,
        },
        {
            subDistrict: 'ท่าสายลวด',
            district: 'แม่สอด',
            province: 'ตาก',
            zipcode: 63110,
        },
        {
            subDistrict: 'พระธาตุผาแดง',
            district: 'แม่สอด',
            province: 'ตาก',
            zipcode: 63110,
        },
        {
            subDistrict: 'พะวอ',
            district: 'แม่สอด',
            province: 'ตาก',
            zipcode: 63110,
        },
        {
            subDistrict: 'มหาวัน',
            district: 'แม่สอด',
            province: 'ตาก',
            zipcode: 63110,
        },
        {
            subDistrict: 'แม่กาษา',
            district: 'แม่สอด',
            province: 'ตาก',
            zipcode: 63110,
        },
        {
            subDistrict: 'แม่กุ',
            district: 'แม่สอด',
            province: 'ตาก',
            zipcode: 63110,
        },
        {
            subDistrict: 'แม่ตาว',
            district: 'แม่สอด',
            province: 'ตาก',
            zipcode: 63110,
        },
        {
            subDistrict: 'แม่ปะ',
            district: 'แม่สอด',
            province: 'ตาก',
            zipcode: 63110,
        },
        {
            subDistrict: 'แม่สอด',
            district: 'แม่สอด',
            province: 'ตาก',
            zipcode: 63110,
        },
        {
            subDistrict: 'ทองหลาง',
            district: 'บ้านนา',
            province: 'นครนายก',
            zipcode: 26110,
        },
        {
            subDistrict: 'บางอ้อ',
            district: 'บ้านนา',
            province: 'นครนายก',
            zipcode: 26110,
        },
        {
            subDistrict: 'บ้านนา',
            district: 'บ้านนา',
            province: 'นครนายก',
            zipcode: 26110,
        },
        {
            subDistrict: 'บ้านพริก',
            district: 'บ้านนา',
            province: 'นครนายก',
            zipcode: 26110,
        },
        {
            subDistrict: 'บ้านพร้าว',
            district: 'บ้านนา',
            province: 'นครนายก',
            zipcode: 26110,
        },
        {
            subDistrict: 'ป่าขะ',
            district: 'บ้านนา',
            province: 'นครนายก',
            zipcode: 26110,
        },
        {
            subDistrict: 'พิกุลออก',
            district: 'บ้านนา',
            province: 'นครนายก',
            zipcode: 26110,
        },
        {
            subDistrict: 'ศรีกะอาง',
            district: 'บ้านนา',
            province: 'นครนายก',
            zipcode: 26110,
        },
        {
            subDistrict: 'อาษา',
            district: 'บ้านนา',
            province: 'นครนายก',
            zipcode: 26110,
        },
        {
            subDistrict: 'เขาเพิ่ม',
            district: 'บ้านนา',
            province: 'นครนายก',
            zipcode: 26110,
        },
        {
            subDistrict: 'ท่าเรือ',
            district: 'ปากพลี',
            province: 'นครนายก',
            zipcode: 26130,
        },
        {
            subDistrict: 'นาหินลาด',
            district: 'ปากพลี',
            province: 'นครนายก',
            zipcode: 26130,
        },
        {
            subDistrict: 'ปากพลี',
            district: 'ปากพลี',
            province: 'นครนายก',
            zipcode: 26130,
        },
        {
            subDistrict: 'หนองแสง',
            district: 'ปากพลี',
            province: 'นครนายก',
            zipcode: 26130,
        },
        {
            subDistrict: 'เกาะหวาย',
            district: 'ปากพลี',
            province: 'นครนายก',
            zipcode: 26130,
        },
        {
            subDistrict: 'เกาะโพธิ์',
            district: 'ปากพลี',
            province: 'นครนายก',
            zipcode: 26130,
        },
        {
            subDistrict: 'โคกกรวด',
            district: 'ปากพลี',
            province: 'นครนายก',
            zipcode: 26130,
        },
        {
            subDistrict: 'คลองใหญ่',
            district: 'องครักษ์',
            province: 'นครนายก',
            zipcode: 26120,
        },
        {
            subDistrict: 'ชุมพล',
            district: 'องครักษ์',
            province: 'นครนายก',
            zipcode: 26120,
        },
        {
            subDistrict: 'ทรายมูล',
            district: 'องครักษ์',
            province: 'นครนายก',
            zipcode: 26120,
        },
        {
            subDistrict: 'บางปลากด',
            district: 'องครักษ์',
            province: 'นครนายก',
            zipcode: 26120,
        },
        {
            subDistrict: 'บางลูกเสือ',
            district: 'องครักษ์',
            province: 'นครนายก',
            zipcode: 26120,
        },
        {
            subDistrict: 'บางสมบูรณ์',
            district: 'องครักษ์',
            province: 'นครนายก',
            zipcode: 26120,
        },
        {
            subDistrict: 'บึงศาล',
            district: 'องครักษ์',
            province: 'นครนายก',
            zipcode: 26120,
        },
        {
            subDistrict: 'พระอาจารย์',
            district: 'องครักษ์',
            province: 'นครนายก',
            zipcode: 26120,
        },
        {
            subDistrict: 'ศีรษะกระบือ',
            district: 'องครักษ์',
            province: 'นครนายก',
            zipcode: 26120,
        },
        {
            subDistrict: 'องครักษ์',
            district: 'องครักษ์',
            province: 'นครนายก',
            zipcode: 26120,
        },
        {
            subDistrict: 'โพธิ์แทน',
            district: 'องครักษ์',
            province: 'นครนายก',
            zipcode: 26120,
        },
        {
            subDistrict: 'ดงละคร',
            district: 'เมืองนครนายก',
            province: 'นครนายก',
            zipcode: 26000,
        },
        {
            subDistrict: 'ดอนยอ',
            district: 'เมืองนครนายก',
            province: 'นครนายก',
            zipcode: 26000,
        },
        {
            subDistrict: 'ท่าช้าง',
            district: 'เมืองนครนายก',
            province: 'นครนายก',
            zipcode: 26000,
        },
        {
            subDistrict: 'ท่าทราย',
            district: 'เมืองนครนายก',
            province: 'นครนายก',
            zipcode: 26000,
        },
        {
            subDistrict: 'นครนายก',
            district: 'เมืองนครนายก',
            province: 'นครนายก',
            zipcode: 26000,
        },
        {
            subDistrict: 'บ้านใหญ่',
            district: 'เมืองนครนายก',
            province: 'นครนายก',
            zipcode: 26000,
        },
        {
            subDistrict: 'พรหมณี',
            district: 'เมืองนครนายก',
            province: 'นครนายก',
            zipcode: 26000,
        },
        {
            subDistrict: 'วังกระโจม',
            district: 'เมืองนครนายก',
            province: 'นครนายก',
            zipcode: 26000,
        },
        {
            subDistrict: 'ศรีจุฬา',
            district: 'เมืองนครนายก',
            province: 'นครนายก',
            zipcode: 26000,
        },
        {
            subDistrict: 'ศรีนาวา',
            district: 'เมืองนครนายก',
            province: 'นครนายก',
            zipcode: 26000,
        },
        {
            subDistrict: 'สาริกา',
            district: 'เมืองนครนายก',
            province: 'นครนายก',
            zipcode: 26000,
        },
        {
            subDistrict: 'หินตั้ง',
            district: 'เมืองนครนายก',
            province: 'นครนายก',
            zipcode: 26000,
        },
        {
            subDistrict: 'เขาพระ',
            district: 'เมืองนครนายก',
            province: 'นครนายก',
            zipcode: 26000,
        },
        {
            subDistrict: 'กระตีบ',
            district: 'กำแพงแสน',
            province: 'นครปฐม',
            zipcode: 73180,
        },
        {
            subDistrict: 'กำแพงแสน',
            district: 'กำแพงแสน',
            province: 'นครปฐม',
            zipcode: 73140,
        },
        {
            subDistrict: 'ดอนข่อย',
            district: 'กำแพงแสน',
            province: 'นครปฐม',
            zipcode: 73140,
        },
        {
            subDistrict: 'ทุ่งกระพังโหม',
            district: 'กำแพงแสน',
            province: 'นครปฐม',
            zipcode: 73140,
        },
        {
            subDistrict: 'ทุ่งขวาง',
            district: 'กำแพงแสน',
            province: 'นครปฐม',
            zipcode: 73140,
        },
        {
            subDistrict: 'ทุ่งบัว',
            district: 'กำแพงแสน',
            province: 'นครปฐม',
            zipcode: 73140,
        },
        {
            subDistrict: 'ทุ่งลูกนก',
            district: 'กำแพงแสน',
            province: 'นครปฐม',
            zipcode: 73140,
        },
        {
            subDistrict: 'รางพิกุล',
            district: 'กำแพงแสน',
            province: 'นครปฐม',
            zipcode: 73140,
        },
        {
            subDistrict: 'วังน้ำเขียว',
            district: 'กำแพงแสน',
            province: 'นครปฐม',
            zipcode: 73140,
        },
        {
            subDistrict: 'สระพัฒนา',
            district: 'กำแพงแสน',
            province: 'นครปฐม',
            zipcode: 73180,
        },
        {
            subDistrict: 'สระสี่มุม',
            district: 'กำแพงแสน',
            province: 'นครปฐม',
            zipcode: 73140,
        },
        {
            subDistrict: 'หนองกระทุ่ม',
            district: 'กำแพงแสน',
            province: 'นครปฐม',
            zipcode: 73140,
        },
        {
            subDistrict: 'ห้วยขวาง',
            district: 'กำแพงแสน',
            province: 'นครปฐม',
            zipcode: 73140,
        },
        {
            subDistrict: 'ห้วยม่วง',
            district: 'กำแพงแสน',
            province: 'นครปฐม',
            zipcode: 73180,
        },
        {
            subDistrict: 'ห้วยหมอนทอง',
            district: 'กำแพงแสน',
            province: 'นครปฐม',
            zipcode: 73140,
        },
        {
            subDistrict: 'ดอนพุทรา',
            district: 'ดอนตูม',
            province: 'นครปฐม',
            zipcode: 73150,
        },
        {
            subDistrict: 'ดอนรวก',
            district: 'ดอนตูม',
            province: 'นครปฐม',
            zipcode: 73150,
        },
        {
            subDistrict: 'บ้านหลวง',
            district: 'ดอนตูม',
            province: 'นครปฐม',
            zipcode: 73150,
        },
        {
            subDistrict: 'ลำลูกบัว',
            district: 'ดอนตูม',
            province: 'นครปฐม',
            zipcode: 73150,
        },
        {
            subDistrict: 'ลำเหย',
            district: 'ดอนตูม',
            province: 'นครปฐม',
            zipcode: 73150,
        },
        {
            subDistrict: 'สามง่าม',
            district: 'ดอนตูม',
            province: 'นครปฐม',
            zipcode: 73150,
        },
        {
            subDistrict: 'ห้วยด้วน',
            district: 'ดอนตูม',
            province: 'นครปฐม',
            zipcode: 73150,
        },
        {
            subDistrict: 'ห้วยพระ',
            district: 'ดอนตูม',
            province: 'นครปฐม',
            zipcode: 73150,
        },
        {
            subDistrict: 'ขุนแก้ว',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'งิ้วราย',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'ดอนแฝก',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'ท่ากระชับ',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'ท่าตำหนัก',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'ท่าพระยา',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'นครชัยศรี',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'บางกระเบา',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'บางพระ',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'บางระกำ',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'บางแก้ว',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'บางแก้วฟ้า',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'พะเนียด',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'ลานตากฟ้า',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'วัดละมุด',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'วัดสำโรง',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'วัดแค',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'ศรีมหาโพธิ์',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'ศรีษะทอง',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'สัมปทวน',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'ห้วยพลู',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'แหลมบัว',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'โคกพระเจดีย์',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'ไทยาวาส',
            district: 'นครชัยศรี',
            province: 'นครปฐม',
            zipcode: 73120,
        },
        {
            subDistrict: 'คลองนกกระทุง',
            district: 'บางเลน',
            province: 'นครปฐม',
            zipcode: 73130,
        },
        {
            subDistrict: 'ดอนตูม',
            district: 'บางเลน',
            province: 'นครปฐม',
            zipcode: 73130,
        },
        {
            subDistrict: 'นราภิรมย์',
            district: 'บางเลน',
            province: 'นครปฐม',
            zipcode: 73130,
        },
        {
            subDistrict: 'นิลเพชร',
            district: 'บางเลน',
            province: 'นครปฐม',
            zipcode: 73130,
        },
        {
            subDistrict: 'บัวปากท่า',
            district: 'บางเลน',
            province: 'นครปฐม',
            zipcode: 73130,
        },
        {
            subDistrict: 'บางปลา',
            district: 'บางเลน',
            province: 'นครปฐม',
            zipcode: 73130,
        },
        {
            subDistrict: 'บางภาษี',
            district: 'บางเลน',
            province: 'นครปฐม',
            zipcode: 73130,
        },
        {
            subDistrict: 'บางระกำ',
            district: 'บางเลน',
            province: 'นครปฐม',
            zipcode: 73130,
        },
        {
            subDistrict: 'บางหลวง',
            district: 'บางเลน',
            province: 'นครปฐม',
            zipcode: 73190,
        },
        {
            subDistrict: 'บางเลน',
            district: 'บางเลน',
            province: 'นครปฐม',
            zipcode: 73130,
        },
        {
            subDistrict: 'บางไทรป่า',
            district: 'บางเลน',
            province: 'นครปฐม',
            zipcode: 73130,
        },
        {
            subDistrict: 'ลำพญา',
            district: 'บางเลน',
            province: 'นครปฐม',
            zipcode: 73130,
        },
        {
            subDistrict: 'หินมูล',
            district: 'บางเลน',
            province: 'นครปฐม',
            zipcode: 73190,
        },
        {
            subDistrict: 'ไทรงาม',
            district: 'บางเลน',
            province: 'นครปฐม',
            zipcode: 73130,
        },
        {
            subDistrict: 'ไผ่หูช้าง',
            district: 'บางเลน',
            province: 'นครปฐม',
            zipcode: 73130,
        },
        {
            subDistrict: 'คลองโยง',
            district: 'พุทธมณฑล',
            province: 'นครปฐม',
            zipcode: 73170,
        },
        {
            subDistrict: 'มหาสวัสดิ์',
            district: 'พุทธมณฑล',
            province: 'นครปฐม',
            zipcode: 73170,
        },
        {
            subDistrict: 'ศาลายา',
            district: 'พุทธมณฑล',
            province: 'นครปฐม',
            zipcode: 73170,
        },
        {
            subDistrict: 'กระทุ่มล้ม',
            district: 'สามพราน',
            province: 'นครปฐม',
            zipcode: 73220,
        },
        {
            subDistrict: 'คลองจินดา',
            district: 'สามพราน',
            province: 'นครปฐม',
            zipcode: 73110,
        },
        {
            subDistrict: 'คลองใหม่',
            district: 'สามพราน',
            province: 'นครปฐม',
            zipcode: 73110,
        },
        {
            subDistrict: 'ตลาดจินดา',
            district: 'สามพราน',
            province: 'นครปฐม',
            zipcode: 73110,
        },
        {
            subDistrict: 'ทรงคนอง',
            district: 'สามพราน',
            province: 'นครปฐม',
            zipcode: 73210,
        },
        {
            subDistrict: 'ท่าข้าม',
            district: 'สามพราน',
            province: 'นครปฐม',
            zipcode: 73110,
        },
        {
            subDistrict: 'ท่าตลาด',
            district: 'สามพราน',
            province: 'นครปฐม',
            zipcode: 73110,
        },
        {
            subDistrict: 'บางกระทึก',
            district: 'สามพราน',
            province: 'นครปฐม',
            zipcode: 73210,
        },
        {
            subDistrict: 'บางช้าง',
            district: 'สามพราน',
            province: 'นครปฐม',
            zipcode: 73110,
        },
        {
            subDistrict: 'บางเตย',
            district: 'สามพราน',
            province: 'นครปฐม',
            zipcode: 73210,
        },
        {
            subDistrict: 'บ้านใหม่',
            district: 'สามพราน',
            province: 'นครปฐม',
            zipcode: 73110,
        },
        {
            subDistrict: 'ยายชา',
            district: 'สามพราน',
            province: 'นครปฐม',
            zipcode: 73110,
        },
        {
            subDistrict: 'สามพราน',
            district: 'สามพราน',
            province: 'นครปฐม',
            zipcode: 73110,
        },
        {
            subDistrict: 'หอมเกร็ด',
            district: 'สามพราน',
            province: 'นครปฐม',
            zipcode: 73110,
        },
        {
            subDistrict: 'อ้อมใหญ่',
            district: 'สามพราน',
            province: 'นครปฐม',
            zipcode: 73160,
        },
        {
            subDistrict: 'ไร่ขิง',
            district: 'สามพราน',
            province: 'นครปฐม',
            zipcode: 73210,
        },
        {
            subDistrict: 'ดอนยายหอม',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'ตาก้อง',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'ถนนขาด',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'ทัพหลวง',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'ทุ่งน้อย',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'ธรรมศาลา',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'นครปฐม',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'บางแขม',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'บ่อพลับ',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'บ้านยาง',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'พระปฐมเจดีย์',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'พระประโทน',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'มาบแค',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'ลำพยา',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'วังตะกู',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'วังเย็น',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'สนามจันทร์',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'สระกะเทียม',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'สวนป่าน',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'สามควายเผือก',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'หนองงูเหลือม',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'หนองดินแดง',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'หนองปากโลง',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'ห้วยจรเข้',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'โพรงมะเดื่อ',
            district: 'เมืองนครปฐม',
            province: 'นครปฐม',
            zipcode: 73000,
        },
        {
            subDistrict: 'ท่าจำปา',
            district: 'ท่าอุเทน',
            province: 'นครพนม',
            zipcode: 48120,
        },
        {
            subDistrict: 'ท่าอุเทน',
            district: 'ท่าอุเทน',
            province: 'นครพนม',
            zipcode: 48120,
        },
        {
            subDistrict: 'พนอม',
            district: 'ท่าอุเทน',
            province: 'นครพนม',
            zipcode: 48120,
        },
        {
            subDistrict: 'พะทาย',
            district: 'ท่าอุเทน',
            province: 'นครพนม',
            zipcode: 48120,
        },
        {
            subDistrict: 'รามราช',
            district: 'ท่าอุเทน',
            province: 'นครพนม',
            zipcode: 48120,
        },
        {
            subDistrict: 'หนองเทา',
            district: 'ท่าอุเทน',
            province: 'นครพนม',
            zipcode: 48120,
        },
        {
            subDistrict: 'เวินพระบาท',
            district: 'ท่าอุเทน',
            province: 'นครพนม',
            zipcode: 48120,
        },
        {
            subDistrict: 'โนนตาล',
            district: 'ท่าอุเทน',
            province: 'นครพนม',
            zipcode: 48120,
        },
        {
            subDistrict: 'ไชยบุรี',
            district: 'ท่าอุเทน',
            province: 'นครพนม',
            zipcode: 48120,
        },
        {
            subDistrict: 'กุดฉิม',
            district: 'ธาตุพนม',
            province: 'นครพนม',
            zipcode: 48110,
        },
        {
            subDistrict: 'ดอนนางหงส์',
            district: 'ธาตุพนม',
            province: 'นครพนม',
            zipcode: 48110,
        },
        {
            subDistrict: 'ธาตุพนม',
            district: 'ธาตุพนม',
            province: 'นครพนม',
            zipcode: 48110,
        },
        {
            subDistrict: 'ธาตุพนมเหนือ',
            district: 'ธาตุพนม',
            province: 'นครพนม',
            zipcode: 48110,
        },
        {
            subDistrict: 'นาถ่อน',
            district: 'ธาตุพนม',
            province: 'นครพนม',
            zipcode: 48110,
        },
        {
            subDistrict: 'นาหนาด',
            district: 'ธาตุพนม',
            province: 'นครพนม',
            zipcode: 48110,
        },
        {
            subDistrict: 'น้ำก่ำ',
            district: 'ธาตุพนม',
            province: 'นครพนม',
            zipcode: 48110,
        },
        {
            subDistrict: 'ฝั่งแดง',
            district: 'ธาตุพนม',
            province: 'นครพนม',
            zipcode: 48110,
        },
        {
            subDistrict: 'พระกลางทุ่ง',
            district: 'ธาตุพนม',
            province: 'นครพนม',
            zipcode: 48110,
        },
        {
            subDistrict: 'อุ่มเหม้า',
            district: 'ธาตุพนม',
            province: 'นครพนม',
            zipcode: 48110,
        },
        {
            subDistrict: 'แสนพัน',
            district: 'ธาตุพนม',
            province: 'นครพนม',
            zipcode: 48110,
        },
        {
            subDistrict: 'โพนแพง',
            district: 'ธาตุพนม',
            province: 'นครพนม',
            zipcode: 48110,
        },
        {
            subDistrict: 'ดอนเตย',
            district: 'นาทม',
            province: 'นครพนม',
            zipcode: 48140,
        },
        {
            subDistrict: 'นาทม',
            district: 'นาทม',
            province: 'นครพนม',
            zipcode: 48140,
        },
        {
            subDistrict: 'หนองซน',
            district: 'นาทม',
            province: 'นครพนม',
            zipcode: 48140,
        },
        {
            subDistrict: 'ท่าเรือ',
            district: 'นาหว้า',
            province: 'นครพนม',
            zipcode: 48180,
        },
        {
            subDistrict: 'นาคูณใหญ่',
            district: 'นาหว้า',
            province: 'นครพนม',
            zipcode: 48180,
        },
        {
            subDistrict: 'นางัว',
            district: 'นาหว้า',
            province: 'นครพนม',
            zipcode: 48180,
        },
        {
            subDistrict: 'นาหว้า',
            district: 'นาหว้า',
            province: 'นครพนม',
            zipcode: 48180,
        },
        {
            subDistrict: 'บ้านเสียว',
            district: 'นาหว้า',
            province: 'นครพนม',
            zipcode: 48180,
        },
        {
            subDistrict: 'เหล่าพัฒนา',
            district: 'นาหว้า',
            province: 'นครพนม',
            zipcode: 48180,
        },
        {
            subDistrict: 'ก้านเหลือง',
            district: 'นาแก',
            province: 'นครพนม',
            zipcode: 48130,
        },
        {
            subDistrict: 'คำพี้',
            district: 'นาแก',
            province: 'นครพนม',
            zipcode: 48130,
        },
        {
            subDistrict: 'นาคู่',
            district: 'นาแก',
            province: 'นครพนม',
            zipcode: 48130,
        },
        {
            subDistrict: 'นาเลียง',
            district: 'นาแก',
            province: 'นครพนม',
            zipcode: 48130,
        },
        {
            subDistrict: 'นาแก',
            district: 'นาแก',
            province: 'นครพนม',
            zipcode: 48130,
        },
        {
            subDistrict: 'บ้านแก้ง',
            district: 'นาแก',
            province: 'นครพนม',
            zipcode: 48130,
        },
        {
            subDistrict: 'พระซอง',
            district: 'นาแก',
            province: 'นครพนม',
            zipcode: 48130,
        },
        {
            subDistrict: 'พิมาน',
            district: 'นาแก',
            province: 'นครพนม',
            zipcode: 48130,
        },
        {
            subDistrict: 'พุ่มแก',
            district: 'นาแก',
            province: 'นครพนม',
            zipcode: 48130,
        },
        {
            subDistrict: 'สีชมพู',
            district: 'นาแก',
            province: 'นครพนม',
            zipcode: 48130,
        },
        {
            subDistrict: 'หนองบ่อ',
            district: 'นาแก',
            province: 'นครพนม',
            zipcode: 48130,
        },
        {
            subDistrict: 'หนองสังข์',
            district: 'นาแก',
            province: 'นครพนม',
            zipcode: 48130,
        },
        {
            subDistrict: 'นางัว',
            district: 'บ้านแพง',
            province: 'นครพนม',
            zipcode: 48140,
        },
        {
            subDistrict: 'นาเข',
            district: 'บ้านแพง',
            province: 'นครพนม',
            zipcode: 48140,
        },
        {
            subDistrict: 'บ้านแพง',
            district: 'บ้านแพง',
            province: 'นครพนม',
            zipcode: 48140,
        },
        {
            subDistrict: 'หนองแวง',
            district: 'บ้านแพง',
            province: 'นครพนม',
            zipcode: 48140,
        },
        {
            subDistrict: 'โพนทอง',
            district: 'บ้านแพง',
            province: 'นครพนม',
            zipcode: 48140,
        },
        {
            subDistrict: 'ไผ่ล้อม',
            district: 'บ้านแพง',
            province: 'นครพนม',
            zipcode: 48140,
        },
        {
            subDistrict: 'กุตาไก้',
            district: 'ปลาปาก',
            province: 'นครพนม',
            zipcode: 48160,
        },
        {
            subDistrict: 'นามะเขือ',
            district: 'ปลาปาก',
            province: 'นครพนม',
            zipcode: 48160,
        },
        {
            subDistrict: 'ปลาปาก',
            district: 'ปลาปาก',
            province: 'นครพนม',
            zipcode: 48160,
        },
        {
            subDistrict: 'มหาชัย',
            district: 'ปลาปาก',
            province: 'นครพนม',
            zipcode: 48160,
        },
        {
            subDistrict: 'หนองฮี',
            district: 'ปลาปาก',
            province: 'นครพนม',
            zipcode: 48160,
        },
        {
            subDistrict: 'หนองเทาใหญ่',
            district: 'ปลาปาก',
            province: 'นครพนม',
            zipcode: 48160,
        },
        {
            subDistrict: 'โคกสว่าง',
            district: 'ปลาปาก',
            province: 'นครพนม',
            zipcode: 48160,
        },
        {
            subDistrict: 'โคกสูง',
            district: 'ปลาปาก',
            province: 'นครพนม',
            zipcode: 48160,
        },
        {
            subDistrict: 'ยอดชาด',
            district: 'วังยาง',
            province: 'นครพนม',
            zipcode: 48130,
        },
        {
            subDistrict: 'วังยาง',
            district: 'วังยาง',
            province: 'นครพนม',
            zipcode: 48130,
        },
        {
            subDistrict: 'หนองโพธิ์',
            district: 'วังยาง',
            province: 'นครพนม',
            zipcode: 48130,
        },
        {
            subDistrict: 'โคกสี',
            district: 'วังยาง',
            province: 'นครพนม',
            zipcode: 48130,
        },
        {
            subDistrict: 'ท่าบ่อสงคราม',
            district: 'ศรีสงคราม',
            province: 'นครพนม',
            zipcode: 48150,
        },
        {
            subDistrict: 'นาคำ',
            district: 'ศรีสงคราม',
            province: 'นครพนม',
            zipcode: 48150,
        },
        {
            subDistrict: 'นาเดื่อ',
            district: 'ศรีสงคราม',
            province: 'นครพนม',
            zipcode: 48150,
        },
        {
            subDistrict: 'บ้านข่า',
            district: 'ศรีสงคราม',
            province: 'นครพนม',
            zipcode: 48150,
        },
        {
            subDistrict: 'บ้านเอื้อง',
            district: 'ศรีสงคราม',
            province: 'นครพนม',
            zipcode: 48150,
        },
        {
            subDistrict: 'ศรีสงคราม',
            district: 'ศรีสงคราม',
            province: 'นครพนม',
            zipcode: 48150,
        },
        {
            subDistrict: 'สามผง',
            district: 'ศรีสงคราม',
            province: 'นครพนม',
            zipcode: 48150,
        },
        {
            subDistrict: 'หาดแพง',
            district: 'ศรีสงคราม',
            province: 'นครพนม',
            zipcode: 48150,
        },
        {
            subDistrict: 'โพนสว่าง',
            district: 'ศรีสงคราม',
            province: 'นครพนม',
            zipcode: 48150,
        },
        {
            subDistrict: 'กุรุคุ',
            district: 'เมืองนครพนม',
            province: 'นครพนม',
            zipcode: 48000,
        },
        {
            subDistrict: 'ขามเฒ่า',
            district: 'เมืองนครพนม',
            province: 'นครพนม',
            zipcode: 48000,
        },
        {
            subDistrict: 'คำเตย',
            district: 'เมืองนครพนม',
            province: 'นครพนม',
            zipcode: 48000,
        },
        {
            subDistrict: 'ดงขวาง',
            district: 'เมืองนครพนม',
            province: 'นครพนม',
            zipcode: 48000,
        },
        {
            subDistrict: 'ท่าค้อ',
            district: 'เมืองนครพนม',
            province: 'นครพนม',
            zipcode: 48000,
        },
        {
            subDistrict: 'นาทราย',
            district: 'เมืองนครพนม',
            province: 'นครพนม',
            zipcode: 48000,
        },
        {
            subDistrict: 'นาราชควาย',
            district: 'เมืองนครพนม',
            province: 'นครพนม',
            zipcode: 48000,
        },
        {
            subDistrict: 'บ้านกลาง',
            district: 'เมืองนครพนม',
            province: 'นครพนม',
            zipcode: 48000,
        },
        {
            subDistrict: 'บ้านผึ้ง',
            district: 'เมืองนครพนม',
            province: 'นครพนม',
            zipcode: 48000,
        },
        {
            subDistrict: 'วังตามัว',
            district: 'เมืองนครพนม',
            province: 'นครพนม',
            zipcode: 48000,
        },
        {
            subDistrict: 'หนองญาติ',
            district: 'เมืองนครพนม',
            province: 'นครพนม',
            zipcode: 48000,
        },
        {
            subDistrict: 'หนองแสง',
            district: 'เมืองนครพนม',
            province: 'นครพนม',
            zipcode: 48000,
        },
        {
            subDistrict: 'อาจสามารถ',
            district: 'เมืองนครพนม',
            province: 'นครพนม',
            zipcode: 48000,
        },
        {
            subDistrict: 'โพธิ์ตาก',
            district: 'เมืองนครพนม',
            province: 'นครพนม',
            zipcode: 48000,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'เมืองนครพนม',
            province: 'นครพนม',
            zipcode: 48000,
        },
        {
            subDistrict: 'ท่าลาด',
            district: 'เรณูนคร',
            province: 'นครพนม',
            zipcode: 48170,
        },
        {
            subDistrict: 'นาขาม',
            district: 'เรณูนคร',
            province: 'นครพนม',
            zipcode: 48170,
        },
        {
            subDistrict: 'นางาม',
            district: 'เรณูนคร',
            province: 'นครพนม',
            zipcode: 48170,
        },
        {
            subDistrict: 'หนองย่างชิ้น',
            district: 'เรณูนคร',
            province: 'นครพนม',
            zipcode: 48170,
        },
        {
            subDistrict: 'เรณู',
            district: 'เรณูนคร',
            province: 'นครพนม',
            zipcode: 48170,
        },
        {
            subDistrict: 'เรณูใต้',
            district: 'เรณูนคร',
            province: 'นครพนม',
            zipcode: 48170,
        },
        {
            subDistrict: 'โคกหินแฮ่',
            district: 'เรณูนคร',
            province: 'นครพนม',
            zipcode: 48170,
        },
        {
            subDistrict: 'โพนทอง',
            district: 'เรณูนคร',
            province: 'นครพนม',
            zipcode: 48170,
        },
        {
            subDistrict: 'นาขมิ้น',
            district: 'โพนสวรรค์',
            province: 'นครพนม',
            zipcode: 48190,
        },
        {
            subDistrict: 'นาหัวบ่อ',
            district: 'โพนสวรรค์',
            province: 'นครพนม',
            zipcode: 48190,
        },
        {
            subDistrict: 'นาใน',
            district: 'โพนสวรรค์',
            province: 'นครพนม',
            zipcode: 48190,
        },
        {
            subDistrict: 'บ้านค้อ',
            district: 'โพนสวรรค์',
            province: 'นครพนม',
            zipcode: 48190,
        },
        {
            subDistrict: 'โพนจาน',
            district: 'โพนสวรรค์',
            province: 'นครพนม',
            zipcode: 48190,
        },
        {
            subDistrict: 'โพนบก',
            district: 'โพนสวรรค์',
            province: 'นครพนม',
            zipcode: 48190,
        },
        {
            subDistrict: 'โพนสวรรค์',
            district: 'โพนสวรรค์',
            province: 'นครพนม',
            zipcode: 48190,
        },
        {
            subDistrict: 'ขามทะเลสอ',
            district: 'ขามทะเลสอ',
            province: 'นครราชสีมา',
            zipcode: 30280,
        },
        {
            subDistrict: 'บึงอ้อ',
            district: 'ขามทะเลสอ',
            province: 'นครราชสีมา',
            zipcode: 30280,
        },
        {
            subDistrict: 'พันดุง',
            district: 'ขามทะเลสอ',
            province: 'นครราชสีมา',
            zipcode: 30280,
        },
        {
            subDistrict: 'หนองสรวง',
            district: 'ขามทะเลสอ',
            province: 'นครราชสีมา',
            zipcode: 30280,
        },
        {
            subDistrict: 'โป่งแดง',
            district: 'ขามทะเลสอ',
            province: 'นครราชสีมา',
            zipcode: 30280,
        },
        {
            subDistrict: 'ขามสะแกแสง',
            district: 'ขามสะแกแสง',
            province: 'นครราชสีมา',
            zipcode: 30290,
        },
        {
            subDistrict: 'ชีวึก',
            district: 'ขามสะแกแสง',
            province: 'นครราชสีมา',
            zipcode: 30290,
        },
        {
            subDistrict: 'พะงาด',
            district: 'ขามสะแกแสง',
            province: 'นครราชสีมา',
            zipcode: 30290,
        },
        {
            subDistrict: 'หนองหัวฟาน',
            district: 'ขามสะแกแสง',
            province: 'นครราชสีมา',
            zipcode: 30290,
        },
        {
            subDistrict: 'เมืองนาท',
            district: 'ขามสะแกแสง',
            province: 'นครราชสีมา',
            zipcode: 30290,
        },
        {
            subDistrict: 'เมืองเกษตร',
            district: 'ขามสะแกแสง',
            province: 'นครราชสีมา',
            zipcode: 30290,
        },
        {
            subDistrict: 'โนนเมือง',
            district: 'ขามสะแกแสง',
            province: 'นครราชสีมา',
            zipcode: 30290,
        },
        {
            subDistrict: 'ขามสมบูรณ์',
            district: 'คง',
            province: 'นครราชสีมา',
            zipcode: 30260,
        },
        {
            subDistrict: 'คูขาด',
            district: 'คง',
            province: 'นครราชสีมา',
            zipcode: 30260,
        },
        {
            subDistrict: 'ดอนใหญ่',
            district: 'คง',
            province: 'นครราชสีมา',
            zipcode: 30260,
        },
        {
            subDistrict: 'ตาจั่น',
            district: 'คง',
            province: 'นครราชสีมา',
            zipcode: 30260,
        },
        {
            subDistrict: 'บ้านปรางค์',
            district: 'คง',
            province: 'นครราชสีมา',
            zipcode: 30260,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'คง',
            province: 'นครราชสีมา',
            zipcode: 30260,
        },
        {
            subDistrict: 'หนองมะนาว',
            district: 'คง',
            province: 'นครราชสีมา',
            zipcode: 30260,
        },
        {
            subDistrict: 'เทพาลัย',
            district: 'คง',
            province: 'นครราชสีมา',
            zipcode: 30260,
        },
        {
            subDistrict: 'เมืองคง',
            district: 'คง',
            province: 'นครราชสีมา',
            zipcode: 30260,
        },
        {
            subDistrict: 'โนนเต็ง',
            district: 'คง',
            province: 'นครราชสีมา',
            zipcode: 30260,
        },
        {
            subDistrict: 'ครบุรี',
            district: 'ครบุรี',
            province: 'นครราชสีมา',
            zipcode: 30250,
        },
        {
            subDistrict: 'ครบุรีใต้',
            district: 'ครบุรี',
            province: 'นครราชสีมา',
            zipcode: 30250,
        },
        {
            subDistrict: 'จระเข้หิน',
            district: 'ครบุรี',
            province: 'นครราชสีมา',
            zipcode: 30250,
        },
        {
            subDistrict: 'ตะแบกบาน',
            district: 'ครบุรี',
            province: 'นครราชสีมา',
            zipcode: 30250,
        },
        {
            subDistrict: 'บ้านใหม่',
            district: 'ครบุรี',
            province: 'นครราชสีมา',
            zipcode: 30250,
        },
        {
            subDistrict: 'มาบตะโกเอน',
            district: 'ครบุรี',
            province: 'นครราชสีมา',
            zipcode: 30250,
        },
        {
            subDistrict: 'ลำเพียก',
            district: 'ครบุรี',
            province: 'นครราชสีมา',
            zipcode: 30250,
        },
        {
            subDistrict: 'สระว่านพระยา',
            district: 'ครบุรี',
            province: 'นครราชสีมา',
            zipcode: 30250,
        },
        {
            subDistrict: 'อรพิมพ์',
            district: 'ครบุรี',
            province: 'นครราชสีมา',
            zipcode: 30250,
        },
        {
            subDistrict: 'เฉลียง',
            district: 'ครบุรี',
            province: 'นครราชสีมา',
            zipcode: 30250,
        },
        {
            subDistrict: 'แชะ',
            district: 'ครบุรี',
            province: 'นครราชสีมา',
            zipcode: 30250,
        },
        {
            subDistrict: 'โคกกระชาย',
            district: 'ครบุรี',
            province: 'นครราชสีมา',
            zipcode: 30250,
        },
        {
            subDistrict: 'คลองเมือง',
            district: 'จักราช',
            province: 'นครราชสีมา',
            zipcode: 30230,
        },
        {
            subDistrict: 'จักราช',
            district: 'จักราช',
            province: 'นครราชสีมา',
            zipcode: 30230,
        },
        {
            subDistrict: 'ทองหลาง',
            district: 'จักราช',
            province: 'นครราชสีมา',
            zipcode: 30230,
        },
        {
            subDistrict: 'ศรีละกอ',
            district: 'จักราช',
            province: 'นครราชสีมา',
            zipcode: 30230,
        },
        {
            subDistrict: 'สีสุก',
            district: 'จักราช',
            province: 'นครราชสีมา',
            zipcode: 30230,
        },
        {
            subDistrict: 'หนองขาม',
            district: 'จักราช',
            province: 'นครราชสีมา',
            zipcode: 30230,
        },
        {
            subDistrict: 'หนองพลวง',
            district: 'จักราช',
            province: 'นครราชสีมา',
            zipcode: 30230,
        },
        {
            subDistrict: 'หินโคน',
            district: 'จักราช',
            province: 'นครราชสีมา',
            zipcode: 30230,
        },
        {
            subDistrict: 'ชุมพวง',
            district: 'ชุมพวง',
            province: 'นครราชสีมา',
            zipcode: 30270,
        },
        {
            subDistrict: 'ตลาดไทร',
            district: 'ชุมพวง',
            province: 'นครราชสีมา',
            zipcode: 30270,
        },
        {
            subDistrict: 'ท่าลาด',
            district: 'ชุมพวง',
            province: 'นครราชสีมา',
            zipcode: 30270,
        },
        {
            subDistrict: 'ประสุข',
            district: 'ชุมพวง',
            province: 'นครราชสีมา',
            zipcode: 30270,
        },
        {
            subDistrict: 'สาหร่าย',
            district: 'ชุมพวง',
            province: 'นครราชสีมา',
            zipcode: 30270,
        },
        {
            subDistrict: 'หนองหลัก',
            district: 'ชุมพวง',
            province: 'นครราชสีมา',
            zipcode: 30270,
        },
        {
            subDistrict: 'โนนตูม',
            district: 'ชุมพวง',
            province: 'นครราชสีมา',
            zipcode: 30270,
        },
        {
            subDistrict: 'โนนยอ',
            district: 'ชุมพวง',
            province: 'นครราชสีมา',
            zipcode: 30270,
        },
        {
            subDistrict: 'โนนรัง',
            district: 'ชุมพวง',
            province: 'นครราชสีมา',
            zipcode: 30270,
        },
        {
            subDistrict: 'กุดพิมาน',
            district: 'ด่านขุนทด',
            province: 'นครราชสีมา',
            zipcode: 30210,
        },
        {
            subDistrict: 'ด่านขุนทด',
            district: 'ด่านขุนทด',
            province: 'นครราชสีมา',
            zipcode: 30210,
        },
        {
            subDistrict: 'ด่านนอก',
            district: 'ด่านขุนทด',
            province: 'นครราชสีมา',
            zipcode: 30210,
        },
        {
            subDistrict: 'ด่านใน',
            district: 'ด่านขุนทด',
            province: 'นครราชสีมา',
            zipcode: 30210,
        },
        {
            subDistrict: 'ตะเคียน',
            district: 'ด่านขุนทด',
            province: 'นครราชสีมา',
            zipcode: 30210,
        },
        {
            subDistrict: 'บ้านเก่า',
            district: 'ด่านขุนทด',
            province: 'นครราชสีมา',
            zipcode: 30210,
        },
        {
            subDistrict: 'บ้านแปรง',
            district: 'ด่านขุนทด',
            province: 'นครราชสีมา',
            zipcode: 36220,
        },
        {
            subDistrict: 'พันชนะ',
            district: 'ด่านขุนทด',
            province: 'นครราชสีมา',
            zipcode: 30210,
        },
        {
            subDistrict: 'สระจรเข้',
            district: 'ด่านขุนทด',
            province: 'นครราชสีมา',
            zipcode: 30210,
        },
        {
            subDistrict: 'หนองกราด',
            district: 'ด่านขุนทด',
            province: 'นครราชสีมา',
            zipcode: 30210,
        },
        {
            subDistrict: 'หนองบัวตะเกียด',
            district: 'ด่านขุนทด',
            province: 'นครราชสีมา',
            zipcode: 30210,
        },
        {
            subDistrict: 'หนองบัวละคร',
            district: 'ด่านขุนทด',
            province: 'นครราชสีมา',
            zipcode: 30210,
        },
        {
            subDistrict: 'หนองไทร',
            district: 'ด่านขุนทด',
            province: 'นครราชสีมา',
            zipcode: 36220,
        },
        {
            subDistrict: 'หินดาด',
            district: 'ด่านขุนทด',
            province: 'นครราชสีมา',
            zipcode: 30210,
        },
        {
            subDistrict: 'ห้วยบง',
            district: 'ด่านขุนทด',
            province: 'นครราชสีมา',
            zipcode: 30210,
        },
        {
            subDistrict: 'โนนเมืองพัฒนา',
            district: 'ด่านขุนทด',
            province: 'นครราชสีมา',
            zipcode: 30210,
        },
        {
            subDistrict: 'บัวลาย',
            district: 'บัวลาย',
            province: 'นครราชสีมา',
            zipcode: 30120,
        },
        {
            subDistrict: 'หนองหว้า',
            district: 'บัวลาย',
            province: 'นครราชสีมา',
            zipcode: 30120,
        },
        {
            subDistrict: 'เมืองพะไล',
            district: 'บัวลาย',
            province: 'นครราชสีมา',
            zipcode: 30120,
        },
        {
            subDistrict: 'โนนจาน',
            district: 'บัวลาย',
            province: 'นครราชสีมา',
            zipcode: 30120,
        },
        {
            subDistrict: 'กุดจอก',
            district: 'บัวใหญ่',
            province: 'นครราชสีมา',
            zipcode: 30120,
        },
        {
            subDistrict: 'ขุนทอง',
            district: 'บัวใหญ่',
            province: 'นครราชสีมา',
            zipcode: 30120,
        },
        {
            subDistrict: 'ดอนตะหนิน',
            district: 'บัวใหญ่',
            province: 'นครราชสีมา',
            zipcode: 30120,
        },
        {
            subDistrict: 'ด่านช้าง',
            district: 'บัวใหญ่',
            province: 'นครราชสีมา',
            zipcode: 30120,
        },
        {
            subDistrict: 'บัวใหญ่',
            district: 'บัวใหญ่',
            province: 'นครราชสีมา',
            zipcode: 30120,
        },
        {
            subDistrict: 'หนองบัวสะอาด',
            district: 'บัวใหญ่',
            province: 'นครราชสีมา',
            zipcode: 30120,
        },
        {
            subDistrict: 'หนองแจ้งใหญ่',
            district: 'บัวใหญ่',
            province: 'นครราชสีมา',
            zipcode: 30120,
        },
        {
            subDistrict: 'ห้วยยาง',
            district: 'บัวใหญ่',
            province: 'นครราชสีมา',
            zipcode: 30120,
        },
        {
            subDistrict: 'เสมาใหญ่',
            district: 'บัวใหญ่',
            province: 'นครราชสีมา',
            zipcode: 30120,
        },
        {
            subDistrict: 'โนนทองหลาง',
            district: 'บัวใหญ่',
            province: 'นครราชสีมา',
            zipcode: 30120,
        },
        {
            subDistrict: 'ช่อระกา',
            district: 'บ้านเหลื่อม',
            province: 'นครราชสีมา',
            zipcode: 30350,
        },
        {
            subDistrict: 'บ้านเหลื่อม',
            district: 'บ้านเหลื่อม',
            province: 'นครราชสีมา',
            zipcode: 30350,
        },
        {
            subDistrict: 'วังโพธิ์',
            district: 'บ้านเหลื่อม',
            province: 'นครราชสีมา',
            zipcode: 30350,
        },
        {
            subDistrict: 'โคกกระเบื้อง',
            district: 'บ้านเหลื่อม',
            province: 'นครราชสีมา',
            zipcode: 30350,
        },
        {
            subDistrict: 'กระทุ่มราย',
            district: 'ประทาย',
            province: 'นครราชสีมา',
            zipcode: 30180,
        },
        {
            subDistrict: 'ดอนมัน',
            district: 'ประทาย',
            province: 'นครราชสีมา',
            zipcode: 30180,
        },
        {
            subDistrict: 'ตลาดไทร',
            district: 'ประทาย',
            province: 'นครราชสีมา',
            zipcode: 30180,
        },
        {
            subDistrict: 'ทุ่งสว่าง',
            district: 'ประทาย',
            province: 'นครราชสีมา',
            zipcode: 30180,
        },
        {
            subDistrict: 'นางรำ',
            district: 'ประทาย',
            province: 'นครราชสีมา',
            zipcode: 30180,
        },
        {
            subDistrict: 'ประทาย',
            district: 'ประทาย',
            province: 'นครราชสีมา',
            zipcode: 30180,
        },
        {
            subDistrict: 'วังไม้แดง',
            district: 'ประทาย',
            province: 'นครราชสีมา',
            zipcode: 30180,
        },
        {
            subDistrict: 'หนองค่าย',
            district: 'ประทาย',
            province: 'นครราชสีมา',
            zipcode: 30180,
        },
        {
            subDistrict: 'หนองพลวง',
            district: 'ประทาย',
            province: 'นครราชสีมา',
            zipcode: 30180,
        },
        {
            subDistrict: 'หันห้วยทราย',
            district: 'ประทาย',
            province: 'นครราชสีมา',
            zipcode: 30180,
        },
        {
            subDistrict: 'เมืองโดน',
            district: 'ประทาย',
            province: 'นครราชสีมา',
            zipcode: 30180,
        },
        {
            subDistrict: 'โคกกลาง',
            district: 'ประทาย',
            province: 'นครราชสีมา',
            zipcode: 30180,
        },
        {
            subDistrict: 'โนนเพ็ด',
            district: 'ประทาย',
            province: 'นครราชสีมา',
            zipcode: 30180,
        },
        {
            subDistrict: 'งิ้ว',
            district: 'ปักธงชัย',
            province: 'นครราชสีมา',
            zipcode: 30150,
        },
        {
            subDistrict: 'ดอน',
            district: 'ปักธงชัย',
            province: 'นครราชสีมา',
            zipcode: 30150,
        },
        {
            subDistrict: 'ตะขบ',
            district: 'ปักธงชัย',
            province: 'นครราชสีมา',
            zipcode: 30150,
        },
        {
            subDistrict: 'ตะคุ',
            district: 'ปักธงชัย',
            province: 'นครราชสีมา',
            zipcode: 30150,
        },
        {
            subDistrict: 'ตูม',
            district: 'ปักธงชัย',
            province: 'นครราชสีมา',
            zipcode: 30150,
        },
        {
            subDistrict: 'ธงชัยเหนือ',
            district: 'ปักธงชัย',
            province: 'นครราชสีมา',
            zipcode: 30150,
        },
        {
            subDistrict: 'นกออก',
            district: 'ปักธงชัย',
            province: 'นครราชสีมา',
            zipcode: 30150,
        },
        {
            subDistrict: 'บ่อปลาทอง',
            district: 'ปักธงชัย',
            province: 'นครราชสีมา',
            zipcode: 30150,
        },
        {
            subDistrict: 'ภูหลวง',
            district: 'ปักธงชัย',
            province: 'นครราชสีมา',
            zipcode: 30150,
        },
        {
            subDistrict: 'ลำนางแก้ว',
            district: 'ปักธงชัย',
            province: 'นครราชสีมา',
            zipcode: 30150,
        },
        {
            subDistrict: 'สะแกราช',
            district: 'ปักธงชัย',
            province: 'นครราชสีมา',
            zipcode: 30150,
        },
        {
            subDistrict: 'สำโรง',
            district: 'ปักธงชัย',
            province: 'นครราชสีมา',
            zipcode: 30150,
        },
        {
            subDistrict: 'สุขเกษม',
            district: 'ปักธงชัย',
            province: 'นครราชสีมา',
            zipcode: 30150,
        },
        {
            subDistrict: 'เกษมทรัพย์',
            district: 'ปักธงชัย',
            province: 'นครราชสีมา',
            zipcode: 30150,
        },
        {
            subDistrict: 'เมืองปัก',
            district: 'ปักธงชัย',
            province: 'นครราชสีมา',
            zipcode: 30150,
        },
        {
            subDistrict: 'โคกไทย',
            district: 'ปักธงชัย',
            province: 'นครราชสีมา',
            zipcode: 30150,
        },
        {
            subDistrict: 'กลางดง',
            district: 'ปากช่อง',
            province: 'นครราชสีมา',
            zipcode: 30320,
        },
        {
            subDistrict: 'ขนงพระ',
            district: 'ปากช่อง',
            province: 'นครราชสีมา',
            zipcode: 30130,
        },
        {
            subDistrict: 'คลองม่วง',
            district: 'ปากช่อง',
            province: 'นครราชสีมา',
            zipcode: 30130,
        },
        {
            subDistrict: 'จันทึก',
            district: 'ปากช่อง',
            province: 'นครราชสีมา',
            zipcode: 30130,
        },
        {
            subDistrict: 'ปากช่อง',
            district: 'ปากช่อง',
            province: 'นครราชสีมา',
            zipcode: 30130,
        },
        {
            subDistrict: 'พญาเย็น',
            district: 'ปากช่อง',
            province: 'นครราชสีมา',
            zipcode: 30320,
        },
        {
            subDistrict: 'วังกะทะ',
            district: 'ปากช่อง',
            province: 'นครราชสีมา',
            zipcode: 30130,
        },
        {
            subDistrict: 'วังไทร',
            district: 'ปากช่อง',
            province: 'นครราชสีมา',
            zipcode: 30130,
        },
        {
            subDistrict: 'หนองน้ำแดง',
            district: 'ปากช่อง',
            province: 'นครราชสีมา',
            zipcode: 30130,
        },
        {
            subDistrict: 'หนองสาหร่าย',
            district: 'ปากช่อง',
            province: 'นครราชสีมา',
            zipcode: 30130,
        },
        {
            subDistrict: 'หมูสี',
            district: 'ปากช่อง',
            province: 'นครราชสีมา',
            zipcode: 30130,
        },
        {
            subDistrict: 'โป่งตาลอง',
            district: 'ปากช่อง',
            province: 'นครราชสีมา',
            zipcode: 30130,
        },
        {
            subDistrict: 'ทัพรั้ง',
            district: 'พระทองคำ',
            province: 'นครราชสีมา',
            zipcode: 30220,
        },
        {
            subDistrict: 'พังเทียม',
            district: 'พระทองคำ',
            province: 'นครราชสีมา',
            zipcode: 30220,
        },
        {
            subDistrict: 'มาบกราด',
            district: 'พระทองคำ',
            province: 'นครราชสีมา',
            zipcode: 30220,
        },
        {
            subDistrict: 'สระพระ',
            district: 'พระทองคำ',
            province: 'นครราชสีมา',
            zipcode: 30220,
        },
        {
            subDistrict: 'หนองหอย',
            district: 'พระทองคำ',
            province: 'นครราชสีมา',
            zipcode: 30220,
        },
        {
            subDistrict: 'กระชอน',
            district: 'พิมาย',
            province: 'นครราชสีมา',
            zipcode: 30110,
        },
        {
            subDistrict: 'กระเบื้องใหญ่',
            district: 'พิมาย',
            province: 'นครราชสีมา',
            zipcode: 30110,
        },
        {
            subDistrict: 'ชีวาน',
            district: 'พิมาย',
            province: 'นครราชสีมา',
            zipcode: 30110,
        },
        {
            subDistrict: 'ดงใหญ่',
            district: 'พิมาย',
            province: 'นครราชสีมา',
            zipcode: 30110,
        },
        {
            subDistrict: 'ท่าหลวง',
            district: 'พิมาย',
            province: 'นครราชสีมา',
            zipcode: 30110,
        },
        {
            subDistrict: 'ธารละหลอด',
            district: 'พิมาย',
            province: 'นครราชสีมา',
            zipcode: 30110,
        },
        {
            subDistrict: 'นิคมสร้างตนเอง',
            district: 'พิมาย',
            province: 'นครราชสีมา',
            zipcode: 30110,
        },
        {
            subDistrict: 'รังกาใหญ่',
            district: 'พิมาย',
            province: 'นครราชสีมา',
            zipcode: 30110,
        },
        {
            subDistrict: 'สัมฤทธิ์',
            district: 'พิมาย',
            province: 'นครราชสีมา',
            zipcode: 30110,
        },
        {
            subDistrict: 'หนองระเวียง',
            district: 'พิมาย',
            province: 'นครราชสีมา',
            zipcode: 30110,
        },
        {
            subDistrict: 'โบสถ์',
            district: 'พิมาย',
            province: 'นครราชสีมา',
            zipcode: 30110,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'พิมาย',
            province: 'นครราชสีมา',
            zipcode: 30110,
        },
        {
            subDistrict: 'ขุย',
            district: 'ลำทะเมนชัย',
            province: 'นครราชสีมา',
            zipcode: 30270,
        },
        {
            subDistrict: 'ช่องแมว',
            district: 'ลำทะเมนชัย',
            province: 'นครราชสีมา',
            zipcode: 30270,
        },
        {
            subDistrict: 'บ้านยาง',
            district: 'ลำทะเมนชัย',
            province: 'นครราชสีมา',
            zipcode: 30270,
        },
        {
            subDistrict: 'ไพล',
            district: 'ลำทะเมนชัย',
            province: 'นครราชสีมา',
            zipcode: 30270,
        },
        {
            subDistrict: 'ระเริง',
            district: 'วังน้ำเขียว',
            province: 'นครราชสีมา',
            zipcode: 30150,
        },
        {
            subDistrict: 'วังน้ำเขียว',
            district: 'วังน้ำเขียว',
            province: 'นครราชสีมา',
            zipcode: 30370,
        },
        {
            subDistrict: 'วังหมี',
            district: 'วังน้ำเขียว',
            province: 'นครราชสีมา',
            zipcode: 30370,
        },
        {
            subDistrict: 'อุดมทรัพย์',
            district: 'วังน้ำเขียว',
            province: 'นครราชสีมา',
            zipcode: 30370,
        },
        {
            subDistrict: 'ไทยสามัคคี',
            district: 'วังน้ำเขียว',
            province: 'นครราชสีมา',
            zipcode: 30370,
        },
        {
            subDistrict: 'กฤษณา',
            district: 'สีคิ้ว',
            province: 'นครราชสีมา',
            zipcode: 30140,
        },
        {
            subDistrict: 'กุดน้อย',
            district: 'สีคิ้ว',
            province: 'นครราชสีมา',
            zipcode: 30140,
        },
        {
            subDistrict: 'คลองไผ่',
            district: 'สีคิ้ว',
            province: 'นครราชสีมา',
            zipcode: 30340,
        },
        {
            subDistrict: 'ดอนเมือง',
            district: 'สีคิ้ว',
            province: 'นครราชสีมา',
            zipcode: 30140,
        },
        {
            subDistrict: 'บ้านหัน',
            district: 'สีคิ้ว',
            province: 'นครราชสีมา',
            zipcode: 30140,
        },
        {
            subDistrict: 'มิตรภาพ',
            district: 'สีคิ้ว',
            province: 'นครราชสีมา',
            zipcode: 30140,
        },
        {
            subDistrict: 'ลาดบัวขาว',
            district: 'สีคิ้ว',
            province: 'นครราชสีมา',
            zipcode: 30340,
        },
        {
            subDistrict: 'วังโรงใหญ่',
            district: 'สีคิ้ว',
            province: 'นครราชสีมา',
            zipcode: 30140,
        },
        {
            subDistrict: 'สีคิ้ว',
            district: 'สีคิ้ว',
            province: 'นครราชสีมา',
            zipcode: 30140,
        },
        {
            subDistrict: 'หนองน้ำใส',
            district: 'สีคิ้ว',
            province: 'นครราชสีมา',
            zipcode: 30140,
        },
        {
            subDistrict: 'หนองบัวน้อย',
            district: 'สีคิ้ว',
            province: 'นครราชสีมา',
            zipcode: 30140,
        },
        {
            subDistrict: 'หนองหญ้าขาว',
            district: 'สีคิ้ว',
            province: 'นครราชสีมา',
            zipcode: 30140,
        },
        {
            subDistrict: 'สามเมือง',
            district: 'สีดา',
            province: 'นครราชสีมา',
            zipcode: 30430,
        },
        {
            subDistrict: 'สีดา',
            district: 'สีดา',
            province: 'นครราชสีมา',
            zipcode: 30430,
        },
        {
            subDistrict: 'หนองตาดใหญ่',
            district: 'สีดา',
            province: 'นครราชสีมา',
            zipcode: 30430,
        },
        {
            subDistrict: 'โนนประดู่',
            district: 'สีดา',
            province: 'นครราชสีมา',
            zipcode: 30430,
        },
        {
            subDistrict: 'โพนทอง',
            district: 'สีดา',
            province: 'นครราชสีมา',
            zipcode: 30430,
        },
        {
            subDistrict: 'กุดจิก',
            district: 'สูงเนิน',
            province: 'นครราชสีมา',
            zipcode: 30380,
        },
        {
            subDistrict: 'นากลาง',
            district: 'สูงเนิน',
            province: 'นครราชสีมา',
            zipcode: 30380,
        },
        {
            subDistrict: 'บุ่งขี้เหล็ก',
            district: 'สูงเนิน',
            province: 'นครราชสีมา',
            zipcode: 30170,
        },
        {
            subDistrict: 'มะเกลือเก่า',
            district: 'สูงเนิน',
            province: 'นครราชสีมา',
            zipcode: 30170,
        },
        {
            subDistrict: 'มะเกลือใหม่',
            district: 'สูงเนิน',
            province: 'นครราชสีมา',
            zipcode: 30170,
        },
        {
            subDistrict: 'สูงเนิน',
            district: 'สูงเนิน',
            province: 'นครราชสีมา',
            zipcode: 30170,
        },
        {
            subDistrict: 'หนองตะไก้',
            district: 'สูงเนิน',
            province: 'นครราชสีมา',
            zipcode: 30380,
        },
        {
            subDistrict: 'เสมา',
            district: 'สูงเนิน',
            province: 'นครราชสีมา',
            zipcode: 30170,
        },
        {
            subDistrict: 'โคราช',
            district: 'สูงเนิน',
            province: 'นครราชสีมา',
            zipcode: 30170,
        },
        {
            subDistrict: 'โค้งยาง',
            district: 'สูงเนิน',
            province: 'นครราชสีมา',
            zipcode: 30170,
        },
        {
            subDistrict: 'โนนค่า',
            district: 'สูงเนิน',
            province: 'นครราชสีมา',
            zipcode: 30170,
        },
        {
            subDistrict: 'บ้านใหม่',
            district: 'หนองบุญมาก',
            province: 'นครราชสีมา',
            zipcode: 30410,
        },
        {
            subDistrict: 'ลุงเขว้า',
            district: 'หนองบุญมาก',
            province: 'นครราชสีมา',
            zipcode: 30410,
        },
        {
            subDistrict: 'สารภี',
            district: 'หนองบุญมาก',
            province: 'นครราชสีมา',
            zipcode: 30410,
        },
        {
            subDistrict: 'หนองตะไก้',
            district: 'หนองบุญมาก',
            province: 'นครราชสีมา',
            zipcode: 30410,
        },
        {
            subDistrict: 'หนองบุนนาก',
            district: 'หนองบุญมาก',
            province: 'นครราชสีมา',
            zipcode: 30410,
        },
        {
            subDistrict: 'หนองหัวแรต',
            district: 'หนองบุญมาก',
            province: 'นครราชสีมา',
            zipcode: 30410,
        },
        {
            subDistrict: 'หนองไม้ไผ่',
            district: 'หนองบุญมาก',
            province: 'นครราชสีมา',
            zipcode: 30410,
        },
        {
            subDistrict: 'แหลมทอง',
            district: 'หนองบุญมาก',
            province: 'นครราชสีมา',
            zipcode: 30410,
        },
        {
            subDistrict: 'ไทยเจริญ',
            district: 'หนองบุญมาก',
            province: 'นครราชสีมา',
            zipcode: 30410,
        },
        {
            subDistrict: 'กงรถ',
            district: 'ห้วยแถลง',
            province: 'นครราชสีมา',
            zipcode: 30240,
        },
        {
            subDistrict: 'งิ้ว',
            district: 'ห้วยแถลง',
            province: 'นครราชสีมา',
            zipcode: 30240,
        },
        {
            subDistrict: 'ตะโก',
            district: 'ห้วยแถลง',
            province: 'นครราชสีมา',
            zipcode: 30240,
        },
        {
            subDistrict: 'ทับสวาย',
            district: 'ห้วยแถลง',
            province: 'นครราชสีมา',
            zipcode: 30240,
        },
        {
            subDistrict: 'หลุ่งตะเคียน',
            district: 'ห้วยแถลง',
            province: 'นครราชสีมา',
            zipcode: 30240,
        },
        {
            subDistrict: 'หลุ่งประดู่',
            district: 'ห้วยแถลง',
            province: 'นครราชสีมา',
            zipcode: 30240,
        },
        {
            subDistrict: 'หินดาด',
            district: 'ห้วยแถลง',
            province: 'นครราชสีมา',
            zipcode: 30240,
        },
        {
            subDistrict: 'ห้วยแคน',
            district: 'ห้วยแถลง',
            province: 'นครราชสีมา',
            zipcode: 30240,
        },
        {
            subDistrict: 'ห้วยแถลง',
            district: 'ห้วยแถลง',
            province: 'นครราชสีมา',
            zipcode: 30240,
        },
        {
            subDistrict: 'เมืองพลับพลา',
            district: 'ห้วยแถลง',
            province: 'นครราชสีมา',
            zipcode: 30240,
        },
        {
            subDistrict: 'ช้างทอง',
            district: 'เฉลิมพระเกียรติ',
            province: 'นครราชสีมา',
            zipcode: 30230,
        },
        {
            subDistrict: 'ท่าช้าง',
            district: 'เฉลิมพระเกียรติ',
            province: 'นครราชสีมา',
            zipcode: 30230,
        },
        {
            subDistrict: 'พระพุทธ',
            district: 'เฉลิมพระเกียรติ',
            province: 'นครราชสีมา',
            zipcode: 30230,
        },
        {
            subDistrict: 'หนองงูเหลือม',
            district: 'เฉลิมพระเกียรติ',
            province: 'นครราชสีมา',
            zipcode: 30000,
        },
        {
            subDistrict: 'หนองยาง',
            district: 'เฉลิมพระเกียรติ',
            province: 'นครราชสีมา',
            zipcode: 30230,
        },
        {
            subDistrict: 'บึงปรือ',
            district: 'เทพารักษ์',
            province: 'นครราชสีมา',
            zipcode: 30210,
        },
        {
            subDistrict: 'วังยายทอง',
            district: 'เทพารักษ์',
            province: 'นครราชสีมา',
            zipcode: 30210,
        },
        {
            subDistrict: 'สำนักตะคร้อ',
            district: 'เทพารักษ์',
            province: 'นครราชสีมา',
            zipcode: 30210,
        },
        {
            subDistrict: 'หนองแวง',
            district: 'เทพารักษ์',
            province: 'นครราชสีมา',
            zipcode: 30210,
        },
        {
            subDistrict: 'จอหอ',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30310,
        },
        {
            subDistrict: 'ตลาด',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30310,
        },
        {
            subDistrict: 'บ้านเกาะ',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30000,
        },
        {
            subDistrict: 'บ้านโพธิ์',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30310,
        },
        {
            subDistrict: 'บ้านใหม่',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30000,
        },
        {
            subDistrict: 'ปรุใหญ่',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30000,
        },
        {
            subDistrict: 'พลกรัง',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30000,
        },
        {
            subDistrict: 'พะเนา',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30000,
        },
        {
            subDistrict: 'พุดซา',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30000,
        },
        {
            subDistrict: 'มะเริง',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30000,
        },
        {
            subDistrict: 'สีมุม',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30000,
        },
        {
            subDistrict: 'สุรนารี',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30000,
        },
        {
            subDistrict: 'หนองกระทุ่ม',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30000,
        },
        {
            subDistrict: 'หนองจะบก',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30000,
        },
        {
            subDistrict: 'หนองบัวศาลา',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30000,
        },
        {
            subDistrict: 'หนองระเวียง',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30000,
        },
        {
            subDistrict: 'หนองไข่น้ำ',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30310,
        },
        {
            subDistrict: 'หนองไผ่ล้อม',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30000,
        },
        {
            subDistrict: 'หมื่นไวย',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30000,
        },
        {
            subDistrict: 'หัวทะเล',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30000,
        },
        {
            subDistrict: 'โคกกรวด',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30280,
        },
        {
            subDistrict: 'โคกสูง',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30310,
        },
        {
            subDistrict: 'โพธิ์กลาง',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30000,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30000,
        },
        {
            subDistrict: 'ไชยมงคล',
            district: 'เมืองนครราชสีมา',
            province: 'นครราชสีมา',
            zipcode: 30000,
        },
        {
            subDistrict: 'กระเบื้องนอก',
            district: 'เมืองยาง',
            province: 'นครราชสีมา',
            zipcode: 30270,
        },
        {
            subDistrict: 'ละหานปลาค้าว',
            district: 'เมืองยาง',
            province: 'นครราชสีมา',
            zipcode: 30270,
        },
        {
            subDistrict: 'เมืองยาง',
            district: 'เมืองยาง',
            province: 'นครราชสีมา',
            zipcode: 30270,
        },
        {
            subDistrict: 'โนนอุดม',
            district: 'เมืองยาง',
            province: 'นครราชสีมา',
            zipcode: 30270,
        },
        {
            subDistrict: 'กุดโบสถ์',
            district: 'เสิงสาง',
            province: 'นครราชสีมา',
            zipcode: 30330,
        },
        {
            subDistrict: 'บ้านราษฎร์',
            district: 'เสิงสาง',
            province: 'นครราชสีมา',
            zipcode: 30330,
        },
        {
            subDistrict: 'สระตะเคียน',
            district: 'เสิงสาง',
            province: 'นครราชสีมา',
            zipcode: 30330,
        },
        {
            subDistrict: 'สุขไพบูลย์',
            district: 'เสิงสาง',
            province: 'นครราชสีมา',
            zipcode: 30330,
        },
        {
            subDistrict: 'เสิงสาง',
            district: 'เสิงสาง',
            province: 'นครราชสีมา',
            zipcode: 30330,
        },
        {
            subDistrict: 'โนนสมบูรณ์',
            district: 'เสิงสาง',
            province: 'นครราชสีมา',
            zipcode: 30330,
        },
        {
            subDistrict: 'บึงพะไล',
            district: 'แก้งสนามนาง',
            province: 'นครราชสีมา',
            zipcode: 30440,
        },
        {
            subDistrict: 'บึงสำโรง',
            district: 'แก้งสนามนาง',
            province: 'นครราชสีมา',
            zipcode: 30440,
        },
        {
            subDistrict: 'สีสุก',
            district: 'แก้งสนามนาง',
            province: 'นครราชสีมา',
            zipcode: 30440,
        },
        {
            subDistrict: 'แก้งสนามนาง',
            district: 'แก้งสนามนาง',
            province: 'นครราชสีมา',
            zipcode: 30440,
        },
        {
            subDistrict: 'โนนสำราญ',
            district: 'แก้งสนามนาง',
            province: 'นครราชสีมา',
            zipcode: 30440,
        },
        {
            subDistrict: 'กระโทก',
            district: 'โชคชัย',
            province: 'นครราชสีมา',
            zipcode: 30190,
        },
        {
            subDistrict: 'ด่านเกวียน',
            district: 'โชคชัย',
            province: 'นครราชสีมา',
            zipcode: 30190,
        },
        {
            subDistrict: 'ทุ่งอรุณ',
            district: 'โชคชัย',
            province: 'นครราชสีมา',
            zipcode: 30190,
        },
        {
            subDistrict: 'ท่าจะหลุง',
            district: 'โชคชัย',
            province: 'นครราชสีมา',
            zipcode: 30190,
        },
        {
            subDistrict: 'ท่าลาดขาว',
            district: 'โชคชัย',
            province: 'นครราชสีมา',
            zipcode: 30190,
        },
        {
            subDistrict: 'ท่าอ่าง',
            district: 'โชคชัย',
            province: 'นครราชสีมา',
            zipcode: 30190,
        },
        {
            subDistrict: 'ท่าเยี่ยม',
            district: 'โชคชัย',
            province: 'นครราชสีมา',
            zipcode: 30190,
        },
        {
            subDistrict: 'พลับพลา',
            district: 'โชคชัย',
            province: 'นครราชสีมา',
            zipcode: 30190,
        },
        {
            subDistrict: 'ละลมใหม่พัฒนา',
            district: 'โชคชัย',
            province: 'นครราชสีมา',
            zipcode: 30190,
        },
        {
            subDistrict: 'โชคชัย',
            district: 'โชคชัย',
            province: 'นครราชสีมา',
            zipcode: 30190,
        },
        {
            subDistrict: 'ขามเฒ่า',
            district: 'โนนสูง',
            province: 'นครราชสีมา',
            zipcode: 30160,
        },
        {
            subDistrict: 'จันอัด',
            district: 'โนนสูง',
            province: 'นครราชสีมา',
            zipcode: 30160,
        },
        {
            subDistrict: 'ดอนชมพู',
            district: 'โนนสูง',
            province: 'นครราชสีมา',
            zipcode: 30160,
        },
        {
            subDistrict: 'ดอนหวาย',
            district: 'โนนสูง',
            province: 'นครราชสีมา',
            zipcode: 30160,
        },
        {
            subDistrict: 'ด่านคล้า',
            district: 'โนนสูง',
            province: 'นครราชสีมา',
            zipcode: 30160,
        },
        {
            subDistrict: 'ธารปราสาท',
            district: 'โนนสูง',
            province: 'นครราชสีมา',
            zipcode: 30420,
        },
        {
            subDistrict: 'บิง',
            district: 'โนนสูง',
            province: 'นครราชสีมา',
            zipcode: 30160,
        },
        {
            subDistrict: 'พลสงคราม',
            district: 'โนนสูง',
            province: 'นครราชสีมา',
            zipcode: 30160,
        },
        {
            subDistrict: 'มะค่า',
            district: 'โนนสูง',
            province: 'นครราชสีมา',
            zipcode: 30160,
        },
        {
            subDistrict: 'ลำคอหงษ์',
            district: 'โนนสูง',
            province: 'นครราชสีมา',
            zipcode: 30160,
        },
        {
            subDistrict: 'ลำมูล',
            district: 'โนนสูง',
            province: 'นครราชสีมา',
            zipcode: 30160,
        },
        {
            subDistrict: 'หลุมข้าว',
            district: 'โนนสูง',
            province: 'นครราชสีมา',
            zipcode: 30160,
        },
        {
            subDistrict: 'เมืองปราสาท',
            district: 'โนนสูง',
            province: 'นครราชสีมา',
            zipcode: 30160,
        },
        {
            subDistrict: 'โตนด',
            district: 'โนนสูง',
            province: 'นครราชสีมา',
            zipcode: 30160,
        },
        {
            subDistrict: 'โนนสูง',
            district: 'โนนสูง',
            province: 'นครราชสีมา',
            zipcode: 30160,
        },
        {
            subDistrict: 'ใหม่',
            district: 'โนนสูง',
            province: 'นครราชสีมา',
            zipcode: 30160,
        },
        {
            subDistrict: 'ดอนยาวใหญ่',
            district: 'โนนแดง',
            province: 'นครราชสีมา',
            zipcode: 30360,
        },
        {
            subDistrict: 'วังหิน',
            district: 'โนนแดง',
            province: 'นครราชสีมา',
            zipcode: 30360,
        },
        {
            subDistrict: 'สำพะเนียง',
            district: 'โนนแดง',
            province: 'นครราชสีมา',
            zipcode: 30360,
        },
        {
            subDistrict: 'โนนตาเถร',
            district: 'โนนแดง',
            province: 'นครราชสีมา',
            zipcode: 30360,
        },
        {
            subDistrict: 'โนนแดง',
            district: 'โนนแดง',
            province: 'นครราชสีมา',
            zipcode: 30360,
        },
        {
            subDistrict: 'กำปัง',
            district: 'โนนไทย',
            province: 'นครราชสีมา',
            zipcode: 30220,
        },
        {
            subDistrict: 'ค้างพลู',
            district: 'โนนไทย',
            province: 'นครราชสีมา',
            zipcode: 30220,
        },
        {
            subDistrict: 'ด่านจาก',
            district: 'โนนไทย',
            province: 'นครราชสีมา',
            zipcode: 30220,
        },
        {
            subDistrict: 'ถนนโพธิ์',
            district: 'โนนไทย',
            province: 'นครราชสีมา',
            zipcode: 30220,
        },
        {
            subDistrict: 'บัลลังก์',
            district: 'โนนไทย',
            province: 'นครราชสีมา',
            zipcode: 30220,
        },
        {
            subDistrict: 'บ้านวัง',
            district: 'โนนไทย',
            province: 'นครราชสีมา',
            zipcode: 30220,
        },
        {
            subDistrict: 'มะค่า',
            district: 'โนนไทย',
            province: 'นครราชสีมา',
            zipcode: 30220,
        },
        {
            subDistrict: 'สายออ',
            district: 'โนนไทย',
            province: 'นครราชสีมา',
            zipcode: 30220,
        },
        {
            subDistrict: 'สำโรง',
            district: 'โนนไทย',
            province: 'นครราชสีมา',
            zipcode: 30220,
        },
        {
            subDistrict: 'โนนไทย',
            district: 'โนนไทย',
            province: 'นครราชสีมา',
            zipcode: 30220,
        },
        {
            subDistrict: 'ขนอม',
            district: 'ขนอม',
            province: 'นครศรีธรรมราช',
            zipcode: 80210,
        },
        {
            subDistrict: 'ควนทอง',
            district: 'ขนอม',
            province: 'นครศรีธรรมราช',
            zipcode: 80210,
        },
        {
            subDistrict: 'ท้องเนียน',
            district: 'ขนอม',
            province: 'นครศรีธรรมราช',
            zipcode: 80210,
        },
        {
            subDistrict: 'ควนหนองคว้า',
            district: 'จุฬาภรณ์',
            province: 'นครศรีธรรมราช',
            zipcode: 80130,
        },
        {
            subDistrict: 'ทุ่งโพธิ์',
            district: 'จุฬาภรณ์',
            province: 'นครศรีธรรมราช',
            zipcode: 80130,
        },
        {
            subDistrict: 'นาหมอบุญ',
            district: 'จุฬาภรณ์',
            province: 'นครศรีธรรมราช',
            zipcode: 80130,
        },
        {
            subDistrict: 'บ้านควนมุด',
            district: 'จุฬาภรณ์',
            province: 'นครศรีธรรมราช',
            zipcode: 80180,
        },
        {
            subDistrict: 'บ้านชะอวด',
            district: 'จุฬาภรณ์',
            province: 'นครศรีธรรมราช',
            zipcode: 80180,
        },
        {
            subDistrict: 'สามตำบล',
            district: 'จุฬาภรณ์',
            province: 'นครศรีธรรมราช',
            zipcode: 80130,
        },
        {
            subDistrict: 'กะเปียด',
            district: 'ฉวาง',
            province: 'นครศรีธรรมราช',
            zipcode: 80260,
        },
        {
            subDistrict: 'จันดี',
            district: 'ฉวาง',
            province: 'นครศรีธรรมราช',
            zipcode: 80250,
        },
        {
            subDistrict: 'ฉวาง',
            district: 'ฉวาง',
            province: 'นครศรีธรรมราช',
            zipcode: 80150,
        },
        {
            subDistrict: 'นากะชะ',
            district: 'ฉวาง',
            province: 'นครศรีธรรมราช',
            zipcode: 80150,
        },
        {
            subDistrict: 'นาเขลียง',
            district: 'ฉวาง',
            province: 'นครศรีธรรมราช',
            zipcode: 80260,
        },
        {
            subDistrict: 'นาแว',
            district: 'ฉวาง',
            province: 'นครศรีธรรมราช',
            zipcode: 80260,
        },
        {
            subDistrict: 'ละอาย',
            district: 'ฉวาง',
            province: 'นครศรีธรรมราช',
            zipcode: 80250,
        },
        {
            subDistrict: 'ห้วยปริก',
            district: 'ฉวาง',
            province: 'นครศรีธรรมราช',
            zipcode: 80260,
        },
        {
            subDistrict: 'ไม้เรียง',
            district: 'ฉวาง',
            province: 'นครศรีธรรมราช',
            zipcode: 80260,
        },
        {
            subDistrict: 'ไสหร้า',
            district: 'ฉวาง',
            province: 'นครศรีธรรมราช',
            zipcode: 80150,
        },
        {
            subDistrict: 'ขอนหาด',
            district: 'ชะอวด',
            province: 'นครศรีธรรมราช',
            zipcode: 80180,
        },
        {
            subDistrict: 'ควนหนองหงษ์',
            district: 'ชะอวด',
            province: 'นครศรีธรรมราช',
            zipcode: 80180,
        },
        {
            subDistrict: 'ชะอวด',
            district: 'ชะอวด',
            province: 'นครศรีธรรมราช',
            zipcode: 80180,
        },
        {
            subDistrict: 'ท่าประจะ',
            district: 'ชะอวด',
            province: 'นครศรีธรรมราช',
            zipcode: 80180,
        },
        {
            subDistrict: 'ท่าเสม็ด',
            district: 'ชะอวด',
            province: 'นครศรีธรรมราช',
            zipcode: 80180,
        },
        {
            subDistrict: 'นางหลง',
            district: 'ชะอวด',
            province: 'นครศรีธรรมราช',
            zipcode: 80180,
        },
        {
            subDistrict: 'บ้านตูล',
            district: 'ชะอวด',
            province: 'นครศรีธรรมราช',
            zipcode: 80180,
        },
        {
            subDistrict: 'วังอ่าง',
            district: 'ชะอวด',
            province: 'นครศรีธรรมราช',
            zipcode: 80180,
        },
        {
            subDistrict: 'เกาะขันธ์',
            district: 'ชะอวด',
            province: 'นครศรีธรรมราช',
            zipcode: 80180,
        },
        {
            subDistrict: 'เขาพระทอง',
            district: 'ชะอวด',
            province: 'นครศรีธรรมราช',
            zipcode: 80180,
        },
        {
            subDistrict: 'เคร็ง',
            district: 'ชะอวด',
            province: 'นครศรีธรรมราช',
            zipcode: 80180,
        },
        {
            subDistrict: 'ช้างกลาง',
            district: 'ช้างกลาง',
            province: 'นครศรีธรรมราช',
            zipcode: 80250,
        },
        {
            subDistrict: 'สวนขัน',
            district: 'ช้างกลาง',
            province: 'นครศรีธรรมราช',
            zipcode: 80250,
        },
        {
            subDistrict: 'หลักช้าง',
            district: 'ช้างกลาง',
            province: 'นครศรีธรรมราช',
            zipcode: 80250,
        },
        {
            subDistrict: 'คลองเส',
            district: 'ถ้ำพรรณรา',
            province: 'นครศรีธรรมราช',
            zipcode: 80260,
        },
        {
            subDistrict: 'ดุสิต',
            district: 'ถ้ำพรรณรา',
            province: 'นครศรีธรรมราช',
            zipcode: 80260,
        },
        {
            subDistrict: 'ถ้ำพรรณรา',
            district: 'ถ้ำพรรณรา',
            province: 'นครศรีธรรมราช',
            zipcode: 80260,
        },
        {
            subDistrict: 'กะปาง',
            district: 'ทุ่งสง',
            province: 'นครศรีธรรมราช',
            zipcode: 80310,
        },
        {
            subDistrict: 'ควนกรด',
            district: 'ทุ่งสง',
            province: 'นครศรีธรรมราช',
            zipcode: 80110,
        },
        {
            subDistrict: 'ชะมาย',
            district: 'ทุ่งสง',
            province: 'นครศรีธรรมราช',
            zipcode: 80110,
        },
        {
            subDistrict: 'ถ้ำใหญ่',
            district: 'ทุ่งสง',
            province: 'นครศรีธรรมราช',
            zipcode: 80110,
        },
        {
            subDistrict: 'ที่วัง',
            district: 'ทุ่งสง',
            province: 'นครศรีธรรมราช',
            zipcode: 80110,
        },
        {
            subDistrict: 'นาหลวงเสน',
            district: 'ทุ่งสง',
            province: 'นครศรีธรรมราช',
            zipcode: 80110,
        },
        {
            subDistrict: 'นาโพธิ์',
            district: 'ทุ่งสง',
            province: 'นครศรีธรรมราช',
            zipcode: 80110,
        },
        {
            subDistrict: 'นาไม้ไผ่',
            district: 'ทุ่งสง',
            province: 'นครศรีธรรมราช',
            zipcode: 80110,
        },
        {
            subDistrict: 'น้ำตก',
            district: 'ทุ่งสง',
            province: 'นครศรีธรรมราช',
            zipcode: 80110,
        },
        {
            subDistrict: 'ปากแพรก',
            district: 'ทุ่งสง',
            province: 'นครศรีธรรมราช',
            zipcode: 80110,
        },
        {
            subDistrict: 'หนองหงส์',
            district: 'ทุ่งสง',
            province: 'นครศรีธรรมราช',
            zipcode: 80110,
        },
        {
            subDistrict: 'เขาขาว',
            district: 'ทุ่งสง',
            province: 'นครศรีธรรมราช',
            zipcode: 80110,
        },
        {
            subDistrict: 'เขาโร',
            district: 'ทุ่งสง',
            province: 'นครศรีธรรมราช',
            zipcode: 80110,
        },
        {
            subDistrict: 'กรุงหยัน',
            district: 'ทุ่งใหญ่',
            province: 'นครศรีธรรมราช',
            zipcode: 80240,
        },
        {
            subDistrict: 'กุแหระ',
            district: 'ทุ่งใหญ่',
            province: 'นครศรีธรรมราช',
            zipcode: 80240,
        },
        {
            subDistrict: 'ทุ่งสัง',
            district: 'ทุ่งใหญ่',
            province: 'นครศรีธรรมราช',
            zipcode: 80240,
        },
        {
            subDistrict: 'ทุ่งใหญ่',
            district: 'ทุ่งใหญ่',
            province: 'นครศรีธรรมราช',
            zipcode: 80240,
        },
        {
            subDistrict: 'ท่ายาง',
            district: 'ทุ่งใหญ่',
            province: 'นครศรีธรรมราช',
            zipcode: 80240,
        },
        {
            subDistrict: 'บางรูป',
            district: 'ทุ่งใหญ่',
            province: 'นครศรีธรรมราช',
            zipcode: 80240,
        },
        {
            subDistrict: 'ปริก',
            district: 'ทุ่งใหญ่',
            province: 'นครศรีธรรมราช',
            zipcode: 80240,
        },
        {
            subDistrict: 'กลาย',
            district: 'ท่าศาลา',
            province: 'นครศรีธรรมราช',
            zipcode: 80160,
        },
        {
            subDistrict: 'ดอนตะโก',
            district: 'ท่าศาลา',
            province: 'นครศรีธรรมราช',
            zipcode: 80160,
        },
        {
            subDistrict: 'ตลิ่งชัน',
            district: 'ท่าศาลา',
            province: 'นครศรีธรรมราช',
            zipcode: 80160,
        },
        {
            subDistrict: 'ท่าขึ้น',
            district: 'ท่าศาลา',
            province: 'นครศรีธรรมราช',
            zipcode: 80160,
        },
        {
            subDistrict: 'ท่าศาลา',
            district: 'ท่าศาลา',
            province: 'นครศรีธรรมราช',
            zipcode: 80160,
        },
        {
            subDistrict: 'สระแก้ว',
            district: 'ท่าศาลา',
            province: 'นครศรีธรรมราช',
            zipcode: 80160,
        },
        {
            subDistrict: 'หัวตะพาน',
            district: 'ท่าศาลา',
            province: 'นครศรีธรรมราช',
            zipcode: 80160,
        },
        {
            subDistrict: 'โพธิ์ทอง',
            district: 'ท่าศาลา',
            province: 'นครศรีธรรมราช',
            zipcode: 80160,
        },
        {
            subDistrict: 'โมคลาน',
            district: 'ท่าศาลา',
            province: 'นครศรีธรรมราช',
            zipcode: 80160,
        },
        {
            subDistrict: 'ไทยบุรี',
            district: 'ท่าศาลา',
            province: 'นครศรีธรรมราช',
            zipcode: 80160,
        },
        {
            subDistrict: 'กรุงชิง',
            district: 'นบพิตำ',
            province: 'นครศรีธรรมราช',
            zipcode: 80160,
        },
        {
            subDistrict: 'กะหรอ',
            district: 'นบพิตำ',
            province: 'นครศรีธรรมราช',
            zipcode: 80160,
        },
        {
            subDistrict: 'นบพิตำ',
            district: 'นบพิตำ',
            province: 'นครศรีธรรมราช',
            zipcode: 80160,
        },
        {
            subDistrict: 'นาเหรง',
            district: 'นบพิตำ',
            province: 'นครศรีธรรมราช',
            zipcode: 80160,
        },
        {
            subDistrict: 'ทุ่งสง',
            district: 'นาบอน',
            province: 'นครศรีธรรมราช',
            zipcode: 80220,
        },
        {
            subDistrict: 'นาบอน',
            district: 'นาบอน',
            province: 'นครศรีธรรมราช',
            zipcode: 80220,
        },
        {
            subDistrict: 'แก้วแสน',
            district: 'นาบอน',
            province: 'นครศรีธรรมราช',
            zipcode: 80220,
        },
        {
            subDistrict: 'บางขัน',
            district: 'บางขัน',
            province: 'นครศรีธรรมราช',
            zipcode: 80360,
        },
        {
            subDistrict: 'บ้านนิคม',
            district: 'บางขัน',
            province: 'นครศรีธรรมราช',
            zipcode: 80360,
        },
        {
            subDistrict: 'บ้านลำนาว',
            district: 'บางขัน',
            province: 'นครศรีธรรมราช',
            zipcode: 80360,
        },
        {
            subDistrict: 'วังหิน',
            district: 'บางขัน',
            province: 'นครศรีธรรมราช',
            zipcode: 80360,
        },
        {
            subDistrict: 'ขนาบนาก',
            district: 'ปากพนัง',
            province: 'นครศรีธรรมราช',
            zipcode: 80140,
        },
        {
            subDistrict: 'คลองกระบือ',
            district: 'ปากพนัง',
            province: 'นครศรีธรรมราช',
            zipcode: 80370,
        },
        {
            subDistrict: 'คลองน้อย',
            district: 'ปากพนัง',
            province: 'นครศรีธรรมราช',
            zipcode: 80330,
        },
        {
            subDistrict: 'ชะเมา',
            district: 'ปากพนัง',
            province: 'นครศรีธรรมราช',
            zipcode: 80330,
        },
        {
            subDistrict: 'ท่าพยา',
            district: 'ปากพนัง',
            province: 'นครศรีธรรมราช',
            zipcode: 80140,
        },
        {
            subDistrict: 'บางตะพง',
            district: 'ปากพนัง',
            province: 'นครศรีธรรมราช',
            zipcode: 80370,
        },
        {
            subDistrict: 'บางพระ',
            district: 'ปากพนัง',
            province: 'นครศรีธรรมราช',
            zipcode: 80140,
        },
        {
            subDistrict: 'บางศาลา',
            district: 'ปากพนัง',
            province: 'นครศรีธรรมราช',
            zipcode: 80370,
        },
        {
            subDistrict: 'บ้านเพิง',
            district: 'ปากพนัง',
            province: 'นครศรีธรรมราช',
            zipcode: 80140,
        },
        {
            subDistrict: 'บ้านใหม่',
            district: 'ปากพนัง',
            province: 'นครศรีธรรมราช',
            zipcode: 80370,
        },
        {
            subDistrict: 'ปากพนัง',
            district: 'ปากพนัง',
            province: 'นครศรีธรรมราช',
            zipcode: 80140,
        },
        {
            subDistrict: 'ปากพนังฝั่งตะวันตก',
            district: 'ปากพนัง',
            province: 'นครศรีธรรมราช',
            zipcode: 80370,
        },
        {
            subDistrict: 'ปากพนังฝั่งตะวันออก',
            district: 'ปากพนัง',
            province: 'นครศรีธรรมราช',
            zipcode: 80140,
        },
        {
            subDistrict: 'ปากแพรก',
            district: 'ปากพนัง',
            province: 'นครศรีธรรมราช',
            zipcode: 80140,
        },
        {
            subDistrict: 'ป่าระกำ',
            district: 'ปากพนัง',
            province: 'นครศรีธรรมราช',
            zipcode: 80370,
        },
        {
            subDistrict: 'หูล่อง',
            district: 'ปากพนัง',
            province: 'นครศรีธรรมราช',
            zipcode: 80370,
        },
        {
            subDistrict: 'เกาะทวด',
            district: 'ปากพนัง',
            province: 'นครศรีธรรมราช',
            zipcode: 80330,
        },
        {
            subDistrict: 'แหลมตะลุมพุก',
            district: 'ปากพนัง',
            province: 'นครศรีธรรมราช',
            zipcode: 80140,
        },
        {
            subDistrict: 'ทอนหงส์',
            district: 'พรหมคีรี',
            province: 'นครศรีธรรมราช',
            zipcode: 80320,
        },
        {
            subDistrict: 'นาเรียง',
            district: 'พรหมคีรี',
            province: 'นครศรีธรรมราช',
            zipcode: 80320,
        },
        {
            subDistrict: 'บ้านเกาะ',
            district: 'พรหมคีรี',
            province: 'นครศรีธรรมราช',
            zipcode: 80320,
        },
        {
            subDistrict: 'พรหมโลก',
            district: 'พรหมคีรี',
            province: 'นครศรีธรรมราช',
            zipcode: 80320,
        },
        {
            subDistrict: 'อินคีรี',
            district: 'พรหมคีรี',
            province: 'นครศรีธรรมราช',
            zipcode: 80320,
        },
        {
            subDistrict: 'ช้างซ้าย',
            district: 'พระพรหม',
            province: 'นครศรีธรรมราช',
            zipcode: 80000,
        },
        {
            subDistrict: 'ท้ายสำเภา',
            district: 'พระพรหม',
            province: 'นครศรีธรรมราช',
            zipcode: 80000,
        },
        {
            subDistrict: 'นาพรุ',
            district: 'พระพรหม',
            province: 'นครศรีธรรมราช',
            zipcode: 80000,
        },
        {
            subDistrict: 'นาสาร',
            district: 'พระพรหม',
            province: 'นครศรีธรรมราช',
            zipcode: 80000,
        },
        {
            subDistrict: 'กะทูน',
            district: 'พิปูน',
            province: 'นครศรีธรรมราช',
            zipcode: 80270,
        },
        {
            subDistrict: 'ควนกลาง',
            district: 'พิปูน',
            province: 'นครศรีธรรมราช',
            zipcode: 80270,
        },
        {
            subDistrict: 'พิปูน',
            district: 'พิปูน',
            province: 'นครศรีธรรมราช',
            zipcode: 80270,
        },
        {
            subDistrict: 'ยางค้อม',
            district: 'พิปูน',
            province: 'นครศรีธรรมราช',
            zipcode: 80270,
        },
        {
            subDistrict: 'เขาพระ',
            district: 'พิปูน',
            province: 'นครศรีธรรมราช',
            zipcode: 80270,
        },
        {
            subDistrict: 'ควนชุม',
            district: 'ร่อนพิบูลย์',
            province: 'นครศรีธรรมราช',
            zipcode: 80130,
        },
        {
            subDistrict: 'ควนพัง',
            district: 'ร่อนพิบูลย์',
            province: 'นครศรีธรรมราช',
            zipcode: 80130,
        },
        {
            subDistrict: 'ควนเกย',
            district: 'ร่อนพิบูลย์',
            province: 'นครศรีธรรมราช',
            zipcode: 80130,
        },
        {
            subDistrict: 'ร่อนพิบูลย์',
            district: 'ร่อนพิบูลย์',
            province: 'นครศรีธรรมราช',
            zipcode: 80130,
        },
        {
            subDistrict: 'หินตก',
            district: 'ร่อนพิบูลย์',
            province: 'นครศรีธรรมราช',
            zipcode: 80350,
        },
        {
            subDistrict: 'เสาธง',
            district: 'ร่อนพิบูลย์',
            province: 'นครศรีธรรมราช',
            zipcode: 80350,
        },
        {
            subDistrict: 'กำโลน',
            district: 'ลานสกา',
            province: 'นครศรีธรรมราช',
            zipcode: 80230,
        },
        {
            subDistrict: 'ขุนทะเล',
            district: 'ลานสกา',
            province: 'นครศรีธรรมราช',
            zipcode: 80230,
        },
        {
            subDistrict: 'ท่าดี',
            district: 'ลานสกา',
            province: 'นครศรีธรรมราช',
            zipcode: 80230,
        },
        {
            subDistrict: 'ลานสกา',
            district: 'ลานสกา',
            province: 'นครศรีธรรมราช',
            zipcode: 80230,
        },
        {
            subDistrict: 'เขาแก้ว',
            district: 'ลานสกา',
            province: 'นครศรีธรรมราช',
            zipcode: 80230,
        },
        {
            subDistrict: 'ฉลอง',
            district: 'สิชล',
            province: 'นครศรีธรรมราช',
            zipcode: 80120,
        },
        {
            subDistrict: 'ทุ่งปรัง',
            district: 'สิชล',
            province: 'นครศรีธรรมราช',
            zipcode: 80120,
        },
        {
            subDistrict: 'ทุ่งใส',
            district: 'สิชล',
            province: 'นครศรีธรรมราช',
            zipcode: 80120,
        },
        {
            subDistrict: 'สิชล',
            district: 'สิชล',
            province: 'นครศรีธรรมราช',
            zipcode: 80120,
        },
        {
            subDistrict: 'สี่ขีด',
            district: 'สิชล',
            province: 'นครศรีธรรมราช',
            zipcode: 80120,
        },
        {
            subDistrict: 'เขาน้อย',
            district: 'สิชล',
            province: 'นครศรีธรรมราช',
            zipcode: 80120,
        },
        {
            subDistrict: 'เทพราช',
            district: 'สิชล',
            province: 'นครศรีธรรมราช',
            zipcode: 80340,
        },
        {
            subDistrict: 'เปลี่ยน',
            district: 'สิชล',
            province: 'นครศรีธรรมราช',
            zipcode: 80120,
        },
        {
            subDistrict: 'เสาเภา',
            district: 'สิชล',
            province: 'นครศรีธรรมราช',
            zipcode: 80340,
        },
        {
            subDistrict: 'ควนชะลิก',
            district: 'หัวไทร',
            province: 'นครศรีธรรมราช',
            zipcode: 80170,
        },
        {
            subDistrict: 'ทรายขาว',
            district: 'หัวไทร',
            province: 'นครศรีธรรมราช',
            zipcode: 80170,
        },
        {
            subDistrict: 'ท่าซอม',
            district: 'หัวไทร',
            province: 'นครศรีธรรมราช',
            zipcode: 80170,
        },
        {
            subDistrict: 'บางนบ',
            district: 'หัวไทร',
            province: 'นครศรีธรรมราช',
            zipcode: 80170,
        },
        {
            subDistrict: 'บ้านราม',
            district: 'หัวไทร',
            province: 'นครศรีธรรมราช',
            zipcode: 80170,
        },
        {
            subDistrict: 'รามแก้ว',
            district: 'หัวไทร',
            province: 'นครศรีธรรมราช',
            zipcode: 80170,
        },
        {
            subDistrict: 'หน้าสตน',
            district: 'หัวไทร',
            province: 'นครศรีธรรมราช',
            zipcode: 80170,
        },
        {
            subDistrict: 'หัวไทร',
            district: 'หัวไทร',
            province: 'นครศรีธรรมราช',
            zipcode: 80170,
        },
        {
            subDistrict: 'เกาะเพชร',
            district: 'หัวไทร',
            province: 'นครศรีธรรมราช',
            zipcode: 80170,
        },
        {
            subDistrict: 'เขาพังไกร',
            district: 'หัวไทร',
            province: 'นครศรีธรรมราช',
            zipcode: 80170,
        },
        {
            subDistrict: 'แหลม',
            district: 'หัวไทร',
            province: 'นครศรีธรรมราช',
            zipcode: 80170,
        },
        {
            subDistrict: 'ดอนตรอ',
            district: 'เฉลิมพระเกียรติ',
            province: 'นครศรีธรรมราช',
            zipcode: 80290,
        },
        {
            subDistrict: 'ทางพูน',
            district: 'เฉลิมพระเกียรติ',
            province: 'นครศรีธรรมราช',
            zipcode: 80290,
        },
        {
            subDistrict: 'สวนหลวง',
            district: 'เฉลิมพระเกียรติ',
            province: 'นครศรีธรรมราช',
            zipcode: 80190,
        },
        {
            subDistrict: 'เชียรเขา',
            district: 'เฉลิมพระเกียรติ',
            province: 'นครศรีธรรมราช',
            zipcode: 80190,
        },
        {
            subDistrict: 'การะเกด',
            district: 'เชียรใหญ่',
            province: 'นครศรีธรรมราช',
            zipcode: 80190,
        },
        {
            subDistrict: 'ท่าขนาน',
            district: 'เชียรใหญ่',
            province: 'นครศรีธรรมราช',
            zipcode: 80190,
        },
        {
            subDistrict: 'ท้องลำเจียก',
            district: 'เชียรใหญ่',
            province: 'นครศรีธรรมราช',
            zipcode: 80190,
        },
        {
            subDistrict: 'บ้านกลาง',
            district: 'เชียรใหญ่',
            province: 'นครศรีธรรมราช',
            zipcode: 80190,
        },
        {
            subDistrict: 'บ้านเนิน',
            district: 'เชียรใหญ่',
            province: 'นครศรีธรรมราช',
            zipcode: 80190,
        },
        {
            subDistrict: 'เขาพระบาท',
            district: 'เชียรใหญ่',
            province: 'นครศรีธรรมราช',
            zipcode: 80190,
        },
        {
            subDistrict: 'เชียรใหญ่',
            district: 'เชียรใหญ่',
            province: 'นครศรีธรรมราช',
            zipcode: 80190,
        },
        {
            subDistrict: 'เสือหึง',
            district: 'เชียรใหญ่',
            province: 'นครศรีธรรมราช',
            zipcode: 80190,
        },
        {
            subDistrict: 'แม่เจ้าอยู่หัว',
            district: 'เชียรใหญ่',
            province: 'นครศรีธรรมราช',
            zipcode: 80190,
        },
        {
            subDistrict: 'ไสหมาก',
            district: 'เชียรใหญ่',
            province: 'นครศรีธรรมราช',
            zipcode: 80190,
        },
        {
            subDistrict: 'กำแพงเซา',
            district: 'เมืองนครศรีธรรมราช',
            province: 'นครศรีธรรมราช',
            zipcode: 80280,
        },
        {
            subDistrict: 'คลัง',
            district: 'เมืองนครศรีธรรมราช',
            province: 'นครศรีธรรมราช',
            zipcode: 80000,
        },
        {
            subDistrict: 'ท่างิ้ว',
            district: 'เมืองนครศรีธรรมราช',
            province: 'นครศรีธรรมราช',
            zipcode: 80280,
        },
        {
            subDistrict: 'ท่าซัก',
            district: 'เมืองนครศรีธรรมราช',
            province: 'นครศรีธรรมราช',
            zipcode: 80000,
        },
        {
            subDistrict: 'ท่าวัง',
            district: 'เมืองนครศรีธรรมราช',
            province: 'นครศรีธรรมราช',
            zipcode: 80000,
        },
        {
            subDistrict: 'ท่าเรือ',
            district: 'เมืองนครศรีธรรมราช',
            province: 'นครศรีธรรมราช',
            zipcode: 80000,
        },
        {
            subDistrict: 'ท่าไร่',
            district: 'เมืองนครศรีธรรมราช',
            province: 'นครศรีธรรมราช',
            zipcode: 80000,
        },
        {
            subDistrict: 'นาทราย',
            district: 'เมืองนครศรีธรรมราช',
            province: 'นครศรีธรรมราช',
            zipcode: 80280,
        },
        {
            subDistrict: 'นาเคียน',
            district: 'เมืองนครศรีธรรมราช',
            province: 'นครศรีธรรมราช',
            zipcode: 80000,
        },
        {
            subDistrict: 'บางจาก',
            district: 'เมืองนครศรีธรรมราช',
            province: 'นครศรีธรรมราช',
            zipcode: 80330,
        },
        {
            subDistrict: 'ปากนคร',
            district: 'เมืองนครศรีธรรมราช',
            province: 'นครศรีธรรมราช',
            zipcode: 80000,
        },
        {
            subDistrict: 'ปากพูน',
            district: 'เมืองนครศรีธรรมราช',
            province: 'นครศรีธรรมราช',
            zipcode: 80000,
        },
        {
            subDistrict: 'มะม่วงสองต้น',
            district: 'เมืองนครศรีธรรมราช',
            province: 'นครศรีธรรมราช',
            zipcode: 80000,
        },
        {
            subDistrict: 'โพธิ์เสด็จ',
            district: 'เมืองนครศรีธรรมราช',
            province: 'นครศรีธรรมราช',
            zipcode: 80000,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'เมืองนครศรีธรรมราช',
            province: 'นครศรีธรรมราช',
            zipcode: 80000,
        },
        {
            subDistrict: 'ไชยมนตรี',
            district: 'เมืองนครศรีธรรมราช',
            province: 'นครศรีธรรมราช',
            zipcode: 80000,
        },
        {
            subDistrict: 'ชุมตาบง',
            district: 'ชุมตาบง',
            province: 'นครสวรรค์',
            zipcode: 60150,
        },
        {
            subDistrict: 'ปางสวรรค์',
            district: 'ชุมตาบง',
            province: 'นครสวรรค์',
            zipcode: 60150,
        },
        {
            subDistrict: 'ฆะมัง',
            district: 'ชุมแสง',
            province: 'นครสวรรค์',
            zipcode: 60120,
        },
        {
            subDistrict: 'ชุมแสง',
            district: 'ชุมแสง',
            province: 'นครสวรรค์',
            zipcode: 60120,
        },
        {
            subDistrict: 'ทับกฤช',
            district: 'ชุมแสง',
            province: 'นครสวรรค์',
            zipcode: 60250,
        },
        {
            subDistrict: 'ทับกฤชใต้',
            district: 'ชุมแสง',
            province: 'นครสวรรค์',
            zipcode: 60250,
        },
        {
            subDistrict: 'ท่าไม้',
            district: 'ชุมแสง',
            province: 'นครสวรรค์',
            zipcode: 60120,
        },
        {
            subDistrict: 'บางเคียน',
            district: 'ชุมแสง',
            province: 'นครสวรรค์',
            zipcode: 60120,
        },
        {
            subDistrict: 'พันลาน',
            district: 'ชุมแสง',
            province: 'นครสวรรค์',
            zipcode: 60250,
        },
        {
            subDistrict: 'พิกุล',
            district: 'ชุมแสง',
            province: 'นครสวรรค์',
            zipcode: 60120,
        },
        {
            subDistrict: 'หนองกระเจา',
            district: 'ชุมแสง',
            province: 'นครสวรรค์',
            zipcode: 60120,
        },
        {
            subDistrict: 'เกยไชย',
            district: 'ชุมแสง',
            province: 'นครสวรรค์',
            zipcode: 60120,
        },
        {
            subDistrict: 'โคกหม้อ',
            district: 'ชุมแสง',
            province: 'นครสวรรค์',
            zipcode: 60120,
        },
        {
            subDistrict: 'ไผ่สิงห์',
            district: 'ชุมแสง',
            province: 'นครสวรรค์',
            zipcode: 60120,
        },
        {
            subDistrict: 'ตากฟ้า',
            district: 'ตากฟ้า',
            province: 'นครสวรรค์',
            zipcode: 60190,
        },
        {
            subDistrict: 'พุนกยูง',
            district: 'ตากฟ้า',
            province: 'นครสวรรค์',
            zipcode: 60190,
        },
        {
            subDistrict: 'ลำพยนต์',
            district: 'ตากฟ้า',
            province: 'นครสวรรค์',
            zipcode: 60190,
        },
        {
            subDistrict: 'สุขสำราญ',
            district: 'ตากฟ้า',
            province: 'นครสวรรค์',
            zipcode: 60190,
        },
        {
            subDistrict: 'หนองพิกุล',
            district: 'ตากฟ้า',
            province: 'นครสวรรค์',
            zipcode: 60190,
        },
        {
            subDistrict: 'อุดมธัญญา',
            district: 'ตากฟ้า',
            province: 'นครสวรรค์',
            zipcode: 60190,
        },
        {
            subDistrict: 'เขาชายธง',
            district: 'ตากฟ้า',
            province: 'นครสวรรค์',
            zipcode: 60190,
        },
        {
            subDistrict: 'จันเสน',
            district: 'ตาคลี',
            province: 'นครสวรรค์',
            zipcode: 60260,
        },
        {
            subDistrict: 'ช่องแค',
            district: 'ตาคลี',
            province: 'นครสวรรค์',
            zipcode: 60210,
        },
        {
            subDistrict: 'ตาคลี',
            district: 'ตาคลี',
            province: 'นครสวรรค์',
            zipcode: 60140,
        },
        {
            subDistrict: 'พรหมนิมิต',
            district: 'ตาคลี',
            province: 'นครสวรรค์',
            zipcode: 60210,
        },
        {
            subDistrict: 'ลาดทิพรส',
            district: 'ตาคลี',
            province: 'นครสวรรค์',
            zipcode: 60260,
        },
        {
            subDistrict: 'สร้อยทอง',
            district: 'ตาคลี',
            province: 'นครสวรรค์',
            zipcode: 60210,
        },
        {
            subDistrict: 'หนองหม้อ',
            district: 'ตาคลี',
            province: 'นครสวรรค์',
            zipcode: 60140,
        },
        {
            subDistrict: 'หนองโพ',
            district: 'ตาคลี',
            province: 'นครสวรรค์',
            zipcode: 60140,
        },
        {
            subDistrict: 'หัวหวาย',
            district: 'ตาคลี',
            province: 'นครสวรรค์',
            zipcode: 60140,
        },
        {
            subDistrict: 'ห้วยหอม',
            district: 'ตาคลี',
            province: 'นครสวรรค์',
            zipcode: 60210,
        },
        {
            subDistrict: 'ดอนคา',
            district: 'ท่าตะโก',
            province: 'นครสวรรค์',
            zipcode: 60160,
        },
        {
            subDistrict: 'ทำนบ',
            district: 'ท่าตะโก',
            province: 'นครสวรรค์',
            zipcode: 60160,
        },
        {
            subDistrict: 'ท่าตะโก',
            district: 'ท่าตะโก',
            province: 'นครสวรรค์',
            zipcode: 60160,
        },
        {
            subDistrict: 'พนมรอก',
            district: 'ท่าตะโก',
            province: 'นครสวรรค์',
            zipcode: 60160,
        },
        {
            subDistrict: 'พนมเศษ',
            district: 'ท่าตะโก',
            province: 'นครสวรรค์',
            zipcode: 60160,
        },
        {
            subDistrict: 'วังมหากร',
            district: 'ท่าตะโก',
            province: 'นครสวรรค์',
            zipcode: 60160,
        },
        {
            subDistrict: 'วังใหญ่',
            district: 'ท่าตะโก',
            province: 'นครสวรรค์',
            zipcode: 60160,
        },
        {
            subDistrict: 'สายลำโพง',
            district: 'ท่าตะโก',
            province: 'นครสวรรค์',
            zipcode: 60160,
        },
        {
            subDistrict: 'หนองหลวง',
            district: 'ท่าตะโก',
            province: 'นครสวรรค์',
            zipcode: 60160,
        },
        {
            subDistrict: 'หัวถนน',
            district: 'ท่าตะโก',
            province: 'นครสวรรค์',
            zipcode: 60160,
        },
        {
            subDistrict: 'ด่านช้าง',
            district: 'บรรพตพิสัย',
            province: 'นครสวรรค์',
            zipcode: 60180,
        },
        {
            subDistrict: 'ตาขีด',
            district: 'บรรพตพิสัย',
            province: 'นครสวรรค์',
            zipcode: 60180,
        },
        {
            subDistrict: 'ตาสัง',
            district: 'บรรพตพิสัย',
            province: 'นครสวรรค์',
            zipcode: 60180,
        },
        {
            subDistrict: 'ท่างิ้ว',
            district: 'บรรพตพิสัย',
            province: 'นครสวรรค์',
            zipcode: 60180,
        },
        {
            subDistrict: 'บางตาหงาย',
            district: 'บรรพตพิสัย',
            province: 'นครสวรรค์',
            zipcode: 60180,
        },
        {
            subDistrict: 'บางแก้ว',
            district: 'บรรพตพิสัย',
            province: 'นครสวรรค์',
            zipcode: 60180,
        },
        {
            subDistrict: 'บึงปลาทู',
            district: 'บรรพตพิสัย',
            province: 'นครสวรรค์',
            zipcode: 60180,
        },
        {
            subDistrict: 'บ้านแดน',
            district: 'บรรพตพิสัย',
            province: 'นครสวรรค์',
            zipcode: 60180,
        },
        {
            subDistrict: 'หนองกรด',
            district: 'บรรพตพิสัย',
            province: 'นครสวรรค์',
            zipcode: 60180,
        },
        {
            subDistrict: 'หนองตางู',
            district: 'บรรพตพิสัย',
            province: 'นครสวรรค์',
            zipcode: 60180,
        },
        {
            subDistrict: 'หูกวาง',
            district: 'บรรพตพิสัย',
            province: 'นครสวรรค์',
            zipcode: 60180,
        },
        {
            subDistrict: 'อ่างทอง',
            district: 'บรรพตพิสัย',
            province: 'นครสวรรค์',
            zipcode: 60180,
        },
        {
            subDistrict: 'เจริญผล',
            district: 'บรรพตพิสัย',
            province: 'นครสวรรค์',
            zipcode: 60180,
        },
        {
            subDistrict: 'ท่าน้ำอ้อย',
            district: 'พยุหะคีรี',
            province: 'นครสวรรค์',
            zipcode: 60130,
        },
        {
            subDistrict: 'นิคมเขาบ่อแก้ว',
            district: 'พยุหะคีรี',
            province: 'นครสวรรค์',
            zipcode: 60130,
        },
        {
            subDistrict: 'น้ำทรง',
            district: 'พยุหะคีรี',
            province: 'นครสวรรค์',
            zipcode: 60130,
        },
        {
            subDistrict: 'พยุหะ',
            district: 'พยุหะคีรี',
            province: 'นครสวรรค์',
            zipcode: 60130,
        },
        {
            subDistrict: 'ม่วงหัก',
            district: 'พยุหะคีรี',
            province: 'นครสวรรค์',
            zipcode: 60130,
        },
        {
            subDistrict: 'ยางขาว',
            district: 'พยุหะคีรี',
            province: 'นครสวรรค์',
            zipcode: 60130,
        },
        {
            subDistrict: 'ย่านมัทรี',
            district: 'พยุหะคีรี',
            province: 'นครสวรรค์',
            zipcode: 60130,
        },
        {
            subDistrict: 'สระทะเล',
            district: 'พยุหะคีรี',
            province: 'นครสวรรค์',
            zipcode: 60130,
        },
        {
            subDistrict: 'เขากะลา',
            district: 'พยุหะคีรี',
            province: 'นครสวรรค์',
            zipcode: 60130,
        },
        {
            subDistrict: 'เขาทอง',
            district: 'พยุหะคีรี',
            province: 'นครสวรรค์',
            zipcode: 60130,
        },
        {
            subDistrict: 'เนินมะกอก',
            district: 'พยุหะคีรี',
            province: 'นครสวรรค์',
            zipcode: 60130,
        },
        {
            subDistrict: 'บ้านไร่',
            district: 'ลาดยาว',
            province: 'นครสวรรค์',
            zipcode: 60150,
        },
        {
            subDistrict: 'มาบแก',
            district: 'ลาดยาว',
            province: 'นครสวรรค์',
            zipcode: 60150,
        },
        {
            subDistrict: 'ลาดยาว',
            district: 'ลาดยาว',
            province: 'นครสวรรค์',
            zipcode: 60150,
        },
        {
            subDistrict: 'วังม้า',
            district: 'ลาดยาว',
            province: 'นครสวรรค์',
            zipcode: 60150,
        },
        {
            subDistrict: 'วังเมือง',
            district: 'ลาดยาว',
            province: 'นครสวรรค์',
            zipcode: 60150,
        },
        {
            subDistrict: 'ศาลเจ้าไก่ต่อ',
            district: 'ลาดยาว',
            province: 'นครสวรรค์',
            zipcode: 60150,
        },
        {
            subDistrict: 'สระแก้ว',
            district: 'ลาดยาว',
            province: 'นครสวรรค์',
            zipcode: 60150,
        },
        {
            subDistrict: 'สร้อยละคร',
            district: 'ลาดยาว',
            province: 'นครสวรรค์',
            zipcode: 60150,
        },
        {
            subDistrict: 'หนองนมวัว',
            district: 'ลาดยาว',
            province: 'นครสวรรค์',
            zipcode: 60150,
        },
        {
            subDistrict: 'หนองยาว',
            district: 'ลาดยาว',
            province: 'นครสวรรค์',
            zipcode: 60150,
        },
        {
            subDistrict: 'ห้วยน้ำหอม',
            district: 'ลาดยาว',
            province: 'นครสวรรค์',
            zipcode: 60150,
        },
        {
            subDistrict: 'เนินขี้เหล็ก',
            district: 'ลาดยาว',
            province: 'นครสวรรค์',
            zipcode: 60150,
        },
        {
            subDistrict: 'ทุ่งทอง',
            district: 'หนองบัว',
            province: 'นครสวรรค์',
            zipcode: 60110,
        },
        {
            subDistrict: 'ธารทหาร',
            district: 'หนองบัว',
            province: 'นครสวรรค์',
            zipcode: 60110,
        },
        {
            subDistrict: 'วังบ่อ',
            district: 'หนองบัว',
            province: 'นครสวรรค์',
            zipcode: 60110,
        },
        {
            subDistrict: 'หนองกลับ',
            district: 'หนองบัว',
            province: 'นครสวรรค์',
            zipcode: 60110,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'หนองบัว',
            province: 'นครสวรรค์',
            zipcode: 60110,
        },
        {
            subDistrict: 'ห้วยถั่วเหนือ',
            district: 'หนองบัว',
            province: 'นครสวรรค์',
            zipcode: 60110,
        },
        {
            subDistrict: 'ห้วยถั่วใต้',
            district: 'หนองบัว',
            province: 'นครสวรรค์',
            zipcode: 60110,
        },
        {
            subDistrict: 'ห้วยร่วม',
            district: 'หนองบัว',
            province: 'นครสวรรค์',
            zipcode: 60110,
        },
        {
            subDistrict: 'ห้วยใหญ่',
            district: 'หนองบัว',
            province: 'นครสวรรค์',
            zipcode: 60110,
        },
        {
            subDistrict: 'มหาโพธิ',
            district: 'เก้าเลี้ยว',
            province: 'นครสวรรค์',
            zipcode: 60230,
        },
        {
            subDistrict: 'หนองเต่า',
            district: 'เก้าเลี้ยว',
            province: 'นครสวรรค์',
            zipcode: 60230,
        },
        {
            subDistrict: 'หัวดง',
            district: 'เก้าเลี้ยว',
            province: 'นครสวรรค์',
            zipcode: 60230,
        },
        {
            subDistrict: 'เก้าเลี้ยว',
            district: 'เก้าเลี้ยว',
            province: 'นครสวรรค์',
            zipcode: 60230,
        },
        {
            subDistrict: 'เขาดิน',
            district: 'เก้าเลี้ยว',
            province: 'นครสวรรค์',
            zipcode: 60230,
        },
        {
            subDistrict: 'กลางแดด',
            district: 'เมืองนครสวรรค์',
            province: 'นครสวรรค์',
            zipcode: 60000,
        },
        {
            subDistrict: 'ตะเคียนเลื่อน',
            district: 'เมืองนครสวรรค์',
            province: 'นครสวรรค์',
            zipcode: 60000,
        },
        {
            subDistrict: 'นครสวรรค์ตก',
            district: 'เมืองนครสวรรค์',
            province: 'นครสวรรค์',
            zipcode: 60000,
        },
        {
            subDistrict: 'นครสวรรค์ออก',
            district: 'เมืองนครสวรรค์',
            province: 'นครสวรรค์',
            zipcode: 60000,
        },
        {
            subDistrict: 'บางพระหลวง',
            district: 'เมืองนครสวรรค์',
            province: 'นครสวรรค์',
            zipcode: 60000,
        },
        {
            subDistrict: 'บางม่วง',
            district: 'เมืองนครสวรรค์',
            province: 'นครสวรรค์',
            zipcode: 60000,
        },
        {
            subDistrict: 'บึงเสนาท',
            district: 'เมืองนครสวรรค์',
            province: 'นครสวรรค์',
            zipcode: 60000,
        },
        {
            subDistrict: 'บ้านมะเกลือ',
            district: 'เมืองนครสวรรค์',
            province: 'นครสวรรค์',
            zipcode: 60000,
        },
        {
            subDistrict: 'บ้านแก่ง',
            district: 'เมืองนครสวรรค์',
            province: 'นครสวรรค์',
            zipcode: 60000,
        },
        {
            subDistrict: 'ปากน้ำโพ',
            district: 'เมืองนครสวรรค์',
            province: 'นครสวรรค์',
            zipcode: 60000,
        },
        {
            subDistrict: 'พระนอน',
            district: 'เมืองนครสวรรค์',
            province: 'นครสวรรค์',
            zipcode: 60000,
        },
        {
            subDistrict: 'วัดไทรย์',
            district: 'เมืองนครสวรรค์',
            province: 'นครสวรรค์',
            zipcode: 60000,
        },
        {
            subDistrict: 'หนองกรด',
            district: 'เมืองนครสวรรค์',
            province: 'นครสวรรค์',
            zipcode: 60240,
        },
        {
            subDistrict: 'หนองกระโดน',
            district: 'เมืองนครสวรรค์',
            province: 'นครสวรรค์',
            zipcode: 60240,
        },
        {
            subDistrict: 'หนองปลิง',
            district: 'เมืองนครสวรรค์',
            province: 'นครสวรรค์',
            zipcode: 60000,
        },
        {
            subDistrict: 'เกรียงไกร',
            district: 'เมืองนครสวรรค์',
            province: 'นครสวรรค์',
            zipcode: 60000,
        },
        {
            subDistrict: 'แควใหญ่',
            district: 'เมืองนครสวรรค์',
            province: 'นครสวรรค์',
            zipcode: 60000,
        },
        {
            subDistrict: 'วังซ่าน',
            district: 'แม่วงก์',
            province: 'นครสวรรค์',
            zipcode: 60150,
        },
        {
            subDistrict: 'เขาชนกัน',
            district: 'แม่วงก์',
            province: 'นครสวรรค์',
            zipcode: 60150,
        },
        {
            subDistrict: 'แม่วงก์',
            district: 'แม่วงก์',
            province: 'นครสวรรค์',
            zipcode: 60150,
        },
        {
            subDistrict: 'แม่เล่ย์',
            district: 'แม่วงก์',
            province: 'นครสวรรค์',
            zipcode: 60150,
        },
        {
            subDistrict: 'แม่เปิน',
            district: 'แม่เปิน',
            province: 'นครสวรรค์',
            zipcode: 60150,
        },
        {
            subDistrict: 'นากลาง',
            district: 'โกรกพระ',
            province: 'นครสวรรค์',
            zipcode: 60170,
        },
        {
            subDistrict: 'บางประมุง',
            district: 'โกรกพระ',
            province: 'นครสวรรค์',
            zipcode: 60170,
        },
        {
            subDistrict: 'บางมะฝ่อ',
            district: 'โกรกพระ',
            province: 'นครสวรรค์',
            zipcode: 60170,
        },
        {
            subDistrict: 'ยางตาล',
            district: 'โกรกพระ',
            province: 'นครสวรรค์',
            zipcode: 60170,
        },
        {
            subDistrict: 'ศาลาแดง',
            district: 'โกรกพระ',
            province: 'นครสวรรค์',
            zipcode: 60170,
        },
        {
            subDistrict: 'หาดสูง',
            district: 'โกรกพระ',
            province: 'นครสวรรค์',
            zipcode: 60170,
        },
        {
            subDistrict: 'เนินกว้าว',
            district: 'โกรกพระ',
            province: 'นครสวรรค์',
            zipcode: 60170,
        },
        {
            subDistrict: 'เนินศาลา',
            district: 'โกรกพระ',
            province: 'นครสวรรค์',
            zipcode: 60170,
        },
        {
            subDistrict: 'โกรกพระ',
            district: 'โกรกพระ',
            province: 'นครสวรรค์',
            zipcode: 60170,
        },
        {
            subDistrict: 'ตะคร้อ',
            district: 'ไพศาลี',
            province: 'นครสวรรค์',
            zipcode: 60220,
        },
        {
            subDistrict: 'นาขอม',
            district: 'ไพศาลี',
            province: 'นครสวรรค์',
            zipcode: 60220,
        },
        {
            subDistrict: 'วังข่อย',
            district: 'ไพศาลี',
            province: 'นครสวรรค์',
            zipcode: 60220,
        },
        {
            subDistrict: 'วังน้ำลัด',
            district: 'ไพศาลี',
            province: 'นครสวรรค์',
            zipcode: 60220,
        },
        {
            subDistrict: 'สำโรงชัย',
            district: 'ไพศาลี',
            province: 'นครสวรรค์',
            zipcode: 60220,
        },
        {
            subDistrict: 'โคกเดื่อ',
            district: 'ไพศาลี',
            province: 'นครสวรรค์',
            zipcode: 60220,
        },
        {
            subDistrict: 'โพธิ์ประสาท',
            district: 'ไพศาลี',
            province: 'นครสวรรค์',
            zipcode: 60220,
        },
        {
            subDistrict: 'ไพศาลี',
            district: 'ไพศาลี',
            province: 'นครสวรรค์',
            zipcode: 60220,
        },
        {
            subDistrict: 'บางกรวย',
            district: 'บางกรวย',
            province: 'นนทบุรี',
            zipcode: 11130,
        },
        {
            subDistrict: 'บางขนุน',
            district: 'บางกรวย',
            province: 'นนทบุรี',
            zipcode: 11130,
        },
        {
            subDistrict: 'บางขุนกอง',
            district: 'บางกรวย',
            province: 'นนทบุรี',
            zipcode: 11130,
        },
        {
            subDistrict: 'บางคูเวียง',
            district: 'บางกรวย',
            province: 'นนทบุรี',
            zipcode: 11130,
        },
        {
            subDistrict: 'บางสีทอง',
            district: 'บางกรวย',
            province: 'นนทบุรี',
            zipcode: 11130,
        },
        {
            subDistrict: 'ปลายบาง',
            district: 'บางกรวย',
            province: 'นนทบุรี',
            zipcode: 11130,
        },
        {
            subDistrict: 'มหาสวัสดิ์',
            district: 'บางกรวย',
            province: 'นนทบุรี',
            zipcode: 11130,
        },
        {
            subDistrict: 'วัดชลอ',
            district: 'บางกรวย',
            province: 'นนทบุรี',
            zipcode: 11130,
        },
        {
            subDistrict: 'ศาลากลาง',
            district: 'บางกรวย',
            province: 'นนทบุรี',
            zipcode: 11130,
        },
        {
            subDistrict: 'บางคูรัด',
            district: 'บางบัวทอง',
            province: 'นนทบุรี',
            zipcode: 11110,
        },
        {
            subDistrict: 'บางบัวทอง',
            district: 'บางบัวทอง',
            province: 'นนทบุรี',
            zipcode: 11110,
        },
        {
            subDistrict: 'บางรักพัฒนา',
            district: 'บางบัวทอง',
            province: 'นนทบุรี',
            zipcode: 11110,
        },
        {
            subDistrict: 'บางรักใหญ่',
            district: 'บางบัวทอง',
            province: 'นนทบุรี',
            zipcode: 11110,
        },
        {
            subDistrict: 'พิมลราช',
            district: 'บางบัวทอง',
            province: 'นนทบุรี',
            zipcode: 11110,
        },
        {
            subDistrict: 'ละหาร',
            district: 'บางบัวทอง',
            province: 'นนทบุรี',
            zipcode: 11110,
        },
        {
            subDistrict: 'ลำโพ',
            district: 'บางบัวทอง',
            province: 'นนทบุรี',
            zipcode: 11110,
        },
        {
            subDistrict: 'โสนลอย',
            district: 'บางบัวทอง',
            province: 'นนทบุรี',
            zipcode: 11110,
        },
        {
            subDistrict: 'บางม่วง',
            district: 'บางใหญ่',
            province: 'นนทบุรี',
            zipcode: 11140,
        },
        {
            subDistrict: 'บางเลน',
            district: 'บางใหญ่',
            province: 'นนทบุรี',
            zipcode: 11140,
        },
        {
            subDistrict: 'บางแม่นาง',
            district: 'บางใหญ่',
            province: 'นนทบุรี',
            zipcode: 11140,
        },
        {
            subDistrict: 'บางใหญ่',
            district: 'บางใหญ่',
            province: 'นนทบุรี',
            zipcode: 11140,
        },
        {
            subDistrict: 'บ้านใหม่',
            district: 'บางใหญ่',
            province: 'นนทบุรี',
            zipcode: 11140,
        },
        {
            subDistrict: 'เสาธงหิน',
            district: 'บางใหญ่',
            province: 'นนทบุรี',
            zipcode: 11140,
        },
        {
            subDistrict: 'คลองข่อย',
            district: 'ปากเกร็ด',
            province: 'นนทบุรี',
            zipcode: 11120,
        },
        {
            subDistrict: 'คลองพระอุดม',
            district: 'ปากเกร็ด',
            province: 'นนทบุรี',
            zipcode: 11120,
        },
        {
            subDistrict: 'คลองเกลือ',
            district: 'ปากเกร็ด',
            province: 'นนทบุรี',
            zipcode: 11120,
        },
        {
            subDistrict: 'ท่าอิฐ',
            district: 'ปากเกร็ด',
            province: 'นนทบุรี',
            zipcode: 11120,
        },
        {
            subDistrict: 'บางตลาด',
            district: 'ปากเกร็ด',
            province: 'นนทบุรี',
            zipcode: 11120,
        },
        {
            subDistrict: 'บางตะไนย์',
            district: 'ปากเกร็ด',
            province: 'นนทบุรี',
            zipcode: 11120,
        },
        {
            subDistrict: 'บางพลับ',
            district: 'ปากเกร็ด',
            province: 'นนทบุรี',
            zipcode: 11120,
        },
        {
            subDistrict: 'บางพูด',
            district: 'ปากเกร็ด',
            province: 'นนทบุรี',
            zipcode: 11120,
        },
        {
            subDistrict: 'บ้านใหม่',
            district: 'ปากเกร็ด',
            province: 'นนทบุรี',
            zipcode: 11120,
        },
        {
            subDistrict: 'ปากเกร็ด',
            district: 'ปากเกร็ด',
            province: 'นนทบุรี',
            zipcode: 11120,
        },
        {
            subDistrict: 'อ้อมเกร็ด',
            district: 'ปากเกร็ด',
            province: 'นนทบุรี',
            zipcode: 11120,
        },
        {
            subDistrict: 'เกาะเกร็ด',
            district: 'ปากเกร็ด',
            province: 'นนทบุรี',
            zipcode: 11120,
        },
        {
            subDistrict: 'ตลาดขวัญ',
            district: 'เมืองนนทบุรี',
            province: 'นนทบุรี',
            zipcode: 11000,
        },
        {
            subDistrict: 'ท่าทราย',
            district: 'เมืองนนทบุรี',
            province: 'นนทบุรี',
            zipcode: 11000,
        },
        {
            subDistrict: 'บางกระสอ',
            district: 'เมืองนนทบุรี',
            province: 'นนทบุรี',
            zipcode: 11000,
        },
        {
            subDistrict: 'บางกร่าง',
            district: 'เมืองนนทบุรี',
            province: 'นนทบุรี',
            zipcode: 11000,
        },
        {
            subDistrict: 'บางรักน้อย',
            district: 'เมืองนนทบุรี',
            province: 'นนทบุรี',
            zipcode: 11000,
        },
        {
            subDistrict: 'บางศรีเมือง',
            district: 'เมืองนนทบุรี',
            province: 'นนทบุรี',
            zipcode: 11000,
        },
        {
            subDistrict: 'บางเขน',
            district: 'เมืองนนทบุรี',
            province: 'นนทบุรี',
            zipcode: 11000,
        },
        {
            subDistrict: 'บางไผ่',
            district: 'เมืองนนทบุรี',
            province: 'นนทบุรี',
            zipcode: 11000,
        },
        {
            subDistrict: 'สวนใหญ่',
            district: 'เมืองนนทบุรี',
            province: 'นนทบุรี',
            zipcode: 11000,
        },
        {
            subDistrict: 'ไทรม้า',
            district: 'เมืองนนทบุรี',
            province: 'นนทบุรี',
            zipcode: 11000,
        },
        {
            subDistrict: 'ขุนศรี',
            district: 'ไทรน้อย',
            province: 'นนทบุรี',
            zipcode: 11150,
        },
        {
            subDistrict: 'คลองขวาง',
            district: 'ไทรน้อย',
            province: 'นนทบุรี',
            zipcode: 11150,
        },
        {
            subDistrict: 'ทวีวัฒนา',
            district: 'ไทรน้อย',
            province: 'นนทบุรี',
            zipcode: 11150,
        },
        {
            subDistrict: 'ราษฎร์นิยม',
            district: 'ไทรน้อย',
            province: 'นนทบุรี',
            zipcode: 11150,
        },
        {
            subDistrict: 'หนองเพรางาย',
            district: 'ไทรน้อย',
            province: 'นนทบุรี',
            zipcode: 11150,
        },
        {
            subDistrict: 'ไทรน้อย',
            district: 'ไทรน้อย',
            province: 'นนทบุรี',
            zipcode: 11150,
        },
        {
            subDistrict: 'ไทรใหญ่',
            district: 'ไทรน้อย',
            province: 'นนทบุรี',
            zipcode: 11150,
        },
        {
            subDistrict: 'จะแนะ',
            district: 'จะแนะ',
            province: 'นราธิวาส',
            zipcode: 96220,
        },
        {
            subDistrict: 'ช้างเผือก',
            district: 'จะแนะ',
            province: 'นราธิวาส',
            zipcode: 96220,
        },
        {
            subDistrict: 'ดุซงญอ',
            district: 'จะแนะ',
            province: 'นราธิวาส',
            zipcode: 96220,
        },
        {
            subDistrict: 'ผดุงมาตร',
            district: 'จะแนะ',
            province: 'นราธิวาส',
            zipcode: 96220,
        },
        {
            subDistrict: 'นานาค',
            district: 'ตากใบ',
            province: 'นราธิวาส',
            zipcode: 96110,
        },
        {
            subDistrict: 'บางขุนทอง',
            district: 'ตากใบ',
            province: 'นราธิวาส',
            zipcode: 96110,
        },
        {
            subDistrict: 'พร่อน',
            district: 'ตากใบ',
            province: 'นราธิวาส',
            zipcode: 96110,
        },
        {
            subDistrict: 'ศาลาใหม่',
            district: 'ตากใบ',
            province: 'นราธิวาส',
            zipcode: 96110,
        },
        {
            subDistrict: 'เกาะสะท้อน',
            district: 'ตากใบ',
            province: 'นราธิวาส',
            zipcode: 96110,
        },
        {
            subDistrict: 'เจ๊ะเห',
            district: 'ตากใบ',
            province: 'นราธิวาส',
            zipcode: 96110,
        },
        {
            subDistrict: 'โฆษิต',
            district: 'ตากใบ',
            province: 'นราธิวาส',
            zipcode: 96110,
        },
        {
            subDistrict: 'ไพรวัน',
            district: 'ตากใบ',
            province: 'นราธิวาส',
            zipcode: 96110,
        },
        {
            subDistrict: 'กาเยาะมาตี',
            district: 'บาเจาะ',
            province: 'นราธิวาส',
            zipcode: 96170,
        },
        {
            subDistrict: 'บาเจาะ',
            district: 'บาเจาะ',
            province: 'นราธิวาส',
            zipcode: 96170,
        },
        {
            subDistrict: 'บาเระเหนือ',
            district: 'บาเจาะ',
            province: 'นราธิวาส',
            zipcode: 96170,
        },
        {
            subDistrict: 'บาเระใต้',
            district: 'บาเจาะ',
            province: 'นราธิวาส',
            zipcode: 96170,
        },
        {
            subDistrict: 'ปะลุกาสาเมาะ',
            district: 'บาเจาะ',
            province: 'นราธิวาส',
            zipcode: 96170,
        },
        {
            subDistrict: 'ลุโบะสาวอ',
            district: 'บาเจาะ',
            province: 'นราธิวาส',
            zipcode: 96170,
        },
        {
            subDistrict: 'จอเบาะ',
            district: 'ยี่งอ',
            province: 'นราธิวาส',
            zipcode: 96180,
        },
        {
            subDistrict: 'ตะปอเยาะ',
            district: 'ยี่งอ',
            province: 'นราธิวาส',
            zipcode: 96180,
        },
        {
            subDistrict: 'ยี่งอ',
            district: 'ยี่งอ',
            province: 'นราธิวาส',
            zipcode: 96180,
        },
        {
            subDistrict: 'ละหาร',
            district: 'ยี่งอ',
            province: 'นราธิวาส',
            zipcode: 96180,
        },
        {
            subDistrict: 'ลุโบะบายะ',
            district: 'ยี่งอ',
            province: 'นราธิวาส',
            zipcode: 96180,
        },
        {
            subDistrict: 'ลุโบะบือซา',
            district: 'ยี่งอ',
            province: 'นราธิวาส',
            zipcode: 96180,
        },
        {
            subDistrict: 'กาลิซา',
            district: 'ระแงะ',
            province: 'นราธิวาส',
            zipcode: 96130,
        },
        {
            subDistrict: 'ตันหยงมัส',
            district: 'ระแงะ',
            province: 'นราธิวาส',
            zipcode: 96130,
        },
        {
            subDistrict: 'ตันหยงลิมอ',
            district: 'ระแงะ',
            province: 'นราธิวาส',
            zipcode: 96130,
        },
        {
            subDistrict: 'บองอ',
            district: 'ระแงะ',
            province: 'นราธิวาส',
            zipcode: 96220,
        },
        {
            subDistrict: 'บาโงสะโต',
            district: 'ระแงะ',
            province: 'นราธิวาส',
            zipcode: 96130,
        },
        {
            subDistrict: 'มะรือโบตก',
            district: 'ระแงะ',
            province: 'นราธิวาส',
            zipcode: 96130,
        },
        {
            subDistrict: 'เฉลิม',
            district: 'ระแงะ',
            province: 'นราธิวาส',
            zipcode: 96130,
        },
        {
            subDistrict: 'บาตง',
            district: 'รือเสาะ',
            province: 'นราธิวาส',
            zipcode: 96150,
        },
        {
            subDistrict: 'รือเสาะ',
            district: 'รือเสาะ',
            province: 'นราธิวาส',
            zipcode: 96150,
        },
        {
            subDistrict: 'รือเสาะออก',
            district: 'รือเสาะ',
            province: 'นราธิวาส',
            zipcode: 96150,
        },
        {
            subDistrict: 'ลาโละ',
            district: 'รือเสาะ',
            province: 'นราธิวาส',
            zipcode: 96150,
        },
        {
            subDistrict: 'สามัคคี',
            district: 'รือเสาะ',
            province: 'นราธิวาส',
            zipcode: 96150,
        },
        {
            subDistrict: 'สาวอ',
            district: 'รือเสาะ',
            province: 'นราธิวาส',
            zipcode: 96150,
        },
        {
            subDistrict: 'สุวารี',
            district: 'รือเสาะ',
            province: 'นราธิวาส',
            zipcode: 96150,
        },
        {
            subDistrict: 'เรียง',
            district: 'รือเสาะ',
            province: 'นราธิวาส',
            zipcode: 96150,
        },
        {
            subDistrict: 'โคกสะตอ',
            district: 'รือเสาะ',
            province: 'นราธิวาส',
            zipcode: 96150,
        },
        {
            subDistrict: 'กาหลง',
            district: 'ศรีสาคร',
            province: 'นราธิวาส',
            zipcode: 96210,
        },
        {
            subDistrict: 'ซากอ',
            district: 'ศรีสาคร',
            province: 'นราธิวาส',
            zipcode: 96210,
        },
        {
            subDistrict: 'ตะมะยูง',
            district: 'ศรีสาคร',
            province: 'นราธิวาส',
            zipcode: 96210,
        },
        {
            subDistrict: 'ศรีบรรพต',
            district: 'ศรีสาคร',
            province: 'นราธิวาส',
            zipcode: 96210,
        },
        {
            subDistrict: 'ศรีสาคร',
            district: 'ศรีสาคร',
            province: 'นราธิวาส',
            zipcode: 96210,
        },
        {
            subDistrict: 'เชิงคีรี',
            district: 'ศรีสาคร',
            province: 'นราธิวาส',
            zipcode: 96210,
        },
        {
            subDistrict: 'ภูเขาทอง',
            district: 'สุคิริน',
            province: 'นราธิวาส',
            zipcode: 96190,
        },
        {
            subDistrict: 'มาโมง',
            district: 'สุคิริน',
            province: 'นราธิวาส',
            zipcode: 96190,
        },
        {
            subDistrict: 'ร่มไทร',
            district: 'สุคิริน',
            province: 'นราธิวาส',
            zipcode: 96190,
        },
        {
            subDistrict: 'สุคิริน',
            district: 'สุคิริน',
            province: 'นราธิวาส',
            zipcode: 96190,
        },
        {
            subDistrict: 'เกียร์',
            district: 'สุคิริน',
            province: 'นราธิวาส',
            zipcode: 96190,
        },
        {
            subDistrict: 'กาวะ',
            district: 'สุไหงปาดี',
            province: 'นราธิวาส',
            zipcode: 96140,
        },
        {
            subDistrict: 'ปะลุรู',
            district: 'สุไหงปาดี',
            province: 'นราธิวาส',
            zipcode: 96140,
        },
        {
            subDistrict: 'ริโก๋',
            district: 'สุไหงปาดี',
            province: 'นราธิวาส',
            zipcode: 96140,
        },
        {
            subDistrict: 'สากอ',
            district: 'สุไหงปาดี',
            province: 'นราธิวาส',
            zipcode: 96140,
        },
        {
            subDistrict: 'สุไหงปาดี',
            district: 'สุไหงปาดี',
            province: 'นราธิวาส',
            zipcode: 96140,
        },
        {
            subDistrict: 'โต๊ะเด็ง',
            district: 'สุไหงปาดี',
            province: 'นราธิวาส',
            zipcode: 96140,
        },
        {
            subDistrict: 'ปาเสมัส',
            district: 'สุไหงโก-ลก',
            province: 'นราธิวาส',
            zipcode: 96120,
        },
        {
            subDistrict: 'ปูโยะ',
            district: 'สุไหงโก-ลก',
            province: 'นราธิวาส',
            zipcode: 96120,
        },
        {
            subDistrict: 'มูโนะ',
            district: 'สุไหงโก-ลก',
            province: 'นราธิวาส',
            zipcode: 96120,
        },
        {
            subDistrict: 'สุไหงโก-ลก',
            district: 'สุไหงโก-ลก',
            province: 'นราธิวาส',
            zipcode: 96120,
        },
        {
            subDistrict: 'จวบ',
            district: 'เจาะไอร้อง',
            province: 'นราธิวาส',
            zipcode: 96130,
        },
        {
            subDistrict: 'บูกิต',
            district: 'เจาะไอร้อง',
            province: 'นราธิวาส',
            zipcode: 96130,
        },
        {
            subDistrict: 'มะรือโบออก',
            district: 'เจาะไอร้อง',
            province: 'นราธิวาส',
            zipcode: 96130,
        },
        {
            subDistrict: 'กะลุวอ',
            district: 'เมืองนราธิวาส',
            province: 'นราธิวาส',
            zipcode: 96000,
        },
        {
            subDistrict: 'กะลุวอเหนือ',
            district: 'เมืองนราธิวาส',
            province: 'นราธิวาส',
            zipcode: 96000,
        },
        {
            subDistrict: 'บางนาค',
            district: 'เมืองนราธิวาส',
            province: 'นราธิวาส',
            zipcode: 96000,
        },
        {
            subDistrict: 'บางปอ',
            district: 'เมืองนราธิวาส',
            province: 'นราธิวาส',
            zipcode: 96000,
        },
        {
            subDistrict: 'มะนังตายอ',
            district: 'เมืองนราธิวาส',
            province: 'นราธิวาส',
            zipcode: 96000,
        },
        {
            subDistrict: 'ลำภู',
            district: 'เมืองนราธิวาส',
            province: 'นราธิวาส',
            zipcode: 96000,
        },
        {
            subDistrict: 'โคกเคียน',
            district: 'เมืองนราธิวาส',
            province: 'นราธิวาส',
            zipcode: 96000,
        },
        {
            subDistrict: 'กายูคละ',
            district: 'แว้ง',
            province: 'นราธิวาส',
            zipcode: 96160,
        },
        {
            subDistrict: 'ฆอเลาะ',
            district: 'แว้ง',
            province: 'นราธิวาส',
            zipcode: 96160,
        },
        {
            subDistrict: 'เอราวัณ',
            district: 'แว้ง',
            province: 'นราธิวาส',
            zipcode: 96160,
        },
        {
            subDistrict: 'แม่ดง',
            district: 'แว้ง',
            province: 'นราธิวาส',
            zipcode: 96160,
        },
        {
            subDistrict: 'แว้ง',
            district: 'แว้ง',
            province: 'นราธิวาส',
            zipcode: 96160,
        },
        {
            subDistrict: 'โละจูด',
            district: 'แว้ง',
            province: 'นราธิวาส',
            zipcode: 96160,
        },
        {
            subDistrict: 'งอบ',
            district: 'ทุ่งช้าง',
            province: 'น่าน',
            zipcode: 55130,
        },
        {
            subDistrict: 'ทุ่งช้าง',
            district: 'ทุ่งช้าง',
            province: 'น่าน',
            zipcode: 55130,
        },
        {
            subDistrict: 'ปอน',
            district: 'ทุ่งช้าง',
            province: 'น่าน',
            zipcode: 55130,
        },
        {
            subDistrict: 'และ',
            district: 'ทุ่งช้าง',
            province: 'น่าน',
            zipcode: 55130,
        },
        {
            subDistrict: 'จอมพระ',
            district: 'ท่าวังผา',
            province: 'น่าน',
            zipcode: 55140,
        },
        {
            subDistrict: 'ตาลชุม',
            district: 'ท่าวังผา',
            province: 'น่าน',
            zipcode: 55140,
        },
        {
            subDistrict: 'ท่าวังผา',
            district: 'ท่าวังผา',
            province: 'น่าน',
            zipcode: 55140,
        },
        {
            subDistrict: 'ป่าคา',
            district: 'ท่าวังผา',
            province: 'น่าน',
            zipcode: 55140,
        },
        {
            subDistrict: 'ผาตอ',
            district: 'ท่าวังผา',
            province: 'น่าน',
            zipcode: 55140,
        },
        {
            subDistrict: 'ผาทอง',
            district: 'ท่าวังผา',
            province: 'น่าน',
            zipcode: 55140,
        },
        {
            subDistrict: 'ยม',
            district: 'ท่าวังผา',
            province: 'น่าน',
            zipcode: 55140,
        },
        {
            subDistrict: 'ริม',
            district: 'ท่าวังผา',
            province: 'น่าน',
            zipcode: 55140,
        },
        {
            subDistrict: 'ศรีภูมิ',
            district: 'ท่าวังผา',
            province: 'น่าน',
            zipcode: 55140,
        },
        {
            subDistrict: 'แสนทอง',
            district: 'ท่าวังผา',
            province: 'น่าน',
            zipcode: 55140,
        },
        {
            subDistrict: 'นาน้อย',
            district: 'นาน้อย',
            province: 'น่าน',
            zipcode: 55150,
        },
        {
            subDistrict: 'น้ำตก',
            district: 'นาน้อย',
            province: 'น่าน',
            zipcode: 55150,
        },
        {
            subDistrict: 'บัวใหญ่',
            district: 'นาน้อย',
            province: 'น่าน',
            zipcode: 55150,
        },
        {
            subDistrict: 'ศรีษะเกษ',
            district: 'นาน้อย',
            province: 'น่าน',
            zipcode: 55150,
        },
        {
            subDistrict: 'สถาน',
            district: 'นาน้อย',
            province: 'น่าน',
            zipcode: 55150,
        },
        {
            subDistrict: 'สันทะ',
            district: 'นาน้อย',
            province: 'น่าน',
            zipcode: 55150,
        },
        {
            subDistrict: 'เชียงของ',
            district: 'นาน้อย',
            province: 'น่าน',
            zipcode: 55150,
        },
        {
            subDistrict: 'นาทะนุง',
            district: 'นาหมื่น',
            province: 'น่าน',
            zipcode: 55180,
        },
        {
            subDistrict: 'บ่อแก้ว',
            district: 'นาหมื่น',
            province: 'น่าน',
            zipcode: 55180,
        },
        {
            subDistrict: 'ปิงหลวง',
            district: 'นาหมื่น',
            province: 'น่าน',
            zipcode: 55180,
        },
        {
            subDistrict: 'เมืองลี',
            district: 'นาหมื่น',
            province: 'น่าน',
            zipcode: 55180,
        },
        {
            subDistrict: 'ดงพญา',
            district: 'บ่อเกลือ',
            province: 'น่าน',
            zipcode: 55220,
        },
        {
            subDistrict: 'บ่อเกลือเหนือ',
            district: 'บ่อเกลือ',
            province: 'น่าน',
            zipcode: 55220,
        },
        {
            subDistrict: 'บ่อเกลือใต้',
            district: 'บ่อเกลือ',
            province: 'น่าน',
            zipcode: 55220,
        },
        {
            subDistrict: 'ภูฟ้า',
            district: 'บ่อเกลือ',
            province: 'น่าน',
            zipcode: 55220,
        },
        {
            subDistrict: 'บ้านพี้',
            district: 'บ้านหลวง',
            province: 'น่าน',
            zipcode: 55190,
        },
        {
            subDistrict: 'บ้านฟ้า',
            district: 'บ้านหลวง',
            province: 'น่าน',
            zipcode: 55190,
        },
        {
            subDistrict: 'ป่าคาหลวง',
            district: 'บ้านหลวง',
            province: 'น่าน',
            zipcode: 55190,
        },
        {
            subDistrict: 'สวด',
            district: 'บ้านหลวง',
            province: 'น่าน',
            zipcode: 55190,
        },
        {
            subDistrict: 'ปัว',
            district: 'ปัว',
            province: 'น่าน',
            zipcode: 55120,
        },
        {
            subDistrict: 'ป่ากลาง',
            district: 'ปัว',
            province: 'น่าน',
            zipcode: 55120,
        },
        {
            subDistrict: 'ภูคา',
            district: 'ปัว',
            province: 'น่าน',
            zipcode: 55120,
        },
        {
            subDistrict: 'วรนคร',
            district: 'ปัว',
            province: 'น่าน',
            zipcode: 55120,
        },
        {
            subDistrict: 'ศิลาเพชร',
            district: 'ปัว',
            province: 'น่าน',
            zipcode: 55120,
        },
        {
            subDistrict: 'ศิลาแลง',
            district: 'ปัว',
            province: 'น่าน',
            zipcode: 55120,
        },
        {
            subDistrict: 'สกาด',
            district: 'ปัว',
            province: 'น่าน',
            zipcode: 55120,
        },
        {
            subDistrict: 'สถาน',
            district: 'ปัว',
            province: 'น่าน',
            zipcode: 55120,
        },
        {
            subDistrict: 'อวน',
            district: 'ปัว',
            province: 'น่าน',
            zipcode: 55120,
        },
        {
            subDistrict: 'เจดีย์ชัย',
            district: 'ปัว',
            province: 'น่าน',
            zipcode: 55120,
        },
        {
            subDistrict: 'แงง',
            district: 'ปัว',
            province: 'น่าน',
            zipcode: 55120,
        },
        {
            subDistrict: 'ไชยวัฒนา',
            district: 'ปัว',
            province: 'น่าน',
            zipcode: 55120,
        },
        {
            subDistrict: 'ท่าน้าว',
            district: 'ภูเพียง',
            province: 'น่าน',
            zipcode: 55000,
        },
        {
            subDistrict: 'นาปัง',
            district: 'ภูเพียง',
            province: 'น่าน',
            zipcode: 55000,
        },
        {
            subDistrict: 'น้ำเกี๋ยน',
            district: 'ภูเพียง',
            province: 'น่าน',
            zipcode: 55000,
        },
        {
            subDistrict: 'น้ำแก่น',
            district: 'ภูเพียง',
            province: 'น่าน',
            zipcode: 55000,
        },
        {
            subDistrict: 'ฝายแก้ว',
            district: 'ภูเพียง',
            province: 'น่าน',
            zipcode: 55000,
        },
        {
            subDistrict: 'ม่วงตึ๊ด',
            district: 'ภูเพียง',
            province: 'น่าน',
            zipcode: 55000,
        },
        {
            subDistrict: 'เมืองจัง',
            district: 'ภูเพียง',
            province: 'น่าน',
            zipcode: 55000,
        },
        {
            subDistrict: 'ชนแดน',
            district: 'สองแคว',
            province: 'น่าน',
            zipcode: 55160,
        },
        {
            subDistrict: 'นาไร่หลวง',
            district: 'สองแคว',
            province: 'น่าน',
            zipcode: 55160,
        },
        {
            subDistrict: 'ยอด',
            district: 'สองแคว',
            province: 'น่าน',
            zipcode: 55160,
        },
        {
            subDistrict: 'ดู่พงษ์',
            district: 'สันติสุข',
            province: 'น่าน',
            zipcode: 55210,
        },
        {
            subDistrict: 'ป่าแลวหลวง',
            district: 'สันติสุข',
            province: 'น่าน',
            zipcode: 55210,
        },
        {
            subDistrict: 'พงษ์',
            district: 'สันติสุข',
            province: 'น่าน',
            zipcode: 55210,
        },
        {
            subDistrict: 'ขุนน่าน',
            district: 'เฉลิมพระเกียรติ',
            province: 'น่าน',
            zipcode: 55130,
        },
        {
            subDistrict: 'ห้วยโก๋น',
            district: 'เฉลิมพระเกียรติ',
            province: 'น่าน',
            zipcode: 55130,
        },
        {
            subDistrict: 'พญาแก้ว',
            district: 'เชียงกลาง',
            province: 'น่าน',
            zipcode: 55160,
        },
        {
            subDistrict: 'พระธาตุ',
            district: 'เชียงกลาง',
            province: 'น่าน',
            zipcode: 55160,
        },
        {
            subDistrict: 'พระพุทธบาท',
            district: 'เชียงกลาง',
            province: 'น่าน',
            zipcode: 55160,
        },
        {
            subDistrict: 'เชียงกลาง',
            district: 'เชียงกลาง',
            province: 'น่าน',
            zipcode: 55160,
        },
        {
            subDistrict: 'เชียงคาน',
            district: 'เชียงกลาง',
            province: 'น่าน',
            zipcode: 55160,
        },
        {
            subDistrict: 'เปือ',
            district: 'เชียงกลาง',
            province: 'น่าน',
            zipcode: 55160,
        },
        {
            subDistrict: 'กองควาย',
            district: 'เมืองน่าน',
            province: 'น่าน',
            zipcode: 55000,
        },
        {
            subDistrict: 'ดู่ใต้',
            district: 'เมืองน่าน',
            province: 'น่าน',
            zipcode: 55000,
        },
        {
            subDistrict: 'ถืมตอง',
            district: 'เมืองน่าน',
            province: 'น่าน',
            zipcode: 55000,
        },
        {
            subDistrict: 'นาซาว',
            district: 'เมืองน่าน',
            province: 'น่าน',
            zipcode: 55000,
        },
        {
            subDistrict: 'บ่อ',
            district: 'เมืองน่าน',
            province: 'น่าน',
            zipcode: 55000,
        },
        {
            subDistrict: 'ผาสิงห์',
            district: 'เมืองน่าน',
            province: 'น่าน',
            zipcode: 55000,
        },
        {
            subDistrict: 'สวก',
            district: 'เมืองน่าน',
            province: 'น่าน',
            zipcode: 55000,
        },
        {
            subDistrict: 'สะเนียน',
            district: 'เมืองน่าน',
            province: 'น่าน',
            zipcode: 55000,
        },
        {
            subDistrict: 'เรือง',
            district: 'เมืองน่าน',
            province: 'น่าน',
            zipcode: 55000,
        },
        {
            subDistrict: 'ในเวียง',
            district: 'เมืองน่าน',
            province: 'น่าน',
            zipcode: 55000,
        },
        {
            subDistrict: 'ไชยสถาน',
            district: 'เมืองน่าน',
            province: 'น่าน',
            zipcode: 55000,
        },
        {
            subDistrict: 'กลางเวียง',
            district: 'เวียงสา',
            province: 'น่าน',
            zipcode: 55110,
        },
        {
            subDistrict: 'ขึ่ง',
            district: 'เวียงสา',
            province: 'น่าน',
            zipcode: 55110,
        },
        {
            subDistrict: 'จอมจันทร์',
            district: 'เวียงสา',
            province: 'น่าน',
            zipcode: 55110,
        },
        {
            subDistrict: 'ตาลชุม',
            district: 'เวียงสา',
            province: 'น่าน',
            zipcode: 55110,
        },
        {
            subDistrict: 'ทุ่งศรีทอง',
            district: 'เวียงสา',
            province: 'น่าน',
            zipcode: 55110,
        },
        {
            subDistrict: 'นาเหลือง',
            district: 'เวียงสา',
            province: 'น่าน',
            zipcode: 55110,
        },
        {
            subDistrict: 'น้ำปั้ว',
            district: 'เวียงสา',
            province: 'น่าน',
            zipcode: 55110,
        },
        {
            subDistrict: 'น้ำมวบ',
            district: 'เวียงสา',
            province: 'น่าน',
            zipcode: 55110,
        },
        {
            subDistrict: 'ปงสนุก',
            district: 'เวียงสา',
            province: 'น่าน',
            zipcode: 55110,
        },
        {
            subDistrict: 'ยาบหัวนา',
            district: 'เวียงสา',
            province: 'น่าน',
            zipcode: 55110,
        },
        {
            subDistrict: 'ส้าน',
            district: 'เวียงสา',
            province: 'น่าน',
            zipcode: 55110,
        },
        {
            subDistrict: 'ส้านนาหนองใหม่',
            district: 'เวียงสา',
            province: 'น่าน',
            zipcode: 55110,
        },
        {
            subDistrict: 'อ่ายนาไลย',
            district: 'เวียงสา',
            province: 'น่าน',
            zipcode: 55110,
        },
        {
            subDistrict: 'แม่ขะนิง',
            district: 'เวียงสา',
            province: 'น่าน',
            zipcode: 55110,
        },
        {
            subDistrict: 'แม่สา',
            district: 'เวียงสา',
            province: 'น่าน',
            zipcode: 55110,
        },
        {
            subDistrict: 'แม่สาคร',
            district: 'เวียงสา',
            province: 'น่าน',
            zipcode: 55110,
        },
        {
            subDistrict: 'ไหล่น่าน',
            district: 'เวียงสา',
            province: 'น่าน',
            zipcode: 55110,
        },
        {
            subDistrict: 'น้ำปาย',
            district: 'แม่จริม',
            province: 'น่าน',
            zipcode: 55170,
        },
        {
            subDistrict: 'น้ำพาง',
            district: 'แม่จริม',
            province: 'น่าน',
            zipcode: 55170,
        },
        {
            subDistrict: 'หนองแดง',
            district: 'แม่จริม',
            province: 'น่าน',
            zipcode: 55170,
        },
        {
            subDistrict: 'หมอเมือง',
            district: 'แม่จริม',
            province: 'น่าน',
            zipcode: 55170,
        },
        {
            subDistrict: 'แม่จริม',
            district: 'แม่จริม',
            province: 'น่าน',
            zipcode: 55170,
        },
        {
            subDistrict: 'ดงบัง',
            district: 'บึงโขงหลง',
            province: 'บึงกาฬ',
            zipcode: 38220,
        },
        {
            subDistrict: 'ท่าดอกคำ',
            district: 'บึงโขงหลง',
            province: 'บึงกาฬ',
            zipcode: 38220,
        },
        {
            subDistrict: 'บึงโขงหลง',
            district: 'บึงโขงหลง',
            province: 'บึงกาฬ',
            zipcode: 38220,
        },
        {
            subDistrict: 'โพธิ์หมากแข้ง',
            district: 'บึงโขงหลง',
            province: 'บึงกาฬ',
            zipcode: 38220,
        },
        {
            subDistrict: 'บุ่งคล้า',
            district: 'บุ่งคล้า',
            province: 'บึงกาฬ',
            zipcode: 38000,
        },
        {
            subDistrict: 'หนองเดิ่น',
            district: 'บุ่งคล้า',
            province: 'บึงกาฬ',
            zipcode: 38000,
        },
        {
            subDistrict: 'โคกกว้าง',
            district: 'บุ่งคล้า',
            province: 'บึงกาฬ',
            zipcode: 38000,
        },
        {
            subDistrict: 'นากั้ง',
            district: 'ปากคาด',
            province: 'บึงกาฬ',
            zipcode: 38190,
        },
        {
            subDistrict: 'นาดง',
            district: 'ปากคาด',
            province: 'บึงกาฬ',
            zipcode: 38190,
        },
        {
            subDistrict: 'ปากคาด',
            district: 'ปากคาด',
            province: 'บึงกาฬ',
            zipcode: 38190,
        },
        {
            subDistrict: 'สมสนุก',
            district: 'ปากคาด',
            province: 'บึงกาฬ',
            zipcode: 38190,
        },
        {
            subDistrict: 'หนองยอง',
            district: 'ปากคาด',
            province: 'บึงกาฬ',
            zipcode: 38190,
        },
        {
            subDistrict: 'โนนศิลา',
            district: 'ปากคาด',
            province: 'บึงกาฬ',
            zipcode: 38190,
        },
        {
            subDistrict: 'ดอนหญ้านาง',
            district: 'พรเจริญ',
            province: 'บึงกาฬ',
            zipcode: 38180,
        },
        {
            subDistrict: 'ป่าแฝก',
            district: 'พรเจริญ',
            province: 'บึงกาฬ',
            zipcode: 38180,
        },
        {
            subDistrict: 'พรเจริญ',
            district: 'พรเจริญ',
            province: 'บึงกาฬ',
            zipcode: 38180,
        },
        {
            subDistrict: 'วังชมภู',
            district: 'พรเจริญ',
            province: 'บึงกาฬ',
            zipcode: 38180,
        },
        {
            subDistrict: 'ศรีชมภู',
            district: 'พรเจริญ',
            province: 'บึงกาฬ',
            zipcode: 38180,
        },
        {
            subDistrict: 'ศรีสำราญ',
            district: 'พรเจริญ',
            province: 'บึงกาฬ',
            zipcode: 38180,
        },
        {
            subDistrict: 'หนองหัวช้าง',
            district: 'พรเจริญ',
            province: 'บึงกาฬ',
            zipcode: 38180,
        },
        {
            subDistrict: 'ชุมภูพร',
            district: 'ศรีวิไล',
            province: 'บึงกาฬ',
            zipcode: 38210,
        },
        {
            subDistrict: 'นาสะแบง',
            district: 'ศรีวิไล',
            province: 'บึงกาฬ',
            zipcode: 38210,
        },
        {
            subDistrict: 'นาสิงห์',
            district: 'ศรีวิไล',
            province: 'บึงกาฬ',
            zipcode: 38210,
        },
        {
            subDistrict: 'นาแสง',
            district: 'ศรีวิไล',
            province: 'บึงกาฬ',
            zipcode: 38210,
        },
        {
            subDistrict: 'ศรีวิไล',
            district: 'ศรีวิไล',
            province: 'บึงกาฬ',
            zipcode: 38210,
        },
        {
            subDistrict: 'ซาง',
            district: 'เซกา',
            province: 'บึงกาฬ',
            zipcode: 38150,
        },
        {
            subDistrict: 'ท่ากกแดง',
            district: 'เซกา',
            province: 'บึงกาฬ',
            zipcode: 38150,
        },
        {
            subDistrict: 'ท่าสะอาด',
            district: 'เซกา',
            province: 'บึงกาฬ',
            zipcode: 38150,
        },
        {
            subDistrict: 'น้ำจั้น',
            district: 'เซกา',
            province: 'บึงกาฬ',
            zipcode: 38150,
        },
        {
            subDistrict: 'บ้านต้อง',
            district: 'เซกา',
            province: 'บึงกาฬ',
            zipcode: 38150,
        },
        {
            subDistrict: 'ป่งไฮ',
            district: 'เซกา',
            province: 'บึงกาฬ',
            zipcode: 38150,
        },
        {
            subDistrict: 'หนองทุ่ม',
            district: 'เซกา',
            province: 'บึงกาฬ',
            zipcode: 38150,
        },
        {
            subDistrict: 'เซกา',
            district: 'เซกา',
            province: 'บึงกาฬ',
            zipcode: 38150,
        },
        {
            subDistrict: 'โสกก่าม',
            district: 'เซกา',
            province: 'บึงกาฬ',
            zipcode: 38150,
        },
        {
            subDistrict: 'คำนาดี',
            district: 'เมืองบึงกาฬ',
            province: 'บึงกาฬ',
            zipcode: 38000,
        },
        {
            subDistrict: 'ชัยพร',
            district: 'เมืองบึงกาฬ',
            province: 'บึงกาฬ',
            zipcode: 38000,
        },
        {
            subDistrict: 'นาสวรรค์',
            district: 'เมืองบึงกาฬ',
            province: 'บึงกาฬ',
            zipcode: 38000,
        },
        {
            subDistrict: 'บึงกาฬ',
            district: 'เมืองบึงกาฬ',
            province: 'บึงกาฬ',
            zipcode: 38000,
        },
        {
            subDistrict: 'วิศิษฐ์',
            district: 'เมืองบึงกาฬ',
            province: 'บึงกาฬ',
            zipcode: 38000,
        },
        {
            subDistrict: 'โนนสว่าง',
            district: 'เมืองบึงกาฬ',
            province: 'บึงกาฬ',
            zipcode: 38000,
        },
        {
            subDistrict: 'หนองเลิง',
            district: 'เมืองบึงกาฬ',
            province: 'บึงกาฬ',
            zipcode: 38000,
        },
        {
            subDistrict: 'หอคำ',
            district: 'เมืองบึงกาฬ',
            province: 'บึงกาฬ',
            zipcode: 38000,
        },
        {
            subDistrict: 'โคกก่อง',
            district: 'เมืองบึงกาฬ',
            province: 'บึงกาฬ',
            zipcode: 38000,
        },
        {
            subDistrict: 'โนนสมบูรณ์',
            district: 'เมืองบึงกาฬ',
            province: 'บึงกาฬ',
            zipcode: 38000,
        },
        {
            subDistrict: 'โป่งเปือย',
            district: 'เมืองบึงกาฬ',
            province: 'บึงกาฬ',
            zipcode: 38000,
        },
        {
            subDistrict: 'ไคสี',
            district: 'เมืองบึงกาฬ',
            province: 'บึงกาฬ',
            zipcode: 38000,
        },
        {
            subDistrict: 'คำแก้ว',
            district: 'โซ่พิสัย',
            province: 'บึงกาฬ',
            zipcode: 38170,
        },
        {
            subDistrict: 'ถ้ำเจริญ',
            district: 'โซ่พิสัย',
            province: 'บึงกาฬ',
            zipcode: 38170,
        },
        {
            subDistrict: 'บัวตูม',
            district: 'โซ่พิสัย',
            province: 'บึงกาฬ',
            zipcode: 38170,
        },
        {
            subDistrict: 'ศรีชมภู',
            district: 'โซ่พิสัย',
            province: 'บึงกาฬ',
            zipcode: 38170,
        },
        {
            subDistrict: 'หนองพันทา',
            district: 'โซ่พิสัย',
            province: 'บึงกาฬ',
            zipcode: 38170,
        },
        {
            subDistrict: 'เหล่าทอง',
            district: 'โซ่พิสัย',
            province: 'บึงกาฬ',
            zipcode: 38170,
        },
        {
            subDistrict: 'โซ่',
            district: 'โซ่พิสัย',
            province: 'บึงกาฬ',
            zipcode: 38170,
        },
        {
            subDistrict: 'กระสัง',
            district: 'กระสัง',
            province: 'บุรีรัมย์',
            zipcode: 31160,
        },
        {
            subDistrict: 'กันทรารมย์',
            district: 'กระสัง',
            province: 'บุรีรัมย์',
            zipcode: 31160,
        },
        {
            subDistrict: 'ชุมแสง',
            district: 'กระสัง',
            province: 'บุรีรัมย์',
            zipcode: 31160,
        },
        {
            subDistrict: 'บ้านปรือ',
            district: 'กระสัง',
            province: 'บุรีรัมย์',
            zipcode: 31160,
        },
        {
            subDistrict: 'ลำดวน',
            district: 'กระสัง',
            province: 'บุรีรัมย์',
            zipcode: 31160,
        },
        {
            subDistrict: 'ศรีภูมิ',
            district: 'กระสัง',
            province: 'บุรีรัมย์',
            zipcode: 31160,
        },
        {
            subDistrict: 'สองชั้น',
            district: 'กระสัง',
            province: 'บุรีรัมย์',
            zipcode: 31160,
        },
        {
            subDistrict: 'สูงเนิน',
            district: 'กระสัง',
            province: 'บุรีรัมย์',
            zipcode: 31160,
        },
        {
            subDistrict: 'หนองเต็ง',
            district: 'กระสัง',
            province: 'บุรีรัมย์',
            zipcode: 31160,
        },
        {
            subDistrict: 'ห้วยสำราญ',
            district: 'กระสัง',
            province: 'บุรีรัมย์',
            zipcode: 31160,
        },
        {
            subDistrict: 'เมืองไผ่',
            district: 'กระสัง',
            province: 'บุรีรัมย์',
            zipcode: 31160,
        },
        {
            subDistrict: 'คูเมือง',
            district: 'คูเมือง',
            province: 'บุรีรัมย์',
            zipcode: 31190,
        },
        {
            subDistrict: 'ตูมใหญ่',
            district: 'คูเมือง',
            province: 'บุรีรัมย์',
            zipcode: 31190,
        },
        {
            subDistrict: 'บ้านแพ',
            district: 'คูเมือง',
            province: 'บุรีรัมย์',
            zipcode: 31190,
        },
        {
            subDistrict: 'ปะเคียบ',
            district: 'คูเมือง',
            province: 'บุรีรัมย์',
            zipcode: 31190,
        },
        {
            subDistrict: 'พรสำราญ',
            district: 'คูเมือง',
            province: 'บุรีรัมย์',
            zipcode: 31190,
        },
        {
            subDistrict: 'หนองขมาร',
            district: 'คูเมือง',
            province: 'บุรีรัมย์',
            zipcode: 31190,
        },
        {
            subDistrict: 'หินเหล็กไฟ',
            district: 'คูเมือง',
            province: 'บุรีรัมย์',
            zipcode: 31190,
        },
        {
            subDistrict: 'ชำนิ',
            district: 'ชำนิ',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'ช่อผกา',
            district: 'ชำนิ',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'ละลวด',
            district: 'ชำนิ',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'หนองปล่อง',
            district: 'ชำนิ',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'เมืองยาง',
            district: 'ชำนิ',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'โคกสนวน',
            district: 'ชำนิ',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'ก้านเหลือง',
            district: 'นางรอง',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'ชุมแสง',
            district: 'นางรอง',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'ถนนหัก',
            district: 'นางรอง',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'ทรัพย์พระยา',
            district: 'นางรอง',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'ทุ่งแสงทอง',
            district: 'นางรอง',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'นางรอง',
            district: 'นางรอง',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'บ้านสิงห์',
            district: 'นางรอง',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'ลำไทรโยง',
            district: 'นางรอง',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'สะเดา',
            district: 'นางรอง',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'หนองกง',
            district: 'นางรอง',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'หนองยายพิมพ์',
            district: 'นางรอง',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'หนองโบสถ์',
            district: 'นางรอง',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'หนองโสน',
            district: 'นางรอง',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'หนองไทร',
            district: 'นางรอง',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'หัวถนน',
            district: 'นางรอง',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'ดอนกอก',
            district: 'นาโพธิ์',
            province: 'บุรีรัมย์',
            zipcode: 31230,
        },
        {
            subDistrict: 'นาโพธิ์',
            district: 'นาโพธิ์',
            province: 'บุรีรัมย์',
            zipcode: 31230,
        },
        {
            subDistrict: 'บ้านคู',
            district: 'นาโพธิ์',
            province: 'บุรีรัมย์',
            zipcode: 31230,
        },
        {
            subDistrict: 'บ้านดู่',
            district: 'นาโพธิ์',
            province: 'บุรีรัมย์',
            zipcode: 31230,
        },
        {
            subDistrict: 'ศรีสว่าง',
            district: 'นาโพธิ์',
            province: 'บุรีรัมย์',
            zipcode: 31230,
        },
        {
            subDistrict: 'จันทบเพชร',
            district: 'บ้านกรวด',
            province: 'บุรีรัมย์',
            zipcode: 31180,
        },
        {
            subDistrict: 'บึงเจริญ',
            district: 'บ้านกรวด',
            province: 'บุรีรัมย์',
            zipcode: 31180,
        },
        {
            subDistrict: 'บ้านกรวด',
            district: 'บ้านกรวด',
            province: 'บุรีรัมย์',
            zipcode: 31180,
        },
        {
            subDistrict: 'ปราสาท',
            district: 'บ้านกรวด',
            province: 'บุรีรัมย์',
            zipcode: 31180,
        },
        {
            subDistrict: 'สายตะกู',
            district: 'บ้านกรวด',
            province: 'บุรีรัมย์',
            zipcode: 31180,
        },
        {
            subDistrict: 'หนองไม้งาม',
            district: 'บ้านกรวด',
            province: 'บุรีรัมย์',
            zipcode: 31180,
        },
        {
            subDistrict: 'หินลาด',
            district: 'บ้านกรวด',
            province: 'บุรีรัมย์',
            zipcode: 31180,
        },
        {
            subDistrict: 'เขาดินเหนือ',
            district: 'บ้านกรวด',
            province: 'บุรีรัมย์',
            zipcode: 31180,
        },
        {
            subDistrict: 'โนนเจริญ',
            district: 'บ้านกรวด',
            province: 'บุรีรัมย์',
            zipcode: 31180,
        },
        {
            subDistrict: 'บ้านด่าน',
            district: 'บ้านด่าน',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'ปราสาท',
            district: 'บ้านด่าน',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'วังเหนือ',
            district: 'บ้านด่าน',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'โนนขวาง',
            district: 'บ้านด่าน',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'กู่สวนแตง',
            district: 'บ้านใหม่ไชยพจน์',
            province: 'บุรีรัมย์',
            zipcode: 31120,
        },
        {
            subDistrict: 'ทองหลาง',
            district: 'บ้านใหม่ไชยพจน์',
            province: 'บุรีรัมย์',
            zipcode: 31120,
        },
        {
            subDistrict: 'หนองเยือง',
            district: 'บ้านใหม่ไชยพจน์',
            province: 'บุรีรัมย์',
            zipcode: 31120,
        },
        {
            subDistrict: 'หนองแวง',
            district: 'บ้านใหม่ไชยพจน์',
            province: 'บุรีรัมย์',
            zipcode: 31120,
        },
        {
            subDistrict: 'แดงใหญ่',
            district: 'บ้านใหม่ไชยพจน์',
            province: 'บุรีรัมย์',
            zipcode: 31120,
        },
        {
            subDistrict: 'จรเข้มาก',
            district: 'ประโคนชัย',
            province: 'บุรีรัมย์',
            zipcode: 31140,
        },
        {
            subDistrict: 'ตะโกตาพิ',
            district: 'ประโคนชัย',
            province: 'บุรีรัมย์',
            zipcode: 31140,
        },
        {
            subDistrict: 'บ้านไทร',
            district: 'ประโคนชัย',
            province: 'บุรีรัมย์',
            zipcode: 31140,
        },
        {
            subDistrict: 'ประทัดบุ',
            district: 'ประโคนชัย',
            province: 'บุรีรัมย์',
            zipcode: 31140,
        },
        {
            subDistrict: 'ประโคนชัย',
            district: 'ประโคนชัย',
            province: 'บุรีรัมย์',
            zipcode: 31140,
        },
        {
            subDistrict: 'ปังกู',
            district: 'ประโคนชัย',
            province: 'บุรีรัมย์',
            zipcode: 31140,
        },
        {
            subDistrict: 'ละเวี้ย',
            district: 'ประโคนชัย',
            province: 'บุรีรัมย์',
            zipcode: 31140,
        },
        {
            subDistrict: 'สี่เหลี่ยม',
            district: 'ประโคนชัย',
            province: 'บุรีรัมย์',
            zipcode: 31140,
        },
        {
            subDistrict: 'หนองบอน',
            district: 'ประโคนชัย',
            province: 'บุรีรัมย์',
            zipcode: 31140,
        },
        {
            subDistrict: 'เขาคอก',
            district: 'ประโคนชัย',
            province: 'บุรีรัมย์',
            zipcode: 31140,
        },
        {
            subDistrict: 'แสลงโทน',
            district: 'ประโคนชัย',
            province: 'บุรีรัมย์',
            zipcode: 31140,
        },
        {
            subDistrict: 'โคกตูม',
            district: 'ประโคนชัย',
            province: 'บุรีรัมย์',
            zipcode: 31140,
        },
        {
            subDistrict: 'โคกมะขาม',
            district: 'ประโคนชัย',
            province: 'บุรีรัมย์',
            zipcode: 31140,
        },
        {
            subDistrict: 'โคกม้า',
            district: 'ประโคนชัย',
            province: 'บุรีรัมย์',
            zipcode: 31140,
        },
        {
            subDistrict: 'โคกย่าง',
            district: 'ประโคนชัย',
            province: 'บุรีรัมย์',
            zipcode: 31140,
        },
        {
            subDistrict: 'ไพศาล',
            district: 'ประโคนชัย',
            province: 'บุรีรัมย์',
            zipcode: 31140,
        },
        {
            subDistrict: 'ปะคำ',
            district: 'ปะคำ',
            province: 'บุรีรัมย์',
            zipcode: 31220,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'ปะคำ',
            province: 'บุรีรัมย์',
            zipcode: 31220,
        },
        {
            subDistrict: 'หูทำนบ',
            district: 'ปะคำ',
            province: 'บุรีรัมย์',
            zipcode: 31220,
        },
        {
            subDistrict: 'โคกมะม่วง',
            district: 'ปะคำ',
            province: 'บุรีรัมย์',
            zipcode: 31220,
        },
        {
            subDistrict: 'ไทยเจริญ',
            district: 'ปะคำ',
            province: 'บุรีรัมย์',
            zipcode: 31220,
        },
        {
            subDistrict: 'จันดุม',
            district: 'พลับพลาชัย',
            province: 'บุรีรัมย์',
            zipcode: 31250,
        },
        {
            subDistrict: 'ป่าชัน',
            district: 'พลับพลาชัย',
            province: 'บุรีรัมย์',
            zipcode: 31250,
        },
        {
            subDistrict: 'สะเดา',
            district: 'พลับพลาชัย',
            province: 'บุรีรัมย์',
            zipcode: 31250,
        },
        {
            subDistrict: 'สำโรง',
            district: 'พลับพลาชัย',
            province: 'บุรีรัมย์',
            zipcode: 31250,
        },
        {
            subDistrict: 'โคกขมิ้น',
            district: 'พลับพลาชัย',
            province: 'บุรีรัมย์',
            zipcode: 31250,
        },
        {
            subDistrict: 'บ้านจาน',
            district: 'พุทไธสง',
            province: 'บุรีรัมย์',
            zipcode: 31120,
        },
        {
            subDistrict: 'บ้านยาง',
            district: 'พุทไธสง',
            province: 'บุรีรัมย์',
            zipcode: 31120,
        },
        {
            subDistrict: 'บ้านเป้า',
            district: 'พุทไธสง',
            province: 'บุรีรัมย์',
            zipcode: 31120,
        },
        {
            subDistrict: 'บ้านแวง',
            district: 'พุทไธสง',
            province: 'บุรีรัมย์',
            zipcode: 31120,
        },
        {
            subDistrict: 'พุทไธสง',
            district: 'พุทไธสง',
            province: 'บุรีรัมย์',
            zipcode: 31120,
        },
        {
            subDistrict: 'มะเฟือง',
            district: 'พุทไธสง',
            province: 'บุรีรัมย์',
            zipcode: 31120,
        },
        {
            subDistrict: 'หายโศก',
            district: 'พุทไธสง',
            province: 'บุรีรัมย์',
            zipcode: 31120,
        },
        {
            subDistrict: 'ตาจง',
            district: 'ละหานทราย',
            province: 'บุรีรัมย์',
            zipcode: 31170,
        },
        {
            subDistrict: 'ละหานทราย',
            district: 'ละหานทราย',
            province: 'บุรีรัมย์',
            zipcode: 31170,
        },
        {
            subDistrict: 'สำโรงใหม่',
            district: 'ละหานทราย',
            province: 'บุรีรัมย์',
            zipcode: 31170,
        },
        {
            subDistrict: 'หนองตะครอง',
            district: 'ละหานทราย',
            province: 'บุรีรัมย์',
            zipcode: 31170,
        },
        {
            subDistrict: 'หนองแวง',
            district: 'ละหานทราย',
            province: 'บุรีรัมย์',
            zipcode: 31170,
        },
        {
            subDistrict: 'โคกว่าน',
            district: 'ละหานทราย',
            province: 'บุรีรัมย์',
            zipcode: 31170,
        },
        {
            subDistrict: 'ตลาดโพธิ์',
            district: 'ลำปลายมาศ',
            province: 'บุรีรัมย์',
            zipcode: 31130,
        },
        {
            subDistrict: 'ทะเมนชัย',
            district: 'ลำปลายมาศ',
            province: 'บุรีรัมย์',
            zipcode: 31130,
        },
        {
            subDistrict: 'บุโพธิ์',
            district: 'ลำปลายมาศ',
            province: 'บุรีรัมย์',
            zipcode: 31130,
        },
        {
            subDistrict: 'บ้านยาง',
            district: 'ลำปลายมาศ',
            province: 'บุรีรัมย์',
            zipcode: 31130,
        },
        {
            subDistrict: 'ผไทรินทร์',
            district: 'ลำปลายมาศ',
            province: 'บุรีรัมย์',
            zipcode: 31130,
        },
        {
            subDistrict: 'ลำปลายมาศ',
            district: 'ลำปลายมาศ',
            province: 'บุรีรัมย์',
            zipcode: 31130,
        },
        {
            subDistrict: 'หนองกะทิง',
            district: 'ลำปลายมาศ',
            province: 'บุรีรัมย์',
            zipcode: 31130,
        },
        {
            subDistrict: 'หนองคู',
            district: 'ลำปลายมาศ',
            province: 'บุรีรัมย์',
            zipcode: 31130,
        },
        {
            subDistrict: 'หนองบัวโคก',
            district: 'ลำปลายมาศ',
            province: 'บุรีรัมย์',
            zipcode: 31130,
        },
        {
            subDistrict: 'หนองโดน',
            district: 'ลำปลายมาศ',
            province: 'บุรีรัมย์',
            zipcode: 31130,
        },
        {
            subDistrict: 'หินโคน',
            district: 'ลำปลายมาศ',
            province: 'บุรีรัมย์',
            zipcode: 31130,
        },
        {
            subDistrict: 'เมืองแฝก',
            district: 'ลำปลายมาศ',
            province: 'บุรีรัมย์',
            zipcode: 31130,
        },
        {
            subDistrict: 'แสลงพัน',
            district: 'ลำปลายมาศ',
            province: 'บุรีรัมย์',
            zipcode: 31130,
        },
        {
            subDistrict: 'โคกกลาง',
            district: 'ลำปลายมาศ',
            province: 'บุรีรัมย์',
            zipcode: 31130,
        },
        {
            subDistrict: 'โคกล่าม',
            district: 'ลำปลายมาศ',
            province: 'บุรีรัมย์',
            zipcode: 31130,
        },
        {
            subDistrict: 'โคกสะอาด',
            district: 'ลำปลายมาศ',
            province: 'บุรีรัมย์',
            zipcode: 31130,
        },
        {
            subDistrict: 'กระสัง',
            district: 'สตึก',
            province: 'บุรีรัมย์',
            zipcode: 31150,
        },
        {
            subDistrict: 'ชุมแสง',
            district: 'สตึก',
            province: 'บุรีรัมย์',
            zipcode: 31150,
        },
        {
            subDistrict: 'ดอนมนต์',
            district: 'สตึก',
            province: 'บุรีรัมย์',
            zipcode: 31150,
        },
        {
            subDistrict: 'ทุ่งวัง',
            district: 'สตึก',
            province: 'บุรีรัมย์',
            zipcode: 31150,
        },
        {
            subDistrict: 'ท่าม่วง',
            district: 'สตึก',
            province: 'บุรีรัมย์',
            zipcode: 31150,
        },
        {
            subDistrict: 'นิคม',
            district: 'สตึก',
            province: 'บุรีรัมย์',
            zipcode: 31150,
        },
        {
            subDistrict: 'ร่อนทอง',
            district: 'สตึก',
            province: 'บุรีรัมย์',
            zipcode: 31150,
        },
        {
            subDistrict: 'สตึก',
            district: 'สตึก',
            province: 'บุรีรัมย์',
            zipcode: 31150,
        },
        {
            subDistrict: 'สนามชัย',
            district: 'สตึก',
            province: 'บุรีรัมย์',
            zipcode: 31150,
        },
        {
            subDistrict: 'สะแก',
            district: 'สตึก',
            province: 'บุรีรัมย์',
            zipcode: 31150,
        },
        {
            subDistrict: 'หนองใหญ่',
            district: 'สตึก',
            province: 'บุรีรัมย์',
            zipcode: 31150,
        },
        {
            subDistrict: 'เมืองแก',
            district: 'สตึก',
            province: 'บุรีรัมย์',
            zipcode: 31150,
        },
        {
            subDistrict: 'ดอนอะราง',
            district: 'หนองกี่',
            province: 'บุรีรัมย์',
            zipcode: 31210,
        },
        {
            subDistrict: 'ทุ่งกระตาดพัฒนา',
            district: 'หนองกี่',
            province: 'บุรีรัมย์',
            zipcode: 31210,
        },
        {
            subDistrict: 'ทุ่งกระเต็น',
            district: 'หนองกี่',
            province: 'บุรีรัมย์',
            zipcode: 31210,
        },
        {
            subDistrict: 'ท่าโพธิ์ชัย',
            district: 'หนองกี่',
            province: 'บุรีรัมย์',
            zipcode: 31210,
        },
        {
            subDistrict: 'บุกระสัง',
            district: 'หนองกี่',
            province: 'บุรีรัมย์',
            zipcode: 31210,
        },
        {
            subDistrict: 'หนองกี่',
            district: 'หนองกี่',
            province: 'บุรีรัมย์',
            zipcode: 31210,
        },
        {
            subDistrict: 'เมืองไผ่',
            district: 'หนองกี่',
            province: 'บุรีรัมย์',
            zipcode: 31210,
        },
        {
            subDistrict: 'เย้ยปราสาท',
            district: 'หนองกี่',
            province: 'บุรีรัมย์',
            zipcode: 31210,
        },
        {
            subDistrict: 'โคกสว่าง',
            district: 'หนองกี่',
            province: 'บุรีรัมย์',
            zipcode: 31210,
        },
        {
            subDistrict: 'โคกสูง',
            district: 'หนองกี่',
            province: 'บุรีรัมย์',
            zipcode: 31210,
        },
        {
            subDistrict: 'สระทอง',
            district: 'หนองหงส์',
            province: 'บุรีรัมย์',
            zipcode: 31240,
        },
        {
            subDistrict: 'สระแก้ว',
            district: 'หนองหงส์',
            province: 'บุรีรัมย์',
            zipcode: 31240,
        },
        {
            subDistrict: 'หนองชัยศรี',
            district: 'หนองหงส์',
            province: 'บุรีรัมย์',
            zipcode: 31240,
        },
        {
            subDistrict: 'ห้วยหิน',
            district: 'หนองหงส์',
            province: 'บุรีรัมย์',
            zipcode: 31240,
        },
        {
            subDistrict: 'เมืองฝ้าย',
            district: 'หนองหงส์',
            province: 'บุรีรัมย์',
            zipcode: 31240,
        },
        {
            subDistrict: 'เสาเดียว',
            district: 'หนองหงส์',
            province: 'บุรีรัมย์',
            zipcode: 31240,
        },
        {
            subDistrict: 'ไทยสามัคคี',
            district: 'หนองหงส์',
            province: 'บุรีรัมย์',
            zipcode: 31240,
        },
        {
            subDistrict: 'ตาเสา',
            district: 'ห้วยราช',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'บ้านตะโก',
            district: 'ห้วยราช',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'สนวน',
            district: 'ห้วยราช',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'สามแวง',
            district: 'ห้วยราช',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'ห้วยราช',
            district: 'ห้วยราช',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'ห้วยราชา',
            district: 'ห้วยราช',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'เมืองโพธิ์',
            district: 'ห้วยราช',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'โคกเหล็ก',
            district: 'ห้วยราช',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'ตาเป๊ก',
            district: 'เฉลิมพระเกียรติ',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'ถาวร',
            district: 'เฉลิมพระเกียรติ',
            province: 'บุรีรัมย์',
            zipcode: 31170,
        },
        {
            subDistrict: 'ยายแย้มวัฒนา',
            district: 'เฉลิมพระเกียรติ',
            province: 'บุรีรัมย์',
            zipcode: 31170,
        },
        {
            subDistrict: 'อีสานเขต',
            district: 'เฉลิมพระเกียรติ',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'เจริญสุข',
            district: 'เฉลิมพระเกียรติ',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'กระสัง',
            district: 'เมืองบุรีรัมย์',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'กลันทา',
            district: 'เมืองบุรีรัมย์',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'ชุมเห็ด',
            district: 'เมืองบุรีรัมย์',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'ถลุงเหล็ก',
            district: 'เมืองบุรีรัมย์',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'บัวทอง',
            district: 'เมืองบุรีรัมย์',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'บ้านบัว',
            district: 'เมืองบุรีรัมย์',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'บ้านยาง',
            district: 'เมืองบุรีรัมย์',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'พระครู',
            district: 'เมืองบุรีรัมย์',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'ลุมปุ๊ก',
            district: 'เมืองบุรีรัมย์',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'สวายจีก',
            district: 'เมืองบุรีรัมย์',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'สองห้อง',
            district: 'เมืองบุรีรัมย์',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'สะแกซำ',
            district: 'เมืองบุรีรัมย์',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'สะแกโพรง',
            district: 'เมืองบุรีรัมย์',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'หนองตาด',
            district: 'เมืองบุรีรัมย์',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'หลักเขต',
            district: 'เมืองบุรีรัมย์',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'อิสาณ',
            district: 'เมืองบุรีรัมย์',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'เมืองฝาง',
            district: 'เมืองบุรีรัมย์',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'เสม็ด',
            district: 'เมืองบุรีรัมย์',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'เมืองบุรีรัมย์',
            province: 'บุรีรัมย์',
            zipcode: 31000,
        },
        {
            subDistrict: 'ดงพลอง',
            district: 'แคนดง',
            province: 'บุรีรัมย์',
            zipcode: 31150,
        },
        {
            subDistrict: 'สระบัว',
            district: 'แคนดง',
            province: 'บุรีรัมย์',
            zipcode: 31150,
        },
        {
            subDistrict: 'หัวฝาย',
            district: 'แคนดง',
            province: 'บุรีรัมย์',
            zipcode: 31150,
        },
        {
            subDistrict: 'แคนดง',
            district: 'แคนดง',
            province: 'บุรีรัมย์',
            zipcode: 31150,
        },
        {
            subDistrict: 'ลำนางรอง',
            district: 'โนนดินแดง',
            province: 'บุรีรัมย์',
            zipcode: 31260,
        },
        {
            subDistrict: 'ส้มป่อย',
            district: 'โนนดินแดง',
            province: 'บุรีรัมย์',
            zipcode: 31260,
        },
        {
            subDistrict: 'โนนดินแดง',
            district: 'โนนดินแดง',
            province: 'บุรีรัมย์',
            zipcode: 31260,
        },
        {
            subDistrict: 'ดงอีจาน',
            district: 'โนนสุวรรณ',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'ทุ่งจังหัน',
            district: 'โนนสุวรรณ',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'โกรกแก้ว',
            district: 'โนนสุวรรณ',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'โนนสุวรรณ',
            district: 'โนนสุวรรณ',
            province: 'บุรีรัมย์',
            zipcode: 31110,
        },
        {
            subDistrict: 'คลองสอง',
            district: 'คลองหลวง',
            province: 'ปทุมธานี',
            zipcode: 12120,
        },
        {
            subDistrict: 'คลองสาม',
            district: 'คลองหลวง',
            province: 'ปทุมธานี',
            zipcode: 12120,
        },
        {
            subDistrict: 'คลองสี่',
            district: 'คลองหลวง',
            province: 'ปทุมธานี',
            zipcode: 12120,
        },
        {
            subDistrict: 'คลองหก',
            district: 'คลองหลวง',
            province: 'ปทุมธานี',
            zipcode: 12120,
        },
        {
            subDistrict: 'คลองหนึ่ง',
            district: 'คลองหลวง',
            province: 'ปทุมธานี',
            zipcode: 12120,
        },
        {
            subDistrict: 'คลองห้า',
            district: 'คลองหลวง',
            province: 'ปทุมธานี',
            zipcode: 12120,
        },
        {
            subDistrict: 'คลองเจ็ด',
            district: 'คลองหลวง',
            province: 'ปทุมธานี',
            zipcode: 12120,
        },
        {
            subDistrict: 'บึงน้ำรักษ์',
            district: 'ธัญบุรี',
            province: 'ปทุมธานี',
            zipcode: 12110,
        },
        {
            subDistrict: 'บึงยี่โถ',
            district: 'ธัญบุรี',
            province: 'ปทุมธานี',
            zipcode: 12130,
        },
        {
            subDistrict: 'บึงสนั่น',
            district: 'ธัญบุรี',
            province: 'ปทุมธานี',
            zipcode: 12110,
        },
        {
            subDistrict: 'ประชาธิปัตย์',
            district: 'ธัญบุรี',
            province: 'ปทุมธานี',
            zipcode: 12130,
        },
        {
            subDistrict: 'รังสิต',
            district: 'ธัญบุรี',
            province: 'ปทุมธานี',
            zipcode: 12110,
        },
        {
            subDistrict: 'ลำผักกูด',
            district: 'ธัญบุรี',
            province: 'ปทุมธานี',
            zipcode: 12110,
        },
        {
            subDistrict: 'คลองพระอุดม',
            district: 'ลาดหลุมแก้ว',
            province: 'ปทุมธานี',
            zipcode: 12140,
        },
        {
            subDistrict: 'คูขวาง',
            district: 'ลาดหลุมแก้ว',
            province: 'ปทุมธานี',
            zipcode: 12140,
        },
        {
            subDistrict: 'คูบางหลวง',
            district: 'ลาดหลุมแก้ว',
            province: 'ปทุมธานี',
            zipcode: 12140,
        },
        {
            subDistrict: 'บ่อเงิน',
            district: 'ลาดหลุมแก้ว',
            province: 'ปทุมธานี',
            zipcode: 12140,
        },
        {
            subDistrict: 'ระแหง',
            district: 'ลาดหลุมแก้ว',
            province: 'ปทุมธานี',
            zipcode: 12140,
        },
        {
            subDistrict: 'ลาดหลุมแก้ว',
            district: 'ลาดหลุมแก้ว',
            province: 'ปทุมธานี',
            zipcode: 12140,
        },
        {
            subDistrict: 'หน้าไม้',
            district: 'ลาดหลุมแก้ว',
            province: 'ปทุมธานี',
            zipcode: 12140,
        },
        {
            subDistrict: 'คูคต',
            district: 'ลำลูกกา',
            province: 'ปทุมธานี',
            zipcode: 12130,
        },
        {
            subDistrict: 'บึงคอไห',
            district: 'ลำลูกกา',
            province: 'ปทุมธานี',
            zipcode: 12150,
        },
        {
            subDistrict: 'บึงคำพร้อย',
            district: 'ลำลูกกา',
            province: 'ปทุมธานี',
            zipcode: 12150,
        },
        {
            subDistrict: 'บึงทองหลาง',
            district: 'ลำลูกกา',
            province: 'ปทุมธานี',
            zipcode: 12150,
        },
        {
            subDistrict: 'พืชอุดม',
            district: 'ลำลูกกา',
            province: 'ปทุมธานี',
            zipcode: 12150,
        },
        {
            subDistrict: 'ลาดสวาย',
            district: 'ลำลูกกา',
            province: 'ปทุมธานี',
            zipcode: 12150,
        },
        {
            subDistrict: 'ลำลูกกา',
            district: 'ลำลูกกา',
            province: 'ปทุมธานี',
            zipcode: 12150,
        },
        {
            subDistrict: 'ลำไทร',
            district: 'ลำลูกกา',
            province: 'ปทุมธานี',
            zipcode: 12150,
        },
        {
            subDistrict: 'กระแชง',
            district: 'สามโคก',
            province: 'ปทุมธานี',
            zipcode: 12160,
        },
        {
            subDistrict: 'คลองควาย',
            district: 'สามโคก',
            province: 'ปทุมธานี',
            zipcode: 12160,
        },
        {
            subDistrict: 'ท้ายเกาะ',
            district: 'สามโคก',
            province: 'ปทุมธานี',
            zipcode: 12160,
        },
        {
            subDistrict: 'บางกระบือ',
            district: 'สามโคก',
            province: 'ปทุมธานี',
            zipcode: 12160,
        },
        {
            subDistrict: 'บางเตย',
            district: 'สามโคก',
            province: 'ปทุมธานี',
            zipcode: 12160,
        },
        {
            subDistrict: 'บางโพธิ์เหนือ',
            district: 'สามโคก',
            province: 'ปทุมธานี',
            zipcode: 12160,
        },
        {
            subDistrict: 'บ้านงิ้ว',
            district: 'สามโคก',
            province: 'ปทุมธานี',
            zipcode: 12160,
        },
        {
            subDistrict: 'บ้านปทุม',
            district: 'สามโคก',
            province: 'ปทุมธานี',
            zipcode: 12160,
        },
        {
            subDistrict: 'สามโคก',
            district: 'สามโคก',
            province: 'ปทุมธานี',
            zipcode: 12160,
        },
        {
            subDistrict: 'เชียงรากน้อย',
            district: 'สามโคก',
            province: 'ปทุมธานี',
            zipcode: 12160,
        },
        {
            subDistrict: 'เชียงรากใหญ่',
            district: 'สามโคก',
            province: 'ปทุมธานี',
            zipcode: 12160,
        },
        {
            subDistrict: 'นพรัตน์',
            district: 'หนองเสือ',
            province: 'ปทุมธานี',
            zipcode: 12170,
        },
        {
            subDistrict: 'บึงกาสาม',
            district: 'หนองเสือ',
            province: 'ปทุมธานี',
            zipcode: 12170,
        },
        {
            subDistrict: 'บึงชำอ้อ',
            district: 'หนองเสือ',
            province: 'ปทุมธานี',
            zipcode: 12170,
        },
        {
            subDistrict: 'บึงบอน',
            district: 'หนองเสือ',
            province: 'ปทุมธานี',
            zipcode: 12170,
        },
        {
            subDistrict: 'บึงบา',
            district: 'หนองเสือ',
            province: 'ปทุมธานี',
            zipcode: 12170,
        },
        {
            subDistrict: 'ศาลาครุ',
            district: 'หนองเสือ',
            province: 'ปทุมธานี',
            zipcode: 12170,
        },
        {
            subDistrict: 'หนองสามวัง',
            district: 'หนองเสือ',
            province: 'ปทุมธานี',
            zipcode: 12170,
        },
        {
            subDistrict: 'บางกะดี',
            district: 'เมืองปทุมธานี',
            province: 'ปทุมธานี',
            zipcode: 12000,
        },
        {
            subDistrict: 'บางขะแยง',
            district: 'เมืองปทุมธานี',
            province: 'ปทุมธานี',
            zipcode: 12000,
        },
        {
            subDistrict: 'บางคูวัด',
            district: 'เมืองปทุมธานี',
            province: 'ปทุมธานี',
            zipcode: 12000,
        },
        {
            subDistrict: 'บางปรอก',
            district: 'เมืองปทุมธานี',
            province: 'ปทุมธานี',
            zipcode: 12000,
        },
        {
            subDistrict: 'บางพูด',
            district: 'เมืองปทุมธานี',
            province: 'ปทุมธานี',
            zipcode: 12000,
        },
        {
            subDistrict: 'บางพูน',
            district: 'เมืองปทุมธานี',
            province: 'ปทุมธานี',
            zipcode: 12000,
        },
        {
            subDistrict: 'บางหลวง',
            district: 'เมืองปทุมธานี',
            province: 'ปทุมธานี',
            zipcode: 12000,
        },
        {
            subDistrict: 'บางเดื่อ',
            district: 'เมืองปทุมธานี',
            province: 'ปทุมธานี',
            zipcode: 12000,
        },
        {
            subDistrict: 'บ้านกระแชง',
            district: 'เมืองปทุมธานี',
            province: 'ปทุมธานี',
            zipcode: 12000,
        },
        {
            subDistrict: 'บ้านกลาง',
            district: 'เมืองปทุมธานี',
            province: 'ปทุมธานี',
            zipcode: 12000,
        },
        {
            subDistrict: 'บ้านฉาง',
            district: 'เมืองปทุมธานี',
            province: 'ปทุมธานี',
            zipcode: 12000,
        },
        {
            subDistrict: 'บ้านใหม่',
            district: 'เมืองปทุมธานี',
            province: 'ปทุมธานี',
            zipcode: 12000,
        },
        {
            subDistrict: 'สวนพริกไทย',
            district: 'เมืองปทุมธานี',
            province: 'ปทุมธานี',
            zipcode: 12000,
        },
        {
            subDistrict: 'หลักหก',
            district: 'เมืองปทุมธานี',
            province: 'ปทุมธานี',
            zipcode: 12000,
        },
        {
            subDistrict: 'กุยบุรี',
            district: 'กุยบุรี',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77150,
        },
        {
            subDistrict: 'กุยเหนือ',
            district: 'กุยบุรี',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77150,
        },
        {
            subDistrict: 'ดอนยายหนู',
            district: 'กุยบุรี',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77150,
        },
        {
            subDistrict: 'สามกระทาย',
            district: 'กุยบุรี',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77150,
        },
        {
            subDistrict: 'หาดขาม',
            district: 'กุยบุรี',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77150,
        },
        {
            subDistrict: 'เขาแดง',
            district: 'กุยบุรี',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77150,
        },
        {
            subDistrict: 'ทับสะแก',
            district: 'ทับสะแก',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77130,
        },
        {
            subDistrict: 'นาหูกวาง',
            district: 'ทับสะแก',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77130,
        },
        {
            subDistrict: 'ห้วยยาง',
            district: 'ทับสะแก',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77130,
        },
        {
            subDistrict: 'อ่างทอง',
            district: 'ทับสะแก',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77130,
        },
        {
            subDistrict: 'เขาล้าน',
            district: 'ทับสะแก',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77130,
        },
        {
            subDistrict: 'แสงอรุณ',
            district: 'ทับสะแก',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77130,
        },
        {
            subDistrict: 'กำเนิดนพคุณ',
            district: 'บางสะพาน',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77140,
        },
        {
            subDistrict: 'ชัยเกษม',
            district: 'บางสะพาน',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77190,
        },
        {
            subDistrict: 'ทองมงคล',
            district: 'บางสะพาน',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77230,
        },
        {
            subDistrict: 'ธงชัย',
            district: 'บางสะพาน',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77190,
        },
        {
            subDistrict: 'พงศ์ประศาสน์',
            district: 'บางสะพาน',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77140,
        },
        {
            subDistrict: 'ร่อนทอง',
            district: 'บางสะพาน',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77230,
        },
        {
            subDistrict: 'แม่รำพึง',
            district: 'บางสะพาน',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77140,
        },
        {
            subDistrict: 'ช้างแรก',
            district: 'บางสะพานน้อย',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77170,
        },
        {
            subDistrict: 'ทรายทอง',
            district: 'บางสะพานน้อย',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77170,
        },
        {
            subDistrict: 'บางสะพาน',
            district: 'บางสะพานน้อย',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77170,
        },
        {
            subDistrict: 'ปากแพรก',
            district: 'บางสะพานน้อย',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77170,
        },
        {
            subDistrict: 'ไชยราช',
            district: 'บางสะพานน้อย',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77170,
        },
        {
            subDistrict: 'ปราณบุรี',
            district: 'ปราณบุรี',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77120,
        },
        {
            subDistrict: 'ปราณบุรี',
            district: 'ปราณบุรี',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77160,
        },
        {
            subDistrict: 'ปากน้ำปราณ',
            district: 'ปราณบุรี',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77220,
        },
        {
            subDistrict: 'วังก์พง',
            district: 'ปราณบุรี',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77120,
        },
        {
            subDistrict: 'วังก์พง',
            district: 'ปราณบุรี',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77160,
        },
        {
            subDistrict: 'หนองตาแต้ม',
            district: 'ปราณบุรี',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77120,
        },
        {
            subDistrict: 'หนองตาแต้ม',
            district: 'ปราณบุรี',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77160,
        },
        {
            subDistrict: 'เขาจ้าว',
            district: 'ปราณบุรี',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77120,
        },
        {
            subDistrict: 'เขาจ้าว',
            district: 'ปราณบุรี',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77160,
        },
        {
            subDistrict: 'เขาน้อย',
            district: 'ปราณบุรี',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77120,
        },
        {
            subDistrict: 'เขาน้อย',
            district: 'ปราณบุรี',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77160,
        },
        {
            subDistrict: 'ศาลาลัย',
            district: 'สามร้อยยอด',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77180,
        },
        {
            subDistrict: 'ศิลาลอย',
            district: 'สามร้อยยอด',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77180,
        },
        {
            subDistrict: 'สามร้อยยอด',
            district: 'สามร้อยยอด',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77120,
        },
        {
            subDistrict: 'สามร้อยยอด',
            district: 'สามร้อยยอด',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77160,
        },
        {
            subDistrict: 'ไร่เก่า',
            district: 'สามร้อยยอด',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77180,
        },
        {
            subDistrict: 'ไร่ใหม่',
            district: 'สามร้อยยอด',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77180,
        },
        {
            subDistrict: 'ทับใต้',
            district: 'หัวหิน',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77110,
        },
        {
            subDistrict: 'บึงนคร',
            district: 'หัวหิน',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77110,
        },
        {
            subDistrict: 'หนองพลับ',
            district: 'หัวหิน',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77110,
        },
        {
            subDistrict: 'หนองแก',
            district: 'หัวหิน',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77110,
        },
        {
            subDistrict: 'หัวหิน',
            district: 'หัวหิน',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77110,
        },
        {
            subDistrict: 'หินเหล็กไฟ',
            district: 'หัวหิน',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77110,
        },
        {
            subDistrict: 'ห้วยสัตว์ใหญ่',
            district: 'หัวหิน',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77110,
        },
        {
            subDistrict: 'คลองวาฬ',
            district: 'เมืองประจวบคีรีขันธ์',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77000,
        },
        {
            subDistrict: 'บ่อนอก',
            district: 'เมืองประจวบคีรีขันธ์',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77210,
        },
        {
            subDistrict: 'ประจวบคีรีขันธ์',
            district: 'เมืองประจวบคีรีขันธ์',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77000,
        },
        {
            subDistrict: 'ห้วยทราย',
            district: 'เมืองประจวบคีรีขันธ์',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77000,
        },
        {
            subDistrict: 'อ่าวน้อย',
            district: 'เมืองประจวบคีรีขันธ์',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77210,
        },
        {
            subDistrict: 'เกาะหลัก',
            district: 'เมืองประจวบคีรีขันธ์',
            province: 'ประจวบคีรีขันธ์',
            zipcode: 77000,
        },
        {
            subDistrict: 'กบินทร์',
            district: 'กบินทร์บุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25110,
        },
        {
            subDistrict: 'นนทรี',
            district: 'กบินทร์บุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25110,
        },
        {
            subDistrict: 'นาแขม',
            district: 'กบินทร์บุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25110,
        },
        {
            subDistrict: 'บ่อทอง',
            district: 'กบินทร์บุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25110,
        },
        {
            subDistrict: 'บ้านนา',
            district: 'กบินทร์บุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25110,
        },
        {
            subDistrict: 'ย่านรี',
            district: 'กบินทร์บุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25110,
        },
        {
            subDistrict: 'ลาดตะเคียน',
            district: 'กบินทร์บุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25110,
        },
        {
            subDistrict: 'วังดาล',
            district: 'กบินทร์บุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25110,
        },
        {
            subDistrict: 'วังตะเคียน',
            district: 'กบินทร์บุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25110,
        },
        {
            subDistrict: 'วังท่าช้าง',
            district: 'กบินทร์บุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25110,
        },
        {
            subDistrict: 'หนองกี่',
            district: 'กบินทร์บุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25110,
        },
        {
            subDistrict: 'หาดนางแก้ว',
            district: 'กบินทร์บุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25110,
        },
        {
            subDistrict: 'เขาไม้แก้ว',
            district: 'กบินทร์บุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25110,
        },
        {
            subDistrict: 'เมืองเก่า',
            district: 'กบินทร์บุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25240,
        },
        {
            subDistrict: 'ทุ่งโพธิ์',
            district: 'นาดี',
            province: 'ปราจีนบุรี',
            zipcode: 25220,
        },
        {
            subDistrict: 'นาดี',
            district: 'นาดี',
            province: 'ปราจีนบุรี',
            zipcode: 25220,
        },
        {
            subDistrict: 'บุพราหมณ์',
            district: 'นาดี',
            province: 'ปราจีนบุรี',
            zipcode: 25220,
        },
        {
            subDistrict: 'สะพานหิน',
            district: 'นาดี',
            province: 'ปราจีนบุรี',
            zipcode: 25220,
        },
        {
            subDistrict: 'สำพันตา',
            district: 'นาดี',
            province: 'ปราจีนบุรี',
            zipcode: 25220,
        },
        {
            subDistrict: 'แก่งดินสอ',
            district: 'นาดี',
            province: 'ปราจีนบุรี',
            zipcode: 25220,
        },
        {
            subDistrict: 'กระทุ่มแพ้ว',
            district: 'บ้านสร้าง',
            province: 'ปราจีนบุรี',
            zipcode: 25150,
        },
        {
            subDistrict: 'บางกระเบา',
            district: 'บ้านสร้าง',
            province: 'ปราจีนบุรี',
            zipcode: 25150,
        },
        {
            subDistrict: 'บางขาม',
            district: 'บ้านสร้าง',
            province: 'ปราจีนบุรี',
            zipcode: 25150,
        },
        {
            subDistrict: 'บางปลาร้า',
            district: 'บ้านสร้าง',
            province: 'ปราจีนบุรี',
            zipcode: 25150,
        },
        {
            subDistrict: 'บางพลวง',
            district: 'บ้านสร้าง',
            province: 'ปราจีนบุรี',
            zipcode: 25150,
        },
        {
            subDistrict: 'บางยาง',
            district: 'บ้านสร้าง',
            province: 'ปราจีนบุรี',
            zipcode: 25150,
        },
        {
            subDistrict: 'บางเตย',
            district: 'บ้านสร้าง',
            province: 'ปราจีนบุรี',
            zipcode: 25150,
        },
        {
            subDistrict: 'บางแตน',
            district: 'บ้านสร้าง',
            province: 'ปราจีนบุรี',
            zipcode: 25150,
        },
        {
            subDistrict: 'บ้านสร้าง',
            district: 'บ้านสร้าง',
            province: 'ปราจีนบุรี',
            zipcode: 25150,
        },
        {
            subDistrict: 'คำโตนด',
            district: 'ประจันตคาม',
            province: 'ปราจีนบุรี',
            zipcode: 25130,
        },
        {
            subDistrict: 'ดงบัง',
            district: 'ประจันตคาม',
            province: 'ปราจีนบุรี',
            zipcode: 25130,
        },
        {
            subDistrict: 'บุฝ้าย',
            district: 'ประจันตคาม',
            province: 'ปราจีนบุรี',
            zipcode: 25130,
        },
        {
            subDistrict: 'บ้านหอย',
            district: 'ประจันตคาม',
            province: 'ปราจีนบุรี',
            zipcode: 25130,
        },
        {
            subDistrict: 'ประจันตคาม',
            district: 'ประจันตคาม',
            province: 'ปราจีนบุรี',
            zipcode: 25130,
        },
        {
            subDistrict: 'หนองแก้ว',
            district: 'ประจันตคาม',
            province: 'ปราจีนบุรี',
            zipcode: 25130,
        },
        {
            subDistrict: 'หนองแสง',
            district: 'ประจันตคาม',
            province: 'ปราจีนบุรี',
            zipcode: 25130,
        },
        {
            subDistrict: 'เกาะลอย',
            district: 'ประจันตคาม',
            province: 'ปราจีนบุรี',
            zipcode: 25130,
        },
        {
            subDistrict: 'โพธิ์งาม',
            district: 'ประจันตคาม',
            province: 'ปราจีนบุรี',
            zipcode: 25130,
        },
        {
            subDistrict: 'กรอกสมบูรณ์',
            district: 'ศรีมหาโพธิ',
            province: 'ปราจีนบุรี',
            zipcode: 25140,
        },
        {
            subDistrict: 'ดงกระทงยาม',
            district: 'ศรีมหาโพธิ',
            province: 'ปราจีนบุรี',
            zipcode: 25140,
        },
        {
            subDistrict: 'ท่าตูม',
            district: 'ศรีมหาโพธิ',
            province: 'ปราจีนบุรี',
            zipcode: 25140,
        },
        {
            subDistrict: 'บางกุ้ง',
            district: 'ศรีมหาโพธิ',
            province: 'ปราจีนบุรี',
            zipcode: 25140,
        },
        {
            subDistrict: 'บ้านทาม',
            district: 'ศรีมหาโพธิ',
            province: 'ปราจีนบุรี',
            zipcode: 25140,
        },
        {
            subDistrict: 'ศรีมหาโพธิ',
            district: 'ศรีมหาโพธิ',
            province: 'ปราจีนบุรี',
            zipcode: 25140,
        },
        {
            subDistrict: 'สัมพันธ์',
            district: 'ศรีมหาโพธิ',
            province: 'ปราจีนบุรี',
            zipcode: 25140,
        },
        {
            subDistrict: 'หนองโพรง',
            district: 'ศรีมหาโพธิ',
            province: 'ปราจีนบุรี',
            zipcode: 25140,
        },
        {
            subDistrict: 'หัวหว้า',
            district: 'ศรีมหาโพธิ',
            province: 'ปราจีนบุรี',
            zipcode: 25140,
        },
        {
            subDistrict: 'หาดยาง',
            district: 'ศรีมหาโพธิ',
            province: 'ปราจีนบุรี',
            zipcode: 25140,
        },
        {
            subDistrict: 'คู้ลำพัน',
            district: 'ศรีมโหสถ',
            province: 'ปราจีนบุรี',
            zipcode: 25190,
        },
        {
            subDistrict: 'โคกปีบ',
            district: 'ศรีมโหสถ',
            province: 'ปราจีนบุรี',
            zipcode: 25190,
        },
        {
            subDistrict: 'โคกไทย',
            district: 'ศรีมโหสถ',
            province: 'ปราจีนบุรี',
            zipcode: 25190,
        },
        {
            subDistrict: 'ไผ่ชะเลือด',
            district: 'ศรีมโหสถ',
            province: 'ปราจีนบุรี',
            zipcode: 25190,
        },
        {
            subDistrict: 'ดงขี้เหล็ก',
            district: 'เมืองปราจีนบุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25000,
        },
        {
            subDistrict: 'ดงพระราม',
            district: 'เมืองปราจีนบุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25000,
        },
        {
            subDistrict: 'ท่างาม',
            district: 'เมืองปราจีนบุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25000,
        },
        {
            subDistrict: 'บางบริบูรณ์',
            district: 'เมืองปราจีนบุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25000,
        },
        {
            subDistrict: 'บางเดชะ',
            district: 'เมืองปราจีนบุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25000,
        },
        {
            subDistrict: 'บ้านพระ',
            district: 'เมืองปราจีนบุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25230,
        },
        {
            subDistrict: 'รอบเมือง',
            district: 'เมืองปราจีนบุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25000,
        },
        {
            subDistrict: 'วัดโบสถ์',
            district: 'เมืองปราจีนบุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25000,
        },
        {
            subDistrict: 'หน้าเมือง',
            district: 'เมืองปราจีนบุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25000,
        },
        {
            subDistrict: 'เนินหอม',
            district: 'เมืองปราจีนบุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25230,
        },
        {
            subDistrict: 'โคกไม้ลาย',
            district: 'เมืองปราจีนบุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25230,
        },
        {
            subDistrict: 'โนนห้อม',
            district: 'เมืองปราจีนบุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25000,
        },
        {
            subDistrict: 'ไม้เค็ด',
            district: 'เมืองปราจีนบุรี',
            province: 'ปราจีนบุรี',
            zipcode: 25230,
        },
        {
            subDistrict: 'กะรุบี',
            district: 'กะพ้อ',
            province: 'ปัตตานี',
            zipcode: 94230,
        },
        {
            subDistrict: 'ตะโละดือรามัน',
            district: 'กะพ้อ',
            province: 'ปัตตานี',
            zipcode: 94230,
        },
        {
            subDistrict: 'ปล่องหอย',
            district: 'กะพ้อ',
            province: 'ปัตตานี',
            zipcode: 94230,
        },
        {
            subDistrict: 'ตะโละแมะนา',
            district: 'ทุ่งยางแดง',
            province: 'ปัตตานี',
            zipcode: 94140,
        },
        {
            subDistrict: 'น้ำดำ',
            district: 'ทุ่งยางแดง',
            province: 'ปัตตานี',
            zipcode: 94140,
        },
        {
            subDistrict: 'ปากู',
            district: 'ทุ่งยางแดง',
            province: 'ปัตตานี',
            zipcode: 94140,
        },
        {
            subDistrict: 'พิเทน',
            district: 'ทุ่งยางแดง',
            province: 'ปัตตานี',
            zipcode: 94140,
        },
        {
            subDistrict: 'ควน',
            district: 'ปะนาเระ',
            province: 'ปัตตานี',
            zipcode: 94190,
        },
        {
            subDistrict: 'คอกกระบือ',
            district: 'ปะนาเระ',
            province: 'ปัตตานี',
            zipcode: 94130,
        },
        {
            subDistrict: 'ดอน',
            district: 'ปะนาเระ',
            province: 'ปัตตานี',
            zipcode: 94190,
        },
        {
            subDistrict: 'ท่าข้าม',
            district: 'ปะนาเระ',
            province: 'ปัตตานี',
            zipcode: 94130,
        },
        {
            subDistrict: 'ท่าน้ำ',
            district: 'ปะนาเระ',
            province: 'ปัตตานี',
            zipcode: 94130,
        },
        {
            subDistrict: 'บ้านกลาง',
            district: 'ปะนาเระ',
            province: 'ปัตตานี',
            zipcode: 94130,
        },
        {
            subDistrict: 'บ้านนอก',
            district: 'ปะนาเระ',
            province: 'ปัตตานี',
            zipcode: 94130,
        },
        {
            subDistrict: 'บ้านน้ำบ่อ',
            district: 'ปะนาเระ',
            province: 'ปัตตานี',
            zipcode: 94130,
        },
        {
            subDistrict: 'ปะนาเระ',
            district: 'ปะนาเระ',
            province: 'ปัตตานี',
            zipcode: 94130,
        },
        {
            subDistrict: 'พ่อมิ่ง',
            district: 'ปะนาเระ',
            province: 'ปัตตานี',
            zipcode: 94130,
        },
        {
            subDistrict: 'กระหวะ',
            district: 'มายอ',
            province: 'ปัตตานี',
            zipcode: 94190,
        },
        {
            subDistrict: 'กระเสาะ',
            district: 'มายอ',
            province: 'ปัตตานี',
            zipcode: 94140,
        },
        {
            subDistrict: 'ตรัง',
            district: 'มายอ',
            province: 'ปัตตานี',
            zipcode: 94140,
        },
        {
            subDistrict: 'ถนน',
            district: 'มายอ',
            province: 'ปัตตานี',
            zipcode: 94140,
        },
        {
            subDistrict: 'ปะโด',
            district: 'มายอ',
            province: 'ปัตตานี',
            zipcode: 94140,
        },
        {
            subDistrict: 'ปานัน',
            district: 'มายอ',
            province: 'ปัตตานี',
            zipcode: 94140,
        },
        {
            subDistrict: 'มายอ',
            district: 'มายอ',
            province: 'ปัตตานี',
            zipcode: 94140,
        },
        {
            subDistrict: 'ลางา',
            district: 'มายอ',
            province: 'ปัตตานี',
            zipcode: 94190,
        },
        {
            subDistrict: 'ลุโบะยิไร',
            district: 'มายอ',
            province: 'ปัตตานี',
            zipcode: 94140,
        },
        {
            subDistrict: 'สะกำ',
            district: 'มายอ',
            province: 'ปัตตานี',
            zipcode: 94140,
        },
        {
            subDistrict: 'สาคอบน',
            district: 'มายอ',
            province: 'ปัตตานี',
            zipcode: 94140,
        },
        {
            subDistrict: 'สาคอใต้',
            district: 'มายอ',
            province: 'ปัตตานี',
            zipcode: 94140,
        },
        {
            subDistrict: 'เกาะจัน',
            district: 'มายอ',
            province: 'ปัตตานี',
            zipcode: 94140,
        },
        {
            subDistrict: 'กระโด',
            district: 'ยะรัง',
            province: 'ปัตตานี',
            zipcode: 94160,
        },
        {
            subDistrict: 'กอลำ',
            district: 'ยะรัง',
            province: 'ปัตตานี',
            zipcode: 94160,
        },
        {
            subDistrict: 'คลองใหม่',
            district: 'ยะรัง',
            province: 'ปัตตานี',
            zipcode: 94160,
        },
        {
            subDistrict: 'ประจัน',
            district: 'ยะรัง',
            province: 'ปัตตานี',
            zipcode: 94160,
        },
        {
            subDistrict: 'ปิตูมุดี',
            district: 'ยะรัง',
            province: 'ปัตตานี',
            zipcode: 94160,
        },
        {
            subDistrict: 'ยะรัง',
            district: 'ยะรัง',
            province: 'ปัตตานี',
            zipcode: 94160,
        },
        {
            subDistrict: 'ระแว้ง',
            district: 'ยะรัง',
            province: 'ปัตตานี',
            zipcode: 94160,
        },
        {
            subDistrict: 'วัด',
            district: 'ยะรัง',
            province: 'ปัตตานี',
            zipcode: 94160,
        },
        {
            subDistrict: 'สะดาวา',
            district: 'ยะรัง',
            province: 'ปัตตานี',
            zipcode: 94160,
        },
        {
            subDistrict: 'สะนอ',
            district: 'ยะรัง',
            province: 'ปัตตานี',
            zipcode: 94160,
        },
        {
            subDistrict: 'เขาตูม',
            district: 'ยะรัง',
            province: 'ปัตตานี',
            zipcode: 94160,
        },
        {
            subDistrict: 'เมาะมาวี',
            district: 'ยะรัง',
            province: 'ปัตตานี',
            zipcode: 94160,
        },
        {
            subDistrict: 'จะรัง',
            district: 'ยะหริ่ง',
            province: 'ปัตตานี',
            zipcode: 94150,
        },
        {
            subDistrict: 'ตอหลัง',
            district: 'ยะหริ่ง',
            province: 'ปัตตานี',
            zipcode: 94150,
        },
        {
            subDistrict: 'ตะโละ',
            district: 'ยะหริ่ง',
            province: 'ปัตตานี',
            zipcode: 94150,
        },
        {
            subDistrict: 'ตะโละกาโปร์',
            district: 'ยะหริ่ง',
            province: 'ปัตตานี',
            zipcode: 94150,
        },
        {
            subDistrict: 'ตันหยงจึงงา',
            district: 'ยะหริ่ง',
            province: 'ปัตตานี',
            zipcode: 94190,
        },
        {
            subDistrict: 'ตันหยงดาลอ',
            district: 'ยะหริ่ง',
            province: 'ปัตตานี',
            zipcode: 94150,
        },
        {
            subDistrict: 'ตาลีอายร์',
            district: 'ยะหริ่ง',
            province: 'ปัตตานี',
            zipcode: 94150,
        },
        {
            subDistrict: 'ตาแกะ',
            district: 'ยะหริ่ง',
            province: 'ปัตตานี',
            zipcode: 94150,
        },
        {
            subDistrict: 'บางปู',
            district: 'ยะหริ่ง',
            province: 'ปัตตานี',
            zipcode: 94150,
        },
        {
            subDistrict: 'บาโลย',
            district: 'ยะหริ่ง',
            province: 'ปัตตานี',
            zipcode: 94190,
        },
        {
            subDistrict: 'ปิยามุมัง',
            district: 'ยะหริ่ง',
            province: 'ปัตตานี',
            zipcode: 94150,
        },
        {
            subDistrict: 'ปุลากง',
            district: 'ยะหริ่ง',
            province: 'ปัตตานี',
            zipcode: 94150,
        },
        {
            subDistrict: 'มะนังยง',
            district: 'ยะหริ่ง',
            province: 'ปัตตานี',
            zipcode: 94150,
        },
        {
            subDistrict: 'ยามู',
            district: 'ยะหริ่ง',
            province: 'ปัตตานี',
            zipcode: 94150,
        },
        {
            subDistrict: 'ราตาปันยัง',
            district: 'ยะหริ่ง',
            province: 'ปัตตานี',
            zipcode: 94150,
        },
        {
            subDistrict: 'สาบัน',
            district: 'ยะหริ่ง',
            province: 'ปัตตานี',
            zipcode: 94150,
        },
        {
            subDistrict: 'หนองแรต',
            district: 'ยะหริ่ง',
            province: 'ปัตตานี',
            zipcode: 94150,
        },
        {
            subDistrict: 'แหลมโพธิ์',
            district: 'ยะหริ่ง',
            province: 'ปัตตานี',
            zipcode: 94150,
        },
        {
            subDistrict: 'กะดุนง',
            district: 'สายบุรี',
            province: 'ปัตตานี',
            zipcode: 94110,
        },
        {
            subDistrict: 'ตะบิ้ง',
            district: 'สายบุรี',
            province: 'ปัตตานี',
            zipcode: 94110,
        },
        {
            subDistrict: 'ตะลุบัน',
            district: 'สายบุรี',
            province: 'ปัตตานี',
            zipcode: 94110,
        },
        {
            subDistrict: 'ทุ่งคล้า',
            district: 'สายบุรี',
            province: 'ปัตตานี',
            zipcode: 94190,
        },
        {
            subDistrict: 'บางเก่า',
            district: 'สายบุรี',
            province: 'ปัตตานี',
            zipcode: 94110,
        },
        {
            subDistrict: 'บือเระ',
            district: 'สายบุรี',
            province: 'ปัตตานี',
            zipcode: 94110,
        },
        {
            subDistrict: 'ปะเสยะวอ',
            district: 'สายบุรี',
            province: 'ปัตตานี',
            zipcode: 94110,
        },
        {
            subDistrict: 'มะนังดาลำ',
            district: 'สายบุรี',
            province: 'ปัตตานี',
            zipcode: 94110,
        },
        {
            subDistrict: 'ละหาร',
            district: 'สายบุรี',
            province: 'ปัตตานี',
            zipcode: 94110,
        },
        {
            subDistrict: 'เตราะบอน',
            district: 'สายบุรี',
            province: 'ปัตตานี',
            zipcode: 94110,
        },
        {
            subDistrict: 'แป้น',
            district: 'สายบุรี',
            province: 'ปัตตานี',
            zipcode: 94110,
        },
        {
            subDistrict: 'คอลอตันหยง',
            district: 'หนองจิก',
            province: 'ปัตตานี',
            zipcode: 94170,
        },
        {
            subDistrict: 'ดอนรัก',
            district: 'หนองจิก',
            province: 'ปัตตานี',
            zipcode: 94170,
        },
        {
            subDistrict: 'ดาโต๊ะ',
            district: 'หนองจิก',
            province: 'ปัตตานี',
            zipcode: 94170,
        },
        {
            subDistrict: 'ตุยง',
            district: 'หนองจิก',
            province: 'ปัตตานี',
            zipcode: 94170,
        },
        {
            subDistrict: 'ท่ากำชำ',
            district: 'หนองจิก',
            province: 'ปัตตานี',
            zipcode: 94170,
        },
        {
            subDistrict: 'บางตาวา',
            district: 'หนองจิก',
            province: 'ปัตตานี',
            zipcode: 94170,
        },
        {
            subDistrict: 'บางเขา',
            district: 'หนองจิก',
            province: 'ปัตตานี',
            zipcode: 94170,
        },
        {
            subDistrict: 'บ่อทอง',
            district: 'หนองจิก',
            province: 'ปัตตานี',
            zipcode: 94170,
        },
        {
            subDistrict: 'ปุโละปุโย',
            district: 'หนองจิก',
            province: 'ปัตตานี',
            zipcode: 94170,
        },
        {
            subDistrict: 'ยาบี',
            district: 'หนองจิก',
            province: 'ปัตตานี',
            zipcode: 94170,
        },
        {
            subDistrict: 'ลิปะสะโง',
            district: 'หนองจิก',
            province: 'ปัตตานี',
            zipcode: 94170,
        },
        {
            subDistrict: 'เกาะเปาะ',
            district: 'หนองจิก',
            province: 'ปัตตานี',
            zipcode: 94170,
        },
        {
            subDistrict: 'กะมิยอ',
            district: 'เมืองปัตตานี',
            province: 'ปัตตานี',
            zipcode: 94000,
        },
        {
            subDistrict: 'คลองมานิง',
            district: 'เมืองปัตตานี',
            province: 'ปัตตานี',
            zipcode: 94000,
        },
        {
            subDistrict: 'จะบังติกอ',
            district: 'เมืองปัตตานี',
            province: 'ปัตตานี',
            zipcode: 94000,
        },
        {
            subDistrict: 'ตะลุโบะ',
            district: 'เมืองปัตตานี',
            province: 'ปัตตานี',
            zipcode: 94000,
        },
        {
            subDistrict: 'ตันหยงลุโละ',
            district: 'เมืองปัตตานี',
            province: 'ปัตตานี',
            zipcode: 94000,
        },
        {
            subDistrict: 'บานา',
            district: 'เมืองปัตตานี',
            province: 'ปัตตานี',
            zipcode: 94000,
        },
        {
            subDistrict: 'บาราเฮาะ',
            district: 'เมืองปัตตานี',
            province: 'ปัตตานี',
            zipcode: 94000,
        },
        {
            subDistrict: 'บาราโหม',
            district: 'เมืองปัตตานี',
            province: 'ปัตตานี',
            zipcode: 94000,
        },
        {
            subDistrict: 'ปะกาฮะรัง',
            district: 'เมืองปัตตานี',
            province: 'ปัตตานี',
            zipcode: 94000,
        },
        {
            subDistrict: 'ปุยุด',
            district: 'เมืองปัตตานี',
            province: 'ปัตตานี',
            zipcode: 94000,
        },
        {
            subDistrict: 'รูสะมิแล',
            district: 'เมืองปัตตานี',
            province: 'ปัตตานี',
            zipcode: 94000,
        },
        {
            subDistrict: 'สะบารัง',
            district: 'เมืองปัตตานี',
            province: 'ปัตตานี',
            zipcode: 94000,
        },
        {
            subDistrict: 'อาเนาะรู',
            district: 'เมืองปัตตานี',
            province: 'ปัตตานี',
            zipcode: 94000,
        },
        {
            subDistrict: 'ป่าไร่',
            district: 'แม่ลาน',
            province: 'ปัตตานี',
            zipcode: 94180,
        },
        {
            subDistrict: 'ม่วงเตี้ย',
            district: 'แม่ลาน',
            province: 'ปัตตานี',
            zipcode: 94180,
        },
        {
            subDistrict: 'แม่ลาน',
            district: 'แม่ลาน',
            province: 'ปัตตานี',
            zipcode: 94180,
        },
        {
            subDistrict: 'ควนโนรี',
            district: 'โคกโพธิ์',
            province: 'ปัตตานี',
            zipcode: 94180,
        },
        {
            subDistrict: 'ช้างให้ตก',
            district: 'โคกโพธิ์',
            province: 'ปัตตานี',
            zipcode: 94120,
        },
        {
            subDistrict: 'ทรายขาว',
            district: 'โคกโพธิ์',
            province: 'ปัตตานี',
            zipcode: 94120,
        },
        {
            subDistrict: 'ทุ่งพลา',
            district: 'โคกโพธิ์',
            province: 'ปัตตานี',
            zipcode: 94180,
        },
        {
            subDistrict: 'ท่าเรือ',
            district: 'โคกโพธิ์',
            province: 'ปัตตานี',
            zipcode: 94120,
        },
        {
            subDistrict: 'นาประดู่',
            district: 'โคกโพธิ์',
            province: 'ปัตตานี',
            zipcode: 94180,
        },
        {
            subDistrict: 'นาเกตุ',
            district: 'โคกโพธิ์',
            province: 'ปัตตานี',
            zipcode: 94120,
        },
        {
            subDistrict: 'บางโกระ',
            district: 'โคกโพธิ์',
            province: 'ปัตตานี',
            zipcode: 94120,
        },
        {
            subDistrict: 'ปากล่อ',
            district: 'โคกโพธิ์',
            province: 'ปัตตานี',
            zipcode: 94180,
        },
        {
            subDistrict: 'ป่าบอน',
            district: 'โคกโพธิ์',
            province: 'ปัตตานี',
            zipcode: 94120,
        },
        {
            subDistrict: 'มะกรูด',
            district: 'โคกโพธิ์',
            province: 'ปัตตานี',
            zipcode: 94120,
        },
        {
            subDistrict: 'โคกโพธิ์',
            district: 'โคกโพธิ์',
            province: 'ปัตตานี',
            zipcode: 94120,
        },
        {
            subDistrict: 'ดอนทราย',
            district: 'ไม้แก่น',
            province: 'ปัตตานี',
            zipcode: 94220,
        },
        {
            subDistrict: 'ตะโละไกรทอง',
            district: 'ไม้แก่น',
            province: 'ปัตตานี',
            zipcode: 94220,
        },
        {
            subDistrict: 'ไทรทอง',
            district: 'ไม้แก่น',
            province: 'ปัตตานี',
            zipcode: 94220,
        },
        {
            subDistrict: 'ไม้แก่น',
            district: 'ไม้แก่น',
            province: 'ปัตตานี',
            zipcode: 94220,
        },
        {
            subDistrict: 'จำปา',
            district: 'ท่าเรือ',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13130,
        },
        {
            subDistrict: 'ท่าหลวง',
            district: 'ท่าเรือ',
            province: 'พระนครศรีอยุธยา',
            zipcode: 18270,
        },
        {
            subDistrict: 'ท่าเจ้าสนุก',
            district: 'ท่าเรือ',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13130,
        },
        {
            subDistrict: 'ท่าเรือ',
            district: 'ท่าเรือ',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13130,
        },
        {
            subDistrict: 'บ้านร่อม',
            district: 'ท่าเรือ',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13130,
        },
        {
            subDistrict: 'ปากท่า',
            district: 'ท่าเรือ',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13130,
        },
        {
            subDistrict: 'วังแดง',
            district: 'ท่าเรือ',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13130,
        },
        {
            subDistrict: 'ศาลาลอย',
            district: 'ท่าเรือ',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13130,
        },
        {
            subDistrict: 'หนองขนาก',
            district: 'ท่าเรือ',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13130,
        },
        {
            subDistrict: 'โพธิ์เอน',
            district: 'ท่าเรือ',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13130,
        },
        {
            subDistrict: 'คลองสะแก',
            district: 'นครหลวง',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13260,
        },
        {
            subDistrict: 'ท่าช้าง',
            district: 'นครหลวง',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13260,
        },
        {
            subDistrict: 'นครหลวง',
            district: 'นครหลวง',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13260,
        },
        {
            subDistrict: 'บางพระครู',
            district: 'นครหลวง',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13260,
        },
        {
            subDistrict: 'บางระกำ',
            district: 'นครหลวง',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13260,
        },
        {
            subDistrict: 'บ่อโพง',
            district: 'นครหลวง',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13260,
        },
        {
            subDistrict: 'บ้านชุ้ง',
            district: 'นครหลวง',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13260,
        },
        {
            subDistrict: 'ปากจั่น',
            district: 'นครหลวง',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13260,
        },
        {
            subDistrict: 'พระนอน',
            district: 'นครหลวง',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13260,
        },
        {
            subDistrict: 'สามไถ',
            district: 'นครหลวง',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13260,
        },
        {
            subDistrict: 'หนองปลิง',
            district: 'นครหลวง',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13260,
        },
        {
            subDistrict: 'แม่ลา',
            district: 'นครหลวง',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13260,
        },
        {
            subDistrict: 'บางซ้าย',
            district: 'บางซ้าย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13270,
        },
        {
            subDistrict: 'ปลายกลัด',
            district: 'บางซ้าย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13270,
        },
        {
            subDistrict: 'วังพัฒนา',
            district: 'บางซ้าย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13270,
        },
        {
            subDistrict: 'เต่าเล่า',
            district: 'บางซ้าย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13270,
        },
        {
            subDistrict: 'เทพมงคล',
            district: 'บางซ้าย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13270,
        },
        {
            subDistrict: 'แก้วฟ้า',
            district: 'บางซ้าย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13270,
        },
        {
            subDistrict: 'กบเจา',
            district: 'บางบาล',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13250,
        },
        {
            subDistrict: 'ทางช้าง',
            district: 'บางบาล',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13250,
        },
        {
            subDistrict: 'น้ำเต้า',
            district: 'บางบาล',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13250,
        },
        {
            subDistrict: 'บางชะนี',
            district: 'บางบาล',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13250,
        },
        {
            subDistrict: 'บางบาล',
            district: 'บางบาล',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13250,
        },
        {
            subDistrict: 'บางหลวง',
            district: 'บางบาล',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13250,
        },
        {
            subDistrict: 'บางหลวงโดด',
            district: 'บางบาล',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13250,
        },
        {
            subDistrict: 'บางหัก',
            district: 'บางบาล',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13250,
        },
        {
            subDistrict: 'บ้านกุ่ม',
            district: 'บางบาล',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13250,
        },
        {
            subDistrict: 'บ้านคลัง',
            district: 'บางบาล',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13250,
        },
        {
            subDistrict: 'พระขาว',
            district: 'บางบาล',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13250,
        },
        {
            subDistrict: 'มหาพราหมณ์',
            district: 'บางบาล',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13250,
        },
        {
            subDistrict: 'วัดตะกู',
            district: 'บางบาล',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13250,
        },
        {
            subDistrict: 'วัดยม',
            district: 'บางบาล',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13250,
        },
        {
            subDistrict: 'สะพานไทย',
            district: 'บางบาล',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13250,
        },
        {
            subDistrict: 'ไทรน้อย',
            district: 'บางบาล',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13250,
        },
        {
            subDistrict: 'ขยาย',
            district: 'บางปะหัน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13220,
        },
        {
            subDistrict: 'ขวัญเมือง',
            district: 'บางปะหัน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13220,
        },
        {
            subDistrict: 'ตานิม',
            district: 'บางปะหัน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13220,
        },
        {
            subDistrict: 'ตาลเอน',
            district: 'บางปะหัน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13220,
        },
        {
            subDistrict: 'ทับน้ำ',
            district: 'บางปะหัน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13220,
        },
        {
            subDistrict: 'ทางกลาง',
            district: 'บางปะหัน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13220,
        },
        {
            subDistrict: 'บางนางร้า',
            district: 'บางปะหัน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13220,
        },
        {
            subDistrict: 'บางปะหัน',
            district: 'บางปะหัน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13220,
        },
        {
            subDistrict: 'บางเดื่อ',
            district: 'บางปะหัน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13220,
        },
        {
            subDistrict: 'บางเพลิง',
            district: 'บางปะหัน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13220,
        },
        {
            subDistrict: 'บ้านขล้อ',
            district: 'บางปะหัน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13220,
        },
        {
            subDistrict: 'บ้านม้า',
            district: 'บางปะหัน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13220,
        },
        {
            subDistrict: 'บ้านลี่',
            district: 'บางปะหัน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13220,
        },
        {
            subDistrict: 'พุทเลา',
            district: 'บางปะหัน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13220,
        },
        {
            subDistrict: 'หันสัง',
            district: 'บางปะหัน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13220,
        },
        {
            subDistrict: 'เสาธง',
            district: 'บางปะหัน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13220,
        },
        {
            subDistrict: 'โพธิ์สามต้น',
            district: 'บางปะหัน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13220,
        },
        {
            subDistrict: 'ขนอนหลวง',
            district: 'บางปะอิน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13160,
        },
        {
            subDistrict: 'คลองจิก',
            district: 'บางปะอิน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13160,
        },
        {
            subDistrict: 'คุ้งลาน',
            district: 'บางปะอิน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13160,
        },
        {
            subDistrict: 'ตลาดเกรียบ',
            district: 'บางปะอิน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13160,
        },
        {
            subDistrict: 'ตลิ่งชัน',
            district: 'บางปะอิน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13160,
        },
        {
            subDistrict: 'บางกระสั้น',
            district: 'บางปะอิน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13160,
        },
        {
            subDistrict: 'บางประแดง',
            district: 'บางปะอิน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13160,
        },
        {
            subDistrict: 'บ้านกรด',
            district: 'บางปะอิน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13160,
        },
        {
            subDistrict: 'บ้านพลับ',
            district: 'บางปะอิน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13160,
        },
        {
            subDistrict: 'บ้านสร้าง',
            district: 'บางปะอิน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13170,
        },
        {
            subDistrict: 'บ้านหว้า',
            district: 'บางปะอิน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13160,
        },
        {
            subDistrict: 'บ้านเลน',
            district: 'บางปะอิน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13160,
        },
        {
            subDistrict: 'บ้านแป้ง',
            district: 'บางปะอิน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13160,
        },
        {
            subDistrict: 'บ้านโพ',
            district: 'บางปะอิน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13160,
        },
        {
            subDistrict: 'วัดยม',
            district: 'บางปะอิน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13160,
        },
        {
            subDistrict: 'สามเรือน',
            district: 'บางปะอิน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13160,
        },
        {
            subDistrict: 'เกาะเกิด',
            district: 'บางปะอิน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13160,
        },
        {
            subDistrict: 'เชียงรากน้อย',
            district: 'บางปะอิน',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13180,
        },
        {
            subDistrict: 'กกแก้วบูรพา',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13190,
        },
        {
            subDistrict: 'กระแชง',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13190,
        },
        {
            subDistrict: 'ช่างเหล็ก',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13190,
        },
        {
            subDistrict: 'ช้างน้อย',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13190,
        },
        {
            subDistrict: 'ช้างใหญ่',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13290,
        },
        {
            subDistrict: 'บางพลี',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13190,
        },
        {
            subDistrict: 'บางยี่โท',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13190,
        },
        {
            subDistrict: 'บางไทร',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13190,
        },
        {
            subDistrict: 'บ้านกลึง',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13190,
        },
        {
            subDistrict: 'บ้านม้า',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13190,
        },
        {
            subDistrict: 'บ้านเกาะ',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13190,
        },
        {
            subDistrict: 'บ้านแป้ง',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13190,
        },
        {
            subDistrict: 'ราชคราม',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13290,
        },
        {
            subDistrict: 'สนามชัย',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13190,
        },
        {
            subDistrict: 'หน้าไม้',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13190,
        },
        {
            subDistrict: 'ห่อหมก',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13190,
        },
        {
            subDistrict: 'เชียงรากน้อย',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13290,
        },
        {
            subDistrict: 'แคตก',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13190,
        },
        {
            subDistrict: 'แคออก',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13190,
        },
        {
            subDistrict: 'โคกช้าง',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13190,
        },
        {
            subDistrict: 'โพแตง',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13290,
        },
        {
            subDistrict: 'ไผ่พระ',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13190,
        },
        {
            subDistrict: 'ไม้ตรา',
            district: 'บางไทร',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13190,
        },
        {
            subDistrict: 'คลองน้อย',
            district: 'บ้านแพรก',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13240,
        },
        {
            subDistrict: 'บ้านแพรก',
            district: 'บ้านแพรก',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13240,
        },
        {
            subDistrict: 'บ้านใหม่',
            district: 'บ้านแพรก',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13240,
        },
        {
            subDistrict: 'สองห้อง',
            district: 'บ้านแพรก',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13240,
        },
        {
            subDistrict: 'สำพะเนียง',
            district: 'บ้านแพรก',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13240,
        },
        {
            subDistrict: 'กุฎี',
            district: 'ผักไห่',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13120,
        },
        {
            subDistrict: 'จักราช',
            district: 'ผักไห่',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13280,
        },
        {
            subDistrict: 'ดอนลาน',
            district: 'ผักไห่',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13280,
        },
        {
            subDistrict: 'ตาลาน',
            district: 'ผักไห่',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13120,
        },
        {
            subDistrict: 'ท่าดินแดง',
            district: 'ผักไห่',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13120,
        },
        {
            subDistrict: 'นาคู',
            district: 'ผักไห่',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13280,
        },
        {
            subDistrict: 'บ้านแค',
            district: 'ผักไห่',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13120,
        },
        {
            subDistrict: 'บ้านใหญ่',
            district: 'ผักไห่',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13120,
        },
        {
            subDistrict: 'ผักไห่',
            district: 'ผักไห่',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13120,
        },
        {
            subDistrict: 'ลาดชิด',
            district: 'ผักไห่',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13120,
        },
        {
            subDistrict: 'ลาดน้ำเค็ม',
            district: 'ผักไห่',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13120,
        },
        {
            subDistrict: 'ลำตะเคียน',
            district: 'ผักไห่',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13280,
        },
        {
            subDistrict: 'หนองน้ำใหญ่',
            district: 'ผักไห่',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13280,
        },
        {
            subDistrict: 'หน้าโคก',
            district: 'ผักไห่',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13120,
        },
        {
            subDistrict: 'อมฤต',
            district: 'ผักไห่',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13120,
        },
        {
            subDistrict: 'โคกช้าง',
            district: 'ผักไห่',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13120,
        },
        {
            subDistrict: 'กะมัง',
            district: 'พระนครศรีอยุธยา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13000,
        },
        {
            subDistrict: 'คลองตะเคียน',
            district: 'พระนครศรีอยุธยา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13000,
        },
        {
            subDistrict: 'คลองสระบัว',
            district: 'พระนครศรีอยุธยา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13000,
        },
        {
            subDistrict: 'คลองสวนพลู',
            district: 'พระนครศรีอยุธยา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13000,
        },
        {
            subDistrict: 'ท่าวาสุกรี',
            district: 'พระนครศรีอยุธยา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13000,
        },
        {
            subDistrict: 'บ้านป้อม',
            district: 'พระนครศรีอยุธยา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13000,
        },
        {
            subDistrict: 'บ้านรุน',
            district: 'พระนครศรีอยุธยา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13000,
        },
        {
            subDistrict: 'บ้านเกาะ',
            district: 'พระนครศรีอยุธยา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13000,
        },
        {
            subDistrict: 'บ้านใหม่',
            district: 'พระนครศรีอยุธยา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13000,
        },
        {
            subDistrict: 'ประตูชัย',
            district: 'พระนครศรีอยุธยา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13000,
        },
        {
            subDistrict: 'ปากกราน',
            district: 'พระนครศรีอยุธยา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13000,
        },
        {
            subDistrict: 'ภูเขาทอง',
            district: 'พระนครศรีอยุธยา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13000,
        },
        {
            subDistrict: 'ลุมพลี',
            district: 'พระนครศรีอยุธยา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13000,
        },
        {
            subDistrict: 'วัดตูม',
            district: 'พระนครศรีอยุธยา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13000,
        },
        {
            subDistrict: 'สวนพริก',
            district: 'พระนครศรีอยุธยา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13000,
        },
        {
            subDistrict: 'สำเภาล่ม',
            district: 'พระนครศรีอยุธยา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13000,
        },
        {
            subDistrict: 'หอรัตนไชย',
            district: 'พระนครศรีอยุธยา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13000,
        },
        {
            subDistrict: 'หันตรา',
            district: 'พระนครศรีอยุธยา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13000,
        },
        {
            subDistrict: 'หัวรอ',
            district: 'พระนครศรีอยุธยา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13000,
        },
        {
            subDistrict: 'เกาะเรียน',
            district: 'พระนครศรีอยุธยา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13000,
        },
        {
            subDistrict: 'ไผ่ลิง',
            district: 'พระนครศรีอยุธยา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13000,
        },
        {
            subDistrict: 'กระจิว',
            district: 'ภาชี',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13140,
        },
        {
            subDistrict: 'ดอนหญ้านาง',
            district: 'ภาชี',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13140,
        },
        {
            subDistrict: 'พระแก้ว',
            district: 'ภาชี',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13140,
        },
        {
            subDistrict: 'ภาชี',
            district: 'ภาชี',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13140,
        },
        {
            subDistrict: 'ระโสม',
            district: 'ภาชี',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13140,
        },
        {
            subDistrict: 'หนองน้ำใส',
            district: 'ภาชี',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13140,
        },
        {
            subDistrict: 'โคกม่วง',
            district: 'ภาชี',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13140,
        },
        {
            subDistrict: 'ไผ่ล้อม',
            district: 'ภาชี',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13140,
        },
        {
            subDistrict: 'กะทุ่ม',
            district: 'มหาราช',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13150,
        },
        {
            subDistrict: 'ท่าตอ',
            district: 'มหาราช',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13150,
        },
        {
            subDistrict: 'น้ำเต้า',
            district: 'มหาราช',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13150,
        },
        {
            subDistrict: 'บางนา',
            district: 'มหาราช',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13150,
        },
        {
            subDistrict: 'บ้านขวาง',
            district: 'มหาราช',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13150,
        },
        {
            subDistrict: 'บ้านนา',
            district: 'มหาราช',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13150,
        },
        {
            subDistrict: 'บ้านใหม่',
            district: 'มหาราช',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13150,
        },
        {
            subDistrict: 'พิตเพียน',
            district: 'มหาราช',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13150,
        },
        {
            subDistrict: 'มหาราช',
            district: 'มหาราช',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13150,
        },
        {
            subDistrict: 'หัวไผ่',
            district: 'มหาราช',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13150,
        },
        {
            subDistrict: 'เจ้าปลุก',
            district: 'มหาราช',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13150,
        },
        {
            subDistrict: 'โรงช้าง',
            district: 'มหาราช',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13150,
        },
        {
            subDistrict: 'คลองพระยาบันลือ',
            district: 'ลาดบัวหลวง',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13230,
        },
        {
            subDistrict: 'คู้สลอด',
            district: 'ลาดบัวหลวง',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13230,
        },
        {
            subDistrict: 'พระยาบันลือ',
            district: 'ลาดบัวหลวง',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13230,
        },
        {
            subDistrict: 'ลาดบัวหลวง',
            district: 'ลาดบัวหลวง',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13230,
        },
        {
            subDistrict: 'สามเมือง',
            district: 'ลาดบัวหลวง',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13230,
        },
        {
            subDistrict: 'สิงหนาท',
            district: 'ลาดบัวหลวง',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13230,
        },
        {
            subDistrict: 'หลักชัย',
            district: 'ลาดบัวหลวง',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13230,
        },
        {
            subDistrict: 'ข้าวงาม',
            district: 'วังน้อย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13170,
        },
        {
            subDistrict: 'ชะแมบ',
            district: 'วังน้อย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13170,
        },
        {
            subDistrict: 'บ่อตาโล่',
            district: 'วังน้อย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13170,
        },
        {
            subDistrict: 'พยอม',
            district: 'วังน้อย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13170,
        },
        {
            subDistrict: 'ลำตาเสา',
            district: 'วังน้อย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13170,
        },
        {
            subDistrict: 'ลำไทร',
            district: 'วังน้อย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13170,
        },
        {
            subDistrict: 'วังจุฬา',
            district: 'วังน้อย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13170,
        },
        {
            subDistrict: 'วังน้อย',
            district: 'วังน้อย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13170,
        },
        {
            subDistrict: 'สนับทึบ',
            district: 'วังน้อย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13170,
        },
        {
            subDistrict: 'หันตะเภา',
            district: 'วังน้อย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13170,
        },
        {
            subDistrict: 'ข้าวเม่า',
            district: 'อุทัย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13210,
        },
        {
            subDistrict: 'คานหาม',
            district: 'อุทัย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13210,
        },
        {
            subDistrict: 'ธนู',
            district: 'อุทัย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13210,
        },
        {
            subDistrict: 'บ้านช้าง',
            district: 'อุทัย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13210,
        },
        {
            subDistrict: 'บ้านหีบ',
            district: 'อุทัย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13210,
        },
        {
            subDistrict: 'สามบัณฑิต',
            district: 'อุทัย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13210,
        },
        {
            subDistrict: 'หนองน้ำส้ม',
            district: 'อุทัย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13210,
        },
        {
            subDistrict: 'หนองไม้ซุง',
            district: 'อุทัย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13210,
        },
        {
            subDistrict: 'อุทัย',
            district: 'อุทัย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13210,
        },
        {
            subDistrict: 'เสนา',
            district: 'อุทัย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13210,
        },
        {
            subDistrict: 'โพสาวหาญ',
            district: 'อุทัย',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13210,
        },
        {
            subDistrict: 'ชายนา',
            district: 'เสนา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13110,
        },
        {
            subDistrict: 'ดอนทอง',
            district: 'เสนา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13110,
        },
        {
            subDistrict: 'บางนมโค',
            district: 'เสนา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13110,
        },
        {
            subDistrict: 'บ้านกระทุ่ม',
            district: 'เสนา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13110,
        },
        {
            subDistrict: 'บ้านหลวง',
            district: 'เสนา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13110,
        },
        {
            subDistrict: 'บ้านแถว',
            district: 'เสนา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13110,
        },
        {
            subDistrict: 'บ้านแพน',
            district: 'เสนา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13110,
        },
        {
            subDistrict: 'บ้านโพธิ์',
            district: 'เสนา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13110,
        },
        {
            subDistrict: 'มารวิชัย',
            district: 'เสนา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13110,
        },
        {
            subDistrict: 'รางจรเข้',
            district: 'เสนา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13110,
        },
        {
            subDistrict: 'ลาดงา',
            district: 'เสนา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13110,
        },
        {
            subDistrict: 'สามกอ',
            district: 'เสนา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13110,
        },
        {
            subDistrict: 'สามตุ่ม',
            district: 'เสนา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13110,
        },
        {
            subDistrict: 'หัวเวียง',
            district: 'เสนา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13110,
        },
        {
            subDistrict: 'เจ้าเจ็ด',
            district: 'เสนา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13110,
        },
        {
            subDistrict: 'เจ้าเสด็จ',
            district: 'เสนา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13110,
        },
        {
            subDistrict: 'เสนา',
            district: 'เสนา',
            province: 'พระนครศรีอยุธยา',
            zipcode: 13110,
        },
        {
            subDistrict: 'จุน',
            district: 'จุน',
            province: 'พะเยา',
            zipcode: 56150,
        },
        {
            subDistrict: 'ทุ่งรวงทอง',
            district: 'จุน',
            province: 'พะเยา',
            zipcode: 56150,
        },
        {
            subDistrict: 'พระธาตุขิงแกง',
            district: 'จุน',
            province: 'พะเยา',
            zipcode: 56150,
        },
        {
            subDistrict: 'ลอ',
            district: 'จุน',
            province: 'พะเยา',
            zipcode: 56150,
        },
        {
            subDistrict: 'หงส์หิน',
            district: 'จุน',
            province: 'พะเยา',
            zipcode: 56150,
        },
        {
            subDistrict: 'ห้วยข้าวก่ำ',
            district: 'จุน',
            province: 'พะเยา',
            zipcode: 56150,
        },
        {
            subDistrict: 'ห้วยยางขาม',
            district: 'จุน',
            province: 'พะเยา',
            zipcode: 56150,
        },
        {
            subDistrict: 'คือเวียง',
            district: 'ดอกคำใต้',
            province: 'พะเยา',
            zipcode: 56120,
        },
        {
            subDistrict: 'ดงสุวรรณ',
            district: 'ดอกคำใต้',
            province: 'พะเยา',
            zipcode: 56120,
        },
        {
            subDistrict: 'ดอกคำใต้',
            district: 'ดอกคำใต้',
            province: 'พะเยา',
            zipcode: 56120,
        },
        {
            subDistrict: 'ดอนศรีชุม',
            district: 'ดอกคำใต้',
            province: 'พะเยา',
            zipcode: 56120,
        },
        {
            subDistrict: 'บุญเกิด',
            district: 'ดอกคำใต้',
            province: 'พะเยา',
            zipcode: 56120,
        },
        {
            subDistrict: 'บ้านถ้ำ',
            district: 'ดอกคำใต้',
            province: 'พะเยา',
            zipcode: 56120,
        },
        {
            subDistrict: 'บ้านปิน',
            district: 'ดอกคำใต้',
            province: 'พะเยา',
            zipcode: 56120,
        },
        {
            subDistrict: 'ป่าซาง',
            district: 'ดอกคำใต้',
            province: 'พะเยา',
            zipcode: 56120,
        },
        {
            subDistrict: 'สว่างอารมณ์',
            district: 'ดอกคำใต้',
            province: 'พะเยา',
            zipcode: 56120,
        },
        {
            subDistrict: 'สันโค้ง',
            district: 'ดอกคำใต้',
            province: 'พะเยา',
            zipcode: 56120,
        },
        {
            subDistrict: 'หนองหล่ม',
            district: 'ดอกคำใต้',
            province: 'พะเยา',
            zipcode: 56120,
        },
        {
            subDistrict: 'ห้วยลาน',
            district: 'ดอกคำใต้',
            province: 'พะเยา',
            zipcode: 56120,
        },
        {
            subDistrict: 'ขุนควร',
            district: 'ปง',
            province: 'พะเยา',
            zipcode: 56140,
        },
        {
            subDistrict: 'ควร',
            district: 'ปง',
            province: 'พะเยา',
            zipcode: 56140,
        },
        {
            subDistrict: 'งิม',
            district: 'ปง',
            province: 'พะเยา',
            zipcode: 56140,
        },
        {
            subDistrict: 'นาปรัง',
            district: 'ปง',
            province: 'พะเยา',
            zipcode: 56140,
        },
        {
            subDistrict: 'ปง',
            district: 'ปง',
            province: 'พะเยา',
            zipcode: 56140,
        },
        {
            subDistrict: 'ผาช้างน้อย',
            district: 'ปง',
            province: 'พะเยา',
            zipcode: 56140,
        },
        {
            subDistrict: 'ออย',
            district: 'ปง',
            province: 'พะเยา',
            zipcode: 56140,
        },
        {
            subDistrict: 'ดงเจน',
            district: 'ภูกามยาว',
            province: 'พะเยา',
            zipcode: 56000,
        },
        {
            subDistrict: 'ห้วยแก้ว',
            district: 'ภูกามยาว',
            province: 'พะเยา',
            zipcode: 56000,
        },
        {
            subDistrict: 'แม่อิง',
            district: 'ภูกามยาว',
            province: 'พะเยา',
            zipcode: 56000,
        },
        {
            subDistrict: 'ทุ่งกล้วย',
            district: 'ภูซาง',
            province: 'พะเยา',
            zipcode: 56110,
        },
        {
            subDistrict: 'ป่าสัก',
            district: 'ภูซาง',
            province: 'พะเยา',
            zipcode: 56110,
        },
        {
            subDistrict: 'ภูซาง',
            district: 'ภูซาง',
            province: 'พะเยา',
            zipcode: 56110,
        },
        {
            subDistrict: 'สบบง',
            district: 'ภูซาง',
            province: 'พะเยา',
            zipcode: 56110,
        },
        {
            subDistrict: 'เชียงแรง',
            district: 'ภูซาง',
            province: 'พะเยา',
            zipcode: 56110,
        },
        {
            subDistrict: 'ทุ่งผาสุข',
            district: 'เชียงคำ',
            province: 'พะเยา',
            zipcode: 56110,
        },
        {
            subDistrict: 'น้ำแวน',
            district: 'เชียงคำ',
            province: 'พะเยา',
            zipcode: 56110,
        },
        {
            subDistrict: 'ฝายกวาง',
            district: 'เชียงคำ',
            province: 'พะเยา',
            zipcode: 56110,
        },
        {
            subDistrict: 'ร่มเย็น',
            district: 'เชียงคำ',
            province: 'พะเยา',
            zipcode: 56110,
        },
        {
            subDistrict: 'หย่วน',
            district: 'เชียงคำ',
            province: 'พะเยา',
            zipcode: 56110,
        },
        {
            subDistrict: 'อ่างทอง',
            district: 'เชียงคำ',
            province: 'พะเยา',
            zipcode: 56110,
        },
        {
            subDistrict: 'เจดีย์คำ',
            district: 'เชียงคำ',
            province: 'พะเยา',
            zipcode: 56110,
        },
        {
            subDistrict: 'เชียงบาน',
            district: 'เชียงคำ',
            province: 'พะเยา',
            zipcode: 56110,
        },
        {
            subDistrict: 'เวียง',
            district: 'เชียงคำ',
            province: 'พะเยา',
            zipcode: 56110,
        },
        {
            subDistrict: 'แม่ลาว',
            district: 'เชียงคำ',
            province: 'พะเยา',
            zipcode: 56110,
        },
        {
            subDistrict: 'บ้านมาง',
            district: 'เชียงม่วน',
            province: 'พะเยา',
            zipcode: 56160,
        },
        {
            subDistrict: 'สระ',
            district: 'เชียงม่วน',
            province: 'พะเยา',
            zipcode: 56160,
        },
        {
            subDistrict: 'เชียงม่วน',
            district: 'เชียงม่วน',
            province: 'พะเยา',
            zipcode: 56160,
        },
        {
            subDistrict: 'จำป่าหวาย',
            district: 'เมืองพะเยา',
            province: 'พะเยา',
            zipcode: 56000,
        },
        {
            subDistrict: 'ท่าจำปี',
            district: 'เมืองพะเยา',
            province: 'พะเยา',
            zipcode: 56000,
        },
        {
            subDistrict: 'ท่าวังทอง',
            district: 'เมืองพะเยา',
            province: 'พะเยา',
            zipcode: 56000,
        },
        {
            subDistrict: 'บ้านตุ่น',
            district: 'เมืองพะเยา',
            province: 'พะเยา',
            zipcode: 56000,
        },
        {
            subDistrict: 'บ้านต๊ำ',
            district: 'เมืองพะเยา',
            province: 'พะเยา',
            zipcode: 56000,
        },
        {
            subDistrict: 'บ้านต๋อม',
            district: 'เมืองพะเยา',
            province: 'พะเยา',
            zipcode: 56000,
        },
        {
            subDistrict: 'บ้านสาง',
            district: 'เมืองพะเยา',
            province: 'พะเยา',
            zipcode: 56000,
        },
        {
            subDistrict: 'บ้านใหม่',
            district: 'เมืองพะเยา',
            province: 'พะเยา',
            zipcode: 56000,
        },
        {
            subDistrict: 'สันป่าม่วง',
            district: 'เมืองพะเยา',
            province: 'พะเยา',
            zipcode: 56000,
        },
        {
            subDistrict: 'เวียง',
            district: 'เมืองพะเยา',
            province: 'พะเยา',
            zipcode: 56000,
        },
        {
            subDistrict: 'แม่กา',
            district: 'เมืองพะเยา',
            province: 'พะเยา',
            zipcode: 56000,
        },
        {
            subDistrict: 'แม่ต๋ำ',
            district: 'เมืองพะเยา',
            province: 'พะเยา',
            zipcode: 56000,
        },
        {
            subDistrict: 'แม่นาเรือ',
            district: 'เมืองพะเยา',
            province: 'พะเยา',
            zipcode: 56000,
        },
        {
            subDistrict: 'แม่ปืม',
            district: 'เมืองพะเยา',
            province: 'พะเยา',
            zipcode: 56000,
        },
        {
            subDistrict: 'แม่ใส',
            district: 'เมืองพะเยา',
            province: 'พะเยา',
            zipcode: 56000,
        },
        {
            subDistrict: 'บ้านเหล่า',
            district: 'แม่ใจ',
            province: 'พะเยา',
            zipcode: 56130,
        },
        {
            subDistrict: 'ป่าแฝก',
            district: 'แม่ใจ',
            province: 'พะเยา',
            zipcode: 56130,
        },
        {
            subDistrict: 'ศรีถ้อย',
            district: 'แม่ใจ',
            province: 'พะเยา',
            zipcode: 56130,
        },
        {
            subDistrict: 'เจริญราษฎร์',
            district: 'แม่ใจ',
            province: 'พะเยา',
            zipcode: 56130,
        },
        {
            subDistrict: 'แม่สุก',
            district: 'แม่ใจ',
            province: 'พะเยา',
            zipcode: 56130,
        },
        {
            subDistrict: 'แม่ใจ',
            district: 'แม่ใจ',
            province: 'พะเยา',
            zipcode: 56130,
        },
        {
            subDistrict: 'กะปง',
            district: 'กะปง',
            province: 'พังงา',
            zipcode: 82170,
        },
        {
            subDistrict: 'ท่านา',
            district: 'กะปง',
            province: 'พังงา',
            zipcode: 82170,
        },
        {
            subDistrict: 'รมณีย์',
            district: 'กะปง',
            province: 'พังงา',
            zipcode: 82170,
        },
        {
            subDistrict: 'เหมาะ',
            district: 'กะปง',
            province: 'พังงา',
            zipcode: 82170,
        },
        {
            subDistrict: 'เหล',
            district: 'กะปง',
            province: 'พังงา',
            zipcode: 82170,
        },
        {
            subDistrict: 'คุระ',
            district: 'คุระบุรี',
            province: 'พังงา',
            zipcode: 82150,
        },
        {
            subDistrict: 'บางวัน',
            district: 'คุระบุรี',
            province: 'พังงา',
            zipcode: 82150,
        },
        {
            subDistrict: 'เกาะพระทอง',
            district: 'คุระบุรี',
            province: 'พังงา',
            zipcode: 82150,
        },
        {
            subDistrict: 'แม่นางขาว',
            district: 'คุระบุรี',
            province: 'พังงา',
            zipcode: 82150,
        },
        {
            subDistrict: 'กระโสม',
            district: 'ตะกั่วทุ่ง',
            province: 'พังงา',
            zipcode: 82130,
        },
        {
            subDistrict: 'กะไหล',
            district: 'ตะกั่วทุ่ง',
            province: 'พังงา',
            zipcode: 82130,
        },
        {
            subDistrict: 'คลองเคียน',
            district: 'ตะกั่วทุ่ง',
            province: 'พังงา',
            zipcode: 82130,
        },
        {
            subDistrict: 'ถ้ำ',
            district: 'ตะกั่วทุ่ง',
            province: 'พังงา',
            zipcode: 82130,
        },
        {
            subDistrict: 'ท่าอยู่',
            district: 'ตะกั่วทุ่ง',
            province: 'พังงา',
            zipcode: 82130,
        },
        {
            subDistrict: 'หล่อยูง',
            district: 'ตะกั่วทุ่ง',
            province: 'พังงา',
            zipcode: 82140,
        },
        {
            subDistrict: 'โคกกลอย',
            district: 'ตะกั่วทุ่ง',
            province: 'พังงา',
            zipcode: 82140,
        },
        {
            subDistrict: 'คึกคัก',
            district: 'ตะกั่วป่า',
            province: 'พังงา',
            zipcode: 82220,
        },
        {
            subDistrict: 'ตะกั่วป่า',
            district: 'ตะกั่วป่า',
            province: 'พังงา',
            zipcode: 82110,
        },
        {
            subDistrict: 'ตำตัว',
            district: 'ตะกั่วป่า',
            province: 'พังงา',
            zipcode: 82110,
        },
        {
            subDistrict: 'บางนายสี',
            district: 'ตะกั่วป่า',
            province: 'พังงา',
            zipcode: 82110,
        },
        {
            subDistrict: 'บางม่วง',
            district: 'ตะกั่วป่า',
            province: 'พังงา',
            zipcode: 82190,
        },
        {
            subDistrict: 'บางไทร',
            district: 'ตะกั่วป่า',
            province: 'พังงา',
            zipcode: 82110,
        },
        {
            subDistrict: 'เกาะคอเขา',
            district: 'ตะกั่วป่า',
            province: 'พังงา',
            zipcode: 82190,
        },
        {
            subDistrict: 'โคกเคียน',
            district: 'ตะกั่วป่า',
            province: 'พังงา',
            zipcode: 82110,
        },
        {
            subDistrict: 'ถ้ำทองหลาง',
            district: 'ทับปุด',
            province: 'พังงา',
            zipcode: 82180,
        },
        {
            subDistrict: 'ทับปุด',
            district: 'ทับปุด',
            province: 'พังงา',
            zipcode: 82180,
        },
        {
            subDistrict: 'บางเหรียง',
            district: 'ทับปุด',
            province: 'พังงา',
            zipcode: 82180,
        },
        {
            subDistrict: 'บ่อแสน',
            district: 'ทับปุด',
            province: 'พังงา',
            zipcode: 82180,
        },
        {
            subDistrict: 'มะรุ่ย',
            district: 'ทับปุด',
            province: 'พังงา',
            zipcode: 82180,
        },
        {
            subDistrict: 'โคกเจริญ',
            district: 'ทับปุด',
            province: 'พังงา',
            zipcode: 82180,
        },
        {
            subDistrict: 'ทุ่งมะพร้าว',
            district: 'ท้ายเหมือง',
            province: 'พังงา',
            zipcode: 82120,
        },
        {
            subDistrict: 'ท้ายเหมือง',
            district: 'ท้ายเหมือง',
            province: 'พังงา',
            zipcode: 82120,
        },
        {
            subDistrict: 'นาเตย',
            district: 'ท้ายเหมือง',
            province: 'พังงา',
            zipcode: 82120,
        },
        {
            subDistrict: 'บางทอง',
            district: 'ท้ายเหมือง',
            province: 'พังงา',
            zipcode: 82120,
        },
        {
            subDistrict: 'ลำภี',
            district: 'ท้ายเหมือง',
            province: 'พังงา',
            zipcode: 82120,
        },
        {
            subDistrict: 'ลำแก่น',
            district: 'ท้ายเหมือง',
            province: 'พังงา',
            zipcode: 82210,
        },
        {
            subDistrict: 'พรุใน',
            district: 'เกาะยาว',
            province: 'พังงา',
            zipcode: 83000,
        },
        {
            subDistrict: 'เกาะยาวน้อย',
            district: 'เกาะยาว',
            province: 'พังงา',
            zipcode: 82160,
        },
        {
            subDistrict: 'เกาะยาวใหญ่',
            district: 'เกาะยาว',
            province: 'พังงา',
            zipcode: 82160,
        },
        {
            subDistrict: 'ตากแดด',
            district: 'เมืองพังงา',
            province: 'พังงา',
            zipcode: 82000,
        },
        {
            subDistrict: 'ถ้ำน้ำผุด',
            district: 'เมืองพังงา',
            province: 'พังงา',
            zipcode: 82000,
        },
        {
            subDistrict: 'ทุ่งคาโงก',
            district: 'เมืองพังงา',
            province: 'พังงา',
            zipcode: 82000,
        },
        {
            subDistrict: 'ท้ายช้าง',
            district: 'เมืองพังงา',
            province: 'พังงา',
            zipcode: 82000,
        },
        {
            subDistrict: 'นบปริง',
            district: 'เมืองพังงา',
            province: 'พังงา',
            zipcode: 82000,
        },
        {
            subDistrict: 'บางเตย',
            district: 'เมืองพังงา',
            province: 'พังงา',
            zipcode: 82000,
        },
        {
            subDistrict: 'ป่ากอ',
            district: 'เมืองพังงา',
            province: 'พังงา',
            zipcode: 82000,
        },
        {
            subDistrict: 'สองแพรก',
            district: 'เมืองพังงา',
            province: 'พังงา',
            zipcode: 82000,
        },
        {
            subDistrict: 'เกาะปันหยี',
            district: 'เมืองพังงา',
            province: 'พังงา',
            zipcode: 82000,
        },
        {
            subDistrict: 'กงหรา',
            district: 'กงหรา',
            province: 'พัทลุง',
            zipcode: 93180,
        },
        {
            subDistrict: 'คลองทรายขาว',
            district: 'กงหรา',
            province: 'พัทลุง',
            zipcode: 93180,
        },
        {
            subDistrict: 'คลองเฉลิม',
            district: 'กงหรา',
            province: 'พัทลุง',
            zipcode: 93180,
        },
        {
            subDistrict: 'ชะรัด',
            district: 'กงหรา',
            province: 'พัทลุง',
            zipcode: 93000,
        },
        {
            subDistrict: 'สมหวัง',
            district: 'กงหรา',
            province: 'พัทลุง',
            zipcode: 93000,
        },
        {
            subDistrict: 'ควนขนุน',
            district: 'ควนขนุน',
            province: 'พัทลุง',
            zipcode: 93110,
        },
        {
            subDistrict: 'ชะมวง',
            district: 'ควนขนุน',
            province: 'พัทลุง',
            zipcode: 93110,
        },
        {
            subDistrict: 'ดอนทราย',
            district: 'ควนขนุน',
            province: 'พัทลุง',
            zipcode: 93110,
        },
        {
            subDistrict: 'ทะเลน้อย',
            district: 'ควนขนุน',
            province: 'พัทลุง',
            zipcode: 93150,
        },
        {
            subDistrict: 'นาขยาด',
            district: 'ควนขนุน',
            province: 'พัทลุง',
            zipcode: 93110,
        },
        {
            subDistrict: 'ปันแต',
            district: 'ควนขนุน',
            province: 'พัทลุง',
            zipcode: 93110,
        },
        {
            subDistrict: 'พนมวังก์',
            district: 'ควนขนุน',
            province: 'พัทลุง',
            zipcode: 93110,
        },
        {
            subDistrict: 'พนางตุง',
            district: 'ควนขนุน',
            province: 'พัทลุง',
            zipcode: 93150,
        },
        {
            subDistrict: 'มะกอกเหนือ',
            district: 'ควนขนุน',
            province: 'พัทลุง',
            zipcode: 93150,
        },
        {
            subDistrict: 'แพรกหา',
            district: 'ควนขนุน',
            province: 'พัทลุง',
            zipcode: 93110,
        },
        {
            subDistrict: 'แหลมโตนด',
            district: 'ควนขนุน',
            province: 'พัทลุง',
            zipcode: 93110,
        },
        {
            subDistrict: 'โตนดด้วน',
            district: 'ควนขนุน',
            province: 'พัทลุง',
            zipcode: 93110,
        },
        {
            subDistrict: 'คลองใหญ่',
            district: 'ตะโหมด',
            province: 'พัทลุง',
            zipcode: 93160,
        },
        {
            subDistrict: 'ตะโหมด',
            district: 'ตะโหมด',
            province: 'พัทลุง',
            zipcode: 93160,
        },
        {
            subDistrict: 'แม่ขรี',
            district: 'ตะโหมด',
            province: 'พัทลุง',
            zipcode: 93160,
        },
        {
            subDistrict: 'ท่ามะเดื่อ',
            district: 'บางแก้ว',
            province: 'พัทลุง',
            zipcode: 93140,
        },
        {
            subDistrict: 'นาปะขอ',
            district: 'บางแก้ว',
            province: 'พัทลุง',
            zipcode: 93140,
        },
        {
            subDistrict: 'โคกสัก',
            district: 'บางแก้ว',
            province: 'พัทลุง',
            zipcode: 93140,
        },
        {
            subDistrict: 'ดอนทราย',
            district: 'ปากพะยูน',
            province: 'พัทลุง',
            zipcode: 93120,
        },
        {
            subDistrict: 'ดอนประดู่',
            district: 'ปากพะยูน',
            province: 'พัทลุง',
            zipcode: 93120,
        },
        {
            subDistrict: 'ปากพะยูน',
            district: 'ปากพะยูน',
            province: 'พัทลุง',
            zipcode: 93120,
        },
        {
            subDistrict: 'ฝาละมี',
            district: 'ปากพะยูน',
            province: 'พัทลุง',
            zipcode: 93120,
        },
        {
            subDistrict: 'หารเทา',
            district: 'ปากพะยูน',
            province: 'พัทลุง',
            zipcode: 93120,
        },
        {
            subDistrict: 'เกาะนางคำ',
            district: 'ปากพะยูน',
            province: 'พัทลุง',
            zipcode: 93120,
        },
        {
            subDistrict: 'เกาะหมาก',
            district: 'ปากพะยูน',
            province: 'พัทลุง',
            zipcode: 93120,
        },
        {
            subDistrict: 'ทุ่งนารี',
            district: 'ป่าบอน',
            province: 'พัทลุง',
            zipcode: 93170,
        },
        {
            subDistrict: 'ป่าบอน',
            district: 'ป่าบอน',
            province: 'พัทลุง',
            zipcode: 93170,
        },
        {
            subDistrict: 'วังใหม่',
            district: 'ป่าบอน',
            province: 'พัทลุง',
            zipcode: 93170,
        },
        {
            subDistrict: 'หนองธง',
            district: 'ป่าบอน',
            province: 'พัทลุง',
            zipcode: 93170,
        },
        {
            subDistrict: 'โคกทราย',
            district: 'ป่าบอน',
            province: 'พัทลุง',
            zipcode: 93170,
        },
        {
            subDistrict: 'บ้านพร้าว',
            district: 'ป่าพะยอม',
            province: 'พัทลุง',
            zipcode: 93210,
        },
        {
            subDistrict: 'ป่าพะยอม',
            district: 'ป่าพะยอม',
            province: 'พัทลุง',
            zipcode: 93210,
        },
        {
            subDistrict: 'ลานข่อย',
            district: 'ป่าพะยอม',
            province: 'พัทลุง',
            zipcode: 93210,
        },
        {
            subDistrict: 'เกาะเต่า',
            district: 'ป่าพะยอม',
            province: 'พัทลุง',
            zipcode: 93210,
        },
        {
            subDistrict: 'ชุมพล',
            district: 'ศรีนครินทร์',
            province: 'พัทลุง',
            zipcode: 93000,
        },
        {
            subDistrict: 'บ้านนา',
            district: 'ศรีนครินทร์',
            province: 'พัทลุง',
            zipcode: 93000,
        },
        {
            subDistrict: 'ลำสินธุ์',
            district: 'ศรีนครินทร์',
            province: 'พัทลุง',
            zipcode: 93000,
        },
        {
            subDistrict: 'อ่างทอง',
            district: 'ศรีนครินทร์',
            province: 'พัทลุง',
            zipcode: 93000,
        },
        {
            subDistrict: 'ตะแพน',
            district: 'ศรีบรรพต',
            province: 'พัทลุง',
            zipcode: 93190,
        },
        {
            subDistrict: 'เขาปู่',
            district: 'ศรีบรรพต',
            province: 'พัทลุง',
            zipcode: 93190,
        },
        {
            subDistrict: 'เขาย่า',
            district: 'ศรีบรรพต',
            province: 'พัทลุง',
            zipcode: 93190,
        },
        {
            subDistrict: 'ควนขนุน',
            district: 'เขาชัยสน',
            province: 'พัทลุง',
            zipcode: 93130,
        },
        {
            subDistrict: 'จองถนน',
            district: 'เขาชัยสน',
            province: 'พัทลุง',
            zipcode: 93130,
        },
        {
            subDistrict: 'หานโพธิ์',
            district: 'เขาชัยสน',
            province: 'พัทลุง',
            zipcode: 93130,
        },
        {
            subDistrict: 'เขาชัยสน',
            district: 'เขาชัยสน',
            province: 'พัทลุง',
            zipcode: 93130,
        },
        {
            subDistrict: 'โคกม่วง',
            district: 'เขาชัยสน',
            province: 'พัทลุง',
            zipcode: 93130,
        },
        {
            subDistrict: 'ควนมะพร้าว',
            district: 'เมืองพัทลุง',
            province: 'พัทลุง',
            zipcode: 93000,
        },
        {
            subDistrict: 'คูหาสวรรค์',
            district: 'เมืองพัทลุง',
            province: 'พัทลุง',
            zipcode: 93000,
        },
        {
            subDistrict: 'ชัยบุรี',
            district: 'เมืองพัทลุง',
            province: 'พัทลุง',
            zipcode: 93000,
        },
        {
            subDistrict: 'ตำนาน',
            district: 'เมืองพัทลุง',
            province: 'พัทลุง',
            zipcode: 93000,
        },
        {
            subDistrict: 'ท่ามิหรำ',
            district: 'เมืองพัทลุง',
            province: 'พัทลุง',
            zipcode: 93000,
        },
        {
            subDistrict: 'ท่าแค',
            district: 'เมืองพัทลุง',
            province: 'พัทลุง',
            zipcode: 93000,
        },
        {
            subDistrict: 'นาท่อม',
            district: 'เมืองพัทลุง',
            province: 'พัทลุง',
            zipcode: 93000,
        },
        {
            subDistrict: 'นาโหนด',
            district: 'เมืองพัทลุง',
            province: 'พัทลุง',
            zipcode: 93000,
        },
        {
            subDistrict: 'ปรางหมู่',
            district: 'เมืองพัทลุง',
            province: 'พัทลุง',
            zipcode: 93000,
        },
        {
            subDistrict: 'พญาขัน',
            district: 'เมืองพัทลุง',
            province: 'พัทลุง',
            zipcode: 93000,
        },
        {
            subDistrict: 'ร่มเมือง',
            district: 'เมืองพัทลุง',
            province: 'พัทลุง',
            zipcode: 93000,
        },
        {
            subDistrict: 'ลำปำ',
            district: 'เมืองพัทลุง',
            province: 'พัทลุง',
            zipcode: 93000,
        },
        {
            subDistrict: 'เขาเจียก',
            district: 'เมืองพัทลุง',
            province: 'พัทลุง',
            zipcode: 93000,
        },
        {
            subDistrict: 'โคกชะงาย',
            district: 'เมืองพัทลุง',
            province: 'พัทลุง',
            zipcode: 93000,
        },
        {
            subDistrict: 'วังงิ้ว',
            district: 'ดงเจริญ',
            province: 'พิจิตร',
            zipcode: 66210,
        },
        {
            subDistrict: 'วังงิ้วใต้',
            district: 'ดงเจริญ',
            province: 'พิจิตร',
            zipcode: 66210,
        },
        {
            subDistrict: 'สำนักขุนเณร',
            district: 'ดงเจริญ',
            province: 'พิจิตร',
            zipcode: 66210,
        },
        {
            subDistrict: 'ห้วยพุก',
            district: 'ดงเจริญ',
            province: 'พิจิตร',
            zipcode: 66210,
        },
        {
            subDistrict: 'ห้วยร่วม',
            district: 'ดงเจริญ',
            province: 'พิจิตร',
            zipcode: 66210,
        },
        {
            subDistrict: 'คลองคูณ',
            district: 'ตะพานหิน',
            province: 'พิจิตร',
            zipcode: 66110,
        },
        {
            subDistrict: 'งิ้วราย',
            district: 'ตะพานหิน',
            province: 'พิจิตร',
            zipcode: 66110,
        },
        {
            subDistrict: 'ดงตะขบ',
            district: 'ตะพานหิน',
            province: 'พิจิตร',
            zipcode: 66110,
        },
        {
            subDistrict: 'ตะพานหิน',
            district: 'ตะพานหิน',
            province: 'พิจิตร',
            zipcode: 66110,
        },
        {
            subDistrict: 'ทับหมัน',
            district: 'ตะพานหิน',
            province: 'พิจิตร',
            zipcode: 66110,
        },
        {
            subDistrict: 'ทุ่งโพธิ์',
            district: 'ตะพานหิน',
            province: 'พิจิตร',
            zipcode: 66150,
        },
        {
            subDistrict: 'วังสำโรง',
            district: 'ตะพานหิน',
            province: 'พิจิตร',
            zipcode: 66110,
        },
        {
            subDistrict: 'วังหลุม',
            district: 'ตะพานหิน',
            province: 'พิจิตร',
            zipcode: 66150,
        },
        {
            subDistrict: 'วังหว้า',
            district: 'ตะพานหิน',
            province: 'พิจิตร',
            zipcode: 66110,
        },
        {
            subDistrict: 'หนองพยอม',
            district: 'ตะพานหิน',
            province: 'พิจิตร',
            zipcode: 66110,
        },
        {
            subDistrict: 'ห้วยเกตุ',
            district: 'ตะพานหิน',
            province: 'พิจิตร',
            zipcode: 66110,
        },
        {
            subDistrict: 'ไทรโรงโขน',
            district: 'ตะพานหิน',
            province: 'พิจิตร',
            zipcode: 66110,
        },
        {
            subDistrict: 'ไผ่หลวง',
            district: 'ตะพานหิน',
            province: 'พิจิตร',
            zipcode: 66110,
        },
        {
            subDistrict: 'ทับคล้อ',
            district: 'ทับคล้อ',
            province: 'พิจิตร',
            zipcode: 66150,
        },
        {
            subDistrict: 'ท้ายทุ่ง',
            district: 'ทับคล้อ',
            province: 'พิจิตร',
            zipcode: 66150,
        },
        {
            subDistrict: 'เขาทราย',
            district: 'ทับคล้อ',
            province: 'พิจิตร',
            zipcode: 66230,
        },
        {
            subDistrict: 'เขาเจ็ดลูก',
            district: 'ทับคล้อ',
            province: 'พิจิตร',
            zipcode: 66230,
        },
        {
            subDistrict: 'บางมูลนาก',
            district: 'บางมูลนาก',
            province: 'พิจิตร',
            zipcode: 66120,
        },
        {
            subDistrict: 'บางไผ่',
            district: 'บางมูลนาก',
            province: 'พิจิตร',
            zipcode: 66120,
        },
        {
            subDistrict: 'ภูมิ',
            district: 'บางมูลนาก',
            province: 'พิจิตร',
            zipcode: 66120,
        },
        {
            subDistrict: 'ลำประดา',
            district: 'บางมูลนาก',
            province: 'พิจิตร',
            zipcode: 66120,
        },
        {
            subDistrict: 'วังกรด',
            district: 'บางมูลนาก',
            province: 'พิจิตร',
            zipcode: 66120,
        },
        {
            subDistrict: 'วังตะกู',
            district: 'บางมูลนาก',
            province: 'พิจิตร',
            zipcode: 66210,
        },
        {
            subDistrict: 'วังสำโรง',
            district: 'บางมูลนาก',
            province: 'พิจิตร',
            zipcode: 66120,
        },
        {
            subDistrict: 'หอไกร',
            district: 'บางมูลนาก',
            province: 'พิจิตร',
            zipcode: 66120,
        },
        {
            subDistrict: 'ห้วยเขน',
            district: 'บางมูลนาก',
            province: 'พิจิตร',
            zipcode: 66120,
        },
        {
            subDistrict: 'เนินมะกอก',
            district: 'บางมูลนาก',
            province: 'พิจิตร',
            zipcode: 66120,
        },
        {
            subDistrict: 'บางลาย',
            district: 'บึงนาราง',
            province: 'พิจิตร',
            zipcode: 66130,
        },
        {
            subDistrict: 'บึงนาราง',
            district: 'บึงนาราง',
            province: 'พิจิตร',
            zipcode: 66130,
        },
        {
            subDistrict: 'ห้วยแก้ว',
            district: 'บึงนาราง',
            province: 'พิจิตร',
            zipcode: 66130,
        },
        {
            subDistrict: 'แหลมรัง',
            district: 'บึงนาราง',
            province: 'พิจิตร',
            zipcode: 66130,
        },
        {
            subDistrict: 'โพธิ์ไทรงาม',
            district: 'บึงนาราง',
            province: 'พิจิตร',
            zipcode: 66130,
        },
        {
            subDistrict: 'บึงบัว',
            district: 'วชิรบารมี',
            province: 'พิจิตร',
            zipcode: 66140,
        },
        {
            subDistrict: 'บ้านนา',
            district: 'วชิรบารมี',
            province: 'พิจิตร',
            zipcode: 66140,
        },
        {
            subDistrict: 'วังโมกข์',
            district: 'วชิรบารมี',
            province: 'พิจิตร',
            zipcode: 66140,
        },
        {
            subDistrict: 'หนองหลุม',
            district: 'วชิรบารมี',
            province: 'พิจิตร',
            zipcode: 66220,
        },
        {
            subDistrict: 'วังทรายพูน',
            district: 'วังทรายพูน',
            province: 'พิจิตร',
            zipcode: 66180,
        },
        {
            subDistrict: 'หนองปลาไหล',
            district: 'วังทรายพูน',
            province: 'พิจิตร',
            zipcode: 66180,
        },
        {
            subDistrict: 'หนองปล้อง',
            district: 'วังทรายพูน',
            province: 'พิจิตร',
            zipcode: 66180,
        },
        {
            subDistrict: 'หนองพระ',
            district: 'วังทรายพูน',
            province: 'พิจิตร',
            zipcode: 66180,
        },
        {
            subDistrict: 'คลองทราย',
            district: 'สากเหล็ก',
            province: 'พิจิตร',
            zipcode: 66160,
        },
        {
            subDistrict: 'ท่าเยี่ยม',
            district: 'สากเหล็ก',
            province: 'พิจิตร',
            zipcode: 66160,
        },
        {
            subDistrict: 'วังทับไทร',
            district: 'สากเหล็ก',
            province: 'พิจิตร',
            zipcode: 66160,
        },
        {
            subDistrict: 'สากเหล็ก',
            district: 'สากเหล็ก',
            province: 'พิจิตร',
            zipcode: 66160,
        },
        {
            subDistrict: 'หนองหญ้าไทร',
            district: 'สากเหล็ก',
            province: 'พิจิตร',
            zipcode: 66160,
        },
        {
            subDistrict: 'กำแพงดิน',
            district: 'สามง่าม',
            province: 'พิจิตร',
            zipcode: 66220,
        },
        {
            subDistrict: 'รังนก',
            district: 'สามง่าม',
            province: 'พิจิตร',
            zipcode: 66140,
        },
        {
            subDistrict: 'สามง่าม',
            district: 'สามง่าม',
            province: 'พิจิตร',
            zipcode: 66140,
        },
        {
            subDistrict: 'หนองโสน',
            district: 'สามง่าม',
            province: 'พิจิตร',
            zipcode: 66140,
        },
        {
            subDistrict: 'เนินปอ',
            district: 'สามง่าม',
            province: 'พิจิตร',
            zipcode: 66140,
        },
        {
            subDistrict: 'คลองคะเชนทร์',
            district: 'เมืองพิจิตร',
            province: 'พิจิตร',
            zipcode: 66000,
        },
        {
            subDistrict: 'ฆะมัง',
            district: 'เมืองพิจิตร',
            province: 'พิจิตร',
            zipcode: 66000,
        },
        {
            subDistrict: 'ดงกลาง',
            district: 'เมืองพิจิตร',
            province: 'พิจิตร',
            zipcode: 66170,
        },
        {
            subDistrict: 'ดงป่าคำ',
            district: 'เมืองพิจิตร',
            province: 'พิจิตร',
            zipcode: 66170,
        },
        {
            subDistrict: 'ท่าหลวง',
            district: 'เมืองพิจิตร',
            province: 'พิจิตร',
            zipcode: 66000,
        },
        {
            subDistrict: 'ท่าฬ่อ',
            district: 'เมืองพิจิตร',
            province: 'พิจิตร',
            zipcode: 66000,
        },
        {
            subDistrict: 'บ้านบุ่ง',
            district: 'เมืองพิจิตร',
            province: 'พิจิตร',
            zipcode: 66000,
        },
        {
            subDistrict: 'ปากทาง',
            district: 'เมืองพิจิตร',
            province: 'พิจิตร',
            zipcode: 66000,
        },
        {
            subDistrict: 'ป่ามะคาบ',
            district: 'เมืองพิจิตร',
            province: 'พิจิตร',
            zipcode: 66000,
        },
        {
            subDistrict: 'ย่านยาว',
            district: 'เมืองพิจิตร',
            province: 'พิจิตร',
            zipcode: 66000,
        },
        {
            subDistrict: 'สายคำโห้',
            district: 'เมืองพิจิตร',
            province: 'พิจิตร',
            zipcode: 66000,
        },
        {
            subDistrict: 'หัวดง',
            district: 'เมืองพิจิตร',
            province: 'พิจิตร',
            zipcode: 66170,
        },
        {
            subDistrict: 'เมืองเก่า',
            district: 'เมืองพิจิตร',
            province: 'พิจิตร',
            zipcode: 66000,
        },
        {
            subDistrict: 'โรงช้าง',
            district: 'เมืองพิจิตร',
            province: 'พิจิตร',
            zipcode: 66000,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'เมืองพิจิตร',
            province: 'พิจิตร',
            zipcode: 66000,
        },
        {
            subDistrict: 'ไผ่ขวาง',
            district: 'เมืองพิจิตร',
            province: 'พิจิตร',
            zipcode: 66000,
        },
        {
            subDistrict: 'ทะนง',
            district: 'โพทะเล',
            province: 'พิจิตร',
            zipcode: 66130,
        },
        {
            subDistrict: 'ทุ่งน้อย',
            district: 'โพทะเล',
            province: 'พิจิตร',
            zipcode: 66130,
        },
        {
            subDistrict: 'ท่าขมิ้น',
            district: 'โพทะเล',
            province: 'พิจิตร',
            zipcode: 66130,
        },
        {
            subDistrict: 'ท่านั่ง',
            district: 'โพทะเล',
            province: 'พิจิตร',
            zipcode: 66130,
        },
        {
            subDistrict: 'ท่าบัว',
            district: 'โพทะเล',
            province: 'พิจิตร',
            zipcode: 66130,
        },
        {
            subDistrict: 'ท่าเสา',
            district: 'โพทะเล',
            province: 'พิจิตร',
            zipcode: 66130,
        },
        {
            subDistrict: 'ท้ายน้ำ',
            district: 'โพทะเล',
            province: 'พิจิตร',
            zipcode: 66130,
        },
        {
            subDistrict: 'บางคลาน',
            district: 'โพทะเล',
            province: 'พิจิตร',
            zipcode: 66130,
        },
        {
            subDistrict: 'บ้านน้อย',
            district: 'โพทะเล',
            province: 'พิจิตร',
            zipcode: 66130,
        },
        {
            subDistrict: 'วัดขวาง',
            district: 'โพทะเล',
            province: 'พิจิตร',
            zipcode: 66130,
        },
        {
            subDistrict: 'โพทะเล',
            district: 'โพทะเล',
            province: 'พิจิตร',
            zipcode: 66130,
        },
        {
            subDistrict: 'ดงเสือเหลือง',
            district: 'โพธิ์ประทับช้าง',
            province: 'พิจิตร',
            zipcode: 66190,
        },
        {
            subDistrict: 'ทุ่งใหญ่',
            district: 'โพธิ์ประทับช้าง',
            province: 'พิจิตร',
            zipcode: 66190,
        },
        {
            subDistrict: 'วังจิก',
            district: 'โพธิ์ประทับช้าง',
            province: 'พิจิตร',
            zipcode: 66190,
        },
        {
            subDistrict: 'เนินสว่าง',
            district: 'โพธิ์ประทับช้าง',
            province: 'พิจิตร',
            zipcode: 66190,
        },
        {
            subDistrict: 'โพธิ์ประทับช้าง',
            district: 'โพธิ์ประทับช้าง',
            province: 'พิจิตร',
            zipcode: 66190,
        },
        {
            subDistrict: 'ไผ่ท่าโพ',
            district: 'โพธิ์ประทับช้าง',
            province: 'พิจิตร',
            zipcode: 66190,
        },
        {
            subDistrict: 'ไผ่รอบ',
            district: 'โพธิ์ประทับช้าง',
            province: 'พิจิตร',
            zipcode: 66190,
        },
        {
            subDistrict: 'ชาติตระการ',
            district: 'ชาติตระการ',
            province: 'พิษณุโลก',
            zipcode: 65170,
        },
        {
            subDistrict: 'ท่าสะแก',
            district: 'ชาติตระการ',
            province: 'พิษณุโลก',
            zipcode: 65170,
        },
        {
            subDistrict: 'บ่อภาค',
            district: 'ชาติตระการ',
            province: 'พิษณุโลก',
            zipcode: 65170,
        },
        {
            subDistrict: 'บ้านดง',
            district: 'ชาติตระการ',
            province: 'พิษณุโลก',
            zipcode: 65170,
        },
        {
            subDistrict: 'ป่าแดง',
            district: 'ชาติตระการ',
            province: 'พิษณุโลก',
            zipcode: 65170,
        },
        {
            subDistrict: 'สวนเมี่ยง',
            district: 'ชาติตระการ',
            province: 'พิษณุโลก',
            zipcode: 65170,
        },
        {
            subDistrict: 'นครชุม',
            district: 'นครไทย',
            province: 'พิษณุโลก',
            zipcode: 65120,
        },
        {
            subDistrict: 'นครไทย',
            district: 'นครไทย',
            province: 'พิษณุโลก',
            zipcode: 65120,
        },
        {
            subDistrict: 'นาบัว',
            district: 'นครไทย',
            province: 'พิษณุโลก',
            zipcode: 65120,
        },
        {
            subDistrict: 'น้ำกุ่ม',
            district: 'นครไทย',
            province: 'พิษณุโลก',
            zipcode: 65120,
        },
        {
            subDistrict: 'บ่อโพธิ์',
            district: 'นครไทย',
            province: 'พิษณุโลก',
            zipcode: 65120,
        },
        {
            subDistrict: 'บ้านพร้าว',
            district: 'นครไทย',
            province: 'พิษณุโลก',
            zipcode: 65120,
        },
        {
            subDistrict: 'บ้านแยง',
            district: 'นครไทย',
            province: 'พิษณุโลก',
            zipcode: 65120,
        },
        {
            subDistrict: 'ยางโกลน',
            district: 'นครไทย',
            province: 'พิษณุโลก',
            zipcode: 65120,
        },
        {
            subDistrict: 'หนองกะท้าว',
            district: 'นครไทย',
            province: 'พิษณุโลก',
            zipcode: 65120,
        },
        {
            subDistrict: 'ห้วยเฮี้ย',
            district: 'นครไทย',
            province: 'พิษณุโลก',
            zipcode: 65120,
        },
        {
            subDistrict: 'เนินเพิ่ม',
            district: 'นครไทย',
            province: 'พิษณุโลก',
            zipcode: 65120,
        },
        {
            subDistrict: 'ท่าตาล',
            district: 'บางกระทุ่ม',
            province: 'พิษณุโลก',
            zipcode: 65110,
        },
        {
            subDistrict: 'นครป่าหมาก',
            district: 'บางกระทุ่ม',
            province: 'พิษณุโลก',
            zipcode: 65110,
        },
        {
            subDistrict: 'บางกระทุ่ม',
            district: 'บางกระทุ่ม',
            province: 'พิษณุโลก',
            zipcode: 65110,
        },
        {
            subDistrict: 'บ้านไร่',
            district: 'บางกระทุ่ม',
            province: 'พิษณุโลก',
            zipcode: 65110,
        },
        {
            subDistrict: 'วัดตายม',
            district: 'บางกระทุ่ม',
            province: 'พิษณุโลก',
            zipcode: 65210,
        },
        {
            subDistrict: 'สนามคลี',
            district: 'บางกระทุ่ม',
            province: 'พิษณุโลก',
            zipcode: 65110,
        },
        {
            subDistrict: 'เนินกุ่ม',
            district: 'บางกระทุ่ม',
            province: 'พิษณุโลก',
            zipcode: 65210,
        },
        {
            subDistrict: 'โคกสลุด',
            district: 'บางกระทุ่ม',
            province: 'พิษณุโลก',
            zipcode: 65110,
        },
        {
            subDistrict: 'ไผ่ล้อม',
            district: 'บางกระทุ่ม',
            province: 'พิษณุโลก',
            zipcode: 65110,
        },
        {
            subDistrict: 'คุยม่วง',
            district: 'บางระกำ',
            province: 'พิษณุโลก',
            zipcode: 65240,
        },
        {
            subDistrict: 'ชุมแสงสงคราม',
            district: 'บางระกำ',
            province: 'พิษณุโลก',
            zipcode: 65240,
        },
        {
            subDistrict: 'ท่านางงาม',
            district: 'บางระกำ',
            province: 'พิษณุโลก',
            zipcode: 65140,
        },
        {
            subDistrict: 'นิคมพัฒนา',
            district: 'บางระกำ',
            province: 'พิษณุโลก',
            zipcode: 65140,
        },
        {
            subDistrict: 'บางระกำ',
            district: 'บางระกำ',
            province: 'พิษณุโลก',
            zipcode: 65140,
        },
        {
            subDistrict: 'บึงกอก',
            district: 'บางระกำ',
            province: 'พิษณุโลก',
            zipcode: 65140,
        },
        {
            subDistrict: 'บ่อทอง',
            district: 'บางระกำ',
            province: 'พิษณุโลก',
            zipcode: 65140,
        },
        {
            subDistrict: 'ปลักแรด',
            district: 'บางระกำ',
            province: 'พิษณุโลก',
            zipcode: 65140,
        },
        {
            subDistrict: 'พันเสา',
            district: 'บางระกำ',
            province: 'พิษณุโลก',
            zipcode: 65140,
        },
        {
            subDistrict: 'วังอิทก',
            district: 'บางระกำ',
            province: 'พิษณุโลก',
            zipcode: 65140,
        },
        {
            subDistrict: 'หนองกุลา',
            district: 'บางระกำ',
            province: 'พิษณุโลก',
            zipcode: 65140,
        },
        {
            subDistrict: 'ดงประคำ',
            district: 'พรหมพิราม',
            province: 'พิษณุโลก',
            zipcode: 65180,
        },
        {
            subDistrict: 'ตลุกเทียม',
            district: 'พรหมพิราม',
            province: 'พิษณุโลก',
            zipcode: 65180,
        },
        {
            subDistrict: 'ทับยายเชียง',
            district: 'พรหมพิราม',
            province: 'พิษณุโลก',
            zipcode: 65150,
        },
        {
            subDistrict: 'ท่าช้าง',
            district: 'พรหมพิราม',
            province: 'พิษณุโลก',
            zipcode: 65150,
        },
        {
            subDistrict: 'พรหมพิราม',
            district: 'พรหมพิราม',
            province: 'พิษณุโลก',
            zipcode: 65150,
        },
        {
            subDistrict: 'มะตูม',
            district: 'พรหมพิราม',
            province: 'พิษณุโลก',
            zipcode: 65150,
        },
        {
            subDistrict: 'มะต้อง',
            district: 'พรหมพิราม',
            province: 'พิษณุโลก',
            zipcode: 65180,
        },
        {
            subDistrict: 'วงฆ้อง',
            district: 'พรหมพิราม',
            province: 'พิษณุโลก',
            zipcode: 65180,
        },
        {
            subDistrict: 'วังวน',
            district: 'พรหมพิราม',
            province: 'พิษณุโลก',
            zipcode: 65150,
        },
        {
            subDistrict: 'ศรีภิรมย์',
            district: 'พรหมพิราม',
            province: 'พิษณุโลก',
            zipcode: 65180,
        },
        {
            subDistrict: 'หนองแขม',
            district: 'พรหมพิราม',
            province: 'พิษณุโลก',
            zipcode: 65150,
        },
        {
            subDistrict: 'หอกลอง',
            district: 'พรหมพิราม',
            province: 'พิษณุโลก',
            zipcode: 65150,
        },
        {
            subDistrict: 'ชัยนาม',
            district: 'วังทอง',
            province: 'พิษณุโลก',
            zipcode: 65130,
        },
        {
            subDistrict: 'ดินทอง',
            district: 'วังทอง',
            province: 'พิษณุโลก',
            zipcode: 65130,
        },
        {
            subDistrict: 'ท่าหมื่นราม',
            district: 'วังทอง',
            province: 'พิษณุโลก',
            zipcode: 65130,
        },
        {
            subDistrict: 'บ้านกลาง',
            district: 'วังทอง',
            province: 'พิษณุโลก',
            zipcode: 65220,
        },
        {
            subDistrict: 'พันชาลี',
            district: 'วังทอง',
            province: 'พิษณุโลก',
            zipcode: 65130,
        },
        {
            subDistrict: 'วังทอง',
            district: 'วังทอง',
            province: 'พิษณุโลก',
            zipcode: 65130,
        },
        {
            subDistrict: 'วังนกแอ่น',
            district: 'วังทอง',
            province: 'พิษณุโลก',
            zipcode: 65130,
        },
        {
            subDistrict: 'วังพิกุล',
            district: 'วังทอง',
            province: 'พิษณุโลก',
            zipcode: 65130,
        },
        {
            subDistrict: 'หนองพระ',
            district: 'วังทอง',
            province: 'พิษณุโลก',
            zipcode: 65130,
        },
        {
            subDistrict: 'แก่งโสภา',
            district: 'วังทอง',
            province: 'พิษณุโลก',
            zipcode: 65220,
        },
        {
            subDistrict: 'แม่ระกา',
            district: 'วังทอง',
            province: 'พิษณุโลก',
            zipcode: 65130,
        },
        {
            subDistrict: 'คันโช้ง',
            district: 'วัดโบสถ์',
            province: 'พิษณุโลก',
            zipcode: 65160,
        },
        {
            subDistrict: 'ท่างาม',
            district: 'วัดโบสถ์',
            province: 'พิษณุโลก',
            zipcode: 65160,
        },
        {
            subDistrict: 'ท้อแท้',
            district: 'วัดโบสถ์',
            province: 'พิษณุโลก',
            zipcode: 65160,
        },
        {
            subDistrict: 'บ้านยาง',
            district: 'วัดโบสถ์',
            province: 'พิษณุโลก',
            zipcode: 65160,
        },
        {
            subDistrict: 'วัดโบสถ์',
            district: 'วัดโบสถ์',
            province: 'พิษณุโลก',
            zipcode: 65160,
        },
        {
            subDistrict: 'หินลาด',
            district: 'วัดโบสถ์',
            province: 'พิษณุโลก',
            zipcode: 65160,
        },
        {
            subDistrict: 'ชมพู',
            district: 'เนินมะปราง',
            province: 'พิษณุโลก',
            zipcode: 65190,
        },
        {
            subDistrict: 'บ้านน้อยซุ้มขี้เหล็ก',
            district: 'เนินมะปราง',
            province: 'พิษณุโลก',
            zipcode: 65190,
        },
        {
            subDistrict: 'บ้านมุง',
            district: 'เนินมะปราง',
            province: 'พิษณุโลก',
            zipcode: 65190,
        },
        {
            subDistrict: 'วังยาง',
            district: 'เนินมะปราง',
            province: 'พิษณุโลก',
            zipcode: 65190,
        },
        {
            subDistrict: 'วังโพรง',
            district: 'เนินมะปราง',
            province: 'พิษณุโลก',
            zipcode: 65190,
        },
        {
            subDistrict: 'เนินมะปราง',
            district: 'เนินมะปราง',
            province: 'พิษณุโลก',
            zipcode: 65190,
        },
        {
            subDistrict: 'ไทรย้อย',
            district: 'เนินมะปราง',
            province: 'พิษณุโลก',
            zipcode: 65190,
        },
        {
            subDistrict: 'งิ้วงาม',
            district: 'เมืองพิษณุโลก',
            province: 'พิษณุโลก',
            zipcode: 65230,
        },
        {
            subDistrict: 'จอมทอง',
            district: 'เมืองพิษณุโลก',
            province: 'พิษณุโลก',
            zipcode: 65000,
        },
        {
            subDistrict: 'ดอนทอง',
            district: 'เมืองพิษณุโลก',
            province: 'พิษณุโลก',
            zipcode: 65000,
        },
        {
            subDistrict: 'ท่าทอง',
            district: 'เมืองพิษณุโลก',
            province: 'พิษณุโลก',
            zipcode: 65000,
        },
        {
            subDistrict: 'ท่าโพธิ์',
            district: 'เมืองพิษณุโลก',
            province: 'พิษณุโลก',
            zipcode: 65000,
        },
        {
            subDistrict: 'บึงพระ',
            district: 'เมืองพิษณุโลก',
            province: 'พิษณุโลก',
            zipcode: 65000,
        },
        {
            subDistrict: 'บ้านกร่าง',
            district: 'เมืองพิษณุโลก',
            province: 'พิษณุโลก',
            zipcode: 65000,
        },
        {
            subDistrict: 'บ้านคลอง',
            district: 'เมืองพิษณุโลก',
            province: 'พิษณุโลก',
            zipcode: 65000,
        },
        {
            subDistrict: 'บ้านป่า',
            district: 'เมืองพิษณุโลก',
            province: 'พิษณุโลก',
            zipcode: 65000,
        },
        {
            subDistrict: 'ปากโทก',
            district: 'เมืองพิษณุโลก',
            province: 'พิษณุโลก',
            zipcode: 65000,
        },
        {
            subDistrict: 'พลายชุมพล',
            district: 'เมืองพิษณุโลก',
            province: 'พิษณุโลก',
            zipcode: 65000,
        },
        {
            subDistrict: 'มะขามสูง',
            district: 'เมืองพิษณุโลก',
            province: 'พิษณุโลก',
            zipcode: 65000,
        },
        {
            subDistrict: 'วังน้ำคู้',
            district: 'เมืองพิษณุโลก',
            province: 'พิษณุโลก',
            zipcode: 65230,
        },
        {
            subDistrict: 'วัดจันทร์',
            district: 'เมืองพิษณุโลก',
            province: 'พิษณุโลก',
            zipcode: 65000,
        },
        {
            subDistrict: 'วัดพริก',
            district: 'เมืองพิษณุโลก',
            province: 'พิษณุโลก',
            zipcode: 65230,
        },
        {
            subDistrict: 'สมอแข',
            district: 'เมืองพิษณุโลก',
            province: 'พิษณุโลก',
            zipcode: 65000,
        },
        {
            subDistrict: 'หัวรอ',
            district: 'เมืองพิษณุโลก',
            province: 'พิษณุโลก',
            zipcode: 65000,
        },
        {
            subDistrict: 'อรัญญิก',
            district: 'เมืองพิษณุโลก',
            province: 'พิษณุโลก',
            zipcode: 65000,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'เมืองพิษณุโลก',
            province: 'พิษณุโลก',
            zipcode: 65000,
        },
        {
            subDistrict: 'ไผ่ขอดอน',
            district: 'เมืองพิษณุโลก',
            province: 'พิษณุโลก',
            zipcode: 65000,
        },
        {
            subDistrict: 'กมลา',
            district: 'กะทู้',
            province: 'ภูเก็ต',
            zipcode: 83120,
        },
        {
            subDistrict: 'กะทู้',
            district: 'กะทู้',
            province: 'ภูเก็ต',
            zipcode: 83120,
        },
        {
            subDistrict: 'ป่าตอง',
            district: 'กะทู้',
            province: 'ภูเก็ต',
            zipcode: 83150,
        },
        {
            subDistrict: 'ป่าคลอก',
            district: 'ถลาง',
            province: 'ภูเก็ต',
            zipcode: 83110,
        },
        {
            subDistrict: 'ศรีสุนทร',
            district: 'ถลาง',
            province: 'ภูเก็ต',
            zipcode: 83110,
        },
        {
            subDistrict: 'สาคู',
            district: 'ถลาง',
            province: 'ภูเก็ต',
            zipcode: 83110,
        },
        {
            subDistrict: 'เชิงทะเล',
            district: 'ถลาง',
            province: 'ภูเก็ต',
            zipcode: 83110,
        },
        {
            subDistrict: 'เทพกระษัตรี',
            district: 'ถลาง',
            province: 'ภูเก็ต',
            zipcode: 83110,
        },
        {
            subDistrict: 'ไม้ขาว',
            district: 'ถลาง',
            province: 'ภูเก็ต',
            zipcode: 83110,
        },
        {
            subDistrict: 'กะรน',
            district: 'เมืองภูเก็ต',
            province: 'ภูเก็ต',
            zipcode: 83100,
        },
        {
            subDistrict: 'ฉลอง',
            district: 'เมืองภูเก็ต',
            province: 'ภูเก็ต',
            zipcode: 83000,
        },
        {
            subDistrict: 'ฉลอง',
            district: 'เมืองภูเก็ต',
            province: 'ภูเก็ต',
            zipcode: 83130,
        },
        {
            subDistrict: 'ตลาดเหนือ',
            district: 'เมืองภูเก็ต',
            province: 'ภูเก็ต',
            zipcode: 83000,
        },
        {
            subDistrict: 'ตลาดใหญ่',
            district: 'เมืองภูเก็ต',
            province: 'ภูเก็ต',
            zipcode: 83000,
        },
        {
            subDistrict: 'รัษฎา',
            district: 'เมืองภูเก็ต',
            province: 'ภูเก็ต',
            zipcode: 83000,
        },
        {
            subDistrict: 'ราไวย์',
            district: 'เมืองภูเก็ต',
            province: 'ภูเก็ต',
            zipcode: 83100,
        },
        {
            subDistrict: 'ราไวย์',
            district: 'เมืองภูเก็ต',
            province: 'ภูเก็ต',
            zipcode: 83130,
        },
        {
            subDistrict: 'วิชิต',
            district: 'เมืองภูเก็ต',
            province: 'ภูเก็ต',
            zipcode: 83000,
        },
        {
            subDistrict: 'เกาะแก้ว',
            district: 'เมืองภูเก็ต',
            province: 'ภูเก็ต',
            zipcode: 83000,
        },
        {
            subDistrict: 'กุดใส้จ่อ',
            district: 'กันทรวิชัย',
            province: 'มหาสารคาม',
            zipcode: 44150,
        },
        {
            subDistrict: 'ขามเฒ่าพัฒนา',
            district: 'กันทรวิชัย',
            province: 'มหาสารคาม',
            zipcode: 44150,
        },
        {
            subDistrict: 'ขามเรียง',
            district: 'กันทรวิชัย',
            province: 'มหาสารคาม',
            zipcode: 44150,
        },
        {
            subDistrict: 'คันธารราษฎร์',
            district: 'กันทรวิชัย',
            province: 'มหาสารคาม',
            zipcode: 44150,
        },
        {
            subDistrict: 'ท่าขอนยาง',
            district: 'กันทรวิชัย',
            province: 'มหาสารคาม',
            zipcode: 44150,
        },
        {
            subDistrict: 'นาสีนวน',
            district: 'กันทรวิชัย',
            province: 'มหาสารคาม',
            zipcode: 44150,
        },
        {
            subDistrict: 'มะค่า',
            district: 'กันทรวิชัย',
            province: 'มหาสารคาม',
            zipcode: 44150,
        },
        {
            subDistrict: 'ศรีสุข',
            district: 'กันทรวิชัย',
            province: 'มหาสารคาม',
            zipcode: 44150,
        },
        {
            subDistrict: 'เขวาใหญ่',
            district: 'กันทรวิชัย',
            province: 'มหาสารคาม',
            zipcode: 44150,
        },
        {
            subDistrict: 'โคกพระ',
            district: 'กันทรวิชัย',
            province: 'มหาสารคาม',
            zipcode: 44150,
        },
        {
            subDistrict: 'กุดรัง',
            district: 'กุดรัง',
            province: 'มหาสารคาม',
            zipcode: 44130,
        },
        {
            subDistrict: 'นาโพธิ์',
            district: 'กุดรัง',
            province: 'มหาสารคาม',
            zipcode: 44130,
        },
        {
            subDistrict: 'หนองแวง',
            district: 'กุดรัง',
            province: 'มหาสารคาม',
            zipcode: 44130,
        },
        {
            subDistrict: 'ห้วยเตย',
            district: 'กุดรัง',
            province: 'มหาสารคาม',
            zipcode: 44130,
        },
        {
            subDistrict: 'เลิงแฝก',
            district: 'กุดรัง',
            province: 'มหาสารคาม',
            zipcode: 44130,
        },
        {
            subDistrict: 'กุดปลาดุก',
            district: 'ชื่นชม',
            province: 'มหาสารคาม',
            zipcode: 44160,
        },
        {
            subDistrict: 'ชื่นชม',
            district: 'ชื่นชม',
            province: 'มหาสารคาม',
            zipcode: 44160,
        },
        {
            subDistrict: 'หนองกุง',
            district: 'ชื่นชม',
            province: 'มหาสารคาม',
            zipcode: 44160,
        },
        {
            subDistrict: 'เหล่าดอกไม้',
            district: 'ชื่นชม',
            province: 'มหาสารคาม',
            zipcode: 44160,
        },
        {
            subDistrict: 'กู่สันตรัตน์',
            district: 'นาดูน',
            province: 'มหาสารคาม',
            zipcode: 44180,
        },
        {
            subDistrict: 'ดงดวน',
            district: 'นาดูน',
            province: 'มหาสารคาม',
            zipcode: 44180,
        },
        {
            subDistrict: 'ดงบัง',
            district: 'นาดูน',
            province: 'มหาสารคาม',
            zipcode: 44180,
        },
        {
            subDistrict: 'ดงยาง',
            district: 'นาดูน',
            province: 'มหาสารคาม',
            zipcode: 44180,
        },
        {
            subDistrict: 'นาดูน',
            district: 'นาดูน',
            province: 'มหาสารคาม',
            zipcode: 44180,
        },
        {
            subDistrict: 'พระธาตุ',
            district: 'นาดูน',
            province: 'มหาสารคาม',
            zipcode: 44180,
        },
        {
            subDistrict: 'หนองคู',
            district: 'นาดูน',
            province: 'มหาสารคาม',
            zipcode: 44180,
        },
        {
            subDistrict: 'หนองไผ่',
            district: 'นาดูน',
            province: 'มหาสารคาม',
            zipcode: 44180,
        },
        {
            subDistrict: 'หัวดง',
            district: 'นาดูน',
            province: 'มหาสารคาม',
            zipcode: 44180,
        },
        {
            subDistrict: 'นาเชือก',
            district: 'นาเชือก',
            province: 'มหาสารคาม',
            zipcode: 44170,
        },
        {
            subDistrict: 'ปอพาน',
            district: 'นาเชือก',
            province: 'มหาสารคาม',
            zipcode: 44170,
        },
        {
            subDistrict: 'สันป่าตอง',
            district: 'นาเชือก',
            province: 'มหาสารคาม',
            zipcode: 44170,
        },
        {
            subDistrict: 'สำโรง',
            district: 'นาเชือก',
            province: 'มหาสารคาม',
            zipcode: 44170,
        },
        {
            subDistrict: 'หนองกุง',
            district: 'นาเชือก',
            province: 'มหาสารคาม',
            zipcode: 44170,
        },
        {
            subDistrict: 'หนองเม็ก',
            district: 'นาเชือก',
            province: 'มหาสารคาม',
            zipcode: 44170,
        },
        {
            subDistrict: 'หนองเรือ',
            district: 'นาเชือก',
            province: 'มหาสารคาม',
            zipcode: 44170,
        },
        {
            subDistrict: 'หนองแดง',
            district: 'นาเชือก',
            province: 'มหาสารคาม',
            zipcode: 44170,
        },
        {
            subDistrict: 'หนองโพธิ์',
            district: 'นาเชือก',
            province: 'มหาสารคาม',
            zipcode: 44170,
        },
        {
            subDistrict: 'เขวาไร่',
            district: 'นาเชือก',
            province: 'มหาสารคาม',
            zipcode: 44170,
        },
        {
            subDistrict: 'กำพี้',
            district: 'บรบือ',
            province: 'มหาสารคาม',
            zipcode: 44130,
        },
        {
            subDistrict: 'ดอนงัว',
            district: 'บรบือ',
            province: 'มหาสารคาม',
            zipcode: 44130,
        },
        {
            subDistrict: 'บรบือ',
            district: 'บรบือ',
            province: 'มหาสารคาม',
            zipcode: 44130,
        },
        {
            subDistrict: 'บัวมาศ',
            district: 'บรบือ',
            province: 'มหาสารคาม',
            zipcode: 44130,
        },
        {
            subDistrict: 'บ่อใหญ่',
            district: 'บรบือ',
            province: 'มหาสารคาม',
            zipcode: 44130,
        },
        {
            subDistrict: 'ยาง',
            district: 'บรบือ',
            province: 'มหาสารคาม',
            zipcode: 44130,
        },
        {
            subDistrict: 'วังใหม่',
            district: 'บรบือ',
            province: 'มหาสารคาม',
            zipcode: 44130,
        },
        {
            subDistrict: 'วังไชย',
            district: 'บรบือ',
            province: 'มหาสารคาม',
            zipcode: 44130,
        },
        {
            subDistrict: 'หนองคูขาด',
            district: 'บรบือ',
            province: 'มหาสารคาม',
            zipcode: 44130,
        },
        {
            subDistrict: 'หนองจิก',
            district: 'บรบือ',
            province: 'มหาสารคาม',
            zipcode: 44130,
        },
        {
            subDistrict: 'หนองม่วง',
            district: 'บรบือ',
            province: 'มหาสารคาม',
            zipcode: 44130,
        },
        {
            subDistrict: 'หนองสิม',
            district: 'บรบือ',
            province: 'มหาสารคาม',
            zipcode: 44130,
        },
        {
            subDistrict: 'หนองโก',
            district: 'บรบือ',
            province: 'มหาสารคาม',
            zipcode: 44130,
        },
        {
            subDistrict: 'โนนราษี',
            district: 'บรบือ',
            province: 'มหาสารคาม',
            zipcode: 44130,
        },
        {
            subDistrict: 'โนนแดง',
            district: 'บรบือ',
            province: 'มหาสารคาม',
            zipcode: 44130,
        },
        {
            subDistrict: 'ก้ามปู',
            district: 'พยัคฆภูมิพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44110,
        },
        {
            subDistrict: 'นาสีนวล',
            district: 'พยัคฆภูมิพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44110,
        },
        {
            subDistrict: 'ปะหลาน',
            district: 'พยัคฆภูมิพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44110,
        },
        {
            subDistrict: 'ภารแอ่น',
            district: 'พยัคฆภูมิพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44110,
        },
        {
            subDistrict: 'ราษฎร์พัฒนา',
            district: 'พยัคฆภูมิพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44110,
        },
        {
            subDistrict: 'ราษฎร์เจริญ',
            district: 'พยัคฆภูมิพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44110,
        },
        {
            subDistrict: 'ลานสะแก',
            district: 'พยัคฆภูมิพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44110,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'พยัคฆภูมิพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44110,
        },
        {
            subDistrict: 'หนองบัวแก้ว',
            district: 'พยัคฆภูมิพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44110,
        },
        {
            subDistrict: 'เมืองเตา',
            district: 'พยัคฆภูมิพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44110,
        },
        {
            subDistrict: 'เมืองเสือ',
            district: 'พยัคฆภูมิพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44110,
        },
        {
            subDistrict: 'เม็กดำ',
            district: 'พยัคฆภูมิพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44110,
        },
        {
            subDistrict: 'เวียงชัย',
            district: 'พยัคฆภูมิพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44110,
        },
        {
            subDistrict: 'เวียงสะอาด',
            district: 'พยัคฆภูมิพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44110,
        },
        {
            subDistrict: 'ขามเรียน',
            district: 'ยางสีสุราช',
            province: 'มหาสารคาม',
            zipcode: 44210,
        },
        {
            subDistrict: 'ดงเมือง',
            district: 'ยางสีสุราช',
            province: 'มหาสารคาม',
            zipcode: 44210,
        },
        {
            subDistrict: 'นาภู',
            district: 'ยางสีสุราช',
            province: 'มหาสารคาม',
            zipcode: 44210,
        },
        {
            subDistrict: 'บ้านกู่',
            district: 'ยางสีสุราช',
            province: 'มหาสารคาม',
            zipcode: 44210,
        },
        {
            subDistrict: 'ยางสีสุราช',
            district: 'ยางสีสุราช',
            province: 'มหาสารคาม',
            zipcode: 44210,
        },
        {
            subDistrict: 'หนองบัวสันตุ',
            district: 'ยางสีสุราช',
            province: 'มหาสารคาม',
            zipcode: 44210,
        },
        {
            subDistrict: 'แวงดง',
            district: 'ยางสีสุราช',
            province: 'มหาสารคาม',
            zipcode: 44210,
        },
        {
            subDistrict: 'ขามป้อม',
            district: 'วาปีปทุม',
            province: 'มหาสารคาม',
            zipcode: 44120,
        },
        {
            subDistrict: 'งัวบา',
            district: 'วาปีปทุม',
            province: 'มหาสารคาม',
            zipcode: 44120,
        },
        {
            subDistrict: 'ดงใหญ่',
            district: 'วาปีปทุม',
            province: 'มหาสารคาม',
            zipcode: 44120,
        },
        {
            subDistrict: 'นาข่า',
            district: 'วาปีปทุม',
            province: 'มหาสารคาม',
            zipcode: 44120,
        },
        {
            subDistrict: 'บ้านหวาย',
            district: 'วาปีปทุม',
            province: 'มหาสารคาม',
            zipcode: 44120,
        },
        {
            subDistrict: 'ประชาพัฒนา',
            district: 'วาปีปทุม',
            province: 'มหาสารคาม',
            zipcode: 44120,
        },
        {
            subDistrict: 'หนองทุ่ม',
            district: 'วาปีปทุม',
            province: 'มหาสารคาม',
            zipcode: 44120,
        },
        {
            subDistrict: 'หนองแสง',
            district: 'วาปีปทุม',
            province: 'มหาสารคาม',
            zipcode: 44120,
        },
        {
            subDistrict: 'หนองแสน',
            district: 'วาปีปทุม',
            province: 'มหาสารคาม',
            zipcode: 44120,
        },
        {
            subDistrict: 'หนองไฮ',
            district: 'วาปีปทุม',
            province: 'มหาสารคาม',
            zipcode: 44120,
        },
        {
            subDistrict: 'หัวเรือ',
            district: 'วาปีปทุม',
            province: 'มหาสารคาม',
            zipcode: 44120,
        },
        {
            subDistrict: 'เสือโก้ก',
            district: 'วาปีปทุม',
            province: 'มหาสารคาม',
            zipcode: 44120,
        },
        {
            subDistrict: 'แคน',
            district: 'วาปีปทุม',
            province: 'มหาสารคาม',
            zipcode: 44120,
        },
        {
            subDistrict: 'โคกสีทองหลาง',
            district: 'วาปีปทุม',
            province: 'มหาสารคาม',
            zipcode: 44120,
        },
        {
            subDistrict: 'โพธิ์ชัย',
            district: 'วาปีปทุม',
            province: 'มหาสารคาม',
            zipcode: 44120,
        },
        {
            subDistrict: 'กู่ทอง',
            district: 'เชียงยืน',
            province: 'มหาสารคาม',
            zipcode: 44160,
        },
        {
            subDistrict: 'ดอนเงิน',
            district: 'เชียงยืน',
            province: 'มหาสารคาม',
            zipcode: 44160,
        },
        {
            subDistrict: 'นาทอง',
            district: 'เชียงยืน',
            province: 'มหาสารคาม',
            zipcode: 44160,
        },
        {
            subDistrict: 'หนองซอน',
            district: 'เชียงยืน',
            province: 'มหาสารคาม',
            zipcode: 44160,
        },
        {
            subDistrict: 'เชียงยืน',
            district: 'เชียงยืน',
            province: 'มหาสารคาม',
            zipcode: 44160,
        },
        {
            subDistrict: 'เสือเฒ่า',
            district: 'เชียงยืน',
            province: 'มหาสารคาม',
            zipcode: 44160,
        },
        {
            subDistrict: 'เหล่าบัวบาน',
            district: 'เชียงยืน',
            province: 'มหาสารคาม',
            zipcode: 44160,
        },
        {
            subDistrict: 'โพนทอง',
            district: 'เชียงยืน',
            province: 'มหาสารคาม',
            zipcode: 44160,
        },
        {
            subDistrict: 'ดอนหว่าน',
            district: 'เมืองมหาสารคาม',
            province: 'มหาสารคาม',
            zipcode: 44000,
        },
        {
            subDistrict: 'ตลาด',
            district: 'เมืองมหาสารคาม',
            province: 'มหาสารคาม',
            zipcode: 44000,
        },
        {
            subDistrict: 'ท่าตูม',
            district: 'เมืองมหาสารคาม',
            province: 'มหาสารคาม',
            zipcode: 44000,
        },
        {
            subDistrict: 'ท่าสองคอน',
            district: 'เมืองมหาสารคาม',
            province: 'มหาสารคาม',
            zipcode: 44000,
        },
        {
            subDistrict: 'บัวค้อ',
            district: 'เมืองมหาสารคาม',
            province: 'มหาสารคาม',
            zipcode: 44000,
        },
        {
            subDistrict: 'ลาดพัฒนา',
            district: 'เมืองมหาสารคาม',
            province: 'มหาสารคาม',
            zipcode: 44000,
        },
        {
            subDistrict: 'หนองปลิง',
            district: 'เมืองมหาสารคาม',
            province: 'มหาสารคาม',
            zipcode: 44000,
        },
        {
            subDistrict: 'หนองโน',
            district: 'เมืองมหาสารคาม',
            province: 'มหาสารคาม',
            zipcode: 44000,
        },
        {
            subDistrict: 'ห้วยแอ่ง',
            district: 'เมืองมหาสารคาม',
            province: 'มหาสารคาม',
            zipcode: 44000,
        },
        {
            subDistrict: 'เกิ้ง',
            district: 'เมืองมหาสารคาม',
            province: 'มหาสารคาม',
            zipcode: 44000,
        },
        {
            subDistrict: 'เขวา',
            district: 'เมืองมหาสารคาม',
            province: 'มหาสารคาม',
            zipcode: 44000,
        },
        {
            subDistrict: 'แก่งเลิงจาน',
            district: 'เมืองมหาสารคาม',
            province: 'มหาสารคาม',
            zipcode: 44000,
        },
        {
            subDistrict: 'แวงน่าง',
            district: 'เมืองมหาสารคาม',
            province: 'มหาสารคาม',
            zipcode: 44000,
        },
        {
            subDistrict: 'โคกก่อ',
            district: 'เมืองมหาสารคาม',
            province: 'มหาสารคาม',
            zipcode: 44000,
        },
        {
            subDistrict: 'มิตรภาพ',
            district: 'แกดำ',
            province: 'มหาสารคาม',
            zipcode: 44190,
        },
        {
            subDistrict: 'วังแสง',
            district: 'แกดำ',
            province: 'มหาสารคาม',
            zipcode: 44190,
        },
        {
            subDistrict: 'หนองกุง',
            district: 'แกดำ',
            province: 'มหาสารคาม',
            zipcode: 44190,
        },
        {
            subDistrict: 'แกดำ',
            district: 'แกดำ',
            province: 'มหาสารคาม',
            zipcode: 44190,
        },
        {
            subDistrict: 'โนนภิบาล',
            district: 'แกดำ',
            province: 'มหาสารคาม',
            zipcode: 44190,
        },
        {
            subDistrict: 'ดอนกลาง',
            district: 'โกสุมพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44140,
        },
        {
            subDistrict: 'ยางท่าแจ้ง',
            district: 'โกสุมพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44140,
        },
        {
            subDistrict: 'ยางน้อย',
            district: 'โกสุมพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44140,
        },
        {
            subDistrict: 'วังยาว',
            district: 'โกสุมพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44140,
        },
        {
            subDistrict: 'หนองกุงสวรรค์',
            district: 'โกสุมพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44140,
        },
        {
            subDistrict: 'หนองบอน',
            district: 'โกสุมพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44140,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'โกสุมพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44140,
        },
        {
            subDistrict: 'หนองเหล็ก',
            district: 'โกสุมพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44140,
        },
        {
            subDistrict: 'หัวขวาง',
            district: 'โกสุมพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44140,
        },
        {
            subDistrict: 'เขวาไร่',
            district: 'โกสุมพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44140,
        },
        {
            subDistrict: 'เขื่อน',
            district: 'โกสุมพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44140,
        },
        {
            subDistrict: 'เลิงใต้',
            district: 'โกสุมพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44140,
        },
        {
            subDistrict: 'เหล่า',
            district: 'โกสุมพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44140,
        },
        {
            subDistrict: 'แก้งแก',
            district: 'โกสุมพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44140,
        },
        {
            subDistrict: 'แพง',
            district: 'โกสุมพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44140,
        },
        {
            subDistrict: 'แห่ใต้',
            district: 'โกสุมพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44140,
        },
        {
            subDistrict: 'โพนงาม',
            district: 'โกสุมพิสัย',
            province: 'มหาสารคาม',
            zipcode: 44140,
        },
        {
            subDistrict: 'คำชะอี',
            district: 'คำชะอี',
            province: 'มุกดาหาร',
            zipcode: 49110,
        },
        {
            subDistrict: 'คำบก',
            district: 'คำชะอี',
            province: 'มุกดาหาร',
            zipcode: 49110,
        },
        {
            subDistrict: 'น้ำเที่ยง',
            district: 'คำชะอี',
            province: 'มุกดาหาร',
            zipcode: 49110,
        },
        {
            subDistrict: 'บ้านค้อ',
            district: 'คำชะอี',
            province: 'มุกดาหาร',
            zipcode: 49110,
        },
        {
            subDistrict: 'บ้านซ่ง',
            district: 'คำชะอี',
            province: 'มุกดาหาร',
            zipcode: 49110,
        },
        {
            subDistrict: 'บ้านเหล่า',
            district: 'คำชะอี',
            province: 'มุกดาหาร',
            zipcode: 49110,
        },
        {
            subDistrict: 'หนองเอี่ยน',
            district: 'คำชะอี',
            province: 'มุกดาหาร',
            zipcode: 49110,
        },
        {
            subDistrict: 'เหล่าสร้างถ่อ',
            district: 'คำชะอี',
            province: 'มุกดาหาร',
            zipcode: 49110,
        },
        {
            subDistrict: 'โพนงาม',
            district: 'คำชะอี',
            province: 'มุกดาหาร',
            zipcode: 49110,
        },
        {
            subDistrict: 'กกตูม',
            district: 'ดงหลวง',
            province: 'มุกดาหาร',
            zipcode: 49140,
        },
        {
            subDistrict: 'ชะโนดน้อย',
            district: 'ดงหลวง',
            province: 'มุกดาหาร',
            zipcode: 49140,
        },
        {
            subDistrict: 'ดงหลวง',
            district: 'ดงหลวง',
            province: 'มุกดาหาร',
            zipcode: 49140,
        },
        {
            subDistrict: 'พังแดง',
            district: 'ดงหลวง',
            province: 'มุกดาหาร',
            zipcode: 49140,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'ดงหลวง',
            province: 'มุกดาหาร',
            zipcode: 49140,
        },
        {
            subDistrict: 'หนองแคน',
            district: 'ดงหลวง',
            province: 'มุกดาหาร',
            zipcode: 49140,
        },
        {
            subDistrict: 'ดอนตาล',
            district: 'ดอนตาล',
            province: 'มุกดาหาร',
            zipcode: 49120,
        },
        {
            subDistrict: 'นาสะเม็ง',
            district: 'ดอนตาล',
            province: 'มุกดาหาร',
            zipcode: 49120,
        },
        {
            subDistrict: 'บ้านบาก',
            district: 'ดอนตาล',
            province: 'มุกดาหาร',
            zipcode: 49120,
        },
        {
            subDistrict: 'บ้านแก้ง',
            district: 'ดอนตาล',
            province: 'มุกดาหาร',
            zipcode: 49120,
        },
        {
            subDistrict: 'ป่าไร่',
            district: 'ดอนตาล',
            province: 'มุกดาหาร',
            zipcode: 49120,
        },
        {
            subDistrict: 'เหล่าหมี',
            district: 'ดอนตาล',
            province: 'มุกดาหาร',
            zipcode: 49120,
        },
        {
            subDistrict: 'โพธิ์ไทร',
            district: 'ดอนตาล',
            province: 'มุกดาหาร',
            zipcode: 49120,
        },
        {
            subDistrict: 'กกแดง',
            district: 'นิคมคำสร้อย',
            province: 'มุกดาหาร',
            zipcode: 49130,
        },
        {
            subDistrict: 'นากอก',
            district: 'นิคมคำสร้อย',
            province: 'มุกดาหาร',
            zipcode: 49130,
        },
        {
            subDistrict: 'นาอุดม',
            district: 'นิคมคำสร้อย',
            province: 'มุกดาหาร',
            zipcode: 49130,
        },
        {
            subDistrict: 'นิคมคำสร้อย',
            district: 'นิคมคำสร้อย',
            province: 'มุกดาหาร',
            zipcode: 49130,
        },
        {
            subDistrict: 'ร่มเกล้า',
            district: 'นิคมคำสร้อย',
            province: 'มุกดาหาร',
            zipcode: 49130,
        },
        {
            subDistrict: 'หนองแวง',
            district: 'นิคมคำสร้อย',
            province: 'มุกดาหาร',
            zipcode: 49130,
        },
        {
            subDistrict: 'โชคชัย',
            district: 'นิคมคำสร้อย',
            province: 'มุกดาหาร',
            zipcode: 49130,
        },
        {
            subDistrict: 'บ้านเป้า',
            district: 'หนองสูง',
            province: 'มุกดาหาร',
            zipcode: 49160,
        },
        {
            subDistrict: 'ภูวง',
            district: 'หนองสูง',
            province: 'มุกดาหาร',
            zipcode: 49160,
        },
        {
            subDistrict: 'หนองสูง',
            district: 'หนองสูง',
            province: 'มุกดาหาร',
            zipcode: 49160,
        },
        {
            subDistrict: 'หนองสูงเหนือ',
            district: 'หนองสูง',
            province: 'มุกดาหาร',
            zipcode: 49160,
        },
        {
            subDistrict: 'หนองสูงใต้',
            district: 'หนองสูง',
            province: 'มุกดาหาร',
            zipcode: 49160,
        },
        {
            subDistrict: 'โนนยาง',
            district: 'หนองสูง',
            province: 'มุกดาหาร',
            zipcode: 49160,
        },
        {
            subDistrict: 'ชะโนด',
            district: 'หว้านใหญ่',
            province: 'มุกดาหาร',
            zipcode: 49150,
        },
        {
            subDistrict: 'ดงหมู',
            district: 'หว้านใหญ่',
            province: 'มุกดาหาร',
            zipcode: 49150,
        },
        {
            subDistrict: 'บางทรายน้อย',
            district: 'หว้านใหญ่',
            province: 'มุกดาหาร',
            zipcode: 49150,
        },
        {
            subDistrict: 'ป่งขาม',
            district: 'หว้านใหญ่',
            province: 'มุกดาหาร',
            zipcode: 49150,
        },
        {
            subDistrict: 'หว้านใหญ่',
            district: 'หว้านใหญ่',
            province: 'มุกดาหาร',
            zipcode: 49150,
        },
        {
            subDistrict: 'กุดแข้',
            district: 'เมืองมุกดาหาร',
            province: 'มุกดาหาร',
            zipcode: 49000,
        },
        {
            subDistrict: 'คำป่าหลาย',
            district: 'เมืองมุกดาหาร',
            province: 'มุกดาหาร',
            zipcode: 49000,
        },
        {
            subDistrict: 'คำอาฮวน',
            district: 'เมืองมุกดาหาร',
            province: 'มุกดาหาร',
            zipcode: 49000,
        },
        {
            subDistrict: 'ดงมอน',
            district: 'เมืองมุกดาหาร',
            province: 'มุกดาหาร',
            zipcode: 49000,
        },
        {
            subDistrict: 'ดงเย็น',
            district: 'เมืองมุกดาหาร',
            province: 'มุกดาหาร',
            zipcode: 49000,
        },
        {
            subDistrict: 'นาสีนวน',
            district: 'เมืองมุกดาหาร',
            province: 'มุกดาหาร',
            zipcode: 49000,
        },
        {
            subDistrict: 'นาโสก',
            district: 'เมืองมุกดาหาร',
            province: 'มุกดาหาร',
            zipcode: 49000,
        },
        {
            subDistrict: 'บางทรายใหญ่',
            district: 'เมืองมุกดาหาร',
            province: 'มุกดาหาร',
            zipcode: 49000,
        },
        {
            subDistrict: 'บ้านโคก',
            district: 'เมืองมุกดาหาร',
            province: 'มุกดาหาร',
            zipcode: 49000,
        },
        {
            subDistrict: 'ผึ่งแดด',
            district: 'เมืองมุกดาหาร',
            province: 'มุกดาหาร',
            zipcode: 49000,
        },
        {
            subDistrict: 'มุกดาหาร',
            district: 'เมืองมุกดาหาร',
            province: 'มุกดาหาร',
            zipcode: 49000,
        },
        {
            subDistrict: 'ศรีบุญเรือง',
            district: 'เมืองมุกดาหาร',
            province: 'มุกดาหาร',
            zipcode: 49000,
        },
        {
            subDistrict: 'โพนทราย',
            district: 'เมืองมุกดาหาร',
            province: 'มุกดาหาร',
            zipcode: 49000,
        },
        {
            subDistrict: 'กรงปินัง',
            district: 'กรงปินัง',
            province: 'ยะลา',
            zipcode: 95000,
        },
        {
            subDistrict: 'ปุโรง',
            district: 'กรงปินัง',
            province: 'ยะลา',
            zipcode: 95000,
        },
        {
            subDistrict: 'สะเอะ',
            district: 'กรงปินัง',
            province: 'ยะลา',
            zipcode: 95000,
        },
        {
            subDistrict: 'ห้วยกระทิง',
            district: 'กรงปินัง',
            province: 'ยะลา',
            zipcode: 95000,
        },
        {
            subDistrict: 'กาบัง',
            district: 'กาบัง',
            province: 'ยะลา',
            zipcode: 95120,
        },
        {
            subDistrict: 'บาละ',
            district: 'กาบัง',
            province: 'ยะลา',
            zipcode: 95120,
        },
        {
            subDistrict: 'คีรีเขต',
            district: 'ธารโต',
            province: 'ยะลา',
            zipcode: 95150,
        },
        {
            subDistrict: 'ธารโต',
            district: 'ธารโต',
            province: 'ยะลา',
            zipcode: 95150,
        },
        {
            subDistrict: 'บ้านแหร',
            district: 'ธารโต',
            province: 'ยะลา',
            zipcode: 95150,
        },
        {
            subDistrict: 'แม่หวาด',
            district: 'ธารโต',
            province: 'ยะลา',
            zipcode: 95170,
        },
        {
            subDistrict: 'ตลิ่งชัน',
            district: 'บันนังสตา',
            province: 'ยะลา',
            zipcode: 95130,
        },
        {
            subDistrict: 'ตาเนาะปูเต๊ะ',
            district: 'บันนังสตา',
            province: 'ยะลา',
            zipcode: 95130,
        },
        {
            subDistrict: 'ถ้ำทะลุ',
            district: 'บันนังสตา',
            province: 'ยะลา',
            zipcode: 95130,
        },
        {
            subDistrict: 'บันนังสตา',
            district: 'บันนังสตา',
            province: 'ยะลา',
            zipcode: 95130,
        },
        {
            subDistrict: 'บาเจาะ',
            district: 'บันนังสตา',
            province: 'ยะลา',
            zipcode: 95130,
        },
        {
            subDistrict: 'เขื่อนบางลาง',
            district: 'บันนังสตา',
            province: 'ยะลา',
            zipcode: 95130,
        },
        {
            subDistrict: 'กาตอง',
            district: 'ยะหา',
            province: 'ยะลา',
            zipcode: 95120,
        },
        {
            subDistrict: 'ตาชี',
            district: 'ยะหา',
            province: 'ยะลา',
            zipcode: 95120,
        },
        {
            subDistrict: 'บาโงยซิแน',
            district: 'ยะหา',
            province: 'ยะลา',
            zipcode: 95120,
        },
        {
            subDistrict: 'บาโร๊ะ',
            district: 'ยะหา',
            province: 'ยะลา',
            zipcode: 95120,
        },
        {
            subDistrict: 'ปะแต',
            district: 'ยะหา',
            province: 'ยะลา',
            zipcode: 95120,
        },
        {
            subDistrict: 'ยะหา',
            district: 'ยะหา',
            province: 'ยะลา',
            zipcode: 95120,
        },
        {
            subDistrict: 'ละแอ',
            district: 'ยะหา',
            province: 'ยะลา',
            zipcode: 95120,
        },
        {
            subDistrict: 'กอตอตือร๊ะ',
            district: 'รามัน',
            province: 'ยะลา',
            zipcode: 95140,
        },
        {
            subDistrict: 'กายูบอเกาะ',
            district: 'รามัน',
            province: 'ยะลา',
            zipcode: 95140,
        },
        {
            subDistrict: 'กาลอ',
            district: 'รามัน',
            province: 'ยะลา',
            zipcode: 95140,
        },
        {
            subDistrict: 'กาลูปัง',
            district: 'รามัน',
            province: 'ยะลา',
            zipcode: 95140,
        },
        {
            subDistrict: 'จะกว๊ะ',
            district: 'รามัน',
            province: 'ยะลา',
            zipcode: 95140,
        },
        {
            subDistrict: 'ตะโล๊ะหะลอ',
            district: 'รามัน',
            province: 'ยะลา',
            zipcode: 95140,
        },
        {
            subDistrict: 'ท่าธง',
            district: 'รามัน',
            province: 'ยะลา',
            zipcode: 95140,
        },
        {
            subDistrict: 'บาลอ',
            district: 'รามัน',
            province: 'ยะลา',
            zipcode: 95140,
        },
        {
            subDistrict: 'บาโงย',
            district: 'รามัน',
            province: 'ยะลา',
            zipcode: 95140,
        },
        {
            subDistrict: 'บือมัง',
            district: 'รามัน',
            province: 'ยะลา',
            zipcode: 95140,
        },
        {
            subDistrict: 'ยะต๊ะ',
            district: 'รามัน',
            province: 'ยะลา',
            zipcode: 95140,
        },
        {
            subDistrict: 'วังพญา',
            district: 'รามัน',
            province: 'ยะลา',
            zipcode: 95140,
        },
        {
            subDistrict: 'อาซ่อง',
            district: 'รามัน',
            province: 'ยะลา',
            zipcode: 95140,
        },
        {
            subDistrict: 'เกะรอ',
            district: 'รามัน',
            province: 'ยะลา',
            zipcode: 95140,
        },
        {
            subDistrict: 'เนินงาม',
            district: 'รามัน',
            province: 'ยะลา',
            zipcode: 95140,
        },
        {
            subDistrict: 'โกตาบารู',
            district: 'รามัน',
            province: 'ยะลา',
            zipcode: 95140,
        },
        {
            subDistrict: 'ตาเนาะแมเราะ',
            district: 'เบตง',
            province: 'ยะลา',
            zipcode: 95110,
        },
        {
            subDistrict: 'ธารน้ำทิพย์',
            district: 'เบตง',
            province: 'ยะลา',
            zipcode: 95110,
        },
        {
            subDistrict: 'ยะรม',
            district: 'เบตง',
            province: 'ยะลา',
            zipcode: 95110,
        },
        {
            subDistrict: 'อัยเยอร์เวง',
            district: 'เบตง',
            province: 'ยะลา',
            zipcode: 95110,
        },
        {
            subDistrict: 'เบตง',
            district: 'เบตง',
            province: 'ยะลา',
            zipcode: 95110,
        },
        {
            subDistrict: 'ตาเซะ',
            district: 'เมืองยะลา',
            province: 'ยะลา',
            zipcode: 95000,
        },
        {
            subDistrict: 'ท่าสาป',
            district: 'เมืองยะลา',
            province: 'ยะลา',
            zipcode: 95000,
        },
        {
            subDistrict: 'บันนังสาเรง',
            district: 'เมืองยะลา',
            province: 'ยะลา',
            zipcode: 95000,
        },
        {
            subDistrict: 'บุดี',
            district: 'เมืองยะลา',
            province: 'ยะลา',
            zipcode: 95000,
        },
        {
            subDistrict: 'พร่อน',
            district: 'เมืองยะลา',
            province: 'ยะลา',
            zipcode: 95160,
        },
        {
            subDistrict: 'ยะลา',
            district: 'เมืองยะลา',
            province: 'ยะลา',
            zipcode: 95000,
        },
        {
            subDistrict: 'ยุโป',
            district: 'เมืองยะลา',
            province: 'ยะลา',
            zipcode: 95000,
        },
        {
            subDistrict: 'ลำพะยา',
            district: 'เมืองยะลา',
            province: 'ยะลา',
            zipcode: 95160,
        },
        {
            subDistrict: 'ลำใหม่',
            district: 'เมืองยะลา',
            province: 'ยะลา',
            zipcode: 95160,
        },
        {
            subDistrict: 'ลิดล',
            district: 'เมืองยะลา',
            province: 'ยะลา',
            zipcode: 95160,
        },
        {
            subDistrict: 'สะเตง',
            district: 'เมืองยะลา',
            province: 'ยะลา',
            zipcode: 95000,
        },
        {
            subDistrict: 'สะเตงนอก',
            district: 'เมืองยะลา',
            province: 'ยะลา',
            zipcode: 95000,
        },
        {
            subDistrict: 'หน้าถ้ำ',
            district: 'เมืองยะลา',
            province: 'ยะลา',
            zipcode: 95000,
        },
        {
            subDistrict: 'เปาะเส้ง',
            district: 'เมืองยะลา',
            province: 'ยะลา',
            zipcode: 95000,
        },
        {
            subDistrict: 'กำแมด',
            district: 'กุดชุม',
            province: 'ยโสธร',
            zipcode: 35140,
        },
        {
            subDistrict: 'กุดชุม',
            district: 'กุดชุม',
            province: 'ยโสธร',
            zipcode: 35140,
        },
        {
            subDistrict: 'คำน้ำสร้าง',
            district: 'กุดชุม',
            province: 'ยโสธร',
            zipcode: 35140,
        },
        {
            subDistrict: 'นาโส่',
            district: 'กุดชุม',
            province: 'ยโสธร',
            zipcode: 35140,
        },
        {
            subDistrict: 'หนองหมี',
            district: 'กุดชุม',
            province: 'ยโสธร',
            zipcode: 35140,
        },
        {
            subDistrict: 'หนองแหน',
            district: 'กุดชุม',
            province: 'ยโสธร',
            zipcode: 35140,
        },
        {
            subDistrict: 'ห้วยแก้ง',
            district: 'กุดชุม',
            province: 'ยโสธร',
            zipcode: 35140,
        },
        {
            subDistrict: 'โนนเปือย',
            district: 'กุดชุม',
            province: 'ยโสธร',
            zipcode: 35140,
        },
        {
            subDistrict: 'โพนงาม',
            district: 'กุดชุม',
            province: 'ยโสธร',
            zipcode: 35140,
        },
        {
            subDistrict: 'กุดกุง',
            district: 'คำเขื่อนแก้ว',
            province: 'ยโสธร',
            zipcode: 35110,
        },
        {
            subDistrict: 'กู่จาน',
            district: 'คำเขื่อนแก้ว',
            province: 'ยโสธร',
            zipcode: 35110,
        },
        {
            subDistrict: 'ดงเจริญ',
            district: 'คำเขื่อนแก้ว',
            province: 'ยโสธร',
            zipcode: 35110,
        },
        {
            subDistrict: 'ดงแคนใหญ่',
            district: 'คำเขื่อนแก้ว',
            province: 'ยโสธร',
            zipcode: 35180,
        },
        {
            subDistrict: 'ทุ่งมน',
            district: 'คำเขื่อนแก้ว',
            province: 'ยโสธร',
            zipcode: 35110,
        },
        {
            subDistrict: 'นาคำ',
            district: 'คำเขื่อนแก้ว',
            province: 'ยโสธร',
            zipcode: 35180,
        },
        {
            subDistrict: 'นาแก',
            district: 'คำเขื่อนแก้ว',
            province: 'ยโสธร',
            zipcode: 35180,
        },
        {
            subDistrict: 'ย่อ',
            district: 'คำเขื่อนแก้ว',
            province: 'ยโสธร',
            zipcode: 35110,
        },
        {
            subDistrict: 'ลุมพุก',
            district: 'คำเขื่อนแก้ว',
            province: 'ยโสธร',
            zipcode: 35110,
        },
        {
            subDistrict: 'สงเปือย',
            district: 'คำเขื่อนแก้ว',
            province: 'ยโสธร',
            zipcode: 35110,
        },
        {
            subDistrict: 'เหล่าไฮ',
            district: 'คำเขื่อนแก้ว',
            province: 'ยโสธร',
            zipcode: 35110,
        },
        {
            subDistrict: 'แคนน้อย',
            district: 'คำเขื่อนแก้ว',
            province: 'ยโสธร',
            zipcode: 35180,
        },
        {
            subDistrict: 'โพนทัน',
            district: 'คำเขื่อนแก้ว',
            province: 'ยโสธร',
            zipcode: 35110,
        },
        {
            subDistrict: 'กุดน้ำใส',
            district: 'ค้อวัง',
            province: 'ยโสธร',
            zipcode: 35160,
        },
        {
            subDistrict: 'ค้อวัง',
            district: 'ค้อวัง',
            province: 'ยโสธร',
            zipcode: 35160,
        },
        {
            subDistrict: 'น้ำอ้อม',
            district: 'ค้อวัง',
            province: 'ยโสธร',
            zipcode: 35160,
        },
        {
            subDistrict: 'ฟ้าห่วน',
            district: 'ค้อวัง',
            province: 'ยโสธร',
            zipcode: 35160,
        },
        {
            subDistrict: 'ดงมะไฟ',
            district: 'ทรายมูล',
            province: 'ยโสธร',
            zipcode: 35170,
        },
        {
            subDistrict: 'ดู่ลาด',
            district: 'ทรายมูล',
            province: 'ยโสธร',
            zipcode: 35170,
        },
        {
            subDistrict: 'ทรายมูล',
            district: 'ทรายมูล',
            province: 'ยโสธร',
            zipcode: 35170,
        },
        {
            subDistrict: 'นาเวียง',
            district: 'ทรายมูล',
            province: 'ยโสธร',
            zipcode: 35170,
        },
        {
            subDistrict: 'ไผ่',
            district: 'ทรายมูล',
            province: 'ยโสธร',
            zipcode: 35170,
        },
        {
            subDistrict: 'กระจาย',
            district: 'ป่าติ้ว',
            province: 'ยโสธร',
            zipcode: 35150,
        },
        {
            subDistrict: 'ศรีฐาน',
            district: 'ป่าติ้ว',
            province: 'ยโสธร',
            zipcode: 35150,
        },
        {
            subDistrict: 'เชียงเพ็ง',
            district: 'ป่าติ้ว',
            province: 'ยโสธร',
            zipcode: 35150,
        },
        {
            subDistrict: 'โคกนาโก',
            district: 'ป่าติ้ว',
            province: 'ยโสธร',
            zipcode: 35150,
        },
        {
            subDistrict: 'โพธิ์ไทร',
            district: 'ป่าติ้ว',
            province: 'ยโสธร',
            zipcode: 35150,
        },
        {
            subDistrict: 'คูเมือง',
            district: 'มหาชนะชัย',
            province: 'ยโสธร',
            zipcode: 35130,
        },
        {
            subDistrict: 'บากเรือ',
            district: 'มหาชนะชัย',
            province: 'ยโสธร',
            zipcode: 35130,
        },
        {
            subDistrict: 'บึงแก',
            district: 'มหาชนะชัย',
            province: 'ยโสธร',
            zipcode: 35130,
        },
        {
            subDistrict: 'ผือฮี',
            district: 'มหาชนะชัย',
            province: 'ยโสธร',
            zipcode: 35130,
        },
        {
            subDistrict: 'พระเสาร์',
            district: 'มหาชนะชัย',
            province: 'ยโสธร',
            zipcode: 35130,
        },
        {
            subDistrict: 'ฟ้าหยาด',
            district: 'มหาชนะชัย',
            province: 'ยโสธร',
            zipcode: 35130,
        },
        {
            subDistrict: 'ม่วง',
            district: 'มหาชนะชัย',
            province: 'ยโสธร',
            zipcode: 35130,
        },
        {
            subDistrict: 'สงยาง',
            district: 'มหาชนะชัย',
            province: 'ยโสธร',
            zipcode: 35130,
        },
        {
            subDistrict: 'หัวเมือง',
            district: 'มหาชนะชัย',
            province: 'ยโสธร',
            zipcode: 35130,
        },
        {
            subDistrict: 'โนนทราย',
            district: 'มหาชนะชัย',
            province: 'ยโสธร',
            zipcode: 35130,
        },
        {
            subDistrict: 'ขั้นไดใหญ่',
            district: 'เมืองยโสธร',
            province: 'ยโสธร',
            zipcode: 35000,
        },
        {
            subDistrict: 'ขุมเงิน',
            district: 'เมืองยโสธร',
            province: 'ยโสธร',
            zipcode: 35000,
        },
        {
            subDistrict: 'ค้อเหนือ',
            district: 'เมืองยโสธร',
            province: 'ยโสธร',
            zipcode: 35000,
        },
        {
            subDistrict: 'ดู่ทุ่ง',
            district: 'เมืองยโสธร',
            province: 'ยโสธร',
            zipcode: 35000,
        },
        {
            subDistrict: 'ตาดทอง',
            district: 'เมืองยโสธร',
            province: 'ยโสธร',
            zipcode: 35000,
        },
        {
            subDistrict: 'ทุ่งนางโอก',
            district: 'เมืองยโสธร',
            province: 'ยโสธร',
            zipcode: 35000,
        },
        {
            subDistrict: 'ทุ่งแต้',
            district: 'เมืองยโสธร',
            province: 'ยโสธร',
            zipcode: 35000,
        },
        {
            subDistrict: 'นาสะไมย์',
            district: 'เมืองยโสธร',
            province: 'ยโสธร',
            zipcode: 35000,
        },
        {
            subDistrict: 'น้ำคำใหญ่',
            district: 'เมืองยโสธร',
            province: 'ยโสธร',
            zipcode: 35000,
        },
        {
            subDistrict: 'สำราญ',
            district: 'เมืองยโสธร',
            province: 'ยโสธร',
            zipcode: 35000,
        },
        {
            subDistrict: 'สิงห์',
            district: 'เมืองยโสธร',
            province: 'ยโสธร',
            zipcode: 35000,
        },
        {
            subDistrict: 'หนองคู',
            district: 'เมืองยโสธร',
            province: 'ยโสธร',
            zipcode: 35000,
        },
        {
            subDistrict: 'หนองหิน',
            district: 'เมืองยโสธร',
            province: 'ยโสธร',
            zipcode: 35000,
        },
        {
            subDistrict: 'หนองเป็ด',
            district: 'เมืองยโสธร',
            province: 'ยโสธร',
            zipcode: 35000,
        },
        {
            subDistrict: 'หนองเรือ',
            district: 'เมืองยโสธร',
            province: 'ยโสธร',
            zipcode: 35000,
        },
        {
            subDistrict: 'เขื่องคำ',
            district: 'เมืองยโสธร',
            province: 'ยโสธร',
            zipcode: 35000,
        },
        {
            subDistrict: 'เดิด',
            district: 'เมืองยโสธร',
            province: 'ยโสธร',
            zipcode: 35000,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'เมืองยโสธร',
            province: 'ยโสธร',
            zipcode: 35000,
        },
        {
            subDistrict: 'กุดเชียงหมี',
            district: 'เลิงนกทา',
            province: 'ยโสธร',
            zipcode: 35120,
        },
        {
            subDistrict: 'กุดแห่',
            district: 'เลิงนกทา',
            province: 'ยโสธร',
            zipcode: 35120,
        },
        {
            subDistrict: 'บุ่งค้า',
            district: 'เลิงนกทา',
            province: 'ยโสธร',
            zipcode: 35120,
        },
        {
            subDistrict: 'ศรีแก้ว',
            district: 'เลิงนกทา',
            province: 'ยโสธร',
            zipcode: 35120,
        },
        {
            subDistrict: 'สร้างมิ่ง',
            district: 'เลิงนกทา',
            province: 'ยโสธร',
            zipcode: 35120,
        },
        {
            subDistrict: 'สวาท',
            district: 'เลิงนกทา',
            province: 'ยโสธร',
            zipcode: 35120,
        },
        {
            subDistrict: 'สามัคคี',
            district: 'เลิงนกทา',
            province: 'ยโสธร',
            zipcode: 35120,
        },
        {
            subDistrict: 'สามแยก',
            district: 'เลิงนกทา',
            province: 'ยโสธร',
            zipcode: 35120,
        },
        {
            subDistrict: 'ห้องแซง',
            district: 'เลิงนกทา',
            province: 'ยโสธร',
            zipcode: 35120,
        },
        {
            subDistrict: 'โคกสำราญ',
            district: 'เลิงนกทา',
            province: 'ยโสธร',
            zipcode: 35120,
        },
        {
            subDistrict: 'คำเตย',
            district: 'ไทยเจริญ',
            province: 'ยโสธร',
            zipcode: 35120,
        },
        {
            subDistrict: 'คำไผ่',
            district: 'ไทยเจริญ',
            province: 'ยโสธร',
            zipcode: 35120,
        },
        {
            subDistrict: 'น้ำคำ',
            district: 'ไทยเจริญ',
            province: 'ยโสธร',
            zipcode: 35120,
        },
        {
            subDistrict: 'ส้มผ่อ',
            district: 'ไทยเจริญ',
            province: 'ยโสธร',
            zipcode: 35120,
        },
        {
            subDistrict: 'ไทยเจริญ',
            district: 'ไทยเจริญ',
            province: 'ยโสธร',
            zipcode: 35120,
        },
        {
            subDistrict: 'จ.ป.ร.',
            district: 'กระบุรี',
            province: 'ระนอง',
            zipcode: 85110,
        },
        {
            subDistrict: 'น้ำจืด',
            district: 'กระบุรี',
            province: 'ระนอง',
            zipcode: 85110,
        },
        {
            subDistrict: 'น้ำจืดน้อย',
            district: 'กระบุรี',
            province: 'ระนอง',
            zipcode: 85110,
        },
        {
            subDistrict: 'บางใหญ่',
            district: 'กระบุรี',
            province: 'ระนอง',
            zipcode: 85110,
        },
        {
            subDistrict: 'ปากจั่น',
            district: 'กระบุรี',
            province: 'ระนอง',
            zipcode: 85110,
        },
        {
            subDistrict: 'มะมุ',
            district: 'กระบุรี',
            province: 'ระนอง',
            zipcode: 85110,
        },
        {
            subDistrict: 'ลำเลียง',
            district: 'กระบุรี',
            province: 'ระนอง',
            zipcode: 85110,
        },
        {
            subDistrict: 'กะเปอร์',
            district: 'กะเปอร์',
            province: 'ระนอง',
            zipcode: 85120,
        },
        {
            subDistrict: 'บางหิน',
            district: 'กะเปอร์',
            province: 'ระนอง',
            zipcode: 85120,
        },
        {
            subDistrict: 'บ้านนา',
            district: 'กะเปอร์',
            province: 'ระนอง',
            zipcode: 85120,
        },
        {
            subDistrict: 'ม่วงกลวง',
            district: 'กะเปอร์',
            province: 'ระนอง',
            zipcode: 85120,
        },
        {
            subDistrict: 'เชี่ยวเหลียง',
            district: 'กะเปอร์',
            province: 'ระนอง',
            zipcode: 85120,
        },
        {
            subDistrict: 'บางพระเหนือ',
            district: 'ละอุ่น',
            province: 'ระนอง',
            zipcode: 85130,
        },
        {
            subDistrict: 'บางพระใต้',
            district: 'ละอุ่น',
            province: 'ระนอง',
            zipcode: 85130,
        },
        {
            subDistrict: 'บางแก้ว',
            district: 'ละอุ่น',
            province: 'ระนอง',
            zipcode: 85130,
        },
        {
            subDistrict: 'ละอุ่นเหนือ',
            district: 'ละอุ่น',
            province: 'ระนอง',
            zipcode: 85130,
        },
        {
            subDistrict: 'ละอุ่นใต้',
            district: 'ละอุ่น',
            province: 'ระนอง',
            zipcode: 85130,
        },
        {
            subDistrict: 'ในวงเหนือ',
            district: 'ละอุ่น',
            province: 'ระนอง',
            zipcode: 85130,
        },
        {
            subDistrict: 'ในวงใต้',
            district: 'ละอุ่น',
            province: 'ระนอง',
            zipcode: 85130,
        },
        {
            subDistrict: 'กำพวน',
            district: 'สุขสำราญ',
            province: 'ระนอง',
            zipcode: 85120,
        },
        {
            subDistrict: 'นาคา',
            district: 'สุขสำราญ',
            province: 'ระนอง',
            zipcode: 85120,
        },
        {
            subDistrict: 'ทรายแดง',
            district: 'เมืองระนอง',
            province: 'ระนอง',
            zipcode: 85130,
        },
        {
            subDistrict: 'บางนอน',
            district: 'เมืองระนอง',
            province: 'ระนอง',
            zipcode: 85000,
        },
        {
            subDistrict: 'บางริ้น',
            district: 'เมืองระนอง',
            province: 'ระนอง',
            zipcode: 85000,
        },
        {
            subDistrict: 'ปากน้ำ',
            district: 'เมืองระนอง',
            province: 'ระนอง',
            zipcode: 85000,
        },
        {
            subDistrict: 'ราชกรูด',
            district: 'เมืองระนอง',
            province: 'ระนอง',
            zipcode: 85000,
        },
        {
            subDistrict: 'หงาว',
            district: 'เมืองระนอง',
            province: 'ระนอง',
            zipcode: 85000,
        },
        {
            subDistrict: 'หาดส้มแป้น',
            district: 'เมืองระนอง',
            province: 'ระนอง',
            zipcode: 85000,
        },
        {
            subDistrict: 'เกาะพยาม',
            district: 'เมืองระนอง',
            province: 'ระนอง',
            zipcode: 85000,
        },
        {
            subDistrict: 'เขานิเวศน์',
            district: 'เมืองระนอง',
            province: 'ระนอง',
            zipcode: 85000,
        },
        {
            subDistrict: 'นิคมพัฒนา',
            district: 'นิคมพัฒนา',
            province: 'ระยอง',
            zipcode: 21180,
        },
        {
            subDistrict: 'พนานิคม',
            district: 'นิคมพัฒนา',
            province: 'ระยอง',
            zipcode: 21180,
        },
        {
            subDistrict: 'มะขามคู่',
            district: 'นิคมพัฒนา',
            province: 'ระยอง',
            zipcode: 21180,
        },
        {
            subDistrict: 'มาบข่า',
            district: 'นิคมพัฒนา',
            province: 'ระยอง',
            zipcode: 21180,
        },
        {
            subDistrict: 'ชากบก',
            district: 'บ้านค่าย',
            province: 'ระยอง',
            zipcode: 21120,
        },
        {
            subDistrict: 'ตาขัน',
            district: 'บ้านค่าย',
            province: 'ระยอง',
            zipcode: 21120,
        },
        {
            subDistrict: 'บางบุตร',
            district: 'บ้านค่าย',
            province: 'ระยอง',
            zipcode: 21120,
        },
        {
            subDistrict: 'บ้านค่าย',
            district: 'บ้านค่าย',
            province: 'ระยอง',
            zipcode: 21120,
        },
        {
            subDistrict: 'หนองตะพาน',
            district: 'บ้านค่าย',
            province: 'ระยอง',
            zipcode: 21120,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'บ้านค่าย',
            province: 'ระยอง',
            zipcode: 21120,
        },
        {
            subDistrict: 'หนองละลอก',
            district: 'บ้านค่าย',
            province: 'ระยอง',
            zipcode: 21120,
        },
        {
            subDistrict: 'บ้านฉาง',
            district: 'บ้านฉาง',
            province: 'ระยอง',
            zipcode: 21130,
        },
        {
            subDistrict: 'พลา',
            district: 'บ้านฉาง',
            province: 'ระยอง',
            zipcode: 21130,
        },
        {
            subDistrict: 'สำนักท้อน',
            district: 'บ้านฉาง',
            province: 'ระยอง',
            zipcode: 21130,
        },
        {
            subDistrict: 'ตาสิทธิ์',
            district: 'ปลวกแดง',
            province: 'ระยอง',
            zipcode: 21140,
        },
        {
            subDistrict: 'ปลวกแดง',
            district: 'ปลวกแดง',
            province: 'ระยอง',
            zipcode: 21140,
        },
        {
            subDistrict: 'มาบยางพร',
            district: 'ปลวกแดง',
            province: 'ระยอง',
            zipcode: 21140,
        },
        {
            subDistrict: 'ละหาร',
            district: 'ปลวกแดง',
            province: 'ระยอง',
            zipcode: 21140,
        },
        {
            subDistrict: 'หนองไร่',
            district: 'ปลวกแดง',
            province: 'ระยอง',
            zipcode: 21140,
        },
        {
            subDistrict: 'แม่น้ำคู้',
            district: 'ปลวกแดง',
            province: 'ระยอง',
            zipcode: 21140,
        },
        {
            subDistrict: 'ชุมแสง',
            district: 'วังจันทร์',
            province: 'ระยอง',
            zipcode: 21210,
        },
        {
            subDistrict: 'ป่ายุบใน',
            district: 'วังจันทร์',
            province: 'ระยอง',
            zipcode: 21210,
        },
        {
            subDistrict: 'พลงตาเอี่ยม',
            district: 'วังจันทร์',
            province: 'ระยอง',
            zipcode: 21210,
        },
        {
            subDistrict: 'วังจันทร์',
            district: 'วังจันทร์',
            province: 'ระยอง',
            zipcode: 21210,
        },
        {
            subDistrict: 'ชำฆ้อ',
            district: 'เขาชะเมา',
            province: 'ระยอง',
            zipcode: 21110,
        },
        {
            subDistrict: 'น้ำเป็น',
            district: 'เขาชะเมา',
            province: 'ระยอง',
            zipcode: 21110,
        },
        {
            subDistrict: 'ห้วยทับมอญ',
            district: 'เขาชะเมา',
            province: 'ระยอง',
            zipcode: 21110,
        },
        {
            subDistrict: 'เขาน้อย',
            district: 'เขาชะเมา',
            province: 'ระยอง',
            zipcode: 21110,
        },
        {
            subDistrict: 'กะเฉด',
            district: 'เมืองระยอง',
            province: 'ระยอง',
            zipcode: 21100,
        },
        {
            subDistrict: 'ตะพง',
            district: 'เมืองระยอง',
            province: 'ระยอง',
            zipcode: 21000,
        },
        {
            subDistrict: 'ทับมา',
            district: 'เมืองระยอง',
            province: 'ระยอง',
            zipcode: 21000,
        },
        {
            subDistrict: 'ท่าประดู่',
            district: 'เมืองระยอง',
            province: 'ระยอง',
            zipcode: 21000,
        },
        {
            subDistrict: 'นาตาขวัญ',
            district: 'เมืองระยอง',
            province: 'ระยอง',
            zipcode: 21000,
        },
        {
            subDistrict: 'น้ำคอก',
            district: 'เมืองระยอง',
            province: 'ระยอง',
            zipcode: 21000,
        },
        {
            subDistrict: 'บ้านแลง',
            district: 'เมืองระยอง',
            province: 'ระยอง',
            zipcode: 21000,
        },
        {
            subDistrict: 'ปากน้ำ',
            district: 'เมืองระยอง',
            province: 'ระยอง',
            zipcode: 21000,
        },
        {
            subDistrict: 'มาบตาพุด',
            district: 'เมืองระยอง',
            province: 'ระยอง',
            zipcode: 21150,
        },
        {
            subDistrict: 'สำนักทอง',
            district: 'เมืองระยอง',
            province: 'ระยอง',
            zipcode: 21100,
        },
        {
            subDistrict: 'ห้วยโป่ง',
            district: 'เมืองระยอง',
            province: 'ระยอง',
            zipcode: 21150,
        },
        {
            subDistrict: 'เชิงเนิน',
            district: 'เมืองระยอง',
            province: 'ระยอง',
            zipcode: 21000,
        },
        {
            subDistrict: 'เนินพระ',
            district: 'เมืองระยอง',
            province: 'ระยอง',
            zipcode: 21000,
        },
        {
            subDistrict: 'เนินพระ',
            district: 'เมืองระยอง',
            province: 'ระยอง',
            zipcode: 21150,
        },
        {
            subDistrict: 'เพ',
            district: 'เมืองระยอง',
            province: 'ระยอง',
            zipcode: 21160,
        },
        {
            subDistrict: 'แกลง',
            district: 'เมืองระยอง',
            province: 'ระยอง',
            zipcode: 21160,
        },
        {
            subDistrict: 'กระแสบน',
            district: 'แกลง',
            province: 'ระยอง',
            zipcode: 21110,
        },
        {
            subDistrict: 'กร่ำ',
            district: 'แกลง',
            province: 'ระยอง',
            zipcode: 21190,
        },
        {
            subDistrict: 'กองดิน',
            district: 'แกลง',
            province: 'ระยอง',
            zipcode: 22160,
        },
        {
            subDistrict: 'คลองปูน',
            district: 'แกลง',
            province: 'ระยอง',
            zipcode: 21170,
        },
        {
            subDistrict: 'ชากพง',
            district: 'แกลง',
            province: 'ระยอง',
            zipcode: 21190,
        },
        {
            subDistrict: 'ชากโดน',
            district: 'แกลง',
            province: 'ระยอง',
            zipcode: 21110,
        },
        {
            subDistrict: 'ทางเกวียน',
            district: 'แกลง',
            province: 'ระยอง',
            zipcode: 21110,
        },
        {
            subDistrict: 'ทุ่งควายกิน',
            district: 'แกลง',
            province: 'ระยอง',
            zipcode: 21110,
        },
        {
            subDistrict: 'บ้านนา',
            district: 'แกลง',
            province: 'ระยอง',
            zipcode: 21110,
        },
        {
            subDistrict: 'ปากน้ำกระแส',
            district: 'แกลง',
            province: 'ระยอง',
            zipcode: 21170,
        },
        {
            subDistrict: 'พังราด',
            district: 'แกลง',
            province: 'ระยอง',
            zipcode: 21170,
        },
        {
            subDistrict: 'วังหว้า',
            district: 'แกลง',
            province: 'ระยอง',
            zipcode: 21110,
        },
        {
            subDistrict: 'สองสลึง',
            district: 'แกลง',
            province: 'ระยอง',
            zipcode: 21110,
        },
        {
            subDistrict: 'ห้วยยาง',
            district: 'แกลง',
            province: 'ระยอง',
            zipcode: 21110,
        },
        {
            subDistrict: 'เนินฆ้อ',
            district: 'แกลง',
            province: 'ระยอง',
            zipcode: 21110,
        },
        {
            subDistrict: 'จอมบึง',
            district: 'จอมบึง',
            province: 'ราชบุรี',
            zipcode: 70150,
        },
        {
            subDistrict: 'ด่านทับตะโก',
            district: 'จอมบึง',
            province: 'ราชบุรี',
            zipcode: 70150,
        },
        {
            subDistrict: 'ปากช่อง',
            district: 'จอมบึง',
            province: 'ราชบุรี',
            zipcode: 70150,
        },
        {
            subDistrict: 'รางบัว',
            district: 'จอมบึง',
            province: 'ราชบุรี',
            zipcode: 70150,
        },
        {
            subDistrict: 'เบิกไพร',
            district: 'จอมบึง',
            province: 'ราชบุรี',
            zipcode: 70150,
        },
        {
            subDistrict: 'แก้มอ้น',
            district: 'จอมบึง',
            province: 'ราชบุรี',
            zipcode: 70150,
        },
        {
            subDistrict: 'ขุนพิทักษ์',
            district: 'ดำเนินสะดวก',
            province: 'ราชบุรี',
            zipcode: 70130,
        },
        {
            subDistrict: 'ดอนกรวย',
            district: 'ดำเนินสะดวก',
            province: 'ราชบุรี',
            zipcode: 70130,
        },
        {
            subDistrict: 'ดอนคลัง',
            district: 'ดำเนินสะดวก',
            province: 'ราชบุรี',
            zipcode: 70130,
        },
        {
            subDistrict: 'ดอนไผ่',
            district: 'ดำเนินสะดวก',
            province: 'ราชบุรี',
            zipcode: 70130,
        },
        {
            subDistrict: 'ดำเนินสะดวก',
            district: 'ดำเนินสะดวก',
            province: 'ราชบุรี',
            zipcode: 70130,
        },
        {
            subDistrict: 'ตาหลวง',
            district: 'ดำเนินสะดวก',
            province: 'ราชบุรี',
            zipcode: 70130,
        },
        {
            subDistrict: 'ท่านัด',
            district: 'ดำเนินสะดวก',
            province: 'ราชบุรี',
            zipcode: 70130,
        },
        {
            subDistrict: 'บัวงาม',
            district: 'ดำเนินสะดวก',
            province: 'ราชบุรี',
            zipcode: 70210,
        },
        {
            subDistrict: 'บ้านไร่',
            district: 'ดำเนินสะดวก',
            province: 'ราชบุรี',
            zipcode: 70130,
        },
        {
            subDistrict: 'ประสาทสิทธิ์',
            district: 'ดำเนินสะดวก',
            province: 'ราชบุรี',
            zipcode: 70210,
        },
        {
            subDistrict: 'ศรีสุราษฎร์',
            district: 'ดำเนินสะดวก',
            province: 'ราชบุรี',
            zipcode: 70130,
        },
        {
            subDistrict: 'สี่หมื่น',
            district: 'ดำเนินสะดวก',
            province: 'ราชบุรี',
            zipcode: 70130,
        },
        {
            subDistrict: 'แพงพวย',
            district: 'ดำเนินสะดวก',
            province: 'ราชบุรี',
            zipcode: 70130,
        },
        {
            subDistrict: 'ดอนคา',
            district: 'บางแพ',
            province: 'ราชบุรี',
            zipcode: 70160,
        },
        {
            subDistrict: 'ดอนใหญ่',
            district: 'บางแพ',
            province: 'ราชบุรี',
            zipcode: 70160,
        },
        {
            subDistrict: 'บางแพ',
            district: 'บางแพ',
            province: 'ราชบุรี',
            zipcode: 70160,
        },
        {
            subDistrict: 'วังเย็น',
            district: 'บางแพ',
            province: 'ราชบุรี',
            zipcode: 70160,
        },
        {
            subDistrict: 'วัดแก้ว',
            district: 'บางแพ',
            province: 'ราชบุรี',
            zipcode: 70160,
        },
        {
            subDistrict: 'หัวโพ',
            district: 'บางแพ',
            province: 'ราชบุรี',
            zipcode: 70160,
        },
        {
            subDistrict: 'โพหัก',
            district: 'บางแพ',
            province: 'ราชบุรี',
            zipcode: 70160,
        },
        {
            subDistrict: 'บ้านคา',
            district: 'บ้านคา',
            province: 'ราชบุรี',
            zipcode: 70180,
        },
        {
            subDistrict: 'บ้านบึง',
            district: 'บ้านคา',
            province: 'ราชบุรี',
            zipcode: 70180,
        },
        {
            subDistrict: 'หนองพันจันทร์',
            district: 'บ้านคา',
            province: 'ราชบุรี',
            zipcode: 70180,
        },
        {
            subDistrict: 'กรับใหญ่',
            district: 'บ้านโป่ง',
            province: 'ราชบุรี',
            zipcode: 70190,
        },
        {
            subDistrict: 'คุ้งพยอม',
            district: 'บ้านโป่ง',
            province: 'ราชบุรี',
            zipcode: 70110,
        },
        {
            subDistrict: 'ดอนกระเบื้อง',
            district: 'บ้านโป่ง',
            province: 'ราชบุรี',
            zipcode: 70110,
        },
        {
            subDistrict: 'ท่าผา',
            district: 'บ้านโป่ง',
            province: 'ราชบุรี',
            zipcode: 70110,
        },
        {
            subDistrict: 'นครชุมน์',
            district: 'บ้านโป่ง',
            province: 'ราชบุรี',
            zipcode: 70110,
        },
        {
            subDistrict: 'บ้านม่วง',
            district: 'บ้านโป่ง',
            province: 'ราชบุรี',
            zipcode: 70110,
        },
        {
            subDistrict: 'บ้านโป่ง',
            district: 'บ้านโป่ง',
            province: 'ราชบุรี',
            zipcode: 70110,
        },
        {
            subDistrict: 'ปากแรต',
            district: 'บ้านโป่ง',
            province: 'ราชบุรี',
            zipcode: 70110,
        },
        {
            subDistrict: 'ลาดบัวขาว',
            district: 'บ้านโป่ง',
            province: 'ราชบุรี',
            zipcode: 70110,
        },
        {
            subDistrict: 'สวนกล้วย',
            district: 'บ้านโป่ง',
            province: 'ราชบุรี',
            zipcode: 70110,
        },
        {
            subDistrict: 'หนองกบ',
            district: 'บ้านโป่ง',
            province: 'ราชบุรี',
            zipcode: 70110,
        },
        {
            subDistrict: 'หนองปลาหมอ',
            district: 'บ้านโป่ง',
            province: 'ราชบุรี',
            zipcode: 70110,
        },
        {
            subDistrict: 'หนองอ้อ',
            district: 'บ้านโป่ง',
            province: 'ราชบุรี',
            zipcode: 70110,
        },
        {
            subDistrict: 'เขาขลุง',
            district: 'บ้านโป่ง',
            province: 'ราชบุรี',
            zipcode: 70110,
        },
        {
            subDistrict: 'เบิกไพร',
            district: 'บ้านโป่ง',
            province: 'ราชบุรี',
            zipcode: 70110,
        },
        {
            subDistrict: 'ดอนทราย',
            district: 'ปากท่อ',
            province: 'ราชบุรี',
            zipcode: 70140,
        },
        {
            subDistrict: 'ทุ่งหลวง',
            district: 'ปากท่อ',
            province: 'ราชบุรี',
            zipcode: 70140,
        },
        {
            subDistrict: 'บ่อกระดาน',
            district: 'ปากท่อ',
            province: 'ราชบุรี',
            zipcode: 70140,
        },
        {
            subDistrict: 'ปากท่อ',
            district: 'ปากท่อ',
            province: 'ราชบุรี',
            zipcode: 70140,
        },
        {
            subDistrict: 'ป่าไก่',
            district: 'ปากท่อ',
            province: 'ราชบุรี',
            zipcode: 70140,
        },
        {
            subDistrict: 'ยางหัก',
            district: 'ปากท่อ',
            province: 'ราชบุรี',
            zipcode: 70140,
        },
        {
            subDistrict: 'วังมะนาว',
            district: 'ปากท่อ',
            province: 'ราชบุรี',
            zipcode: 70140,
        },
        {
            subDistrict: 'วัดยางงาม',
            district: 'ปากท่อ',
            province: 'ราชบุรี',
            zipcode: 70140,
        },
        {
            subDistrict: 'วันดาว',
            district: 'ปากท่อ',
            province: 'ราชบุรี',
            zipcode: 70140,
        },
        {
            subDistrict: 'หนองกระทุ่ม',
            district: 'ปากท่อ',
            province: 'ราชบุรี',
            zipcode: 70140,
        },
        {
            subDistrict: 'ห้วยยางโทน',
            district: 'ปากท่อ',
            province: 'ราชบุรี',
            zipcode: 70140,
        },
        {
            subDistrict: 'อ่างหิน',
            district: 'ปากท่อ',
            province: 'ราชบุรี',
            zipcode: 70140,
        },
        {
            subDistrict: 'จอมประทัด',
            district: 'วัดเพลง',
            province: 'ราชบุรี',
            zipcode: 70170,
        },
        {
            subDistrict: 'วัดเพลง',
            district: 'วัดเพลง',
            province: 'ราชบุรี',
            zipcode: 70170,
        },
        {
            subDistrict: 'เกาะศาลพระ',
            district: 'วัดเพลง',
            province: 'ราชบุรี',
            zipcode: 70170,
        },
        {
            subDistrict: 'ตะนาวศรี',
            district: 'สวนผึ้ง',
            province: 'ราชบุรี',
            zipcode: 70180,
        },
        {
            subDistrict: 'ท่าเคย',
            district: 'สวนผึ้ง',
            province: 'ราชบุรี',
            zipcode: 70180,
        },
        {
            subDistrict: 'ป่าหวาย',
            district: 'สวนผึ้ง',
            province: 'ราชบุรี',
            zipcode: 70180,
        },
        {
            subDistrict: 'สวนผึ้ง',
            district: 'สวนผึ้ง',
            province: 'ราชบุรี',
            zipcode: 70180,
        },
        {
            subDistrict: 'คุ้งกระถิน',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'คุ้งน้ำวน',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'คูบัว',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'ดอนตะโก',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'ดอนแร่',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'ท่าราบ',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'น้ำพุ',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'บางป่า',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'บ้านไร่',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'พงสวาย',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'พิกุลทอง',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'สามเรือน',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'หนองกลางนา',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'หน้าเมือง',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'หลุมดิน',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'หินกอง',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'ห้วยไผ่',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'อ่างทอง',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'เกาะพลับพลา',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'เขาแร้ง',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'เจดีย์หัก',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'โคกหม้อ',
            district: 'เมืองราชบุรี',
            province: 'ราชบุรี',
            zipcode: 70000,
        },
        {
            subDistrict: 'คลองข่อย',
            district: 'โพธาราม',
            province: 'ราชบุรี',
            zipcode: 70120,
        },
        {
            subDistrict: 'คลองตาคต',
            district: 'โพธาราม',
            province: 'ราชบุรี',
            zipcode: 70120,
        },
        {
            subDistrict: 'ชำแระ',
            district: 'โพธาราม',
            province: 'ราชบุรี',
            zipcode: 70120,
        },
        {
            subDistrict: 'ดอนกระเบื้อง',
            district: 'โพธาราม',
            province: 'ราชบุรี',
            zipcode: 70120,
        },
        {
            subDistrict: 'ดอนทราย',
            district: 'โพธาราม',
            province: 'ราชบุรี',
            zipcode: 70120,
        },
        {
            subDistrict: 'ท่าชุมพล',
            district: 'โพธาราม',
            province: 'ราชบุรี',
            zipcode: 70120,
        },
        {
            subDistrict: 'ธรรมเสน',
            district: 'โพธาราม',
            province: 'ราชบุรี',
            zipcode: 70120,
        },
        {
            subDistrict: 'นางแก้ว',
            district: 'โพธาราม',
            province: 'ราชบุรี',
            zipcode: 70120,
        },
        {
            subDistrict: 'บางโตนด',
            district: 'โพธาราม',
            province: 'ราชบุรี',
            zipcode: 70120,
        },
        {
            subDistrict: 'บ้านฆ้อง',
            district: 'โพธาราม',
            province: 'ราชบุรี',
            zipcode: 70120,
        },
        {
            subDistrict: 'บ้านสิงห์',
            district: 'โพธาราม',
            province: 'ราชบุรี',
            zipcode: 70120,
        },
        {
            subDistrict: 'บ้านเลือก',
            district: 'โพธาราม',
            province: 'ราชบุรี',
            zipcode: 70120,
        },
        {
            subDistrict: 'สร้อยฟ้า',
            district: 'โพธาราม',
            province: 'ราชบุรี',
            zipcode: 70120,
        },
        {
            subDistrict: 'หนองกวาง',
            district: 'โพธาราม',
            province: 'ราชบุรี',
            zipcode: 70120,
        },
        {
            subDistrict: 'หนองโพ',
            district: 'โพธาราม',
            province: 'ราชบุรี',
            zipcode: 70120,
        },
        {
            subDistrict: 'เขาชะงุ้ม',
            district: 'โพธาราม',
            province: 'ราชบุรี',
            zipcode: 70120,
        },
        {
            subDistrict: 'เจ็ดเสมียน',
            district: 'โพธาราม',
            province: 'ราชบุรี',
            zipcode: 70120,
        },
        {
            subDistrict: 'เตาปูน',
            district: 'โพธาราม',
            province: 'ราชบุรี',
            zipcode: 70120,
        },
        {
            subDistrict: 'โพธาราม',
            district: 'โพธาราม',
            province: 'ราชบุรี',
            zipcode: 70120,
        },
        {
            subDistrict: 'ดงกลาง',
            district: 'จตุรพักตรพิมาน',
            province: 'ร้อยเอ็ด',
            zipcode: 45180,
        },
        {
            subDistrict: 'ดงแดง',
            district: 'จตุรพักตรพิมาน',
            province: 'ร้อยเอ็ด',
            zipcode: 45180,
        },
        {
            subDistrict: 'ดู่น้อย',
            district: 'จตุรพักตรพิมาน',
            province: 'ร้อยเอ็ด',
            zipcode: 45180,
        },
        {
            subDistrict: 'น้ำใส',
            district: 'จตุรพักตรพิมาน',
            province: 'ร้อยเอ็ด',
            zipcode: 45180,
        },
        {
            subDistrict: 'ป่าสังข์',
            district: 'จตุรพักตรพิมาน',
            province: 'ร้อยเอ็ด',
            zipcode: 45180,
        },
        {
            subDistrict: 'ลิ้นฟ้า',
            district: 'จตุรพักตรพิมาน',
            province: 'ร้อยเอ็ด',
            zipcode: 45180,
        },
        {
            subDistrict: 'ศรีโคตร',
            district: 'จตุรพักตรพิมาน',
            province: 'ร้อยเอ็ด',
            zipcode: 45180,
        },
        {
            subDistrict: 'หนองผือ',
            district: 'จตุรพักตรพิมาน',
            province: 'ร้อยเอ็ด',
            zipcode: 45180,
        },
        {
            subDistrict: 'หัวช้าง',
            district: 'จตุรพักตรพิมาน',
            province: 'ร้อยเอ็ด',
            zipcode: 45180,
        },
        {
            subDistrict: 'อีง่อง',
            district: 'จตุรพักตรพิมาน',
            province: 'ร้อยเอ็ด',
            zipcode: 45180,
        },
        {
            subDistrict: 'เมืองหงส์',
            district: 'จตุรพักตรพิมาน',
            province: 'ร้อยเอ็ด',
            zipcode: 45180,
        },
        {
            subDistrict: 'โคกล่าม',
            district: 'จตุรพักตรพิมาน',
            province: 'ร้อยเอ็ด',
            zipcode: 45180,
        },
        {
            subDistrict: 'จังหาร',
            district: 'จังหาร',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'ดงสิงห์',
            district: 'จังหาร',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'ดินดำ',
            district: 'จังหาร',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'ปาฝา',
            district: 'จังหาร',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'ผักแว่น',
            district: 'จังหาร',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'ม่วงลาด',
            district: 'จังหาร',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'ยางใหญ่',
            district: 'จังหาร',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'แสนชาติ',
            district: 'จังหาร',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'ทุ่งเขาหลวง',
            district: 'ทุ่งเขาหลวง',
            province: 'ร้อยเอ็ด',
            zipcode: 45170,
        },
        {
            subDistrict: 'บึงงาม',
            district: 'ทุ่งเขาหลวง',
            province: 'ร้อยเอ็ด',
            zipcode: 45170,
        },
        {
            subDistrict: 'มะบ้า',
            district: 'ทุ่งเขาหลวง',
            province: 'ร้อยเอ็ด',
            zipcode: 45170,
        },
        {
            subDistrict: 'เทอดไทย',
            district: 'ทุ่งเขาหลวง',
            province: 'ร้อยเอ็ด',
            zipcode: 45170,
        },
        {
            subDistrict: 'เหล่า',
            district: 'ทุ่งเขาหลวง',
            province: 'ร้อยเอ็ด',
            zipcode: 45170,
        },
        {
            subDistrict: 'ธงธานี',
            district: 'ธวัชบุรี',
            province: 'ร้อยเอ็ด',
            zipcode: 45170,
        },
        {
            subDistrict: 'ธวัชบุรี',
            district: 'ธวัชบุรี',
            province: 'ร้อยเอ็ด',
            zipcode: 45170,
        },
        {
            subDistrict: 'นิเวศน์',
            district: 'ธวัชบุรี',
            province: 'ร้อยเอ็ด',
            zipcode: 45170,
        },
        {
            subDistrict: 'บึงนคร',
            district: 'ธวัชบุรี',
            province: 'ร้อยเอ็ด',
            zipcode: 45170,
        },
        {
            subDistrict: 'มะอึ',
            district: 'ธวัชบุรี',
            province: 'ร้อยเอ็ด',
            zipcode: 45170,
        },
        {
            subDistrict: 'ราชธานี',
            district: 'ธวัชบุรี',
            province: 'ร้อยเอ็ด',
            zipcode: 45170,
        },
        {
            subDistrict: 'หนองพอก',
            district: 'ธวัชบุรี',
            province: 'ร้อยเอ็ด',
            zipcode: 45170,
        },
        {
            subDistrict: 'หนองไผ่',
            district: 'ธวัชบุรี',
            province: 'ร้อยเอ็ด',
            zipcode: 45170,
        },
        {
            subDistrict: 'อุ่มเม้า',
            district: 'ธวัชบุรี',
            province: 'ร้อยเอ็ด',
            zipcode: 45170,
        },
        {
            subDistrict: 'เขวาทุ่ง',
            district: 'ธวัชบุรี',
            province: 'ร้อยเอ็ด',
            zipcode: 45170,
        },
        {
            subDistrict: 'เมืองน้อย',
            district: 'ธวัชบุรี',
            province: 'ร้อยเอ็ด',
            zipcode: 45170,
        },
        {
            subDistrict: 'ไพศาล',
            district: 'ธวัชบุรี',
            province: 'ร้อยเอ็ด',
            zipcode: 45170,
        },
        {
            subDistrict: 'ขี้เหล็ก',
            district: 'ปทุมรัตต์',
            province: 'ร้อยเอ็ด',
            zipcode: 45190,
        },
        {
            subDistrict: 'ดอกล้ำ',
            district: 'ปทุมรัตต์',
            province: 'ร้อยเอ็ด',
            zipcode: 45190,
        },
        {
            subDistrict: 'บัวแดง',
            district: 'ปทุมรัตต์',
            province: 'ร้อยเอ็ด',
            zipcode: 45190,
        },
        {
            subDistrict: 'สระบัว',
            district: 'ปทุมรัตต์',
            province: 'ร้อยเอ็ด',
            zipcode: 45190,
        },
        {
            subDistrict: 'หนองแคน',
            district: 'ปทุมรัตต์',
            province: 'ร้อยเอ็ด',
            zipcode: 45190,
        },
        {
            subDistrict: 'โนนสง่า',
            district: 'ปทุมรัตต์',
            province: 'ร้อยเอ็ด',
            zipcode: 45190,
        },
        {
            subDistrict: 'โนนสวรรค์',
            district: 'ปทุมรัตต์',
            province: 'ร้อยเอ็ด',
            zipcode: 45190,
        },
        {
            subDistrict: 'โพนสูง',
            district: 'ปทุมรัตต์',
            province: 'ร้อยเอ็ด',
            zipcode: 45190,
        },
        {
            subDistrict: 'กุดน้ำใส',
            district: 'พนมไพร',
            province: 'ร้อยเอ็ด',
            zipcode: 45140,
        },
        {
            subDistrict: 'คำไฮ',
            district: 'พนมไพร',
            province: 'ร้อยเอ็ด',
            zipcode: 45140,
        },
        {
            subDistrict: 'ค้อใหญ่',
            district: 'พนมไพร',
            province: 'ร้อยเอ็ด',
            zipcode: 45140,
        },
        {
            subDistrict: 'ชานุวรรณ',
            district: 'พนมไพร',
            province: 'ร้อยเอ็ด',
            zipcode: 45140,
        },
        {
            subDistrict: 'นานวล',
            district: 'พนมไพร',
            province: 'ร้อยเอ็ด',
            zipcode: 45140,
        },
        {
            subDistrict: 'พนมไพร',
            district: 'พนมไพร',
            province: 'ร้อยเอ็ด',
            zipcode: 45140,
        },
        {
            subDistrict: 'วารีสวัสดิ์',
            district: 'พนมไพร',
            province: 'ร้อยเอ็ด',
            zipcode: 45140,
        },
        {
            subDistrict: 'สระแก้ว',
            district: 'พนมไพร',
            province: 'ร้อยเอ็ด',
            zipcode: 45140,
        },
        {
            subDistrict: 'หนองทัพไทย',
            district: 'พนมไพร',
            province: 'ร้อยเอ็ด',
            zipcode: 45140,
        },
        {
            subDistrict: 'แสนสุข',
            district: 'พนมไพร',
            province: 'ร้อยเอ็ด',
            zipcode: 45140,
        },
        {
            subDistrict: 'โคกสว่าง',
            district: 'พนมไพร',
            province: 'ร้อยเอ็ด',
            zipcode: 45140,
        },
        {
            subDistrict: 'โพธิ์ชัย',
            district: 'พนมไพร',
            province: 'ร้อยเอ็ด',
            zipcode: 45140,
        },
        {
            subDistrict: 'โพธิ์ใหญ่',
            district: 'พนมไพร',
            province: 'ร้อยเอ็ด',
            zipcode: 45140,
        },
        {
            subDistrict: 'บ้านบาก',
            district: 'ศรีสมเด็จ',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'ศรีสมเด็จ',
            district: 'ศรีสมเด็จ',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'สวนจิก',
            district: 'ศรีสมเด็จ',
            province: 'ร้อยเอ็ด',
            zipcode: 45280,
        },
        {
            subDistrict: 'หนองแวงควง',
            district: 'ศรีสมเด็จ',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'หนองใหญ่',
            district: 'ศรีสมเด็จ',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'เมืองเปลือย',
            district: 'ศรีสมเด็จ',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'โพธิ์ทอง',
            district: 'ศรีสมเด็จ',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'โพธิ์สัย',
            district: 'ศรีสมเด็จ',
            province: 'ร้อยเอ็ด',
            zipcode: 45280,
        },
        {
            subDistrict: 'จำปาขัน',
            district: 'สุวรรณภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45130,
        },
        {
            subDistrict: 'ช้างเผือก',
            district: 'สุวรรณภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45130,
        },
        {
            subDistrict: 'ดอกไม้',
            district: 'สุวรรณภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45130,
        },
        {
            subDistrict: 'ทุ่งกุลา',
            district: 'สุวรรณภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45130,
        },
        {
            subDistrict: 'ทุ่งศรีเมือง',
            district: 'สุวรรณภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45130,
        },
        {
            subDistrict: 'ทุ่งหลวง',
            district: 'สุวรรณภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45130,
        },
        {
            subDistrict: 'นาใหญ่',
            district: 'สุวรรณภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45130,
        },
        {
            subDistrict: 'น้ำคำ',
            district: 'สุวรรณภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45130,
        },
        {
            subDistrict: 'บ่อพันขัน',
            district: 'สุวรรณภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45130,
        },
        {
            subDistrict: 'สระคู',
            district: 'สุวรรณภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45130,
        },
        {
            subDistrict: 'หัวช้าง',
            district: 'สุวรรณภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45130,
        },
        {
            subDistrict: 'หัวโทน',
            district: 'สุวรรณภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45130,
        },
        {
            subDistrict: 'หินกอง',
            district: 'สุวรรณภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45130,
        },
        {
            subDistrict: 'ห้วยหินลาด',
            district: 'สุวรรณภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45130,
        },
        {
            subDistrict: 'เมืองทุ่ง',
            district: 'สุวรรณภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45130,
        },
        {
            subDistrict: 'กกโพธิ์',
            district: 'หนองพอก',
            province: 'ร้อยเอ็ด',
            zipcode: 45210,
        },
        {
            subDistrict: 'ท่าสีดา',
            district: 'หนองพอก',
            province: 'ร้อยเอ็ด',
            zipcode: 45210,
        },
        {
            subDistrict: 'บึงงาม',
            district: 'หนองพอก',
            province: 'ร้อยเอ็ด',
            zipcode: 45210,
        },
        {
            subDistrict: 'ผาน้ำย้อย',
            district: 'หนองพอก',
            province: 'ร้อยเอ็ด',
            zipcode: 45210,
        },
        {
            subDistrict: 'ภูเขาทอง',
            district: 'หนองพอก',
            province: 'ร้อยเอ็ด',
            zipcode: 45210,
        },
        {
            subDistrict: 'รอบเมือง',
            district: 'หนองพอก',
            province: 'ร้อยเอ็ด',
            zipcode: 45210,
        },
        {
            subDistrict: 'หนองขุ่นใหญ่',
            district: 'หนองพอก',
            province: 'ร้อยเอ็ด',
            zipcode: 45210,
        },
        {
            subDistrict: 'หนองพอก',
            district: 'หนองพอก',
            province: 'ร้อยเอ็ด',
            zipcode: 45210,
        },
        {
            subDistrict: 'โคกสว่าง',
            district: 'หนองพอก',
            province: 'ร้อยเอ็ด',
            zipcode: 45210,
        },
        {
            subDistrict: 'ดูกอึ่ง',
            district: 'หนองฮี',
            province: 'ร้อยเอ็ด',
            zipcode: 45140,
        },
        {
            subDistrict: 'สาวแห',
            district: 'หนองฮี',
            province: 'ร้อยเอ็ด',
            zipcode: 45140,
        },
        {
            subDistrict: 'หนองฮี',
            district: 'หนองฮี',
            province: 'ร้อยเอ็ด',
            zipcode: 45140,
        },
        {
            subDistrict: 'เด่นราษฎร์',
            district: 'หนองฮี',
            province: 'ร้อยเอ็ด',
            zipcode: 45140,
        },
        {
            subDistrict: 'ขี้เหล็ก',
            district: 'อาจสามารถ',
            province: 'ร้อยเอ็ด',
            zipcode: 45160,
        },
        {
            subDistrict: 'บ้านดู่',
            district: 'อาจสามารถ',
            province: 'ร้อยเอ็ด',
            zipcode: 45160,
        },
        {
            subDistrict: 'บ้านแจ้ง',
            district: 'อาจสามารถ',
            province: 'ร้อยเอ็ด',
            zipcode: 45160,
        },
        {
            subDistrict: 'หนองขาม',
            district: 'อาจสามารถ',
            province: 'ร้อยเอ็ด',
            zipcode: 45160,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'อาจสามารถ',
            province: 'ร้อยเอ็ด',
            zipcode: 45160,
        },
        {
            subDistrict: 'หนองหมื่นถ่าน',
            district: 'อาจสามารถ',
            province: 'ร้อยเอ็ด',
            zipcode: 45160,
        },
        {
            subDistrict: 'หน่อม',
            district: 'อาจสามารถ',
            province: 'ร้อยเอ็ด',
            zipcode: 45160,
        },
        {
            subDistrict: 'อาจสามารถ',
            district: 'อาจสามารถ',
            province: 'ร้อยเอ็ด',
            zipcode: 45160,
        },
        {
            subDistrict: 'โพนเมือง',
            district: 'อาจสามารถ',
            province: 'ร้อยเอ็ด',
            zipcode: 45160,
        },
        {
            subDistrict: 'โหรา',
            district: 'อาจสามารถ',
            province: 'ร้อยเอ็ด',
            zipcode: 45160,
        },
        {
            subDistrict: 'กำแพง',
            district: 'เกษตรวิสัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45150,
        },
        {
            subDistrict: 'กู่กาสิงห์',
            district: 'เกษตรวิสัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45150,
        },
        {
            subDistrict: 'ดงครั่งน้อย',
            district: 'เกษตรวิสัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45150,
        },
        {
            subDistrict: 'ดงครั่งใหญ่',
            district: 'เกษตรวิสัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45150,
        },
        {
            subDistrict: 'ทุ่งทอง',
            district: 'เกษตรวิสัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45150,
        },
        {
            subDistrict: 'น้ำอ้อม',
            district: 'เกษตรวิสัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45150,
        },
        {
            subDistrict: 'บ้านฝาง',
            district: 'เกษตรวิสัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45150,
        },
        {
            subDistrict: 'สิงห์โคก',
            district: 'เกษตรวิสัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45150,
        },
        {
            subDistrict: 'หนองแวง',
            district: 'เกษตรวิสัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45150,
        },
        {
            subDistrict: 'เกษตรวิสัย',
            district: 'เกษตรวิสัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45150,
        },
        {
            subDistrict: 'เมืองบัว',
            district: 'เกษตรวิสัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45150,
        },
        {
            subDistrict: 'เหล่าหลวง',
            district: 'เกษตรวิสัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45150,
        },
        {
            subDistrict: 'โนนสว่าง',
            district: 'เกษตรวิสัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45150,
        },
        {
            subDistrict: 'บ้านเขือง',
            district: 'เชียงขวัญ',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'พระธาตุ',
            district: 'เชียงขวัญ',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'พระเจ้า',
            district: 'เชียงขวัญ',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'พลับพลา',
            district: 'เชียงขวัญ',
            province: 'ร้อยเอ็ด',
            zipcode: 45170,
        },
        {
            subDistrict: 'หมูม้น',
            district: 'เชียงขวัญ',
            province: 'ร้อยเอ็ด',
            zipcode: 45170,
        },
        {
            subDistrict: 'เชียงขวัญ',
            district: 'เชียงขวัญ',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'ชมสะอาด',
            district: 'เมยวดี',
            province: 'ร้อยเอ็ด',
            zipcode: 45250,
        },
        {
            subDistrict: 'ชุมพร',
            district: 'เมยวดี',
            province: 'ร้อยเอ็ด',
            zipcode: 45250,
        },
        {
            subDistrict: 'บุ่งเลิศ',
            district: 'เมยวดี',
            province: 'ร้อยเอ็ด',
            zipcode: 45250,
        },
        {
            subDistrict: 'เมยวดี',
            district: 'เมยวดี',
            province: 'ร้อยเอ็ด',
            zipcode: 45250,
        },
        {
            subDistrict: 'ขอนแก่น',
            district: 'เมืองร้อยเอ็ด',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'ดงลาน',
            district: 'เมืองร้อยเอ็ด',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'นาโพธิ์',
            district: 'เมืองร้อยเอ็ด',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'ปอภาร',
            district: 'เมืองร้อยเอ็ด',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'รอบเมือง',
            district: 'เมืองร้อยเอ็ด',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'สะอาดสมบูรณ์',
            district: 'เมืองร้อยเอ็ด',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'สีแก้ว',
            district: 'เมืองร้อยเอ็ด',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'หนองแก้ว',
            district: 'เมืองร้อยเอ็ด',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'หนองแวง',
            district: 'เมืองร้อยเอ็ด',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'เมืองทอง',
            district: 'เมืองร้อยเอ็ด',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'เหนือเมือง',
            district: 'เมืองร้อยเอ็ด',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'แคนใหญ่',
            district: 'เมืองร้อยเอ็ด',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'โนนตาล',
            district: 'เมืองร้อยเอ็ด',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'โนนรัง',
            district: 'เมืองร้อยเอ็ด',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'เมืองร้อยเอ็ด',
            province: 'ร้อยเอ็ด',
            zipcode: 45000,
        },
        {
            subDistrict: 'กกกุง',
            district: 'เมืองสรวง',
            province: 'ร้อยเอ็ด',
            zipcode: 45220,
        },
        {
            subDistrict: 'คูเมือง',
            district: 'เมืองสรวง',
            province: 'ร้อยเอ็ด',
            zipcode: 45220,
        },
        {
            subDistrict: 'หนองผือ',
            district: 'เมืองสรวง',
            province: 'ร้อยเอ็ด',
            zipcode: 45220,
        },
        {
            subDistrict: 'หนองหิน',
            district: 'เมืองสรวง',
            province: 'ร้อยเอ็ด',
            zipcode: 45220,
        },
        {
            subDistrict: 'เมืองสรวง',
            district: 'เมืองสรวง',
            province: 'ร้อยเอ็ด',
            zipcode: 45220,
        },
        {
            subDistrict: 'กลาง',
            district: 'เสลภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45120,
        },
        {
            subDistrict: 'ขวัญเมือง',
            district: 'เสลภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45120,
        },
        {
            subDistrict: 'ขวาว',
            district: 'เสลภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45120,
        },
        {
            subDistrict: 'ท่าม่วง',
            district: 'เสลภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45120,
        },
        {
            subDistrict: 'นางาม',
            district: 'เสลภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45120,
        },
        {
            subDistrict: 'นาเมือง',
            district: 'เสลภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45120,
        },
        {
            subDistrict: 'นาเลิง',
            district: 'เสลภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45120,
        },
        {
            subDistrict: 'นาแซง',
            district: 'เสลภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45120,
        },
        {
            subDistrict: 'บึงเกลือ',
            district: 'เสลภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45120,
        },
        {
            subDistrict: 'พรสวรรค์',
            district: 'เสลภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45120,
        },
        {
            subDistrict: 'ภูเงิน',
            district: 'เสลภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45120,
        },
        {
            subDistrict: 'วังหลวง',
            district: 'เสลภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45120,
        },
        {
            subDistrict: 'ศรีวิลัย',
            district: 'เสลภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45120,
        },
        {
            subDistrict: 'หนองหลวง',
            district: 'เสลภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45120,
        },
        {
            subDistrict: 'เกาะแก้ว',
            district: 'เสลภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45120,
        },
        {
            subDistrict: 'เมืองไพร',
            district: 'เสลภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45120,
        },
        {
            subDistrict: 'เหล่าน้อย',
            district: 'เสลภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45120,
        },
        {
            subDistrict: 'โพธิ์ทอง',
            district: 'เสลภูมิ',
            province: 'ร้อยเอ็ด',
            zipcode: 45120,
        },
        {
            subDistrict: 'ขามเปี้ย',
            district: 'โพธิ์ชัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45230,
        },
        {
            subDistrict: 'คำพอุง',
            district: 'โพธิ์ชัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45230,
        },
        {
            subDistrict: 'ดอนโอง',
            district: 'โพธิ์ชัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45230,
        },
        {
            subDistrict: 'บัวคำ',
            district: 'โพธิ์ชัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45230,
        },
        {
            subDistrict: 'สะอาด',
            district: 'โพธิ์ชัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45230,
        },
        {
            subDistrict: 'หนองตาไก้',
            district: 'โพธิ์ชัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45230,
        },
        {
            subDistrict: 'อัคคะคำ',
            district: 'โพธิ์ชัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45230,
        },
        {
            subDistrict: 'เชียงใหม่',
            district: 'โพธิ์ชัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45230,
        },
        {
            subDistrict: 'โพธิ์ศรี',
            district: 'โพธิ์ชัย',
            province: 'ร้อยเอ็ด',
            zipcode: 45230,
        },
        {
            subDistrict: 'ท่าหาดยาว',
            district: 'โพนทราย',
            province: 'ร้อยเอ็ด',
            zipcode: 45240,
        },
        {
            subDistrict: 'ยางคำ',
            district: 'โพนทราย',
            province: 'ร้อยเอ็ด',
            zipcode: 45240,
        },
        {
            subDistrict: 'ศรีสว่าง',
            district: 'โพนทราย',
            province: 'ร้อยเอ็ด',
            zipcode: 45240,
        },
        {
            subDistrict: 'สามขา',
            district: 'โพนทราย',
            province: 'ร้อยเอ็ด',
            zipcode: 45240,
        },
        {
            subDistrict: 'โพนทราย',
            district: 'โพนทราย',
            province: 'ร้อยเอ็ด',
            zipcode: 45240,
        },
        {
            subDistrict: 'คำนาดี',
            district: 'โพนทอง',
            province: 'ร้อยเอ็ด',
            zipcode: 45110,
        },
        {
            subDistrict: 'นาอุดม',
            district: 'โพนทอง',
            province: 'ร้อยเอ็ด',
            zipcode: 45110,
        },
        {
            subDistrict: 'พรมสวรรค์',
            district: 'โพนทอง',
            province: 'ร้อยเอ็ด',
            zipcode: 45110,
        },
        {
            subDistrict: 'วังสามัคคี',
            district: 'โพนทอง',
            province: 'ร้อยเอ็ด',
            zipcode: 45110,
        },
        {
            subDistrict: 'สระนกแก้ว',
            district: 'โพนทอง',
            province: 'ร้อยเอ็ด',
            zipcode: 45110,
        },
        {
            subDistrict: 'สว่าง',
            district: 'โพนทอง',
            province: 'ร้อยเอ็ด',
            zipcode: 45110,
        },
        {
            subDistrict: 'หนองใหญ่',
            district: 'โพนทอง',
            province: 'ร้อยเอ็ด',
            zipcode: 45110,
        },
        {
            subDistrict: 'อุ่มเม่า',
            district: 'โพนทอง',
            province: 'ร้อยเอ็ด',
            zipcode: 45110,
        },
        {
            subDistrict: 'แวง',
            district: 'โพนทอง',
            province: 'ร้อยเอ็ด',
            zipcode: 45110,
        },
        {
            subDistrict: 'โคกกกม่วง',
            district: 'โพนทอง',
            province: 'ร้อยเอ็ด',
            zipcode: 45110,
        },
        {
            subDistrict: 'โคกสูง',
            district: 'โพนทอง',
            province: 'ร้อยเอ็ด',
            zipcode: 45110,
        },
        {
            subDistrict: 'โนนชัยศรี',
            district: 'โพนทอง',
            province: 'ร้อยเอ็ด',
            zipcode: 45110,
        },
        {
            subDistrict: 'โพธิ์ทอง',
            district: 'โพนทอง',
            province: 'ร้อยเอ็ด',
            zipcode: 45110,
        },
        {
            subDistrict: 'โพธิ์ศรีสว่าง',
            district: 'โพนทอง',
            province: 'ร้อยเอ็ด',
            zipcode: 45110,
        },
        {
            subDistrict: 'ชัยนารายณ์',
            district: 'ชัยบาดาล',
            province: 'ลพบุรี',
            zipcode: 15130,
        },
        {
            subDistrict: 'ชัยบาดาล',
            district: 'ชัยบาดาล',
            province: 'ลพบุรี',
            zipcode: 15230,
        },
        {
            subDistrict: 'ซับตะเคียน',
            district: 'ชัยบาดาล',
            province: 'ลพบุรี',
            zipcode: 15130,
        },
        {
            subDistrict: 'ท่าดินดำ',
            district: 'ชัยบาดาล',
            province: 'ลพบุรี',
            zipcode: 15130,
        },
        {
            subDistrict: 'ท่ามะนาว',
            district: 'ชัยบาดาล',
            province: 'ลพบุรี',
            zipcode: 15130,
        },
        {
            subDistrict: 'นาโสม',
            district: 'ชัยบาดาล',
            province: 'ลพบุรี',
            zipcode: 15190,
        },
        {
            subDistrict: 'นิคมลำนารายณ์',
            district: 'ชัยบาดาล',
            province: 'ลพบุรี',
            zipcode: 15130,
        },
        {
            subDistrict: 'บัวชุม',
            district: 'ชัยบาดาล',
            province: 'ลพบุรี',
            zipcode: 15130,
        },
        {
            subDistrict: 'บ้านใหม่สามัคคี',
            district: 'ชัยบาดาล',
            province: 'ลพบุรี',
            zipcode: 15130,
        },
        {
            subDistrict: 'มะกอกหวาน',
            district: 'ชัยบาดาล',
            province: 'ลพบุรี',
            zipcode: 15230,
        },
        {
            subDistrict: 'ม่วงค่อม',
            district: 'ชัยบาดาล',
            province: 'ลพบุรี',
            zipcode: 15230,
        },
        {
            subDistrict: 'ลำนารายณ์',
            district: 'ชัยบาดาล',
            province: 'ลพบุรี',
            zipcode: 15130,
        },
        {
            subDistrict: 'ศิลาทิพย์',
            district: 'ชัยบาดาล',
            province: 'ลพบุรี',
            zipcode: 15130,
        },
        {
            subDistrict: 'หนองยายโต๊ะ',
            district: 'ชัยบาดาล',
            province: 'ลพบุรี',
            zipcode: 15130,
        },
        {
            subDistrict: 'ห้วยหิน',
            district: 'ชัยบาดาล',
            province: 'ลพบุรี',
            zipcode: 15130,
        },
        {
            subDistrict: 'เกาะรัง',
            district: 'ชัยบาดาล',
            province: 'ลพบุรี',
            zipcode: 15130,
        },
        {
            subDistrict: 'เขาแหลม',
            district: 'ชัยบาดาล',
            province: 'ลพบุรี',
            zipcode: 15130,
        },
        {
            subDistrict: 'ท่าวุ้ง',
            district: 'ท่าวุ้ง',
            province: 'ลพบุรี',
            zipcode: 15150,
        },
        {
            subDistrict: 'บางคู้',
            district: 'ท่าวุ้ง',
            province: 'ลพบุรี',
            zipcode: 15150,
        },
        {
            subDistrict: 'บางงา',
            district: 'ท่าวุ้ง',
            province: 'ลพบุรี',
            zipcode: 15150,
        },
        {
            subDistrict: 'บางลี่',
            district: 'ท่าวุ้ง',
            province: 'ลพบุรี',
            zipcode: 15150,
        },
        {
            subDistrict: 'บ้านเบิก',
            district: 'ท่าวุ้ง',
            province: 'ลพบุรี',
            zipcode: 15150,
        },
        {
            subDistrict: 'มุจลินท์',
            district: 'ท่าวุ้ง',
            province: 'ลพบุรี',
            zipcode: 15150,
        },
        {
            subDistrict: 'ลาดสาลี่',
            district: 'ท่าวุ้ง',
            province: 'ลพบุรี',
            zipcode: 15150,
        },
        {
            subDistrict: 'หัวสำโรง',
            district: 'ท่าวุ้ง',
            province: 'ลพบุรี',
            zipcode: 15150,
        },
        {
            subDistrict: 'เขาสมอคอน',
            district: 'ท่าวุ้ง',
            province: 'ลพบุรี',
            zipcode: 15180,
        },
        {
            subDistrict: 'โคกสลุด',
            district: 'ท่าวุ้ง',
            province: 'ลพบุรี',
            zipcode: 15150,
        },
        {
            subDistrict: 'โพตลาดแก้ว',
            district: 'ท่าวุ้ง',
            province: 'ลพบุรี',
            zipcode: 15150,
        },
        {
            subDistrict: 'ซับจำปา',
            district: 'ท่าหลวง',
            province: 'ลพบุรี',
            zipcode: 15230,
        },
        {
            subDistrict: 'ทะเลวังวัด',
            district: 'ท่าหลวง',
            province: 'ลพบุรี',
            zipcode: 15230,
        },
        {
            subDistrict: 'ท่าหลวง',
            district: 'ท่าหลวง',
            province: 'ลพบุรี',
            zipcode: 15230,
        },
        {
            subDistrict: 'หนองผักแว่น',
            district: 'ท่าหลวง',
            province: 'ลพบุรี',
            zipcode: 15230,
        },
        {
            subDistrict: 'หัวลำ',
            district: 'ท่าหลวง',
            province: 'ลพบุรี',
            zipcode: 15230,
        },
        {
            subDistrict: 'แก่งผักกูด',
            district: 'ท่าหลวง',
            province: 'ลพบุรี',
            zipcode: 15230,
        },
        {
            subDistrict: 'ชอนม่วง',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15110,
        },
        {
            subDistrict: 'ดงพลับ',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15110,
        },
        {
            subDistrict: 'ดอนดึง',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15110,
        },
        {
            subDistrict: 'บางกะพี้',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15110,
        },
        {
            subDistrict: 'บางขาม',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15180,
        },
        {
            subDistrict: 'บางพึ่ง',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15110,
        },
        {
            subDistrict: 'บ้านกล้วย',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15110,
        },
        {
            subDistrict: 'บ้านชี',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15180,
        },
        {
            subDistrict: 'บ้านทราย',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15110,
        },
        {
            subDistrict: 'บ้านหมี่',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15110,
        },
        {
            subDistrict: 'พุคา',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15110,
        },
        {
            subDistrict: 'มหาสอน',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15110,
        },
        {
            subDistrict: 'สนามแจง',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15110,
        },
        {
            subDistrict: 'สายห้วยแก้ว',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15110,
        },
        {
            subDistrict: 'หนองกระเบียน',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15110,
        },
        {
            subDistrict: 'หนองทรายขาว',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15110,
        },
        {
            subDistrict: 'หนองเต่า',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15110,
        },
        {
            subDistrict: 'หนองเมือง',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15110,
        },
        {
            subDistrict: 'หินปัก',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15110,
        },
        {
            subDistrict: 'เชียงงา',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15110,
        },
        {
            subDistrict: 'โพนทอง',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15110,
        },
        {
            subDistrict: 'ไผ่ใหญ่',
            district: 'บ้านหมี่',
            province: 'ลพบุรี',
            zipcode: 15110,
        },
        {
            subDistrict: 'ชอนน้อย',
            district: 'พัฒนานิคม',
            province: 'ลพบุรี',
            zipcode: 15140,
        },
        {
            subDistrict: 'ช่องสาริกา',
            district: 'พัฒนานิคม',
            province: 'ลพบุรี',
            zipcode: 15220,
        },
        {
            subDistrict: 'ดีลัง',
            district: 'พัฒนานิคม',
            province: 'ลพบุรี',
            zipcode: 15220,
        },
        {
            subDistrict: 'น้ำสุด',
            district: 'พัฒนานิคม',
            province: 'ลพบุรี',
            zipcode: 15140,
        },
        {
            subDistrict: 'พัฒนานิคม',
            district: 'พัฒนานิคม',
            province: 'ลพบุรี',
            zipcode: 15140,
        },
        {
            subDistrict: 'มะนาวหวาน',
            district: 'พัฒนานิคม',
            province: 'ลพบุรี',
            zipcode: 15140,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'พัฒนานิคม',
            province: 'ลพบุรี',
            zipcode: 15140,
        },
        {
            subDistrict: 'ห้วยขุนราม',
            district: 'พัฒนานิคม',
            province: 'ลพบุรี',
            zipcode: 18220,
        },
        {
            subDistrict: 'โคกสลุง',
            district: 'พัฒนานิคม',
            province: 'ลพบุรี',
            zipcode: 15140,
        },
        {
            subDistrict: 'กุดตาเพชร',
            district: 'ลำสนธิ',
            province: 'ลพบุรี',
            zipcode: 15190,
        },
        {
            subDistrict: 'ซับสมบูรณ์',
            district: 'ลำสนธิ',
            province: 'ลพบุรี',
            zipcode: 15190,
        },
        {
            subDistrict: 'ลำสนธิ',
            district: 'ลำสนธิ',
            province: 'ลพบุรี',
            zipcode: 15190,
        },
        {
            subDistrict: 'หนองรี',
            district: 'ลำสนธิ',
            province: 'ลพบุรี',
            zipcode: 15190,
        },
        {
            subDistrict: 'เขาน้อย',
            district: 'ลำสนธิ',
            province: 'ลพบุรี',
            zipcode: 15130,
        },
        {
            subDistrict: 'เขารวก',
            district: 'ลำสนธิ',
            province: 'ลพบุรี',
            zipcode: 15190,
        },
        {
            subDistrict: 'ทุ่งท่าช้าง',
            district: 'สระโบสถ์',
            province: 'ลพบุรี',
            zipcode: 15240,
        },
        {
            subDistrict: 'นิยมชัย',
            district: 'สระโบสถ์',
            province: 'ลพบุรี',
            zipcode: 15240,
        },
        {
            subDistrict: 'มหาโพธิ',
            district: 'สระโบสถ์',
            province: 'ลพบุรี',
            zipcode: 15240,
        },
        {
            subDistrict: 'สระโบสถ์',
            district: 'สระโบสถ์',
            province: 'ลพบุรี',
            zipcode: 15240,
        },
        {
            subDistrict: 'ห้วยใหญ่',
            district: 'สระโบสถ์',
            province: 'ลพบุรี',
            zipcode: 15240,
        },
        {
            subDistrict: 'ชอนสมบูรณ์',
            district: 'หนองม่วง',
            province: 'ลพบุรี',
            zipcode: 15170,
        },
        {
            subDistrict: 'ชอนสารเดช',
            district: 'หนองม่วง',
            province: 'ลพบุรี',
            zipcode: 15170,
        },
        {
            subDistrict: 'ดงดินแดง',
            district: 'หนองม่วง',
            province: 'ลพบุรี',
            zipcode: 15170,
        },
        {
            subDistrict: 'บ่อทอง',
            district: 'หนองม่วง',
            province: 'ลพบุรี',
            zipcode: 15170,
        },
        {
            subDistrict: 'ยางโทน',
            district: 'หนองม่วง',
            province: 'ลพบุรี',
            zipcode: 15170,
        },
        {
            subDistrict: 'หนองม่วง',
            district: 'หนองม่วง',
            province: 'ลพบุรี',
            zipcode: 15170,
        },
        {
            subDistrict: 'กกโก',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15000,
        },
        {
            subDistrict: 'งิ้วราย',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15000,
        },
        {
            subDistrict: 'ดอนโพธิ์',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15000,
        },
        {
            subDistrict: 'ตะลุง',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15000,
        },
        {
            subDistrict: 'ถนนใหญ่',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15000,
        },
        {
            subDistrict: 'ทะเลชุบศร',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15000,
        },
        {
            subDistrict: 'ท่าศาลา',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15000,
        },
        {
            subDistrict: 'ท่าหิน',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15000,
        },
        {
            subDistrict: 'ท่าแค',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15000,
        },
        {
            subDistrict: 'ท้ายตลาด',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15000,
        },
        {
            subDistrict: 'นิคมสร้างตนเอง',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15000,
        },
        {
            subDistrict: 'บางขันหมาก',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15000,
        },
        {
            subDistrict: 'บ้านข่อย',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15000,
        },
        {
            subDistrict: 'ป่าตาล',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15000,
        },
        {
            subDistrict: 'พรหมมาสตร์',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15000,
        },
        {
            subDistrict: 'สี่คลอง',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15000,
        },
        {
            subDistrict: 'เขาพระงาม',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15160,
        },
        {
            subDistrict: 'เขาสามยอด',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15000,
        },
        {
            subDistrict: 'โก่งธนู',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 13240,
        },
        {
            subDistrict: 'โคกกะเทียม',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15160,
        },
        {
            subDistrict: 'โคกตูม',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15210,
        },
        {
            subDistrict: 'โคกลำพาน',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15000,
        },
        {
            subDistrict: 'โพธิ์ตรุ',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15000,
        },
        {
            subDistrict: 'โพธิ์เก้าต้น',
            district: 'เมืองลพบุรี',
            province: 'ลพบุรี',
            zipcode: 15000,
        },
        {
            subDistrict: 'คลองเกตุ',
            district: 'โคกสำโรง',
            province: 'ลพบุรี',
            zipcode: 15120,
        },
        {
            subDistrict: 'ดงมะรุม',
            district: 'โคกสำโรง',
            province: 'ลพบุรี',
            zipcode: 15120,
        },
        {
            subDistrict: 'ถลุงเหล็ก',
            district: 'โคกสำโรง',
            province: 'ลพบุรี',
            zipcode: 15120,
        },
        {
            subDistrict: 'วังขอนขว้าง',
            district: 'โคกสำโรง',
            province: 'ลพบุรี',
            zipcode: 15120,
        },
        {
            subDistrict: 'วังจั่น',
            district: 'โคกสำโรง',
            province: 'ลพบุรี',
            zipcode: 15120,
        },
        {
            subDistrict: 'วังเพลิง',
            district: 'โคกสำโรง',
            province: 'ลพบุรี',
            zipcode: 15120,
        },
        {
            subDistrict: 'สะแกราบ',
            district: 'โคกสำโรง',
            province: 'ลพบุรี',
            zipcode: 15120,
        },
        {
            subDistrict: 'หนองแขม',
            district: 'โคกสำโรง',
            province: 'ลพบุรี',
            zipcode: 15120,
        },
        {
            subDistrict: 'หลุมข้าว',
            district: 'โคกสำโรง',
            province: 'ลพบุรี',
            zipcode: 15120,
        },
        {
            subDistrict: 'ห้วยโป่ง',
            district: 'โคกสำโรง',
            province: 'ลพบุรี',
            zipcode: 15120,
        },
        {
            subDistrict: 'เกาะแก้ว',
            district: 'โคกสำโรง',
            province: 'ลพบุรี',
            zipcode: 15120,
        },
        {
            subDistrict: 'เพนียด',
            district: 'โคกสำโรง',
            province: 'ลพบุรี',
            zipcode: 15120,
        },
        {
            subDistrict: 'โคกสำโรง',
            district: 'โคกสำโรง',
            province: 'ลพบุรี',
            zipcode: 15120,
        },
        {
            subDistrict: 'ยางราก',
            district: 'โคกเจริญ',
            province: 'ลพบุรี',
            zipcode: 15250,
        },
        {
            subDistrict: 'วังทอง',
            district: 'โคกเจริญ',
            province: 'ลพบุรี',
            zipcode: 15250,
        },
        {
            subDistrict: 'หนองมะค่า',
            district: 'โคกเจริญ',
            province: 'ลพบุรี',
            zipcode: 15250,
        },
        {
            subDistrict: 'โคกเจริญ',
            district: 'โคกเจริญ',
            province: 'ลพบุรี',
            zipcode: 15250,
        },
        {
            subDistrict: 'โคกแสมสาร',
            district: 'โคกเจริญ',
            province: 'ลพบุรี',
            zipcode: 15250,
        },
        {
            subDistrict: 'นาแก',
            district: 'งาว',
            province: 'ลำปาง',
            zipcode: 52110,
        },
        {
            subDistrict: 'บ้านร้อง',
            district: 'งาว',
            province: 'ลำปาง',
            zipcode: 52110,
        },
        {
            subDistrict: 'บ้านหวด',
            district: 'งาว',
            province: 'ลำปาง',
            zipcode: 52110,
        },
        {
            subDistrict: 'บ้านอ้อน',
            district: 'งาว',
            province: 'ลำปาง',
            zipcode: 52110,
        },
        {
            subDistrict: 'บ้านแหง',
            district: 'งาว',
            province: 'ลำปาง',
            zipcode: 52110,
        },
        {
            subDistrict: 'บ้านโป่ง',
            district: 'งาว',
            province: 'ลำปาง',
            zipcode: 52110,
        },
        {
            subDistrict: 'ปงเตา',
            district: 'งาว',
            province: 'ลำปาง',
            zipcode: 52110,
        },
        {
            subDistrict: 'หลวงเหนือ',
            district: 'งาว',
            province: 'ลำปาง',
            zipcode: 52110,
        },
        {
            subDistrict: 'หลวงใต้',
            district: 'งาว',
            province: 'ลำปาง',
            zipcode: 52110,
        },
        {
            subDistrict: 'แม่ตีบ',
            district: 'งาว',
            province: 'ลำปาง',
            zipcode: 52110,
        },
        {
            subDistrict: 'ทุ่งฮั้ว',
            district: 'วังเหนือ',
            province: 'ลำปาง',
            zipcode: 52140,
        },
        {
            subDistrict: 'ร่องเคาะ',
            district: 'วังเหนือ',
            province: 'ลำปาง',
            zipcode: 52140,
        },
        {
            subDistrict: 'วังซ้าย',
            district: 'วังเหนือ',
            province: 'ลำปาง',
            zipcode: 52140,
        },
        {
            subDistrict: 'วังทรายคำ',
            district: 'วังเหนือ',
            province: 'ลำปาง',
            zipcode: 52140,
        },
        {
            subDistrict: 'วังทอง',
            district: 'วังเหนือ',
            province: 'ลำปาง',
            zipcode: 52140,
        },
        {
            subDistrict: 'วังเหนือ',
            district: 'วังเหนือ',
            province: 'ลำปาง',
            zipcode: 52140,
        },
        {
            subDistrict: 'วังแก้ว',
            district: 'วังเหนือ',
            province: 'ลำปาง',
            zipcode: 52140,
        },
        {
            subDistrict: 'วังใต้',
            district: 'วังเหนือ',
            province: 'ลำปาง',
            zipcode: 52140,
        },
        {
            subDistrict: 'นายาง',
            district: 'สบปราบ',
            province: 'ลำปาง',
            zipcode: 52170,
        },
        {
            subDistrict: 'สบปราบ',
            district: 'สบปราบ',
            province: 'ลำปาง',
            zipcode: 52170,
        },
        {
            subDistrict: 'สมัย',
            district: 'สบปราบ',
            province: 'ลำปาง',
            zipcode: 52170,
        },
        {
            subDistrict: 'แม่กัวะ',
            district: 'สบปราบ',
            province: 'ลำปาง',
            zipcode: 52170,
        },
        {
            subDistrict: 'ปงยางคก',
            district: 'ห้างฉัตร',
            province: 'ลำปาง',
            zipcode: 52190,
        },
        {
            subDistrict: 'วอแก้ว',
            district: 'ห้างฉัตร',
            province: 'ลำปาง',
            zipcode: 52190,
        },
        {
            subDistrict: 'หนองหล่ม',
            district: 'ห้างฉัตร',
            province: 'ลำปาง',
            zipcode: 52190,
        },
        {
            subDistrict: 'ห้างฉัตร',
            district: 'ห้างฉัตร',
            province: 'ลำปาง',
            zipcode: 52190,
        },
        {
            subDistrict: 'เมืองยาว',
            district: 'ห้างฉัตร',
            province: 'ลำปาง',
            zipcode: 52190,
        },
        {
            subDistrict: 'เวียงตาล',
            district: 'ห้างฉัตร',
            province: 'ลำปาง',
            zipcode: 52190,
        },
        {
            subDistrict: 'แม่สัน',
            district: 'ห้างฉัตร',
            province: 'ลำปาง',
            zipcode: 52190,
        },
        {
            subDistrict: 'ท่าผา',
            district: 'เกาะคา',
            province: 'ลำปาง',
            zipcode: 52130,
        },
        {
            subDistrict: 'นาแก้ว',
            district: 'เกาะคา',
            province: 'ลำปาง',
            zipcode: 52130,
        },
        {
            subDistrict: 'นาแส่ง',
            district: 'เกาะคา',
            province: 'ลำปาง',
            zipcode: 52130,
        },
        {
            subDistrict: 'ลำปางหลวง',
            district: 'เกาะคา',
            province: 'ลำปาง',
            zipcode: 52130,
        },
        {
            subDistrict: 'วังพร้าว',
            district: 'เกาะคา',
            province: 'ลำปาง',
            zipcode: 52130,
        },
        {
            subDistrict: 'ศาลา',
            district: 'เกาะคา',
            province: 'ลำปาง',
            zipcode: 52130,
        },
        {
            subDistrict: 'เกาะคา',
            district: 'เกาะคา',
            province: 'ลำปาง',
            zipcode: 52130,
        },
        {
            subDistrict: 'ใหม่พัฒนา',
            district: 'เกาะคา',
            province: 'ลำปาง',
            zipcode: 52130,
        },
        {
            subDistrict: 'ไหล่หิน',
            district: 'เกาะคา',
            province: 'ลำปาง',
            zipcode: 52130,
        },
        {
            subDistrict: 'นาโป่ง',
            district: 'เถิน',
            province: 'ลำปาง',
            zipcode: 52160,
        },
        {
            subDistrict: 'ล้อมแรด',
            district: 'เถิน',
            province: 'ลำปาง',
            zipcode: 52160,
        },
        {
            subDistrict: 'เถินบุรี',
            district: 'เถิน',
            province: 'ลำปาง',
            zipcode: 52160,
        },
        {
            subDistrict: 'เวียงมอก',
            district: 'เถิน',
            province: 'ลำปาง',
            zipcode: 52160,
        },
        {
            subDistrict: 'แม่ถอด',
            district: 'เถิน',
            province: 'ลำปาง',
            zipcode: 52160,
        },
        {
            subDistrict: 'แม่ปะ',
            district: 'เถิน',
            province: 'ลำปาง',
            zipcode: 52160,
        },
        {
            subDistrict: 'แม่มอก',
            district: 'เถิน',
            province: 'ลำปาง',
            zipcode: 52160,
        },
        {
            subDistrict: 'แม่วะ',
            district: 'เถิน',
            province: 'ลำปาง',
            zipcode: 52230,
        },
        {
            subDistrict: 'ทุ่งกว๋าว',
            district: 'เมืองปาน',
            province: 'ลำปาง',
            zipcode: 52240,
        },
        {
            subDistrict: 'บ้านขอ',
            district: 'เมืองปาน',
            province: 'ลำปาง',
            zipcode: 52240,
        },
        {
            subDistrict: 'หัวเมือง',
            district: 'เมืองปาน',
            province: 'ลำปาง',
            zipcode: 52240,
        },
        {
            subDistrict: 'เมืองปาน',
            district: 'เมืองปาน',
            province: 'ลำปาง',
            zipcode: 52240,
        },
        {
            subDistrict: 'แจ้ซ้อน',
            district: 'เมืองปาน',
            province: 'ลำปาง',
            zipcode: 52240,
        },
        {
            subDistrict: 'กล้วยแพะ',
            district: 'เมืองลำปาง',
            province: 'ลำปาง',
            zipcode: 52000,
        },
        {
            subDistrict: 'ชมพู',
            district: 'เมืองลำปาง',
            province: 'ลำปาง',
            zipcode: 52100,
        },
        {
            subDistrict: 'ต้นธงชัย',
            district: 'เมืองลำปาง',
            province: 'ลำปาง',
            zipcode: 52000,
        },
        {
            subDistrict: 'ทุ่งฝาย',
            district: 'เมืองลำปาง',
            province: 'ลำปาง',
            zipcode: 52000,
        },
        {
            subDistrict: 'นิคมพัฒนา',
            district: 'เมืองลำปาง',
            province: 'ลำปาง',
            zipcode: 52000,
        },
        {
            subDistrict: 'บุญนาคพัฒนา',
            district: 'เมืองลำปาง',
            province: 'ลำปาง',
            zipcode: 52000,
        },
        {
            subDistrict: 'บ่อแฮ้ว',
            district: 'เมืองลำปาง',
            province: 'ลำปาง',
            zipcode: 52100,
        },
        {
            subDistrict: 'บ้านค่า',
            district: 'เมืองลำปาง',
            province: 'ลำปาง',
            zipcode: 52100,
        },
        {
            subDistrict: 'บ้านเป้า',
            district: 'เมืองลำปาง',
            province: 'ลำปาง',
            zipcode: 52100,
        },
        {
            subDistrict: 'บ้านเสด็จ',
            district: 'เมืองลำปาง',
            province: 'ลำปาง',
            zipcode: 52000,
        },
        {
            subDistrict: 'บ้านเอื้อม',
            district: 'เมืองลำปาง',
            province: 'ลำปาง',
            zipcode: 52100,
        },
        {
            subDistrict: 'บ้านแลง',
            district: 'เมืองลำปาง',
            province: 'ลำปาง',
            zipcode: 52000,
        },
        {
            subDistrict: 'ปงแสนทอง',
            district: 'เมืองลำปาง',
            province: 'ลำปาง',
            zipcode: 52100,
        },
        {
            subDistrict: 'พระบาท',
            district: 'เมืองลำปาง',
            province: 'ลำปาง',
            zipcode: 52000,
        },
        {
            subDistrict: 'พิชัย',
            district: 'เมืองลำปาง',
            province: 'ลำปาง',
            zipcode: 52000,
        },
        {
            subDistrict: 'สบตุ๋ย',
            district: 'เมืองลำปาง',
            province: 'ลำปาง',
            zipcode: 52100,
        },
        {
            subDistrict: 'สวนดอก',
            district: 'เมืองลำปาง',
            province: 'ลำปาง',
            zipcode: 52100,
        },
        {
            subDistrict: 'หัวเวียง',
            district: 'เมืองลำปาง',
            province: 'ลำปาง',
            zipcode: 52000,
        },
        {
            subDistrict: 'เวียงเหนือ',
            district: 'เมืองลำปาง',
            province: 'ลำปาง',
            zipcode: 52000,
        },
        {
            subDistrict: 'ทุ่งงาม',
            district: 'เสริมงาม',
            province: 'ลำปาง',
            zipcode: 52210,
        },
        {
            subDistrict: 'เสริมกลาง',
            district: 'เสริมงาม',
            province: 'ลำปาง',
            zipcode: 52210,
        },
        {
            subDistrict: 'เสริมขวา',
            district: 'เสริมงาม',
            province: 'ลำปาง',
            zipcode: 52210,
        },
        {
            subDistrict: 'เสริมซ้าย',
            district: 'เสริมงาม',
            province: 'ลำปาง',
            zipcode: 52210,
        },
        {
            subDistrict: 'ทุ่งผึ้ง',
            district: 'แจ้ห่ม',
            province: 'ลำปาง',
            zipcode: 52120,
        },
        {
            subDistrict: 'บ้านสา',
            district: 'แจ้ห่ม',
            province: 'ลำปาง',
            zipcode: 52120,
        },
        {
            subDistrict: 'ปงดอน',
            district: 'แจ้ห่ม',
            province: 'ลำปาง',
            zipcode: 52120,
        },
        {
            subDistrict: 'วิเชตนคร',
            district: 'แจ้ห่ม',
            province: 'ลำปาง',
            zipcode: 52120,
        },
        {
            subDistrict: 'เมืองมาย',
            district: 'แจ้ห่ม',
            province: 'ลำปาง',
            zipcode: 52120,
        },
        {
            subDistrict: 'แจ้ห่ม',
            district: 'แจ้ห่ม',
            province: 'ลำปาง',
            zipcode: 52120,
        },
        {
            subDistrict: 'แม่สุก',
            district: 'แจ้ห่ม',
            province: 'ลำปาง',
            zipcode: 52120,
        },
        {
            subDistrict: 'ดอนไฟ',
            district: 'แม่ทะ',
            province: 'ลำปาง',
            zipcode: 52150,
        },
        {
            subDistrict: 'นาครัว',
            district: 'แม่ทะ',
            province: 'ลำปาง',
            zipcode: 52150,
        },
        {
            subDistrict: 'น้ำโจ้',
            district: 'แม่ทะ',
            province: 'ลำปาง',
            zipcode: 52150,
        },
        {
            subDistrict: 'บ้านกิ่ว',
            district: 'แม่ทะ',
            province: 'ลำปาง',
            zipcode: 52150,
        },
        {
            subDistrict: 'บ้านบอม',
            district: 'แม่ทะ',
            province: 'ลำปาง',
            zipcode: 52150,
        },
        {
            subDistrict: 'ป่าตัน',
            district: 'แม่ทะ',
            province: 'ลำปาง',
            zipcode: 52150,
        },
        {
            subDistrict: 'วังเงิน',
            district: 'แม่ทะ',
            province: 'ลำปาง',
            zipcode: 52150,
        },
        {
            subDistrict: 'สันดอนแก้ว',
            district: 'แม่ทะ',
            province: 'ลำปาง',
            zipcode: 52150,
        },
        {
            subDistrict: 'หัวเสือ',
            district: 'แม่ทะ',
            province: 'ลำปาง',
            zipcode: 52150,
        },
        {
            subDistrict: 'แม่ทะ',
            district: 'แม่ทะ',
            province: 'ลำปาง',
            zipcode: 52150,
        },
        {
            subDistrict: 'ผาปัง',
            district: 'แม่พริก',
            province: 'ลำปาง',
            zipcode: 52180,
        },
        {
            subDistrict: 'พระบาทวังตวง',
            district: 'แม่พริก',
            province: 'ลำปาง',
            zipcode: 52230,
        },
        {
            subDistrict: 'แม่ปุ',
            district: 'แม่พริก',
            province: 'ลำปาง',
            zipcode: 52180,
        },
        {
            subDistrict: 'แม่พริก',
            district: 'แม่พริก',
            province: 'ลำปาง',
            zipcode: 52180,
        },
        {
            subDistrict: 'จางเหนือ',
            district: 'แม่เมาะ',
            province: 'ลำปาง',
            zipcode: 52220,
        },
        {
            subDistrict: 'นาสัก',
            district: 'แม่เมาะ',
            province: 'ลำปาง',
            zipcode: 52220,
        },
        {
            subDistrict: 'บ้านดง',
            district: 'แม่เมาะ',
            province: 'ลำปาง',
            zipcode: 52220,
        },
        {
            subDistrict: 'สบป้าด',
            district: 'แม่เมาะ',
            province: 'ลำปาง',
            zipcode: 52220,
        },
        {
            subDistrict: 'แม่เมาะ',
            district: 'แม่เมาะ',
            province: 'ลำปาง',
            zipcode: 52220,
        },
        {
            subDistrict: 'ตะเคียนปม',
            district: 'ทุ่งหัวช้าง',
            province: 'ลำพูน',
            zipcode: 51160,
        },
        {
            subDistrict: 'ทุ่งหัวช้าง',
            district: 'ทุ่งหัวช้าง',
            province: 'ลำพูน',
            zipcode: 51160,
        },
        {
            subDistrict: 'บ้านปวง',
            district: 'ทุ่งหัวช้าง',
            province: 'ลำพูน',
            zipcode: 51160,
        },
        {
            subDistrict: 'บ้านธิ',
            district: 'บ้านธิ',
            province: 'ลำพูน',
            zipcode: 51180,
        },
        {
            subDistrict: 'ห้วยยาบ',
            district: 'บ้านธิ',
            province: 'ลำพูน',
            zipcode: 51180,
        },
        {
            subDistrict: 'บ้านโฮ่ง',
            district: 'บ้านโฮ่ง',
            province: 'ลำพูน',
            zipcode: 51130,
        },
        {
            subDistrict: 'ป่าพลู',
            district: 'บ้านโฮ่ง',
            province: 'ลำพูน',
            zipcode: 51130,
        },
        {
            subDistrict: 'ศรีเตี้ย',
            district: 'บ้านโฮ่ง',
            province: 'ลำพูน',
            zipcode: 51130,
        },
        {
            subDistrict: 'หนองปลาสะวาย',
            district: 'บ้านโฮ่ง',
            province: 'ลำพูน',
            zipcode: 51130,
        },
        {
            subDistrict: 'เหล่ายาว',
            district: 'บ้านโฮ่ง',
            province: 'ลำพูน',
            zipcode: 51130,
        },
        {
            subDistrict: 'ท่าตุ้ม',
            district: 'ป่าซาง',
            province: 'ลำพูน',
            zipcode: 51120,
        },
        {
            subDistrict: 'นครเจดีย์',
            district: 'ป่าซาง',
            province: 'ลำพูน',
            zipcode: 51120,
        },
        {
            subDistrict: 'น้ำดิบ',
            district: 'ป่าซาง',
            province: 'ลำพูน',
            zipcode: 51120,
        },
        {
            subDistrict: 'บ้านเรือน',
            district: 'ป่าซาง',
            province: 'ลำพูน',
            zipcode: 51120,
        },
        {
            subDistrict: 'ปากบ่อง',
            district: 'ป่าซาง',
            province: 'ลำพูน',
            zipcode: 51120,
        },
        {
            subDistrict: 'ป่าซาง',
            district: 'ป่าซาง',
            province: 'ลำพูน',
            zipcode: 51120,
        },
        {
            subDistrict: 'มะกอก',
            district: 'ป่าซาง',
            province: 'ลำพูน',
            zipcode: 51120,
        },
        {
            subDistrict: 'ม่วงน้อย',
            district: 'ป่าซาง',
            province: 'ลำพูน',
            zipcode: 51120,
        },
        {
            subDistrict: 'แม่แรง',
            district: 'ป่าซาง',
            province: 'ลำพูน',
            zipcode: 51120,
        },
        {
            subDistrict: 'ก้อ',
            district: 'ลี้',
            province: 'ลำพูน',
            zipcode: 51110,
        },
        {
            subDistrict: 'ดงดำ',
            district: 'ลี้',
            province: 'ลำพูน',
            zipcode: 51110,
        },
        {
            subDistrict: 'นาทราย',
            district: 'ลี้',
            province: 'ลำพูน',
            zipcode: 51110,
        },
        {
            subDistrict: 'ป่าไผ่',
            district: 'ลี้',
            province: 'ลำพูน',
            zipcode: 51110,
        },
        {
            subDistrict: 'ลี้',
            district: 'ลี้',
            province: 'ลำพูน',
            zipcode: 51110,
        },
        {
            subDistrict: 'ศรีวิชัย',
            district: 'ลี้',
            province: 'ลำพูน',
            zipcode: 51110,
        },
        {
            subDistrict: 'แม่ตืน',
            district: 'ลี้',
            province: 'ลำพูน',
            zipcode: 51110,
        },
        {
            subDistrict: 'แม่ลาน',
            district: 'ลี้',
            province: 'ลำพูน',
            zipcode: 51110,
        },
        {
            subDistrict: 'ต้นธง',
            district: 'เมืองลำพูน',
            province: 'ลำพูน',
            zipcode: 51000,
        },
        {
            subDistrict: 'บ้านกลาง',
            district: 'เมืองลำพูน',
            province: 'ลำพูน',
            zipcode: 51000,
        },
        {
            subDistrict: 'บ้านแป้น',
            district: 'เมืองลำพูน',
            province: 'ลำพูน',
            zipcode: 51000,
        },
        {
            subDistrict: 'ประตูป่า',
            district: 'เมืองลำพูน',
            province: 'ลำพูน',
            zipcode: 51000,
        },
        {
            subDistrict: 'ป่าสัก',
            district: 'เมืองลำพูน',
            province: 'ลำพูน',
            zipcode: 51000,
        },
        {
            subDistrict: 'มะเขือแจ้',
            district: 'เมืองลำพูน',
            province: 'ลำพูน',
            zipcode: 51000,
        },
        {
            subDistrict: 'ริมปิง',
            district: 'เมืองลำพูน',
            province: 'ลำพูน',
            zipcode: 51000,
        },
        {
            subDistrict: 'ศรีบัวบาน',
            district: 'เมืองลำพูน',
            province: 'ลำพูน',
            zipcode: 51000,
        },
        {
            subDistrict: 'หนองช้างคืน',
            district: 'เมืองลำพูน',
            province: 'ลำพูน',
            zipcode: 51150,
        },
        {
            subDistrict: 'หนองหนาม',
            district: 'เมืองลำพูน',
            province: 'ลำพูน',
            zipcode: 51000,
        },
        {
            subDistrict: 'อุโมงค์',
            district: 'เมืองลำพูน',
            province: 'ลำพูน',
            zipcode: 51150,
        },
        {
            subDistrict: 'เวียงยอง',
            district: 'เมืองลำพูน',
            province: 'ลำพูน',
            zipcode: 51000,
        },
        {
            subDistrict: 'เหมืองง่า',
            district: 'เมืองลำพูน',
            province: 'ลำพูน',
            zipcode: 51000,
        },
        {
            subDistrict: 'เหมืองจี้',
            district: 'เมืองลำพูน',
            province: 'ลำพูน',
            zipcode: 51000,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'เมืองลำพูน',
            province: 'ลำพูน',
            zipcode: 51000,
        },
        {
            subDistrict: 'วังผาง',
            district: 'เวียงหนองล่อง',
            province: 'ลำพูน',
            zipcode: 51120,
        },
        {
            subDistrict: 'หนองยวง',
            district: 'เวียงหนองล่อง',
            province: 'ลำพูน',
            zipcode: 51120,
        },
        {
            subDistrict: 'หนองล่อง',
            district: 'เวียงหนองล่อง',
            province: 'ลำพูน',
            zipcode: 51120,
        },
        {
            subDistrict: 'ทากาศ',
            district: 'แม่ทา',
            province: 'ลำพูน',
            zipcode: 51170,
        },
        {
            subDistrict: 'ทาขุมเงิน',
            district: 'แม่ทา',
            province: 'ลำพูน',
            zipcode: 51170,
        },
        {
            subDistrict: 'ทาทุ่งหลวง',
            district: 'แม่ทา',
            province: 'ลำพูน',
            zipcode: 51170,
        },
        {
            subDistrict: 'ทาปลาดุก',
            district: 'แม่ทา',
            province: 'ลำพูน',
            zipcode: 51140,
        },
        {
            subDistrict: 'ทาสบเส้า',
            district: 'แม่ทา',
            province: 'ลำพูน',
            zipcode: 51140,
        },
        {
            subDistrict: 'ทาแม่ลอบ',
            district: 'แม่ทา',
            province: 'ลำพูน',
            zipcode: 51170,
        },
        {
            subDistrict: 'กระแชง',
            district: 'กันทรลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'กุดเสลา',
            district: 'กันทรลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'ขนุน',
            district: 'กันทรลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'จานใหญ่',
            district: 'กันทรลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'ชำ',
            district: 'กันทรลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'ตระกาจ',
            district: 'กันทรลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'ทุ่งใหญ่',
            district: 'กันทรลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'น้ำอ้อม',
            district: 'กันทรลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'บึงมะลู',
            district: 'กันทรลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'ภูผาหมอก',
            district: 'กันทรลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'ภูเงิน',
            district: 'กันทรลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'รุง',
            district: 'กันทรลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'ละลาย',
            district: 'กันทรลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'สวนกล้วย',
            district: 'กันทรลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'สังเม็ก',
            district: 'กันทรลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'หนองหญ้าลาด',
            district: 'กันทรลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'เมือง',
            district: 'กันทรลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'เวียงเหนือ',
            district: 'กันทรลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'เสาธงชัย',
            district: 'กันทรลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'โนนสำราญ',
            district: 'กันทรลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'คำเนียม',
            district: 'กันทรารมย์',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'จาน',
            district: 'กันทรารมย์',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'ดูน',
            district: 'กันทรารมย์',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'ดู่',
            district: 'กันทรารมย์',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'ทาม',
            district: 'กันทรารมย์',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'บัวน้อย',
            district: 'กันทรารมย์',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'ผักแพว',
            district: 'กันทรารมย์',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'ยาง',
            district: 'กันทรารมย์',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'ละทาย',
            district: 'กันทรารมย์',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'กันทรารมย์',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'หนองหัวช้าง',
            district: 'กันทรารมย์',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'หนองแก้ว',
            district: 'กันทรารมย์',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'หนองแวง',
            district: 'กันทรารมย์',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'อีปาด',
            district: 'กันทรารมย์',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'เมืองน้อย',
            district: 'กันทรารมย์',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'โนนสัง',
            district: 'กันทรารมย์',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'กฤษณา',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'กันทรารมย์',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'จะกง',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'ดองกำเม็ด',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'ตะเคียน',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'ตาอุด',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'นิคมพัฒนา',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'ปราสาท',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'ปรือใหญ่',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'ลมศักดิ์',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'ศรีตระกูล',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'ศรีสะอาด',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'สะเดาใหญ่',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'สำโรงตาเจ็น',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'หนองฉลอง',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'หัวเสือ',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'ห้วยสำราญ',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'ห้วยเหนือ',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'ห้วยใต้',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'โคกเพชร',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'โสน',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'ใจดี',
            district: 'ขุขันธ์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'กระหวัน',
            district: 'ขุนหาญ',
            province: 'ศรีสะเกษ',
            zipcode: 33150,
        },
        {
            subDistrict: 'กันทรอม',
            district: 'ขุนหาญ',
            province: 'ศรีสะเกษ',
            zipcode: 33150,
        },
        {
            subDistrict: 'ขุนหาญ',
            district: 'ขุนหาญ',
            province: 'ศรีสะเกษ',
            zipcode: 33150,
        },
        {
            subDistrict: 'บักดอง',
            district: 'ขุนหาญ',
            province: 'ศรีสะเกษ',
            zipcode: 33150,
        },
        {
            subDistrict: 'พราน',
            district: 'ขุนหาญ',
            province: 'ศรีสะเกษ',
            zipcode: 33150,
        },
        {
            subDistrict: 'ภูฝ้าย',
            district: 'ขุนหาญ',
            province: 'ศรีสะเกษ',
            zipcode: 33150,
        },
        {
            subDistrict: 'สิ',
            district: 'ขุนหาญ',
            province: 'ศรีสะเกษ',
            zipcode: 33150,
        },
        {
            subDistrict: 'ห้วยจันทร์',
            district: 'ขุนหาญ',
            province: 'ศรีสะเกษ',
            zipcode: 33150,
        },
        {
            subDistrict: 'โนนสูง',
            district: 'ขุนหาญ',
            province: 'ศรีสะเกษ',
            zipcode: 33150,
        },
        {
            subDistrict: 'โพธิ์กระสังข์',
            district: 'ขุนหาญ',
            province: 'ศรีสะเกษ',
            zipcode: 33150,
        },
        {
            subDistrict: 'โพธิ์วงศ์',
            district: 'ขุนหาญ',
            province: 'ศรีสะเกษ',
            zipcode: 33150,
        },
        {
            subDistrict: 'ไพร',
            district: 'ขุนหาญ',
            province: 'ศรีสะเกษ',
            zipcode: 33150,
        },
        {
            subDistrict: 'คูบ',
            district: 'น้ำเกลี้ยง',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'ตองปิด',
            district: 'น้ำเกลี้ยง',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'น้ำเกลี้ยง',
            district: 'น้ำเกลี้ยง',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'รุ่งระวี',
            district: 'น้ำเกลี้ยง',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'ละเอาะ',
            district: 'น้ำเกลี้ยง',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'เขิน',
            district: 'น้ำเกลี้ยง',
            province: 'ศรีสะเกษ',
            zipcode: 33130,
        },
        {
            subDistrict: 'บึงบูรพ์',
            district: 'บึงบูรพ์',
            province: 'ศรีสะเกษ',
            zipcode: 33220,
        },
        {
            subDistrict: 'เป๊าะ',
            district: 'บึงบูรพ์',
            province: 'ศรีสะเกษ',
            zipcode: 33220,
        },
        {
            subDistrict: 'กู่',
            district: 'ปรางค์กู่',
            province: 'ศรีสะเกษ',
            zipcode: 33170,
        },
        {
            subDistrict: 'ดู่',
            district: 'ปรางค์กู่',
            province: 'ศรีสะเกษ',
            zipcode: 33170,
        },
        {
            subDistrict: 'ตูม',
            district: 'ปรางค์กู่',
            province: 'ศรีสะเกษ',
            zipcode: 33170,
        },
        {
            subDistrict: 'พิมาย',
            district: 'ปรางค์กู่',
            province: 'ศรีสะเกษ',
            zipcode: 33170,
        },
        {
            subDistrict: 'พิมายเหนือ',
            district: 'ปรางค์กู่',
            province: 'ศรีสะเกษ',
            zipcode: 33170,
        },
        {
            subDistrict: 'สมอ',
            district: 'ปรางค์กู่',
            province: 'ศรีสะเกษ',
            zipcode: 33170,
        },
        {
            subDistrict: 'สวาย',
            district: 'ปรางค์กู่',
            province: 'ศรีสะเกษ',
            zipcode: 33170,
        },
        {
            subDistrict: 'สำโรงปราสาท',
            district: 'ปรางค์กู่',
            province: 'ศรีสะเกษ',
            zipcode: 33170,
        },
        {
            subDistrict: 'หนองเชียงทูน',
            district: 'ปรางค์กู่',
            province: 'ศรีสะเกษ',
            zipcode: 33170,
        },
        {
            subDistrict: 'โพธิ์ศรี',
            district: 'ปรางค์กู่',
            province: 'ศรีสะเกษ',
            zipcode: 33170,
        },
        {
            subDistrict: 'ตำแย',
            district: 'พยุห์',
            province: 'ศรีสะเกษ',
            zipcode: 33230,
        },
        {
            subDistrict: 'พยุห์',
            district: 'พยุห์',
            province: 'ศรีสะเกษ',
            zipcode: 33230,
        },
        {
            subDistrict: 'พรหมสวัสดิ์',
            district: 'พยุห์',
            province: 'ศรีสะเกษ',
            zipcode: 33230,
        },
        {
            subDistrict: 'หนองค้า',
            district: 'พยุห์',
            province: 'ศรีสะเกษ',
            zipcode: 33230,
        },
        {
            subDistrict: 'โนนเพ็ก',
            district: 'พยุห์',
            province: 'ศรีสะเกษ',
            zipcode: 33230,
        },
        {
            subDistrict: 'ดงรัก',
            district: 'ภูสิงห์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'ตะเคียนราม',
            district: 'ภูสิงห์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'ละลม',
            district: 'ภูสิงห์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'ห้วยตามอญ',
            district: 'ภูสิงห์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'ห้วยตึ๊กชู',
            district: 'ภูสิงห์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'โคกตาล',
            district: 'ภูสิงห์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'ไพรพัฒนา',
            district: 'ภูสิงห์',
            province: 'ศรีสะเกษ',
            zipcode: 33140,
        },
        {
            subDistrict: 'กุดเมืองฮาม',
            district: 'ยางชุมน้อย',
            province: 'ศรีสะเกษ',
            zipcode: 33190,
        },
        {
            subDistrict: 'คอนกาม',
            district: 'ยางชุมน้อย',
            province: 'ศรีสะเกษ',
            zipcode: 33190,
        },
        {
            subDistrict: 'บึงบอน',
            district: 'ยางชุมน้อย',
            province: 'ศรีสะเกษ',
            zipcode: 33190,
        },
        {
            subDistrict: 'ยางชุมน้อย',
            district: 'ยางชุมน้อย',
            province: 'ศรีสะเกษ',
            zipcode: 33190,
        },
        {
            subDistrict: 'ยางชุมใหญ่',
            district: 'ยางชุมน้อย',
            province: 'ศรีสะเกษ',
            zipcode: 33190,
        },
        {
            subDistrict: 'ลิ้นฟ้า',
            district: 'ยางชุมน้อย',
            province: 'ศรีสะเกษ',
            zipcode: 33190,
        },
        {
            subDistrict: 'โนนคูณ',
            district: 'ยางชุมน้อย',
            province: 'ศรีสะเกษ',
            zipcode: 33190,
        },
        {
            subDistrict: 'จิกสังข์ทอง',
            district: 'ราษีไศล',
            province: 'ศรีสะเกษ',
            zipcode: 33160,
        },
        {
            subDistrict: 'ดู่',
            district: 'ราษีไศล',
            province: 'ศรีสะเกษ',
            zipcode: 33160,
        },
        {
            subDistrict: 'ด่าน',
            district: 'ราษีไศล',
            province: 'ศรีสะเกษ',
            zipcode: 33160,
        },
        {
            subDistrict: 'บัวหุ่ง',
            district: 'ราษีไศล',
            province: 'ศรีสะเกษ',
            zipcode: 33160,
        },
        {
            subDistrict: 'สร้างปี่',
            district: 'ราษีไศล',
            province: 'ศรีสะเกษ',
            zipcode: 33160,
        },
        {
            subDistrict: 'ส้มป่อย',
            district: 'ราษีไศล',
            province: 'ศรีสะเกษ',
            zipcode: 33160,
        },
        {
            subDistrict: 'หนองหมี',
            district: 'ราษีไศล',
            province: 'ศรีสะเกษ',
            zipcode: 33160,
        },
        {
            subDistrict: 'หนองอึ่ง',
            district: 'ราษีไศล',
            province: 'ศรีสะเกษ',
            zipcode: 33160,
        },
        {
            subDistrict: 'หนองแค',
            district: 'ราษีไศล',
            province: 'ศรีสะเกษ',
            zipcode: 33160,
        },
        {
            subDistrict: 'หว้านคำ',
            district: 'ราษีไศล',
            province: 'ศรีสะเกษ',
            zipcode: 33160,
        },
        {
            subDistrict: 'เมืองคง',
            district: 'ราษีไศล',
            province: 'ศรีสะเกษ',
            zipcode: 33160,
        },
        {
            subDistrict: 'เมืองแคน',
            district: 'ราษีไศล',
            province: 'ศรีสะเกษ',
            zipcode: 33160,
        },
        {
            subDistrict: 'ไผ่',
            district: 'ราษีไศล',
            province: 'ศรีสะเกษ',
            zipcode: 33160,
        },
        {
            subDistrict: 'ดวนใหญ่',
            district: 'วังหิน',
            province: 'ศรีสะเกษ',
            zipcode: 33270,
        },
        {
            subDistrict: 'ทุ่งสว่าง',
            district: 'วังหิน',
            province: 'ศรีสะเกษ',
            zipcode: 33270,
        },
        {
            subDistrict: 'ธาตุ',
            district: 'วังหิน',
            province: 'ศรีสะเกษ',
            zipcode: 33270,
        },
        {
            subDistrict: 'บุสูง',
            district: 'วังหิน',
            province: 'ศรีสะเกษ',
            zipcode: 33270,
        },
        {
            subDistrict: 'บ่อแก้ว',
            district: 'วังหิน',
            province: 'ศรีสะเกษ',
            zipcode: 33270,
        },
        {
            subDistrict: 'วังหิน',
            district: 'วังหิน',
            province: 'ศรีสะเกษ',
            zipcode: 33270,
        },
        {
            subDistrict: 'ศรีสำราญ',
            district: 'วังหิน',
            province: 'ศรีสะเกษ',
            zipcode: 33270,
        },
        {
            subDistrict: 'โพนยาง',
            district: 'วังหิน',
            province: 'ศรีสะเกษ',
            zipcode: 33270,
        },
        {
            subDistrict: 'ตูม',
            district: 'ศรีรัตนะ',
            province: 'ศรีสะเกษ',
            zipcode: 33240,
        },
        {
            subDistrict: 'พิงพวย',
            district: 'ศรีรัตนะ',
            province: 'ศรีสะเกษ',
            zipcode: 33240,
        },
        {
            subDistrict: 'ศรีแก้ว',
            district: 'ศรีรัตนะ',
            province: 'ศรีสะเกษ',
            zipcode: 33240,
        },
        {
            subDistrict: 'ศรีโนนงาม',
            district: 'ศรีรัตนะ',
            province: 'ศรีสะเกษ',
            zipcode: 33240,
        },
        {
            subDistrict: 'สระเยาว์',
            district: 'ศรีรัตนะ',
            province: 'ศรีสะเกษ',
            zipcode: 33240,
        },
        {
            subDistrict: 'สะพุง',
            district: 'ศรีรัตนะ',
            province: 'ศรีสะเกษ',
            zipcode: 33240,
        },
        {
            subDistrict: 'เสื่องข้าว',
            district: 'ศรีรัตนะ',
            province: 'ศรีสะเกษ',
            zipcode: 33240,
        },
        {
            subDistrict: 'กุง',
            district: 'ศิลาลาด',
            province: 'ศรีสะเกษ',
            zipcode: 33160,
        },
        {
            subDistrict: 'คลีกลิ้ง',
            district: 'ศิลาลาด',
            province: 'ศรีสะเกษ',
            zipcode: 33160,
        },
        {
            subDistrict: 'หนองบัวดง',
            district: 'ศิลาลาด',
            province: 'ศรีสะเกษ',
            zipcode: 33160,
        },
        {
            subDistrict: 'โจดม่วง',
            district: 'ศิลาลาด',
            province: 'ศรีสะเกษ',
            zipcode: 33160,
        },
        {
            subDistrict: 'กล้วยกว้าง',
            district: 'ห้วยทับทัน',
            province: 'ศรีสะเกษ',
            zipcode: 33210,
        },
        {
            subDistrict: 'จานแสนไชย',
            district: 'ห้วยทับทัน',
            province: 'ศรีสะเกษ',
            zipcode: 33210,
        },
        {
            subDistrict: 'ปราสาท',
            district: 'ห้วยทับทัน',
            province: 'ศรีสะเกษ',
            zipcode: 33210,
        },
        {
            subDistrict: 'ผักไหม',
            district: 'ห้วยทับทัน',
            province: 'ศรีสะเกษ',
            zipcode: 33210,
        },
        {
            subDistrict: 'ห้วยทับทัน',
            district: 'ห้วยทับทัน',
            province: 'ศรีสะเกษ',
            zipcode: 33210,
        },
        {
            subDistrict: 'เมืองหลวง',
            district: 'ห้วยทับทัน',
            province: 'ศรีสะเกษ',
            zipcode: 33210,
        },
        {
            subDistrict: 'กำแพง',
            district: 'อุทุมพรพิสัย',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'ก้านเหลือง',
            district: 'อุทุมพรพิสัย',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'ขะยูง',
            district: 'อุทุมพรพิสัย',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'ตาเกษ',
            district: 'อุทุมพรพิสัย',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'ทุ่งไชย',
            district: 'อุทุมพรพิสัย',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'ปะอาว',
            district: 'อุทุมพรพิสัย',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'รังแร้ง',
            district: 'อุทุมพรพิสัย',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'สระกำแพงใหญ่',
            district: 'อุทุมพรพิสัย',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'สำโรง',
            district: 'อุทุมพรพิสัย',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'หนองห้าง',
            district: 'อุทุมพรพิสัย',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'หนองไฮ',
            district: 'อุทุมพรพิสัย',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'หัวช้าง',
            district: 'อุทุมพรพิสัย',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'อี่หล่ำ',
            district: 'อุทุมพรพิสัย',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'แขม',
            district: 'อุทุมพรพิสัย',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'แข้',
            district: 'อุทุมพรพิสัย',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'แต้',
            district: 'อุทุมพรพิสัย',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'โคกจาน',
            district: 'อุทุมพรพิสัย',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'โคกหล่าม',
            district: 'อุทุมพรพิสัย',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'โพธิ์ชัย',
            district: 'อุทุมพรพิสัย',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'ท่าคล้อ',
            district: 'เบญจลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'หนองงูเหลือม',
            district: 'เบญจลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'หนองหว้า',
            district: 'เบญจลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'หนองฮาง',
            district: 'เบญจลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'เสียว',
            district: 'เบญจลักษ์',
            province: 'ศรีสะเกษ',
            zipcode: 33110,
        },
        {
            subDistrict: 'ตาโกน',
            district: 'เมืองจันทร์',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'หนองใหญ่',
            district: 'เมืองจันทร์',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'เมืองจันทร์',
            district: 'เมืองจันทร์',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'คูซอด',
            district: 'เมืองศรีสะเกษ',
            province: 'ศรีสะเกษ',
            zipcode: 33000,
        },
        {
            subDistrict: 'จาน',
            district: 'เมืองศรีสะเกษ',
            province: 'ศรีสะเกษ',
            zipcode: 33000,
        },
        {
            subDistrict: 'ซำ',
            district: 'เมืองศรีสะเกษ',
            province: 'ศรีสะเกษ',
            zipcode: 33000,
        },
        {
            subDistrict: 'ตะดอบ',
            district: 'เมืองศรีสะเกษ',
            province: 'ศรีสะเกษ',
            zipcode: 33000,
        },
        {
            subDistrict: 'ทุ่ม',
            district: 'เมืองศรีสะเกษ',
            province: 'ศรีสะเกษ',
            zipcode: 33000,
        },
        {
            subDistrict: 'น้ำคำ',
            district: 'เมืองศรีสะเกษ',
            province: 'ศรีสะเกษ',
            zipcode: 33000,
        },
        {
            subDistrict: 'หญ้าปล้อง',
            district: 'เมืองศรีสะเกษ',
            province: 'ศรีสะเกษ',
            zipcode: 33000,
        },
        {
            subDistrict: 'หนองครก',
            district: 'เมืองศรีสะเกษ',
            province: 'ศรีสะเกษ',
            zipcode: 33000,
        },
        {
            subDistrict: 'หนองแก้ว',
            district: 'เมืองศรีสะเกษ',
            province: 'ศรีสะเกษ',
            zipcode: 33000,
        },
        {
            subDistrict: 'หนองไผ่',
            district: 'เมืองศรีสะเกษ',
            province: 'ศรีสะเกษ',
            zipcode: 33000,
        },
        {
            subDistrict: 'หนองไฮ',
            district: 'เมืองศรีสะเกษ',
            province: 'ศรีสะเกษ',
            zipcode: 33000,
        },
        {
            subDistrict: 'หมากเขียบ',
            district: 'เมืองศรีสะเกษ',
            province: 'ศรีสะเกษ',
            zipcode: 33000,
        },
        {
            subDistrict: 'เมืองเหนือ',
            district: 'เมืองศรีสะเกษ',
            province: 'ศรีสะเกษ',
            zipcode: 33000,
        },
        {
            subDistrict: 'เมืองใต้',
            district: 'เมืองศรีสะเกษ',
            province: 'ศรีสะเกษ',
            zipcode: 33000,
        },
        {
            subDistrict: 'โพธิ์',
            district: 'เมืองศรีสะเกษ',
            province: 'ศรีสะเกษ',
            zipcode: 33000,
        },
        {
            subDistrict: 'โพนข่า',
            district: 'เมืองศรีสะเกษ',
            province: 'ศรีสะเกษ',
            zipcode: 33000,
        },
        {
            subDistrict: 'โพนค้อ',
            district: 'เมืองศรีสะเกษ',
            province: 'ศรีสะเกษ',
            zipcode: 33000,
        },
        {
            subDistrict: 'โพนเขวา',
            district: 'เมืองศรีสะเกษ',
            province: 'ศรีสะเกษ',
            zipcode: 33000,
        },
        {
            subDistrict: 'บก',
            district: 'โนนคูณ',
            province: 'ศรีสะเกษ',
            zipcode: 33250,
        },
        {
            subDistrict: 'หนองกุง',
            district: 'โนนคูณ',
            province: 'ศรีสะเกษ',
            zipcode: 33250,
        },
        {
            subDistrict: 'เหล่ากวาง',
            district: 'โนนคูณ',
            province: 'ศรีสะเกษ',
            zipcode: 33250,
        },
        {
            subDistrict: 'โนนค้อ',
            district: 'โนนคูณ',
            province: 'ศรีสะเกษ',
            zipcode: 33250,
        },
        {
            subDistrict: 'โพธิ์',
            district: 'โนนคูณ',
            province: 'ศรีสะเกษ',
            zipcode: 33250,
        },
        {
            subDistrict: 'ผือใหญ่',
            district: 'โพธิ์ศรีสุวรรณ',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'หนองม้า',
            district: 'โพธิ์ศรีสุวรรณ',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'อีเซ',
            district: 'โพธิ์ศรีสุวรรณ',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'เสียว',
            district: 'โพธิ์ศรีสุวรรณ',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'โดด',
            district: 'โพธิ์ศรีสุวรรณ',
            province: 'ศรีสะเกษ',
            zipcode: 33120,
        },
        {
            subDistrict: 'ดินแดง',
            district: 'ไพรบึง',
            province: 'ศรีสะเกษ',
            zipcode: 33180,
        },
        {
            subDistrict: 'ปราสาทเยอ',
            district: 'ไพรบึง',
            province: 'ศรีสะเกษ',
            zipcode: 33180,
        },
        {
            subDistrict: 'สำโรงพลัน',
            district: 'ไพรบึง',
            province: 'ศรีสะเกษ',
            zipcode: 33180,
        },
        {
            subDistrict: 'สุขสวัสดิ์',
            district: 'ไพรบึง',
            province: 'ศรีสะเกษ',
            zipcode: 33180,
        },
        {
            subDistrict: 'โนนปูน',
            district: 'ไพรบึง',
            province: 'ศรีสะเกษ',
            zipcode: 33180,
        },
        {
            subDistrict: 'ไพรบึง',
            district: 'ไพรบึง',
            province: 'ศรีสะเกษ',
            zipcode: 33180,
        },
        {
            subDistrict: 'กุดบาก',
            district: 'กุดบาก',
            province: 'สกลนคร',
            zipcode: 47180,
        },
        {
            subDistrict: 'กุดไห',
            district: 'กุดบาก',
            province: 'สกลนคร',
            zipcode: 47180,
        },
        {
            subDistrict: 'นาม่อง',
            district: 'กุดบาก',
            province: 'สกลนคร',
            zipcode: 47180,
        },
        {
            subDistrict: 'กุสุมาลย์',
            district: 'กุสุมาลย์',
            province: 'สกลนคร',
            zipcode: 47210,
        },
        {
            subDistrict: 'นาเพียง',
            district: 'กุสุมาลย์',
            province: 'สกลนคร',
            zipcode: 47230,
        },
        {
            subDistrict: 'นาโพธิ์',
            district: 'กุสุมาลย์',
            province: 'สกลนคร',
            zipcode: 47210,
        },
        {
            subDistrict: 'อุ่มจาน',
            district: 'กุสุมาลย์',
            province: 'สกลนคร',
            zipcode: 47230,
        },
        {
            subDistrict: 'โพธิ์ไพศาล',
            district: 'กุสุมาลย์',
            province: 'สกลนคร',
            zipcode: 47210,
        },
        {
            subDistrict: 'คำตากล้า',
            district: 'คำตากล้า',
            province: 'สกลนคร',
            zipcode: 47250,
        },
        {
            subDistrict: 'นาแต้',
            district: 'คำตากล้า',
            province: 'สกลนคร',
            zipcode: 47250,
        },
        {
            subDistrict: 'หนองบัวสิม',
            district: 'คำตากล้า',
            province: 'สกลนคร',
            zipcode: 47250,
        },
        {
            subDistrict: 'แพด',
            district: 'คำตากล้า',
            province: 'สกลนคร',
            zipcode: 47250,
        },
        {
            subDistrict: 'นิคมน้ำอูน',
            district: 'นิคมน้ำอูน',
            province: 'สกลนคร',
            zipcode: 47270,
        },
        {
            subDistrict: 'สุวรรณคาม',
            district: 'นิคมน้ำอูน',
            province: 'สกลนคร',
            zipcode: 47270,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'นิคมน้ำอูน',
            province: 'สกลนคร',
            zipcode: 47270,
        },
        {
            subDistrict: 'หนองปลิง',
            district: 'นิคมน้ำอูน',
            province: 'สกลนคร',
            zipcode: 47270,
        },
        {
            subDistrict: 'ดงหม้อทอง',
            district: 'บ้านม่วง',
            province: 'สกลนคร',
            zipcode: 47140,
        },
        {
            subDistrict: 'ดงหม้อทองใต้',
            district: 'บ้านม่วง',
            province: 'สกลนคร',
            zipcode: 47140,
        },
        {
            subDistrict: 'ดงเหนือ',
            district: 'บ้านม่วง',
            province: 'สกลนคร',
            zipcode: 47140,
        },
        {
            subDistrict: 'บ่อแก้ว',
            district: 'บ้านม่วง',
            province: 'สกลนคร',
            zipcode: 47140,
        },
        {
            subDistrict: 'มาย',
            district: 'บ้านม่วง',
            province: 'สกลนคร',
            zipcode: 47140,
        },
        {
            subDistrict: 'ม่วง',
            district: 'บ้านม่วง',
            province: 'สกลนคร',
            zipcode: 47140,
        },
        {
            subDistrict: 'หนองกวั่ง',
            district: 'บ้านม่วง',
            province: 'สกลนคร',
            zipcode: 47140,
        },
        {
            subDistrict: 'ห้วยหลัว',
            district: 'บ้านม่วง',
            province: 'สกลนคร',
            zipcode: 47140,
        },
        {
            subDistrict: 'โนนสะอาด',
            district: 'บ้านม่วง',
            province: 'สกลนคร',
            zipcode: 47140,
        },
        {
            subDistrict: 'ช้างมิ่ง',
            district: 'พรรณานิคม',
            province: 'สกลนคร',
            zipcode: 47130,
        },
        {
            subDistrict: 'นาหัวบ่อ',
            district: 'พรรณานิคม',
            province: 'สกลนคร',
            zipcode: 47220,
        },
        {
            subDistrict: 'นาใน',
            district: 'พรรณานิคม',
            province: 'สกลนคร',
            zipcode: 47130,
        },
        {
            subDistrict: 'บะฮี',
            district: 'พรรณานิคม',
            province: 'สกลนคร',
            zipcode: 47130,
        },
        {
            subDistrict: 'พรรณา',
            district: 'พรรณานิคม',
            province: 'สกลนคร',
            zipcode: 47130,
        },
        {
            subDistrict: 'พอกน้อย',
            district: 'พรรณานิคม',
            province: 'สกลนคร',
            zipcode: 47220,
        },
        {
            subDistrict: 'วังยาง',
            district: 'พรรณานิคม',
            province: 'สกลนคร',
            zipcode: 47130,
        },
        {
            subDistrict: 'สว่าง',
            district: 'พรรณานิคม',
            province: 'สกลนคร',
            zipcode: 47130,
        },
        {
            subDistrict: 'เชิงชุม',
            district: 'พรรณานิคม',
            province: 'สกลนคร',
            zipcode: 47130,
        },
        {
            subDistrict: 'ไร่',
            district: 'พรรณานิคม',
            province: 'สกลนคร',
            zipcode: 47130,
        },
        {
            subDistrict: 'ต้นผึ้ง',
            district: 'พังโคน',
            province: 'สกลนคร',
            zipcode: 47160,
        },
        {
            subDistrict: 'พังโคน',
            district: 'พังโคน',
            province: 'สกลนคร',
            zipcode: 47160,
        },
        {
            subDistrict: 'ม่วงไข่',
            district: 'พังโคน',
            province: 'สกลนคร',
            zipcode: 47160,
        },
        {
            subDistrict: 'แร่',
            district: 'พังโคน',
            province: 'สกลนคร',
            zipcode: 47160,
        },
        {
            subDistrict: 'ไฮหย่อง',
            district: 'พังโคน',
            province: 'สกลนคร',
            zipcode: 47160,
        },
        {
            subDistrict: 'กกปลาซิว',
            district: 'ภูพาน',
            province: 'สกลนคร',
            zipcode: 47180,
        },
        {
            subDistrict: 'สร้างค้อ',
            district: 'ภูพาน',
            province: 'สกลนคร',
            zipcode: 47180,
        },
        {
            subDistrict: 'หลุบเลา',
            district: 'ภูพาน',
            province: 'สกลนคร',
            zipcode: 47180,
        },
        {
            subDistrict: 'โคกภู',
            district: 'ภูพาน',
            province: 'สกลนคร',
            zipcode: 47180,
        },
        {
            subDistrict: 'กุดเรือคำ',
            district: 'วานรนิวาส',
            province: 'สกลนคร',
            zipcode: 47120,
        },
        {
            subDistrict: 'ขัวก่าย',
            district: 'วานรนิวาส',
            province: 'สกลนคร',
            zipcode: 47120,
        },
        {
            subDistrict: 'คอนสวรรค์',
            district: 'วานรนิวาส',
            province: 'สกลนคร',
            zipcode: 47120,
        },
        {
            subDistrict: 'คูสะคาม',
            district: 'วานรนิวาส',
            province: 'สกลนคร',
            zipcode: 47120,
        },
        {
            subDistrict: 'ธาตุ',
            district: 'วานรนิวาส',
            province: 'สกลนคร',
            zipcode: 47120,
        },
        {
            subDistrict: 'นาคำ',
            district: 'วานรนิวาส',
            province: 'สกลนคร',
            zipcode: 47120,
        },
        {
            subDistrict: 'นาซอ',
            district: 'วานรนิวาส',
            province: 'สกลนคร',
            zipcode: 47120,
        },
        {
            subDistrict: 'วานรนิวาส',
            district: 'วานรนิวาส',
            province: 'สกลนคร',
            zipcode: 47120,
        },
        {
            subDistrict: 'ศรีวิชัย',
            district: 'วานรนิวาส',
            province: 'สกลนคร',
            zipcode: 47120,
        },
        {
            subDistrict: 'หนองสนม',
            district: 'วานรนิวาส',
            province: 'สกลนคร',
            zipcode: 47120,
        },
        {
            subDistrict: 'หนองแวง',
            district: 'วานรนิวาส',
            province: 'สกลนคร',
            zipcode: 47120,
        },
        {
            subDistrict: 'หนองแวงใต้',
            district: 'วานรนิวาส',
            province: 'สกลนคร',
            zipcode: 47120,
        },
        {
            subDistrict: 'อินทร์แปลง',
            district: 'วานรนิวาส',
            province: 'สกลนคร',
            zipcode: 47120,
        },
        {
            subDistrict: 'เดื่อศรีคันไชย',
            district: 'วานรนิวาส',
            province: 'สกลนคร',
            zipcode: 47120,
        },
        {
            subDistrict: 'คำบ่อ',
            district: 'วาริชภูมิ',
            province: 'สกลนคร',
            zipcode: 47150,
        },
        {
            subDistrict: 'ค้อเขียว',
            district: 'วาริชภูมิ',
            province: 'สกลนคร',
            zipcode: 47150,
        },
        {
            subDistrict: 'ปลาโหล',
            district: 'วาริชภูมิ',
            province: 'สกลนคร',
            zipcode: 47150,
        },
        {
            subDistrict: 'วาริชภูมิ',
            district: 'วาริชภูมิ',
            province: 'สกลนคร',
            zipcode: 47150,
        },
        {
            subDistrict: 'หนองลาด',
            district: 'วาริชภูมิ',
            province: 'สกลนคร',
            zipcode: 47150,
        },
        {
            subDistrict: 'คำสะอาด',
            district: 'สว่างแดนดิน',
            province: 'สกลนคร',
            zipcode: 47110,
        },
        {
            subDistrict: 'ค้อใต้',
            district: 'สว่างแดนดิน',
            province: 'สกลนคร',
            zipcode: 47110,
        },
        {
            subDistrict: 'ตาลเนิ้ง',
            district: 'สว่างแดนดิน',
            province: 'สกลนคร',
            zipcode: 47240,
        },
        {
            subDistrict: 'ตาลโกน',
            district: 'สว่างแดนดิน',
            province: 'สกลนคร',
            zipcode: 47240,
        },
        {
            subDistrict: 'ทรายมูล',
            district: 'สว่างแดนดิน',
            province: 'สกลนคร',
            zipcode: 47110,
        },
        {
            subDistrict: 'ธาตุทอง',
            district: 'สว่างแดนดิน',
            province: 'สกลนคร',
            zipcode: 47240,
        },
        {
            subDistrict: 'บงเหนือ',
            district: 'สว่างแดนดิน',
            province: 'สกลนคร',
            zipcode: 47110,
        },
        {
            subDistrict: 'บงใต้',
            district: 'สว่างแดนดิน',
            province: 'สกลนคร',
            zipcode: 47110,
        },
        {
            subDistrict: 'บ้านต้าย',
            district: 'สว่างแดนดิน',
            province: 'สกลนคร',
            zipcode: 47110,
        },
        {
            subDistrict: 'บ้านถ่อน',
            district: 'สว่างแดนดิน',
            province: 'สกลนคร',
            zipcode: 47110,
        },
        {
            subDistrict: 'พันนา',
            district: 'สว่างแดนดิน',
            province: 'สกลนคร',
            zipcode: 47240,
        },
        {
            subDistrict: 'สว่างแดนดิน',
            district: 'สว่างแดนดิน',
            province: 'สกลนคร',
            zipcode: 47110,
        },
        {
            subDistrict: 'หนองหลวง',
            district: 'สว่างแดนดิน',
            province: 'สกลนคร',
            zipcode: 47110,
        },
        {
            subDistrict: 'แวง',
            district: 'สว่างแดนดิน',
            province: 'สกลนคร',
            zipcode: 47240,
        },
        {
            subDistrict: 'โคกสี',
            district: 'สว่างแดนดิน',
            province: 'สกลนคร',
            zipcode: 47110,
        },
        {
            subDistrict: 'โพนสูง',
            district: 'สว่างแดนดิน',
            province: 'สกลนคร',
            zipcode: 47110,
        },
        {
            subDistrict: 'ท่าศิลา',
            district: 'ส่องดาว',
            province: 'สกลนคร',
            zipcode: 47190,
        },
        {
            subDistrict: 'ปทุมวาปี',
            district: 'ส่องดาว',
            province: 'สกลนคร',
            zipcode: 47190,
        },
        {
            subDistrict: 'วัฒนา',
            district: 'ส่องดาว',
            province: 'สกลนคร',
            zipcode: 47190,
        },
        {
            subDistrict: 'ส่องดาว',
            district: 'ส่องดาว',
            province: 'สกลนคร',
            zipcode: 47190,
        },
        {
            subDistrict: 'ท่าก้อน',
            district: 'อากาศอำนวย',
            province: 'สกลนคร',
            zipcode: 47170,
        },
        {
            subDistrict: 'นาฮี',
            district: 'อากาศอำนวย',
            province: 'สกลนคร',
            zipcode: 47170,
        },
        {
            subDistrict: 'บะหว้า',
            district: 'อากาศอำนวย',
            province: 'สกลนคร',
            zipcode: 47170,
        },
        {
            subDistrict: 'วาใหญ่',
            district: 'อากาศอำนวย',
            province: 'สกลนคร',
            zipcode: 47170,
        },
        {
            subDistrict: 'สามัคคีพัฒนา',
            district: 'อากาศอำนวย',
            province: 'สกลนคร',
            zipcode: 47170,
        },
        {
            subDistrict: 'อากาศ',
            district: 'อากาศอำนวย',
            province: 'สกลนคร',
            zipcode: 47170,
        },
        {
            subDistrict: 'โพนงาม',
            district: 'อากาศอำนวย',
            province: 'สกลนคร',
            zipcode: 47170,
        },
        {
            subDistrict: 'โพนแพง',
            district: 'อากาศอำนวย',
            province: 'สกลนคร',
            zipcode: 47170,
        },
        {
            subDistrict: 'ทุ่งแก',
            district: 'เจริญศิลป์',
            province: 'สกลนคร',
            zipcode: 47290,
        },
        {
            subDistrict: 'บ้านเหล่า',
            district: 'เจริญศิลป์',
            province: 'สกลนคร',
            zipcode: 47290,
        },
        {
            subDistrict: 'หนองแปน',
            district: 'เจริญศิลป์',
            province: 'สกลนคร',
            zipcode: 47290,
        },
        {
            subDistrict: 'เจริญศิลป์',
            district: 'เจริญศิลป์',
            province: 'สกลนคร',
            zipcode: 47290,
        },
        {
            subDistrict: 'โคกศิลา',
            district: 'เจริญศิลป์',
            province: 'สกลนคร',
            zipcode: 47290,
        },
        {
            subDistrict: 'จันทร์เพ็ญ',
            district: 'เต่างอย',
            province: 'สกลนคร',
            zipcode: 47260,
        },
        {
            subDistrict: 'นาตาล',
            district: 'เต่างอย',
            province: 'สกลนคร',
            zipcode: 47260,
        },
        {
            subDistrict: 'บึงทวาย',
            district: 'เต่างอย',
            province: 'สกลนคร',
            zipcode: 47260,
        },
        {
            subDistrict: 'เต่างอย',
            district: 'เต่างอย',
            province: 'สกลนคร',
            zipcode: 47260,
        },
        {
            subDistrict: 'ขมิ้น',
            district: 'เมืองสกลนคร',
            province: 'สกลนคร',
            zipcode: 47220,
        },
        {
            subDistrict: 'งิ้วด่อน',
            district: 'เมืองสกลนคร',
            province: 'สกลนคร',
            zipcode: 47000,
        },
        {
            subDistrict: 'ดงชน',
            district: 'เมืองสกลนคร',
            province: 'สกลนคร',
            zipcode: 47000,
        },
        {
            subDistrict: 'ดงมะไฟ',
            district: 'เมืองสกลนคร',
            province: 'สกลนคร',
            zipcode: 47000,
        },
        {
            subDistrict: 'ท่าแร่',
            district: 'เมืองสกลนคร',
            province: 'สกลนคร',
            zipcode: 47230,
        },
        {
            subDistrict: 'ธาตุนาเวง',
            district: 'เมืองสกลนคร',
            province: 'สกลนคร',
            zipcode: 47000,
        },
        {
            subDistrict: 'ธาตุเชิงชุม',
            district: 'เมืองสกลนคร',
            province: 'สกลนคร',
            zipcode: 47000,
        },
        {
            subDistrict: 'พังขว้าง',
            district: 'เมืองสกลนคร',
            province: 'สกลนคร',
            zipcode: 47000,
        },
        {
            subDistrict: 'ม่วงลาย',
            district: 'เมืองสกลนคร',
            province: 'สกลนคร',
            zipcode: 47000,
        },
        {
            subDistrict: 'หนองลาด',
            district: 'เมืองสกลนคร',
            province: 'สกลนคร',
            zipcode: 47220,
        },
        {
            subDistrict: 'ห้วยยาง',
            district: 'เมืองสกลนคร',
            province: 'สกลนคร',
            zipcode: 47000,
        },
        {
            subDistrict: 'ฮางโฮง',
            district: 'เมืองสกลนคร',
            province: 'สกลนคร',
            zipcode: 47000,
        },
        {
            subDistrict: 'เชียงเครือ',
            district: 'เมืองสกลนคร',
            province: 'สกลนคร',
            zipcode: 47000,
        },
        {
            subDistrict: 'เหล่าปอแดง',
            district: 'เมืองสกลนคร',
            province: 'สกลนคร',
            zipcode: 47000,
        },
        {
            subDistrict: 'โคกก่อง',
            district: 'เมืองสกลนคร',
            province: 'สกลนคร',
            zipcode: 47000,
        },
        {
            subDistrict: 'โนนหอม',
            district: 'เมืองสกลนคร',
            province: 'สกลนคร',
            zipcode: 47000,
        },
        {
            subDistrict: 'ด่านม่วงคำ',
            district: 'โคกศรีสุพรรณ',
            province: 'สกลนคร',
            zipcode: 47280,
        },
        {
            subDistrict: 'ตองโขบ',
            district: 'โคกศรีสุพรรณ',
            province: 'สกลนคร',
            zipcode: 47280,
        },
        {
            subDistrict: 'เหล่าโพนค้อ',
            district: 'โคกศรีสุพรรณ',
            province: 'สกลนคร',
            zipcode: 47280,
        },
        {
            subDistrict: 'แมดนาท่ม',
            district: 'โคกศรีสุพรรณ',
            province: 'สกลนคร',
            zipcode: 47280,
        },
        {
            subDistrict: 'นาตงวัฒนา',
            district: 'โพนนาแก้ว',
            province: 'สกลนคร',
            zipcode: 47230,
        },
        {
            subDistrict: 'นาแก้ว',
            district: 'โพนนาแก้ว',
            province: 'สกลนคร',
            zipcode: 47230,
        },
        {
            subDistrict: 'บ้านแป้น',
            district: 'โพนนาแก้ว',
            province: 'สกลนคร',
            zipcode: 47230,
        },
        {
            subDistrict: 'บ้านโพน',
            district: 'โพนนาแก้ว',
            province: 'สกลนคร',
            zipcode: 47230,
        },
        {
            subDistrict: 'เชียงสือ',
            district: 'โพนนาแก้ว',
            province: 'สกลนคร',
            zipcode: 47230,
        },
        {
            subDistrict: 'กระแสสินธุ์',
            district: 'กระแสสินธุ์',
            province: 'สงขลา',
            zipcode: 90270,
        },
        {
            subDistrict: 'เกาะใหญ่',
            district: 'กระแสสินธุ์',
            province: 'สงขลา',
            zipcode: 90270,
        },
        {
            subDistrict: 'เชิงแส',
            district: 'กระแสสินธุ์',
            province: 'สงขลา',
            zipcode: 90270,
        },
        {
            subDistrict: 'โรง',
            district: 'กระแสสินธุ์',
            province: 'สงขลา',
            zipcode: 90270,
        },
        {
            subDistrict: 'คลองหลา',
            district: 'คลองหอยโข่ง',
            province: 'สงขลา',
            zipcode: 90115,
        },
        {
            subDistrict: 'คลองหอยโข่ง',
            district: 'คลองหอยโข่ง',
            province: 'สงขลา',
            zipcode: 90230,
        },
        {
            subDistrict: 'ทุ่งลาน',
            district: 'คลองหอยโข่ง',
            province: 'สงขลา',
            zipcode: 90230,
        },
        {
            subDistrict: 'โคกม่วง',
            district: 'คลองหอยโข่ง',
            province: 'สงขลา',
            zipcode: 90230,
        },
        {
            subDistrict: 'โคกม่วง',
            district: 'คลองหอยโข่ง',
            province: 'สงขลา',
            zipcode: 90115,
        },
        {
            subDistrict: 'ควนโส',
            district: 'ควนเนียง',
            province: 'สงขลา',
            zipcode: 90220,
        },
        {
            subDistrict: 'บางเหรียง',
            district: 'ควนเนียง',
            province: 'สงขลา',
            zipcode: 90220,
        },
        {
            subDistrict: 'รัตภูมิ',
            district: 'ควนเนียง',
            province: 'สงขลา',
            zipcode: 90220,
        },
        {
            subDistrict: 'ห้วยลึก',
            district: 'ควนเนียง',
            province: 'สงขลา',
            zipcode: 90220,
        },
        {
            subDistrict: 'ขุนตัดหวาย',
            district: 'จะนะ',
            province: 'สงขลา',
            zipcode: 90130,
        },
        {
            subDistrict: 'คลองเปียะ',
            district: 'จะนะ',
            province: 'สงขลา',
            zipcode: 90130,
        },
        {
            subDistrict: 'คู',
            district: 'จะนะ',
            province: 'สงขลา',
            zipcode: 90130,
        },
        {
            subDistrict: 'จะโหนง',
            district: 'จะนะ',
            province: 'สงขลา',
            zipcode: 90130,
        },
        {
            subDistrict: 'ตลิ่งชัน',
            district: 'จะนะ',
            province: 'สงขลา',
            zipcode: 90130,
        },
        {
            subDistrict: 'ท่าหมอไทร',
            district: 'จะนะ',
            province: 'สงขลา',
            zipcode: 90130,
        },
        {
            subDistrict: 'นาทับ',
            district: 'จะนะ',
            province: 'สงขลา',
            zipcode: 90130,
        },
        {
            subDistrict: 'นาหว้า',
            district: 'จะนะ',
            province: 'สงขลา',
            zipcode: 90130,
        },
        {
            subDistrict: 'น้ำขาว',
            district: 'จะนะ',
            province: 'สงขลา',
            zipcode: 90130,
        },
        {
            subDistrict: 'บ้านนา',
            district: 'จะนะ',
            province: 'สงขลา',
            zipcode: 90130,
        },
        {
            subDistrict: 'ป่าชิง',
            district: 'จะนะ',
            province: 'สงขลา',
            zipcode: 90130,
        },
        {
            subDistrict: 'สะกอม',
            district: 'จะนะ',
            province: 'สงขลา',
            zipcode: 90130,
        },
        {
            subDistrict: 'สะพานไม้แก่น',
            district: 'จะนะ',
            province: 'สงขลา',
            zipcode: 90130,
        },
        {
            subDistrict: 'แค',
            district: 'จะนะ',
            province: 'สงขลา',
            zipcode: 90130,
        },
        {
            subDistrict: 'คลองกวาง',
            district: 'นาทวี',
            province: 'สงขลา',
            zipcode: 90160,
        },
        {
            subDistrict: 'คลองทราย',
            district: 'นาทวี',
            province: 'สงขลา',
            zipcode: 90160,
        },
        {
            subDistrict: 'ฉาง',
            district: 'นาทวี',
            province: 'สงขลา',
            zipcode: 90160,
        },
        {
            subDistrict: 'ทับช้าง',
            district: 'นาทวี',
            province: 'สงขลา',
            zipcode: 90160,
        },
        {
            subDistrict: 'ท่าประดู่',
            district: 'นาทวี',
            province: 'สงขลา',
            zipcode: 90160,
        },
        {
            subDistrict: 'นาทวี',
            district: 'นาทวี',
            province: 'สงขลา',
            zipcode: 90160,
        },
        {
            subDistrict: 'นาหมอศรี',
            district: 'นาทวี',
            province: 'สงขลา',
            zipcode: 90160,
        },
        {
            subDistrict: 'ประกอบ',
            district: 'นาทวี',
            province: 'สงขลา',
            zipcode: 90160,
        },
        {
            subDistrict: 'ปลักหนู',
            district: 'นาทวี',
            province: 'สงขลา',
            zipcode: 90160,
        },
        {
            subDistrict: 'สะท้อน',
            district: 'นาทวี',
            province: 'สงขลา',
            zipcode: 90160,
        },
        {
            subDistrict: 'คลองหรัง',
            district: 'นาหม่อม',
            province: 'สงขลา',
            zipcode: 90310,
        },
        {
            subDistrict: 'ทุ่งขมิ้น',
            district: 'นาหม่อม',
            province: 'สงขลา',
            zipcode: 90310,
        },
        {
            subDistrict: 'นาหม่อม',
            district: 'นาหม่อม',
            province: 'สงขลา',
            zipcode: 90310,
        },
        {
            subDistrict: 'พิจิตร',
            district: 'นาหม่อม',
            province: 'สงขลา',
            zipcode: 90310,
        },
        {
            subDistrict: 'ท่าช้าง',
            district: 'บางกล่ำ',
            province: 'สงขลา',
            zipcode: 90110,
        },
        {
            subDistrict: 'บางกล่ำ',
            district: 'บางกล่ำ',
            province: 'สงขลา',
            zipcode: 90110,
        },
        {
            subDistrict: 'บ้านหาร',
            district: 'บางกล่ำ',
            province: 'สงขลา',
            zipcode: 90110,
        },
        {
            subDistrict: 'แม่ทอม',
            district: 'บางกล่ำ',
            province: 'สงขลา',
            zipcode: 90110,
        },
        {
            subDistrict: 'คลองแดน',
            district: 'ระโนด',
            province: 'สงขลา',
            zipcode: 90140,
        },
        {
            subDistrict: 'ตะเครียะ',
            district: 'ระโนด',
            province: 'สงขลา',
            zipcode: 90140,
        },
        {
            subDistrict: 'ท่าบอน',
            district: 'ระโนด',
            province: 'สงขลา',
            zipcode: 90140,
        },
        {
            subDistrict: 'บ่อตรุ',
            district: 'ระโนด',
            province: 'สงขลา',
            zipcode: 90140,
        },
        {
            subDistrict: 'บ้านขาว',
            district: 'ระโนด',
            province: 'สงขลา',
            zipcode: 90140,
        },
        {
            subDistrict: 'บ้านใหม่',
            district: 'ระโนด',
            province: 'สงขลา',
            zipcode: 90140,
        },
        {
            subDistrict: 'ปากแตระ',
            district: 'ระโนด',
            province: 'สงขลา',
            zipcode: 90140,
        },
        {
            subDistrict: 'พังยาง',
            district: 'ระโนด',
            province: 'สงขลา',
            zipcode: 90140,
        },
        {
            subDistrict: 'ระวะ',
            district: 'ระโนด',
            province: 'สงขลา',
            zipcode: 90140,
        },
        {
            subDistrict: 'ระโนด',
            district: 'ระโนด',
            province: 'สงขลา',
            zipcode: 90140,
        },
        {
            subDistrict: 'วัดสน',
            district: 'ระโนด',
            province: 'สงขลา',
            zipcode: 90140,
        },
        {
            subDistrict: 'แดนสงวน',
            district: 'ระโนด',
            province: 'สงขลา',
            zipcode: 90140,
        },
        {
            subDistrict: 'กำแพงเพชร',
            district: 'รัตภูมิ',
            province: 'สงขลา',
            zipcode: 90180,
        },
        {
            subDistrict: 'ควนรู',
            district: 'รัตภูมิ',
            province: 'สงขลา',
            zipcode: 90220,
        },
        {
            subDistrict: 'คูหาใต้',
            district: 'รัตภูมิ',
            province: 'สงขลา',
            zipcode: 90180,
        },
        {
            subDistrict: 'ท่าชะมวง',
            district: 'รัตภูมิ',
            province: 'สงขลา',
            zipcode: 90180,
        },
        {
            subDistrict: 'เขาพระ',
            district: 'รัตภูมิ',
            province: 'สงขลา',
            zipcode: 90180,
        },
        {
            subDistrict: 'กระดังงา',
            district: 'สทิงพระ',
            province: 'สงขลา',
            zipcode: 90190,
        },
        {
            subDistrict: 'คลองรี',
            district: 'สทิงพระ',
            province: 'สงขลา',
            zipcode: 90190,
        },
        {
            subDistrict: 'คูขุด',
            district: 'สทิงพระ',
            province: 'สงขลา',
            zipcode: 90190,
        },
        {
            subDistrict: 'จะทิ้งพระ',
            district: 'สทิงพระ',
            province: 'สงขลา',
            zipcode: 90190,
        },
        {
            subDistrict: 'ชุมพล',
            district: 'สทิงพระ',
            province: 'สงขลา',
            zipcode: 90190,
        },
        {
            subDistrict: 'ดีหลวง',
            district: 'สทิงพระ',
            province: 'สงขลา',
            zipcode: 90190,
        },
        {
            subDistrict: 'ท่าหิน',
            district: 'สทิงพระ',
            province: 'สงขลา',
            zipcode: 90190,
        },
        {
            subDistrict: 'บ่อดาน',
            district: 'สทิงพระ',
            province: 'สงขลา',
            zipcode: 90190,
        },
        {
            subDistrict: 'บ่อแดง',
            district: 'สทิงพระ',
            province: 'สงขลา',
            zipcode: 90190,
        },
        {
            subDistrict: 'วัดจันทร์',
            district: 'สทิงพระ',
            province: 'สงขลา',
            zipcode: 90190,
        },
        {
            subDistrict: 'สนามชัย',
            district: 'สทิงพระ',
            province: 'สงขลา',
            zipcode: 90190,
        },
        {
            subDistrict: 'คูหา',
            district: 'สะบ้าย้อย',
            province: 'สงขลา',
            zipcode: 90210,
        },
        {
            subDistrict: 'จะแหน',
            district: 'สะบ้าย้อย',
            province: 'สงขลา',
            zipcode: 90210,
        },
        {
            subDistrict: 'ทุ่งพอ',
            district: 'สะบ้าย้อย',
            province: 'สงขลา',
            zipcode: 90210,
        },
        {
            subDistrict: 'ธารคีรี',
            district: 'สะบ้าย้อย',
            province: 'สงขลา',
            zipcode: 90210,
        },
        {
            subDistrict: 'บาโหย',
            district: 'สะบ้าย้อย',
            province: 'สงขลา',
            zipcode: 90210,
        },
        {
            subDistrict: 'บ้านโหนด',
            district: 'สะบ้าย้อย',
            province: 'สงขลา',
            zipcode: 90210,
        },
        {
            subDistrict: 'สะบ้าย้อย',
            district: 'สะบ้าย้อย',
            province: 'สงขลา',
            zipcode: 90210,
        },
        {
            subDistrict: 'เขาแดง',
            district: 'สะบ้าย้อย',
            province: 'สงขลา',
            zipcode: 90210,
        },
        {
            subDistrict: 'เปียน',
            district: 'สะบ้าย้อย',
            province: 'สงขลา',
            zipcode: 90210,
        },
        {
            subDistrict: 'ทุ่งหมอ',
            district: 'สะเดา',
            province: 'สงขลา',
            zipcode: 90240,
        },
        {
            subDistrict: 'ท่าโพธิ์',
            district: 'สะเดา',
            province: 'สงขลา',
            zipcode: 90170,
        },
        {
            subDistrict: 'ปริก',
            district: 'สะเดา',
            province: 'สงขลา',
            zipcode: 90120,
        },
        {
            subDistrict: 'ปาดังเบซาร์',
            district: 'สะเดา',
            province: 'สงขลา',
            zipcode: 90240,
        },
        {
            subDistrict: 'พังลา',
            district: 'สะเดา',
            province: 'สงขลา',
            zipcode: 90170,
        },
        {
            subDistrict: 'สะเดา',
            district: 'สะเดา',
            province: 'สงขลา',
            zipcode: 90120,
        },
        {
            subDistrict: 'สำนักขาม',
            district: 'สะเดา',
            province: 'สงขลา',
            zipcode: 90320,
        },
        {
            subDistrict: 'สำนักแต้ว',
            district: 'สะเดา',
            province: 'สงขลา',
            zipcode: 90120,
        },
        {
            subDistrict: 'เขามีเกียรติ',
            district: 'สะเดา',
            province: 'สงขลา',
            zipcode: 90170,
        },
        {
            subDistrict: 'ชะแล้',
            district: 'สิงหนคร',
            province: 'สงขลา',
            zipcode: 90330,
        },
        {
            subDistrict: 'ชิงโค',
            district: 'สิงหนคร',
            province: 'สงขลา',
            zipcode: 90280,
        },
        {
            subDistrict: 'ทำนบ',
            district: 'สิงหนคร',
            province: 'สงขลา',
            zipcode: 90280,
        },
        {
            subDistrict: 'บางเขียด',
            district: 'สิงหนคร',
            province: 'สงขลา',
            zipcode: 90330,
        },
        {
            subDistrict: 'ปากรอ',
            district: 'สิงหนคร',
            province: 'สงขลา',
            zipcode: 90330,
        },
        {
            subDistrict: 'ป่าขาด',
            district: 'สิงหนคร',
            province: 'สงขลา',
            zipcode: 90330,
        },
        {
            subDistrict: 'ม่วงงาม',
            district: 'สิงหนคร',
            province: 'สงขลา',
            zipcode: 90330,
        },
        {
            subDistrict: 'รำแดง',
            district: 'สิงหนคร',
            province: 'สงขลา',
            zipcode: 90330,
        },
        {
            subDistrict: 'วัดขนุน',
            district: 'สิงหนคร',
            province: 'สงขลา',
            zipcode: 90330,
        },
        {
            subDistrict: 'สทิงหม้อ',
            district: 'สิงหนคร',
            province: 'สงขลา',
            zipcode: 90280,
        },
        {
            subDistrict: 'หัวเขา',
            district: 'สิงหนคร',
            province: 'สงขลา',
            zipcode: 90280,
        },
        {
            subDistrict: 'คลองอู่ตะเภา',
            district: 'หาดใหญ่',
            province: 'สงขลา',
            zipcode: 90110,
        },
        {
            subDistrict: 'คลองแห',
            district: 'หาดใหญ่',
            province: 'สงขลา',
            zipcode: 90110,
        },
        {
            subDistrict: 'ควนลัง',
            district: 'หาดใหญ่',
            province: 'สงขลา',
            zipcode: 90110,
        },
        {
            subDistrict: 'คอหงส์',
            district: 'หาดใหญ่',
            province: 'สงขลา',
            zipcode: 90110,
        },
        {
            subDistrict: 'คูเต่า',
            district: 'หาดใหญ่',
            province: 'สงขลา',
            zipcode: 90110,
        },
        {
            subDistrict: 'ฉลุง',
            district: 'หาดใหญ่',
            province: 'สงขลา',
            zipcode: 90110,
        },
        {
            subDistrict: 'ทุ่งตำเสา',
            district: 'หาดใหญ่',
            province: 'สงขลา',
            zipcode: 90110,
        },
        {
            subDistrict: 'ทุ่งใหญ่',
            district: 'หาดใหญ่',
            province: 'สงขลา',
            zipcode: 90110,
        },
        {
            subDistrict: 'ท่าข้าม',
            district: 'หาดใหญ่',
            province: 'สงขลา',
            zipcode: 90110,
        },
        {
            subDistrict: 'น้ำน้อย',
            district: 'หาดใหญ่',
            province: 'สงขลา',
            zipcode: 90110,
        },
        {
            subDistrict: 'บ้านพรุ',
            district: 'หาดใหญ่',
            province: 'สงขลา',
            zipcode: 90250,
        },
        {
            subDistrict: 'พะตง',
            district: 'หาดใหญ่',
            province: 'สงขลา',
            zipcode: 90230,
        },
        {
            subDistrict: 'หาดใหญ่',
            district: 'หาดใหญ่',
            province: 'สงขลา',
            zipcode: 90110,
        },
        {
            subDistrict: 'ท่าม่วง',
            district: 'เทพา',
            province: 'สงขลา',
            zipcode: 90150,
        },
        {
            subDistrict: 'ปากบาง',
            district: 'เทพา',
            province: 'สงขลา',
            zipcode: 90150,
        },
        {
            subDistrict: 'ลำไพล',
            district: 'เทพา',
            province: 'สงขลา',
            zipcode: 90260,
        },
        {
            subDistrict: 'วังใหญ่',
            district: 'เทพา',
            province: 'สงขลา',
            zipcode: 90260,
        },
        {
            subDistrict: 'สะกอม',
            district: 'เทพา',
            province: 'สงขลา',
            zipcode: 90150,
        },
        {
            subDistrict: 'เกาะสะบ้า',
            district: 'เทพา',
            province: 'สงขลา',
            zipcode: 90150,
        },
        {
            subDistrict: 'เทพา',
            district: 'เทพา',
            province: 'สงขลา',
            zipcode: 90150,
        },
        {
            subDistrict: 'ทุ่งหวัง',
            district: 'เมืองสงขลา',
            province: 'สงขลา',
            zipcode: 90000,
        },
        {
            subDistrict: 'บ่อยาง',
            district: 'เมืองสงขลา',
            province: 'สงขลา',
            zipcode: 90000,
        },
        {
            subDistrict: 'พะวง',
            district: 'เมืองสงขลา',
            province: 'สงขลา',
            zipcode: 90100,
        },
        {
            subDistrict: 'เกาะยอ',
            district: 'เมืองสงขลา',
            province: 'สงขลา',
            zipcode: 90100,
        },
        {
            subDistrict: 'เกาะแต้ว',
            district: 'เมืองสงขลา',
            province: 'สงขลา',
            zipcode: 90000,
        },
        {
            subDistrict: 'เขารูปช้าง',
            district: 'เมืองสงขลา',
            province: 'สงขลา',
            zipcode: 90000,
        },
        {
            subDistrict: 'ควนกาหลง',
            district: 'ควนกาหลง',
            province: 'สตูล',
            zipcode: 91130,
        },
        {
            subDistrict: 'ทุ่งนุ้ย',
            district: 'ควนกาหลง',
            province: 'สตูล',
            zipcode: 91130,
        },
        {
            subDistrict: 'อุไดเจริญ',
            district: 'ควนกาหลง',
            province: 'สตูล',
            zipcode: 91130,
        },
        {
            subDistrict: 'ควนสตอ',
            district: 'ควนโดน',
            province: 'สตูล',
            zipcode: 91160,
        },
        {
            subDistrict: 'ควนโดน',
            district: 'ควนโดน',
            province: 'สตูล',
            zipcode: 91160,
        },
        {
            subDistrict: 'ย่านซื่อ',
            district: 'ควนโดน',
            province: 'สตูล',
            zipcode: 91160,
        },
        {
            subDistrict: 'วังประจัน',
            district: 'ควนโดน',
            province: 'สตูล',
            zipcode: 91160,
        },
        {
            subDistrict: 'ขอนคลาน',
            district: 'ทุ่งหว้า',
            province: 'สตูล',
            zipcode: 91120,
        },
        {
            subDistrict: 'ทุ่งบุหลัง',
            district: 'ทุ่งหว้า',
            province: 'สตูล',
            zipcode: 91120,
        },
        {
            subDistrict: 'ทุ่งหว้า',
            district: 'ทุ่งหว้า',
            province: 'สตูล',
            zipcode: 91120,
        },
        {
            subDistrict: 'นาทอน',
            district: 'ทุ่งหว้า',
            province: 'สตูล',
            zipcode: 91120,
        },
        {
            subDistrict: 'ป่าแก่บ่อหิน',
            district: 'ทุ่งหว้า',
            province: 'สตูล',
            zipcode: 91120,
        },
        {
            subDistrict: 'ท่าเรือ',
            district: 'ท่าแพ',
            province: 'สตูล',
            zipcode: 91150,
        },
        {
            subDistrict: 'ท่าแพ',
            district: 'ท่าแพ',
            province: 'สตูล',
            zipcode: 91150,
        },
        {
            subDistrict: 'สาคร',
            district: 'ท่าแพ',
            province: 'สตูล',
            zipcode: 91150,
        },
        {
            subDistrict: 'แป-ระ',
            district: 'ท่าแพ',
            province: 'สตูล',
            zipcode: 91150,
        },
        {
            subDistrict: 'นิคมพัฒนา',
            district: 'มะนัง',
            province: 'สตูล',
            zipcode: 91130,
        },
        {
            subDistrict: 'ปาล์มพัฒนา',
            district: 'มะนัง',
            province: 'สตูล',
            zipcode: 91130,
        },
        {
            subDistrict: 'กำแพง',
            district: 'ละงู',
            province: 'สตูล',
            zipcode: 91110,
        },
        {
            subDistrict: 'น้ำผุด',
            district: 'ละงู',
            province: 'สตูล',
            zipcode: 91110,
        },
        {
            subDistrict: 'ปากน้ำ',
            district: 'ละงู',
            province: 'สตูล',
            zipcode: 91110,
        },
        {
            subDistrict: 'ละงู',
            district: 'ละงู',
            province: 'สตูล',
            zipcode: 91110,
        },
        {
            subDistrict: 'เขาขาว',
            district: 'ละงู',
            province: 'สตูล',
            zipcode: 91110,
        },
        {
            subDistrict: 'แหลมสน',
            district: 'ละงู',
            province: 'สตูล',
            zipcode: 91110,
        },
        {
            subDistrict: 'คลองขุด',
            district: 'เมืองสตูล',
            province: 'สตูล',
            zipcode: 91000,
        },
        {
            subDistrict: 'ควนขัน',
            district: 'เมืองสตูล',
            province: 'สตูล',
            zipcode: 91000,
        },
        {
            subDistrict: 'ควนโพธิ์',
            district: 'เมืองสตูล',
            province: 'สตูล',
            zipcode: 91140,
        },
        {
            subDistrict: 'ฉลุง',
            district: 'เมืองสตูล',
            province: 'สตูล',
            zipcode: 91140,
        },
        {
            subDistrict: 'ตันหยงโป',
            district: 'เมืองสตูล',
            province: 'สตูล',
            zipcode: 91000,
        },
        {
            subDistrict: 'ตำมะลัง',
            district: 'เมืองสตูล',
            province: 'สตูล',
            zipcode: 91000,
        },
        {
            subDistrict: 'บ้านควน',
            district: 'เมืองสตูล',
            province: 'สตูล',
            zipcode: 91140,
        },
        {
            subDistrict: 'ปูยู',
            district: 'เมืองสตูล',
            province: 'สตูล',
            zipcode: 91000,
        },
        {
            subDistrict: 'พิมาน',
            district: 'เมืองสตูล',
            province: 'สตูล',
            zipcode: 91000,
        },
        {
            subDistrict: 'เกตรี',
            district: 'เมืองสตูล',
            province: 'สตูล',
            zipcode: 91140,
        },
        {
            subDistrict: 'เกาะสาหร่าย',
            district: 'เมืองสตูล',
            province: 'สตูล',
            zipcode: 91000,
        },
        {
            subDistrict: 'เจ๊ะบิลัง',
            district: 'เมืองสตูล',
            province: 'สตูล',
            zipcode: 91000,
        },
        {
            subDistrict: 'คลองด่าน',
            district: 'บางบ่อ',
            province: 'สมุทรปราการ',
            zipcode: 10550,
        },
        {
            subDistrict: 'คลองนิยมยาตรา',
            district: 'บางบ่อ',
            province: 'สมุทรปราการ',
            zipcode: 10560,
        },
        {
            subDistrict: 'คลองสวน',
            district: 'บางบ่อ',
            province: 'สมุทรปราการ',
            zipcode: 10560,
        },
        {
            subDistrict: 'บางบ่อ',
            district: 'บางบ่อ',
            province: 'สมุทรปราการ',
            zipcode: 10560,
        },
        {
            subDistrict: 'บางพลีน้อย',
            district: 'บางบ่อ',
            province: 'สมุทรปราการ',
            zipcode: 10560,
        },
        {
            subDistrict: 'บางเพรียง',
            district: 'บางบ่อ',
            province: 'สมุทรปราการ',
            zipcode: 10560,
        },
        {
            subDistrict: 'บ้านระกาศ',
            district: 'บางบ่อ',
            province: 'สมุทรปราการ',
            zipcode: 10560,
        },
        {
            subDistrict: 'เปร็ง',
            district: 'บางบ่อ',
            province: 'สมุทรปราการ',
            zipcode: 10560,
        },
        {
            subDistrict: 'บางปลา',
            district: 'บางพลี',
            province: 'สมุทรปราการ',
            zipcode: 10540,
        },
        {
            subDistrict: 'บางพลีใหญ่',
            district: 'บางพลี',
            province: 'สมุทรปราการ',
            zipcode: 10540,
        },
        {
            subDistrict: 'บางแก้ว',
            district: 'บางพลี',
            province: 'สมุทรปราการ',
            zipcode: 10540,
        },
        {
            subDistrict: 'บางโฉลง',
            district: 'บางพลี',
            province: 'สมุทรปราการ',
            zipcode: 10540,
        },
        {
            subDistrict: 'ราชาเทวะ',
            district: 'บางพลี',
            province: 'สมุทรปราการ',
            zipcode: 10540,
        },
        {
            subDistrict: 'หนองปรือ',
            district: 'บางพลี',
            province: 'สมุทรปราการ',
            zipcode: 10540,
        },
        {
            subDistrict: 'บางเสาธง',
            district: 'บางเสาธง',
            province: 'สมุทรปราการ',
            zipcode: 10570,
        },
        {
            subDistrict: 'ศีรษะจรเข้น้อย',
            district: 'บางเสาธง',
            province: 'สมุทรปราการ',
            zipcode: 10570,
        },
        {
            subDistrict: 'ศีรษะจรเข้ใหญ่',
            district: 'บางเสาธง',
            province: 'สมุทรปราการ',
            zipcode: 10570,
        },
        {
            subDistrict: 'ตลาด',
            district: 'พระประแดง',
            province: 'สมุทรปราการ',
            zipcode: 10130,
        },
        {
            subDistrict: 'ทรงคนอง',
            district: 'พระประแดง',
            province: 'สมุทรปราการ',
            zipcode: 10130,
        },
        {
            subDistrict: 'บางกระสอบ',
            district: 'พระประแดง',
            province: 'สมุทรปราการ',
            zipcode: 10130,
        },
        {
            subDistrict: 'บางกอบัว',
            district: 'พระประแดง',
            province: 'สมุทรปราการ',
            zipcode: 10130,
        },
        {
            subDistrict: 'บางกะเจ้า',
            district: 'พระประแดง',
            province: 'สมุทรปราการ',
            zipcode: 10130,
        },
        {
            subDistrict: 'บางครุ',
            district: 'พระประแดง',
            province: 'สมุทรปราการ',
            zipcode: 10130,
        },
        {
            subDistrict: 'บางจาก',
            district: 'พระประแดง',
            province: 'สมุทรปราการ',
            zipcode: 10130,
        },
        {
            subDistrict: 'บางน้ำผึ้ง',
            district: 'พระประแดง',
            province: 'สมุทรปราการ',
            zipcode: 10130,
        },
        {
            subDistrict: 'บางพึ่ง',
            district: 'พระประแดง',
            province: 'สมุทรปราการ',
            zipcode: 10130,
        },
        {
            subDistrict: 'บางยอ',
            district: 'พระประแดง',
            province: 'สมุทรปราการ',
            zipcode: 10130,
        },
        {
            subDistrict: 'บางหญ้าแพรก',
            district: 'พระประแดง',
            province: 'สมุทรปราการ',
            zipcode: 10130,
        },
        {
            subDistrict: 'บางหัวเสือ',
            district: 'พระประแดง',
            province: 'สมุทรปราการ',
            zipcode: 10130,
        },
        {
            subDistrict: 'สำโรง',
            district: 'พระประแดง',
            province: 'สมุทรปราการ',
            zipcode: 10130,
        },
        {
            subDistrict: 'สำโรงกลาง',
            district: 'พระประแดง',
            province: 'สมุทรปราการ',
            zipcode: 10130,
        },
        {
            subDistrict: 'สำโรงใต้',
            district: 'พระประแดง',
            province: 'สมุทรปราการ',
            zipcode: 10130,
        },
        {
            subDistrict: 'นาเกลือ',
            district: 'พระสมุทรเจดีย์',
            province: 'สมุทรปราการ',
            zipcode: 10290,
        },
        {
            subDistrict: 'บ้านคลองสวน',
            district: 'พระสมุทรเจดีย์',
            province: 'สมุทรปราการ',
            zipcode: 10290,
        },
        {
            subDistrict: 'ปากคลองบางปลากด',
            district: 'พระสมุทรเจดีย์',
            province: 'สมุทรปราการ',
            zipcode: 10290,
        },
        {
            subDistrict: 'แหลมฟ้าผ่า',
            district: 'พระสมุทรเจดีย์',
            province: 'สมุทรปราการ',
            zipcode: 10290,
        },
        {
            subDistrict: 'ในคลองบางปลากด',
            district: 'พระสมุทรเจดีย์',
            province: 'สมุทรปราการ',
            zipcode: 10290,
        },
        {
            subDistrict: 'ท้ายบ้าน',
            district: 'เมืองสมุทรปราการ',
            province: 'สมุทรปราการ',
            zipcode: 10280,
        },
        {
            subDistrict: 'ท้ายบ้านใหม่',
            district: 'เมืองสมุทรปราการ',
            province: 'สมุทรปราการ',
            zipcode: 10280,
        },
        {
            subDistrict: 'บางด้วน',
            district: 'เมืองสมุทรปราการ',
            province: 'สมุทรปราการ',
            zipcode: 10270,
        },
        {
            subDistrict: 'บางปู',
            district: 'เมืองสมุทรปราการ',
            province: 'สมุทรปราการ',
            zipcode: 10280,
        },
        {
            subDistrict: 'บางปูใหม่',
            district: 'เมืองสมุทรปราการ',
            province: 'สมุทรปราการ',
            zipcode: 10280,
        },
        {
            subDistrict: 'บางเมือง',
            district: 'เมืองสมุทรปราการ',
            province: 'สมุทรปราการ',
            zipcode: 10270,
        },
        {
            subDistrict: 'บางเมืองใหม่',
            district: 'เมืองสมุทรปราการ',
            province: 'สมุทรปราการ',
            zipcode: 10270,
        },
        {
            subDistrict: 'บางโปรง',
            district: 'เมืองสมุทรปราการ',
            province: 'สมุทรปราการ',
            zipcode: 10270,
        },
        {
            subDistrict: 'ปากน้ำ',
            district: 'เมืองสมุทรปราการ',
            province: 'สมุทรปราการ',
            zipcode: 10270,
        },
        {
            subDistrict: 'สำโรงเหนือ',
            district: 'เมืองสมุทรปราการ',
            province: 'สมุทรปราการ',
            zipcode: 10270,
        },
        {
            subDistrict: 'เทพารักษ์',
            district: 'เมืองสมุทรปราการ',
            province: 'สมุทรปราการ',
            zipcode: 10270,
        },
        {
            subDistrict: 'แพรกษา',
            district: 'เมืองสมุทรปราการ',
            province: 'สมุทรปราการ',
            zipcode: 10280,
        },
        {
            subDistrict: 'แพรกษาใหม่',
            district: 'เมืองสมุทรปราการ',
            province: 'สมุทรปราการ',
            zipcode: 10280,
        },
        {
            subDistrict: 'กระดังงา',
            district: 'บางคนที',
            province: 'สมุทรสงคราม',
            zipcode: 75120,
        },
        {
            subDistrict: 'จอมปลวก',
            district: 'บางคนที',
            province: 'สมุทรสงคราม',
            zipcode: 75120,
        },
        {
            subDistrict: 'ดอนมะโนรา',
            district: 'บางคนที',
            province: 'สมุทรสงคราม',
            zipcode: 75120,
        },
        {
            subDistrict: 'บางกระบือ',
            district: 'บางคนที',
            province: 'สมุทรสงคราม',
            zipcode: 75120,
        },
        {
            subDistrict: 'บางกุ้ง',
            district: 'บางคนที',
            province: 'สมุทรสงคราม',
            zipcode: 75120,
        },
        {
            subDistrict: 'บางคนที',
            district: 'บางคนที',
            province: 'สมุทรสงคราม',
            zipcode: 75120,
        },
        {
            subDistrict: 'บางนกแขวก',
            district: 'บางคนที',
            province: 'สมุทรสงคราม',
            zipcode: 75120,
        },
        {
            subDistrict: 'บางพรม',
            district: 'บางคนที',
            province: 'สมุทรสงคราม',
            zipcode: 75120,
        },
        {
            subDistrict: 'บางยี่รงค์',
            district: 'บางคนที',
            province: 'สมุทรสงคราม',
            zipcode: 75120,
        },
        {
            subDistrict: 'บางสะแก',
            district: 'บางคนที',
            province: 'สมุทรสงคราม',
            zipcode: 75120,
        },
        {
            subDistrict: 'บ้านปราโมทย์',
            district: 'บางคนที',
            province: 'สมุทรสงคราม',
            zipcode: 75120,
        },
        {
            subDistrict: 'ยายแพง',
            district: 'บางคนที',
            province: 'สมุทรสงคราม',
            zipcode: 75120,
        },
        {
            subDistrict: 'โรงหีบ',
            district: 'บางคนที',
            province: 'สมุทรสงคราม',
            zipcode: 75120,
        },
        {
            subDistrict: 'ท่าคา',
            district: 'อัมพวา',
            province: 'สมุทรสงคราม',
            zipcode: 75110,
        },
        {
            subDistrict: 'บางช้าง',
            district: 'อัมพวา',
            province: 'สมุทรสงคราม',
            zipcode: 75110,
        },
        {
            subDistrict: 'บางนางลี่',
            district: 'อัมพวา',
            province: 'สมุทรสงคราม',
            zipcode: 75110,
        },
        {
            subDistrict: 'บางแค',
            district: 'อัมพวา',
            province: 'สมุทรสงคราม',
            zipcode: 75110,
        },
        {
            subDistrict: 'ปลายโพงพาง',
            district: 'อัมพวา',
            province: 'สมุทรสงคราม',
            zipcode: 75110,
        },
        {
            subDistrict: 'ยี่สาร',
            district: 'อัมพวา',
            province: 'สมุทรสงคราม',
            zipcode: 75110,
        },
        {
            subDistrict: 'วัดประดู่',
            district: 'อัมพวา',
            province: 'สมุทรสงคราม',
            zipcode: 75110,
        },
        {
            subDistrict: 'สวนหลวง',
            district: 'อัมพวา',
            province: 'สมุทรสงคราม',
            zipcode: 75110,
        },
        {
            subDistrict: 'อัมพวา',
            district: 'อัมพวา',
            province: 'สมุทรสงคราม',
            zipcode: 75110,
        },
        {
            subDistrict: 'เหมืองใหม่',
            district: 'อัมพวา',
            province: 'สมุทรสงคราม',
            zipcode: 75110,
        },
        {
            subDistrict: 'แควอ้อม',
            district: 'อัมพวา',
            province: 'สมุทรสงคราม',
            zipcode: 75110,
        },
        {
            subDistrict: 'แพรกหนามแดง',
            district: 'อัมพวา',
            province: 'สมุทรสงคราม',
            zipcode: 75110,
        },
        {
            subDistrict: 'คลองเขิน',
            district: 'เมืองสมุทรสงคราม',
            province: 'สมุทรสงคราม',
            zipcode: 75000,
        },
        {
            subDistrict: 'คลองโคน',
            district: 'เมืองสมุทรสงคราม',
            province: 'สมุทรสงคราม',
            zipcode: 75000,
        },
        {
            subDistrict: 'ท้ายหาด',
            district: 'เมืองสมุทรสงคราม',
            province: 'สมุทรสงคราม',
            zipcode: 75000,
        },
        {
            subDistrict: 'นางตะเคียน',
            district: 'เมืองสมุทรสงคราม',
            province: 'สมุทรสงคราม',
            zipcode: 75000,
        },
        {
            subDistrict: 'บางขันแตก',
            district: 'เมืองสมุทรสงคราม',
            province: 'สมุทรสงคราม',
            zipcode: 75000,
        },
        {
            subDistrict: 'บางจะเกร็ง',
            district: 'เมืองสมุทรสงคราม',
            province: 'สมุทรสงคราม',
            zipcode: 75000,
        },
        {
            subDistrict: 'บางแก้ว',
            district: 'เมืองสมุทรสงคราม',
            province: 'สมุทรสงคราม',
            zipcode: 75000,
        },
        {
            subDistrict: 'บ้านปรก',
            district: 'เมืองสมุทรสงคราม',
            province: 'สมุทรสงคราม',
            zipcode: 75000,
        },
        {
            subDistrict: 'ลาดใหญ่',
            district: 'เมืองสมุทรสงคราม',
            province: 'สมุทรสงคราม',
            zipcode: 75000,
        },
        {
            subDistrict: 'แม่กลอง',
            district: 'เมืองสมุทรสงคราม',
            province: 'สมุทรสงคราม',
            zipcode: 75000,
        },
        {
            subDistrict: 'แหลมใหญ่',
            district: 'เมืองสมุทรสงคราม',
            province: 'สมุทรสงคราม',
            zipcode: 75000,
        },
        {
            subDistrict: 'คลองมะเดื่อ',
            district: 'กระทุ่มแบน',
            province: 'สมุทรสาคร',
            zipcode: 74110,
        },
        {
            subDistrict: 'ดอนไก่ดี',
            district: 'กระทุ่มแบน',
            province: 'สมุทรสาคร',
            zipcode: 74110,
        },
        {
            subDistrict: 'ตลาดกระทุ่มแบน',
            district: 'กระทุ่มแบน',
            province: 'สมุทรสาคร',
            zipcode: 74110,
        },
        {
            subDistrict: 'ท่าเสา',
            district: 'กระทุ่มแบน',
            province: 'สมุทรสาคร',
            zipcode: 74110,
        },
        {
            subDistrict: 'ท่าไม้',
            district: 'กระทุ่มแบน',
            province: 'สมุทรสาคร',
            zipcode: 74110,
        },
        {
            subDistrict: 'บางยาง',
            district: 'กระทุ่มแบน',
            province: 'สมุทรสาคร',
            zipcode: 74110,
        },
        {
            subDistrict: 'สวนหลวง',
            district: 'กระทุ่มแบน',
            province: 'สมุทรสาคร',
            zipcode: 74110,
        },
        {
            subDistrict: 'หนองนกไข่',
            district: 'กระทุ่มแบน',
            province: 'สมุทรสาคร',
            zipcode: 74110,
        },
        {
            subDistrict: 'อ้อมน้อย',
            district: 'กระทุ่มแบน',
            province: 'สมุทรสาคร',
            zipcode: 74130,
        },
        {
            subDistrict: 'แคราย',
            district: 'กระทุ่มแบน',
            province: 'สมุทรสาคร',
            zipcode: 74110,
        },
        {
            subDistrict: 'คลองตัน',
            district: 'บ้านแพ้ว',
            province: 'สมุทรสาคร',
            zipcode: 74120,
        },
        {
            subDistrict: 'บ้านแพ้ว',
            district: 'บ้านแพ้ว',
            province: 'สมุทรสาคร',
            zipcode: 74120,
        },
        {
            subDistrict: 'ยกกระบัตร',
            district: 'บ้านแพ้ว',
            province: 'สมุทรสาคร',
            zipcode: 74120,
        },
        {
            subDistrict: 'สวนส้ม',
            district: 'บ้านแพ้ว',
            province: 'สมุทรสาคร',
            zipcode: 74120,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'บ้านแพ้ว',
            province: 'สมุทรสาคร',
            zipcode: 74120,
        },
        {
            subDistrict: 'หนองสองห้อง',
            district: 'บ้านแพ้ว',
            province: 'สมุทรสาคร',
            zipcode: 74120,
        },
        {
            subDistrict: 'หลักสอง',
            district: 'บ้านแพ้ว',
            province: 'สมุทรสาคร',
            zipcode: 74120,
        },
        {
            subDistrict: 'หลักสาม',
            district: 'บ้านแพ้ว',
            province: 'สมุทรสาคร',
            zipcode: 74120,
        },
        {
            subDistrict: 'อำแพง',
            district: 'บ้านแพ้ว',
            province: 'สมุทรสาคร',
            zipcode: 74120,
        },
        {
            subDistrict: 'เกษตรพัฒนา',
            district: 'บ้านแพ้ว',
            province: 'สมุทรสาคร',
            zipcode: 74120,
        },
        {
            subDistrict: 'เจ็ดริ้ว',
            district: 'บ้านแพ้ว',
            province: 'สมุทรสาคร',
            zipcode: 74120,
        },
        {
            subDistrict: 'โรงเข้',
            district: 'บ้านแพ้ว',
            province: 'สมุทรสาคร',
            zipcode: 74120,
        },
        {
            subDistrict: 'กาหลง',
            district: 'เมืองสมุทรสาคร',
            province: 'สมุทรสาคร',
            zipcode: 74000,
        },
        {
            subDistrict: 'คอกกระบือ',
            district: 'เมืองสมุทรสาคร',
            province: 'สมุทรสาคร',
            zipcode: 74000,
        },
        {
            subDistrict: 'ชัยมงคล',
            district: 'เมืองสมุทรสาคร',
            province: 'สมุทรสาคร',
            zipcode: 74000,
        },
        {
            subDistrict: 'ท่าจีน',
            district: 'เมืองสมุทรสาคร',
            province: 'สมุทรสาคร',
            zipcode: 74000,
        },
        {
            subDistrict: 'ท่าฉลอม',
            district: 'เมืองสมุทรสาคร',
            province: 'สมุทรสาคร',
            zipcode: 74000,
        },
        {
            subDistrict: 'ท่าทราย',
            district: 'เมืองสมุทรสาคร',
            province: 'สมุทรสาคร',
            zipcode: 74000,
        },
        {
            subDistrict: 'นาดี',
            district: 'เมืองสมุทรสาคร',
            province: 'สมุทรสาคร',
            zipcode: 74000,
        },
        {
            subDistrict: 'นาโคก',
            district: 'เมืองสมุทรสาคร',
            province: 'สมุทรสาคร',
            zipcode: 74000,
        },
        {
            subDistrict: 'บางกระเจ้า',
            district: 'เมืองสมุทรสาคร',
            province: 'สมุทรสาคร',
            zipcode: 74000,
        },
        {
            subDistrict: 'บางน้ำจืด',
            district: 'เมืองสมุทรสาคร',
            province: 'สมุทรสาคร',
            zipcode: 74000,
        },
        {
            subDistrict: 'บางหญ้าแพรก',
            district: 'เมืองสมุทรสาคร',
            province: 'สมุทรสาคร',
            zipcode: 74000,
        },
        {
            subDistrict: 'บางโทรัด',
            district: 'เมืองสมุทรสาคร',
            province: 'สมุทรสาคร',
            zipcode: 74000,
        },
        {
            subDistrict: 'บ้านบ่อ',
            district: 'เมืองสมุทรสาคร',
            province: 'สมุทรสาคร',
            zipcode: 74000,
        },
        {
            subDistrict: 'บ้านเกาะ',
            district: 'เมืองสมุทรสาคร',
            province: 'สมุทรสาคร',
            zipcode: 74000,
        },
        {
            subDistrict: 'พันท้ายนรสิงห์',
            district: 'เมืองสมุทรสาคร',
            province: 'สมุทรสาคร',
            zipcode: 74000,
        },
        {
            subDistrict: 'มหาชัย',
            district: 'เมืองสมุทรสาคร',
            province: 'สมุทรสาคร',
            zipcode: 74000,
        },
        {
            subDistrict: 'โกรกกราก',
            district: 'เมืองสมุทรสาคร',
            province: 'สมุทรสาคร',
            zipcode: 74000,
        },
        {
            subDistrict: 'โคกขาม',
            district: 'เมืองสมุทรสาคร',
            province: 'สมุทรสาคร',
            zipcode: 74000,
        },
        {
            subDistrict: 'ดงตะงาว',
            district: 'ดอนพุด',
            province: 'สระบุรี',
            zipcode: 18210,
        },
        {
            subDistrict: 'ดอนพุด',
            district: 'ดอนพุด',
            province: 'สระบุรี',
            zipcode: 18210,
        },
        {
            subDistrict: 'บ้านหลวง',
            district: 'ดอนพุด',
            province: 'สระบุรี',
            zipcode: 18210,
        },
        {
            subDistrict: 'ไผ่หลิ่ว',
            district: 'ดอนพุด',
            province: 'สระบุรี',
            zipcode: 18210,
        },
        {
            subDistrict: 'ตลาดน้อย',
            district: 'บ้านหมอ',
            province: 'สระบุรี',
            zipcode: 18130,
        },
        {
            subDistrict: 'บางโขมด',
            district: 'บ้านหมอ',
            province: 'สระบุรี',
            zipcode: 18130,
        },
        {
            subDistrict: 'บ้านครัว',
            district: 'บ้านหมอ',
            province: 'สระบุรี',
            zipcode: 18270,
        },
        {
            subDistrict: 'บ้านหมอ',
            district: 'บ้านหมอ',
            province: 'สระบุรี',
            zipcode: 18130,
        },
        {
            subDistrict: 'สร่างโศก',
            district: 'บ้านหมอ',
            province: 'สระบุรี',
            zipcode: 18130,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'บ้านหมอ',
            province: 'สระบุรี',
            zipcode: 18130,
        },
        {
            subDistrict: 'หรเทพ',
            district: 'บ้านหมอ',
            province: 'สระบุรี',
            zipcode: 18130,
        },
        {
            subDistrict: 'โคกใหญ่',
            district: 'บ้านหมอ',
            province: 'สระบุรี',
            zipcode: 18130,
        },
        {
            subDistrict: 'ไผ่ขวาง',
            district: 'บ้านหมอ',
            province: 'สระบุรี',
            zipcode: 18130,
        },
        {
            subDistrict: 'ขุนโขลน',
            district: 'พระพุทธบาท',
            province: 'สระบุรี',
            zipcode: 18120,
        },
        {
            subDistrict: 'ธารเกษม',
            district: 'พระพุทธบาท',
            province: 'สระบุรี',
            zipcode: 18120,
        },
        {
            subDistrict: 'นายาว',
            district: 'พระพุทธบาท',
            province: 'สระบุรี',
            zipcode: 18120,
        },
        {
            subDistrict: 'พระพุทธบาท',
            district: 'พระพุทธบาท',
            province: 'สระบุรี',
            zipcode: 18120,
        },
        {
            subDistrict: 'พุกร่าง',
            district: 'พระพุทธบาท',
            province: 'สระบุรี',
            zipcode: 18120,
        },
        {
            subDistrict: 'พุคำจาน',
            district: 'พระพุทธบาท',
            province: 'สระบุรี',
            zipcode: 18120,
        },
        {
            subDistrict: 'หนองแก',
            district: 'พระพุทธบาท',
            province: 'สระบุรี',
            zipcode: 18120,
        },
        {
            subDistrict: 'ห้วยป่าหวาย',
            district: 'พระพุทธบาท',
            province: 'สระบุรี',
            zipcode: 18120,
        },
        {
            subDistrict: 'เขาวง',
            district: 'พระพุทธบาท',
            province: 'สระบุรี',
            zipcode: 18120,
        },
        {
            subDistrict: 'ซับสนุ่น',
            district: 'มวกเหล็ก',
            province: 'สระบุรี',
            zipcode: 18220,
        },
        {
            subDistrict: 'มวกเหล็ก',
            district: 'มวกเหล็ก',
            province: 'สระบุรี',
            zipcode: 18180,
        },
        {
            subDistrict: 'มิตรภาพ',
            district: 'มวกเหล็ก',
            province: 'สระบุรี',
            zipcode: 18180,
        },
        {
            subDistrict: 'ลำพญากลาง',
            district: 'มวกเหล็ก',
            province: 'สระบุรี',
            zipcode: 30130,
        },
        {
            subDistrict: 'ลำสมพุง',
            district: 'มวกเหล็ก',
            province: 'สระบุรี',
            zipcode: 30130,
        },
        {
            subDistrict: 'หนองย่างเสือ',
            district: 'มวกเหล็ก',
            province: 'สระบุรี',
            zipcode: 18180,
        },
        {
            subDistrict: 'คำพราน',
            district: 'วังม่วง',
            province: 'สระบุรี',
            zipcode: 18220,
        },
        {
            subDistrict: 'วังม่วง',
            district: 'วังม่วง',
            province: 'สระบุรี',
            zipcode: 18220,
        },
        {
            subDistrict: 'แสลงพัน',
            district: 'วังม่วง',
            province: 'สระบุรี',
            zipcode: 18220,
        },
        {
            subDistrict: 'คลองเรือ',
            district: 'วิหารแดง',
            province: 'สระบุรี',
            zipcode: 18150,
        },
        {
            subDistrict: 'บ้านลำ',
            district: 'วิหารแดง',
            province: 'สระบุรี',
            zipcode: 18150,
        },
        {
            subDistrict: 'วิหารแดง',
            district: 'วิหารแดง',
            province: 'สระบุรี',
            zipcode: 18150,
        },
        {
            subDistrict: 'หนองสรวง',
            district: 'วิหารแดง',
            province: 'สระบุรี',
            zipcode: 18150,
        },
        {
            subDistrict: 'หนองหมู',
            district: 'วิหารแดง',
            province: 'สระบุรี',
            zipcode: 18150,
        },
        {
            subDistrict: 'เจริญธรรม',
            district: 'วิหารแดง',
            province: 'สระบุรี',
            zipcode: 18150,
        },
        {
            subDistrict: 'กุ่มหัก',
            district: 'หนองแค',
            province: 'สระบุรี',
            zipcode: 18140,
        },
        {
            subDistrict: 'คชสิทธิ์',
            district: 'หนองแค',
            province: 'สระบุรี',
            zipcode: 18250,
        },
        {
            subDistrict: 'บัวลอย',
            district: 'หนองแค',
            province: 'สระบุรี',
            zipcode: 18230,
        },
        {
            subDistrict: 'หนองจรเข้',
            district: 'หนองแค',
            province: 'สระบุรี',
            zipcode: 18140,
        },
        {
            subDistrict: 'หนองจิก',
            district: 'หนองแค',
            province: 'สระบุรี',
            zipcode: 18230,
        },
        {
            subDistrict: 'หนองนาก',
            district: 'หนองแค',
            province: 'สระบุรี',
            zipcode: 18230,
        },
        {
            subDistrict: 'หนองปลาหมอ',
            district: 'หนองแค',
            province: 'สระบุรี',
            zipcode: 18140,
        },
        {
            subDistrict: 'หนองปลิง',
            district: 'หนองแค',
            province: 'สระบุรี',
            zipcode: 18140,
        },
        {
            subDistrict: 'หนองแขม',
            district: 'หนองแค',
            province: 'สระบุรี',
            zipcode: 18140,
        },
        {
            subDistrict: 'หนองแค',
            district: 'หนองแค',
            province: 'สระบุรี',
            zipcode: 18140,
        },
        {
            subDistrict: 'หนองโรง',
            district: 'หนองแค',
            province: 'สระบุรี',
            zipcode: 18140,
        },
        {
            subDistrict: 'หนองไข่น้ำ',
            district: 'หนองแค',
            province: 'สระบุรี',
            zipcode: 18140,
        },
        {
            subDistrict: 'ห้วยขมิ้น',
            district: 'หนองแค',
            province: 'สระบุรี',
            zipcode: 18230,
        },
        {
            subDistrict: 'ห้วยทราย',
            district: 'หนองแค',
            province: 'สระบุรี',
            zipcode: 18230,
        },
        {
            subDistrict: 'โคกตูม',
            district: 'หนองแค',
            province: 'สระบุรี',
            zipcode: 18250,
        },
        {
            subDistrict: 'โคกแย้',
            district: 'หนองแค',
            province: 'สระบุรี',
            zipcode: 18230,
        },
        {
            subDistrict: 'โพนทอง',
            district: 'หนองแค',
            province: 'สระบุรี',
            zipcode: 18250,
        },
        {
            subDistrict: 'ไผ่ต่ำ',
            district: 'หนองแค',
            province: 'สระบุรี',
            zipcode: 18140,
        },
        {
            subDistrict: 'ม่วงหวาน',
            district: 'หนองแซง',
            province: 'สระบุรี',
            zipcode: 18170,
        },
        {
            subDistrict: 'หนองกบ',
            district: 'หนองแซง',
            province: 'สระบุรี',
            zipcode: 18170,
        },
        {
            subDistrict: 'หนองควายโซ',
            district: 'หนองแซง',
            province: 'สระบุรี',
            zipcode: 18170,
        },
        {
            subDistrict: 'หนองสีดา',
            district: 'หนองแซง',
            province: 'สระบุรี',
            zipcode: 18170,
        },
        {
            subDistrict: 'หนองหัวโพ',
            district: 'หนองแซง',
            province: 'สระบุรี',
            zipcode: 18170,
        },
        {
            subDistrict: 'หนองแซง',
            district: 'หนองแซง',
            province: 'สระบุรี',
            zipcode: 18170,
        },
        {
            subDistrict: 'เขาดิน',
            district: 'หนองแซง',
            province: 'สระบุรี',
            zipcode: 18170,
        },
        {
            subDistrict: 'โคกสะอาด',
            district: 'หนองแซง',
            province: 'สระบุรี',
            zipcode: 18170,
        },
        {
            subDistrict: 'ไก่เส่า',
            district: 'หนองแซง',
            province: 'สระบุรี',
            zipcode: 18170,
        },
        {
            subDistrict: 'ดอนทอง',
            district: 'หนองโดน',
            province: 'สระบุรี',
            zipcode: 18190,
        },
        {
            subDistrict: 'บ้านกลับ',
            district: 'หนองโดน',
            province: 'สระบุรี',
            zipcode: 18190,
        },
        {
            subDistrict: 'บ้านโปร่ง',
            district: 'หนองโดน',
            province: 'สระบุรี',
            zipcode: 18190,
        },
        {
            subDistrict: 'หนองโดน',
            district: 'หนองโดน',
            province: 'สระบุรี',
            zipcode: 18190,
        },
        {
            subDistrict: 'บ้านแก้ง',
            district: 'เฉลิมพระเกียรติ',
            province: 'สระบุรี',
            zipcode: 18000,
        },
        {
            subDistrict: 'ผึ้งรวง',
            district: 'เฉลิมพระเกียรติ',
            province: 'สระบุรี',
            zipcode: 18000,
        },
        {
            subDistrict: 'พุแค',
            district: 'เฉลิมพระเกียรติ',
            province: 'สระบุรี',
            zipcode: 18240,
        },
        {
            subDistrict: 'หน้าพระลาน',
            district: 'เฉลิมพระเกียรติ',
            province: 'สระบุรี',
            zipcode: 18240,
        },
        {
            subDistrict: 'ห้วยบง',
            district: 'เฉลิมพระเกียรติ',
            province: 'สระบุรี',
            zipcode: 18000,
        },
        {
            subDistrict: 'เขาดินพัฒนา',
            district: 'เฉลิมพระเกียรติ',
            province: 'สระบุรี',
            zipcode: 18000,
        },
        {
            subDistrict: 'กุดนกเปล้า',
            district: 'เมืองสระบุรี',
            province: 'สระบุรี',
            zipcode: 18000,
        },
        {
            subDistrict: 'ดาวเรือง',
            district: 'เมืองสระบุรี',
            province: 'สระบุรี',
            zipcode: 18000,
        },
        {
            subDistrict: 'ตลิ่งชัน',
            district: 'เมืองสระบุรี',
            province: 'สระบุรี',
            zipcode: 18000,
        },
        {
            subDistrict: 'ตะกุด',
            district: 'เมืองสระบุรี',
            province: 'สระบุรี',
            zipcode: 18000,
        },
        {
            subDistrict: 'นาโฉง',
            district: 'เมืองสระบุรี',
            province: 'สระบุรี',
            zipcode: 18000,
        },
        {
            subDistrict: 'ปากข้าวสาร',
            district: 'เมืองสระบุรี',
            province: 'สระบุรี',
            zipcode: 18000,
        },
        {
            subDistrict: 'ปากเพรียว',
            district: 'เมืองสระบุรี',
            province: 'สระบุรี',
            zipcode: 18000,
        },
        {
            subDistrict: 'หนองปลาไหล',
            district: 'เมืองสระบุรี',
            province: 'สระบุรี',
            zipcode: 18000,
        },
        {
            subDistrict: 'หนองยาว',
            district: 'เมืองสระบุรี',
            province: 'สระบุรี',
            zipcode: 18000,
        },
        {
            subDistrict: 'หนองโน',
            district: 'เมืองสระบุรี',
            province: 'สระบุรี',
            zipcode: 18000,
        },
        {
            subDistrict: 'โคกสว่าง',
            district: 'เมืองสระบุรี',
            province: 'สระบุรี',
            zipcode: 18000,
        },
        {
            subDistrict: 'งิ้วงาม',
            district: 'เสาไห้',
            province: 'สระบุรี',
            zipcode: 18160,
        },
        {
            subDistrict: 'ต้นตาล',
            district: 'เสาไห้',
            province: 'สระบุรี',
            zipcode: 18160,
        },
        {
            subDistrict: 'ท่าช้าง',
            district: 'เสาไห้',
            province: 'สระบุรี',
            zipcode: 18160,
        },
        {
            subDistrict: 'บ้านยาง',
            district: 'เสาไห้',
            province: 'สระบุรี',
            zipcode: 18160,
        },
        {
            subDistrict: 'พระยาทด',
            district: 'เสาไห้',
            province: 'สระบุรี',
            zipcode: 18160,
        },
        {
            subDistrict: 'ม่วงงาม',
            district: 'เสาไห้',
            province: 'สระบุรี',
            zipcode: 18160,
        },
        {
            subDistrict: 'ศาลารีไทย',
            district: 'เสาไห้',
            province: 'สระบุรี',
            zipcode: 18160,
        },
        {
            subDistrict: 'สวนดอกไม้',
            district: 'เสาไห้',
            province: 'สระบุรี',
            zipcode: 18160,
        },
        {
            subDistrict: 'หัวปลวก',
            district: 'เสาไห้',
            province: 'สระบุรี',
            zipcode: 18160,
        },
        {
            subDistrict: 'เมืองเก่า',
            district: 'เสาไห้',
            province: 'สระบุรี',
            zipcode: 18160,
        },
        {
            subDistrict: 'เริงราง',
            district: 'เสาไห้',
            province: 'สระบุรี',
            zipcode: 18160,
        },
        {
            subDistrict: 'เสาไห้',
            district: 'เสาไห้',
            province: 'สระบุรี',
            zipcode: 18160,
        },
        {
            subDistrict: 'ชะอม',
            district: 'แก่งคอย',
            province: 'สระบุรี',
            zipcode: 18110,
        },
        {
            subDistrict: 'ชำผักแพว',
            district: 'แก่งคอย',
            province: 'สระบุรี',
            zipcode: 18110,
        },
        {
            subDistrict: 'ตาลเดี่ยว',
            district: 'แก่งคอย',
            province: 'สระบุรี',
            zipcode: 18110,
        },
        {
            subDistrict: 'ทับกวาง',
            district: 'แก่งคอย',
            province: 'สระบุรี',
            zipcode: 18260,
        },
        {
            subDistrict: 'ท่าคล้อ',
            district: 'แก่งคอย',
            province: 'สระบุรี',
            zipcode: 18110,
        },
        {
            subDistrict: 'ท่าตูม',
            district: 'แก่งคอย',
            province: 'สระบุรี',
            zipcode: 18110,
        },
        {
            subDistrict: 'ท่ามะปราง',
            district: 'แก่งคอย',
            province: 'สระบุรี',
            zipcode: 18110,
        },
        {
            subDistrict: 'บ้านธาตุ',
            district: 'แก่งคอย',
            province: 'สระบุรี',
            zipcode: 18110,
        },
        {
            subDistrict: 'บ้านป่า',
            district: 'แก่งคอย',
            province: 'สระบุรี',
            zipcode: 18110,
        },
        {
            subDistrict: 'สองคอน',
            district: 'แก่งคอย',
            province: 'สระบุรี',
            zipcode: 18110,
        },
        {
            subDistrict: 'หินซ้อน',
            district: 'แก่งคอย',
            province: 'สระบุรี',
            zipcode: 18110,
        },
        {
            subDistrict: 'ห้วยแห้ง',
            district: 'แก่งคอย',
            province: 'สระบุรี',
            zipcode: 18110,
        },
        {
            subDistrict: 'เตาปูน',
            district: 'แก่งคอย',
            province: 'สระบุรี',
            zipcode: 18110,
        },
        {
            subDistrict: 'แก่งคอย',
            district: 'แก่งคอย',
            province: 'สระบุรี',
            zipcode: 18110,
        },
        {
            subDistrict: 'คลองหาด',
            district: 'คลองหาด',
            province: 'สระแก้ว',
            zipcode: 27260,
        },
        {
            subDistrict: 'คลองไก่เถื่อน',
            district: 'คลองหาด',
            province: 'สระแก้ว',
            zipcode: 27260,
        },
        {
            subDistrict: 'ซับมะกรูด',
            district: 'คลองหาด',
            province: 'สระแก้ว',
            zipcode: 27260,
        },
        {
            subDistrict: 'เบญจขร',
            district: 'คลองหาด',
            province: 'สระแก้ว',
            zipcode: 27260,
        },
        {
            subDistrict: 'ไทยอุดม',
            district: 'คลองหาด',
            province: 'สระแก้ว',
            zipcode: 27260,
        },
        {
            subDistrict: 'ไทรทอง',
            district: 'คลองหาด',
            province: 'สระแก้ว',
            zipcode: 27260,
        },
        {
            subDistrict: 'ไทรเดี่ยว',
            district: 'คลองหาด',
            province: 'สระแก้ว',
            zipcode: 27260,
        },
        {
            subDistrict: 'ตาพระยา',
            district: 'ตาพระยา',
            province: 'สระแก้ว',
            zipcode: 27180,
        },
        {
            subDistrict: 'ทัพราช',
            district: 'ตาพระยา',
            province: 'สระแก้ว',
            zipcode: 27180,
        },
        {
            subDistrict: 'ทัพเสด็จ',
            district: 'ตาพระยา',
            province: 'สระแก้ว',
            zipcode: 27180,
        },
        {
            subDistrict: 'ทัพไทย',
            district: 'ตาพระยา',
            province: 'สระแก้ว',
            zipcode: 27180,
        },
        {
            subDistrict: 'โคคลาน',
            district: 'ตาพระยา',
            province: 'สระแก้ว',
            zipcode: 27180,
        },
        {
            subDistrict: 'คลองหินปูน',
            district: 'วังน้ำเย็น',
            province: 'สระแก้ว',
            zipcode: 27210,
        },
        {
            subDistrict: 'ตาหลังใน',
            district: 'วังน้ำเย็น',
            province: 'สระแก้ว',
            zipcode: 27210,
        },
        {
            subDistrict: 'ทุ่งมหาเจริญ',
            district: 'วังน้ำเย็น',
            province: 'สระแก้ว',
            zipcode: 27210,
        },
        {
            subDistrict: 'วังน้ำเย็น',
            district: 'วังน้ำเย็น',
            province: 'สระแก้ว',
            zipcode: 27210,
        },
        {
            subDistrict: 'วังทอง',
            district: 'วังสมบูรณ์',
            province: 'สระแก้ว',
            zipcode: 27250,
        },
        {
            subDistrict: 'วังสมบูรณ์',
            district: 'วังสมบูรณ์',
            province: 'สระแก้ว',
            zipcode: 27250,
        },
        {
            subDistrict: 'วังใหม่',
            district: 'วังสมบูรณ์',
            province: 'สระแก้ว',
            zipcode: 27250,
        },
        {
            subDistrict: 'ช่องกุ่ม',
            district: 'วัฒนานคร',
            province: 'สระแก้ว',
            zipcode: 27160,
        },
        {
            subDistrict: 'ท่าเกวียน',
            district: 'วัฒนานคร',
            province: 'สระแก้ว',
            zipcode: 27160,
        },
        {
            subDistrict: 'ผักขะ',
            district: 'วัฒนานคร',
            province: 'สระแก้ว',
            zipcode: 27160,
        },
        {
            subDistrict: 'วัฒนานคร',
            district: 'วัฒนานคร',
            province: 'สระแก้ว',
            zipcode: 27160,
        },
        {
            subDistrict: 'หนองตะเคียนบอน',
            district: 'วัฒนานคร',
            province: 'สระแก้ว',
            zipcode: 27160,
        },
        {
            subDistrict: 'หนองน้ำใส',
            district: 'วัฒนานคร',
            province: 'สระแก้ว',
            zipcode: 27160,
        },
        {
            subDistrict: 'หนองหมากฝ้าย',
            district: 'วัฒนานคร',
            province: 'สระแก้ว',
            zipcode: 27160,
        },
        {
            subDistrict: 'หนองแวง',
            district: 'วัฒนานคร',
            province: 'สระแก้ว',
            zipcode: 27160,
        },
        {
            subDistrict: 'ห้วยโจด',
            district: 'วัฒนานคร',
            province: 'สระแก้ว',
            zipcode: 27160,
        },
        {
            subDistrict: 'แซร์ออ',
            district: 'วัฒนานคร',
            province: 'สระแก้ว',
            zipcode: 27160,
        },
        {
            subDistrict: 'โนนหมากเค็ง',
            district: 'วัฒนานคร',
            province: 'สระแก้ว',
            zipcode: 27160,
        },
        {
            subDistrict: 'คลองทับจันทร์',
            district: 'อรัญประเทศ',
            province: 'สระแก้ว',
            zipcode: 27120,
        },
        {
            subDistrict: 'คลองน้ำใส',
            district: 'อรัญประเทศ',
            province: 'สระแก้ว',
            zipcode: 27120,
        },
        {
            subDistrict: 'ทับพริก',
            district: 'อรัญประเทศ',
            province: 'สระแก้ว',
            zipcode: 27120,
        },
        {
            subDistrict: 'ท่าข้าม',
            district: 'อรัญประเทศ',
            province: 'สระแก้ว',
            zipcode: 27120,
        },
        {
            subDistrict: 'บ้านด่าน',
            district: 'อรัญประเทศ',
            province: 'สระแก้ว',
            zipcode: 27120,
        },
        {
            subDistrict: 'บ้านใหม่หนองไทร',
            district: 'อรัญประเทศ',
            province: 'สระแก้ว',
            zipcode: 27120,
        },
        {
            subDistrict: 'ป่าไร่',
            district: 'อรัญประเทศ',
            province: 'สระแก้ว',
            zipcode: 27120,
        },
        {
            subDistrict: 'ผ่านศึก',
            district: 'อรัญประเทศ',
            province: 'สระแก้ว',
            zipcode: 27120,
        },
        {
            subDistrict: 'ฟากห้วย',
            district: 'อรัญประเทศ',
            province: 'สระแก้ว',
            zipcode: 27120,
        },
        {
            subDistrict: 'หนองสังข์',
            district: 'อรัญประเทศ',
            province: 'สระแก้ว',
            zipcode: 27120,
        },
        {
            subDistrict: 'หันทราย',
            district: 'อรัญประเทศ',
            province: 'สระแก้ว',
            zipcode: 27120,
        },
        {
            subDistrict: 'อรัญประเทศ',
            district: 'อรัญประเทศ',
            province: 'สระแก้ว',
            zipcode: 27120,
        },
        {
            subDistrict: 'เมืองไผ่',
            district: 'อรัญประเทศ',
            province: 'สระแก้ว',
            zipcode: 27120,
        },
        {
            subDistrict: 'พระเพลิง',
            district: 'เขาฉกรรจ์',
            province: 'สระแก้ว',
            zipcode: 27000,
        },
        {
            subDistrict: 'หนองหว้า',
            district: 'เขาฉกรรจ์',
            province: 'สระแก้ว',
            zipcode: 27000,
        },
        {
            subDistrict: 'เขาฉกรรจ์',
            district: 'เขาฉกรรจ์',
            province: 'สระแก้ว',
            zipcode: 27000,
        },
        {
            subDistrict: 'เขาสามสิบ',
            district: 'เขาฉกรรจ์',
            province: 'สระแก้ว',
            zipcode: 27000,
        },
        {
            subDistrict: 'ท่าเกษม',
            district: 'เมืองสระแก้ว',
            province: 'สระแก้ว',
            zipcode: 27000,
        },
        {
            subDistrict: 'ท่าแยก',
            district: 'เมืองสระแก้ว',
            province: 'สระแก้ว',
            zipcode: 27000,
        },
        {
            subDistrict: 'บ้านแก้ง',
            district: 'เมืองสระแก้ว',
            province: 'สระแก้ว',
            zipcode: 27000,
        },
        {
            subDistrict: 'ศาลาลำดวน',
            district: 'เมืองสระแก้ว',
            province: 'สระแก้ว',
            zipcode: 27000,
        },
        {
            subDistrict: 'สระขวัญ',
            district: 'เมืองสระแก้ว',
            province: 'สระแก้ว',
            zipcode: 27000,
        },
        {
            subDistrict: 'สระแก้ว',
            district: 'เมืองสระแก้ว',
            province: 'สระแก้ว',
            zipcode: 27000,
        },
        {
            subDistrict: 'หนองบอน',
            district: 'เมืองสระแก้ว',
            province: 'สระแก้ว',
            zipcode: 27000,
        },
        {
            subDistrict: 'โคกปี่ฆ้อง',
            district: 'เมืองสระแก้ว',
            province: 'สระแก้ว',
            zipcode: 27000,
        },
        {
            subDistrict: 'หนองม่วง',
            district: 'โคกสูง',
            province: 'สระแก้ว',
            zipcode: 27180,
        },
        {
            subDistrict: 'หนองแวง',
            district: 'โคกสูง',
            province: 'สระแก้ว',
            zipcode: 27180,
        },
        {
            subDistrict: 'โคกสูง',
            district: 'โคกสูง',
            province: 'สระแก้ว',
            zipcode: 27120,
        },
        {
            subDistrict: 'โนนหมากมุ่น',
            district: 'โคกสูง',
            province: 'สระแก้ว',
            zipcode: 27120,
        },
        {
            subDistrict: 'คอทราย',
            district: 'ค่ายบางระจัน',
            province: 'สิงห์บุรี',
            zipcode: 16150,
        },
        {
            subDistrict: 'ท่าข้าม',
            district: 'ค่ายบางระจัน',
            province: 'สิงห์บุรี',
            zipcode: 16150,
        },
        {
            subDistrict: 'บางระจัน',
            district: 'ค่ายบางระจัน',
            province: 'สิงห์บุรี',
            zipcode: 16150,
        },
        {
            subDistrict: 'หนองกระทุ่ม',
            district: 'ค่ายบางระจัน',
            province: 'สิงห์บุรี',
            zipcode: 16150,
        },
        {
            subDistrict: 'โพทะเล',
            district: 'ค่ายบางระจัน',
            province: 'สิงห์บุรี',
            zipcode: 16150,
        },
        {
            subDistrict: 'โพสังโฆ',
            district: 'ค่ายบางระจัน',
            province: 'สิงห์บุรี',
            zipcode: 16150,
        },
        {
            subDistrict: 'ถอนสมอ',
            district: 'ท่าช้าง',
            province: 'สิงห์บุรี',
            zipcode: 16140,
        },
        {
            subDistrict: 'พิกุลทอง',
            district: 'ท่าช้าง',
            province: 'สิงห์บุรี',
            zipcode: 16140,
        },
        {
            subDistrict: 'วิหารขาว',
            district: 'ท่าช้าง',
            province: 'สิงห์บุรี',
            zipcode: 16140,
        },
        {
            subDistrict: 'โพประจักษ์',
            district: 'ท่าช้าง',
            province: 'สิงห์บุรี',
            zipcode: 16140,
        },
        {
            subDistrict: 'บ้านจ่า',
            district: 'บางระจัน',
            province: 'สิงห์บุรี',
            zipcode: 16130,
        },
        {
            subDistrict: 'พักทัน',
            district: 'บางระจัน',
            province: 'สิงห์บุรี',
            zipcode: 16130,
        },
        {
            subDistrict: 'สระแจง',
            district: 'บางระจัน',
            province: 'สิงห์บุรี',
            zipcode: 16130,
        },
        {
            subDistrict: 'สิงห์',
            district: 'บางระจัน',
            province: 'สิงห์บุรี',
            zipcode: 16130,
        },
        {
            subDistrict: 'เชิงกลัด',
            district: 'บางระจัน',
            province: 'สิงห์บุรี',
            zipcode: 16130,
        },
        {
            subDistrict: 'แม่ลา',
            district: 'บางระจัน',
            province: 'สิงห์บุรี',
            zipcode: 16130,
        },
        {
            subDistrict: 'โพชนไก่',
            district: 'บางระจัน',
            province: 'สิงห์บุรี',
            zipcode: 16130,
        },
        {
            subDistrict: 'ไม้ดัด',
            district: 'บางระจัน',
            province: 'สิงห์บุรี',
            zipcode: 16130,
        },
        {
            subDistrict: 'บางน้ำเชี่ยว',
            district: 'พรหมบุรี',
            province: 'สิงห์บุรี',
            zipcode: 16120,
        },
        {
            subDistrict: 'บ้านหม้อ',
            district: 'พรหมบุรี',
            province: 'สิงห์บุรี',
            zipcode: 16120,
        },
        {
            subDistrict: 'บ้านแป้ง',
            district: 'พรหมบุรี',
            province: 'สิงห์บุรี',
            zipcode: 16120,
        },
        {
            subDistrict: 'พรหมบุรี',
            district: 'พรหมบุรี',
            province: 'สิงห์บุรี',
            zipcode: 16160,
        },
        {
            subDistrict: 'พระงาม',
            district: 'พรหมบุรี',
            province: 'สิงห์บุรี',
            zipcode: 16120,
        },
        {
            subDistrict: 'หัวป่า',
            district: 'พรหมบุรี',
            province: 'สิงห์บุรี',
            zipcode: 16120,
        },
        {
            subDistrict: 'โรงช้าง',
            district: 'พรหมบุรี',
            province: 'สิงห์บุรี',
            zipcode: 16120,
        },
        {
            subDistrict: 'งิ้วราย',
            district: 'อินทร์บุรี',
            province: 'สิงห์บุรี',
            zipcode: 16110,
        },
        {
            subDistrict: 'ชีน้ำร้าย',
            district: 'อินทร์บุรี',
            province: 'สิงห์บุรี',
            zipcode: 16110,
        },
        {
            subDistrict: 'ทองเอน',
            district: 'อินทร์บุรี',
            province: 'สิงห์บุรี',
            zipcode: 16110,
        },
        {
            subDistrict: 'ทับยา',
            district: 'อินทร์บุรี',
            province: 'สิงห์บุรี',
            zipcode: 16110,
        },
        {
            subDistrict: 'ท่างาม',
            district: 'อินทร์บุรี',
            province: 'สิงห์บุรี',
            zipcode: 16110,
        },
        {
            subDistrict: 'น้ำตาล',
            district: 'อินทร์บุรี',
            province: 'สิงห์บุรี',
            zipcode: 16110,
        },
        {
            subDistrict: 'ประศุก',
            district: 'อินทร์บุรี',
            province: 'สิงห์บุรี',
            zipcode: 16110,
        },
        {
            subDistrict: 'ห้วยชัน',
            district: 'อินทร์บุรี',
            province: 'สิงห์บุรี',
            zipcode: 16110,
        },
        {
            subDistrict: 'อินทร์บุรี',
            district: 'อินทร์บุรี',
            province: 'สิงห์บุรี',
            zipcode: 16110,
        },
        {
            subDistrict: 'โพธิ์ชัย',
            district: 'อินทร์บุรี',
            province: 'สิงห์บุรี',
            zipcode: 16110,
        },
        {
            subDistrict: 'จักรสีห์',
            district: 'เมืองสิงห์บุรี',
            province: 'สิงห์บุรี',
            zipcode: 16000,
        },
        {
            subDistrict: 'ต้นโพธิ์',
            district: 'เมืองสิงห์บุรี',
            province: 'สิงห์บุรี',
            zipcode: 16000,
        },
        {
            subDistrict: 'บางกระบือ',
            district: 'เมืองสิงห์บุรี',
            province: 'สิงห์บุรี',
            zipcode: 16000,
        },
        {
            subDistrict: 'บางพุทรา',
            district: 'เมืองสิงห์บุรี',
            province: 'สิงห์บุรี',
            zipcode: 16000,
        },
        {
            subDistrict: 'บางมัญ',
            district: 'เมืองสิงห์บุรี',
            province: 'สิงห์บุรี',
            zipcode: 16000,
        },
        {
            subDistrict: 'ม่วงหมู่',
            district: 'เมืองสิงห์บุรี',
            province: 'สิงห์บุรี',
            zipcode: 16000,
        },
        {
            subDistrict: 'หัวไผ่',
            district: 'เมืองสิงห์บุรี',
            province: 'สิงห์บุรี',
            zipcode: 16000,
        },
        {
            subDistrict: 'โพกรวม',
            district: 'เมืองสิงห์บุรี',
            province: 'สิงห์บุรี',
            zipcode: 16000,
        },
        {
            subDistrict: 'ดอนเจดีย์',
            district: 'ดอนเจดีย์',
            province: 'สุพรรณบุรี',
            zipcode: 72170,
        },
        {
            subDistrict: 'ทะเลบก',
            district: 'ดอนเจดีย์',
            province: 'สุพรรณบุรี',
            zipcode: 72250,
        },
        {
            subDistrict: 'สระกระโจม',
            district: 'ดอนเจดีย์',
            province: 'สุพรรณบุรี',
            zipcode: 72250,
        },
        {
            subDistrict: 'หนองสาหร่าย',
            district: 'ดอนเจดีย์',
            province: 'สุพรรณบุรี',
            zipcode: 72170,
        },
        {
            subDistrict: 'ไร่รถ',
            district: 'ดอนเจดีย์',
            province: 'สุพรรณบุรี',
            zipcode: 72170,
        },
        {
            subDistrict: 'ด่านช้าง',
            district: 'ด่านช้าง',
            province: 'สุพรรณบุรี',
            zipcode: 72180,
        },
        {
            subDistrict: 'นิคมกระเสียว',
            district: 'ด่านช้าง',
            province: 'สุพรรณบุรี',
            zipcode: 72180,
        },
        {
            subDistrict: 'วังคัน',
            district: 'ด่านช้าง',
            province: 'สุพรรณบุรี',
            zipcode: 72180,
        },
        {
            subDistrict: 'วังยาว',
            district: 'ด่านช้าง',
            province: 'สุพรรณบุรี',
            zipcode: 72180,
        },
        {
            subDistrict: 'หนองมะค่าโมง',
            district: 'ด่านช้าง',
            province: 'สุพรรณบุรี',
            zipcode: 72180,
        },
        {
            subDistrict: 'ห้วยขมิ้น',
            district: 'ด่านช้าง',
            province: 'สุพรรณบุรี',
            zipcode: 72180,
        },
        {
            subDistrict: 'องค์พระ',
            district: 'ด่านช้าง',
            province: 'สุพรรณบุรี',
            zipcode: 72180,
        },
        {
            subDistrict: 'กฤษณา',
            district: 'บางปลาม้า',
            province: 'สุพรรณบุรี',
            zipcode: 72150,
        },
        {
            subDistrict: 'จรเข้ใหญ่',
            district: 'บางปลาม้า',
            province: 'สุพรรณบุรี',
            zipcode: 72150,
        },
        {
            subDistrict: 'ตะค่า',
            district: 'บางปลาม้า',
            province: 'สุพรรณบุรี',
            zipcode: 72150,
        },
        {
            subDistrict: 'บางปลาม้า',
            district: 'บางปลาม้า',
            province: 'สุพรรณบุรี',
            zipcode: 72150,
        },
        {
            subDistrict: 'บางใหญ่',
            district: 'บางปลาม้า',
            province: 'สุพรรณบุรี',
            zipcode: 72150,
        },
        {
            subDistrict: 'บ้านแหลม',
            district: 'บางปลาม้า',
            province: 'สุพรรณบุรี',
            zipcode: 72150,
        },
        {
            subDistrict: 'มะขามล้ม',
            district: 'บางปลาม้า',
            province: 'สุพรรณบุรี',
            zipcode: 72150,
        },
        {
            subDistrict: 'วังน้ำเย็น',
            district: 'บางปลาม้า',
            province: 'สุพรรณบุรี',
            zipcode: 72150,
        },
        {
            subDistrict: 'วัดดาว',
            district: 'บางปลาม้า',
            province: 'สุพรรณบุรี',
            zipcode: 72150,
        },
        {
            subDistrict: 'วัดโบสถ์',
            district: 'บางปลาม้า',
            province: 'สุพรรณบุรี',
            zipcode: 72150,
        },
        {
            subDistrict: 'สาลี',
            district: 'บางปลาม้า',
            province: 'สุพรรณบุรี',
            zipcode: 72150,
        },
        {
            subDistrict: 'องครักษ์',
            district: 'บางปลาม้า',
            province: 'สุพรรณบุรี',
            zipcode: 72150,
        },
        {
            subDistrict: 'โคกคราม',
            district: 'บางปลาม้า',
            province: 'สุพรรณบุรี',
            zipcode: 72150,
        },
        {
            subDistrict: 'ไผ่กองดิน',
            district: 'บางปลาม้า',
            province: 'สุพรรณบุรี',
            zipcode: 72150,
        },
        {
            subDistrict: 'ดอนปรู',
            district: 'ศรีประจันต์',
            province: 'สุพรรณบุรี',
            zipcode: 72140,
        },
        {
            subDistrict: 'บางงาม',
            district: 'ศรีประจันต์',
            province: 'สุพรรณบุรี',
            zipcode: 72140,
        },
        {
            subDistrict: 'บ้านกร่าง',
            district: 'ศรีประจันต์',
            province: 'สุพรรณบุรี',
            zipcode: 72140,
        },
        {
            subDistrict: 'ปลายนา',
            district: 'ศรีประจันต์',
            province: 'สุพรรณบุรี',
            zipcode: 72140,
        },
        {
            subDistrict: 'มดแดง',
            district: 'ศรีประจันต์',
            province: 'สุพรรณบุรี',
            zipcode: 72140,
        },
        {
            subDistrict: 'วังน้ำซับ',
            district: 'ศรีประจันต์',
            province: 'สุพรรณบุรี',
            zipcode: 72140,
        },
        {
            subDistrict: 'วังยาง',
            district: 'ศรีประจันต์',
            province: 'สุพรรณบุรี',
            zipcode: 72140,
        },
        {
            subDistrict: 'วังหว้า',
            district: 'ศรีประจันต์',
            province: 'สุพรรณบุรี',
            zipcode: 72140,
        },
        {
            subDistrict: 'ศรีประจันต์',
            district: 'ศรีประจันต์',
            province: 'สุพรรณบุรี',
            zipcode: 72140,
        },
        {
            subDistrict: 'ดอนมะนาว',
            district: 'สองพี่น้อง',
            province: 'สุพรรณบุรี',
            zipcode: 72110,
        },
        {
            subDistrict: 'ต้นตาล',
            district: 'สองพี่น้อง',
            province: 'สุพรรณบุรี',
            zipcode: 72110,
        },
        {
            subDistrict: 'ทุ่งคอก',
            district: 'สองพี่น้อง',
            province: 'สุพรรณบุรี',
            zipcode: 72110,
        },
        {
            subDistrict: 'บางตะเคียน',
            district: 'สองพี่น้อง',
            province: 'สุพรรณบุรี',
            zipcode: 72110,
        },
        {
            subDistrict: 'บางตาเถร',
            district: 'สองพี่น้อง',
            province: 'สุพรรณบุรี',
            zipcode: 72110,
        },
        {
            subDistrict: 'บางพลับ',
            district: 'สองพี่น้อง',
            province: 'สุพรรณบุรี',
            zipcode: 72110,
        },
        {
            subDistrict: 'บางเลน',
            district: 'สองพี่น้อง',
            province: 'สุพรรณบุรี',
            zipcode: 72110,
        },
        {
            subDistrict: 'บ่อสุพรรณ',
            district: 'สองพี่น้อง',
            province: 'สุพรรณบุรี',
            zipcode: 72190,
        },
        {
            subDistrict: 'บ้านกุ่ม',
            district: 'สองพี่น้อง',
            province: 'สุพรรณบุรี',
            zipcode: 72110,
        },
        {
            subDistrict: 'บ้านช้าง',
            district: 'สองพี่น้อง',
            province: 'สุพรรณบุรี',
            zipcode: 72110,
        },
        {
            subDistrict: 'ศรีสำราญ',
            district: 'สองพี่น้อง',
            province: 'สุพรรณบุรี',
            zipcode: 72110,
        },
        {
            subDistrict: 'สองพี่น้อง',
            district: 'สองพี่น้อง',
            province: 'สุพรรณบุรี',
            zipcode: 72110,
        },
        {
            subDistrict: 'หนองบ่อ',
            district: 'สองพี่น้อง',
            province: 'สุพรรณบุรี',
            zipcode: 72190,
        },
        {
            subDistrict: 'หัวโพธิ์',
            district: 'สองพี่น้อง',
            province: 'สุพรรณบุรี',
            zipcode: 72110,
        },
        {
            subDistrict: 'เนินพระปรางค์',
            district: 'สองพี่น้อง',
            province: 'สุพรรณบุรี',
            zipcode: 72110,
        },
        {
            subDistrict: 'กระเสียว',
            district: 'สามชุก',
            province: 'สุพรรณบุรี',
            zipcode: 72130,
        },
        {
            subDistrict: 'บ้านสระ',
            district: 'สามชุก',
            province: 'สุพรรณบุรี',
            zipcode: 72130,
        },
        {
            subDistrict: 'ย่านยาว',
            district: 'สามชุก',
            province: 'สุพรรณบุรี',
            zipcode: 72130,
        },
        {
            subDistrict: 'วังลึก',
            district: 'สามชุก',
            province: 'สุพรรณบุรี',
            zipcode: 72130,
        },
        {
            subDistrict: 'สามชุก',
            district: 'สามชุก',
            province: 'สุพรรณบุรี',
            zipcode: 72130,
        },
        {
            subDistrict: 'หนองผักนาก',
            district: 'สามชุก',
            province: 'สุพรรณบุรี',
            zipcode: 72130,
        },
        {
            subDistrict: 'หนองสะเดา',
            district: 'สามชุก',
            province: 'สุพรรณบุรี',
            zipcode: 72130,
        },
        {
            subDistrict: 'ทัพหลวง',
            district: 'หนองหญ้าไซ',
            province: 'สุพรรณบุรี',
            zipcode: 72240,
        },
        {
            subDistrict: 'หนองขาม',
            district: 'หนองหญ้าไซ',
            province: 'สุพรรณบุรี',
            zipcode: 72240,
        },
        {
            subDistrict: 'หนองราชวัตร',
            district: 'หนองหญ้าไซ',
            province: 'สุพรรณบุรี',
            zipcode: 72240,
        },
        {
            subDistrict: 'หนองหญ้าไซ',
            district: 'หนองหญ้าไซ',
            province: 'สุพรรณบุรี',
            zipcode: 72240,
        },
        {
            subDistrict: 'หนองโพธิ์',
            district: 'หนองหญ้าไซ',
            province: 'สุพรรณบุรี',
            zipcode: 72240,
        },
        {
            subDistrict: 'แจงงาม',
            district: 'หนองหญ้าไซ',
            province: 'สุพรรณบุรี',
            zipcode: 72240,
        },
        {
            subDistrict: 'กระจัน',
            district: 'อู่ทอง',
            province: 'สุพรรณบุรี',
            zipcode: 72160,
        },
        {
            subDistrict: 'จรเข้สามพัน',
            district: 'อู่ทอง',
            province: 'สุพรรณบุรี',
            zipcode: 71170,
        },
        {
            subDistrict: 'ดอนคา',
            district: 'อู่ทอง',
            province: 'สุพรรณบุรี',
            zipcode: 72160,
        },
        {
            subDistrict: 'ดอนมะเกลือ',
            district: 'อู่ทอง',
            province: 'สุพรรณบุรี',
            zipcode: 72220,
        },
        {
            subDistrict: 'บ้านดอน',
            district: 'อู่ทอง',
            province: 'สุพรรณบุรี',
            zipcode: 72160,
        },
        {
            subDistrict: 'บ้านโข้ง',
            district: 'อู่ทอง',
            province: 'สุพรรณบุรี',
            zipcode: 72160,
        },
        {
            subDistrict: 'พลับพลาไชย',
            district: 'อู่ทอง',
            province: 'สุพรรณบุรี',
            zipcode: 72160,
        },
        {
            subDistrict: 'ยุ้งทะลาย',
            district: 'อู่ทอง',
            province: 'สุพรรณบุรี',
            zipcode: 72160,
        },
        {
            subDistrict: 'สระพังลาน',
            district: 'อู่ทอง',
            province: 'สุพรรณบุรี',
            zipcode: 72220,
        },
        {
            subDistrict: 'สระยายโสม',
            district: 'อู่ทอง',
            province: 'สุพรรณบุรี',
            zipcode: 72220,
        },
        {
            subDistrict: 'หนองโอ่ง',
            district: 'อู่ทอง',
            province: 'สุพรรณบุรี',
            zipcode: 72160,
        },
        {
            subDistrict: 'อู่ทอง',
            district: 'อู่ทอง',
            province: 'สุพรรณบุรี',
            zipcode: 72160,
        },
        {
            subDistrict: 'เจดีย์',
            district: 'อู่ทอง',
            province: 'สุพรรณบุรี',
            zipcode: 72160,
        },
        {
            subDistrict: 'ทุ่งคลี',
            district: 'เดิมบางนางบวช',
            province: 'สุพรรณบุรี',
            zipcode: 72120,
        },
        {
            subDistrict: 'นางบวช',
            district: 'เดิมบางนางบวช',
            province: 'สุพรรณบุรี',
            zipcode: 72120,
        },
        {
            subDistrict: 'บ่อกรุ',
            district: 'เดิมบางนางบวช',
            province: 'สุพรรณบุรี',
            zipcode: 72120,
        },
        {
            subDistrict: 'ปากน้ำ',
            district: 'เดิมบางนางบวช',
            province: 'สุพรรณบุรี',
            zipcode: 72120,
        },
        {
            subDistrict: 'ป่าสะแก',
            district: 'เดิมบางนางบวช',
            province: 'สุพรรณบุรี',
            zipcode: 72120,
        },
        {
            subDistrict: 'ยางนอน',
            district: 'เดิมบางนางบวช',
            province: 'สุพรรณบุรี',
            zipcode: 72120,
        },
        {
            subDistrict: 'วังศรีราช',
            district: 'เดิมบางนางบวช',
            province: 'สุพรรณบุรี',
            zipcode: 72120,
        },
        {
            subDistrict: 'หนองกระทุ่ม',
            district: 'เดิมบางนางบวช',
            province: 'สุพรรณบุรี',
            zipcode: 72120,
        },
        {
            subDistrict: 'หัวนา',
            district: 'เดิมบางนางบวช',
            province: 'สุพรรณบุรี',
            zipcode: 72120,
        },
        {
            subDistrict: 'หัวเขา',
            district: 'เดิมบางนางบวช',
            province: 'สุพรรณบุรี',
            zipcode: 72120,
        },
        {
            subDistrict: 'เขาดิน',
            district: 'เดิมบางนางบวช',
            province: 'สุพรรณบุรี',
            zipcode: 72120,
        },
        {
            subDistrict: 'เขาพระ',
            district: 'เดิมบางนางบวช',
            province: 'สุพรรณบุรี',
            zipcode: 72120,
        },
        {
            subDistrict: 'เดิมบาง',
            district: 'เดิมบางนางบวช',
            province: 'สุพรรณบุรี',
            zipcode: 72120,
        },
        {
            subDistrict: 'โคกช้าง',
            district: 'เดิมบางนางบวช',
            province: 'สุพรรณบุรี',
            zipcode: 72120,
        },
        {
            subDistrict: 'ดอนกำยาน',
            district: 'เมืองสุพรรณบุรี',
            province: 'สุพรรณบุรี',
            zipcode: 72000,
        },
        {
            subDistrict: 'ดอนตาล',
            district: 'เมืองสุพรรณบุรี',
            province: 'สุพรรณบุรี',
            zipcode: 72000,
        },
        {
            subDistrict: 'ดอนมะสังข์',
            district: 'เมืองสุพรรณบุรี',
            province: 'สุพรรณบุรี',
            zipcode: 72000,
        },
        {
            subDistrict: 'ดอนโพธิ์ทอง',
            district: 'เมืองสุพรรณบุรี',
            province: 'สุพรรณบุรี',
            zipcode: 72000,
        },
        {
            subDistrict: 'ตลิ่งชัน',
            district: 'เมืองสุพรรณบุรี',
            province: 'สุพรรณบุรี',
            zipcode: 72230,
        },
        {
            subDistrict: 'ทับตีเหล็ก',
            district: 'เมืองสุพรรณบุรี',
            province: 'สุพรรณบุรี',
            zipcode: 72000,
        },
        {
            subDistrict: 'ท่าพี่เลี้ยง',
            district: 'เมืองสุพรรณบุรี',
            province: 'สุพรรณบุรี',
            zipcode: 72000,
        },
        {
            subDistrict: 'ท่าระหัด',
            district: 'เมืองสุพรรณบุรี',
            province: 'สุพรรณบุรี',
            zipcode: 72000,
        },
        {
            subDistrict: 'บางกุ้ง',
            district: 'เมืองสุพรรณบุรี',
            province: 'สุพรรณบุรี',
            zipcode: 72210,
        },
        {
            subDistrict: 'บ้านโพธิ์',
            district: 'เมืองสุพรรณบุรี',
            province: 'สุพรรณบุรี',
            zipcode: 72000,
        },
        {
            subDistrict: 'พิหารแดง',
            district: 'เมืองสุพรรณบุรี',
            province: 'สุพรรณบุรี',
            zipcode: 72000,
        },
        {
            subDistrict: 'รั้วใหญ่',
            district: 'เมืองสุพรรณบุรี',
            province: 'สุพรรณบุรี',
            zipcode: 72000,
        },
        {
            subDistrict: 'ศาลาขาว',
            district: 'เมืองสุพรรณบุรี',
            province: 'สุพรรณบุรี',
            zipcode: 72210,
        },
        {
            subDistrict: 'สนามคลี',
            district: 'เมืองสุพรรณบุรี',
            province: 'สุพรรณบุรี',
            zipcode: 72230,
        },
        {
            subDistrict: 'สนามชัย',
            district: 'เมืองสุพรรณบุรี',
            province: 'สุพรรณบุรี',
            zipcode: 72000,
        },
        {
            subDistrict: 'สระแก้ว',
            district: 'เมืองสุพรรณบุรี',
            province: 'สุพรรณบุรี',
            zipcode: 72230,
        },
        {
            subDistrict: 'สวนแตง',
            district: 'เมืองสุพรรณบุรี',
            province: 'สุพรรณบุรี',
            zipcode: 72210,
        },
        {
            subDistrict: 'โคกโคเฒ่า',
            district: 'เมืองสุพรรณบุรี',
            province: 'สุพรรณบุรี',
            zipcode: 72000,
        },
        {
            subDistrict: 'โพธิ์พระยา',
            district: 'เมืองสุพรรณบุรี',
            province: 'สุพรรณบุรี',
            zipcode: 72000,
        },
        {
            subDistrict: 'ไผ่ขวาง',
            district: 'เมืองสุพรรณบุรี',
            province: 'สุพรรณบุรี',
            zipcode: 72000,
        },
        {
            subDistrict: 'กรูด',
            district: 'กาญจนดิษฐ์',
            province: 'สุราษฎร์ธานี',
            zipcode: 84160,
        },
        {
            subDistrict: 'กะแดะ',
            district: 'กาญจนดิษฐ์',
            province: 'สุราษฎร์ธานี',
            zipcode: 84160,
        },
        {
            subDistrict: 'คลองสระ',
            district: 'กาญจนดิษฐ์',
            province: 'สุราษฎร์ธานี',
            zipcode: 84160,
        },
        {
            subDistrict: 'ช้างขวา',
            district: 'กาญจนดิษฐ์',
            province: 'สุราษฎร์ธานี',
            zipcode: 84160,
        },
        {
            subDistrict: 'ช้างซ้าย',
            district: 'กาญจนดิษฐ์',
            province: 'สุราษฎร์ธานี',
            zipcode: 84160,
        },
        {
            subDistrict: 'ตะเคียนทอง',
            district: 'กาญจนดิษฐ์',
            province: 'สุราษฎร์ธานี',
            zipcode: 84160,
        },
        {
            subDistrict: 'ทุ่งกง',
            district: 'กาญจนดิษฐ์',
            province: 'สุราษฎร์ธานี',
            zipcode: 84290,
        },
        {
            subDistrict: 'ทุ่งรัง',
            district: 'กาญจนดิษฐ์',
            province: 'สุราษฎร์ธานี',
            zipcode: 84290,
        },
        {
            subDistrict: 'ท่าทอง',
            district: 'กาญจนดิษฐ์',
            province: 'สุราษฎร์ธานี',
            zipcode: 84160,
        },
        {
            subDistrict: 'ท่าทองใหม่',
            district: 'กาญจนดิษฐ์',
            province: 'สุราษฎร์ธานี',
            zipcode: 84290,
        },
        {
            subDistrict: 'ท่าอุแท',
            district: 'กาญจนดิษฐ์',
            province: 'สุราษฎร์ธานี',
            zipcode: 84160,
        },
        {
            subDistrict: 'ป่าร่อน',
            district: 'กาญจนดิษฐ์',
            province: 'สุราษฎร์ธานี',
            zipcode: 84160,
        },
        {
            subDistrict: 'พลายวาส',
            district: 'กาญจนดิษฐ์',
            province: 'สุราษฎร์ธานี',
            zipcode: 84160,
        },
        {
            subDistrict: 'กะเปา',
            district: 'คีรีรัฐนิคม',
            province: 'สุราษฎร์ธานี',
            zipcode: 84180,
        },
        {
            subDistrict: 'ถ้ำสิงขร',
            district: 'คีรีรัฐนิคม',
            province: 'สุราษฎร์ธานี',
            zipcode: 84180,
        },
        {
            subDistrict: 'ท่ากระดาน',
            district: 'คีรีรัฐนิคม',
            province: 'สุราษฎร์ธานี',
            zipcode: 84180,
        },
        {
            subDistrict: 'ท่าขนอน',
            district: 'คีรีรัฐนิคม',
            province: 'สุราษฎร์ธานี',
            zipcode: 84180,
        },
        {
            subDistrict: 'น้ำหัก',
            district: 'คีรีรัฐนิคม',
            province: 'สุราษฎร์ธานี',
            zipcode: 84180,
        },
        {
            subDistrict: 'บ้านทำเนียบ',
            district: 'คีรีรัฐนิคม',
            province: 'สุราษฎร์ธานี',
            zipcode: 84180,
        },
        {
            subDistrict: 'บ้านยาง',
            district: 'คีรีรัฐนิคม',
            province: 'สุราษฎร์ธานี',
            zipcode: 84180,
        },
        {
            subDistrict: 'ย่านยาว',
            district: 'คีรีรัฐนิคม',
            province: 'สุราษฎร์ธานี',
            zipcode: 84180,
        },
        {
            subDistrict: 'คลองน้อย',
            district: 'ชัยบุรี',
            province: 'สุราษฎร์ธานี',
            zipcode: 84350,
        },
        {
            subDistrict: 'ชัยบุรี',
            district: 'ชัยบุรี',
            province: 'สุราษฎร์ธานี',
            zipcode: 84350,
        },
        {
            subDistrict: 'สองแพรก',
            district: 'ชัยบุรี',
            province: 'สุราษฎร์ธานี',
            zipcode: 84350,
        },
        {
            subDistrict: 'ไทรทอง',
            district: 'ชัยบุรี',
            province: 'สุราษฎร์ธานี',
            zipcode: 84350,
        },
        {
            subDistrict: 'ชลคราม',
            district: 'ดอนสัก',
            province: 'สุราษฎร์ธานี',
            zipcode: 84160,
        },
        {
            subDistrict: 'ดอนสัก',
            district: 'ดอนสัก',
            province: 'สุราษฎร์ธานี',
            zipcode: 84220,
        },
        {
            subDistrict: 'ปากแพรก',
            district: 'ดอนสัก',
            province: 'สุราษฎร์ธานี',
            zipcode: 84340,
        },
        {
            subDistrict: 'ไชยคราม',
            district: 'ดอนสัก',
            province: 'สุราษฎร์ธานี',
            zipcode: 84220,
        },
        {
            subDistrict: 'คลองไทร',
            district: 'ท่าฉาง',
            province: 'สุราษฎร์ธานี',
            zipcode: 84150,
        },
        {
            subDistrict: 'ท่าฉาง',
            district: 'ท่าฉาง',
            province: 'สุราษฎร์ธานี',
            zipcode: 84150,
        },
        {
            subDistrict: 'ท่าเคย',
            district: 'ท่าฉาง',
            province: 'สุราษฎร์ธานี',
            zipcode: 84150,
        },
        {
            subDistrict: 'ปากฉลุย',
            district: 'ท่าฉาง',
            province: 'สุราษฎร์ธานี',
            zipcode: 84150,
        },
        {
            subDistrict: 'เขาถ่าน',
            district: 'ท่าฉาง',
            province: 'สุราษฎร์ธานี',
            zipcode: 84150,
        },
        {
            subDistrict: 'เสวียด',
            district: 'ท่าฉาง',
            province: 'สุราษฎร์ธานี',
            zipcode: 84150,
        },
        {
            subDistrict: 'คลองพา',
            district: 'ท่าชนะ',
            province: 'สุราษฎร์ธานี',
            zipcode: 84170,
        },
        {
            subDistrict: 'คันธุลี',
            district: 'ท่าชนะ',
            province: 'สุราษฎร์ธานี',
            zipcode: 84170,
        },
        {
            subDistrict: 'ท่าชนะ',
            district: 'ท่าชนะ',
            province: 'สุราษฎร์ธานี',
            zipcode: 84170,
        },
        {
            subDistrict: 'ประสงค์',
            district: 'ท่าชนะ',
            province: 'สุราษฎร์ธานี',
            zipcode: 84170,
        },
        {
            subDistrict: 'วัง',
            district: 'ท่าชนะ',
            province: 'สุราษฎร์ธานี',
            zipcode: 84170,
        },
        {
            subDistrict: 'สมอทอง',
            district: 'ท่าชนะ',
            province: 'สุราษฎร์ธานี',
            zipcode: 84170,
        },
        {
            subDistrict: 'พรุไทย',
            district: 'บ้านตาขุน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84230,
        },
        {
            subDistrict: 'พะแสง',
            district: 'บ้านตาขุน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84230,
        },
        {
            subDistrict: 'เขาพัง',
            district: 'บ้านตาขุน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84230,
        },
        {
            subDistrict: 'เขาวง',
            district: 'บ้านตาขุน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84230,
        },
        {
            subDistrict: 'คลองปราบ',
            district: 'บ้านนาสาร',
            province: 'สุราษฎร์ธานี',
            zipcode: 84120,
        },
        {
            subDistrict: 'ควนศรี',
            district: 'บ้านนาสาร',
            province: 'สุราษฎร์ธานี',
            zipcode: 84270,
        },
        {
            subDistrict: 'ควนสุบรรณ',
            district: 'บ้านนาสาร',
            province: 'สุราษฎร์ธานี',
            zipcode: 84120,
        },
        {
            subDistrict: 'ทุ่งเตา',
            district: 'บ้านนาสาร',
            province: 'สุราษฎร์ธานี',
            zipcode: 84120,
        },
        {
            subDistrict: 'ทุ่งเตาใหม่',
            district: 'บ้านนาสาร',
            province: 'สุราษฎร์ธานี',
            zipcode: 84120,
        },
        {
            subDistrict: 'ท่าชี',
            district: 'บ้านนาสาร',
            province: 'สุราษฎร์ธานี',
            zipcode: 84120,
        },
        {
            subDistrict: 'นาสาร',
            district: 'บ้านนาสาร',
            province: 'สุราษฎร์ธานี',
            zipcode: 84120,
        },
        {
            subDistrict: 'น้ำพุ',
            district: 'บ้านนาสาร',
            province: 'สุราษฎร์ธานี',
            zipcode: 84120,
        },
        {
            subDistrict: 'พรุพี',
            district: 'บ้านนาสาร',
            province: 'สุราษฎร์ธานี',
            zipcode: 84270,
        },
        {
            subDistrict: 'ลำพูน',
            district: 'บ้านนาสาร',
            province: 'สุราษฎร์ธานี',
            zipcode: 84120,
        },
        {
            subDistrict: 'เพิ่มพูนทรัพย์',
            district: 'บ้านนาสาร',
            province: 'สุราษฎร์ธานี',
            zipcode: 84120,
        },
        {
            subDistrict: 'ทรัพย์ทวี',
            district: 'บ้านนาเดิม',
            province: 'สุราษฎร์ธานี',
            zipcode: 84240,
        },
        {
            subDistrict: 'ท่าเรือ',
            district: 'บ้านนาเดิม',
            province: 'สุราษฎร์ธานี',
            zipcode: 84240,
        },
        {
            subDistrict: 'นาใต้',
            district: 'บ้านนาเดิม',
            province: 'สุราษฎร์ธานี',
            zipcode: 84240,
        },
        {
            subDistrict: 'บ้านนา',
            district: 'บ้านนาเดิม',
            province: 'สุราษฎร์ธานี',
            zipcode: 84240,
        },
        {
            subDistrict: 'คลองชะอุ่น',
            district: 'พนม',
            province: 'สุราษฎร์ธานี',
            zipcode: 84250,
        },
        {
            subDistrict: 'คลองศก',
            district: 'พนม',
            province: 'สุราษฎร์ธานี',
            zipcode: 84250,
        },
        {
            subDistrict: 'ต้นยวน',
            district: 'พนม',
            province: 'สุราษฎร์ธานี',
            zipcode: 84250,
        },
        {
            subDistrict: 'พนม',
            district: 'พนม',
            province: 'สุราษฎร์ธานี',
            zipcode: 84250,
        },
        {
            subDistrict: 'พลูเถื่อน',
            district: 'พนม',
            province: 'สุราษฎร์ธานี',
            zipcode: 84250,
        },
        {
            subDistrict: 'พังกาญจน์',
            district: 'พนม',
            province: 'สุราษฎร์ธานี',
            zipcode: 84250,
        },
        {
            subDistrict: 'บางสวรรค์',
            district: 'พระแสง',
            province: 'สุราษฎร์ธานี',
            zipcode: 84210,
        },
        {
            subDistrict: 'สาคู',
            district: 'พระแสง',
            province: 'สุราษฎร์ธานี',
            zipcode: 84210,
        },
        {
            subDistrict: 'สินปุน',
            district: 'พระแสง',
            province: 'สุราษฎร์ธานี',
            zipcode: 84210,
        },
        {
            subDistrict: 'สินเจริญ',
            district: 'พระแสง',
            province: 'สุราษฎร์ธานี',
            zipcode: 84210,
        },
        {
            subDistrict: 'อิปัน',
            district: 'พระแสง',
            province: 'สุราษฎร์ธานี',
            zipcode: 84210,
        },
        {
            subDistrict: 'ไทรขึง',
            district: 'พระแสง',
            province: 'สุราษฎร์ธานี',
            zipcode: 84210,
        },
        {
            subDistrict: 'ไทรโสภา',
            district: 'พระแสง',
            province: 'สุราษฎร์ธานี',
            zipcode: 84210,
        },
        {
            subDistrict: 'กรูด',
            district: 'พุนพิน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84130,
        },
        {
            subDistrict: 'ตะปาน',
            district: 'พุนพิน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84130,
        },
        {
            subDistrict: 'ท่าข้าม',
            district: 'พุนพิน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84130,
        },
        {
            subDistrict: 'ท่าสะท้อน',
            district: 'พุนพิน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84130,
        },
        {
            subDistrict: 'ท่าโรงช้าง',
            district: 'พุนพิน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84130,
        },
        {
            subDistrict: 'น้ำรอบ',
            district: 'พุนพิน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84130,
        },
        {
            subDistrict: 'บางงอน',
            district: 'พุนพิน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84130,
        },
        {
            subDistrict: 'บางมะเดื่อ',
            district: 'พุนพิน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84130,
        },
        {
            subDistrict: 'บางเดือน',
            district: 'พุนพิน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84130,
        },
        {
            subDistrict: 'พุนพิน',
            district: 'พุนพิน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84130,
        },
        {
            subDistrict: 'มะลวน',
            district: 'พุนพิน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84130,
        },
        {
            subDistrict: 'ลีเล็ด',
            district: 'พุนพิน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84130,
        },
        {
            subDistrict: 'ศรีวิชัย',
            district: 'พุนพิน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84130,
        },
        {
            subDistrict: 'หนองไทร',
            district: 'พุนพิน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84130,
        },
        {
            subDistrict: 'หัวเตย',
            district: 'พุนพิน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84130,
        },
        {
            subDistrict: 'เขาหัวควาย',
            district: 'พุนพิน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84130,
        },
        {
            subDistrict: 'ตะกุกเหนือ',
            district: 'วิภาวดี',
            province: 'สุราษฎร์ธานี',
            zipcode: 84180,
        },
        {
            subDistrict: 'ตะกุกใต้',
            district: 'วิภาวดี',
            province: 'สุราษฎร์ธานี',
            zipcode: 84180,
        },
        {
            subDistrict: 'บ้านใต้',
            district: 'เกาะพะงัน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84280,
        },
        {
            subDistrict: 'เกาะพะงัน',
            district: 'เกาะพะงัน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84280,
        },
        {
            subDistrict: 'เกาะเต่า',
            district: 'เกาะพะงัน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84280,
        },
        {
            subDistrict: 'เกาะเต่า',
            district: 'เกาะพะงัน',
            province: 'สุราษฎร์ธานี',
            zipcode: 84360,
        },
        {
            subDistrict: 'ตลิ่งงาม',
            district: 'เกาะสมุย',
            province: 'สุราษฎร์ธานี',
            zipcode: 84140,
        },
        {
            subDistrict: 'บ่อผุด',
            district: 'เกาะสมุย',
            province: 'สุราษฎร์ธานี',
            zipcode: 84320,
        },
        {
            subDistrict: 'มะเร็ต',
            district: 'เกาะสมุย',
            province: 'สุราษฎร์ธานี',
            zipcode: 84310,
        },
        {
            subDistrict: 'ลิปะน้อย',
            district: 'เกาะสมุย',
            province: 'สุราษฎร์ธานี',
            zipcode: 84140,
        },
        {
            subDistrict: 'หน้าเมือง',
            district: 'เกาะสมุย',
            province: 'สุราษฎร์ธานี',
            zipcode: 84140,
        },
        {
            subDistrict: 'อ่างทอง',
            district: 'เกาะสมุย',
            province: 'สุราษฎร์ธานี',
            zipcode: 84140,
        },
        {
            subDistrict: 'แม่น้ำ',
            district: 'เกาะสมุย',
            province: 'สุราษฎร์ธานี',
            zipcode: 84330,
        },
        {
            subDistrict: 'บ้านเสด็จ',
            district: 'เคียนซา',
            province: 'สุราษฎร์ธานี',
            zipcode: 84260,
        },
        {
            subDistrict: 'พ่วงพรมคร',
            district: 'เคียนซา',
            province: 'สุราษฎร์ธานี',
            zipcode: 84210,
        },
        {
            subDistrict: 'อรัญคามวารี',
            district: 'เคียนซา',
            province: 'สุราษฎร์ธานี',
            zipcode: 84260,
        },
        {
            subDistrict: 'เขาตอก',
            district: 'เคียนซา',
            province: 'สุราษฎร์ธานี',
            zipcode: 84260,
        },
        {
            subDistrict: 'เคียนซา',
            district: 'เคียนซา',
            province: 'สุราษฎร์ธานี',
            zipcode: 84260,
        },
        {
            subDistrict: 'ขุนทะเล',
            district: 'เมืองสุราษฎร์ธานี',
            province: 'สุราษฎร์ธานี',
            zipcode: 84100,
        },
        {
            subDistrict: 'คลองฉนาก',
            district: 'เมืองสุราษฎร์ธานี',
            province: 'สุราษฎร์ธานี',
            zipcode: 84000,
        },
        {
            subDistrict: 'คลองน้อย',
            district: 'เมืองสุราษฎร์ธานี',
            province: 'สุราษฎร์ธานี',
            zipcode: 84000,
        },
        {
            subDistrict: 'ตลาด',
            district: 'เมืองสุราษฎร์ธานี',
            province: 'สุราษฎร์ธานี',
            zipcode: 84000,
        },
        {
            subDistrict: 'บางกุ้ง',
            district: 'เมืองสุราษฎร์ธานี',
            province: 'สุราษฎร์ธานี',
            zipcode: 84000,
        },
        {
            subDistrict: 'บางชนะ',
            district: 'เมืองสุราษฎร์ธานี',
            province: 'สุราษฎร์ธานี',
            zipcode: 84000,
        },
        {
            subDistrict: 'บางโพธิ์',
            district: 'เมืองสุราษฎร์ธานี',
            province: 'สุราษฎร์ธานี',
            zipcode: 84000,
        },
        {
            subDistrict: 'บางใบไม้',
            district: 'เมืองสุราษฎร์ธานี',
            province: 'สุราษฎร์ธานี',
            zipcode: 84000,
        },
        {
            subDistrict: 'บางไทร',
            district: 'เมืองสุราษฎร์ธานี',
            province: 'สุราษฎร์ธานี',
            zipcode: 84000,
        },
        {
            subDistrict: 'มะขามเตี้ย',
            district: 'เมืองสุราษฎร์ธานี',
            province: 'สุราษฎร์ธานี',
            zipcode: 84000,
        },
        {
            subDistrict: 'วัดประดู่',
            district: 'เมืองสุราษฎร์ธานี',
            province: 'สุราษฎร์ธานี',
            zipcode: 84000,
        },
        {
            subDistrict: 'คลองฉนวน',
            district: 'เวียงสระ',
            province: 'สุราษฎร์ธานี',
            zipcode: 84190,
        },
        {
            subDistrict: 'ทุ่งหลวง',
            district: 'เวียงสระ',
            province: 'สุราษฎร์ธานี',
            zipcode: 84190,
        },
        {
            subDistrict: 'บ้านส้อง',
            district: 'เวียงสระ',
            province: 'สุราษฎร์ธานี',
            zipcode: 84190,
        },
        {
            subDistrict: 'เขานิพันธ์',
            district: 'เวียงสระ',
            province: 'สุราษฎร์ธานี',
            zipcode: 84190,
        },
        {
            subDistrict: 'เวียงสระ',
            district: 'เวียงสระ',
            province: 'สุราษฎร์ธานี',
            zipcode: 84190,
        },
        {
            subDistrict: 'ตลาดไชยา',
            district: 'ไชยา',
            province: 'สุราษฎร์ธานี',
            zipcode: 84110,
        },
        {
            subDistrict: 'ตะกรบ',
            district: 'ไชยา',
            province: 'สุราษฎร์ธานี',
            zipcode: 84110,
        },
        {
            subDistrict: 'ทุ่ง',
            district: 'ไชยา',
            province: 'สุราษฎร์ธานี',
            zipcode: 84110,
        },
        {
            subDistrict: 'ปากหมาก',
            district: 'ไชยา',
            province: 'สุราษฎร์ธานี',
            zipcode: 84110,
        },
        {
            subDistrict: 'ป่าเว',
            district: 'ไชยา',
            province: 'สุราษฎร์ธานี',
            zipcode: 84110,
        },
        {
            subDistrict: 'พุมเรียง',
            district: 'ไชยา',
            province: 'สุราษฎร์ธานี',
            zipcode: 84110,
        },
        {
            subDistrict: 'เลม็ด',
            district: 'ไชยา',
            province: 'สุราษฎร์ธานี',
            zipcode: 84110,
        },
        {
            subDistrict: 'เวียง',
            district: 'ไชยา',
            province: 'สุราษฎร์ธานี',
            zipcode: 84110,
        },
        {
            subDistrict: 'โมถ่าย',
            district: 'ไชยา',
            province: 'สุราษฎร์ธานี',
            zipcode: 84110,
        },
        {
            subDistrict: 'กาบเชิง',
            district: 'กาบเชิง',
            province: 'สุรินทร์',
            zipcode: 32210,
        },
        {
            subDistrict: 'คูตัน',
            district: 'กาบเชิง',
            province: 'สุรินทร์',
            zipcode: 32210,
        },
        {
            subDistrict: 'ด่าน',
            district: 'กาบเชิง',
            province: 'สุรินทร์',
            zipcode: 32210,
        },
        {
            subDistrict: 'ตะเคียน',
            district: 'กาบเชิง',
            province: 'สุรินทร์',
            zipcode: 32210,
        },
        {
            subDistrict: 'แนงมุด',
            district: 'กาบเชิง',
            province: 'สุรินทร์',
            zipcode: 32210,
        },
        {
            subDistrict: 'โคกตะเคียน',
            district: 'กาบเชิง',
            province: 'สุรินทร์',
            zipcode: 32210,
        },
        {
            subDistrict: 'กระหาด',
            district: 'จอมพระ',
            province: 'สุรินทร์',
            zipcode: 32180,
        },
        {
            subDistrict: 'จอมพระ',
            district: 'จอมพระ',
            province: 'สุรินทร์',
            zipcode: 32180,
        },
        {
            subDistrict: 'ชุมแสง',
            district: 'จอมพระ',
            province: 'สุรินทร์',
            zipcode: 32180,
        },
        {
            subDistrict: 'บุแกรง',
            district: 'จอมพระ',
            province: 'สุรินทร์',
            zipcode: 32180,
        },
        {
            subDistrict: 'บ้านผือ',
            district: 'จอมพระ',
            province: 'สุรินทร์',
            zipcode: 32180,
        },
        {
            subDistrict: 'ลุ่มระวี',
            district: 'จอมพระ',
            province: 'สุรินทร์',
            zipcode: 32180,
        },
        {
            subDistrict: 'หนองสนิท',
            district: 'จอมพระ',
            province: 'สุรินทร์',
            zipcode: 32180,
        },
        {
            subDistrict: 'เป็นสุข',
            district: 'จอมพระ',
            province: 'สุรินทร์',
            zipcode: 32180,
        },
        {
            subDistrict: 'เมืองลีง',
            district: 'จอมพระ',
            province: 'สุรินทร์',
            zipcode: 32180,
        },
        {
            subDistrict: 'กระเบื้อง',
            district: 'ชุมพลบุรี',
            province: 'สุรินทร์',
            zipcode: 32190,
        },
        {
            subDistrict: 'ชุมพลบุรี',
            district: 'ชุมพลบุรี',
            province: 'สุรินทร์',
            zipcode: 32190,
        },
        {
            subDistrict: 'นาหนองไผ่',
            district: 'ชุมพลบุรี',
            province: 'สุรินทร์',
            zipcode: 32190,
        },
        {
            subDistrict: 'ยะวึก',
            district: 'ชุมพลบุรี',
            province: 'สุรินทร์',
            zipcode: 32190,
        },
        {
            subDistrict: 'ศรีณรงค์',
            district: 'ชุมพลบุรี',
            province: 'สุรินทร์',
            zipcode: 32190,
        },
        {
            subDistrict: 'สระขุด',
            district: 'ชุมพลบุรี',
            province: 'สุรินทร์',
            zipcode: 32190,
        },
        {
            subDistrict: 'หนองเรือ',
            district: 'ชุมพลบุรี',
            province: 'สุรินทร์',
            zipcode: 32190,
        },
        {
            subDistrict: 'เมืองบัว',
            district: 'ชุมพลบุรี',
            province: 'สุรินทร์',
            zipcode: 32190,
        },
        {
            subDistrict: 'ไพรขลา',
            district: 'ชุมพลบุรี',
            province: 'สุรินทร์',
            zipcode: 32190,
        },
        {
            subDistrict: 'กระโพ',
            district: 'ท่าตูม',
            province: 'สุรินทร์',
            zipcode: 32120,
        },
        {
            subDistrict: 'ทุ่งกุลา',
            district: 'ท่าตูม',
            province: 'สุรินทร์',
            zipcode: 32120,
        },
        {
            subDistrict: 'ท่าตูม',
            district: 'ท่าตูม',
            province: 'สุรินทร์',
            zipcode: 32120,
        },
        {
            subDistrict: 'บะ',
            district: 'ท่าตูม',
            province: 'สุรินทร์',
            zipcode: 32120,
        },
        {
            subDistrict: 'บัวโคก',
            district: 'ท่าตูม',
            province: 'สุรินทร์',
            zipcode: 32120,
        },
        {
            subDistrict: 'พรมเทพ',
            district: 'ท่าตูม',
            province: 'สุรินทร์',
            zipcode: 32120,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'ท่าตูม',
            province: 'สุรินทร์',
            zipcode: 32120,
        },
        {
            subDistrict: 'หนองเมธี',
            district: 'ท่าตูม',
            province: 'สุรินทร์',
            zipcode: 32120,
        },
        {
            subDistrict: 'เมืองแก',
            district: 'ท่าตูม',
            province: 'สุรินทร์',
            zipcode: 32120,
        },
        {
            subDistrict: 'โพนครก',
            district: 'ท่าตูม',
            province: 'สุรินทร์',
            zipcode: 32120,
        },
        {
            subDistrict: 'จรัส',
            district: 'บัวเชด',
            province: 'สุรินทร์',
            zipcode: 32230,
        },
        {
            subDistrict: 'ตาวัง',
            district: 'บัวเชด',
            province: 'สุรินทร์',
            zipcode: 32230,
        },
        {
            subDistrict: 'บัวเชด',
            district: 'บัวเชด',
            province: 'สุรินทร์',
            zipcode: 32230,
        },
        {
            subDistrict: 'สะเดา',
            district: 'บัวเชด',
            province: 'สุรินทร์',
            zipcode: 32230,
        },
        {
            subDistrict: 'สำเภาลูน',
            district: 'บัวเชด',
            province: 'สุรินทร์',
            zipcode: 32230,
        },
        {
            subDistrict: 'อาโพน',
            district: 'บัวเชด',
            province: 'สุรินทร์',
            zipcode: 32230,
        },
        {
            subDistrict: 'กังแอน',
            district: 'ปราสาท',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'กันตวจระมวล',
            district: 'ปราสาท',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'ตานี',
            district: 'ปราสาท',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'ตาเบา',
            district: 'ปราสาท',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'ทมอ',
            district: 'ปราสาท',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'ทุ่งมน',
            district: 'ปราสาท',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'บ้านพลวง',
            district: 'ปราสาท',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'บ้านไทร',
            district: 'ปราสาท',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'ประทัดบุ',
            district: 'ปราสาท',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'ปราสาททนง',
            district: 'ปราสาท',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'ปรือ',
            district: 'ปราสาท',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'สมุด',
            district: 'ปราสาท',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'หนองใหญ่',
            district: 'ปราสาท',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'เชื้อเพลิง',
            district: 'ปราสาท',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'โคกยาง',
            district: 'ปราสาท',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'โคกสะอาด',
            district: 'ปราสาท',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'โชคนาสาม',
            district: 'ปราสาท',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'ไพล',
            district: 'ปราสาท',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'จีกแดก',
            district: 'พนมดงรัก',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'ตาเมียง',
            district: 'พนมดงรัก',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'บักได',
            district: 'พนมดงรัก',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'โคกกลาง',
            district: 'พนมดงรัก',
            province: 'สุรินทร์',
            zipcode: 32140,
        },
        {
            subDistrict: 'กุดขาคีม',
            district: 'รัตนบุรี',
            province: 'สุรินทร์',
            zipcode: 32130,
        },
        {
            subDistrict: 'ดอนแรด',
            district: 'รัตนบุรี',
            province: 'สุรินทร์',
            zipcode: 32130,
        },
        {
            subDistrict: 'ทับใหญ่',
            district: 'รัตนบุรี',
            province: 'สุรินทร์',
            zipcode: 32130,
        },
        {
            subDistrict: 'ธาตุ',
            district: 'รัตนบุรี',
            province: 'สุรินทร์',
            zipcode: 32130,
        },
        {
            subDistrict: 'น้ำเขียว',
            district: 'รัตนบุรี',
            province: 'สุรินทร์',
            zipcode: 32130,
        },
        {
            subDistrict: 'ยางสว่าง',
            district: 'รัตนบุรี',
            province: 'สุรินทร์',
            zipcode: 32130,
        },
        {
            subDistrict: 'รัตนบุรี',
            district: 'รัตนบุรี',
            province: 'สุรินทร์',
            zipcode: 32130,
        },
        {
            subDistrict: 'หนองบัวทอง',
            district: 'รัตนบุรี',
            province: 'สุรินทร์',
            zipcode: 32130,
        },
        {
            subDistrict: 'หนองบัวบาน',
            district: 'รัตนบุรี',
            province: 'สุรินทร์',
            zipcode: 32130,
        },
        {
            subDistrict: 'เบิด',
            district: 'รัตนบุรี',
            province: 'สุรินทร์',
            zipcode: 32130,
        },
        {
            subDistrict: 'แก',
            district: 'รัตนบุรี',
            province: 'สุรินทร์',
            zipcode: 32130,
        },
        {
            subDistrict: 'ไผ่',
            district: 'รัตนบุรี',
            province: 'สุรินทร์',
            zipcode: 32130,
        },
        {
            subDistrict: 'ตระเปียงเตีย',
            district: 'ลำดวน',
            province: 'สุรินทร์',
            zipcode: 32220,
        },
        {
            subDistrict: 'ตรำดม',
            district: 'ลำดวน',
            province: 'สุรินทร์',
            zipcode: 32220,
        },
        {
            subDistrict: 'ลำดวน',
            district: 'ลำดวน',
            province: 'สุรินทร์',
            zipcode: 32220,
        },
        {
            subDistrict: 'อู่โลก',
            district: 'ลำดวน',
            province: 'สุรินทร์',
            zipcode: 32220,
        },
        {
            subDistrict: 'โชคเหนือ',
            district: 'ลำดวน',
            province: 'สุรินทร์',
            zipcode: 32220,
        },
        {
            subDistrict: 'ณรงค์',
            district: 'ศรีณรงค์',
            province: 'สุรินทร์',
            zipcode: 32150,
        },
        {
            subDistrict: 'ตรวจ',
            district: 'ศรีณรงค์',
            province: 'สุรินทร์',
            zipcode: 32150,
        },
        {
            subDistrict: 'ศรีสุข',
            district: 'ศรีณรงค์',
            province: 'สุรินทร์',
            zipcode: 32150,
        },
        {
            subDistrict: 'หนองแวง',
            district: 'ศรีณรงค์',
            province: 'สุรินทร์',
            zipcode: 32150,
        },
        {
            subDistrict: 'แจนแวน',
            district: 'ศรีณรงค์',
            province: 'สุรินทร์',
            zipcode: 32150,
        },
        {
            subDistrict: 'กุดหวาย',
            district: 'ศีขรภูมิ',
            province: 'สุรินทร์',
            zipcode: 32110,
        },
        {
            subDistrict: 'ขวาวใหญ่',
            district: 'ศีขรภูมิ',
            province: 'สุรินทร์',
            zipcode: 32110,
        },
        {
            subDistrict: 'คาละแมะ',
            district: 'ศีขรภูมิ',
            province: 'สุรินทร์',
            zipcode: 32110,
        },
        {
            subDistrict: 'จารพัต',
            district: 'ศีขรภูมิ',
            province: 'สุรินทร์',
            zipcode: 32110,
        },
        {
            subDistrict: 'ช่างปี่',
            district: 'ศีขรภูมิ',
            province: 'สุรินทร์',
            zipcode: 32110,
        },
        {
            subDistrict: 'ตรมไพร',
            district: 'ศีขรภูมิ',
            province: 'สุรินทร์',
            zipcode: 32110,
        },
        {
            subDistrict: 'ตรึม',
            district: 'ศีขรภูมิ',
            province: 'สุรินทร์',
            zipcode: 32110,
        },
        {
            subDistrict: 'นารุ่ง',
            district: 'ศีขรภูมิ',
            province: 'สุรินทร์',
            zipcode: 32110,
        },
        {
            subDistrict: 'ผักไหม',
            district: 'ศีขรภูมิ',
            province: 'สุรินทร์',
            zipcode: 32110,
        },
        {
            subDistrict: 'ยาง',
            district: 'ศีขรภูมิ',
            province: 'สุรินทร์',
            zipcode: 32110,
        },
        {
            subDistrict: 'ระแงง',
            district: 'ศีขรภูมิ',
            province: 'สุรินทร์',
            zipcode: 32110,
        },
        {
            subDistrict: 'หนองขวาว',
            district: 'ศีขรภูมิ',
            province: 'สุรินทร์',
            zipcode: 32110,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'ศีขรภูมิ',
            province: 'สุรินทร์',
            zipcode: 32110,
        },
        {
            subDistrict: 'หนองเหล็ก',
            district: 'ศีขรภูมิ',
            province: 'สุรินทร์',
            zipcode: 32110,
        },
        {
            subDistrict: 'แตล',
            district: 'ศีขรภูมิ',
            province: 'สุรินทร์',
            zipcode: 32110,
        },
        {
            subDistrict: 'นานวน',
            district: 'สนม',
            province: 'สุรินทร์',
            zipcode: 32160,
        },
        {
            subDistrict: 'สนม',
            district: 'สนม',
            province: 'สุรินทร์',
            zipcode: 32160,
        },
        {
            subDistrict: 'หนองระฆัง',
            district: 'สนม',
            province: 'สุรินทร์',
            zipcode: 32160,
        },
        {
            subDistrict: 'หนองอียอ',
            district: 'สนม',
            province: 'สุรินทร์',
            zipcode: 32160,
        },
        {
            subDistrict: 'หัวงัว',
            district: 'สนม',
            province: 'สุรินทร์',
            zipcode: 32160,
        },
        {
            subDistrict: 'แคน',
            district: 'สนม',
            province: 'สุรินทร์',
            zipcode: 32160,
        },
        {
            subDistrict: 'โพนโก',
            district: 'สนม',
            province: 'สุรินทร์',
            zipcode: 32160,
        },
        {
            subDistrict: 'กระเทียม',
            district: 'สังขะ',
            province: 'สุรินทร์',
            zipcode: 32150,
        },
        {
            subDistrict: 'ขอนแตก',
            district: 'สังขะ',
            province: 'สุรินทร์',
            zipcode: 32150,
        },
        {
            subDistrict: 'ดม',
            district: 'สังขะ',
            province: 'สุรินทร์',
            zipcode: 32150,
        },
        {
            subDistrict: 'ตาคง',
            district: 'สังขะ',
            province: 'สุรินทร์',
            zipcode: 32150,
        },
        {
            subDistrict: 'ตาตุม',
            district: 'สังขะ',
            province: 'สุรินทร์',
            zipcode: 32150,
        },
        {
            subDistrict: 'ทับทัน',
            district: 'สังขะ',
            province: 'สุรินทร์',
            zipcode: 32150,
        },
        {
            subDistrict: 'บ้านจารย์',
            district: 'สังขะ',
            province: 'สุรินทร์',
            zipcode: 32150,
        },
        {
            subDistrict: 'บ้านชบ',
            district: 'สังขะ',
            province: 'สุรินทร์',
            zipcode: 32150,
        },
        {
            subDistrict: 'พระแก้ว',
            district: 'สังขะ',
            province: 'สุรินทร์',
            zipcode: 32150,
        },
        {
            subDistrict: 'สะกาด',
            district: 'สังขะ',
            province: 'สุรินทร์',
            zipcode: 32150,
        },
        {
            subDistrict: 'สังขะ',
            district: 'สังขะ',
            province: 'สุรินทร์',
            zipcode: 32150,
        },
        {
            subDistrict: 'เทพรักษา',
            district: 'สังขะ',
            province: 'สุรินทร์',
            zipcode: 32150,
        },
        {
            subDistrict: 'กระออม',
            district: 'สำโรงทาบ',
            province: 'สุรินทร์',
            zipcode: 32170,
        },
        {
            subDistrict: 'ประดู่',
            district: 'สำโรงทาบ',
            province: 'สุรินทร์',
            zipcode: 32170,
        },
        {
            subDistrict: 'ศรีสุข',
            district: 'สำโรงทาบ',
            province: 'สุรินทร์',
            zipcode: 32170,
        },
        {
            subDistrict: 'สะโน',
            district: 'สำโรงทาบ',
            province: 'สุรินทร์',
            zipcode: 32170,
        },
        {
            subDistrict: 'สำโรงทาบ',
            district: 'สำโรงทาบ',
            province: 'สุรินทร์',
            zipcode: 32170,
        },
        {
            subDistrict: 'หนองฮะ',
            district: 'สำโรงทาบ',
            province: 'สุรินทร์',
            zipcode: 32170,
        },
        {
            subDistrict: 'หนองไผ่ล้อม',
            district: 'สำโรงทาบ',
            province: 'สุรินทร์',
            zipcode: 32170,
        },
        {
            subDistrict: 'หมื่นศรี',
            district: 'สำโรงทาบ',
            province: 'สุรินทร์',
            zipcode: 32170,
        },
        {
            subDistrict: 'เกาะแก้ว',
            district: 'สำโรงทาบ',
            province: 'สุรินทร์',
            zipcode: 32170,
        },
        {
            subDistrict: 'เสม็จ',
            district: 'สำโรงทาบ',
            province: 'สุรินทร์',
            zipcode: 32170,
        },
        {
            subDistrict: 'ตากูก',
            district: 'เขวาสินรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'บึง',
            district: 'เขวาสินรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'บ้านแร่',
            district: 'เขวาสินรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'ปราสาททอง',
            district: 'เขวาสินรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'เขวาสินรินทร์',
            district: 'เขวาสินรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'กาเกาะ',
            district: 'เมืองสุรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'คอโค',
            district: 'เมืองสุรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'ตระแสง',
            district: 'เมืองสุรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'ตั้งใจ',
            district: 'เมืองสุรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'ตาอ็อง',
            district: 'เมืองสุรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'ท่าสว่าง',
            district: 'เมืองสุรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'นอกเมือง',
            district: 'เมืองสุรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'นาดี',
            district: 'เมืองสุรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'นาบัว',
            district: 'เมืองสุรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'บุฤาษี',
            district: 'เมืองสุรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'ราม',
            district: 'เมืองสุรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'สลักได',
            district: 'เมืองสุรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'สวาย',
            district: 'เมืองสุรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'สำโรง',
            district: 'เมืองสุรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'เฉนียง',
            district: 'เมืองสุรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'เทนมีย์',
            district: 'เมืองสุรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'เพี้ยราม',
            district: 'เมืองสุรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'เมืองที',
            district: 'เมืองสุรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'แกใหญ่',
            district: 'เมืองสุรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'แสลงพันธ์',
            district: 'เมืองสุรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'เมืองสุรินทร์',
            province: 'สุรินทร์',
            zipcode: 32000,
        },
        {
            subDistrict: 'คำผง',
            district: 'โนนนารายณ์',
            province: 'สุรินทร์',
            zipcode: 32130,
        },
        {
            subDistrict: 'ระเวียง',
            district: 'โนนนารายณ์',
            province: 'สุรินทร์',
            zipcode: 32130,
        },
        {
            subDistrict: 'หนองหลวง',
            district: 'โนนนารายณ์',
            province: 'สุรินทร์',
            zipcode: 32130,
        },
        {
            subDistrict: 'หนองเทพ',
            district: 'โนนนารายณ์',
            province: 'สุรินทร์',
            zipcode: 32130,
        },
        {
            subDistrict: 'โนน',
            district: 'โนนนารายณ์',
            province: 'สุรินทร์',
            zipcode: 32130,
        },
        {
            subDistrict: 'กกแรต',
            district: 'กงไกรลาศ',
            province: 'สุโขทัย',
            zipcode: 64170,
        },
        {
            subDistrict: 'กง',
            district: 'กงไกรลาศ',
            province: 'สุโขทัย',
            zipcode: 64170,
        },
        {
            subDistrict: 'ดงเดือย',
            district: 'กงไกรลาศ',
            province: 'สุโขทัย',
            zipcode: 64170,
        },
        {
            subDistrict: 'ท่าฉนวน',
            district: 'กงไกรลาศ',
            province: 'สุโขทัย',
            zipcode: 64170,
        },
        {
            subDistrict: 'บ้านกร่าง',
            district: 'กงไกรลาศ',
            province: 'สุโขทัย',
            zipcode: 64170,
        },
        {
            subDistrict: 'บ้านใหม่สุขเกษม',
            district: 'กงไกรลาศ',
            province: 'สุโขทัย',
            zipcode: 64170,
        },
        {
            subDistrict: 'ป่าแฝก',
            district: 'กงไกรลาศ',
            province: 'สุโขทัย',
            zipcode: 64170,
        },
        {
            subDistrict: 'หนองตูม',
            district: 'กงไกรลาศ',
            province: 'สุโขทัย',
            zipcode: 64170,
        },
        {
            subDistrict: 'ไกรกลาง',
            district: 'กงไกรลาศ',
            province: 'สุโขทัย',
            zipcode: 64170,
        },
        {
            subDistrict: 'ไกรนอก',
            district: 'กงไกรลาศ',
            province: 'สุโขทัย',
            zipcode: 64170,
        },
        {
            subDistrict: 'ไกรใน',
            district: 'กงไกรลาศ',
            province: 'สุโขทัย',
            zipcode: 64170,
        },
        {
            subDistrict: 'ทุ่งยางเมือง',
            district: 'คีรีมาศ',
            province: 'สุโขทัย',
            zipcode: 64160,
        },
        {
            subDistrict: 'ทุ่งหลวง',
            district: 'คีรีมาศ',
            province: 'สุโขทัย',
            zipcode: 64160,
        },
        {
            subDistrict: 'นาเชิงคีรี',
            district: 'คีรีมาศ',
            province: 'สุโขทัย',
            zipcode: 64160,
        },
        {
            subDistrict: 'บ้านน้ำพุ',
            district: 'คีรีมาศ',
            province: 'สุโขทัย',
            zipcode: 64160,
        },
        {
            subDistrict: 'บ้านป้อม',
            district: 'คีรีมาศ',
            province: 'สุโขทัย',
            zipcode: 64160,
        },
        {
            subDistrict: 'ศรีคีรีมาศ',
            district: 'คีรีมาศ',
            province: 'สุโขทัย',
            zipcode: 64160,
        },
        {
            subDistrict: 'สามพวง',
            district: 'คีรีมาศ',
            province: 'สุโขทัย',
            zipcode: 64160,
        },
        {
            subDistrict: 'หนองกระดิ่ง',
            district: 'คีรีมาศ',
            province: 'สุโขทัย',
            zipcode: 64160,
        },
        {
            subDistrict: 'หนองจิก',
            district: 'คีรีมาศ',
            province: 'สุโขทัย',
            zipcode: 64160,
        },
        {
            subDistrict: 'โตนด',
            district: 'คีรีมาศ',
            province: 'สุโขทัย',
            zipcode: 64160,
        },
        {
            subDistrict: 'กลางดง',
            district: 'ทุ่งเสลี่ยม',
            province: 'สุโขทัย',
            zipcode: 64150,
        },
        {
            subDistrict: 'ทุ่งเสลี่ยม',
            district: 'ทุ่งเสลี่ยม',
            province: 'สุโขทัย',
            zipcode: 64150,
        },
        {
            subDistrict: 'บ้านใหม่ไชยมงคล',
            district: 'ทุ่งเสลี่ยม',
            province: 'สุโขทัย',
            zipcode: 64230,
        },
        {
            subDistrict: 'เขาแก้วศรีสมบูรณ์',
            district: 'ทุ่งเสลี่ยม',
            province: 'สุโขทัย',
            zipcode: 64230,
        },
        {
            subDistrict: 'ไทยชนะศึก',
            district: 'ทุ่งเสลี่ยม',
            province: 'สุโขทัย',
            zipcode: 64150,
        },
        {
            subDistrict: 'ตลิ่งชัน',
            district: 'บ้านด่านลานหอย',
            province: 'สุโขทัย',
            zipcode: 64140,
        },
        {
            subDistrict: 'บ้านด่าน',
            district: 'บ้านด่านลานหอย',
            province: 'สุโขทัย',
            zipcode: 64140,
        },
        {
            subDistrict: 'ลานหอย',
            district: 'บ้านด่านลานหอย',
            province: 'สุโขทัย',
            zipcode: 64140,
        },
        {
            subDistrict: 'วังตะคร้อ',
            district: 'บ้านด่านลานหอย',
            province: 'สุโขทัย',
            zipcode: 64140,
        },
        {
            subDistrict: 'วังน้ำขาว',
            district: 'บ้านด่านลานหอย',
            province: 'สุโขทัย',
            zipcode: 64140,
        },
        {
            subDistrict: 'วังลึก',
            district: 'บ้านด่านลานหอย',
            province: 'สุโขทัย',
            zipcode: 64140,
        },
        {
            subDistrict: 'หนองหญ้าปล้อง',
            district: 'บ้านด่านลานหอย',
            province: 'สุโขทัย',
            zipcode: 64140,
        },
        {
            subDistrict: 'คลองมะพลับ',
            district: 'ศรีนคร',
            province: 'สุโขทัย',
            zipcode: 64180,
        },
        {
            subDistrict: 'นครเดิฐ',
            district: 'ศรีนคร',
            province: 'สุโขทัย',
            zipcode: 64180,
        },
        {
            subDistrict: 'น้ำขุม',
            district: 'ศรีนคร',
            province: 'สุโขทัย',
            zipcode: 64180,
        },
        {
            subDistrict: 'ศรีนคร',
            district: 'ศรีนคร',
            province: 'สุโขทัย',
            zipcode: 64180,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'ศรีนคร',
            province: 'สุโขทัย',
            zipcode: 64180,
        },
        {
            subDistrict: 'ดงคู่',
            district: 'ศรีสัชนาลัย',
            province: 'สุโขทัย',
            zipcode: 64130,
        },
        {
            subDistrict: 'ท่าชัย',
            district: 'ศรีสัชนาลัย',
            province: 'สุโขทัย',
            zipcode: 64190,
        },
        {
            subDistrict: 'บ้านตึก',
            district: 'ศรีสัชนาลัย',
            province: 'สุโขทัย',
            zipcode: 64130,
        },
        {
            subDistrict: 'บ้านแก่ง',
            district: 'ศรีสัชนาลัย',
            province: 'สุโขทัย',
            zipcode: 64130,
        },
        {
            subDistrict: 'ป่างิ้ว',
            district: 'ศรีสัชนาลัย',
            province: 'สุโขทัย',
            zipcode: 64130,
        },
        {
            subDistrict: 'ศรีสัชนาลัย',
            district: 'ศรีสัชนาลัย',
            province: 'สุโขทัย',
            zipcode: 64190,
        },
        {
            subDistrict: 'สารจิตร',
            district: 'ศรีสัชนาลัย',
            province: 'สุโขทัย',
            zipcode: 64130,
        },
        {
            subDistrict: 'หนองอ้อ',
            district: 'ศรีสัชนาลัย',
            province: 'สุโขทัย',
            zipcode: 64130,
        },
        {
            subDistrict: 'หาดเสี้ยว',
            district: 'ศรีสัชนาลัย',
            province: 'สุโขทัย',
            zipcode: 64130,
        },
        {
            subDistrict: 'แม่สำ',
            district: 'ศรีสัชนาลัย',
            province: 'สุโขทัย',
            zipcode: 64130,
        },
        {
            subDistrict: 'แม่สิน',
            district: 'ศรีสัชนาลัย',
            province: 'สุโขทัย',
            zipcode: 64130,
        },
        {
            subDistrict: 'คลองตาล',
            district: 'ศรีสำโรง',
            province: 'สุโขทัย',
            zipcode: 64120,
        },
        {
            subDistrict: 'ทับผึ้ง',
            district: 'ศรีสำโรง',
            province: 'สุโขทัย',
            zipcode: 64120,
        },
        {
            subDistrict: 'นาขุนไกร',
            district: 'ศรีสำโรง',
            province: 'สุโขทัย',
            zipcode: 64120,
        },
        {
            subDistrict: 'บ้านซ่าน',
            district: 'ศรีสำโรง',
            province: 'สุโขทัย',
            zipcode: 64120,
        },
        {
            subDistrict: 'บ้านนา',
            district: 'ศรีสำโรง',
            province: 'สุโขทัย',
            zipcode: 64120,
        },
        {
            subDistrict: 'บ้านไร่',
            district: 'ศรีสำโรง',
            province: 'สุโขทัย',
            zipcode: 64120,
        },
        {
            subDistrict: 'ราวต้นจันทร์',
            district: 'ศรีสำโรง',
            province: 'สุโขทัย',
            zipcode: 64120,
        },
        {
            subDistrict: 'วังทอง',
            district: 'ศรีสำโรง',
            province: 'สุโขทัย',
            zipcode: 64120,
        },
        {
            subDistrict: 'วังลึก',
            district: 'ศรีสำโรง',
            province: 'สุโขทัย',
            zipcode: 64120,
        },
        {
            subDistrict: 'วังใหญ่',
            district: 'ศรีสำโรง',
            province: 'สุโขทัย',
            zipcode: 64120,
        },
        {
            subDistrict: 'วัดเกาะ',
            district: 'ศรีสำโรง',
            province: 'สุโขทัย',
            zipcode: 64120,
        },
        {
            subDistrict: 'สามเรือน',
            district: 'ศรีสำโรง',
            province: 'สุโขทัย',
            zipcode: 64120,
        },
        {
            subDistrict: 'เกาะตาเลี้ยง',
            district: 'ศรีสำโรง',
            province: 'สุโขทัย',
            zipcode: 64120,
        },
        {
            subDistrict: 'คลองกระจง',
            district: 'สวรรคโลก',
            province: 'สุโขทัย',
            zipcode: 64110,
        },
        {
            subDistrict: 'คลองยาง',
            district: 'สวรรคโลก',
            province: 'สุโขทัย',
            zipcode: 64110,
        },
        {
            subDistrict: 'ท่าทอง',
            district: 'สวรรคโลก',
            province: 'สุโขทัย',
            zipcode: 64110,
        },
        {
            subDistrict: 'นาทุ่ง',
            district: 'สวรรคโลก',
            province: 'สุโขทัย',
            zipcode: 64110,
        },
        {
            subDistrict: 'ปากน้ำ',
            district: 'สวรรคโลก',
            province: 'สุโขทัย',
            zipcode: 64110,
        },
        {
            subDistrict: 'ป่ากุมเกาะ',
            district: 'สวรรคโลก',
            province: 'สุโขทัย',
            zipcode: 64110,
        },
        {
            subDistrict: 'ย่านยาว',
            district: 'สวรรคโลก',
            province: 'สุโขทัย',
            zipcode: 64110,
        },
        {
            subDistrict: 'วังพิณพาทย์',
            district: 'สวรรคโลก',
            province: 'สุโขทัย',
            zipcode: 64110,
        },
        {
            subDistrict: 'วังไม้ขอน',
            district: 'สวรรคโลก',
            province: 'สุโขทัย',
            zipcode: 64110,
        },
        {
            subDistrict: 'หนองกลับ',
            district: 'สวรรคโลก',
            province: 'สุโขทัย',
            zipcode: 64110,
        },
        {
            subDistrict: 'เมืองบางขลัง',
            district: 'สวรรคโลก',
            province: 'สุโขทัย',
            zipcode: 64110,
        },
        {
            subDistrict: 'เมืองบางยม',
            district: 'สวรรคโลก',
            province: 'สุโขทัย',
            zipcode: 64110,
        },
        {
            subDistrict: 'เมืองสวรรคโลก',
            district: 'สวรรคโลก',
            province: 'สุโขทัย',
            zipcode: 64110,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'สวรรคโลก',
            province: 'สุโขทัย',
            zipcode: 64110,
        },
        {
            subDistrict: 'ตาลเตี้ย',
            district: 'เมืองสุโขทัย',
            province: 'สุโขทัย',
            zipcode: 64220,
        },
        {
            subDistrict: 'ธานี',
            district: 'เมืองสุโขทัย',
            province: 'สุโขทัย',
            zipcode: 64000,
        },
        {
            subDistrict: 'บ้านกล้วย',
            district: 'เมืองสุโขทัย',
            province: 'สุโขทัย',
            zipcode: 64000,
        },
        {
            subDistrict: 'บ้านสวน',
            district: 'เมืองสุโขทัย',
            province: 'สุโขทัย',
            zipcode: 64220,
        },
        {
            subDistrict: 'บ้านหลุม',
            district: 'เมืองสุโขทัย',
            province: 'สุโขทัย',
            zipcode: 64000,
        },
        {
            subDistrict: 'ปากพระ',
            district: 'เมืองสุโขทัย',
            province: 'สุโขทัย',
            zipcode: 64000,
        },
        {
            subDistrict: 'ปากแคว',
            district: 'เมืองสุโขทัย',
            province: 'สุโขทัย',
            zipcode: 64000,
        },
        {
            subDistrict: 'ยางซ้าย',
            district: 'เมืองสุโขทัย',
            province: 'สุโขทัย',
            zipcode: 64000,
        },
        {
            subDistrict: 'วังทองแดง',
            district: 'เมืองสุโขทัย',
            province: 'สุโขทัย',
            zipcode: 64210,
        },
        {
            subDistrict: 'เมืองเก่า',
            district: 'เมืองสุโขทัย',
            province: 'สุโขทัย',
            zipcode: 64210,
        },
        {
            subDistrict: 'กองนาง',
            district: 'ท่าบ่อ',
            province: 'หนองคาย',
            zipcode: 43110,
        },
        {
            subDistrict: 'ท่าบ่อ',
            district: 'ท่าบ่อ',
            province: 'หนองคาย',
            zipcode: 43110,
        },
        {
            subDistrict: 'นาข่า',
            district: 'ท่าบ่อ',
            province: 'หนองคาย',
            zipcode: 43110,
        },
        {
            subDistrict: 'น้ำโมง',
            district: 'ท่าบ่อ',
            province: 'หนองคาย',
            zipcode: 43110,
        },
        {
            subDistrict: 'บ้านถ่อน',
            district: 'ท่าบ่อ',
            province: 'หนองคาย',
            zipcode: 43110,
        },
        {
            subDistrict: 'บ้านว่าน',
            district: 'ท่าบ่อ',
            province: 'หนองคาย',
            zipcode: 43110,
        },
        {
            subDistrict: 'บ้านเดื่อ',
            district: 'ท่าบ่อ',
            province: 'หนองคาย',
            zipcode: 43110,
        },
        {
            subDistrict: 'หนองนาง',
            district: 'ท่าบ่อ',
            province: 'หนองคาย',
            zipcode: 43110,
        },
        {
            subDistrict: 'โคกคอน',
            district: 'ท่าบ่อ',
            province: 'หนองคาย',
            zipcode: 43110,
        },
        {
            subDistrict: 'โพนสา',
            district: 'ท่าบ่อ',
            province: 'หนองคาย',
            zipcode: 43110,
        },
        {
            subDistrict: 'นาทับไฮ',
            district: 'รัตนวาปี',
            province: 'หนองคาย',
            zipcode: 43120,
        },
        {
            subDistrict: 'บ้านต้อน',
            district: 'รัตนวาปี',
            province: 'หนองคาย',
            zipcode: 43120,
        },
        {
            subDistrict: 'พระบาทนาสิงห์',
            district: 'รัตนวาปี',
            province: 'หนองคาย',
            zipcode: 43120,
        },
        {
            subDistrict: 'รัตนวาปี',
            district: 'รัตนวาปี',
            province: 'หนองคาย',
            zipcode: 43120,
        },
        {
            subDistrict: 'โพนแพง',
            district: 'รัตนวาปี',
            province: 'หนองคาย',
            zipcode: 43120,
        },
        {
            subDistrict: 'บ้านหม้อ',
            district: 'ศรีเชียงใหม่',
            province: 'หนองคาย',
            zipcode: 43130,
        },
        {
            subDistrict: 'พระพุทธบาท',
            district: 'ศรีเชียงใหม่',
            province: 'หนองคาย',
            zipcode: 43130,
        },
        {
            subDistrict: 'พานพร้าว',
            district: 'ศรีเชียงใหม่',
            province: 'หนองคาย',
            zipcode: 43130,
        },
        {
            subDistrict: 'หนองปลาปาก',
            district: 'ศรีเชียงใหม่',
            province: 'หนองคาย',
            zipcode: 43130,
        },
        {
            subDistrict: 'คอกช้าง',
            district: 'สระใคร',
            province: 'หนองคาย',
            zipcode: 43100,
        },
        {
            subDistrict: 'บ้านฝาง',
            district: 'สระใคร',
            province: 'หนองคาย',
            zipcode: 43100,
        },
        {
            subDistrict: 'สระใคร',
            district: 'สระใคร',
            province: 'หนองคาย',
            zipcode: 43100,
        },
        {
            subDistrict: 'นางิ้ว',
            district: 'สังคม',
            province: 'หนองคาย',
            zipcode: 43160,
        },
        {
            subDistrict: 'บ้านม่วง',
            district: 'สังคม',
            province: 'หนองคาย',
            zipcode: 43160,
        },
        {
            subDistrict: 'ผาตั้ง',
            district: 'สังคม',
            province: 'หนองคาย',
            zipcode: 43160,
        },
        {
            subDistrict: 'สังคม',
            district: 'สังคม',
            province: 'หนองคาย',
            zipcode: 43160,
        },
        {
            subDistrict: 'แก้งไก่',
            district: 'สังคม',
            province: 'หนองคาย',
            zipcode: 43160,
        },
        {
            subDistrict: 'นาดี',
            district: 'เฝ้าไร่',
            province: 'หนองคาย',
            zipcode: 43120,
        },
        {
            subDistrict: 'วังหลวง',
            district: 'เฝ้าไร่',
            province: 'หนองคาย',
            zipcode: 43120,
        },
        {
            subDistrict: 'หนองหลวง',
            district: 'เฝ้าไร่',
            province: 'หนองคาย',
            zipcode: 43120,
        },
        {
            subDistrict: 'อุดมพร',
            district: 'เฝ้าไร่',
            province: 'หนองคาย',
            zipcode: 43120,
        },
        {
            subDistrict: 'เฝ้าไร่',
            district: 'เฝ้าไร่',
            province: 'หนองคาย',
            zipcode: 43120,
        },
        {
            subDistrict: 'กวนวัน',
            district: 'เมืองหนองคาย',
            province: 'หนองคาย',
            zipcode: 43000,
        },
        {
            subDistrict: 'ค่ายบกหวาน',
            district: 'เมืองหนองคาย',
            province: 'หนองคาย',
            zipcode: 43100,
        },
        {
            subDistrict: 'บ้านเดื่อ',
            district: 'เมืองหนองคาย',
            province: 'หนองคาย',
            zipcode: 43000,
        },
        {
            subDistrict: 'ปะโค',
            district: 'เมืองหนองคาย',
            province: 'หนองคาย',
            zipcode: 43000,
        },
        {
            subDistrict: 'พระธาตุบังพวน',
            district: 'เมืองหนองคาย',
            province: 'หนองคาย',
            zipcode: 43100,
        },
        {
            subDistrict: 'มีชัย',
            district: 'เมืองหนองคาย',
            province: 'หนองคาย',
            zipcode: 43000,
        },
        {
            subDistrict: 'วัดธาตุ',
            district: 'เมืองหนองคาย',
            province: 'หนองคาย',
            zipcode: 43000,
        },
        {
            subDistrict: 'สองห้อง',
            district: 'เมืองหนองคาย',
            province: 'หนองคาย',
            zipcode: 43100,
        },
        {
            subDistrict: 'สีกาย',
            district: 'เมืองหนองคาย',
            province: 'หนองคาย',
            zipcode: 43000,
        },
        {
            subDistrict: 'หนองกอมเกาะ',
            district: 'เมืองหนองคาย',
            province: 'หนองคาย',
            zipcode: 43000,
        },
        {
            subDistrict: 'หาดคำ',
            district: 'เมืองหนองคาย',
            province: 'หนองคาย',
            zipcode: 43000,
        },
        {
            subDistrict: 'หินโงม',
            district: 'เมืองหนองคาย',
            province: 'หนองคาย',
            zipcode: 43000,
        },
        {
            subDistrict: 'เมืองหมี',
            district: 'เมืองหนองคาย',
            province: 'หนองคาย',
            zipcode: 43000,
        },
        {
            subDistrict: 'เวียงคุก',
            district: 'เมืองหนองคาย',
            province: 'หนองคาย',
            zipcode: 43000,
        },
        {
            subDistrict: 'โพธิ์ชัย',
            district: 'เมืองหนองคาย',
            province: 'หนองคาย',
            zipcode: 43000,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'เมืองหนองคาย',
            province: 'หนองคาย',
            zipcode: 43000,
        },
        {
            subDistrict: 'ด่านศรีสุข',
            district: 'โพธิ์ตาก',
            province: 'หนองคาย',
            zipcode: 43130,
        },
        {
            subDistrict: 'โพธิ์ตาก',
            district: 'โพธิ์ตาก',
            province: 'หนองคาย',
            zipcode: 43130,
        },
        {
            subDistrict: 'โพนทอง',
            district: 'โพธิ์ตาก',
            province: 'หนองคาย',
            zipcode: 43130,
        },
        {
            subDistrict: 'กุดบง',
            district: 'โพนพิสัย',
            province: 'หนองคาย',
            zipcode: 43120,
        },
        {
            subDistrict: 'จุมพล',
            district: 'โพนพิสัย',
            province: 'หนองคาย',
            zipcode: 43120,
        },
        {
            subDistrict: 'ชุมช้าง',
            district: 'โพนพิสัย',
            province: 'หนองคาย',
            zipcode: 43120,
        },
        {
            subDistrict: 'ทุ่งหลวง',
            district: 'โพนพิสัย',
            province: 'หนองคาย',
            zipcode: 43120,
        },
        {
            subDistrict: 'นาหนัง',
            district: 'โพนพิสัย',
            province: 'หนองคาย',
            zipcode: 43120,
        },
        {
            subDistrict: 'บ้านผือ',
            district: 'โพนพิสัย',
            province: 'หนองคาย',
            zipcode: 43120,
        },
        {
            subDistrict: 'บ้านโพธิ์',
            district: 'โพนพิสัย',
            province: 'หนองคาย',
            zipcode: 43120,
        },
        {
            subDistrict: 'วัดหลวง',
            district: 'โพนพิสัย',
            province: 'หนองคาย',
            zipcode: 43120,
        },
        {
            subDistrict: 'สร้างนางขาว',
            district: 'โพนพิสัย',
            province: 'หนองคาย',
            zipcode: 43120,
        },
        {
            subDistrict: 'เซิม',
            district: 'โพนพิสัย',
            province: 'หนองคาย',
            zipcode: 43120,
        },
        {
            subDistrict: 'เหล่าต่างคำ',
            district: 'โพนพิสัย',
            province: 'หนองคาย',
            zipcode: 43120,
        },
        {
            subDistrict: 'กุดดินจี่',
            district: 'นากลาง',
            province: 'หนองบัวลำภู',
            zipcode: 39350,
        },
        {
            subDistrict: 'กุดแห่',
            district: 'นากลาง',
            province: 'หนองบัวลำภู',
            zipcode: 39170,
        },
        {
            subDistrict: 'ดงสวรรค์',
            district: 'นากลาง',
            province: 'หนองบัวลำภู',
            zipcode: 39350,
        },
        {
            subDistrict: 'ด่านช้าง',
            district: 'นากลาง',
            province: 'หนองบัวลำภู',
            zipcode: 39170,
        },
        {
            subDistrict: 'นากลาง',
            district: 'นากลาง',
            province: 'หนองบัวลำภู',
            zipcode: 39170,
        },
        {
            subDistrict: 'ฝั่งแดง',
            district: 'นากลาง',
            province: 'หนองบัวลำภู',
            zipcode: 39170,
        },
        {
            subDistrict: 'อุทัยสวรรค์',
            district: 'นากลาง',
            province: 'หนองบัวลำภู',
            zipcode: 39170,
        },
        {
            subDistrict: 'เก่ากลอย',
            district: 'นากลาง',
            province: 'หนองบัวลำภู',
            zipcode: 39350,
        },
        {
            subDistrict: 'โนนเมือง',
            district: 'นากลาง',
            province: 'หนองบัวลำภู',
            zipcode: 39170,
        },
        {
            subDistrict: 'นาเหล่า',
            district: 'นาวัง',
            province: 'หนองบัวลำภู',
            zipcode: 39170,
        },
        {
            subDistrict: 'นาแก',
            district: 'นาวัง',
            province: 'หนองบัวลำภู',
            zipcode: 39170,
        },
        {
            subDistrict: 'วังทอง',
            district: 'นาวัง',
            province: 'หนองบัวลำภู',
            zipcode: 39170,
        },
        {
            subDistrict: 'วังปลาป้อม',
            district: 'นาวัง',
            province: 'หนองบัวลำภู',
            zipcode: 39170,
        },
        {
            subDistrict: 'เทพคีรี',
            district: 'นาวัง',
            province: 'หนองบัวลำภู',
            zipcode: 39170,
        },
        {
            subDistrict: 'กุดสะเทียน',
            district: 'ศรีบุญเรือง',
            province: 'หนองบัวลำภู',
            zipcode: 39180,
        },
        {
            subDistrict: 'ทรายทอง',
            district: 'ศรีบุญเรือง',
            province: 'หนองบัวลำภู',
            zipcode: 39180,
        },
        {
            subDistrict: 'นากอก',
            district: 'ศรีบุญเรือง',
            province: 'หนองบัวลำภู',
            zipcode: 39180,
        },
        {
            subDistrict: 'ยางหล่อ',
            district: 'ศรีบุญเรือง',
            province: 'หนองบัวลำภู',
            zipcode: 39180,
        },
        {
            subDistrict: 'ศรีบุญเรือง',
            district: 'ศรีบุญเรือง',
            province: 'หนองบัวลำภู',
            zipcode: 39180,
        },
        {
            subDistrict: 'หนองกุงแก้ว',
            district: 'ศรีบุญเรือง',
            province: 'หนองบัวลำภู',
            zipcode: 39180,
        },
        {
            subDistrict: 'หนองบัวใต้',
            district: 'ศรีบุญเรือง',
            province: 'หนองบัวลำภู',
            zipcode: 39180,
        },
        {
            subDistrict: 'หนองแก',
            district: 'ศรีบุญเรือง',
            province: 'หนองบัวลำภู',
            zipcode: 39180,
        },
        {
            subDistrict: 'หันนางาม',
            district: 'ศรีบุญเรือง',
            province: 'หนองบัวลำภู',
            zipcode: 39180,
        },
        {
            subDistrict: 'เมืองใหม่',
            district: 'ศรีบุญเรือง',
            province: 'หนองบัวลำภู',
            zipcode: 39180,
        },
        {
            subDistrict: 'โนนม่วง',
            district: 'ศรีบุญเรือง',
            province: 'หนองบัวลำภู',
            zipcode: 39180,
        },
        {
            subDistrict: 'โนนสะอาด',
            district: 'ศรีบุญเรือง',
            province: 'หนองบัวลำภู',
            zipcode: 39180,
        },
        {
            subDistrict: 'กุดผึ้ง',
            district: 'สุวรรณคูหา',
            province: 'หนองบัวลำภู',
            zipcode: 39270,
        },
        {
            subDistrict: 'ดงมะไฟ',
            district: 'สุวรรณคูหา',
            province: 'หนองบัวลำภู',
            zipcode: 39270,
        },
        {
            subDistrict: 'นาดี',
            district: 'สุวรรณคูหา',
            province: 'หนองบัวลำภู',
            zipcode: 39270,
        },
        {
            subDistrict: 'นาด่าน',
            district: 'สุวรรณคูหา',
            province: 'หนองบัวลำภู',
            zipcode: 39270,
        },
        {
            subDistrict: 'นาสี',
            district: 'สุวรรณคูหา',
            province: 'หนองบัวลำภู',
            zipcode: 39270,
        },
        {
            subDistrict: 'บุญทัน',
            district: 'สุวรรณคูหา',
            province: 'หนองบัวลำภู',
            zipcode: 39270,
        },
        {
            subDistrict: 'บ้านโคก',
            district: 'สุวรรณคูหา',
            province: 'หนองบัวลำภู',
            zipcode: 39270,
        },
        {
            subDistrict: 'สุวรรณคูหา',
            district: 'สุวรรณคูหา',
            province: 'หนองบัวลำภู',
            zipcode: 39270,
        },
        {
            subDistrict: 'กุดจิก',
            district: 'เมืองหนองบัวลำภู',
            province: 'หนองบัวลำภู',
            zipcode: 39000,
        },
        {
            subDistrict: 'นาคำไฮ',
            district: 'เมืองหนองบัวลำภู',
            province: 'หนองบัวลำภู',
            zipcode: 39000,
        },
        {
            subDistrict: 'นามะเฟือง',
            district: 'เมืองหนองบัวลำภู',
            province: 'หนองบัวลำภู',
            zipcode: 39000,
        },
        {
            subDistrict: 'บ้านขาม',
            district: 'เมืองหนองบัวลำภู',
            province: 'หนองบัวลำภู',
            zipcode: 39000,
        },
        {
            subDistrict: 'บ้านพร้าว',
            district: 'เมืองหนองบัวลำภู',
            province: 'หนองบัวลำภู',
            zipcode: 39000,
        },
        {
            subDistrict: 'ป่าไม้งาม',
            district: 'เมืองหนองบัวลำภู',
            province: 'หนองบัวลำภู',
            zipcode: 39000,
        },
        {
            subDistrict: 'ลำภู',
            district: 'เมืองหนองบัวลำภู',
            province: 'หนองบัวลำภู',
            zipcode: 39000,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'เมืองหนองบัวลำภู',
            province: 'หนองบัวลำภู',
            zipcode: 39000,
        },
        {
            subDistrict: 'หนองภัยศูนย์',
            district: 'เมืองหนองบัวลำภู',
            province: 'หนองบัวลำภู',
            zipcode: 39000,
        },
        {
            subDistrict: 'หนองสวรรค์',
            district: 'เมืองหนองบัวลำภู',
            province: 'หนองบัวลำภู',
            zipcode: 39000,
        },
        {
            subDistrict: 'หนองหว้า',
            district: 'เมืองหนองบัวลำภู',
            province: 'หนองบัวลำภู',
            zipcode: 39000,
        },
        {
            subDistrict: 'หัวนา',
            district: 'เมืองหนองบัวลำภู',
            province: 'หนองบัวลำภู',
            zipcode: 39000,
        },
        {
            subDistrict: 'โนนขมิ้น',
            district: 'เมืองหนองบัวลำภู',
            province: 'หนองบัวลำภู',
            zipcode: 39000,
        },
        {
            subDistrict: 'โนนทัน',
            district: 'เมืองหนองบัวลำภู',
            province: 'หนองบัวลำภู',
            zipcode: 39000,
        },
        {
            subDistrict: 'โพธิ์ชัย',
            district: 'เมืองหนองบัวลำภู',
            province: 'หนองบัวลำภู',
            zipcode: 39000,
        },
        {
            subDistrict: 'กุดดู่',
            district: 'โนนสัง',
            province: 'หนองบัวลำภู',
            zipcode: 39140,
        },
        {
            subDistrict: 'นิคมพัฒนา',
            district: 'โนนสัง',
            province: 'หนองบัวลำภู',
            zipcode: 39140,
        },
        {
            subDistrict: 'บ้านค้อ',
            district: 'โนนสัง',
            province: 'หนองบัวลำภู',
            zipcode: 39140,
        },
        {
            subDistrict: 'บ้านถิ่น',
            district: 'โนนสัง',
            province: 'หนองบัวลำภู',
            zipcode: 39140,
        },
        {
            subDistrict: 'ปางกู่',
            district: 'โนนสัง',
            province: 'หนองบัวลำภู',
            zipcode: 39140,
        },
        {
            subDistrict: 'หนองเรือ',
            district: 'โนนสัง',
            province: 'หนองบัวลำภู',
            zipcode: 39140,
        },
        {
            subDistrict: 'โคกม่วง',
            district: 'โนนสัง',
            province: 'หนองบัวลำภู',
            zipcode: 39140,
        },
        {
            subDistrict: 'โคกใหญ่',
            district: 'โนนสัง',
            province: 'หนองบัวลำภู',
            zipcode: 39140,
        },
        {
            subDistrict: 'โนนสัง',
            district: 'โนนสัง',
            province: 'หนองบัวลำภู',
            zipcode: 39140,
        },
        {
            subDistrict: 'โนนเมือง',
            district: 'โนนสัง',
            province: 'หนองบัวลำภู',
            zipcode: 39140,
        },
        {
            subDistrict: 'คำเขื่อนแก้ว',
            district: 'ชานุมาน',
            province: 'อำนาจเจริญ',
            zipcode: 37210,
        },
        {
            subDistrict: 'ชานุมาน',
            district: 'ชานุมาน',
            province: 'อำนาจเจริญ',
            zipcode: 37210,
        },
        {
            subDistrict: 'ป่าก่อ',
            district: 'ชานุมาน',
            province: 'อำนาจเจริญ',
            zipcode: 37210,
        },
        {
            subDistrict: 'โคกก่ง',
            district: 'ชานุมาน',
            province: 'อำนาจเจริญ',
            zipcode: 37210,
        },
        {
            subDistrict: 'โคกสาร',
            district: 'ชานุมาน',
            province: 'อำนาจเจริญ',
            zipcode: 37210,
        },
        {
            subDistrict: 'คำโพน',
            district: 'ปทุมราชวงศา',
            province: 'อำนาจเจริญ',
            zipcode: 37110,
        },
        {
            subDistrict: 'นาป่าแซง',
            district: 'ปทุมราชวงศา',
            province: 'อำนาจเจริญ',
            zipcode: 37110,
        },
        {
            subDistrict: 'นาหว้า',
            district: 'ปทุมราชวงศา',
            province: 'อำนาจเจริญ',
            zipcode: 37110,
        },
        {
            subDistrict: 'ลือ',
            district: 'ปทุมราชวงศา',
            province: 'อำนาจเจริญ',
            zipcode: 37110,
        },
        {
            subDistrict: 'หนองข่า',
            district: 'ปทุมราชวงศา',
            province: 'อำนาจเจริญ',
            zipcode: 37110,
        },
        {
            subDistrict: 'ห้วย',
            district: 'ปทุมราชวงศา',
            province: 'อำนาจเจริญ',
            zipcode: 37110,
        },
        {
            subDistrict: 'โนนงาม',
            district: 'ปทุมราชวงศา',
            province: 'อำนาจเจริญ',
            zipcode: 37110,
        },
        {
            subDistrict: 'จานลาน',
            district: 'พนา',
            province: 'อำนาจเจริญ',
            zipcode: 37180,
        },
        {
            subDistrict: 'พนา',
            district: 'พนา',
            province: 'อำนาจเจริญ',
            zipcode: 37180,
        },
        {
            subDistrict: 'พระเหลา',
            district: 'พนา',
            province: 'อำนาจเจริญ',
            zipcode: 37180,
        },
        {
            subDistrict: 'ไม้กลอน',
            district: 'พนา',
            province: 'อำนาจเจริญ',
            zipcode: 37180,
        },
        {
            subDistrict: 'ดงบัง',
            district: 'ลืออำนาจ',
            province: 'อำนาจเจริญ',
            zipcode: 37120,
        },
        {
            subDistrict: 'ดงมะยาง',
            district: 'ลืออำนาจ',
            province: 'อำนาจเจริญ',
            zipcode: 37120,
        },
        {
            subDistrict: 'อำนาจ',
            district: 'ลืออำนาจ',
            province: 'อำนาจเจริญ',
            zipcode: 37120,
        },
        {
            subDistrict: 'เปือย',
            district: 'ลืออำนาจ',
            province: 'อำนาจเจริญ',
            zipcode: 37120,
        },
        {
            subDistrict: 'แมด',
            district: 'ลืออำนาจ',
            province: 'อำนาจเจริญ',
            zipcode: 37120,
        },
        {
            subDistrict: 'โคกกลาง',
            district: 'ลืออำนาจ',
            province: 'อำนาจเจริญ',
            zipcode: 37120,
        },
        {
            subDistrict: 'ไร่ขี',
            district: 'ลืออำนาจ',
            province: 'อำนาจเจริญ',
            zipcode: 37120,
        },
        {
            subDistrict: 'คำพระ',
            district: 'หัวตะพาน',
            province: 'อำนาจเจริญ',
            zipcode: 37240,
        },
        {
            subDistrict: 'จิกดู่',
            district: 'หัวตะพาน',
            province: 'อำนาจเจริญ',
            zipcode: 37240,
        },
        {
            subDistrict: 'รัตนวารี',
            district: 'หัวตะพาน',
            province: 'อำนาจเจริญ',
            zipcode: 37240,
        },
        {
            subDistrict: 'สร้างถ่อน้อย',
            district: 'หัวตะพาน',
            province: 'อำนาจเจริญ',
            zipcode: 37240,
        },
        {
            subDistrict: 'หนองแก้ว',
            district: 'หัวตะพาน',
            province: 'อำนาจเจริญ',
            zipcode: 37240,
        },
        {
            subDistrict: 'หัวตะพาน',
            district: 'หัวตะพาน',
            province: 'อำนาจเจริญ',
            zipcode: 37240,
        },
        {
            subDistrict: 'เค็งใหญ่',
            district: 'หัวตะพาน',
            province: 'อำนาจเจริญ',
            zipcode: 37240,
        },
        {
            subDistrict: 'โพนเมืองน้อย',
            district: 'หัวตะพาน',
            province: 'อำนาจเจริญ',
            zipcode: 37240,
        },
        {
            subDistrict: 'กุดปลาดุก',
            district: 'เมืองอำนาจเจริญ',
            province: 'อำนาจเจริญ',
            zipcode: 37000,
        },
        {
            subDistrict: 'คึมใหญ่',
            district: 'เมืองอำนาจเจริญ',
            province: 'อำนาจเจริญ',
            zipcode: 37000,
        },
        {
            subDistrict: 'ดอนเมย',
            district: 'เมืองอำนาจเจริญ',
            province: 'อำนาจเจริญ',
            zipcode: 37000,
        },
        {
            subDistrict: 'นาจิก',
            district: 'เมืองอำนาจเจริญ',
            province: 'อำนาจเจริญ',
            zipcode: 37000,
        },
        {
            subDistrict: 'นาผือ',
            district: 'เมืองอำนาจเจริญ',
            province: 'อำนาจเจริญ',
            zipcode: 37000,
        },
        {
            subDistrict: 'นายม',
            district: 'เมืองอำนาจเจริญ',
            province: 'อำนาจเจริญ',
            zipcode: 37000,
        },
        {
            subDistrict: 'นาวัง',
            district: 'เมืองอำนาจเจริญ',
            province: 'อำนาจเจริญ',
            zipcode: 37000,
        },
        {
            subDistrict: 'นาหมอม้า',
            district: 'เมืองอำนาจเจริญ',
            province: 'อำนาจเจริญ',
            zipcode: 37000,
        },
        {
            subDistrict: 'นาแต้',
            district: 'เมืองอำนาจเจริญ',
            province: 'อำนาจเจริญ',
            zipcode: 37000,
        },
        {
            subDistrict: 'น้ำปลีก',
            district: 'เมืองอำนาจเจริญ',
            province: 'อำนาจเจริญ',
            zipcode: 37000,
        },
        {
            subDistrict: 'บุ่ง',
            district: 'เมืองอำนาจเจริญ',
            province: 'อำนาจเจริญ',
            zipcode: 37000,
        },
        {
            subDistrict: 'ปลาค้าว',
            district: 'เมืองอำนาจเจริญ',
            province: 'อำนาจเจริญ',
            zipcode: 37000,
        },
        {
            subDistrict: 'สร้างนกทา',
            district: 'เมืองอำนาจเจริญ',
            province: 'อำนาจเจริญ',
            zipcode: 37000,
        },
        {
            subDistrict: 'หนองมะแซว',
            district: 'เมืองอำนาจเจริญ',
            province: 'อำนาจเจริญ',
            zipcode: 37000,
        },
        {
            subDistrict: 'ห้วยไร่',
            district: 'เมืองอำนาจเจริญ',
            province: 'อำนาจเจริญ',
            zipcode: 37000,
        },
        {
            subDistrict: 'เหล่าพรวน',
            district: 'เมืองอำนาจเจริญ',
            province: 'อำนาจเจริญ',
            zipcode: 37000,
        },
        {
            subDistrict: 'โนนหนามแท่ง',
            district: 'เมืองอำนาจเจริญ',
            province: 'อำนาจเจริญ',
            zipcode: 37000,
        },
        {
            subDistrict: 'โนนโพธิ์',
            district: 'เมืองอำนาจเจริญ',
            province: 'อำนาจเจริญ',
            zipcode: 37000,
        },
        {
            subDistrict: 'ไก่คำ',
            district: 'เมืองอำนาจเจริญ',
            province: 'อำนาจเจริญ',
            zipcode: 37000,
        },
        {
            subDistrict: 'นาเวียง',
            district: 'เสนางคนิคม',
            province: 'อำนาจเจริญ',
            zipcode: 37290,
        },
        {
            subDistrict: 'หนองสามสี',
            district: 'เสนางคนิคม',
            province: 'อำนาจเจริญ',
            zipcode: 37290,
        },
        {
            subDistrict: 'หนองไฮ',
            district: 'เสนางคนิคม',
            province: 'อำนาจเจริญ',
            zipcode: 37290,
        },
        {
            subDistrict: 'เสนางคนิคม',
            district: 'เสนางคนิคม',
            province: 'อำนาจเจริญ',
            zipcode: 37290,
        },
        {
            subDistrict: 'โพนทอง',
            district: 'เสนางคนิคม',
            province: 'อำนาจเจริญ',
            zipcode: 37290,
        },
        {
            subDistrict: 'ไร่สีสุก',
            district: 'เสนางคนิคม',
            province: 'อำนาจเจริญ',
            zipcode: 37290,
        },
        {
            subDistrict: 'กุดจับ',
            district: 'กุดจับ',
            province: 'อุดรธานี',
            zipcode: 41250,
        },
        {
            subDistrict: 'ขอนยูง',
            district: 'กุดจับ',
            province: 'อุดรธานี',
            zipcode: 41250,
        },
        {
            subDistrict: 'ตาลเลียน',
            district: 'กุดจับ',
            province: 'อุดรธานี',
            zipcode: 41250,
        },
        {
            subDistrict: 'ปะโค',
            district: 'กุดจับ',
            province: 'อุดรธานี',
            zipcode: 41250,
        },
        {
            subDistrict: 'สร้างก่อ',
            district: 'กุดจับ',
            province: 'อุดรธานี',
            zipcode: 41250,
        },
        {
            subDistrict: 'เชียงเพ็ง',
            district: 'กุดจับ',
            province: 'อุดรธานี',
            zipcode: 41250,
        },
        {
            subDistrict: 'เมืองเพีย',
            district: 'กุดจับ',
            province: 'อุดรธานี',
            zipcode: 41250,
        },
        {
            subDistrict: 'กุมภวาปี',
            district: 'กุมภวาปี',
            province: 'อุดรธานี',
            zipcode: 41110,
        },
        {
            subDistrict: 'ตูมใต้',
            district: 'กุมภวาปี',
            province: 'อุดรธานี',
            zipcode: 41110,
        },
        {
            subDistrict: 'ท่าลี่',
            district: 'กุมภวาปี',
            province: 'อุดรธานี',
            zipcode: 41110,
        },
        {
            subDistrict: 'ปะโค',
            district: 'กุมภวาปี',
            province: 'อุดรธานี',
            zipcode: 41370,
        },
        {
            subDistrict: 'ผาสุก',
            district: 'กุมภวาปี',
            province: 'อุดรธานี',
            zipcode: 41370,
        },
        {
            subDistrict: 'พันดอน',
            district: 'กุมภวาปี',
            province: 'อุดรธานี',
            zipcode: 41370,
        },
        {
            subDistrict: 'สีออ',
            district: 'กุมภวาปี',
            province: 'อุดรธานี',
            zipcode: 41110,
        },
        {
            subDistrict: 'หนองหว้า',
            district: 'กุมภวาปี',
            province: 'อุดรธานี',
            zipcode: 41110,
        },
        {
            subDistrict: 'ห้วยเกิ้ง',
            district: 'กุมภวาปี',
            province: 'อุดรธานี',
            zipcode: 41110,
        },
        {
            subDistrict: 'เชียงแหว',
            district: 'กุมภวาปี',
            province: 'อุดรธานี',
            zipcode: 41110,
        },
        {
            subDistrict: 'เวียงคำ',
            district: 'กุมภวาปี',
            province: 'อุดรธานี',
            zipcode: 41110,
        },
        {
            subDistrict: 'เสอเพลอ',
            district: 'กุมภวาปี',
            province: 'อุดรธานี',
            zipcode: 41370,
        },
        {
            subDistrict: 'แชแล',
            district: 'กุมภวาปี',
            province: 'อุดรธานี',
            zipcode: 41110,
        },
        {
            subDistrict: 'คอนสาย',
            district: 'กู่แก้ว',
            province: 'อุดรธานี',
            zipcode: 41130,
        },
        {
            subDistrict: 'ค้อใหญ่',
            district: 'กู่แก้ว',
            province: 'อุดรธานี',
            zipcode: 41130,
        },
        {
            subDistrict: 'บ้านจีต',
            district: 'กู่แก้ว',
            province: 'อุดรธานี',
            zipcode: 41130,
        },
        {
            subDistrict: 'โนนทองอินทร์',
            district: 'กู่แก้ว',
            province: 'อุดรธานี',
            zipcode: 41130,
        },
        {
            subDistrict: 'ทุ่งฝน',
            district: 'ทุ่งฝน',
            province: 'อุดรธานี',
            zipcode: 41310,
        },
        {
            subDistrict: 'ทุ่งใหญ่',
            district: 'ทุ่งฝน',
            province: 'อุดรธานี',
            zipcode: 41310,
        },
        {
            subDistrict: 'นาชุมแสง',
            district: 'ทุ่งฝน',
            province: 'อุดรธานี',
            zipcode: 41310,
        },
        {
            subDistrict: 'นาทม',
            district: 'ทุ่งฝน',
            province: 'อุดรธานี',
            zipcode: 41310,
        },
        {
            subDistrict: 'นายูง',
            district: 'นายูง',
            province: 'อุดรธานี',
            zipcode: 41380,
        },
        {
            subDistrict: 'นาแค',
            district: 'นายูง',
            province: 'อุดรธานี',
            zipcode: 41380,
        },
        {
            subDistrict: 'บ้านก้อง',
            district: 'นายูง',
            province: 'อุดรธานี',
            zipcode: 41380,
        },
        {
            subDistrict: 'โนนทอง',
            district: 'นายูง',
            province: 'อุดรธานี',
            zipcode: 41380,
        },
        {
            subDistrict: 'นางัว',
            district: 'น้ำโสม',
            province: 'อุดรธานี',
            zipcode: 41210,
        },
        {
            subDistrict: 'น้ำโสม',
            district: 'น้ำโสม',
            province: 'อุดรธานี',
            zipcode: 41210,
        },
        {
            subDistrict: 'บ้านหยวก',
            district: 'น้ำโสม',
            province: 'อุดรธานี',
            zipcode: 41210,
        },
        {
            subDistrict: 'ศรีสำราญ',
            district: 'น้ำโสม',
            province: 'อุดรธานี',
            zipcode: 41210,
        },
        {
            subDistrict: 'สามัคคี',
            district: 'น้ำโสม',
            province: 'อุดรธานี',
            zipcode: 41210,
        },
        {
            subDistrict: 'หนองแวง',
            district: 'น้ำโสม',
            province: 'อุดรธานี',
            zipcode: 41210,
        },
        {
            subDistrict: 'โสมเยี่ยม',
            district: 'น้ำโสม',
            province: 'อุดรธานี',
            zipcode: 41210,
        },
        {
            subDistrict: 'ดงเย็น',
            district: 'บ้านดุง',
            province: 'อุดรธานี',
            zipcode: 41190,
        },
        {
            subDistrict: 'ถ่อนนาลับ',
            district: 'บ้านดุง',
            province: 'อุดรธานี',
            zipcode: 41190,
        },
        {
            subDistrict: 'นาคำ',
            district: 'บ้านดุง',
            province: 'อุดรธานี',
            zipcode: 41190,
        },
        {
            subDistrict: 'นาไหม',
            district: 'บ้านดุง',
            province: 'อุดรธานี',
            zipcode: 41190,
        },
        {
            subDistrict: 'บ้านจันทร์',
            district: 'บ้านดุง',
            province: 'อุดรธานี',
            zipcode: 41190,
        },
        {
            subDistrict: 'บ้านชัย',
            district: 'บ้านดุง',
            province: 'อุดรธานี',
            zipcode: 41190,
        },
        {
            subDistrict: 'บ้านดุง',
            district: 'บ้านดุง',
            province: 'อุดรธานี',
            zipcode: 41190,
        },
        {
            subDistrict: 'บ้านตาด',
            district: 'บ้านดุง',
            province: 'อุดรธานี',
            zipcode: 41190,
        },
        {
            subDistrict: 'บ้านม่วง',
            district: 'บ้านดุง',
            province: 'อุดรธานี',
            zipcode: 41190,
        },
        {
            subDistrict: 'วังทอง',
            district: 'บ้านดุง',
            province: 'อุดรธานี',
            zipcode: 41190,
        },
        {
            subDistrict: 'ศรีสุทโธ',
            district: 'บ้านดุง',
            province: 'อุดรธานี',
            zipcode: 41190,
        },
        {
            subDistrict: 'อ้อมกอ',
            district: 'บ้านดุง',
            province: 'อุดรธานี',
            zipcode: 41190,
        },
        {
            subDistrict: 'โพนสูง',
            district: 'บ้านดุง',
            province: 'อุดรธานี',
            zipcode: 41190,
        },
        {
            subDistrict: 'กลางใหญ่',
            district: 'บ้านผือ',
            province: 'อุดรธานี',
            zipcode: 41160,
        },
        {
            subDistrict: 'ข้าวสาร',
            district: 'บ้านผือ',
            province: 'อุดรธานี',
            zipcode: 41160,
        },
        {
            subDistrict: 'คำด้วง',
            district: 'บ้านผือ',
            province: 'อุดรธานี',
            zipcode: 41160,
        },
        {
            subDistrict: 'คำบง',
            district: 'บ้านผือ',
            province: 'อุดรธานี',
            zipcode: 41160,
        },
        {
            subDistrict: 'จำปาโมง',
            district: 'บ้านผือ',
            province: 'อุดรธานี',
            zipcode: 41160,
        },
        {
            subDistrict: 'บ้านค้อ',
            district: 'บ้านผือ',
            province: 'อุดรธานี',
            zipcode: 41160,
        },
        {
            subDistrict: 'บ้านผือ',
            district: 'บ้านผือ',
            province: 'อุดรธานี',
            zipcode: 41160,
        },
        {
            subDistrict: 'หนองหัวคู',
            district: 'บ้านผือ',
            province: 'อุดรธานี',
            zipcode: 41160,
        },
        {
            subDistrict: 'หนองแวง',
            district: 'บ้านผือ',
            province: 'อุดรธานี',
            zipcode: 41160,
        },
        {
            subDistrict: 'หายโศก',
            district: 'บ้านผือ',
            province: 'อุดรธานี',
            zipcode: 41160,
        },
        {
            subDistrict: 'เขือน้ำ',
            district: 'บ้านผือ',
            province: 'อุดรธานี',
            zipcode: 41160,
        },
        {
            subDistrict: 'เมืองพาน',
            district: 'บ้านผือ',
            province: 'อุดรธานี',
            zipcode: 41160,
        },
        {
            subDistrict: 'โนนทอง',
            district: 'บ้านผือ',
            province: 'อุดรธานี',
            zipcode: 41160,
        },
        {
            subDistrict: 'นาม่วง',
            district: 'ประจักษ์ศิลปาคม',
            province: 'อุดรธานี',
            zipcode: 41110,
        },
        {
            subDistrict: 'ห้วยสามพาด',
            district: 'ประจักษ์ศิลปาคม',
            province: 'อุดรธานี',
            zipcode: 41110,
        },
        {
            subDistrict: 'อุ่มจาน',
            district: 'ประจักษ์ศิลปาคม',
            province: 'อุดรธานี',
            zipcode: 41110,
        },
        {
            subDistrict: 'ดอนกลอย',
            district: 'พิบูลย์รักษ์',
            province: 'อุดรธานี',
            zipcode: 41130,
        },
        {
            subDistrict: 'นาทราย',
            district: 'พิบูลย์รักษ์',
            province: 'อุดรธานี',
            zipcode: 41130,
        },
        {
            subDistrict: 'บ้านแดง',
            district: 'พิบูลย์รักษ์',
            province: 'อุดรธานี',
            zipcode: 41130,
        },
        {
            subDistrict: 'คำโคกสูง',
            district: 'วังสามหมอ',
            province: 'อุดรธานี',
            zipcode: 41280,
        },
        {
            subDistrict: 'บะยาว',
            district: 'วังสามหมอ',
            province: 'อุดรธานี',
            zipcode: 41280,
        },
        {
            subDistrict: 'ผาสุก',
            district: 'วังสามหมอ',
            province: 'อุดรธานี',
            zipcode: 41280,
        },
        {
            subDistrict: 'วังสามหมอ',
            district: 'วังสามหมอ',
            province: 'อุดรธานี',
            zipcode: 41280,
        },
        {
            subDistrict: 'หนองกุงทับม้า',
            district: 'วังสามหมอ',
            province: 'อุดรธานี',
            zipcode: 41280,
        },
        {
            subDistrict: 'หนองหญ้าไซ',
            district: 'วังสามหมอ',
            province: 'อุดรธานี',
            zipcode: 41280,
        },
        {
            subDistrict: 'จำปี',
            district: 'ศรีธาตุ',
            province: 'อุดรธานี',
            zipcode: 41230,
        },
        {
            subDistrict: 'ตาดทอง',
            district: 'ศรีธาตุ',
            province: 'อุดรธานี',
            zipcode: 41230,
        },
        {
            subDistrict: 'นายูง',
            district: 'ศรีธาตุ',
            province: 'อุดรธานี',
            zipcode: 41230,
        },
        {
            subDistrict: 'บ้านโปร่ง',
            district: 'ศรีธาตุ',
            province: 'อุดรธานี',
            zipcode: 41230,
        },
        {
            subDistrict: 'ศรีธาตุ',
            district: 'ศรีธาตุ',
            province: 'อุดรธานี',
            zipcode: 41230,
        },
        {
            subDistrict: 'หนองนกเขียน',
            district: 'ศรีธาตุ',
            province: 'อุดรธานี',
            zipcode: 41230,
        },
        {
            subDistrict: 'หัวนาคำ',
            district: 'ศรีธาตุ',
            province: 'อุดรธานี',
            zipcode: 41230,
        },
        {
            subDistrict: 'นาสะอาด',
            district: 'สร้างคอม',
            province: 'อุดรธานี',
            zipcode: 41260,
        },
        {
            subDistrict: 'บ้านยวด',
            district: 'สร้างคอม',
            province: 'อุดรธานี',
            zipcode: 41260,
        },
        {
            subDistrict: 'บ้านหินโงม',
            district: 'สร้างคอม',
            province: 'อุดรธานี',
            zipcode: 41260,
        },
        {
            subDistrict: 'บ้านโคก',
            district: 'สร้างคอม',
            province: 'อุดรธานี',
            zipcode: 41260,
        },
        {
            subDistrict: 'สร้างคอม',
            district: 'สร้างคอม',
            province: 'อุดรธานี',
            zipcode: 41260,
        },
        {
            subDistrict: 'เชียงดา',
            district: 'สร้างคอม',
            province: 'อุดรธานี',
            zipcode: 41260,
        },
        {
            subDistrict: 'กุดหมากไฟ',
            district: 'หนองวัวซอ',
            province: 'อุดรธานี',
            zipcode: 41220,
        },
        {
            subDistrict: 'น้ำพ่น',
            district: 'หนองวัวซอ',
            province: 'อุดรธานี',
            zipcode: 41360,
        },
        {
            subDistrict: 'หนองบัวบาน',
            district: 'หนองวัวซอ',
            province: 'อุดรธานี',
            zipcode: 41360,
        },
        {
            subDistrict: 'หนองวัวซอ',
            district: 'หนองวัวซอ',
            province: 'อุดรธานี',
            zipcode: 41360,
        },
        {
            subDistrict: 'หนองอ้อ',
            district: 'หนองวัวซอ',
            province: 'อุดรธานี',
            zipcode: 41220,
        },
        {
            subDistrict: 'หมากหญ้า',
            district: 'หนองวัวซอ',
            province: 'อุดรธานี',
            zipcode: 41360,
        },
        {
            subDistrict: 'อูบมุง',
            district: 'หนองวัวซอ',
            province: 'อุดรธานี',
            zipcode: 41220,
        },
        {
            subDistrict: 'โนนหวาย',
            district: 'หนองวัวซอ',
            province: 'อุดรธานี',
            zipcode: 41220,
        },
        {
            subDistrict: 'ดอนหายโศก',
            district: 'หนองหาน',
            province: 'อุดรธานี',
            zipcode: 41130,
        },
        {
            subDistrict: 'บ้านยา',
            district: 'หนองหาน',
            province: 'อุดรธานี',
            zipcode: 41320,
        },
        {
            subDistrict: 'บ้านเชียง',
            district: 'หนองหาน',
            province: 'อุดรธานี',
            zipcode: 41320,
        },
        {
            subDistrict: 'ผักตบ',
            district: 'หนองหาน',
            province: 'อุดรธานี',
            zipcode: 41130,
        },
        {
            subDistrict: 'พังงู',
            district: 'หนองหาน',
            province: 'อุดรธานี',
            zipcode: 41130,
        },
        {
            subDistrict: 'สร้อยพร้าว',
            district: 'หนองหาน',
            province: 'อุดรธานี',
            zipcode: 41130,
        },
        {
            subDistrict: 'สะแบง',
            district: 'หนองหาน',
            province: 'อุดรธานี',
            zipcode: 41130,
        },
        {
            subDistrict: 'หนองสระปลา',
            district: 'หนองหาน',
            province: 'อุดรธานี',
            zipcode: 41320,
        },
        {
            subDistrict: 'หนองหาน',
            district: 'หนองหาน',
            province: 'อุดรธานี',
            zipcode: 41130,
        },
        {
            subDistrict: 'หนองเม็ก',
            district: 'หนองหาน',
            province: 'อุดรธานี',
            zipcode: 41130,
        },
        {
            subDistrict: 'หนองไผ่',
            district: 'หนองหาน',
            province: 'อุดรธานี',
            zipcode: 41130,
        },
        {
            subDistrict: 'โพนงาม',
            district: 'หนองหาน',
            province: 'อุดรธานี',
            zipcode: 41130,
        },
        {
            subDistrict: 'ทับกุง',
            district: 'หนองแสง',
            province: 'อุดรธานี',
            zipcode: 41340,
        },
        {
            subDistrict: 'นาดี',
            district: 'หนองแสง',
            province: 'อุดรธานี',
            zipcode: 41340,
        },
        {
            subDistrict: 'หนองแสง',
            district: 'หนองแสง',
            province: 'อุดรธานี',
            zipcode: 41340,
        },
        {
            subDistrict: 'แสงสว่าง',
            district: 'หนองแสง',
            province: 'อุดรธานี',
            zipcode: 41340,
        },
        {
            subDistrict: 'จอมศรี',
            district: 'เพ็ญ',
            province: 'อุดรธานี',
            zipcode: 41150,
        },
        {
            subDistrict: 'นาบัว',
            district: 'เพ็ญ',
            province: 'อุดรธานี',
            zipcode: 41150,
        },
        {
            subDistrict: 'นาพู่',
            district: 'เพ็ญ',
            province: 'อุดรธานี',
            zipcode: 41150,
        },
        {
            subDistrict: 'บ้านธาตุ',
            district: 'เพ็ญ',
            province: 'อุดรธานี',
            zipcode: 41150,
        },
        {
            subDistrict: 'บ้านเหล่า',
            district: 'เพ็ญ',
            province: 'อุดรธานี',
            zipcode: 41150,
        },
        {
            subDistrict: 'สร้างแป้น',
            district: 'เพ็ญ',
            province: 'อุดรธานี',
            zipcode: 41150,
        },
        {
            subDistrict: 'สุมเส้า',
            district: 'เพ็ญ',
            province: 'อุดรธานี',
            zipcode: 41150,
        },
        {
            subDistrict: 'เชียงหวาง',
            district: 'เพ็ญ',
            province: 'อุดรธานี',
            zipcode: 41150,
        },
        {
            subDistrict: 'เตาไห',
            district: 'เพ็ญ',
            province: 'อุดรธานี',
            zipcode: 41150,
        },
        {
            subDistrict: 'เพ็ญ',
            district: 'เพ็ญ',
            province: 'อุดรธานี',
            zipcode: 41150,
        },
        {
            subDistrict: 'โคกกลาง',
            district: 'เพ็ญ',
            province: 'อุดรธานี',
            zipcode: 41150,
        },
        {
            subDistrict: 'กุดสระ',
            district: 'เมืองอุดรธานี',
            province: 'อุดรธานี',
            zipcode: 41000,
        },
        {
            subDistrict: 'นากว้าง',
            district: 'เมืองอุดรธานี',
            province: 'อุดรธานี',
            zipcode: 41000,
        },
        {
            subDistrict: 'นาข่า',
            district: 'เมืองอุดรธานี',
            province: 'อุดรธานี',
            zipcode: 41000,
        },
        {
            subDistrict: 'นาดี',
            district: 'เมืองอุดรธานี',
            province: 'อุดรธานี',
            zipcode: 41000,
        },
        {
            subDistrict: 'นิคมสงเคราะห์',
            district: 'เมืองอุดรธานี',
            province: 'อุดรธานี',
            zipcode: 41000,
        },
        {
            subDistrict: 'บ้านขาว',
            district: 'เมืองอุดรธานี',
            province: 'อุดรธานี',
            zipcode: 41000,
        },
        {
            subDistrict: 'บ้านจั่น',
            district: 'เมืองอุดรธานี',
            province: 'อุดรธานี',
            zipcode: 41000,
        },
        {
            subDistrict: 'บ้านตาด',
            district: 'เมืองอุดรธานี',
            province: 'อุดรธานี',
            zipcode: 41000,
        },
        {
            subDistrict: 'บ้านเลื่อม',
            district: 'เมืองอุดรธานี',
            province: 'อุดรธานี',
            zipcode: 41000,
        },
        {
            subDistrict: 'สามพร้าว',
            district: 'เมืองอุดรธานี',
            province: 'อุดรธานี',
            zipcode: 41000,
        },
        {
            subDistrict: 'หนองขอนกว้าง',
            district: 'เมืองอุดรธานี',
            province: 'อุดรธานี',
            zipcode: 41000,
        },
        {
            subDistrict: 'หนองนาคำ',
            district: 'เมืองอุดรธานี',
            province: 'อุดรธานี',
            zipcode: 41000,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'เมืองอุดรธานี',
            province: 'อุดรธานี',
            zipcode: 41000,
        },
        {
            subDistrict: 'หนองไผ่',
            district: 'เมืองอุดรธานี',
            province: 'อุดรธานี',
            zipcode: 41330,
        },
        {
            subDistrict: 'หนองไฮ',
            district: 'เมืองอุดรธานี',
            province: 'อุดรธานี',
            zipcode: 41000,
        },
        {
            subDistrict: 'หมากแข้ง',
            district: 'เมืองอุดรธานี',
            province: 'อุดรธานี',
            zipcode: 41000,
        },
        {
            subDistrict: 'หมูม่น',
            district: 'เมืองอุดรธานี',
            province: 'อุดรธานี',
            zipcode: 41000,
        },
        {
            subDistrict: 'เชียงพิณ',
            district: 'เมืองอุดรธานี',
            province: 'อุดรธานี',
            zipcode: 41000,
        },
        {
            subDistrict: 'เชียงยืน',
            district: 'เมืองอุดรธานี',
            province: 'อุดรธานี',
            zipcode: 41000,
        },
        {
            subDistrict: 'โคกสะอาด',
            district: 'เมืองอุดรธานี',
            province: 'อุดรธานี',
            zipcode: 41000,
        },
        {
            subDistrict: 'โนนสูง',
            district: 'เมืองอุดรธานี',
            province: 'อุดรธานี',
            zipcode: 41330,
        },
        {
            subDistrict: 'ทมนางาม',
            district: 'โนนสะอาด',
            province: 'อุดรธานี',
            zipcode: 41240,
        },
        {
            subDistrict: 'บุ่งแก้ว',
            district: 'โนนสะอาด',
            province: 'อุดรธานี',
            zipcode: 41240,
        },
        {
            subDistrict: 'หนองกุงศรี',
            district: 'โนนสะอาด',
            province: 'อุดรธานี',
            zipcode: 41240,
        },
        {
            subDistrict: 'โคกกลาง',
            district: 'โนนสะอาด',
            province: 'อุดรธานี',
            zipcode: 41240,
        },
        {
            subDistrict: 'โนนสะอาด',
            district: 'โนนสะอาด',
            province: 'อุดรธานี',
            zipcode: 41240,
        },
        {
            subDistrict: 'โพธิ์ศรีสำราญ',
            district: 'โนนสะอาด',
            province: 'อุดรธานี',
            zipcode: 41240,
        },
        {
            subDistrict: 'คำเลาะ',
            district: 'ไชยวาน',
            province: 'อุดรธานี',
            zipcode: 41290,
        },
        {
            subDistrict: 'หนองหลัก',
            district: 'ไชยวาน',
            province: 'อุดรธานี',
            zipcode: 41290,
        },
        {
            subDistrict: 'โพนสูง',
            district: 'ไชยวาน',
            province: 'อุดรธานี',
            zipcode: 41290,
        },
        {
            subDistrict: 'ไชยวาน',
            district: 'ไชยวาน',
            province: 'อุดรธานี',
            zipcode: 41290,
        },
        {
            subDistrict: 'ข่อยสูง',
            district: 'ตรอน',
            province: 'อุตรดิตถ์',
            zipcode: 53140,
        },
        {
            subDistrict: 'น้ำอ่าง',
            district: 'ตรอน',
            province: 'อุตรดิตถ์',
            zipcode: 53140,
        },
        {
            subDistrict: 'บ้านแก่ง',
            district: 'ตรอน',
            province: 'อุตรดิตถ์',
            zipcode: 53140,
        },
        {
            subDistrict: 'วังแดง',
            district: 'ตรอน',
            province: 'อุตรดิตถ์',
            zipcode: 53140,
        },
        {
            subDistrict: 'หาดสองแคว',
            district: 'ตรอน',
            province: 'อุตรดิตถ์',
            zipcode: 53140,
        },
        {
            subDistrict: 'น้ำพี้',
            district: 'ทองแสนขัน',
            province: 'อุตรดิตถ์',
            zipcode: 53230,
        },
        {
            subDistrict: 'บ่อทอง',
            district: 'ทองแสนขัน',
            province: 'อุตรดิตถ์',
            zipcode: 53230,
        },
        {
            subDistrict: 'ป่าคาย',
            district: 'ทองแสนขัน',
            province: 'อุตรดิตถ์',
            zipcode: 53230,
        },
        {
            subDistrict: 'ผักขวง',
            district: 'ทองแสนขัน',
            province: 'อุตรดิตถ์',
            zipcode: 53230,
        },
        {
            subDistrict: 'จริม',
            district: 'ท่าปลา',
            province: 'อุตรดิตถ์',
            zipcode: 53150,
        },
        {
            subDistrict: 'ท่าปลา',
            district: 'ท่าปลา',
            province: 'อุตรดิตถ์',
            zipcode: 53150,
        },
        {
            subDistrict: 'ท่าแฝก',
            district: 'น้ำปาด',
            province: 'อุตรดิตถ์',
            zipcode: 53110,
        },
        {
            subDistrict: 'นางพญา',
            district: 'ท่าปลา',
            province: 'อุตรดิตถ์',
            zipcode: 53150,
        },
        {
            subDistrict: 'น้ำหมัน',
            district: 'ท่าปลา',
            province: 'อุตรดิตถ์',
            zipcode: 53150,
        },
        {
            subDistrict: 'ผาเลือด',
            district: 'ท่าปลา',
            province: 'อุตรดิตถ์',
            zipcode: 53190,
        },
        {
            subDistrict: 'ร่วมจิต',
            district: 'ท่าปลา',
            province: 'อุตรดิตถ์',
            zipcode: 53190,
        },
        {
            subDistrict: 'หาดล้า',
            district: 'ท่าปลา',
            province: 'อุตรดิตถ์',
            zipcode: 53190,
        },
        {
            subDistrict: 'น้ำไคร้',
            district: 'น้ำปาด',
            province: 'อุตรดิตถ์',
            zipcode: 53110,
        },
        {
            subDistrict: 'น้ำไผ่',
            district: 'น้ำปาด',
            province: 'อุตรดิตถ์',
            zipcode: 53110,
        },
        {
            subDistrict: 'บ้านฝาย',
            district: 'น้ำปาด',
            province: 'อุตรดิตถ์',
            zipcode: 53110,
        },
        {
            subDistrict: 'ห้วยมุ่น',
            district: 'น้ำปาด',
            province: 'อุตรดิตถ์',
            zipcode: 53110,
        },
        {
            subDistrict: 'เด่นเหล็ก',
            district: 'น้ำปาด',
            province: 'อุตรดิตถ์',
            zipcode: 53110,
        },
        {
            subDistrict: 'แสนตอ',
            district: 'น้ำปาด',
            province: 'อุตรดิตถ์',
            zipcode: 53110,
        },
        {
            subDistrict: 'นาขุม',
            district: 'บ้านโคก',
            province: 'อุตรดิตถ์',
            zipcode: 53180,
        },
        {
            subDistrict: 'บ่อเบี้ย',
            district: 'บ้านโคก',
            province: 'อุตรดิตถ์',
            zipcode: 53180,
        },
        {
            subDistrict: 'บ้านโคก',
            district: 'บ้านโคก',
            province: 'อุตรดิตถ์',
            zipcode: 53180,
        },
        {
            subDistrict: 'ม่วงเจ็ดต้น',
            district: 'บ้านโคก',
            province: 'อุตรดิตถ์',
            zipcode: 53180,
        },
        {
            subDistrict: 'คอรุม',
            district: 'พิชัย',
            province: 'อุตรดิตถ์',
            zipcode: 53120,
        },
        {
            subDistrict: 'ท่ามะเฟือง',
            district: 'พิชัย',
            province: 'อุตรดิตถ์',
            zipcode: 53120,
        },
        {
            subDistrict: 'ท่าสัก',
            district: 'พิชัย',
            province: 'อุตรดิตถ์',
            zipcode: 53220,
        },
        {
            subDistrict: 'นายาง',
            district: 'พิชัย',
            province: 'อุตรดิตถ์',
            zipcode: 53120,
        },
        {
            subDistrict: 'นาอิน',
            district: 'พิชัย',
            province: 'อุตรดิตถ์',
            zipcode: 53120,
        },
        {
            subDistrict: 'บ้านดารา',
            district: 'พิชัย',
            province: 'อุตรดิตถ์',
            zipcode: 53220,
        },
        {
            subDistrict: 'บ้านหม้อ',
            district: 'พิชัย',
            province: 'อุตรดิตถ์',
            zipcode: 53120,
        },
        {
            subDistrict: 'บ้านโคน',
            district: 'พิชัย',
            province: 'อุตรดิตถ์',
            zipcode: 53120,
        },
        {
            subDistrict: 'พญาแมน',
            district: 'พิชัย',
            province: 'อุตรดิตถ์',
            zipcode: 53120,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'พิชัย',
            province: 'อุตรดิตถ์',
            zipcode: 53120,
        },
        {
            subDistrict: 'ไร่อ้อย',
            district: 'พิชัย',
            province: 'อุตรดิตถ์',
            zipcode: 53120,
        },
        {
            subDistrict: 'บ้านเสี้ยว',
            district: 'ฟากท่า',
            province: 'อุตรดิตถ์',
            zipcode: 53160,
        },
        {
            subDistrict: 'ฟากท่า',
            district: 'ฟากท่า',
            province: 'อุตรดิตถ์',
            zipcode: 53160,
        },
        {
            subDistrict: 'สองคอน',
            district: 'ฟากท่า',
            province: 'อุตรดิตถ์',
            zipcode: 53160,
        },
        {
            subDistrict: 'สองห้อง',
            district: 'ฟากท่า',
            province: 'อุตรดิตถ์',
            zipcode: 53160,
        },
        {
            subDistrict: 'ชัยจุมพล',
            district: 'ลับแล',
            province: 'อุตรดิตถ์',
            zipcode: 53130,
        },
        {
            subDistrict: 'ด่านแม่คำมัน',
            district: 'ลับแล',
            province: 'อุตรดิตถ์',
            zipcode: 53210,
        },
        {
            subDistrict: 'ทุ่งยั้ง',
            district: 'ลับแล',
            province: 'อุตรดิตถ์',
            zipcode: 53210,
        },
        {
            subDistrict: 'นานกกก',
            district: 'ลับแล',
            province: 'อุตรดิตถ์',
            zipcode: 53130,
        },
        {
            subDistrict: 'ฝายหลวง',
            district: 'ลับแล',
            province: 'อุตรดิตถ์',
            zipcode: 53130,
        },
        {
            subDistrict: 'ศรีพนมมาศ',
            district: 'ลับแล',
            province: 'อุตรดิตถ์',
            zipcode: 53130,
        },
        {
            subDistrict: 'แม่พูล',
            district: 'ลับแล',
            province: 'อุตรดิตถ์',
            zipcode: 53130,
        },
        {
            subDistrict: 'ไผ่ล้อม',
            district: 'ลับแล',
            province: 'อุตรดิตถ์',
            zipcode: 53210,
        },
        {
            subDistrict: 'ขุนฝาง',
            district: 'เมืองอุตรดิตถ์',
            province: 'อุตรดิตถ์',
            zipcode: 53000,
        },
        {
            subDistrict: 'คุ้งตะเภา',
            district: 'เมืองอุตรดิตถ์',
            province: 'อุตรดิตถ์',
            zipcode: 53000,
        },
        {
            subDistrict: 'งิ้วงาม',
            district: 'เมืองอุตรดิตถ์',
            province: 'อุตรดิตถ์',
            zipcode: 53000,
        },
        {
            subDistrict: 'ถ้ำฉลอง',
            district: 'เมืองอุตรดิตถ์',
            province: 'อุตรดิตถ์',
            zipcode: 53000,
        },
        {
            subDistrict: 'ท่าอิฐ',
            district: 'เมืองอุตรดิตถ์',
            province: 'อุตรดิตถ์',
            zipcode: 53000,
        },
        {
            subDistrict: 'ท่าเสา',
            district: 'เมืองอุตรดิตถ์',
            province: 'อุตรดิตถ์',
            zipcode: 53000,
        },
        {
            subDistrict: 'น้ำริด',
            district: 'เมืองอุตรดิตถ์',
            province: 'อุตรดิตถ์',
            zipcode: 53000,
        },
        {
            subDistrict: 'บ้านด่าน',
            district: 'เมืองอุตรดิตถ์',
            province: 'อุตรดิตถ์',
            zipcode: 53000,
        },
        {
            subDistrict: 'บ้านด่านนาขาม',
            district: 'เมืองอุตรดิตถ์',
            province: 'อุตรดิตถ์',
            zipcode: 53000,
        },
        {
            subDistrict: 'บ้านเกาะ',
            district: 'เมืองอุตรดิตถ์',
            province: 'อุตรดิตถ์',
            zipcode: 53000,
        },
        {
            subDistrict: 'ป่าเซ่า',
            district: 'เมืองอุตรดิตถ์',
            province: 'อุตรดิตถ์',
            zipcode: 53000,
        },
        {
            subDistrict: 'ผาจุก',
            district: 'เมืองอุตรดิตถ์',
            province: 'อุตรดิตถ์',
            zipcode: 53000,
        },
        {
            subDistrict: 'วังกะพี้',
            district: 'เมืองอุตรดิตถ์',
            province: 'อุตรดิตถ์',
            zipcode: 53170,
        },
        {
            subDistrict: 'วังดิน',
            district: 'เมืองอุตรดิตถ์',
            province: 'อุตรดิตถ์',
            zipcode: 53000,
        },
        {
            subDistrict: 'หาดกรวด',
            district: 'เมืองอุตรดิตถ์',
            province: 'อุตรดิตถ์',
            zipcode: 53000,
        },
        {
            subDistrict: 'หาดงิ้ว',
            district: 'เมืองอุตรดิตถ์',
            province: 'อุตรดิตถ์',
            zipcode: 53000,
        },
        {
            subDistrict: 'แสนตอ',
            district: 'เมืองอุตรดิตถ์',
            province: 'อุตรดิตถ์',
            zipcode: 53000,
        },
        {
            subDistrict: 'ตลุกดู่',
            district: 'ทัพทัน',
            province: 'อุทัยธานี',
            zipcode: 61120,
        },
        {
            subDistrict: 'ทัพทัน',
            district: 'ทัพทัน',
            province: 'อุทัยธานี',
            zipcode: 61120,
        },
        {
            subDistrict: 'ทุ่งนาไทย',
            district: 'ทัพทัน',
            province: 'อุทัยธานี',
            zipcode: 61120,
        },
        {
            subDistrict: 'หนองกระทุ่ม',
            district: 'ทัพทัน',
            province: 'อุทัยธานี',
            zipcode: 61120,
        },
        {
            subDistrict: 'หนองกลางดง',
            district: 'ทัพทัน',
            province: 'อุทัยธานี',
            zipcode: 61120,
        },
        {
            subDistrict: 'หนองยายดา',
            district: 'ทัพทัน',
            province: 'อุทัยธานี',
            zipcode: 61120,
        },
        {
            subDistrict: 'หนองสระ',
            district: 'ทัพทัน',
            province: 'อุทัยธานี',
            zipcode: 61120,
        },
        {
            subDistrict: 'หนองหญ้าปล้อง',
            district: 'ทัพทัน',
            province: 'อุทัยธานี',
            zipcode: 61120,
        },
        {
            subDistrict: 'เขาขี้ฝอย',
            district: 'ทัพทัน',
            province: 'อุทัยธานี',
            zipcode: 61120,
        },
        {
            subDistrict: 'โคกหม้อ',
            district: 'ทัพทัน',
            province: 'อุทัยธานี',
            zipcode: 61120,
        },
        {
            subDistrict: 'คอกควาย',
            district: 'บ้านไร่',
            province: 'อุทัยธานี',
            zipcode: 61140,
        },
        {
            subDistrict: 'ทัพหลวง',
            district: 'บ้านไร่',
            province: 'อุทัยธานี',
            zipcode: 61140,
        },
        {
            subDistrict: 'บ้านบึง',
            district: 'บ้านไร่',
            province: 'อุทัยธานี',
            zipcode: 61140,
        },
        {
            subDistrict: 'บ้านใหม่คลองเคียน',
            district: 'บ้านไร่',
            province: 'อุทัยธานี',
            zipcode: 61180,
        },
        {
            subDistrict: 'บ้านไร่',
            district: 'บ้านไร่',
            province: 'อุทัยธานี',
            zipcode: 61140,
        },
        {
            subDistrict: 'วังหิน',
            district: 'บ้านไร่',
            province: 'อุทัยธานี',
            zipcode: 61180,
        },
        {
            subDistrict: 'หนองจอก',
            district: 'บ้านไร่',
            province: 'อุทัยธานี',
            zipcode: 61180,
        },
        {
            subDistrict: 'หนองบ่มกล้วย',
            district: 'บ้านไร่',
            province: 'อุทัยธานี',
            zipcode: 61180,
        },
        {
            subDistrict: 'หูช้าง',
            district: 'บ้านไร่',
            province: 'อุทัยธานี',
            zipcode: 61180,
        },
        {
            subDistrict: 'ห้วยแห้ง',
            district: 'บ้านไร่',
            province: 'อุทัยธานี',
            zipcode: 61140,
        },
        {
            subDistrict: 'เจ้าวัด',
            district: 'บ้านไร่',
            province: 'อุทัยธานี',
            zipcode: 61150,
        },
        {
            subDistrict: 'เมืองการุ้ง',
            district: 'บ้านไร่',
            province: 'อุทัยธานี',
            zipcode: 61180,
        },
        {
            subDistrict: 'แก่นมะกรูด',
            district: 'บ้านไร่',
            province: 'อุทัยธานี',
            zipcode: 61140,
        },
        {
            subDistrict: 'ทุ่งนางาม',
            district: 'ลานสัก',
            province: 'อุทัยธานี',
            zipcode: 61160,
        },
        {
            subDistrict: 'น้ำรอบ',
            district: 'ลานสัก',
            province: 'อุทัยธานี',
            zipcode: 61160,
        },
        {
            subDistrict: 'ประดู่ยืน',
            district: 'ลานสัก',
            province: 'อุทัยธานี',
            zipcode: 61160,
        },
        {
            subDistrict: 'ป่าอ้อ',
            district: 'ลานสัก',
            province: 'อุทัยธานี',
            zipcode: 61160,
        },
        {
            subDistrict: 'ระบำ',
            district: 'ลานสัก',
            province: 'อุทัยธานี',
            zipcode: 61160,
        },
        {
            subDistrict: 'ลานสัก',
            district: 'ลานสัก',
            province: 'อุทัยธานี',
            zipcode: 61160,
        },
        {
            subDistrict: 'บ่อยาง',
            district: 'สว่างอารมณ์',
            province: 'อุทัยธานี',
            zipcode: 61150,
        },
        {
            subDistrict: 'พลวงสองนาง',
            district: 'สว่างอารมณ์',
            province: 'อุทัยธานี',
            zipcode: 61150,
        },
        {
            subDistrict: 'สว่างอารมณ์',
            district: 'สว่างอารมณ์',
            province: 'อุทัยธานี',
            zipcode: 61150,
        },
        {
            subDistrict: 'หนองหลวง',
            district: 'สว่างอารมณ์',
            province: 'อุทัยธานี',
            zipcode: 61150,
        },
        {
            subDistrict: 'ไผ่เขียว',
            district: 'สว่างอารมณ์',
            province: 'อุทัยธานี',
            zipcode: 61150,
        },
        {
            subDistrict: 'ดงขวาง',
            district: 'หนองขาหย่าง',
            province: 'อุทัยธานี',
            zipcode: 61130,
        },
        {
            subDistrict: 'ดอนกลอย',
            district: 'หนองขาหย่าง',
            province: 'อุทัยธานี',
            zipcode: 61130,
        },
        {
            subDistrict: 'ทุ่งพึ่ง',
            district: 'หนองขาหย่าง',
            province: 'อุทัยธานี',
            zipcode: 61130,
        },
        {
            subDistrict: 'ท่าโพ',
            district: 'หนองขาหย่าง',
            province: 'อุทัยธานี',
            zipcode: 61130,
        },
        {
            subDistrict: 'หนองขาหย่าง',
            district: 'หนองขาหย่าง',
            province: 'อุทัยธานี',
            zipcode: 61130,
        },
        {
            subDistrict: 'หนองไผ่',
            district: 'หนองขาหย่าง',
            province: 'อุทัยธานี',
            zipcode: 61130,
        },
        {
            subDistrict: 'หมกแถว',
            district: 'หนองขาหย่าง',
            province: 'อุทัยธานี',
            zipcode: 61130,
        },
        {
            subDistrict: 'หลุมเข้า',
            district: 'หนองขาหย่าง',
            province: 'อุทัยธานี',
            zipcode: 61130,
        },
        {
            subDistrict: 'ห้วยรอบ',
            district: 'หนองขาหย่าง',
            province: 'อุทัยธานี',
            zipcode: 61130,
        },
        {
            subDistrict: 'ทุ่งพง',
            district: 'หนองฉาง',
            province: 'อุทัยธานี',
            zipcode: 61110,
        },
        {
            subDistrict: 'ทุ่งโพ',
            district: 'หนองฉาง',
            province: 'อุทัยธานี',
            zipcode: 61110,
        },
        {
            subDistrict: 'บ้านเก่า',
            district: 'หนองฉาง',
            province: 'อุทัยธานี',
            zipcode: 61110,
        },
        {
            subDistrict: 'หนองฉาง',
            district: 'หนองฉาง',
            province: 'อุทัยธานี',
            zipcode: 61110,
        },
        {
            subDistrict: 'หนองนางนวล',
            district: 'หนองฉาง',
            province: 'อุทัยธานี',
            zipcode: 61110,
        },
        {
            subDistrict: 'หนองยาง',
            district: 'หนองฉาง',
            province: 'อุทัยธานี',
            zipcode: 61110,
        },
        {
            subDistrict: 'หนองสรวง',
            district: 'หนองฉาง',
            province: 'อุทัยธานี',
            zipcode: 61110,
        },
        {
            subDistrict: 'อุทัยเก่า',
            district: 'หนองฉาง',
            province: 'อุทัยธานี',
            zipcode: 61110,
        },
        {
            subDistrict: 'เขากวางทอง',
            district: 'หนองฉาง',
            province: 'อุทัยธานี',
            zipcode: 61110,
        },
        {
            subDistrict: 'เขาบางแกรก',
            district: 'หนองฉาง',
            province: 'อุทัยธานี',
            zipcode: 61170,
        },
        {
            subDistrict: 'ทองหลาง',
            district: 'ห้วยคต',
            province: 'อุทัยธานี',
            zipcode: 61170,
        },
        {
            subDistrict: 'สุขฤทัย',
            district: 'ห้วยคต',
            province: 'อุทัยธานี',
            zipcode: 61170,
        },
        {
            subDistrict: 'ห้วยคต',
            district: 'ห้วยคต',
            province: 'อุทัยธานี',
            zipcode: 61170,
        },
        {
            subDistrict: 'ดอนขวาง',
            district: 'เมืองอุทัยธานี',
            province: 'อุทัยธานี',
            zipcode: 61000,
        },
        {
            subDistrict: 'ทุ่งใหญ่',
            district: 'เมืองอุทัยธานี',
            province: 'อุทัยธานี',
            zipcode: 61000,
        },
        {
            subDistrict: 'ท่าซุง',
            district: 'เมืองอุทัยธานี',
            province: 'อุทัยธานี',
            zipcode: 61000,
        },
        {
            subDistrict: 'น้ำซึม',
            district: 'เมืองอุทัยธานี',
            province: 'อุทัยธานี',
            zipcode: 61000,
        },
        {
            subDistrict: 'สะแกกรัง',
            district: 'เมืองอุทัยธานี',
            province: 'อุทัยธานี',
            zipcode: 61000,
        },
        {
            subDistrict: 'หนองพังค่า',
            district: 'เมืองอุทัยธานี',
            province: 'อุทัยธานี',
            zipcode: 61000,
        },
        {
            subDistrict: 'หนองเต่า',
            district: 'เมืองอุทัยธานี',
            province: 'อุทัยธานี',
            zipcode: 61000,
        },
        {
            subDistrict: 'หนองแก',
            district: 'เมืองอุทัยธานี',
            province: 'อุทัยธานี',
            zipcode: 61000,
        },
        {
            subDistrict: 'หนองไผ่แบน',
            district: 'เมืองอุทัยธานี',
            province: 'อุทัยธานี',
            zipcode: 61000,
        },
        {
            subDistrict: 'หาดทนง',
            district: 'เมืองอุทัยธานี',
            province: 'อุทัยธานี',
            zipcode: 61000,
        },
        {
            subDistrict: 'อุทัยใหม่',
            district: 'เมืองอุทัยธานี',
            province: 'อุทัยธานี',
            zipcode: 61000,
        },
        {
            subDistrict: 'เกาะเทโพ',
            district: 'เมืองอุทัยธานี',
            province: 'อุทัยธานี',
            zipcode: 61000,
        },
        {
            subDistrict: 'เนินแจง',
            district: 'เมืองอุทัยธานี',
            province: 'อุทัยธานี',
            zipcode: 61000,
        },
        {
            subDistrict: 'โนนเหล็ก',
            district: 'เมืองอุทัยธานี',
            province: 'อุทัยธานี',
            zipcode: 61000,
        },
        {
            subDistrict: 'กาบิน',
            district: 'กุดข้าวปุ้น',
            province: 'อุบลราชธานี',
            zipcode: 34270,
        },
        {
            subDistrict: 'ข้าวปุ้น',
            district: 'กุดข้าวปุ้น',
            province: 'อุบลราชธานี',
            zipcode: 34270,
        },
        {
            subDistrict: 'หนองทันน้ำ',
            district: 'กุดข้าวปุ้น',
            province: 'อุบลราชธานี',
            zipcode: 34270,
        },
        {
            subDistrict: 'แก่งเค็ง',
            district: 'กุดข้าวปุ้น',
            province: 'อุบลราชธานี',
            zipcode: 34270,
        },
        {
            subDistrict: 'โนนสวาง',
            district: 'กุดข้าวปุ้น',
            province: 'อุบลราชธานี',
            zipcode: 34270,
        },
        {
            subDistrict: 'คำไฮใหญ่',
            district: 'ดอนมดแดง',
            province: 'อุบลราชธานี',
            zipcode: 34000,
        },
        {
            subDistrict: 'ดอนมดแดง',
            district: 'ดอนมดแดง',
            province: 'อุบลราชธานี',
            zipcode: 34000,
        },
        {
            subDistrict: 'ท่าเมือง',
            district: 'ดอนมดแดง',
            province: 'อุบลราชธานี',
            zipcode: 34000,
        },
        {
            subDistrict: 'เหล่าแดง',
            district: 'ดอนมดแดง',
            province: 'อุบลราชธานี',
            zipcode: 34000,
        },
        {
            subDistrict: 'กระเดียน',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'กุดยาลวน',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'กุศกร',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'ขามเปี้ย',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'ขุหลุ',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'คอนสาย',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'คำเจริญ',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'ตระการ',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'ตากแดด',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'ถ้ำแข้',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'ท่าหลวง',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'นาพิน',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'นาสะไม',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'บ้านแดง',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'สะพือ',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'หนองเต่า',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'ห้วยฝ้ายพัฒนา',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'เกษม',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'เซเป็ด',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'เป้า',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'โคกจาน',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'โนนกุง',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'ไหล่ทุ่ง',
            district: 'ตระการพืชผล',
            province: 'อุบลราชธานี',
            zipcode: 34130,
        },
        {
            subDistrict: 'คำหว้า',
            district: 'ตาลสุม',
            province: 'อุบลราชธานี',
            zipcode: 34330,
        },
        {
            subDistrict: 'จิกเทิง',
            district: 'ตาลสุม',
            province: 'อุบลราชธานี',
            zipcode: 34330,
        },
        {
            subDistrict: 'ตาลสุม',
            district: 'ตาลสุม',
            province: 'อุบลราชธานี',
            zipcode: 34330,
        },
        {
            subDistrict: 'นาคาย',
            district: 'ตาลสุม',
            province: 'อุบลราชธานี',
            zipcode: 34330,
        },
        {
            subDistrict: 'สำโรง',
            district: 'ตาลสุม',
            province: 'อุบลราชธานี',
            zipcode: 34330,
        },
        {
            subDistrict: 'หนองกุง',
            district: 'ตาลสุม',
            province: 'อุบลราชธานี',
            zipcode: 34330,
        },
        {
            subDistrict: 'กุดเรือ',
            district: 'ทุ่งศรีอุดม',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'นาห่อม',
            district: 'ทุ่งศรีอุดม',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'นาเกษม',
            district: 'ทุ่งศรีอุดม',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'หนองอ้ม',
            district: 'ทุ่งศรีอุดม',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'โคกชำแระ',
            district: 'ทุ่งศรีอุดม',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'นาจะหลวย',
            district: 'นาจะหลวย',
            province: 'อุบลราชธานี',
            zipcode: 34280,
        },
        {
            subDistrict: 'บ้านตูม',
            district: 'นาจะหลวย',
            province: 'อุบลราชธานี',
            zipcode: 34280,
        },
        {
            subDistrict: 'พรสวรรค์',
            district: 'นาจะหลวย',
            province: 'อุบลราชธานี',
            zipcode: 34280,
        },
        {
            subDistrict: 'โนนสมบูรณ์',
            district: 'นาจะหลวย',
            province: 'อุบลราชธานี',
            zipcode: 34280,
        },
        {
            subDistrict: 'โนนสวรรค์',
            district: 'นาจะหลวย',
            province: 'อุบลราชธานี',
            zipcode: 34280,
        },
        {
            subDistrict: 'โสกแสง',
            district: 'นาจะหลวย',
            province: 'อุบลราชธานี',
            zipcode: 34280,
        },
        {
            subDistrict: 'กองโพน',
            district: 'นาตาล',
            province: 'อุบลราชธานี',
            zipcode: 34170,
        },
        {
            subDistrict: 'นาตาล',
            district: 'นาตาล',
            province: 'อุบลราชธานี',
            zipcode: 34170,
        },
        {
            subDistrict: 'พะลาน',
            district: 'นาตาล',
            province: 'อุบลราชธานี',
            zipcode: 34170,
        },
        {
            subDistrict: 'พังเคน',
            district: 'นาตาล',
            province: 'อุบลราชธานี',
            zipcode: 34170,
        },
        {
            subDistrict: 'นาดี',
            district: 'นาเยีย',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'นาเยีย',
            district: 'นาเยีย',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'นาเรือง',
            district: 'นาเยีย',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'ขี้เหล็ก',
            district: 'น้ำขุ่น',
            province: 'อุบลราชธานี',
            zipcode: 34260,
        },
        {
            subDistrict: 'ตาเกา',
            district: 'น้ำขุ่น',
            province: 'อุบลราชธานี',
            zipcode: 34260,
        },
        {
            subDistrict: 'โคกสะอาด',
            district: 'น้ำขุ่น',
            province: 'อุบลราชธานี',
            zipcode: 34260,
        },
        {
            subDistrict: 'ไพบูลย์',
            district: 'น้ำขุ่น',
            province: 'อุบลราชธานี',
            zipcode: 34260,
        },
        {
            subDistrict: 'บุเปือย',
            district: 'น้ำยืน',
            province: 'อุบลราชธานี',
            zipcode: 34260,
        },
        {
            subDistrict: 'ยาง',
            district: 'น้ำยืน',
            province: 'อุบลราชธานี',
            zipcode: 34260,
        },
        {
            subDistrict: 'ยางใหญ่',
            district: 'น้ำยืน',
            province: 'อุบลราชธานี',
            zipcode: 34260,
        },
        {
            subDistrict: 'สีวิเชียร',
            district: 'น้ำยืน',
            province: 'อุบลราชธานี',
            zipcode: 34260,
        },
        {
            subDistrict: 'เก่าขาม',
            district: 'น้ำยืน',
            province: 'อุบลราชธานี',
            zipcode: 34260,
        },
        {
            subDistrict: 'โซง',
            district: 'น้ำยืน',
            province: 'อุบลราชธานี',
            zipcode: 34260,
        },
        {
            subDistrict: 'โดมประดิษฐ์',
            district: 'น้ำยืน',
            province: 'อุบลราชธานี',
            zipcode: 34260,
        },
        {
            subDistrict: 'คอแลน',
            district: 'บุณฑริก',
            province: 'อุบลราชธานี',
            zipcode: 34230,
        },
        {
            subDistrict: 'นาโพธิ์',
            district: 'บุณฑริก',
            province: 'อุบลราชธานี',
            zipcode: 34230,
        },
        {
            subDistrict: 'บัวงาม',
            district: 'บุณฑริก',
            province: 'อุบลราชธานี',
            zipcode: 34230,
        },
        {
            subDistrict: 'บ้านแมด',
            district: 'บุณฑริก',
            province: 'อุบลราชธานี',
            zipcode: 34230,
        },
        {
            subDistrict: 'หนองสะโน',
            district: 'บุณฑริก',
            province: 'อุบลราชธานี',
            zipcode: 34230,
        },
        {
            subDistrict: 'ห้วยข่า',
            district: 'บุณฑริก',
            province: 'อุบลราชธานี',
            zipcode: 34230,
        },
        {
            subDistrict: 'โนนค้อ',
            district: 'บุณฑริก',
            province: 'อุบลราชธานี',
            zipcode: 34230,
        },
        {
            subDistrict: 'โพนงาม',
            district: 'บุณฑริก',
            province: 'อุบลราชธานี',
            zipcode: 34230,
        },
        {
            subDistrict: 'กุดชมภู',
            district: 'พิบูลมังสาหาร',
            province: 'อุบลราชธานี',
            zipcode: 34110,
        },
        {
            subDistrict: 'ดอนจิก',
            district: 'พิบูลมังสาหาร',
            province: 'อุบลราชธานี',
            zipcode: 34110,
        },
        {
            subDistrict: 'ทรายมูล',
            district: 'พิบูลมังสาหาร',
            province: 'อุบลราชธานี',
            zipcode: 34110,
        },
        {
            subDistrict: 'นาโพธิ์',
            district: 'พิบูลมังสาหาร',
            province: 'อุบลราชธานี',
            zipcode: 34110,
        },
        {
            subDistrict: 'บ้านแขม',
            district: 'พิบูลมังสาหาร',
            province: 'อุบลราชธานี',
            zipcode: 34110,
        },
        {
            subDistrict: 'พิบูล',
            district: 'พิบูลมังสาหาร',
            province: 'อุบลราชธานี',
            zipcode: 34110,
        },
        {
            subDistrict: 'ระเว',
            district: 'พิบูลมังสาหาร',
            province: 'อุบลราชธานี',
            zipcode: 34110,
        },
        {
            subDistrict: 'หนองบัวฮี',
            district: 'พิบูลมังสาหาร',
            province: 'อุบลราชธานี',
            zipcode: 34110,
        },
        {
            subDistrict: 'อ่างศิลา',
            district: 'พิบูลมังสาหาร',
            province: 'อุบลราชธานี',
            zipcode: 34110,
        },
        {
            subDistrict: 'โนนกลาง',
            district: 'พิบูลมังสาหาร',
            province: 'อุบลราชธานี',
            zipcode: 34110,
        },
        {
            subDistrict: 'โนนกาหลง',
            district: 'พิบูลมังสาหาร',
            province: 'อุบลราชธานี',
            zipcode: 34110,
        },
        {
            subDistrict: 'โพธิ์ศรี',
            district: 'พิบูลมังสาหาร',
            province: 'อุบลราชธานี',
            zipcode: 34110,
        },
        {
            subDistrict: 'โพธิ์ไทร',
            district: 'พิบูลมังสาหาร',
            province: 'อุบลราชธานี',
            zipcode: 34110,
        },
        {
            subDistrict: 'ไร่ใต้',
            district: 'พิบูลมังสาหาร',
            province: 'อุบลราชธานี',
            zipcode: 34110,
        },
        {
            subDistrict: 'ดุมใหญ่',
            district: 'ม่วงสามสิบ',
            province: 'อุบลราชธานี',
            zipcode: 34140,
        },
        {
            subDistrict: 'นาเลิง',
            district: 'ม่วงสามสิบ',
            province: 'อุบลราชธานี',
            zipcode: 34140,
        },
        {
            subDistrict: 'ม่วงสามสิบ',
            district: 'ม่วงสามสิบ',
            province: 'อุบลราชธานี',
            zipcode: 34140,
        },
        {
            subDistrict: 'ยางสักกระโพหลุ่ม',
            district: 'ม่วงสามสิบ',
            province: 'อุบลราชธานี',
            zipcode: 34140,
        },
        {
            subDistrict: 'ยางโยภาพ',
            district: 'ม่วงสามสิบ',
            province: 'อุบลราชธานี',
            zipcode: 34140,
        },
        {
            subDistrict: 'หนองช้างใหญ่',
            district: 'ม่วงสามสิบ',
            province: 'อุบลราชธานี',
            zipcode: 34140,
        },
        {
            subDistrict: 'หนองฮาง',
            district: 'ม่วงสามสิบ',
            province: 'อุบลราชธานี',
            zipcode: 34140,
        },
        {
            subDistrict: 'หนองเมือง',
            district: 'ม่วงสามสิบ',
            province: 'อุบลราชธานี',
            zipcode: 34140,
        },
        {
            subDistrict: 'หนองเหล่า',
            district: 'ม่วงสามสิบ',
            province: 'อุบลราชธานี',
            zipcode: 34140,
        },
        {
            subDistrict: 'หนองไข่นก',
            district: 'ม่วงสามสิบ',
            province: 'อุบลราชธานี',
            zipcode: 34140,
        },
        {
            subDistrict: 'เตย',
            district: 'ม่วงสามสิบ',
            province: 'อุบลราชธานี',
            zipcode: 34140,
        },
        {
            subDistrict: 'เหล่าบก',
            district: 'ม่วงสามสิบ',
            province: 'อุบลราชธานี',
            zipcode: 34140,
        },
        {
            subDistrict: 'โพนแพง',
            district: 'ม่วงสามสิบ',
            province: 'อุบลราชธานี',
            zipcode: 34140,
        },
        {
            subDistrict: 'ไผ่ใหญ่',
            district: 'ม่วงสามสิบ',
            province: 'อุบลราชธานี',
            zipcode: 34140,
        },
        {
            subDistrict: 'คำขวาง',
            district: 'วารินชำราบ',
            province: 'อุบลราชธานี',
            zipcode: 34190,
        },
        {
            subDistrict: 'คำน้ำแซบ',
            district: 'วารินชำราบ',
            province: 'อุบลราชธานี',
            zipcode: 34190,
        },
        {
            subDistrict: 'คูเมือง',
            district: 'วารินชำราบ',
            province: 'อุบลราชธานี',
            zipcode: 34190,
        },
        {
            subDistrict: 'ท่าลาด',
            district: 'วารินชำราบ',
            province: 'อุบลราชธานี',
            zipcode: 34310,
        },
        {
            subDistrict: 'ธาตุ',
            district: 'วารินชำราบ',
            province: 'อุบลราชธานี',
            zipcode: 34190,
        },
        {
            subDistrict: 'บุ่งหวาย',
            district: 'วารินชำราบ',
            province: 'อุบลราชธานี',
            zipcode: 34310,
        },
        {
            subDistrict: 'บุ่งไหม',
            district: 'วารินชำราบ',
            province: 'อุบลราชธานี',
            zipcode: 34190,
        },
        {
            subDistrict: 'วารินชำราบ',
            district: 'วารินชำราบ',
            province: 'อุบลราชธานี',
            zipcode: 34190,
        },
        {
            subDistrict: 'สระสมิง',
            district: 'วารินชำราบ',
            province: 'อุบลราชธานี',
            zipcode: 34190,
        },
        {
            subDistrict: 'หนองกินเพล',
            district: 'วารินชำราบ',
            province: 'อุบลราชธานี',
            zipcode: 34190,
        },
        {
            subDistrict: 'ห้วยขะยูง',
            district: 'วารินชำราบ',
            province: 'อุบลราชธานี',
            zipcode: 34310,
        },
        {
            subDistrict: 'เมืองศรีไค',
            district: 'วารินชำราบ',
            province: 'อุบลราชธานี',
            zipcode: 34190,
        },
        {
            subDistrict: 'แสนสุข',
            district: 'วารินชำราบ',
            province: 'อุบลราชธานี',
            zipcode: 34190,
        },
        {
            subDistrict: 'โนนผึ้ง',
            district: 'วารินชำราบ',
            province: 'อุบลราชธานี',
            zipcode: 34190,
        },
        {
            subDistrict: 'โนนโหนน',
            district: 'วารินชำราบ',
            province: 'อุบลราชธานี',
            zipcode: 34190,
        },
        {
            subDistrict: 'โพธิ์ใหญ่',
            district: 'วารินชำราบ',
            province: 'อุบลราชธานี',
            zipcode: 34190,
        },
        {
            subDistrict: 'คำไหล',
            district: 'ศรีเมืองใหม่',
            province: 'อุบลราชธานี',
            zipcode: 34250,
        },
        {
            subDistrict: 'ดอนใหญ่',
            district: 'ศรีเมืองใหม่',
            province: 'อุบลราชธานี',
            zipcode: 34250,
        },
        {
            subDistrict: 'ตะบ่าย',
            district: 'ศรีเมืองใหม่',
            province: 'อุบลราชธานี',
            zipcode: 34250,
        },
        {
            subDistrict: 'นาคำ',
            district: 'ศรีเมืองใหม่',
            province: 'อุบลราชธานี',
            zipcode: 34250,
        },
        {
            subDistrict: 'นาเลิน',
            district: 'ศรีเมืองใหม่',
            province: 'อุบลราชธานี',
            zipcode: 34250,
        },
        {
            subDistrict: 'ลาดควาย',
            district: 'ศรีเมืองใหม่',
            province: 'อุบลราชธานี',
            zipcode: 34250,
        },
        {
            subDistrict: 'วาริน',
            district: 'ศรีเมืองใหม่',
            province: 'อุบลราชธานี',
            zipcode: 34250,
        },
        {
            subDistrict: 'สงยาง',
            district: 'ศรีเมืองใหม่',
            province: 'อุบลราชธานี',
            zipcode: 34250,
        },
        {
            subDistrict: 'หนามแท่ง',
            district: 'ศรีเมืองใหม่',
            province: 'อุบลราชธานี',
            zipcode: 34250,
        },
        {
            subDistrict: 'เอือดใหญ่',
            district: 'ศรีเมืองใหม่',
            province: 'อุบลราชธานี',
            zipcode: 34250,
        },
        {
            subDistrict: 'แก้งกอก',
            district: 'ศรีเมืองใหม่',
            province: 'อุบลราชธานี',
            zipcode: 34250,
        },
        {
            subDistrict: 'ท่าช้าง',
            district: 'สว่างวีระวงศ์',
            province: 'อุบลราชธานี',
            zipcode: 34190,
        },
        {
            subDistrict: 'บุ่งมะแลง',
            district: 'สว่างวีระวงศ์',
            province: 'อุบลราชธานี',
            zipcode: 34190,
        },
        {
            subDistrict: 'สว่าง',
            district: 'สว่างวีระวงศ์',
            province: 'อุบลราชธานี',
            zipcode: 34190,
        },
        {
            subDistrict: 'แก่งโดม',
            district: 'สว่างวีระวงศ์',
            province: 'อุบลราชธานี',
            zipcode: 34190,
        },
        {
            subDistrict: 'ขามป้อม',
            district: 'สำโรง',
            province: 'อุบลราชธานี',
            zipcode: 34360,
        },
        {
            subDistrict: 'ค้อน้อย',
            district: 'สำโรง',
            province: 'อุบลราชธานี',
            zipcode: 34360,
        },
        {
            subDistrict: 'บอน',
            district: 'สำโรง',
            province: 'อุบลราชธานี',
            zipcode: 34360,
        },
        {
            subDistrict: 'สำโรง',
            district: 'สำโรง',
            province: 'อุบลราชธานี',
            zipcode: 34360,
        },
        {
            subDistrict: 'หนองไฮ',
            district: 'สำโรง',
            province: 'อุบลราชธานี',
            zipcode: 34360,
        },
        {
            subDistrict: 'โคกก่อง',
            district: 'สำโรง',
            province: 'อุบลราชธานี',
            zipcode: 34360,
        },
        {
            subDistrict: 'โคกสว่าง',
            district: 'สำโรง',
            province: 'อุบลราชธานี',
            zipcode: 34360,
        },
        {
            subDistrict: 'โนนกลาง',
            district: 'สำโรง',
            province: 'อุบลราชธานี',
            zipcode: 34360,
        },
        {
            subDistrict: 'โนนกาเล็น',
            district: 'สำโรง',
            province: 'อุบลราชธานี',
            zipcode: 34360,
        },
        {
            subDistrict: 'คันไร่',
            district: 'สิรินธร',
            province: 'อุบลราชธานี',
            zipcode: 34350,
        },
        {
            subDistrict: 'คำเขื่อนแก้ว',
            district: 'สิรินธร',
            province: 'อุบลราชธานี',
            zipcode: 34350,
        },
        {
            subDistrict: 'ช่องเม็ก',
            district: 'สิรินธร',
            province: 'อุบลราชธานี',
            zipcode: 34350,
        },
        {
            subDistrict: 'นิคมสร้างตนเองลำโดมน้อย',
            district: 'สิรินธร',
            province: 'อุบลราชธานี',
            zipcode: 34350,
        },
        {
            subDistrict: 'ฝางคำ',
            district: 'สิรินธร',
            province: 'อุบลราชธานี',
            zipcode: 34350,
        },
        {
            subDistrict: 'โนนก่อ',
            district: 'สิรินธร',
            province: 'อุบลราชธานี',
            zipcode: 34350,
        },
        {
            subDistrict: 'ขามป้อม',
            district: 'เขมราฐ',
            province: 'อุบลราชธานี',
            zipcode: 34170,
        },
        {
            subDistrict: 'นาแวง',
            district: 'เขมราฐ',
            province: 'อุบลราชธานี',
            zipcode: 34170,
        },
        {
            subDistrict: 'หนองนกทา',
            district: 'เขมราฐ',
            province: 'อุบลราชธานี',
            zipcode: 34170,
        },
        {
            subDistrict: 'หนองผือ',
            district: 'เขมราฐ',
            province: 'อุบลราชธานี',
            zipcode: 34170,
        },
        {
            subDistrict: 'หนองสิม',
            district: 'เขมราฐ',
            province: 'อุบลราชธานี',
            zipcode: 34170,
        },
        {
            subDistrict: 'หัวนา',
            district: 'เขมราฐ',
            province: 'อุบลราชธานี',
            zipcode: 34170,
        },
        {
            subDistrict: 'เขมราฐ',
            district: 'เขมราฐ',
            province: 'อุบลราชธานี',
            zipcode: 34170,
        },
        {
            subDistrict: 'เจียด',
            district: 'เขมราฐ',
            province: 'อุบลราชธานี',
            zipcode: 34170,
        },
        {
            subDistrict: 'แก้งเหนือ',
            district: 'เขมราฐ',
            province: 'อุบลราชธานี',
            zipcode: 34170,
        },
        {
            subDistrict: 'กลางใหญ่',
            district: 'เขื่องใน',
            province: 'อุบลราชธานี',
            zipcode: 34320,
        },
        {
            subDistrict: 'ก่อเอ้',
            district: 'เขื่องใน',
            province: 'อุบลราชธานี',
            zipcode: 34150,
        },
        {
            subDistrict: 'ค้อทอง',
            district: 'เขื่องใน',
            province: 'อุบลราชธานี',
            zipcode: 34150,
        },
        {
            subDistrict: 'ชีทวน',
            district: 'เขื่องใน',
            province: 'อุบลราชธานี',
            zipcode: 34150,
        },
        {
            subDistrict: 'ท่าไห',
            district: 'เขื่องใน',
            province: 'อุบลราชธานี',
            zipcode: 34150,
        },
        {
            subDistrict: 'ธาตุน้อย',
            district: 'เขื่องใน',
            province: 'อุบลราชธานี',
            zipcode: 34150,
        },
        {
            subDistrict: 'นาคำใหญ่',
            district: 'เขื่องใน',
            province: 'อุบลราชธานี',
            zipcode: 34150,
        },
        {
            subDistrict: 'บ้านกอก',
            district: 'เขื่องใน',
            province: 'อุบลราชธานี',
            zipcode: 34320,
        },
        {
            subDistrict: 'บ้านไทย',
            district: 'เขื่องใน',
            province: 'อุบลราชธานี',
            zipcode: 34320,
        },
        {
            subDistrict: 'ยางขี้นก',
            district: 'เขื่องใน',
            province: 'อุบลราชธานี',
            zipcode: 34150,
        },
        {
            subDistrict: 'ศรีสุข',
            district: 'เขื่องใน',
            province: 'อุบลราชธานี',
            zipcode: 34150,
        },
        {
            subDistrict: 'สร้างถ่อ',
            district: 'เขื่องใน',
            province: 'อุบลราชธานี',
            zipcode: 34150,
        },
        {
            subDistrict: 'สหธาตุ',
            district: 'เขื่องใน',
            province: 'อุบลราชธานี',
            zipcode: 34150,
        },
        {
            subDistrict: 'หนองเหล่า',
            district: 'เขื่องใน',
            province: 'อุบลราชธานี',
            zipcode: 34150,
        },
        {
            subDistrict: 'หัวดอน',
            district: 'เขื่องใน',
            province: 'อุบลราชธานี',
            zipcode: 34150,
        },
        {
            subDistrict: 'เขื่องใน',
            district: 'เขื่องใน',
            province: 'อุบลราชธานี',
            zipcode: 34150,
        },
        {
            subDistrict: 'แดงหม้อ',
            district: 'เขื่องใน',
            province: 'อุบลราชธานี',
            zipcode: 34150,
        },
        {
            subDistrict: 'โนนรัง',
            district: 'เขื่องใน',
            province: 'อุบลราชธานี',
            zipcode: 34320,
        },
        {
            subDistrict: 'กลาง',
            district: 'เดชอุดม',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'กุดประทาย',
            district: 'เดชอุดม',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'คำครั่ง',
            district: 'เดชอุดม',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'ตบหู',
            district: 'เดชอุดม',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'ทุ่งเทิง',
            district: 'เดชอุดม',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'ท่าโพธิ์ศรี',
            district: 'เดชอุดม',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'นากระแซง',
            district: 'เดชอุดม',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'นาส่วง',
            district: 'เดชอุดม',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'นาเจริญ',
            district: 'เดชอุดม',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'บัวงาม',
            district: 'เดชอุดม',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'ป่าโมง',
            district: 'เดชอุดม',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'สมสะอาด',
            district: 'เดชอุดม',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'เมืองเดช',
            district: 'เดชอุดม',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'แก้ง',
            district: 'เดชอุดม',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'โนนสมบูรณ์',
            district: 'เดชอุดม',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'โพนงาม',
            district: 'เดชอุดม',
            province: 'อุบลราชธานี',
            zipcode: 34160,
        },
        {
            subDistrict: 'กระโสบ',
            district: 'เมืองอุบลราชธานี',
            province: 'อุบลราชธานี',
            zipcode: 34000,
        },
        {
            subDistrict: 'กุดลาด',
            district: 'เมืองอุบลราชธานี',
            province: 'อุบลราชธานี',
            zipcode: 34000,
        },
        {
            subDistrict: 'ขามใหญ่',
            district: 'เมืองอุบลราชธานี',
            province: 'อุบลราชธานี',
            zipcode: 34000,
        },
        {
            subDistrict: 'ขี้เหล็ก',
            district: 'เมืองอุบลราชธานี',
            province: 'อุบลราชธานี',
            zipcode: 34000,
        },
        {
            subDistrict: 'ปทุม',
            district: 'เมืองอุบลราชธานี',
            province: 'อุบลราชธานี',
            zipcode: 34000,
        },
        {
            subDistrict: 'ปะอาว',
            district: 'เมืองอุบลราชธานี',
            province: 'อุบลราชธานี',
            zipcode: 34000,
        },
        {
            subDistrict: 'หนองขอน',
            district: 'เมืองอุบลราชธานี',
            province: 'อุบลราชธานี',
            zipcode: 34000,
        },
        {
            subDistrict: 'หนองบ่อ',
            district: 'เมืองอุบลราชธานี',
            province: 'อุบลราชธานี',
            zipcode: 34000,
        },
        {
            subDistrict: 'หัวเรือ',
            district: 'เมืองอุบลราชธานี',
            province: 'อุบลราชธานี',
            zipcode: 34000,
        },
        {
            subDistrict: 'แจระแม',
            district: 'เมืองอุบลราชธานี',
            province: 'อุบลราชธานี',
            zipcode: 34000,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'เมืองอุบลราชธานี',
            province: 'อุบลราชธานี',
            zipcode: 34000,
        },
        {
            subDistrict: 'ไร่น้อย',
            district: 'เมืองอุบลราชธานี',
            province: 'อุบลราชธานี',
            zipcode: 34000,
        },
        {
            subDistrict: 'หนองบก',
            district: 'เหล่าเสือโก้ก',
            province: 'อุบลราชธานี',
            zipcode: 34000,
        },
        {
            subDistrict: 'เหล่าเสือโก้ก',
            district: 'เหล่าเสือโก้ก',
            province: 'อุบลราชธานี',
            zipcode: 34000,
        },
        {
            subDistrict: 'แพงใหญ่',
            district: 'เหล่าเสือโก้ก',
            province: 'อุบลราชธานี',
            zipcode: 34000,
        },
        {
            subDistrict: 'โพนเมือง',
            district: 'เหล่าเสือโก้ก',
            province: 'อุบลราชธานี',
            zipcode: 34000,
        },
        {
            subDistrict: 'นาโพธิ์กลาง',
            district: 'โขงเจียม',
            province: 'อุบลราชธานี',
            zipcode: 34220,
        },
        {
            subDistrict: 'หนองแสงใหญ่',
            district: 'โขงเจียม',
            province: 'อุบลราชธานี',
            zipcode: 34220,
        },
        {
            subDistrict: 'ห้วยยาง',
            district: 'โขงเจียม',
            province: 'อุบลราชธานี',
            zipcode: 34220,
        },
        {
            subDistrict: 'ห้วยไผ่',
            district: 'โขงเจียม',
            province: 'อุบลราชธานี',
            zipcode: 34220,
        },
        {
            subDistrict: 'โขงเจียม',
            district: 'โขงเจียม',
            province: 'อุบลราชธานี',
            zipcode: 34220,
        },
        {
            subDistrict: 'ม่วงใหญ่',
            district: 'โพธิ์ไทร',
            province: 'อุบลราชธานี',
            zipcode: 34340,
        },
        {
            subDistrict: 'สองคอน',
            district: 'โพธิ์ไทร',
            province: 'อุบลราชธานี',
            zipcode: 34340,
        },
        {
            subDistrict: 'สารภี',
            district: 'โพธิ์ไทร',
            province: 'อุบลราชธานี',
            zipcode: 34340,
        },
        {
            subDistrict: 'สำโรง',
            district: 'โพธิ์ไทร',
            province: 'อุบลราชธานี',
            zipcode: 34340,
        },
        {
            subDistrict: 'เหล่างาม',
            district: 'โพธิ์ไทร',
            province: 'อุบลราชธานี',
            zipcode: 34340,
        },
        {
            subDistrict: 'โพธิ์ไทร',
            district: 'โพธิ์ไทร',
            province: 'อุบลราชธานี',
            zipcode: 34340,
        },
        {
            subDistrict: 'นรสิงห์',
            district: 'ป่าโมก',
            province: 'อ่างทอง',
            zipcode: 14130,
        },
        {
            subDistrict: 'บางปลากด',
            district: 'ป่าโมก',
            province: 'อ่างทอง',
            zipcode: 14130,
        },
        {
            subDistrict: 'บางเสด็จ',
            district: 'ป่าโมก',
            province: 'อ่างทอง',
            zipcode: 14130,
        },
        {
            subDistrict: 'ป่าโมก',
            district: 'ป่าโมก',
            province: 'อ่างทอง',
            zipcode: 14130,
        },
        {
            subDistrict: 'สายทอง',
            district: 'ป่าโมก',
            province: 'อ่างทอง',
            zipcode: 14130,
        },
        {
            subDistrict: 'เอกราช',
            district: 'ป่าโมก',
            province: 'อ่างทอง',
            zipcode: 14130,
        },
        {
            subDistrict: 'โผงเผง',
            district: 'ป่าโมก',
            province: 'อ่างทอง',
            zipcode: 14130,
        },
        {
            subDistrict: 'โรงช้าง',
            district: 'ป่าโมก',
            province: 'อ่างทอง',
            zipcode: 14130,
        },
        {
            subDistrict: 'คลองขนาก',
            district: 'วิเศษชัยชาญ',
            province: 'อ่างทอง',
            zipcode: 14110,
        },
        {
            subDistrict: 'ตลาดใหม่',
            district: 'วิเศษชัยชาญ',
            province: 'อ่างทอง',
            zipcode: 14110,
        },
        {
            subDistrict: 'ท่าช้าง',
            district: 'วิเศษชัยชาญ',
            province: 'อ่างทอง',
            zipcode: 14110,
        },
        {
            subDistrict: 'บางจัก',
            district: 'วิเศษชัยชาญ',
            province: 'อ่างทอง',
            zipcode: 14110,
        },
        {
            subDistrict: 'ม่วงเตี้ย',
            district: 'วิเศษชัยชาญ',
            province: 'อ่างทอง',
            zipcode: 14110,
        },
        {
            subDistrict: 'ยี่ล้น',
            district: 'วิเศษชัยชาญ',
            province: 'อ่างทอง',
            zipcode: 14110,
        },
        {
            subDistrict: 'ศาลเจ้าโรงทอง',
            district: 'วิเศษชัยชาญ',
            province: 'อ่างทอง',
            zipcode: 14110,
        },
        {
            subDistrict: 'สาวร้องไห้',
            district: 'วิเศษชัยชาญ',
            province: 'อ่างทอง',
            zipcode: 14110,
        },
        {
            subDistrict: 'สี่ร้อย',
            district: 'วิเศษชัยชาญ',
            province: 'อ่างทอง',
            zipcode: 14110,
        },
        {
            subDistrict: 'หลักแก้ว',
            district: 'วิเศษชัยชาญ',
            province: 'อ่างทอง',
            zipcode: 14110,
        },
        {
            subDistrict: 'หัวตะพาน',
            district: 'วิเศษชัยชาญ',
            province: 'อ่างทอง',
            zipcode: 14110,
        },
        {
            subDistrict: 'ห้วยคันแหลน',
            district: 'วิเศษชัยชาญ',
            province: 'อ่างทอง',
            zipcode: 14110,
        },
        {
            subDistrict: 'ไผ่จำศีล',
            district: 'วิเศษชัยชาญ',
            province: 'อ่างทอง',
            zipcode: 14110,
        },
        {
            subDistrict: 'ไผ่ดำพัฒนา',
            district: 'วิเศษชัยชาญ',
            province: 'อ่างทอง',
            zipcode: 14110,
        },
        {
            subDistrict: 'ไผ่วง',
            district: 'วิเศษชัยชาญ',
            province: 'อ่างทอง',
            zipcode: 14110,
        },
        {
            subDistrict: 'มงคลธรรมนิมิต',
            district: 'สามโก้',
            province: 'อ่างทอง',
            zipcode: 14160,
        },
        {
            subDistrict: 'ราษฎรพัฒนา',
            district: 'สามโก้',
            province: 'อ่างทอง',
            zipcode: 14160,
        },
        {
            subDistrict: 'สามโก้',
            district: 'สามโก้',
            province: 'อ่างทอง',
            zipcode: 14160,
        },
        {
            subDistrict: 'อบทม',
            district: 'สามโก้',
            province: 'อ่างทอง',
            zipcode: 14160,
        },
        {
            subDistrict: 'โพธิ์ม่วงพันธ์',
            district: 'สามโก้',
            province: 'อ่างทอง',
            zipcode: 14160,
        },
        {
            subDistrict: 'คลองวัว',
            district: 'เมืองอ่างทอง',
            province: 'อ่างทอง',
            zipcode: 14000,
        },
        {
            subDistrict: 'จำปาหล่อ',
            district: 'เมืองอ่างทอง',
            province: 'อ่างทอง',
            zipcode: 14000,
        },
        {
            subDistrict: 'ตลาดกรวด',
            district: 'เมืองอ่างทอง',
            province: 'อ่างทอง',
            zipcode: 14000,
        },
        {
            subDistrict: 'ตลาดหลวง',
            district: 'เมืองอ่างทอง',
            province: 'อ่างทอง',
            zipcode: 14000,
        },
        {
            subDistrict: 'บางแก้ว',
            district: 'เมืองอ่างทอง',
            province: 'อ่างทอง',
            zipcode: 14000,
        },
        {
            subDistrict: 'บ้านรี',
            district: 'เมืองอ่างทอง',
            province: 'อ่างทอง',
            zipcode: 14000,
        },
        {
            subDistrict: 'บ้านอิฐ',
            district: 'เมืองอ่างทอง',
            province: 'อ่างทอง',
            zipcode: 14000,
        },
        {
            subDistrict: 'บ้านแห',
            district: 'เมืองอ่างทอง',
            province: 'อ่างทอง',
            zipcode: 14000,
        },
        {
            subDistrict: 'ป่างิ้ว',
            district: 'เมืองอ่างทอง',
            province: 'อ่างทอง',
            zipcode: 14000,
        },
        {
            subDistrict: 'มหาดไทย',
            district: 'เมืองอ่างทอง',
            province: 'อ่างทอง',
            zipcode: 14000,
        },
        {
            subDistrict: 'ย่านซื่อ',
            district: 'เมืองอ่างทอง',
            province: 'อ่างทอง',
            zipcode: 14000,
        },
        {
            subDistrict: 'ศาลาแดง',
            district: 'เมืองอ่างทอง',
            province: 'อ่างทอง',
            zipcode: 14000,
        },
        {
            subDistrict: 'หัวไผ่',
            district: 'เมืองอ่างทอง',
            province: 'อ่างทอง',
            zipcode: 14000,
        },
        {
            subDistrict: 'โพสะ',
            district: 'เมืองอ่างทอง',
            province: 'อ่างทอง',
            zipcode: 14000,
        },
        {
            subDistrict: 'จำลอง',
            district: 'แสวงหา',
            province: 'อ่างทอง',
            zipcode: 14150,
        },
        {
            subDistrict: 'บ้านพราน',
            district: 'แสวงหา',
            province: 'อ่างทอง',
            zipcode: 14150,
        },
        {
            subDistrict: 'วังน้ำเย็น',
            district: 'แสวงหา',
            province: 'อ่างทอง',
            zipcode: 14150,
        },
        {
            subDistrict: 'ศรีพราน',
            district: 'แสวงหา',
            province: 'อ่างทอง',
            zipcode: 14150,
        },
        {
            subDistrict: 'สีบัวทอง',
            district: 'แสวงหา',
            province: 'อ่างทอง',
            zipcode: 14150,
        },
        {
            subDistrict: 'ห้วยไผ่',
            district: 'แสวงหา',
            province: 'อ่างทอง',
            zipcode: 14150,
        },
        {
            subDistrict: 'แสวงหา',
            district: 'แสวงหา',
            province: 'อ่างทอง',
            zipcode: 14150,
        },
        {
            subDistrict: 'คำหยาด',
            district: 'โพธิ์ทอง',
            province: 'อ่างทอง',
            zipcode: 14120,
        },
        {
            subDistrict: 'ทางพระ',
            district: 'โพธิ์ทอง',
            province: 'อ่างทอง',
            zipcode: 14120,
        },
        {
            subDistrict: 'บางพลับ',
            district: 'โพธิ์ทอง',
            province: 'อ่างทอง',
            zipcode: 14120,
        },
        {
            subDistrict: 'บางระกำ',
            district: 'โพธิ์ทอง',
            province: 'อ่างทอง',
            zipcode: 14120,
        },
        {
            subDistrict: 'บางเจ้าฉ่า',
            district: 'โพธิ์ทอง',
            province: 'อ่างทอง',
            zipcode: 14120,
        },
        {
            subDistrict: 'บ่อแร่',
            district: 'โพธิ์ทอง',
            province: 'อ่างทอง',
            zipcode: 14120,
        },
        {
            subDistrict: 'ยางช้าย',
            district: 'โพธิ์ทอง',
            province: 'อ่างทอง',
            zipcode: 14120,
        },
        {
            subDistrict: 'รำมะสัก',
            district: 'โพธิ์ทอง',
            province: 'อ่างทอง',
            zipcode: 14120,
        },
        {
            subDistrict: 'สามง่าม',
            district: 'โพธิ์ทอง',
            province: 'อ่างทอง',
            zipcode: 14120,
        },
        {
            subDistrict: 'หนองแม่ไก่',
            district: 'โพธิ์ทอง',
            province: 'อ่างทอง',
            zipcode: 14120,
        },
        {
            subDistrict: 'องครักษ์',
            district: 'โพธิ์ทอง',
            province: 'อ่างทอง',
            zipcode: 14120,
        },
        {
            subDistrict: 'อินทประมูล',
            district: 'โพธิ์ทอง',
            province: 'อ่างทอง',
            zipcode: 14120,
        },
        {
            subDistrict: 'อ่างแก้ว',
            district: 'โพธิ์ทอง',
            province: 'อ่างทอง',
            zipcode: 14120,
        },
        {
            subDistrict: 'โคกพุทรา',
            district: 'โพธิ์ทอง',
            province: 'อ่างทอง',
            zipcode: 14120,
        },
        {
            subDistrict: 'โพธิ์รังนก',
            district: 'โพธิ์ทอง',
            province: 'อ่างทอง',
            zipcode: 14120,
        },
        {
            subDistrict: 'จรเข้ร้อง',
            district: 'ไชโย',
            province: 'อ่างทอง',
            zipcode: 14140,
        },
        {
            subDistrict: 'ชะไว',
            district: 'ไชโย',
            province: 'อ่างทอง',
            zipcode: 14140,
        },
        {
            subDistrict: 'ชัยฤทธิ์',
            district: 'ไชโย',
            province: 'อ่างทอง',
            zipcode: 14140,
        },
        {
            subDistrict: 'ตรีณรงค์',
            district: 'ไชโย',
            province: 'อ่างทอง',
            zipcode: 14140,
        },
        {
            subDistrict: 'ราชสถิตย์',
            district: 'ไชโย',
            province: 'อ่างทอง',
            zipcode: 14140,
        },
        {
            subDistrict: 'หลักฟ้า',
            district: 'ไชโย',
            province: 'อ่างทอง',
            zipcode: 14140,
        },
        {
            subDistrict: 'เทวราช',
            district: 'ไชโย',
            province: 'อ่างทอง',
            zipcode: 14140,
        },
        {
            subDistrict: 'ไชยภูมิ',
            district: 'ไชโย',
            province: 'อ่างทอง',
            zipcode: 14140,
        },
        {
            subDistrict: 'ไชโย',
            district: 'ไชโย',
            province: 'อ่างทอง',
            zipcode: 14140,
        },
        {
            subDistrict: 'ต้า',
            district: 'ขุนตาล',
            province: 'เชียงราย',
            zipcode: 57340,
        },
        {
            subDistrict: 'ป่าตาล',
            district: 'ขุนตาล',
            province: 'เชียงราย',
            zipcode: 57340,
        },
        {
            subDistrict: 'ยางฮอม',
            district: 'ขุนตาล',
            province: 'เชียงราย',
            zipcode: 57340,
        },
        {
            subDistrict: 'ปงน้อย',
            district: 'ดอยหลวง',
            province: 'เชียงราย',
            zipcode: 57110,
        },
        {
            subDistrict: 'หนองป่าก่อ',
            district: 'ดอยหลวง',
            province: 'เชียงราย',
            zipcode: 57110,
        },
        {
            subDistrict: 'โชคชัย',
            district: 'ดอยหลวง',
            province: 'เชียงราย',
            zipcode: 57110,
        },
        {
            subDistrict: 'ป่าแงะ',
            district: 'ป่าแดด',
            province: 'เชียงราย',
            zipcode: 57190,
        },
        {
            subDistrict: 'ป่าแดด',
            district: 'ป่าแดด',
            province: 'เชียงราย',
            zipcode: 57190,
        },
        {
            subDistrict: 'ศรีโพธิ์เงิน',
            district: 'ป่าแดด',
            province: 'เชียงราย',
            zipcode: 57190,
        },
        {
            subDistrict: 'สันมะค่า',
            district: 'ป่าแดด',
            province: 'เชียงราย',
            zipcode: 57190,
        },
        {
            subDistrict: 'โรงช้าง',
            district: 'ป่าแดด',
            province: 'เชียงราย',
            zipcode: 57190,
        },
        {
            subDistrict: 'ตาดควัน',
            district: 'พญาเม็งราย',
            province: 'เชียงราย',
            zipcode: 57290,
        },
        {
            subDistrict: 'เม็งราย',
            district: 'พญาเม็งราย',
            province: 'เชียงราย',
            zipcode: 57290,
        },
        {
            subDistrict: 'แม่ต๋ำ',
            district: 'พญาเม็งราย',
            province: 'เชียงราย',
            zipcode: 57290,
        },
        {
            subDistrict: 'แม่เปา',
            district: 'พญาเม็งราย',
            province: 'เชียงราย',
            zipcode: 57290,
        },
        {
            subDistrict: 'ไม้ยา',
            district: 'พญาเม็งราย',
            province: 'เชียงราย',
            zipcode: 57290,
        },
        {
            subDistrict: 'ดอยงาม',
            district: 'พาน',
            province: 'เชียงราย',
            zipcode: 57120,
        },
        {
            subDistrict: 'ทรายขาว',
            district: 'พาน',
            province: 'เชียงราย',
            zipcode: 57120,
        },
        {
            subDistrict: 'ทานตะวัน',
            district: 'พาน',
            province: 'เชียงราย',
            zipcode: 57280,
        },
        {
            subDistrict: 'ธารทอง',
            district: 'พาน',
            province: 'เชียงราย',
            zipcode: 57250,
        },
        {
            subDistrict: 'ป่าหุ่ง',
            district: 'พาน',
            province: 'เชียงราย',
            zipcode: 57120,
        },
        {
            subDistrict: 'ม่วงคำ',
            district: 'พาน',
            province: 'เชียงราย',
            zipcode: 57120,
        },
        {
            subDistrict: 'สันกลาง',
            district: 'พาน',
            province: 'เชียงราย',
            zipcode: 57120,
        },
        {
            subDistrict: 'สันติสุข',
            district: 'พาน',
            province: 'เชียงราย',
            zipcode: 57120,
        },
        {
            subDistrict: 'สันมะเค็ด',
            district: 'พาน',
            province: 'เชียงราย',
            zipcode: 57120,
        },
        {
            subDistrict: 'หัวง้ม',
            district: 'พาน',
            province: 'เชียงราย',
            zipcode: 57120,
        },
        {
            subDistrict: 'เจริญเมือง',
            district: 'พาน',
            province: 'เชียงราย',
            zipcode: 57120,
        },
        {
            subDistrict: 'เมืองพาน',
            district: 'พาน',
            province: 'เชียงราย',
            zipcode: 57120,
        },
        {
            subDistrict: 'เวียงห้าว',
            district: 'พาน',
            province: 'เชียงราย',
            zipcode: 57120,
        },
        {
            subDistrict: 'แม่อ้อ',
            district: 'พาน',
            province: 'เชียงราย',
            zipcode: 57120,
        },
        {
            subDistrict: 'แม่เย็น',
            district: 'พาน',
            province: 'เชียงราย',
            zipcode: 57280,
        },
        {
            subDistrict: 'ครึ่ง',
            district: 'เชียงของ',
            province: 'เชียงราย',
            zipcode: 57140,
        },
        {
            subDistrict: 'บุญเรือง',
            district: 'เชียงของ',
            province: 'เชียงราย',
            zipcode: 57140,
        },
        {
            subDistrict: 'ริมโขง',
            district: 'เชียงของ',
            province: 'เชียงราย',
            zipcode: 57140,
        },
        {
            subDistrict: 'ศรีดอนชัย',
            district: 'เชียงของ',
            province: 'เชียงราย',
            zipcode: 57140,
        },
        {
            subDistrict: 'สถาน',
            district: 'เชียงของ',
            province: 'เชียงราย',
            zipcode: 57140,
        },
        {
            subDistrict: 'ห้วยซ้อ',
            district: 'เชียงของ',
            province: 'เชียงราย',
            zipcode: 57140,
        },
        {
            subDistrict: 'เวียง',
            district: 'เชียงของ',
            province: 'เชียงราย',
            zipcode: 57140,
        },
        {
            subDistrict: 'บ้านแซว',
            district: 'เชียงแสน',
            province: 'เชียงราย',
            zipcode: 57150,
        },
        {
            subDistrict: 'ป่าสัก',
            district: 'เชียงแสน',
            province: 'เชียงราย',
            zipcode: 57150,
        },
        {
            subDistrict: 'ศรีดอนมูล',
            district: 'เชียงแสน',
            province: 'เชียงราย',
            zipcode: 57150,
        },
        {
            subDistrict: 'เวียง',
            district: 'เชียงแสน',
            province: 'เชียงราย',
            zipcode: 57150,
        },
        {
            subDistrict: 'แม่เงิน',
            district: 'เชียงแสน',
            province: 'เชียงราย',
            zipcode: 57150,
        },
        {
            subDistrict: 'โยนก',
            district: 'เชียงแสน',
            province: 'เชียงราย',
            zipcode: 57150,
        },
        {
            subDistrict: 'งิ้ว',
            district: 'เทิง',
            province: 'เชียงราย',
            zipcode: 57160,
        },
        {
            subDistrict: 'ตับเต่า',
            district: 'เทิง',
            province: 'เชียงราย',
            zipcode: 57160,
        },
        {
            subDistrict: 'ปล้อง',
            district: 'เทิง',
            province: 'เชียงราย',
            zipcode: 57230,
        },
        {
            subDistrict: 'ศรีดอนไชย',
            district: 'เทิง',
            province: 'เชียงราย',
            zipcode: 57230,
        },
        {
            subDistrict: 'สันทรายงาม',
            district: 'เทิง',
            province: 'เชียงราย',
            zipcode: 57160,
        },
        {
            subDistrict: 'หงาว',
            district: 'เทิง',
            province: 'เชียงราย',
            zipcode: 57160,
        },
        {
            subDistrict: 'หนองแรด',
            district: 'เทิง',
            province: 'เชียงราย',
            zipcode: 57230,
        },
        {
            subDistrict: 'เชียงเคี่ยน',
            district: 'เทิง',
            province: 'เชียงราย',
            zipcode: 57230,
        },
        {
            subDistrict: 'เวียง',
            district: 'เทิง',
            province: 'เชียงราย',
            zipcode: 57160,
        },
        {
            subDistrict: 'แม่ลอย',
            district: 'เทิง',
            province: 'เชียงราย',
            zipcode: 57230,
        },
        {
            subDistrict: 'ดอยลาน',
            district: 'เมืองเชียงราย',
            province: 'เชียงราย',
            zipcode: 57000,
        },
        {
            subDistrict: 'ดอยฮาง',
            district: 'เมืองเชียงราย',
            province: 'เชียงราย',
            zipcode: 57000,
        },
        {
            subDistrict: 'ท่าสาย',
            district: 'เมืองเชียงราย',
            province: 'เชียงราย',
            zipcode: 57000,
        },
        {
            subDistrict: 'ท่าสุด',
            district: 'เมืองเชียงราย',
            province: 'เชียงราย',
            zipcode: 57100,
        },
        {
            subDistrict: 'นางแล',
            district: 'เมืองเชียงราย',
            province: 'เชียงราย',
            zipcode: 57100,
        },
        {
            subDistrict: 'บ้านดู่',
            district: 'เมืองเชียงราย',
            province: 'เชียงราย',
            zipcode: 57100,
        },
        {
            subDistrict: 'ป่าอ้อดอนชัย',
            district: 'เมืองเชียงราย',
            province: 'เชียงราย',
            zipcode: 57000,
        },
        {
            subDistrict: 'รอบเวียง',
            district: 'เมืองเชียงราย',
            province: 'เชียงราย',
            zipcode: 57000,
        },
        {
            subDistrict: 'ริมกก',
            district: 'เมืองเชียงราย',
            province: 'เชียงราย',
            zipcode: 57100,
        },
        {
            subDistrict: 'สันทราย',
            district: 'เมืองเชียงราย',
            province: 'เชียงราย',
            zipcode: 57000,
        },
        {
            subDistrict: 'ห้วยชมภู',
            district: 'เมืองเชียงราย',
            province: 'เชียงราย',
            zipcode: 57000,
        },
        {
            subDistrict: 'ห้วยสัก',
            district: 'เมืองเชียงราย',
            province: 'เชียงราย',
            zipcode: 57000,
        },
        {
            subDistrict: 'เวียง',
            district: 'เมืองเชียงราย',
            province: 'เชียงราย',
            zipcode: 57000,
        },
        {
            subDistrict: 'แม่กรณ์',
            district: 'เมืองเชียงราย',
            province: 'เชียงราย',
            zipcode: 57000,
        },
        {
            subDistrict: 'แม่ข้าวต้ม',
            district: 'เมืองเชียงราย',
            province: 'เชียงราย',
            zipcode: 57100,
        },
        {
            subDistrict: 'แม่ยาว',
            district: 'เมืองเชียงราย',
            province: 'เชียงราย',
            zipcode: 57100,
        },
        {
            subDistrict: 'ดอนศิลา',
            district: 'เวียงชัย',
            province: 'เชียงราย',
            zipcode: 57210,
        },
        {
            subDistrict: 'ผางาม',
            district: 'เวียงชัย',
            province: 'เชียงราย',
            zipcode: 57210,
        },
        {
            subDistrict: 'เมืองชุม',
            district: 'เวียงชัย',
            province: 'เชียงราย',
            zipcode: 57210,
        },
        {
            subDistrict: 'เวียงชัย',
            district: 'เวียงชัย',
            province: 'เชียงราย',
            zipcode: 57210,
        },
        {
            subDistrict: 'เวียงเหนือ',
            district: 'เวียงชัย',
            province: 'เชียงราย',
            zipcode: 57210,
        },
        {
            subDistrict: 'บ้านโป่ง',
            district: 'เวียงป่าเป้า',
            province: 'เชียงราย',
            zipcode: 57170,
        },
        {
            subDistrict: 'ป่างิ้ว',
            district: 'เวียงป่าเป้า',
            province: 'เชียงราย',
            zipcode: 57170,
        },
        {
            subDistrict: 'สันสลี',
            district: 'เวียงป่าเป้า',
            province: 'เชียงราย',
            zipcode: 57170,
        },
        {
            subDistrict: 'เวียง',
            district: 'เวียงป่าเป้า',
            province: 'เชียงราย',
            zipcode: 57170,
        },
        {
            subDistrict: 'เวียงกาหลง',
            district: 'เวียงป่าเป้า',
            province: 'เชียงราย',
            zipcode: 57260,
        },
        {
            subDistrict: 'แม่เจดีย์',
            district: 'เวียงป่าเป้า',
            province: 'เชียงราย',
            zipcode: 57260,
        },
        {
            subDistrict: 'แม่เจดีย์ใหม่',
            district: 'เวียงป่าเป้า',
            province: 'เชียงราย',
            zipcode: 57260,
        },
        {
            subDistrict: 'ดงมหาวัน',
            district: 'เวียงเชียงรุ้ง',
            province: 'เชียงราย',
            zipcode: 57210,
        },
        {
            subDistrict: 'ทุ่งก่อ',
            district: 'เวียงเชียงรุ้ง',
            province: 'เชียงราย',
            zipcode: 57210,
        },
        {
            subDistrict: 'ป่าซาง',
            district: 'เวียงเชียงรุ้ง',
            province: 'เชียงราย',
            zipcode: 57210,
        },
        {
            subDistrict: 'ท่าข้าม',
            district: 'เวียงแก่น',
            province: 'เชียงราย',
            zipcode: 57310,
        },
        {
            subDistrict: 'ปอ',
            district: 'เวียงแก่น',
            province: 'เชียงราย',
            zipcode: 57310,
        },
        {
            subDistrict: 'ม่วงยาย',
            district: 'เวียงแก่น',
            province: 'เชียงราย',
            zipcode: 57310,
        },
        {
            subDistrict: 'หล่ายงาว',
            district: 'เวียงแก่น',
            province: 'เชียงราย',
            zipcode: 57310,
        },
        {
            subDistrict: 'จอมสวรรค์',
            district: 'แม่จัน',
            province: 'เชียงราย',
            zipcode: 57110,
        },
        {
            subDistrict: 'จันจว้า',
            district: 'แม่จัน',
            province: 'เชียงราย',
            zipcode: 57270,
        },
        {
            subDistrict: 'จันจว้าใต้',
            district: 'แม่จัน',
            province: 'เชียงราย',
            zipcode: 57270,
        },
        {
            subDistrict: 'ท่าข้าวเปลือก',
            district: 'แม่จัน',
            province: 'เชียงราย',
            zipcode: 57110,
        },
        {
            subDistrict: 'ป่าซาง',
            district: 'แม่จัน',
            province: 'เชียงราย',
            zipcode: 57110,
        },
        {
            subDistrict: 'ป่าตึง',
            district: 'แม่จัน',
            province: 'เชียงราย',
            zipcode: 57110,
        },
        {
            subDistrict: 'ศรีค้ำ',
            district: 'แม่จัน',
            province: 'เชียงราย',
            zipcode: 57110,
        },
        {
            subDistrict: 'สันทราย',
            district: 'แม่จัน',
            province: 'เชียงราย',
            zipcode: 57110,
        },
        {
            subDistrict: 'แม่คำ',
            district: 'แม่จัน',
            province: 'เชียงราย',
            zipcode: 57240,
        },
        {
            subDistrict: 'แม่จัน',
            district: 'แม่จัน',
            province: 'เชียงราย',
            zipcode: 57110,
        },
        {
            subDistrict: 'แม่ไร่',
            district: 'แม่จัน',
            province: 'เชียงราย',
            zipcode: 57240,
        },
        {
            subDistrict: 'เทอดไทย',
            district: 'แม่ฟ้าหลวง',
            province: 'เชียงราย',
            zipcode: 57240,
        },
        {
            subDistrict: 'แม่ฟ้าหลวง',
            district: 'แม่ฟ้าหลวง',
            province: 'เชียงราย',
            zipcode: 57240,
        },
        {
            subDistrict: 'แม่สลองนอก',
            district: 'แม่ฟ้าหลวง',
            province: 'เชียงราย',
            zipcode: 57110,
        },
        {
            subDistrict: 'แม่สลองใน',
            district: 'แม่ฟ้าหลวง',
            province: 'เชียงราย',
            zipcode: 57110,
        },
        {
            subDistrict: 'จอมหมอกแก้ว',
            district: 'แม่ลาว',
            province: 'เชียงราย',
            zipcode: 57250,
        },
        {
            subDistrict: 'ดงมะดะ',
            district: 'แม่ลาว',
            province: 'เชียงราย',
            zipcode: 57250,
        },
        {
            subDistrict: 'บัวสลี',
            district: 'แม่ลาว',
            province: 'เชียงราย',
            zipcode: 57250,
        },
        {
            subDistrict: 'ป่าก่อดำ',
            district: 'แม่ลาว',
            province: 'เชียงราย',
            zipcode: 57250,
        },
        {
            subDistrict: 'โป่งแพร่',
            district: 'แม่ลาว',
            province: 'เชียงราย',
            zipcode: 57000,
        },
        {
            subDistrict: 'ท่าก๊อ',
            district: 'แม่สรวย',
            province: 'เชียงราย',
            zipcode: 57180,
        },
        {
            subDistrict: 'ป่าแดด',
            district: 'แม่สรวย',
            province: 'เชียงราย',
            zipcode: 57180,
        },
        {
            subDistrict: 'วาวี',
            district: 'แม่สรวย',
            province: 'เชียงราย',
            zipcode: 57180,
        },
        {
            subDistrict: 'ศรีถ้อย',
            district: 'แม่สรวย',
            province: 'เชียงราย',
            zipcode: 57180,
        },
        {
            subDistrict: 'เจดีย์หลวง',
            district: 'แม่สรวย',
            province: 'เชียงราย',
            zipcode: 57180,
        },
        {
            subDistrict: 'แม่พริก',
            district: 'แม่สรวย',
            province: 'เชียงราย',
            zipcode: 57180,
        },
        {
            subDistrict: 'แม่สรวย',
            district: 'แม่สรวย',
            province: 'เชียงราย',
            zipcode: 57180,
        },
        {
            subDistrict: 'บ้านด้าย',
            district: 'แม่สาย',
            province: 'เชียงราย',
            zipcode: 57220,
        },
        {
            subDistrict: 'ศรีเมืองชุม',
            district: 'แม่สาย',
            province: 'เชียงราย',
            zipcode: 57130,
        },
        {
            subDistrict: 'ห้วยไคร้',
            district: 'แม่สาย',
            province: 'เชียงราย',
            zipcode: 57220,
        },
        {
            subDistrict: 'เกาะช้าง',
            district: 'แม่สาย',
            province: 'เชียงราย',
            zipcode: 57130,
        },
        {
            subDistrict: 'เวียงพางคำ',
            district: 'แม่สาย',
            province: 'เชียงราย',
            zipcode: 57130,
        },
        {
            subDistrict: 'แม่สาย',
            district: 'แม่สาย',
            province: 'เชียงราย',
            zipcode: 57130,
        },
        {
            subDistrict: 'โป่งงาม',
            district: 'แม่สาย',
            province: 'เชียงราย',
            zipcode: 57130,
        },
        {
            subDistrict: 'โป่งผา',
            district: 'แม่สาย',
            province: 'เชียงราย',
            zipcode: 57130,
        },
        {
            subDistrict: 'บ้านจันทร์',
            district: 'กัลยาณิวัฒนา',
            province: 'เชียงใหม่',
            zipcode: 58130,
        },
        {
            subDistrict: 'แจ่มหลวง',
            district: 'กัลยาณิวัฒนา',
            province: 'เชียงใหม่',
            zipcode: 58130,
        },
        {
            subDistrict: 'แม่แดด',
            district: 'กัลยาณิวัฒนา',
            province: 'เชียงใหม่',
            zipcode: 58130,
        },
        {
            subDistrict: 'ข่วงเปา',
            district: 'จอมทอง',
            province: 'เชียงใหม่',
            zipcode: 50160,
        },
        {
            subDistrict: 'ดอยแก้ว',
            district: 'จอมทอง',
            province: 'เชียงใหม่',
            zipcode: 50160,
        },
        {
            subDistrict: 'บ้านหลวง',
            district: 'จอมทอง',
            province: 'เชียงใหม่',
            zipcode: 50160,
        },
        {
            subDistrict: 'บ้านแปะ',
            district: 'จอมทอง',
            province: 'เชียงใหม่',
            zipcode: 50240,
        },
        {
            subDistrict: 'สบเตี๊ยะ',
            district: 'จอมทอง',
            province: 'เชียงใหม่',
            zipcode: 50160,
        },
        {
            subDistrict: 'แม่สอย',
            district: 'จอมทอง',
            province: 'เชียงใหม่',
            zipcode: 50240,
        },
        {
            subDistrict: 'ตลาดขวัญ',
            district: 'ดอยสะเก็ด',
            province: 'เชียงใหม่',
            zipcode: 50220,
        },
        {
            subDistrict: 'ตลาดใหญ่',
            district: 'ดอยสะเก็ด',
            province: 'เชียงใหม่',
            zipcode: 50220,
        },
        {
            subDistrict: 'ป่าป้อง',
            district: 'ดอยสะเก็ด',
            province: 'เชียงใหม่',
            zipcode: 50220,
        },
        {
            subDistrict: 'ป่าลาน',
            district: 'ดอยสะเก็ด',
            province: 'เชียงใหม่',
            zipcode: 50220,
        },
        {
            subDistrict: 'ป่าเมี่ยง',
            district: 'ดอยสะเก็ด',
            province: 'เชียงใหม่',
            zipcode: 50220,
        },
        {
            subDistrict: 'ลวงเหนือ',
            district: 'ดอยสะเก็ด',
            province: 'เชียงใหม่',
            zipcode: 50220,
        },
        {
            subDistrict: 'สง่าบ้าน',
            district: 'ดอยสะเก็ด',
            province: 'เชียงใหม่',
            zipcode: 50220,
        },
        {
            subDistrict: 'สันปูเลย',
            district: 'ดอยสะเก็ด',
            province: 'เชียงใหม่',
            zipcode: 50220,
        },
        {
            subDistrict: 'สำราญราษฎร์',
            district: 'ดอยสะเก็ด',
            province: 'เชียงใหม่',
            zipcode: 50220,
        },
        {
            subDistrict: 'เชิงดอย',
            district: 'ดอยสะเก็ด',
            province: 'เชียงใหม่',
            zipcode: 50220,
        },
        {
            subDistrict: 'เทพเสด็จ',
            district: 'ดอยสะเก็ด',
            province: 'เชียงใหม่',
            zipcode: 50220,
        },
        {
            subDistrict: 'แม่คือ',
            district: 'ดอยสะเก็ด',
            province: 'เชียงใหม่',
            zipcode: 50220,
        },
        {
            subDistrict: 'แม่ฮ้อยเงิน',
            district: 'ดอยสะเก็ด',
            province: 'เชียงใหม่',
            zipcode: 50220,
        },
        {
            subDistrict: 'แม่โป่ง',
            district: 'ดอยสะเก็ด',
            province: 'เชียงใหม่',
            zipcode: 50220,
        },
        {
            subDistrict: 'ดอยหล่อ',
            district: 'ดอยหล่อ',
            province: 'เชียงใหม่',
            zipcode: 50160,
        },
        {
            subDistrict: 'ยางคราม',
            district: 'ดอยหล่อ',
            province: 'เชียงใหม่',
            zipcode: 50160,
        },
        {
            subDistrict: 'สองแคว',
            district: 'ดอยหล่อ',
            province: 'เชียงใหม่',
            zipcode: 50160,
        },
        {
            subDistrict: 'สันติสุข',
            district: 'ดอยหล่อ',
            province: 'เชียงใหม่',
            zipcode: 50160,
        },
        {
            subDistrict: 'ดอยเต่า',
            district: 'ดอยเต่า',
            province: 'เชียงใหม่',
            zipcode: 50260,
        },
        {
            subDistrict: 'ท่าเดื่อ',
            district: 'ดอยเต่า',
            province: 'เชียงใหม่',
            zipcode: 50260,
        },
        {
            subDistrict: 'บงตัน',
            district: 'ดอยเต่า',
            province: 'เชียงใหม่',
            zipcode: 50260,
        },
        {
            subDistrict: 'บ้านแอ่น',
            district: 'ดอยเต่า',
            province: 'เชียงใหม่',
            zipcode: 50260,
        },
        {
            subDistrict: 'มืดกา',
            district: 'ดอยเต่า',
            province: 'เชียงใหม่',
            zipcode: 50260,
        },
        {
            subDistrict: 'โปงทุ่ง',
            district: 'ดอยเต่า',
            province: 'เชียงใหม่',
            zipcode: 50260,
        },
        {
            subDistrict: 'ม่อนปิ่น',
            district: 'ฝาง',
            province: 'เชียงใหม่',
            zipcode: 50110,
        },
        {
            subDistrict: 'สันทราย',
            district: 'ฝาง',
            province: 'เชียงใหม่',
            zipcode: 50110,
        },
        {
            subDistrict: 'เวียง',
            district: 'ฝาง',
            province: 'เชียงใหม่',
            zipcode: 50110,
        },
        {
            subDistrict: 'แม่ข่า',
            district: 'ฝาง',
            province: 'เชียงใหม่',
            zipcode: 50320,
        },
        {
            subDistrict: 'แม่คะ',
            district: 'ฝาง',
            province: 'เชียงใหม่',
            zipcode: 50110,
        },
        {
            subDistrict: 'แม่งอน',
            district: 'ฝาง',
            province: 'เชียงใหม่',
            zipcode: 50320,
        },
        {
            subDistrict: 'แม่สูน',
            district: 'ฝาง',
            province: 'เชียงใหม่',
            zipcode: 50110,
        },
        {
            subDistrict: 'โป่งน้ำร้อน',
            district: 'ฝาง',
            province: 'เชียงใหม่',
            zipcode: 50110,
        },
        {
            subDistrict: 'ทุ่งหลวง',
            district: 'พร้าว',
            province: 'เชียงใหม่',
            zipcode: 50190,
        },
        {
            subDistrict: 'น้ำแพร่',
            district: 'พร้าว',
            province: 'เชียงใหม่',
            zipcode: 50190,
        },
        {
            subDistrict: 'บ้านโป่ง',
            district: 'พร้าว',
            province: 'เชียงใหม่',
            zipcode: 50190,
        },
        {
            subDistrict: 'ป่าตุ้ม',
            district: 'พร้าว',
            province: 'เชียงใหม่',
            zipcode: 50190,
        },
        {
            subDistrict: 'ป่าไหน่',
            district: 'พร้าว',
            province: 'เชียงใหม่',
            zipcode: 50190,
        },
        {
            subDistrict: 'สันทราย',
            district: 'พร้าว',
            province: 'เชียงใหม่',
            zipcode: 50190,
        },
        {
            subDistrict: 'เขื่อนผาก',
            district: 'พร้าว',
            province: 'เชียงใหม่',
            zipcode: 50190,
        },
        {
            subDistrict: 'เวียง',
            district: 'พร้าว',
            province: 'เชียงใหม่',
            zipcode: 50190,
        },
        {
            subDistrict: 'แม่ปั๋ง',
            district: 'พร้าว',
            province: 'เชียงใหม่',
            zipcode: 50190,
        },
        {
            subDistrict: 'แม่แวน',
            district: 'พร้าว',
            province: 'เชียงใหม่',
            zipcode: 50190,
        },
        {
            subDistrict: 'โหล่งขอด',
            district: 'พร้าว',
            province: 'เชียงใหม่',
            zipcode: 50190,
        },
        {
            subDistrict: 'บ่อแก้ว',
            district: 'สะเมิง',
            province: 'เชียงใหม่',
            zipcode: 50250,
        },
        {
            subDistrict: 'ยั้งเมิน',
            district: 'สะเมิง',
            province: 'เชียงใหม่',
            zipcode: 50250,
        },
        {
            subDistrict: 'สะเมิงเหนือ',
            district: 'สะเมิง',
            province: 'เชียงใหม่',
            zipcode: 50250,
        },
        {
            subDistrict: 'สะเมิงใต้',
            district: 'สะเมิง',
            province: 'เชียงใหม่',
            zipcode: 50250,
        },
        {
            subDistrict: 'แม่สาบ',
            district: 'สะเมิง',
            province: 'เชียงใหม่',
            zipcode: 50250,
        },
        {
            subDistrict: 'ต้นเปา',
            district: 'สันกำแพง',
            province: 'เชียงใหม่',
            zipcode: 50130,
        },
        {
            subDistrict: 'ทรายมูล',
            district: 'สันกำแพง',
            province: 'เชียงใหม่',
            zipcode: 50130,
        },
        {
            subDistrict: 'บวกค้าง',
            district: 'สันกำแพง',
            province: 'เชียงใหม่',
            zipcode: 50130,
        },
        {
            subDistrict: 'ร้องวัวแดง',
            district: 'สันกำแพง',
            province: 'เชียงใหม่',
            zipcode: 50130,
        },
        {
            subDistrict: 'สันกลาง',
            district: 'สันกำแพง',
            province: 'เชียงใหม่',
            zipcode: 50130,
        },
        {
            subDistrict: 'สันกำแพง',
            district: 'สันกำแพง',
            province: 'เชียงใหม่',
            zipcode: 50130,
        },
        {
            subDistrict: 'ห้วยทราย',
            district: 'สันกำแพง',
            province: 'เชียงใหม่',
            zipcode: 50130,
        },
        {
            subDistrict: 'ออนใต้',
            district: 'สันกำแพง',
            province: 'เชียงใหม่',
            zipcode: 50130,
        },
        {
            subDistrict: 'แช่ช้าง',
            district: 'สันกำแพง',
            province: 'เชียงใหม่',
            zipcode: 50130,
        },
        {
            subDistrict: 'แม่ปูคา',
            district: 'สันกำแพง',
            province: 'เชียงใหม่',
            zipcode: 50130,
        },
        {
            subDistrict: 'ป่าไผ่',
            district: 'สันทราย',
            province: 'เชียงใหม่',
            zipcode: 50210,
        },
        {
            subDistrict: 'สันทรายน้อย',
            district: 'สันทราย',
            province: 'เชียงใหม่',
            zipcode: 50210,
        },
        {
            subDistrict: 'สันทรายหลวง',
            district: 'สันทราย',
            province: 'เชียงใหม่',
            zipcode: 50210,
        },
        {
            subDistrict: 'สันนาเม็ง',
            district: 'สันทราย',
            province: 'เชียงใหม่',
            zipcode: 50210,
        },
        {
            subDistrict: 'สันป่าเปา',
            district: 'สันทราย',
            province: 'เชียงใหม่',
            zipcode: 50210,
        },
        {
            subDistrict: 'สันพระเนตร',
            district: 'สันทราย',
            province: 'เชียงใหม่',
            zipcode: 50210,
        },
        {
            subDistrict: 'หนองจ๊อม',
            district: 'สันทราย',
            province: 'เชียงใหม่',
            zipcode: 50210,
        },
        {
            subDistrict: 'หนองหาร',
            district: 'สันทราย',
            province: 'เชียงใหม่',
            zipcode: 50290,
        },
        {
            subDistrict: 'หนองแหย่ง',
            district: 'สันทราย',
            province: 'เชียงใหม่',
            zipcode: 50210,
        },
        {
            subDistrict: 'เมืองเล็น',
            district: 'สันทราย',
            province: 'เชียงใหม่',
            zipcode: 50210,
        },
        {
            subDistrict: 'แม่แฝก',
            district: 'สันทราย',
            province: 'เชียงใหม่',
            zipcode: 50290,
        },
        {
            subDistrict: 'แม่แฝกใหม่',
            district: 'สันทราย',
            province: 'เชียงใหม่',
            zipcode: 50290,
        },
        {
            subDistrict: 'ทุ่งต้อม',
            district: 'สันป่าตอง',
            province: 'เชียงใหม่',
            zipcode: 50120,
        },
        {
            subDistrict: 'ทุ่งสะโตก',
            district: 'สันป่าตอง',
            province: 'เชียงใหม่',
            zipcode: 50120,
        },
        {
            subDistrict: 'ท่าวังพร้าว',
            district: 'สันป่าตอง',
            province: 'เชียงใหม่',
            zipcode: 50120,
        },
        {
            subDistrict: 'น้ำบ่อหลวง',
            district: 'สันป่าตอง',
            province: 'เชียงใหม่',
            zipcode: 50120,
        },
        {
            subDistrict: 'บ้านกลาง',
            district: 'สันป่าตอง',
            province: 'เชียงใหม่',
            zipcode: 50120,
        },
        {
            subDistrict: 'บ้านแม',
            district: 'สันป่าตอง',
            province: 'เชียงใหม่',
            zipcode: 50120,
        },
        {
            subDistrict: 'มะขามหลวง',
            district: 'สันป่าตอง',
            province: 'เชียงใหม่',
            zipcode: 50120,
        },
        {
            subDistrict: 'มะขุนหวาน',
            district: 'สันป่าตอง',
            province: 'เชียงใหม่',
            zipcode: 50120,
        },
        {
            subDistrict: 'ยุหว่า',
            district: 'สันป่าตอง',
            province: 'เชียงใหม่',
            zipcode: 50120,
        },
        {
            subDistrict: 'สันกลาง',
            district: 'สันป่าตอง',
            province: 'เชียงใหม่',
            zipcode: 50120,
        },
        {
            subDistrict: 'แม่ก๊า',
            district: 'สันป่าตอง',
            province: 'เชียงใหม่',
            zipcode: 50120,
        },
        {
            subDistrict: 'ขัวมุง',
            district: 'สารภี',
            province: 'เชียงใหม่',
            zipcode: 50140,
        },
        {
            subDistrict: 'ชมภู',
            district: 'สารภี',
            province: 'เชียงใหม่',
            zipcode: 50140,
        },
        {
            subDistrict: 'ดอนแก้ว',
            district: 'สารภี',
            province: 'เชียงใหม่',
            zipcode: 50140,
        },
        {
            subDistrict: 'ท่ากว้าง',
            district: 'สารภี',
            province: 'เชียงใหม่',
            zipcode: 50140,
        },
        {
            subDistrict: 'ท่าวังตาล',
            district: 'สารภี',
            province: 'เชียงใหม่',
            zipcode: 50140,
        },
        {
            subDistrict: 'ป่าบง',
            district: 'สารภี',
            province: 'เชียงใหม่',
            zipcode: 50140,
        },
        {
            subDistrict: 'ยางเนิ้ง',
            district: 'สารภี',
            province: 'เชียงใหม่',
            zipcode: 50140,
        },
        {
            subDistrict: 'สันทราย',
            district: 'สารภี',
            province: 'เชียงใหม่',
            zipcode: 50140,
        },
        {
            subDistrict: 'สารภี',
            district: 'สารภี',
            province: 'เชียงใหม่',
            zipcode: 50140,
        },
        {
            subDistrict: 'หนองผึ้ง',
            district: 'สารภี',
            province: 'เชียงใหม่',
            zipcode: 50140,
        },
        {
            subDistrict: 'หนองแฝก',
            district: 'สารภี',
            province: 'เชียงใหม่',
            zipcode: 50140,
        },
        {
            subDistrict: 'ไชยสถาน',
            district: 'สารภี',
            province: 'เชียงใหม่',
            zipcode: 50140,
        },
        {
            subDistrict: 'ขุนคง',
            district: 'หางดง',
            province: 'เชียงใหม่',
            zipcode: 50230,
        },
        {
            subDistrict: 'น้ำแพร่',
            district: 'หางดง',
            province: 'เชียงใหม่',
            zipcode: 50230,
        },
        {
            subDistrict: 'บ้านปง',
            district: 'หางดง',
            province: 'เชียงใหม่',
            zipcode: 50230,
        },
        {
            subDistrict: 'บ้านแหวน',
            district: 'หางดง',
            province: 'เชียงใหม่',
            zipcode: 50230,
        },
        {
            subDistrict: 'สบแม่ข่า',
            district: 'หางดง',
            province: 'เชียงใหม่',
            zipcode: 50230,
        },
        {
            subDistrict: 'สันผักหวาน',
            district: 'หางดง',
            province: 'เชียงใหม่',
            zipcode: 50230,
        },
        {
            subDistrict: 'หนองควาย',
            district: 'หางดง',
            province: 'เชียงใหม่',
            zipcode: 50230,
        },
        {
            subDistrict: 'หนองตอง',
            district: 'หางดง',
            province: 'เชียงใหม่',
            zipcode: 50340,
        },
        {
            subDistrict: 'หนองแก๋ว',
            district: 'หางดง',
            province: 'เชียงใหม่',
            zipcode: 50230,
        },
        {
            subDistrict: 'หางดง',
            district: 'หางดง',
            province: 'เชียงใหม่',
            zipcode: 50230,
        },
        {
            subDistrict: 'หารแก้ว',
            district: 'หางดง',
            province: 'เชียงใหม่',
            zipcode: 50230,
        },
        {
            subDistrict: 'นาเกียน',
            district: 'อมก๋อย',
            province: 'เชียงใหม่',
            zipcode: 50310,
        },
        {
            subDistrict: 'ม่อนจอง',
            district: 'อมก๋อย',
            province: 'เชียงใหม่',
            zipcode: 50310,
        },
        {
            subDistrict: 'ยางเปียง',
            district: 'อมก๋อย',
            province: 'เชียงใหม่',
            zipcode: 50310,
        },
        {
            subDistrict: 'สบโขง',
            district: 'อมก๋อย',
            province: 'เชียงใหม่',
            zipcode: 50310,
        },
        {
            subDistrict: 'อมก๋อย',
            district: 'อมก๋อย',
            province: 'เชียงใหม่',
            zipcode: 50310,
        },
        {
            subDistrict: 'แม่ตื่น',
            district: 'อมก๋อย',
            province: 'เชียงใหม่',
            zipcode: 50310,
        },
        {
            subDistrict: 'นาคอเรือ',
            district: 'ฮอด',
            province: 'เชียงใหม่',
            zipcode: 50240,
        },
        {
            subDistrict: 'บ่อสลี',
            district: 'ฮอด',
            province: 'เชียงใหม่',
            zipcode: 50240,
        },
        {
            subDistrict: 'บ่อหลวง',
            district: 'ฮอด',
            province: 'เชียงใหม่',
            zipcode: 50240,
        },
        {
            subDistrict: 'บ้านตาล',
            district: 'ฮอด',
            province: 'เชียงใหม่',
            zipcode: 50240,
        },
        {
            subDistrict: 'หางดง',
            district: 'ฮอด',
            province: 'เชียงใหม่',
            zipcode: 50240,
        },
        {
            subDistrict: 'ฮอด',
            district: 'ฮอด',
            province: 'เชียงใหม่',
            zipcode: 50240,
        },
        {
            subDistrict: 'ทุ่งข้าวพวง',
            district: 'เชียงดาว',
            province: 'เชียงใหม่',
            zipcode: 50170,
        },
        {
            subDistrict: 'ปิงโค้ง',
            district: 'เชียงดาว',
            province: 'เชียงใหม่',
            zipcode: 50170,
        },
        {
            subDistrict: 'เชียงดาว',
            district: 'เชียงดาว',
            province: 'เชียงใหม่',
            zipcode: 50170,
        },
        {
            subDistrict: 'เมืองคอง',
            district: 'เชียงดาว',
            province: 'เชียงใหม่',
            zipcode: 50170,
        },
        {
            subDistrict: 'เมืองงาย',
            district: 'เชียงดาว',
            province: 'เชียงใหม่',
            zipcode: 50170,
        },
        {
            subDistrict: 'เมืองนะ',
            district: 'เชียงดาว',
            province: 'เชียงใหม่',
            zipcode: 50170,
        },
        {
            subDistrict: 'แม่นะ',
            district: 'เชียงดาว',
            province: 'เชียงใหม่',
            zipcode: 50170,
        },
        {
            subDistrict: 'ช้างคลาน',
            district: 'เมืองเชียงใหม่',
            province: 'เชียงใหม่',
            zipcode: 50100,
        },
        {
            subDistrict: 'ช้างม่อย',
            district: 'เมืองเชียงใหม่',
            province: 'เชียงใหม่',
            zipcode: 50300,
        },
        {
            subDistrict: 'ช้างเผือก',
            district: 'เมืองเชียงใหม่',
            province: 'เชียงใหม่',
            zipcode: 50300,
        },
        {
            subDistrict: 'ท่าศาลา',
            district: 'เมืองเชียงใหม่',
            province: 'เชียงใหม่',
            zipcode: 50000,
        },
        {
            subDistrict: 'ป่าตัน',
            district: 'เมืองเชียงใหม่',
            province: 'เชียงใหม่',
            zipcode: 50300,
        },
        {
            subDistrict: 'ป่าแดด',
            district: 'เมืองเชียงใหม่',
            province: 'เชียงใหม่',
            zipcode: 50100,
        },
        {
            subDistrict: 'พระสิงห์',
            district: 'เมืองเชียงใหม่',
            province: 'เชียงใหม่',
            zipcode: 50200,
        },
        {
            subDistrict: 'ฟ้าฮ่าม',
            district: 'เมืองเชียงใหม่',
            province: 'เชียงใหม่',
            zipcode: 50000,
        },
        {
            subDistrict: 'วัดเกต',
            district: 'เมืองเชียงใหม่',
            province: 'เชียงใหม่',
            zipcode: 50000,
        },
        {
            subDistrict: 'ศรีภูมิ',
            district: 'เมืองเชียงใหม่',
            province: 'เชียงใหม่',
            zipcode: 50200,
        },
        {
            subDistrict: 'สันผีเสื้อ',
            district: 'เมืองเชียงใหม่',
            province: 'เชียงใหม่',
            zipcode: 50300,
        },
        {
            subDistrict: 'สุเทพ',
            district: 'เมืองเชียงใหม่',
            province: 'เชียงใหม่',
            zipcode: 50200,
        },
        {
            subDistrict: 'หนองป่าครั่ง',
            district: 'เมืองเชียงใหม่',
            province: 'เชียงใหม่',
            zipcode: 50000,
        },
        {
            subDistrict: 'หนองหอย',
            district: 'เมืองเชียงใหม่',
            province: 'เชียงใหม่',
            zipcode: 50000,
        },
        {
            subDistrict: 'หายยา',
            district: 'เมืองเชียงใหม่',
            province: 'เชียงใหม่',
            zipcode: 50100,
        },
        {
            subDistrict: 'แม่เหียะ',
            district: 'เมืองเชียงใหม่',
            province: 'เชียงใหม่',
            zipcode: 50100,
        },
        {
            subDistrict: 'เปียงหลวง',
            district: 'เวียงแหง',
            province: 'เชียงใหม่',
            zipcode: 50350,
        },
        {
            subDistrict: 'เมืองแหง',
            district: 'เวียงแหง',
            province: 'เชียงใหม่',
            zipcode: 50350,
        },
        {
            subDistrict: 'แสนไห',
            district: 'เวียงแหง',
            province: 'เชียงใหม่',
            zipcode: 50350,
        },
        {
            subDistrict: 'ขี้เหล็ก',
            district: 'แม่ริม',
            province: 'เชียงใหม่',
            zipcode: 50180,
        },
        {
            subDistrict: 'ดอนแก้ว',
            district: 'แม่ริม',
            province: 'เชียงใหม่',
            zipcode: 50180,
        },
        {
            subDistrict: 'ริมเหนือ',
            district: 'แม่ริม',
            province: 'เชียงใหม่',
            zipcode: 50180,
        },
        {
            subDistrict: 'ริมใต้',
            district: 'แม่ริม',
            province: 'เชียงใหม่',
            zipcode: 50180,
        },
        {
            subDistrict: 'สะลวง',
            district: 'แม่ริม',
            province: 'เชียงใหม่',
            zipcode: 50330,
        },
        {
            subDistrict: 'สันโป่ง',
            district: 'แม่ริม',
            province: 'เชียงใหม่',
            zipcode: 50180,
        },
        {
            subDistrict: 'ห้วยทราย',
            district: 'แม่ริม',
            province: 'เชียงใหม่',
            zipcode: 50180,
        },
        {
            subDistrict: 'เหมืองแก้ว',
            district: 'แม่ริม',
            province: 'เชียงใหม่',
            zipcode: 50180,
        },
        {
            subDistrict: 'แม่สา',
            district: 'แม่ริม',
            province: 'เชียงใหม่',
            zipcode: 50180,
        },
        {
            subDistrict: 'แม่แรม',
            district: 'แม่ริม',
            province: 'เชียงใหม่',
            zipcode: 50180,
        },
        {
            subDistrict: 'โป่งแยง',
            district: 'แม่ริม',
            province: 'เชียงใหม่',
            zipcode: 50180,
        },
        {
            subDistrict: 'ดอนเปา',
            district: 'แม่วาง',
            province: 'เชียงใหม่',
            zipcode: 50360,
        },
        {
            subDistrict: 'ทุ่งปี้',
            district: 'แม่วาง',
            province: 'เชียงใหม่',
            zipcode: 50360,
        },
        {
            subDistrict: 'ทุ่งรวงทอง',
            district: 'แม่วาง',
            province: 'เชียงใหม่',
            zipcode: 50360,
        },
        {
            subDistrict: 'บ้านกาด',
            district: 'แม่วาง',
            province: 'เชียงใหม่',
            zipcode: 50360,
        },
        {
            subDistrict: 'แม่วิน',
            district: 'แม่วาง',
            province: 'เชียงใหม่',
            zipcode: 50360,
        },
        {
            subDistrict: 'ทาเหนือ',
            district: 'แม่ออน',
            province: 'เชียงใหม่',
            zipcode: 50130,
        },
        {
            subDistrict: 'บ้านสหกรณ์',
            district: 'แม่ออน',
            province: 'เชียงใหม่',
            zipcode: 50130,
        },
        {
            subDistrict: 'ห้วยแก้ว',
            district: 'แม่ออน',
            province: 'เชียงใหม่',
            zipcode: 50130,
        },
        {
            subDistrict: 'ออนกลาง',
            district: 'แม่ออน',
            province: 'เชียงใหม่',
            zipcode: 50130,
        },
        {
            subDistrict: 'ออนเหนือ',
            district: 'แม่ออน',
            province: 'เชียงใหม่',
            zipcode: 50130,
        },
        {
            subDistrict: 'แม่ทา',
            district: 'แม่ออน',
            province: 'เชียงใหม่',
            zipcode: 50130,
        },
        {
            subDistrict: 'ท่าตอน',
            district: 'แม่อาย',
            province: 'เชียงใหม่',
            zipcode: 50280,
        },
        {
            subDistrict: 'บ้านหลวง',
            district: 'แม่อาย',
            province: 'เชียงใหม่',
            zipcode: 50280,
        },
        {
            subDistrict: 'มะลิกา',
            district: 'แม่อาย',
            province: 'เชียงใหม่',
            zipcode: 50280,
        },
        {
            subDistrict: 'สันต้นหมื้อ',
            district: 'แม่อาย',
            province: 'เชียงใหม่',
            zipcode: 50280,
        },
        {
            subDistrict: 'แม่นาวาง',
            district: 'แม่อาย',
            province: 'เชียงใหม่',
            zipcode: 50280,
        },
        {
            subDistrict: 'แม่สาว',
            district: 'แม่อาย',
            province: 'เชียงใหม่',
            zipcode: 50280,
        },
        {
            subDistrict: 'แม่อาย',
            district: 'แม่อาย',
            province: 'เชียงใหม่',
            zipcode: 50280,
        },
        {
            subDistrict: 'กองแขก',
            district: 'แม่แจ่ม',
            province: 'เชียงใหม่',
            zipcode: 50270,
        },
        {
            subDistrict: 'ช่างเคิ่ง',
            district: 'แม่แจ่ม',
            province: 'เชียงใหม่',
            zipcode: 50270,
        },
        {
            subDistrict: 'ท่าผา',
            district: 'แม่แจ่ม',
            province: 'เชียงใหม่',
            zipcode: 50270,
        },
        {
            subDistrict: 'บ้านทับ',
            district: 'แม่แจ่ม',
            province: 'เชียงใหม่',
            zipcode: 50270,
        },
        {
            subDistrict: 'ปางหินฝน',
            district: 'แม่แจ่ม',
            province: 'เชียงใหม่',
            zipcode: 50270,
        },
        {
            subDistrict: 'แม่นาจร',
            district: 'แม่แจ่ม',
            province: 'เชียงใหม่',
            zipcode: 50270,
        },
        {
            subDistrict: 'แม่ศึก',
            district: 'แม่แจ่ม',
            province: 'เชียงใหม่',
            zipcode: 50270,
        },
        {
            subDistrict: 'กื้ดช้าง',
            district: 'แม่แตง',
            province: 'เชียงใหม่',
            zipcode: 50150,
        },
        {
            subDistrict: 'ขี้เหล็ก',
            district: 'แม่แตง',
            province: 'เชียงใหม่',
            zipcode: 50150,
        },
        {
            subDistrict: 'ช่อแล',
            district: 'แม่แตง',
            province: 'เชียงใหม่',
            zipcode: 50150,
        },
        {
            subDistrict: 'บ้านช้าง',
            district: 'แม่แตง',
            province: 'เชียงใหม่',
            zipcode: 50150,
        },
        {
            subDistrict: 'บ้านเป้า',
            district: 'แม่แตง',
            province: 'เชียงใหม่',
            zipcode: 50150,
        },
        {
            subDistrict: 'ป่าแป๋',
            district: 'แม่แตง',
            province: 'เชียงใหม่',
            zipcode: 50150,
        },
        {
            subDistrict: 'สบเปิง',
            district: 'แม่แตง',
            province: 'เชียงใหม่',
            zipcode: 50150,
        },
        {
            subDistrict: 'สันป่ายาง',
            district: 'แม่แตง',
            province: 'เชียงใหม่',
            zipcode: 50330,
        },
        {
            subDistrict: 'สันมหาพน',
            district: 'แม่แตง',
            province: 'เชียงใหม่',
            zipcode: 50150,
        },
        {
            subDistrict: 'อินทขิล',
            district: 'แม่แตง',
            province: 'เชียงใหม่',
            zipcode: 50150,
        },
        {
            subDistrict: 'เมืองก๋าย',
            district: 'แม่แตง',
            province: 'เชียงใหม่',
            zipcode: 50150,
        },
        {
            subDistrict: 'แม่หอพระ',
            district: 'แม่แตง',
            province: 'เชียงใหม่',
            zipcode: 50150,
        },
        {
            subDistrict: 'แม่แตง',
            district: 'แม่แตง',
            province: 'เชียงใหม่',
            zipcode: 50150,
        },
        {
            subDistrict: 'ปงตำ',
            district: 'ไชยปราการ',
            province: 'เชียงใหม่',
            zipcode: 50320,
        },
        {
            subDistrict: 'ศรีดงเย็น',
            district: 'ไชยปราการ',
            province: 'เชียงใหม่',
            zipcode: 50320,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'ไชยปราการ',
            province: 'เชียงใหม่',
            zipcode: 50320,
        },
        {
            subDistrict: 'แม่ทะลบ',
            district: 'ไชยปราการ',
            province: 'เชียงใหม่',
            zipcode: 50320,
        },
        {
            subDistrict: 'ชะอำ',
            district: 'ชะอำ',
            province: 'เพชรบุรี',
            zipcode: 76120,
        },
        {
            subDistrict: 'ดอนขุนห้วย',
            district: 'ชะอำ',
            province: 'เพชรบุรี',
            zipcode: 76120,
        },
        {
            subDistrict: 'นายาง',
            district: 'ชะอำ',
            province: 'เพชรบุรี',
            zipcode: 76120,
        },
        {
            subDistrict: 'บางเก่า',
            district: 'ชะอำ',
            province: 'เพชรบุรี',
            zipcode: 76120,
        },
        {
            subDistrict: 'สามพระยา',
            district: 'ชะอำ',
            province: 'เพชรบุรี',
            zipcode: 76120,
        },
        {
            subDistrict: 'หนองศาลา',
            district: 'ชะอำ',
            province: 'เพชรบุรี',
            zipcode: 76120,
        },
        {
            subDistrict: 'ห้วยทรายเหนือ',
            district: 'ชะอำ',
            province: 'เพชรบุรี',
            zipcode: 76120,
        },
        {
            subDistrict: 'เขาใหญ่',
            district: 'ชะอำ',
            province: 'เพชรบุรี',
            zipcode: 76120,
        },
        {
            subDistrict: 'ไร่ใหม่พัฒนา',
            district: 'ชะอำ',
            province: 'เพชรบุรี',
            zipcode: 76120,
        },
        {
            subDistrict: 'กลัดหลวง',
            district: 'ท่ายาง',
            province: 'เพชรบุรี',
            zipcode: 76130,
        },
        {
            subDistrict: 'ท่าคอย',
            district: 'ท่ายาง',
            province: 'เพชรบุรี',
            zipcode: 76130,
        },
        {
            subDistrict: 'ท่ายาง',
            district: 'ท่ายาง',
            province: 'เพชรบุรี',
            zipcode: 76130,
        },
        {
            subDistrict: 'ท่าแลง',
            district: 'ท่ายาง',
            province: 'เพชรบุรี',
            zipcode: 76130,
        },
        {
            subDistrict: 'ท่าไม้รวก',
            district: 'ท่ายาง',
            province: 'เพชรบุรี',
            zipcode: 76130,
        },
        {
            subDistrict: 'บ้านในดง',
            district: 'ท่ายาง',
            province: 'เพชรบุรี',
            zipcode: 76130,
        },
        {
            subDistrict: 'ปึกเตียน',
            district: 'ท่ายาง',
            province: 'เพชรบุรี',
            zipcode: 76130,
        },
        {
            subDistrict: 'มาบปลาเค้า',
            district: 'ท่ายาง',
            province: 'เพชรบุรี',
            zipcode: 76130,
        },
        {
            subDistrict: 'ยางหย่อง',
            district: 'ท่ายาง',
            province: 'เพชรบุรี',
            zipcode: 76130,
        },
        {
            subDistrict: 'วังไคร้',
            district: 'ท่ายาง',
            province: 'เพชรบุรี',
            zipcode: 76130,
        },
        {
            subDistrict: 'หนองจอก',
            district: 'ท่ายาง',
            province: 'เพชรบุรี',
            zipcode: 76130,
        },
        {
            subDistrict: 'เขากระปุก',
            district: 'ท่ายาง',
            province: 'เพชรบุรี',
            zipcode: 76130,
        },
        {
            subDistrict: 'ตำหรุ',
            district: 'บ้านลาด',
            province: 'เพชรบุรี',
            zipcode: 76150,
        },
        {
            subDistrict: 'ถ้ำรงค์',
            district: 'บ้านลาด',
            province: 'เพชรบุรี',
            zipcode: 76150,
        },
        {
            subDistrict: 'ท่าช้าง',
            district: 'บ้านลาด',
            province: 'เพชรบุรี',
            zipcode: 76150,
        },
        {
            subDistrict: 'ท่าเสน',
            district: 'บ้านลาด',
            province: 'เพชรบุรี',
            zipcode: 76150,
        },
        {
            subDistrict: 'บ้านทาน',
            district: 'บ้านลาด',
            province: 'เพชรบุรี',
            zipcode: 76150,
        },
        {
            subDistrict: 'บ้านลาด',
            district: 'บ้านลาด',
            province: 'เพชรบุรี',
            zipcode: 76150,
        },
        {
            subDistrict: 'บ้านหาด',
            district: 'บ้านลาด',
            province: 'เพชรบุรี',
            zipcode: 76150,
        },
        {
            subDistrict: 'ลาดโพธิ์',
            district: 'บ้านลาด',
            province: 'เพชรบุรี',
            zipcode: 76150,
        },
        {
            subDistrict: 'สมอพลือ',
            district: 'บ้านลาด',
            province: 'เพชรบุรี',
            zipcode: 76150,
        },
        {
            subDistrict: 'สะพานไกร',
            district: 'บ้านลาด',
            province: 'เพชรบุรี',
            zipcode: 76150,
        },
        {
            subDistrict: 'หนองกระเจ็ด',
            district: 'บ้านลาด',
            province: 'เพชรบุรี',
            zipcode: 76150,
        },
        {
            subDistrict: 'หนองกะปุ',
            district: 'บ้านลาด',
            province: 'เพชรบุรี',
            zipcode: 76150,
        },
        {
            subDistrict: 'ห้วยข้อง',
            district: 'บ้านลาด',
            province: 'เพชรบุรี',
            zipcode: 76150,
        },
        {
            subDistrict: 'ห้วยลึก',
            district: 'บ้านลาด',
            province: 'เพชรบุรี',
            zipcode: 76150,
        },
        {
            subDistrict: 'โรงเข้',
            district: 'บ้านลาด',
            province: 'เพชรบุรี',
            zipcode: 76150,
        },
        {
            subDistrict: 'ไร่มะขาม',
            district: 'บ้านลาด',
            province: 'เพชรบุรี',
            zipcode: 76150,
        },
        {
            subDistrict: 'ไร่สะท้อน',
            district: 'บ้านลาด',
            province: 'เพชรบุรี',
            zipcode: 76150,
        },
        {
            subDistrict: 'ไร่โคก',
            district: 'บ้านลาด',
            province: 'เพชรบุรี',
            zipcode: 76150,
        },
        {
            subDistrict: 'ท่าแร้ง',
            district: 'บ้านแหลม',
            province: 'เพชรบุรี',
            zipcode: 76110,
        },
        {
            subDistrict: 'ท่าแร้งออก',
            district: 'บ้านแหลม',
            province: 'เพชรบุรี',
            zipcode: 76110,
        },
        {
            subDistrict: 'บางขุนไทร',
            district: 'บ้านแหลม',
            province: 'เพชรบุรี',
            zipcode: 76110,
        },
        {
            subDistrict: 'บางครก',
            district: 'บ้านแหลม',
            province: 'เพชรบุรี',
            zipcode: 76110,
        },
        {
            subDistrict: 'บางตะบูน',
            district: 'บ้านแหลม',
            province: 'เพชรบุรี',
            zipcode: 76110,
        },
        {
            subDistrict: 'บางตะบูนออก',
            district: 'บ้านแหลม',
            province: 'เพชรบุรี',
            zipcode: 76110,
        },
        {
            subDistrict: 'บางแก้ว',
            district: 'บ้านแหลม',
            province: 'เพชรบุรี',
            zipcode: 76110,
        },
        {
            subDistrict: 'บ้านแหลม',
            district: 'บ้านแหลม',
            province: 'เพชรบุรี',
            zipcode: 76110,
        },
        {
            subDistrict: 'ปากทะเล',
            district: 'บ้านแหลม',
            province: 'เพชรบุรี',
            zipcode: 76110,
        },
        {
            subDistrict: 'แหลมผักเบี้ย',
            district: 'บ้านแหลม',
            province: 'เพชรบุรี',
            zipcode: 76100,
        },
        {
            subDistrict: 'ท่าตะคร้อ',
            district: 'หนองหญ้าปล้อง',
            province: 'เพชรบุรี',
            zipcode: 76160,
        },
        {
            subDistrict: 'ยางน้ำกลัดเหนือ',
            district: 'หนองหญ้าปล้อง',
            province: 'เพชรบุรี',
            zipcode: 76160,
        },
        {
            subDistrict: 'ยางน้ำกลัดใต้',
            district: 'หนองหญ้าปล้อง',
            province: 'เพชรบุรี',
            zipcode: 76160,
        },
        {
            subDistrict: 'หนองหญ้าปล้อง',
            district: 'หนองหญ้าปล้อง',
            province: 'เพชรบุรี',
            zipcode: 76160,
        },
        {
            subDistrict: 'ทับคาง',
            district: 'เขาย้อย',
            province: 'เพชรบุรี',
            zipcode: 76140,
        },
        {
            subDistrict: 'บางเค็ม',
            district: 'เขาย้อย',
            province: 'เพชรบุรี',
            zipcode: 76140,
        },
        {
            subDistrict: 'สระพัง',
            district: 'เขาย้อย',
            province: 'เพชรบุรี',
            zipcode: 76140,
        },
        {
            subDistrict: 'หนองชุมพล',
            district: 'เขาย้อย',
            province: 'เพชรบุรี',
            zipcode: 76140,
        },
        {
            subDistrict: 'หนองชุมพลเหนือ',
            district: 'เขาย้อย',
            province: 'เพชรบุรี',
            zipcode: 76140,
        },
        {
            subDistrict: 'หนองปรง',
            district: 'เขาย้อย',
            province: 'เพชรบุรี',
            zipcode: 76140,
        },
        {
            subDistrict: 'หนองปลาไหล',
            district: 'เขาย้อย',
            province: 'เพชรบุรี',
            zipcode: 76140,
        },
        {
            subDistrict: 'ห้วยท่าช้าง',
            district: 'เขาย้อย',
            province: 'เพชรบุรี',
            zipcode: 76140,
        },
        {
            subDistrict: 'ห้วยโรง',
            district: 'เขาย้อย',
            province: 'เพชรบุรี',
            zipcode: 76140,
        },
        {
            subDistrict: 'เขาย้อย',
            district: 'เขาย้อย',
            province: 'เพชรบุรี',
            zipcode: 76140,
        },
        {
            subDistrict: 'คลองกระแชง',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'ช่องสะแก',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'ดอนยาง',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'ต้นมะพร้าว',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'ต้นมะม่วง',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'ท่าราบ',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'ธงชัย',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'นาพันสาม',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'นาวุ้ง',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'บางจาก',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'บางจาน',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'บ้านกุ่ม',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'บ้านหม้อ',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'วังตะโก',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'สำมะโรง',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'หนองขนาน',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'หนองพลับ',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'หนองโสน',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'หัวสะพาน',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'หาดเจ้าสำราญ',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76100,
        },
        {
            subDistrict: 'เวียงคอย',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'โพพระ',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'โพไร่หวาน',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'ไร่ส้ม',
            district: 'เมืองเพชรบุรี',
            province: 'เพชรบุรี',
            zipcode: 76000,
        },
        {
            subDistrict: 'ป่าเด็ง',
            district: 'แก่งกระจาน',
            province: 'เพชรบุรี',
            zipcode: 76170,
        },
        {
            subDistrict: 'พุสวรรค์',
            district: 'แก่งกระจาน',
            province: 'เพชรบุรี',
            zipcode: 76170,
        },
        {
            subDistrict: 'วังจันทร์',
            district: 'แก่งกระจาน',
            province: 'เพชรบุรี',
            zipcode: 76170,
        },
        {
            subDistrict: 'สองพี่น้อง',
            district: 'แก่งกระจาน',
            province: 'เพชรบุรี',
            zipcode: 76170,
        },
        {
            subDistrict: 'ห้วยแม่เพรียง',
            district: 'แก่งกระจาน',
            province: 'เพชรบุรี',
            zipcode: 76170,
        },
        {
            subDistrict: 'แก่งกระจาน',
            district: 'แก่งกระจาน',
            province: 'เพชรบุรี',
            zipcode: 76170,
        },
        {
            subDistrict: 'ชนแดน',
            district: 'ชนแดน',
            province: 'เพชรบูรณ์',
            zipcode: 67150,
        },
        {
            subDistrict: 'ซับพุทรา',
            district: 'ชนแดน',
            province: 'เพชรบูรณ์',
            zipcode: 67150,
        },
        {
            subDistrict: 'ดงขุย',
            district: 'ชนแดน',
            province: 'เพชรบูรณ์',
            zipcode: 67190,
        },
        {
            subDistrict: 'ตะกุดไร',
            district: 'ชนแดน',
            province: 'เพชรบูรณ์',
            zipcode: 67190,
        },
        {
            subDistrict: 'ท่าข้าม',
            district: 'ชนแดน',
            province: 'เพชรบูรณ์',
            zipcode: 67150,
        },
        {
            subDistrict: 'บ้านกล้วย',
            district: 'ชนแดน',
            province: 'เพชรบูรณ์',
            zipcode: 67190,
        },
        {
            subDistrict: 'พุทธบาท',
            district: 'ชนแดน',
            province: 'เพชรบูรณ์',
            zipcode: 67150,
        },
        {
            subDistrict: 'ลาดแค',
            district: 'ชนแดน',
            province: 'เพชรบูรณ์',
            zipcode: 67150,
        },
        {
            subDistrict: 'ศาลาลาย',
            district: 'ชนแดน',
            province: 'เพชรบูรณ์',
            zipcode: 67150,
        },
        {
            subDistrict: 'น้ำหนาว',
            district: 'น้ำหนาว',
            province: 'เพชรบูรณ์',
            zipcode: 67260,
        },
        {
            subDistrict: 'วังกวาง',
            district: 'น้ำหนาว',
            province: 'เพชรบูรณ์',
            zipcode: 67260,
        },
        {
            subDistrict: 'หลักด่าน',
            district: 'น้ำหนาว',
            province: 'เพชรบูรณ์',
            zipcode: 67260,
        },
        {
            subDistrict: 'โคกมน',
            district: 'น้ำหนาว',
            province: 'เพชรบูรณ์',
            zipcode: 67260,
        },
        {
            subDistrict: 'กันจุ',
            district: 'บึงสามพัน',
            province: 'เพชรบูรณ์',
            zipcode: 67160,
        },
        {
            subDistrict: 'ซับสมอทอด',
            district: 'บึงสามพัน',
            province: 'เพชรบูรณ์',
            zipcode: 67160,
        },
        {
            subDistrict: 'ซับไม้แดง',
            district: 'บึงสามพัน',
            province: 'เพชรบูรณ์',
            zipcode: 67160,
        },
        {
            subDistrict: 'บึงสามพัน',
            district: 'บึงสามพัน',
            province: 'เพชรบูรณ์',
            zipcode: 67160,
        },
        {
            subDistrict: 'พญาวัง',
            district: 'บึงสามพัน',
            province: 'เพชรบูรณ์',
            zipcode: 67160,
        },
        {
            subDistrict: 'วังพิกุล',
            district: 'บึงสามพัน',
            province: 'เพชรบูรณ์',
            zipcode: 67230,
        },
        {
            subDistrict: 'ศรีมงคล',
            district: 'บึงสามพัน',
            province: 'เพชรบูรณ์',
            zipcode: 67160,
        },
        {
            subDistrict: 'สระแก้ว',
            district: 'บึงสามพัน',
            province: 'เพชรบูรณ์',
            zipcode: 67160,
        },
        {
            subDistrict: 'หนองแจง',
            district: 'บึงสามพัน',
            province: 'เพชรบูรณ์',
            zipcode: 67160,
        },
        {
            subDistrict: 'ซับเปิบ',
            district: 'วังโป่ง',
            province: 'เพชรบูรณ์',
            zipcode: 67240,
        },
        {
            subDistrict: 'ท้ายดง',
            district: 'วังโป่ง',
            province: 'เพชรบูรณ์',
            zipcode: 67240,
        },
        {
            subDistrict: 'วังศาล',
            district: 'วังโป่ง',
            province: 'เพชรบูรณ์',
            zipcode: 67240,
        },
        {
            subDistrict: 'วังหิน',
            district: 'วังโป่ง',
            province: 'เพชรบูรณ์',
            zipcode: 67240,
        },
        {
            subDistrict: 'วังโป่ง',
            district: 'วังโป่ง',
            province: 'เพชรบูรณ์',
            zipcode: 67240,
        },
        {
            subDistrict: 'ซับน้อย',
            district: 'วิเชียรบุรี',
            province: 'เพชรบูรณ์',
            zipcode: 67180,
        },
        {
            subDistrict: 'ซับสมบูรณ์',
            district: 'วิเชียรบุรี',
            province: 'เพชรบูรณ์',
            zipcode: 67180,
        },
        {
            subDistrict: 'ท่าโรง',
            district: 'วิเชียรบุรี',
            province: 'เพชรบูรณ์',
            zipcode: 67130,
        },
        {
            subDistrict: 'น้ำร้อน',
            district: 'วิเชียรบุรี',
            province: 'เพชรบูรณ์',
            zipcode: 67130,
        },
        {
            subDistrict: 'บึงกระจับ',
            district: 'วิเชียรบุรี',
            province: 'เพชรบูรณ์',
            zipcode: 67130,
        },
        {
            subDistrict: 'บ่อรัง',
            district: 'วิเชียรบุรี',
            province: 'เพชรบูรณ์',
            zipcode: 67130,
        },
        {
            subDistrict: 'พุขาม',
            district: 'วิเชียรบุรี',
            province: 'เพชรบูรณ์',
            zipcode: 67180,
        },
        {
            subDistrict: 'พุเตย',
            district: 'วิเชียรบุรี',
            province: 'เพชรบูรณ์',
            zipcode: 67180,
        },
        {
            subDistrict: 'ภูน้ำหยด',
            district: 'วิเชียรบุรี',
            province: 'เพชรบูรณ์',
            zipcode: 67180,
        },
        {
            subDistrict: 'ยางสาว',
            district: 'วิเชียรบุรี',
            province: 'เพชรบูรณ์',
            zipcode: 67130,
        },
        {
            subDistrict: 'วังใหญ่',
            district: 'วิเชียรบุรี',
            province: 'เพชรบูรณ์',
            zipcode: 67180,
        },
        {
            subDistrict: 'สระประดู่',
            district: 'วิเชียรบุรี',
            province: 'เพชรบูรณ์',
            zipcode: 67130,
        },
        {
            subDistrict: 'สามแยก',
            district: 'วิเชียรบุรี',
            province: 'เพชรบูรณ์',
            zipcode: 67130,
        },
        {
            subDistrict: 'โคกปรง',
            district: 'วิเชียรบุรี',
            province: 'เพชรบูรณ์',
            zipcode: 67130,
        },
        {
            subDistrict: 'คลองกระจัง',
            district: 'ศรีเทพ',
            province: 'เพชรบูรณ์',
            zipcode: 67170,
        },
        {
            subDistrict: 'นาสนุ่น',
            district: 'ศรีเทพ',
            province: 'เพชรบูรณ์',
            zipcode: 67170,
        },
        {
            subDistrict: 'ประดู่งาม',
            district: 'ศรีเทพ',
            province: 'เพชรบูรณ์',
            zipcode: 67170,
        },
        {
            subDistrict: 'ศรีเทพ',
            district: 'ศรีเทพ',
            province: 'เพชรบูรณ์',
            zipcode: 67170,
        },
        {
            subDistrict: 'สระกรวด',
            district: 'ศรีเทพ',
            province: 'เพชรบูรณ์',
            zipcode: 67170,
        },
        {
            subDistrict: 'หนองย่างทอย',
            district: 'ศรีเทพ',
            province: 'เพชรบูรณ์',
            zipcode: 67170,
        },
        {
            subDistrict: 'โคกสะอาด',
            district: 'ศรีเทพ',
            province: 'เพชรบูรณ์',
            zipcode: 67170,
        },
        {
            subDistrict: 'กองทูล',
            district: 'หนองไผ่',
            province: 'เพชรบูรณ์',
            zipcode: 67140,
        },
        {
            subDistrict: 'ท่าด้วง',
            district: 'หนองไผ่',
            province: 'เพชรบูรณ์',
            zipcode: 67140,
        },
        {
            subDistrict: 'ท่าแดง',
            district: 'หนองไผ่',
            province: 'เพชรบูรณ์',
            zipcode: 67140,
        },
        {
            subDistrict: 'นาเฉลียง',
            district: 'หนองไผ่',
            province: 'เพชรบูรณ์',
            zipcode: 67220,
        },
        {
            subDistrict: 'บัววัฒนา',
            district: 'หนองไผ่',
            province: 'เพชรบูรณ์',
            zipcode: 67140,
        },
        {
            subDistrict: 'บ่อไทย',
            district: 'หนองไผ่',
            province: 'เพชรบูรณ์',
            zipcode: 67140,
        },
        {
            subDistrict: 'บ้านโภชน์',
            district: 'หนองไผ่',
            province: 'เพชรบูรณ์',
            zipcode: 67140,
        },
        {
            subDistrict: 'ยางงาม',
            district: 'หนองไผ่',
            province: 'เพชรบูรณ์',
            zipcode: 67220,
        },
        {
            subDistrict: 'วังท่าดี',
            district: 'หนองไผ่',
            province: 'เพชรบูรณ์',
            zipcode: 67140,
        },
        {
            subDistrict: 'วังโบสถ์',
            district: 'หนองไผ่',
            province: 'เพชรบูรณ์',
            zipcode: 67140,
        },
        {
            subDistrict: 'หนองไผ่',
            district: 'หนองไผ่',
            province: 'เพชรบูรณ์',
            zipcode: 67140,
        },
        {
            subDistrict: 'ห้วยโป่ง',
            district: 'หนองไผ่',
            province: 'เพชรบูรณ์',
            zipcode: 67220,
        },
        {
            subDistrict: 'เพชรละคร',
            district: 'หนองไผ่',
            province: 'เพชรบูรณ์',
            zipcode: 67140,
        },
        {
            subDistrict: 'ช้างตะลูด',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'ตาลเดี่ยว',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'ท่าอิบุญ',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'น้ำก้อ',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'น้ำชุน',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'น้ำเฮี้ย',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'บุ่งคล้า',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'บุ่งน้ำเต้า',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'บ้านกลาง',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'บ้านติ้ว',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'บ้านหวาย',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'บ้านโสก',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'บ้านไร่',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'ปากช่อง',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'ปากดุก',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'ฝายนาแซง',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'ลานบ่า',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'วัดป่า',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'สักหลง',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'หนองสว่าง',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'หนองไขว่',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'หล่มสัก',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'ห้วยไร่',
            district: 'หล่มสัก',
            province: 'เพชรบูรณ์',
            zipcode: 67110,
        },
        {
            subDistrict: 'ตาดกลอย',
            district: 'หล่มเก่า',
            province: 'เพชรบูรณ์',
            zipcode: 67120,
        },
        {
            subDistrict: 'นาซำ',
            district: 'หล่มเก่า',
            province: 'เพชรบูรณ์',
            zipcode: 67120,
        },
        {
            subDistrict: 'นาเกาะ',
            district: 'หล่มเก่า',
            province: 'เพชรบูรณ์',
            zipcode: 67120,
        },
        {
            subDistrict: 'นาแซง',
            district: 'หล่มเก่า',
            province: 'เพชรบูรณ์',
            zipcode: 67120,
        },
        {
            subDistrict: 'บ้านเนิน',
            district: 'หล่มเก่า',
            province: 'เพชรบูรณ์',
            zipcode: 67120,
        },
        {
            subDistrict: 'วังบาล',
            district: 'หล่มเก่า',
            province: 'เพชรบูรณ์',
            zipcode: 67120,
        },
        {
            subDistrict: 'ศิลา',
            district: 'หล่มเก่า',
            province: 'เพชรบูรณ์',
            zipcode: 67120,
        },
        {
            subDistrict: 'หล่มเก่า',
            district: 'หล่มเก่า',
            province: 'เพชรบูรณ์',
            zipcode: 67120,
        },
        {
            subDistrict: 'หินฮาว',
            district: 'หล่มเก่า',
            province: 'เพชรบูรณ์',
            zipcode: 67120,
        },
        {
            subDistrict: 'ทุ่งสมอ',
            district: 'เขาค้อ',
            province: 'เพชรบูรณ์',
            zipcode: 67270,
        },
        {
            subDistrict: 'ริมสีม่วง',
            district: 'เขาค้อ',
            province: 'เพชรบูรณ์',
            zipcode: 67270,
        },
        {
            subDistrict: 'สะเดาะพง',
            district: 'เขาค้อ',
            province: 'เพชรบูรณ์',
            zipcode: 67270,
        },
        {
            subDistrict: 'หนองแม่นา',
            district: 'เขาค้อ',
            province: 'เพชรบูรณ์',
            zipcode: 67270,
        },
        {
            subDistrict: 'เขาค้อ',
            district: 'เขาค้อ',
            province: 'เพชรบูรณ์',
            zipcode: 67270,
        },
        {
            subDistrict: 'เข็กน้อย',
            district: 'เขาค้อ',
            province: 'เพชรบูรณ์',
            zipcode: 67280,
        },
        {
            subDistrict: 'แคมป์สน',
            district: 'เขาค้อ',
            province: 'เพชรบูรณ์',
            zipcode: 67280,
        },
        {
            subDistrict: 'ชอนไพร',
            district: 'เมืองเพชรบูรณ์',
            province: 'เพชรบูรณ์',
            zipcode: 67000,
        },
        {
            subDistrict: 'ดงมูลเหล็ก',
            district: 'เมืองเพชรบูรณ์',
            province: 'เพชรบูรณ์',
            zipcode: 67000,
        },
        {
            subDistrict: 'ตะเบาะ',
            district: 'เมืองเพชรบูรณ์',
            province: 'เพชรบูรณ์',
            zipcode: 67000,
        },
        {
            subDistrict: 'ท่าพล',
            district: 'เมืองเพชรบูรณ์',
            province: 'เพชรบูรณ์',
            zipcode: 67250,
        },
        {
            subDistrict: 'นางั่ว',
            district: 'เมืองเพชรบูรณ์',
            province: 'เพชรบูรณ์',
            zipcode: 67000,
        },
        {
            subDistrict: 'นาป่า',
            district: 'เมืองเพชรบูรณ์',
            province: 'เพชรบูรณ์',
            zipcode: 67000,
        },
        {
            subDistrict: 'นายม',
            district: 'เมืองเพชรบูรณ์',
            province: 'เพชรบูรณ์',
            zipcode: 67210,
        },
        {
            subDistrict: 'น้ำร้อน',
            district: 'เมืองเพชรบูรณ์',
            province: 'เพชรบูรณ์',
            zipcode: 67000,
        },
        {
            subDistrict: 'บ้านโคก',
            district: 'เมืองเพชรบูรณ์',
            province: 'เพชรบูรณ์',
            zipcode: 67000,
        },
        {
            subDistrict: 'บ้านโตก',
            district: 'เมืองเพชรบูรณ์',
            province: 'เพชรบูรณ์',
            zipcode: 67000,
        },
        {
            subDistrict: 'ป่าเลา',
            district: 'เมืองเพชรบูรณ์',
            province: 'เพชรบูรณ์',
            zipcode: 67000,
        },
        {
            subDistrict: 'ระวิง',
            district: 'เมืองเพชรบูรณ์',
            province: 'เพชรบูรณ์',
            zipcode: 67210,
        },
        {
            subDistrict: 'วังชมภู',
            district: 'เมืองเพชรบูรณ์',
            province: 'เพชรบูรณ์',
            zipcode: 67210,
        },
        {
            subDistrict: 'สะเดียง',
            district: 'เมืองเพชรบูรณ์',
            province: 'เพชรบูรณ์',
            zipcode: 67000,
        },
        {
            subDistrict: 'ห้วยสะแก',
            district: 'เมืองเพชรบูรณ์',
            province: 'เพชรบูรณ์',
            zipcode: 67210,
        },
        {
            subDistrict: 'ห้วยใหญ่',
            district: 'เมืองเพชรบูรณ์',
            province: 'เพชรบูรณ์',
            zipcode: 67000,
        },
        {
            subDistrict: 'ในเมือง',
            district: 'เมืองเพชรบูรณ์',
            province: 'เพชรบูรณ์',
            zipcode: 67000,
        },
        {
            subDistrict: 'กกสะทอน',
            district: 'ด่านซ้าย',
            province: 'เลย',
            zipcode: 42120,
        },
        {
            subDistrict: 'ด่านซ้าย',
            district: 'ด่านซ้าย',
            province: 'เลย',
            zipcode: 42120,
        },
        {
            subDistrict: 'นาดี',
            district: 'ด่านซ้าย',
            province: 'เลย',
            zipcode: 42120,
        },
        {
            subDistrict: 'นาหอ',
            district: 'ด่านซ้าย',
            province: 'เลย',
            zipcode: 42120,
        },
        {
            subDistrict: 'ปากหมัน',
            district: 'ด่านซ้าย',
            province: 'เลย',
            zipcode: 42120,
        },
        {
            subDistrict: 'วังยาว',
            district: 'ด่านซ้าย',
            province: 'เลย',
            zipcode: 42120,
        },
        {
            subDistrict: 'อิปุ่ม',
            district: 'ด่านซ้าย',
            province: 'เลย',
            zipcode: 42120,
        },
        {
            subDistrict: 'โคกงาม',
            district: 'ด่านซ้าย',
            province: 'เลย',
            zipcode: 42120,
        },
        {
            subDistrict: 'โป่ง',
            district: 'ด่านซ้าย',
            province: 'เลย',
            zipcode: 42120,
        },
        {
            subDistrict: 'โพนสูง',
            district: 'ด่านซ้าย',
            province: 'เลย',
            zipcode: 42120,
        },
        {
            subDistrict: 'ท่าลี่',
            district: 'ท่าลี่',
            province: 'เลย',
            zipcode: 42140,
        },
        {
            subDistrict: 'น้ำทูน',
            district: 'ท่าลี่',
            province: 'เลย',
            zipcode: 42140,
        },
        {
            subDistrict: 'น้ำแคม',
            district: 'ท่าลี่',
            province: 'เลย',
            zipcode: 42140,
        },
        {
            subDistrict: 'หนองผือ',
            district: 'ท่าลี่',
            province: 'เลย',
            zipcode: 42140,
        },
        {
            subDistrict: 'อาฮี',
            district: 'ท่าลี่',
            province: 'เลย',
            zipcode: 42140,
        },
        {
            subDistrict: 'โคกใหญ่',
            district: 'ท่าลี่',
            province: 'เลย',
            zipcode: 42140,
        },
        {
            subDistrict: 'ท่าสวรรค์',
            district: 'นาด้วง',
            province: 'เลย',
            zipcode: 42210,
        },
        {
            subDistrict: 'ท่าสะอาด',
            district: 'นาด้วง',
            province: 'เลย',
            zipcode: 42210,
        },
        {
            subDistrict: 'นาดอกคำ',
            district: 'นาด้วง',
            province: 'เลย',
            zipcode: 42210,
        },
        {
            subDistrict: 'นาด้วง',
            district: 'นาด้วง',
            province: 'เลย',
            zipcode: 42210,
        },
        {
            subDistrict: 'นาพึง',
            district: 'นาแห้ว',
            province: 'เลย',
            zipcode: 42170,
        },
        {
            subDistrict: 'นามาลา',
            district: 'นาแห้ว',
            province: 'เลย',
            zipcode: 42170,
        },
        {
            subDistrict: 'นาแห้ว',
            district: 'นาแห้ว',
            province: 'เลย',
            zipcode: 42170,
        },
        {
            subDistrict: 'เหล่ากอหก',
            district: 'นาแห้ว',
            province: 'เลย',
            zipcode: 42170,
        },
        {
            subDistrict: 'แสงภา',
            district: 'นาแห้ว',
            province: 'เลย',
            zipcode: 42170,
        },
        {
            subDistrict: 'ชมเจริญ',
            district: 'ปากชม',
            province: 'เลย',
            zipcode: 42150,
        },
        {
            subDistrict: 'ปากชม',
            district: 'ปากชม',
            province: 'เลย',
            zipcode: 42150,
        },
        {
            subDistrict: 'หาดคัมภีร์',
            district: 'ปากชม',
            province: 'เลย',
            zipcode: 42150,
        },
        {
            subDistrict: 'ห้วยบ่อซืน',
            district: 'ปากชม',
            province: 'เลย',
            zipcode: 42150,
        },
        {
            subDistrict: 'ห้วยพิชัย',
            district: 'ปากชม',
            province: 'เลย',
            zipcode: 42150,
        },
        {
            subDistrict: 'เชียงกลม',
            district: 'ปากชม',
            province: 'เลย',
            zipcode: 42150,
        },
        {
            subDistrict: 'ท่าช้างคล้อง',
            district: 'ผาขาว',
            province: 'เลย',
            zipcode: 42240,
        },
        {
            subDistrict: 'บ้านเพิ่ม',
            district: 'ผาขาว',
            province: 'เลย',
            zipcode: 42240,
        },
        {
            subDistrict: 'ผาขาว',
            district: 'ผาขาว',
            province: 'เลย',
            zipcode: 42240,
        },
        {
            subDistrict: 'โนนปอแดง',
            district: 'ผาขาว',
            province: 'เลย',
            zipcode: 42240,
        },
        {
            subDistrict: 'โนนป่าซาง',
            district: 'ผาขาว',
            province: 'เลย',
            zipcode: 42240,
        },
        {
            subDistrict: 'ผานกเค้า',
            district: 'ภูกระดึง',
            province: 'เลย',
            zipcode: 42180,
        },
        {
            subDistrict: 'ภูกระดึง',
            district: 'ภูกระดึง',
            province: 'เลย',
            zipcode: 42180,
        },
        {
            subDistrict: 'ศรีฐาน',
            district: 'ภูกระดึง',
            province: 'เลย',
            zipcode: 42180,
        },
        {
            subDistrict: 'ห้วยส้ม',
            district: 'ภูกระดึง',
            province: 'เลย',
            zipcode: 42180,
        },
        {
            subDistrict: 'ภูหอ',
            district: 'ภูหลวง',
            province: 'เลย',
            zipcode: 42230,
        },
        {
            subDistrict: 'หนองคัน',
            district: 'ภูหลวง',
            province: 'เลย',
            zipcode: 42230,
        },
        {
            subDistrict: 'ห้วยสีเสียด',
            district: 'ภูหลวง',
            province: 'เลย',
            zipcode: 42230,
        },
        {
            subDistrict: 'เลยวังไสย์',
            district: 'ภูหลวง',
            province: 'เลย',
            zipcode: 42230,
        },
        {
            subDistrict: 'แก่งศรีภูมิ',
            district: 'ภูหลวง',
            province: 'เลย',
            zipcode: 42230,
        },
        {
            subDistrict: 'ท่าศาลา',
            district: 'ภูเรือ',
            province: 'เลย',
            zipcode: 42160,
        },
        {
            subDistrict: 'ปลาบ่า',
            district: 'ภูเรือ',
            province: 'เลย',
            zipcode: 42160,
        },
        {
            subDistrict: 'ร่องจิก',
            district: 'ภูเรือ',
            province: 'เลย',
            zipcode: 42160,
        },
        {
            subDistrict: 'ลาดค่าง',
            district: 'ภูเรือ',
            province: 'เลย',
            zipcode: 42160,
        },
        {
            subDistrict: 'สานตม',
            district: 'ภูเรือ',
            province: 'เลย',
            zipcode: 42160,
        },
        {
            subDistrict: 'หนองบัว',
            district: 'ภูเรือ',
            province: 'เลย',
            zipcode: 42160,
        },
        {
            subDistrict: 'ทรายขาว',
            district: 'วังสะพุง',
            province: 'เลย',
            zipcode: 42130,
        },
        {
            subDistrict: 'ปากปวน',
            district: 'วังสะพุง',
            province: 'เลย',
            zipcode: 42130,
        },
        {
            subDistrict: 'ผาน้อย',
            district: 'วังสะพุง',
            province: 'เลย',
            zipcode: 42130,
        },
        {
            subDistrict: 'ผาบิ้ง',
            district: 'วังสะพุง',
            province: 'เลย',
            zipcode: 42130,
        },
        {
            subDistrict: 'วังสะพุง',
            district: 'วังสะพุง',
            province: 'เลย',
            zipcode: 42130,
        },
        {
            subDistrict: 'ศรีสงคราม',
            district: 'วังสะพุง',
            province: 'เลย',
            zipcode: 42130,
        },
        {
            subDistrict: 'หนองงิ้ว',
            district: 'วังสะพุง',
            province: 'เลย',
            zipcode: 42130,
        },
        {
            subDistrict: 'หนองหญ้าปล้อง',
            district: 'วังสะพุง',
            province: 'เลย',
            zipcode: 42130,
        },
        {
            subDistrict: 'เขาหลวง',
            district: 'วังสะพุง',
            province: 'เลย',
            zipcode: 42130,
        },
        {
            subDistrict: 'โคกขมิ้น',
            district: 'วังสะพุง',
            province: 'เลย',
            zipcode: 42130,
        },
        {
            subDistrict: 'ตาดข่า',
            district: 'หนองหิน',
            province: 'เลย',
            zipcode: 42190,
        },
        {
            subDistrict: 'ปวนพุ',
            district: 'หนองหิน',
            province: 'เลย',
            zipcode: 42190,
        },
        {
            subDistrict: 'หนองหิน',
            district: 'หนองหิน',
            province: 'เลย',
            zipcode: 42190,
        },
        {
            subDistrict: 'จอมศรี',
            district: 'เชียงคาน',
            province: 'เลย',
            zipcode: 42110,
        },
        {
            subDistrict: 'ธาตุ',
            district: 'เชียงคาน',
            province: 'เลย',
            zipcode: 42110,
        },
        {
            subDistrict: 'นาซ่าว',
            district: 'เชียงคาน',
            province: 'เลย',
            zipcode: 42110,
        },
        {
            subDistrict: 'บุฮม',
            district: 'เชียงคาน',
            province: 'เลย',
            zipcode: 42110,
        },
        {
            subDistrict: 'ปากตม',
            district: 'เชียงคาน',
            province: 'เลย',
            zipcode: 42110,
        },
        {
            subDistrict: 'หาดทรายขาว',
            district: 'เชียงคาน',
            province: 'เลย',
            zipcode: 42110,
        },
        {
            subDistrict: 'เขาแก้ว',
            district: 'เชียงคาน',
            province: 'เลย',
            zipcode: 42110,
        },
        {
            subDistrict: 'เชียงคาน',
            district: 'เชียงคาน',
            province: 'เลย',
            zipcode: 42110,
        },
        {
            subDistrict: 'กกดู่',
            district: 'เมืองเลย',
            province: 'เลย',
            zipcode: 42000,
        },
        {
            subDistrict: 'กกทอง',
            district: 'เมืองเลย',
            province: 'เลย',
            zipcode: 42000,
        },
        {
            subDistrict: 'กุดป่อง',
            district: 'เมืองเลย',
            province: 'เลย',
            zipcode: 42000,
        },
        {
            subDistrict: 'ชัยพฤกษ์',
            district: 'เมืองเลย',
            province: 'เลย',
            zipcode: 42000,
        },
        {
            subDistrict: 'นาดินดำ',
            district: 'เมืองเลย',
            province: 'เลย',
            zipcode: 42000,
        },
        {
            subDistrict: 'นาอาน',
            district: 'เมืองเลย',
            province: 'เลย',
            zipcode: 42000,
        },
        {
            subDistrict: 'นาอ้อ',
            district: 'เมืองเลย',
            province: 'เลย',
            zipcode: 42100,
        },
        {
            subDistrict: 'นาแขม',
            district: 'เมืองเลย',
            province: 'เลย',
            zipcode: 42000,
        },
        {
            subDistrict: 'นาโป่ง',
            district: 'เมืองเลย',
            province: 'เลย',
            zipcode: 42000,
        },
        {
            subDistrict: 'น้ำสวย',
            district: 'เมืองเลย',
            province: 'เลย',
            zipcode: 42000,
        },
        {
            subDistrict: 'น้ำหมาน',
            district: 'เมืองเลย',
            province: 'เลย',
            zipcode: 42000,
        },
        {
            subDistrict: 'ศรีสองรัก',
            district: 'เมืองเลย',
            province: 'เลย',
            zipcode: 42100,
        },
        {
            subDistrict: 'เมือง',
            district: 'เมืองเลย',
            province: 'เลย',
            zipcode: 42000,
        },
        {
            subDistrict: 'เสี้ยว',
            district: 'เมืองเลย',
            province: 'เลย',
            zipcode: 42000,
        },
        {
            subDistrict: 'ทรัพย์ไพวัลย์',
            district: 'เอราวัณ',
            province: 'เลย',
            zipcode: 42220,
        },
        {
            subDistrict: 'ผาสามยอด',
            district: 'เอราวัณ',
            province: 'เลย',
            zipcode: 42220,
        },
        {
            subDistrict: 'ผาอินทร์แปลง',
            district: 'เอราวัณ',
            province: 'เลย',
            zipcode: 42220,
        },
        {
            subDistrict: 'เอราวัณ',
            district: 'เอราวัณ',
            province: 'เลย',
            zipcode: 42220,
        },
        {
            subDistrict: 'ทุ่งศรี',
            district: 'ร้องกวาง',
            province: 'แพร่',
            zipcode: 54140,
        },
        {
            subDistrict: 'น้ำเลา',
            district: 'ร้องกวาง',
            province: 'แพร่',
            zipcode: 54140,
        },
        {
            subDistrict: 'บ้านเวียง',
            district: 'ร้องกวาง',
            province: 'แพร่',
            zipcode: 54140,
        },
        {
            subDistrict: 'ร้องกวาง',
            district: 'ร้องกวาง',
            province: 'แพร่',
            zipcode: 54140,
        },
        {
            subDistrict: 'ร้องเข็ม',
            district: 'ร้องกวาง',
            province: 'แพร่',
            zipcode: 54140,
        },
        {
            subDistrict: 'ห้วยโรง',
            district: 'ร้องกวาง',
            province: 'แพร่',
            zipcode: 54140,
        },
        {
            subDistrict: 'แม่ทราย',
            district: 'ร้องกวาง',
            province: 'แพร่',
            zipcode: 54140,
        },
        {
            subDistrict: 'แม่ยางตาล',
            district: 'ร้องกวาง',
            province: 'แพร่',
            zipcode: 54140,
        },
        {
            subDistrict: 'แม่ยางร้อง',
            district: 'ร้องกวาง',
            province: 'แพร่',
            zipcode: 54140,
        },
        {
            subDistrict: 'แม่ยางฮ่อ',
            district: 'ร้องกวาง',
            province: 'แพร่',
            zipcode: 54140,
        },
        {
            subDistrict: 'ไผ่โทน',
            district: 'ร้องกวาง',
            province: 'แพร่',
            zipcode: 54140,
        },
        {
            subDistrict: 'ต้าผามอก',
            district: 'ลอง',
            province: 'แพร่',
            zipcode: 54150,
        },
        {
            subDistrict: 'ทุ่งแล้ง',
            district: 'ลอง',
            province: 'แพร่',
            zipcode: 54150,
        },
        {
            subDistrict: 'บ่อเหล็กลอง',
            district: 'ลอง',
            province: 'แพร่',
            zipcode: 54150,
        },
        {
            subDistrict: 'บ้านปิน',
            district: 'ลอง',
            province: 'แพร่',
            zipcode: 54150,
        },
        {
            subDistrict: 'ปากกาง',
            district: 'ลอง',
            province: 'แพร่',
            zipcode: 54150,
        },
        {
            subDistrict: 'หัวทุ่ง',
            district: 'ลอง',
            province: 'แพร่',
            zipcode: 54150,
        },
        {
            subDistrict: 'ห้วยอ้อ',
            district: 'ลอง',
            province: 'แพร่',
            zipcode: 54150,
        },
        {
            subDistrict: 'เวียงต้า',
            district: 'ลอง',
            province: 'แพร่',
            zipcode: 54150,
        },
        {
            subDistrict: 'แม่ปาน',
            district: 'ลอง',
            province: 'แพร่',
            zipcode: 54150,
        },
        {
            subDistrict: 'นาพูน',
            district: 'วังชิ้น',
            province: 'แพร่',
            zipcode: 54160,
        },
        {
            subDistrict: 'ป่าสัก',
            district: 'วังชิ้น',
            province: 'แพร่',
            zipcode: 54160,
        },
        {
            subDistrict: 'วังชิ้น',
            district: 'วังชิ้น',
            province: 'แพร่',
            zipcode: 54160,
        },
        {
            subDistrict: 'สรอย',
            district: 'วังชิ้น',
            province: 'แพร่',
            zipcode: 54160,
        },
        {
            subDistrict: 'แม่ป้าก',
            district: 'วังชิ้น',
            province: 'แพร่',
            zipcode: 54160,
        },
        {
            subDistrict: 'แม่พุง',
            district: 'วังชิ้น',
            province: 'แพร่',
            zipcode: 54160,
        },
        {
            subDistrict: 'แม่เกิ๋ง',
            district: 'วังชิ้น',
            province: 'แพร่',
            zipcode: 54160,
        },
        {
            subDistrict: 'ทุ่งน้าว',
            district: 'สอง',
            province: 'แพร่',
            zipcode: 54120,
        },
        {
            subDistrict: 'บ้านกลาง',
            district: 'สอง',
            province: 'แพร่',
            zipcode: 54120,
        },
        {
            subDistrict: 'บ้านหนุน',
            district: 'สอง',
            province: 'แพร่',
            zipcode: 54120,
        },
        {
            subDistrict: 'สะเอียบ',
            district: 'สอง',
            province: 'แพร่',
            zipcode: 54120,
        },
        {
            subDistrict: 'หัวเมือง',
            district: 'สอง',
            province: 'แพร่',
            zipcode: 54120,
        },
        {
            subDistrict: 'ห้วยหม้าย',
            district: 'สอง',
            province: 'แพร่',
            zipcode: 54120,
        },
        {
            subDistrict: 'เตาปูน',
            district: 'สอง',
            province: 'แพร่',
            zipcode: 54120,
        },
        {
            subDistrict: 'แดนชุมพล',
            district: 'สอง',
            province: 'แพร่',
            zipcode: 54120,
        },
        {
            subDistrict: 'ดอนมูล',
            district: 'สูงเม่น',
            province: 'แพร่',
            zipcode: 54130,
        },
        {
            subDistrict: 'น้ำชำ',
            district: 'สูงเม่น',
            province: 'แพร่',
            zipcode: 54130,
        },
        {
            subDistrict: 'บ้านกวาง',
            district: 'สูงเม่น',
            province: 'แพร่',
            zipcode: 54130,
        },
        {
            subDistrict: 'บ้านกาศ',
            district: 'สูงเม่น',
            province: 'แพร่',
            zipcode: 54130,
        },
        {
            subDistrict: 'บ้านปง',
            district: 'สูงเม่น',
            province: 'แพร่',
            zipcode: 54130,
        },
        {
            subDistrict: 'บ้านเหล่า',
            district: 'สูงเม่น',
            province: 'แพร่',
            zipcode: 54130,
        },
        {
            subDistrict: 'พระหลวง',
            district: 'สูงเม่น',
            province: 'แพร่',
            zipcode: 54130,
        },
        {
            subDistrict: 'ร่องกาศ',
            district: 'สูงเม่น',
            province: 'แพร่',
            zipcode: 54130,
        },
        {
            subDistrict: 'สบสาย',
            district: 'สูงเม่น',
            province: 'แพร่',
            zipcode: 54130,
        },
        {
            subDistrict: 'สูงเม่น',
            district: 'สูงเม่น',
            province: 'แพร่',
            zipcode: 54130,
        },
        {
            subDistrict: 'หัวฝาย',
            district: 'สูงเม่น',
            province: 'แพร่',
            zipcode: 54130,
        },
        {
            subDistrict: 'เวียงทอง',
            district: 'สูงเม่น',
            province: 'แพร่',
            zipcode: 54000,
        },
        {
            subDistrict: 'ตำหนักธรรม',
            district: 'หนองม่วงไข่',
            province: 'แพร่',
            zipcode: 54170,
        },
        {
            subDistrict: 'ทุ่งแค้ว',
            district: 'หนองม่วงไข่',
            province: 'แพร่',
            zipcode: 54170,
        },
        {
            subDistrict: 'น้ำรัด',
            district: 'หนองม่วงไข่',
            province: 'แพร่',
            zipcode: 54170,
        },
        {
            subDistrict: 'วังหลวง',
            district: 'หนองม่วงไข่',
            province: 'แพร่',
            zipcode: 54170,
        },
        {
            subDistrict: 'หนองม่วงไข่',
            district: 'หนองม่วงไข่',
            province: 'แพร่',
            zipcode: 54170,
        },
        {
            subDistrict: 'แม่คำมี',
            district: 'หนองม่วงไข่',
            province: 'แพร่',
            zipcode: 54170,
        },
        {
            subDistrict: 'ปงป่าหวาย',
            district: 'เด่นชัย',
            province: 'แพร่',
            zipcode: 54110,
        },
        {
            subDistrict: 'ห้วยไร่',
            district: 'เด่นชัย',
            province: 'แพร่',
            zipcode: 54110,
        },
        {
            subDistrict: 'เด่นชัย',
            district: 'เด่นชัย',
            province: 'แพร่',
            zipcode: 54110,
        },
        {
            subDistrict: 'แม่จั๊วะ',
            district: 'เด่นชัย',
            province: 'แพร่',
            zipcode: 54110,
        },
        {
            subDistrict: 'ไทรย้อย',
            district: 'เด่นชัย',
            province: 'แพร่',
            zipcode: 54110,
        },
        {
            subDistrict: 'กาญจนา',
            district: 'เมืองแพร่',
            province: 'แพร่',
            zipcode: 54000,
        },
        {
            subDistrict: 'ช่อแฮ',
            district: 'เมืองแพร่',
            province: 'แพร่',
            zipcode: 54000,
        },
        {
            subDistrict: 'ทุ่งกวาว',
            district: 'เมืองแพร่',
            province: 'แพร่',
            zipcode: 54000,
        },
        {
            subDistrict: 'ทุ่งโฮ้ง',
            district: 'เมืองแพร่',
            province: 'แพร่',
            zipcode: 54000,
        },
        {
            subDistrict: 'ท่าข้าม',
            district: 'เมืองแพร่',
            province: 'แพร่',
            zipcode: 54000,
        },
        {
            subDistrict: 'นาจักร',
            district: 'เมืองแพร่',
            province: 'แพร่',
            zipcode: 54000,
        },
        {
            subDistrict: 'น้ำชำ',
            district: 'เมืองแพร่',
            province: 'แพร่',
            zipcode: 54000,
        },
        {
            subDistrict: 'บ้านถิ่น',
            district: 'เมืองแพร่',
            province: 'แพร่',
            zipcode: 54000,
        },
        {
            subDistrict: 'ป่าแดง',
            district: 'เมืองแพร่',
            province: 'แพร่',
            zipcode: 54000,
        },
        {
            subDistrict: 'ป่าแมต',
            district: 'เมืองแพร่',
            province: 'แพร่',
            zipcode: 54000,
        },
        {
            subDistrict: 'ร่องฟอง',
            district: 'เมืองแพร่',
            province: 'แพร่',
            zipcode: 54000,
        },
        {
            subDistrict: 'วังธง',
            district: 'เมืองแพร่',
            province: 'แพร่',
            zipcode: 54000,
        },
        {
            subDistrict: 'วังหงษ์',
            district: 'เมืองแพร่',
            province: 'แพร่',
            zipcode: 54000,
        },
        {
            subDistrict: 'สวนเขื่อน',
            district: 'เมืองแพร่',
            province: 'แพร่',
            zipcode: 54000,
        },
        {
            subDistrict: 'ห้วยม้า',
            district: 'เมืองแพร่',
            province: 'แพร่',
            zipcode: 54000,
        },
        {
            subDistrict: 'เหมืองหม้อ',
            district: 'เมืองแพร่',
            province: 'แพร่',
            zipcode: 54000,
        },
        {
            subDistrict: 'แม่คำมี',
            district: 'เมืองแพร่',
            province: 'แพร่',
            zipcode: 54000,
        },
        {
            subDistrict: 'แม่ยม',
            district: 'เมืองแพร่',
            province: 'แพร่',
            zipcode: 54000,
        },
        {
            subDistrict: 'แม่หล่าย',
            district: 'เมืองแพร่',
            province: 'แพร่',
            zipcode: 54000,
        },
        {
            subDistrict: 'ในเวียง',
            district: 'เมืองแพร่',
            province: 'แพร่',
            zipcode: 54000,
        },
        {
            subDistrict: 'ขุนยวม',
            district: 'ขุนยวม',
            province: 'แม่ฮ่องสอน',
            zipcode: 58140,
        },
        {
            subDistrict: 'เมืองปอน',
            district: 'ขุนยวม',
            province: 'แม่ฮ่องสอน',
            zipcode: 58140,
        },
        {
            subDistrict: 'แม่กิ๊',
            district: 'ขุนยวม',
            province: 'แม่ฮ่องสอน',
            zipcode: 58140,
        },
        {
            subDistrict: 'แม่ยวมน้อย',
            district: 'ขุนยวม',
            province: 'แม่ฮ่องสอน',
            zipcode: 58140,
        },
        {
            subDistrict: 'แม่อูคอ',
            district: 'ขุนยวม',
            province: 'แม่ฮ่องสอน',
            zipcode: 58140,
        },
        {
            subDistrict: 'แม่เงา',
            district: 'ขุนยวม',
            province: 'แม่ฮ่องสอน',
            zipcode: 58140,
        },
        {
            subDistrict: 'ถ้ำลอด',
            district: 'ปางมะผ้า',
            province: 'แม่ฮ่องสอน',
            zipcode: 58150,
        },
        {
            subDistrict: 'นาปู่ป้อม',
            district: 'ปางมะผ้า',
            province: 'แม่ฮ่องสอน',
            zipcode: 58150,
        },
        {
            subDistrict: 'ปางมะผ้า',
            district: 'ปางมะผ้า',
            province: 'แม่ฮ่องสอน',
            zipcode: 58150,
        },
        {
            subDistrict: 'สบป่อง',
            district: 'ปางมะผ้า',
            province: 'แม่ฮ่องสอน',
            zipcode: 58150,
        },
        {
            subDistrict: 'ทุ่งยาว',
            district: 'ปาย',
            province: 'แม่ฮ่องสอน',
            zipcode: 58130,
        },
        {
            subDistrict: 'เมืองแปง',
            district: 'ปาย',
            province: 'แม่ฮ่องสอน',
            zipcode: 58130,
        },
        {
            subDistrict: 'เวียงเหนือ',
            district: 'ปาย',
            province: 'แม่ฮ่องสอน',
            zipcode: 58130,
        },
        {
            subDistrict: 'เวียงใต้',
            district: 'ปาย',
            province: 'แม่ฮ่องสอน',
            zipcode: 58130,
        },
        {
            subDistrict: 'แม่นาเติง',
            district: 'ปาย',
            province: 'แม่ฮ่องสอน',
            zipcode: 58130,
        },
        {
            subDistrict: 'แม่ฮี้',
            district: 'ปาย',
            province: 'แม่ฮ่องสอน',
            zipcode: 58130,
        },
        {
            subDistrict: 'โป่งสา',
            district: 'ปาย',
            province: 'แม่ฮ่องสอน',
            zipcode: 58130,
        },
        {
            subDistrict: 'กองก๋อย',
            district: 'สบเมย',
            province: 'แม่ฮ่องสอน',
            zipcode: 58110,
        },
        {
            subDistrict: 'ป่าโปง',
            district: 'สบเมย',
            province: 'แม่ฮ่องสอน',
            zipcode: 58110,
        },
        {
            subDistrict: 'สบเมย',
            district: 'สบเมย',
            province: 'แม่ฮ่องสอน',
            zipcode: 58110,
        },
        {
            subDistrict: 'แม่คะตวน',
            district: 'สบเมย',
            province: 'แม่ฮ่องสอน',
            zipcode: 58110,
        },
        {
            subDistrict: 'แม่สวด',
            district: 'สบเมย',
            province: 'แม่ฮ่องสอน',
            zipcode: 58110,
        },
        {
            subDistrict: 'แม่สามแลบ',
            district: 'สบเมย',
            province: 'แม่ฮ่องสอน',
            zipcode: 58110,
        },
        {
            subDistrict: 'จองคำ',
            district: 'เมืองแม่ฮ่องสอน',
            province: 'แม่ฮ่องสอน',
            zipcode: 58000,
        },
        {
            subDistrict: 'ปางหมู',
            district: 'เมืองแม่ฮ่องสอน',
            province: 'แม่ฮ่องสอน',
            zipcode: 58000,
        },
        {
            subDistrict: 'ผาบ่อง',
            district: 'เมืองแม่ฮ่องสอน',
            province: 'แม่ฮ่องสอน',
            zipcode: 58000,
        },
        {
            subDistrict: 'หมอกจำแป่',
            district: 'เมืองแม่ฮ่องสอน',
            province: 'แม่ฮ่องสอน',
            zipcode: 58000,
        },
        {
            subDistrict: 'ห้วยปูลิง',
            district: 'เมืองแม่ฮ่องสอน',
            province: 'แม่ฮ่องสอน',
            zipcode: 58000,
        },
        {
            subDistrict: 'ห้วยผา',
            district: 'เมืองแม่ฮ่องสอน',
            province: 'แม่ฮ่องสอน',
            zipcode: 58000,
        },
        {
            subDistrict: 'ห้วยโป่ง',
            district: 'เมืองแม่ฮ่องสอน',
            province: 'แม่ฮ่องสอน',
            zipcode: 58000,
        },
        {
            subDistrict: 'ขุนแม่ลาน้อย',
            district: 'แม่ลาน้อย',
            province: 'แม่ฮ่องสอน',
            zipcode: 58120,
        },
        {
            subDistrict: 'ท่าผาปุ้ม',
            district: 'แม่ลาน้อย',
            province: 'แม่ฮ่องสอน',
            zipcode: 58120,
        },
        {
            subDistrict: 'สันติคีรี',
            district: 'แม่ลาน้อย',
            province: 'แม่ฮ่องสอน',
            zipcode: 58120,
        },
        {
            subDistrict: 'ห้วยห้อม',
            district: 'แม่ลาน้อย',
            province: 'แม่ฮ่องสอน',
            zipcode: 58120,
        },
        {
            subDistrict: 'แม่นาจาง',
            district: 'แม่ลาน้อย',
            province: 'แม่ฮ่องสอน',
            zipcode: 58120,
        },
        {
            subDistrict: 'แม่ลาน้อย',
            district: 'แม่ลาน้อย',
            province: 'แม่ฮ่องสอน',
            zipcode: 58120,
        },
        {
            subDistrict: 'แม่ลาหลวง',
            district: 'แม่ลาน้อย',
            province: 'แม่ฮ่องสอน',
            zipcode: 58120,
        },
        {
            subDistrict: 'แม่โถ',
            district: 'แม่ลาน้อย',
            province: 'แม่ฮ่องสอน',
            zipcode: 58120,
        },
        {
            subDistrict: 'บ้านกาศ',
            district: 'แม่สะเรียง',
            province: 'แม่ฮ่องสอน',
            zipcode: 58110,
        },
        {
            subDistrict: 'ป่าแป๋',
            district: 'แม่สะเรียง',
            province: 'แม่ฮ่องสอน',
            zipcode: 58110,
        },
        {
            subDistrict: 'เสาหิน',
            district: 'แม่สะเรียง',
            province: 'แม่ฮ่องสอน',
            zipcode: 58110,
        },
        {
            subDistrict: 'แม่คง',
            district: 'แม่สะเรียง',
            province: 'แม่ฮ่องสอน',
            zipcode: 58110,
        },
        {
            subDistrict: 'แม่ยวม',
            district: 'แม่สะเรียง',
            province: 'แม่ฮ่องสอน',
            zipcode: 58110,
        },
        {
            subDistrict: 'แม่สะเรียง',
            district: 'แม่สะเรียง',
            province: 'แม่ฮ่องสอน',
            zipcode: 58110,
        },
        {
            subDistrict: 'แม่เหาะ',
            district: 'แม่สะเรียง',
            province: 'แม่ฮ่องสอน',
            zipcode: 58110,
        },
    ];
}
