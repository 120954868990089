import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseRequester } from '../common/base-requester';
import { SsmGetRequest } from './interfaces/request-get-param';
import { SsmPutRequest } from './interfaces/request-put-param';

@Injectable({
    providedIn: 'root',
})
export class SsmService extends BaseRequester {
    constructor() {
        super();
    }

    getParameters(keys: string[]): Observable<any> {
        const getRequest: SsmGetRequest = {
            keys,
        };
        return this.internalPostRequest(
            environment.ssmGetEndpoint,
            getRequest
        ).pipe(
            map((response) => {
                return response.response;
            })
        );
    }

    putParameter(key: string, value: string): any {
        const getRequest: SsmPutRequest = {
            key,
            value,
        };
        return this.internalPostRequest(environment.ssmGetEndpoint, getRequest);
    }
}
