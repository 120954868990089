<div class="login-header">
    <div class="title">{{ appInfo.title }}</div>
</div>
<ng-container *ngIf="currentUIState === LoginUIState.SignIn">
    <div class="dx-field slim">Sign In to your account</div>
    <ng-container *ngTemplateOutlet="noticeMessage"></ng-container>
    <dx-validation-group>
        <div class="dx-field">
            <dx-text-box [(value)]="login" placeholder="Login" width="100%">
                <dx-validator>
                    <dxi-validation-rule
                        type="required"
                        message="Login is required"
                    ></dxi-validation-rule>
                </dx-validator>
            </dx-text-box>
        </div>
        <div class="dx-field">
            <dx-text-box
                mode="password"
                [(value)]="password"
                placeholder="Password"
                width="100%"
            >
                <dx-validator>
                    <dxi-validation-rule
                        type="required"
                        message="Password is required"
                    ></dxi-validation-rule>
                </dx-validator>
            </dx-text-box>
        </div>
        <div class="dx-field center-content">
            <dx-check-box [value]="false" text="Remember me"></dx-check-box>
        </div>
        <div class="dx-field slim">
            <dx-validation-summary></dx-validation-summary>
        </div>
        <div>
            <dx-button
                type="default"
                text="Login"
                (onClick)="onLoginClick($event)"
                width="100%"
            ></dx-button>
        </div>
    </dx-validation-group>
    <div class="bottom-link">
        <a (click)="onForgotPasswordClicked()">Forgot password ?</a>
    </div>
</ng-container>

<ng-container *ngIf="currentUIState === LoginUIState.RequestForgotPasswordCode">
    <div class="dx-field slim">
        Please enter your username. We will send a verification code to your
        registered email.
    </div>
    <ng-container *ngTemplateOutlet="noticeMessage"></ng-container>
    <dx-validation-group>
        <div class="dx-field">
            <dx-text-box [(value)]="login" placeholder="Login" width="100%">
                <dx-validator>
                    <dxi-validation-rule
                        type="required"
                        message="Username is required"
                    ></dxi-validation-rule>
                </dx-validator>
            </dx-text-box>
        </div>
        <div class="dx-field slim">
            <dx-validation-summary></dx-validation-summary>
        </div>
        <div class="dx-field">
            <dx-button
                type="default"
                text="Send Verification Code"
                (onClick)="onSendVerificationCodeButtonClicked($event)"
                width="100%"
            ></dx-button>
        </div>
        <ng-container *ngTemplateOutlet="backToSignIn"></ng-container>
    </dx-validation-group>
    <div></div>
</ng-container>

<ng-container *ngIf="currentUIState === LoginUIState.CompleteForgotPassword">
    <div class="dx-field slim">
        We've sent a verification code to your registered email. Please enter
        the code and a new password below.
    </div>
    <ng-container *ngTemplateOutlet="noticeMessage"></ng-container>
    <dx-validation-group>
        <ng-container *ngTemplateOutlet="createPasswordFields"></ng-container>
        <div class="dx-field">
            <dx-text-box
                [(value)]="verificationCode"
                placeholder="Verification Code"
                width="100%"
            >
                <dx-validator>
                    <dxi-validation-rule
                        type="required"
                        message="Verification code is required."
                    ></dxi-validation-rule>
                </dx-validator>
            </dx-text-box>
        </div>
        <div class="dx-field slim">
            <dx-validation-summary></dx-validation-summary>
        </div>
        <div class="dx-field">
            <dx-button
                type="default"
                text="Reset Password"
                (onClick)="onResetPasswordClick($event)"
                width="100%"
            >
            </dx-button>
        </div>
        <ng-container *ngTemplateOutlet="backToSignIn"></ng-container>
    </dx-validation-group>
    <div></div>
</ng-container>

<ng-container *ngIf="currentUIState === LoginUIState.CompleteNewPassword">
    <div class="dx-field slim">Please enter a new password below.</div>
    <ng-container *ngTemplateOutlet="noticeMessage"></ng-container>
    <dx-validation-group>
        <ng-container *ngTemplateOutlet="createPasswordFields"></ng-container>
        <div class="dx-field slim">
            <dx-validation-summary></dx-validation-summary>
        </div>
        <div class="dx-field">
            <dx-button
                type="default"
                text="Submit"
                (onClick)="completeNewPassword($event)"
                width="100%"
            ></dx-button>
        </div>
        <ng-container *ngTemplateOutlet="backToSignIn"></ng-container>
    </dx-validation-group>
    <div></div>
</ng-container>

<ng-template #createPasswordFields>
    <div class="dx-field">
        <dx-text-box
            #newPasswordField
            mode="password"
            [(value)]="newPassword"
            placeholder="New Password"
            width="100%"
        >
            <dx-validator>
                <dxi-validation-rule
                    type="required"
                    message="Password is required."
                ></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>
    </div>
    <div class="dx-field">
        <dx-text-box
            #confirmNewPasswordField
            mode="password"
            [(value)]="confirmNewPassword"
            placeholder="Confirm New Password"
            width="100%"
        >
            <dx-validator>
                <dxi-validation-rule
                    type="required"
                    message="Confirm password is required"
                ></dxi-validation-rule>
                <dxi-validation-rule
                    type="custom"
                    message="Passwords are not matched"
                    [validationCallback]="validateConfirmPassword.bind(this)"
                ></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>
    </div>
</ng-template>

<ng-template #backToSignIn>
    <div class="bottom-link">
        <a (click)="onBackToSignInClicked()">Back to sign in</a>
    </div>
</ng-template>

<ng-template #noticeMessage>
    <div class="dx-field slim danger">{{ notificationMessage }}</div>
</ng-template>
