import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ViewChild,
} from '@angular/core';
import moment, { Moment } from 'moment';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    MessageType,
    NotificationService,
    UnifiedQueryV2Service,
    UtilService,
} from 'src/app/shared/services';
import { ReorderingService } from 'src/app/shared/services/reordering.service';
import {
    AbcAnalysisResponse,
    AbcAnalysisService,
} from 'src/app/shared/services/abc-analysis.service';

import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import * as FileSaver from 'file-saver';
import {
    isEmpty as _isEmpty,
    merge as _merge,
    keyBy as _keyBy,
    values as _values,
    isNil as _isNil,
    get as _get,
    set as _set,
    each as _each,
    includes as _includes,
} from 'lodash';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'app-reordering',
    templateUrl: './reordering.component.html',
    styleUrls: ['./reordering.component.scss'],
})
export class ReorderingComponent implements AfterViewInit {
    @ViewChild('dataGrid', { static: true })
    private dataGrid: DxDataGridComponent;

    calculationDays = 90;
    reorderingLeadTimeDays = 21;
    demandForDays = 30;
    dataSource = null;
    showOnlyBelowRop = false;
    hideDesisted = true;
    desistedItemCount = 0;
    belowRopItemCount = 0;
    dataLoading = false;
    constructor(
        private cdref: ChangeDetectorRef,
        private notificationService: NotificationService,
        private reorderingService: ReorderingService,
        private unifiedQueryV2Service: UnifiedQueryV2Service,
        private abcAnalysisService: AbcAnalysisService,
        private utilService: UtilService
    ) {}

    ngAfterViewInit(): void {
        this.loadReorderingData();
        this.cdref.detectChanges();
    }

    loadReorderingData() {
        if (!this.dataLoading) {
            this.dataLoading = true;
            this.dataGrid.instance.beginCustomLoading(null);
            this.fetchReorderingData().subscribe(
                (result) => {
                    this.dataSource = result;
                    setTimeout(() => {
                        this.onFilterSelectionChanged();
                    }, 10);

                    this.dataGrid.instance.endCustomLoading();
                    this.dataLoading = false;
                },
                (err) => {
                    console.error(err);
                    this.dataGrid.instance.endCustomLoading();
                    this.notificationService.pushMessage({
                        displayTime: 6000,
                        message: this.utilService.errorToString(err),
                        messageType: MessageType.error,
                    });
                    this.dataLoading = false;
                }
            );
        }
    }

    private fetchReorderingData() {
        const inventoryObservable = this.unifiedQueryV2Service.query({
            db: 'arduino4',
            col: 'inventory',
            query: {
                is_show: true,
            },
            options: {
                projection: {
                    sku: 1,
                    title: 1,
                    price: 1,
                    is_show: 1,
                    remain: 1,
                    stock_status: 1,
                    crawler_last_updated: 1,
                },
            },
        });
        const reorderingObservable = this.reorderingService.fetch(
            {},
            this.calculationDays,
            this.reorderingLeadTimeDays
        );
        const abcObservable: Observable<AbcAnalysisResponse> =
            this.abcAnalysisService.get(moment().subtract(1, 'year'));

        return forkJoin([
            inventoryObservable,
            reorderingObservable,
            abcObservable,
        ]).pipe(
            map((responses: any[]) => {
                const inventoryData = responses[0];
                const reorderingData = responses[1].reordering_result;
                const abcAnalysisData = responses[2];
                const merged = _merge(
                    _keyBy(inventoryData, '_id'),
                    _keyBy(reorderingData, '_id'),
                    _keyBy(abcAnalysisData, 'pid')
                );
                const results = _values(merged);
                this.prepareReorderingData(results);
                this.countBelowRopItem();
                return results;
            })
        );
    }

    onCellPrepared(e): void {
        if (e.rowType === 'data') {
            if (
                e.column.dataField === 'remain' &&
                e.data.remain <= e.data.rop
            ) {
                e.cellElement.classList.add('low-stock');
            }
            if (_includes(['rop'], e.column.dataField)) {
                e.cellElement.classList.add('calculated-field');
            }
            if (
                _includes(['sku', 'title'], e.column.dataField) &&
                e.data.isDesisted
            ) {
                e.cellElement.classList.add('desisted-field');
            }
        }
    }

    private prepareReorderingData(records: any[]) {
        const calFields = [
            'avg_sale_per_day',
            'unit_sold',
            'rop',
            'total_sale',
        ];
        this.desistedItemCount = 0;
        this.belowRopItemCount = 0;
        _each(records, (dataItem) => {
            // Fill isDesisted flag
            if (dataItem.stock_status === 'discontinued') {
                dataItem.isDesisted = true;
                this.desistedItemCount++;
            } else {
                dataItem.isDesisted = false;
            }

            // Fill empty class
            if (_isNil(dataItem.class)) {
                dataItem.class = 'F';
            }

            // Fill empty calculation value
            _each(calFields, (field) => {
                if (_isNil(_get(dataItem, field))) {
                    _set(dataItem, field, 0);
                }
            });

            // Count below ROP items
            if (dataItem.remain <= dataItem.rop) {
                this.belowRopItemCount++;
            }

            // Calcuate demand for days
            dataItem.demand = dataItem.avg_sale_per_day * this.demandForDays;
            return dataItem;
        });
    }

    private countBelowRopItem() {
        this.belowRopItemCount = 0;
        _each(this.dataSource, (dataItem) => {
            if (this.hideDesisted) {
                if (!dataItem.isDesisted && dataItem.remain <= dataItem.rop) {
                    this.belowRopItemCount++;
                }
            } else {
                if (dataItem.remain <= dataItem.rop) {
                    this.belowRopItemCount++;
                }
            }
        });
    }

    convertLastUpdateDatetimeToMoment(data): Moment {
        return moment(data.crawler_last_updated);
    }

    onFilterSelectionChanged() {
        this.dataGrid.instance.clearFilter();
        const filters = [];
        if (this.showOnlyBelowRop) {
            filters.push((itemData) => {
                return itemData.remain <= itemData.rop;
            });
        }
        if (this.hideDesisted) {
            if (!_isEmpty(filters)) {
                filters.push('and');
            }
            filters.push(['isDesisted', '=', false]);
        }
        if (!_isEmpty(filters)) {
            this.dataGrid.instance.filter(filters);
        }
        this.countBelowRopItem();
    }

    exportExcel(): void {
        const workbook = new Workbook();
        const sheet = workbook.addWorksheet('Reordering 1');
        exportDataGrid({
            component: this.dataGrid.instance,
            worksheet: sheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
                FileSaver.saveAs(
                    new Blob([buffer], { type: 'application/octet-stream' }),
                    `Reordering ${moment().format(
                        'YYYY_MM_DD-HH_mm_ss_SS'
                    )}.xlsx`
                );
            });
        });
    }
}
