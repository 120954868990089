export const navigation = [
    {
        text: 'Home',
        path: 'secure/home',
        icon: 'home',
    },
    {
        text: 'Operations',
        icon: 'product',
        items: [
            {
                text: 'Split View',
                path: 'secure/orders/quick',
            },
            {
                text: 'Lazada',
                path: 'secure/orders/lazada',
            },
            {
                text: 'Shopee',
                path: 'secure/orders/shopee',
            },
        ],
    },
    {
        text: 'เอกสารขาย',
        icon: 'textdocument',
        items: [
            {
                text: 'ออกใบเสนอราคา',
                path: 'secure/billings/quote-generator',
            },
            {
                text: 'ออกใบกำกับภาษี',
                path: 'secure/billings/tax-invoice',
            },
        ],
    },
    {
        text: 'ภาษี',
        icon: 'taskhelpneeded',
        items: [
            {
                text: 'รายงานภาษีขาย',
                path: 'secure/tax/output-tax/summary',
            },
        ],
    },
    {
        text: 'Utilities',
        icon: 'toolbox',
        items: [
            {
                text: 'สินค้าคงคลัง',
                path: 'secure/inventory/inventory-list',
            },
            {
                text: 'Reordering Calc',
                path: 'secure/utilities/reordering',
            },
            {
                text: 'Lazada Video URL Adder',
                path: 'secure/utilities/laz-vdo-adder',
            },
            // {
            //     text: 'ทะเบียนคู่ค้า',
            //     path: 'secure/utilities/ctp-list',
            // },
            // {
            //     text: 'ค้นหาคู่ค้า',
            //     path: 'embeded/ctp-finder',
            // },
        ],
    },
    {
        text: 'Analytic Tools',
        icon: 'datafield',
        items: [
            {
                text: 'Sale by Time',
                path: 'secure/sale/time',
            },
            {
                text: 'Product Performance',
                path: 'secure/analytic/product-performance',
            },
        ],
    },
    {
        text: 'Settings',
        icon: 'preferences',
        items: [
            {
                text: 'APIs Authorization',
                path: 'secure/settings/api-auth',
            },
            {
                text: 'Service Monitor',
                path: 'secure/settings/svc-mon',
            },
        ],
    },
];
