export enum OrderStatus {
    Unpaid = 'UNPAID', //Order is created, buyer has not paid yet
    Processed = 'PROCESSED', //Seller has arranged shipment online and got tracking number from 3PL
    ReadyToShip = 'READY_TO_SHIP', //Seller can arrange shipment
    Shipped = 'SHIPPED', //The parcel has been drop to 3PL or picked up by 3PL
    ToConfirmReceive = 'TO_CONFIRM_RECEIVE', //The order has been received by buyer
    Completed = 'COMPLETED', //The order has been completed
    RetryShip = 'RETRY_SHIP', //3PL pickup parcel fail. Need to re arrange shipment
    InCancel = 'IN_CANCEL', //The order's cancelation is under processing
    Cacelled = 'CANCELLED', //The order has been canceled
    ToReturn = 'TO_RETURN', //The buyer requested to return the order and order's return is processing
}
