import { Component, NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';

import { AuthService, AppInfoService } from '../../services';
import {
    DxButtonModule,
    DxButtonComponent,
} from 'devextreme-angular/ui/button';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { DxValidationSummaryModule } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
    public LoginUIState: typeof LoginUIState = LoginUIState;
    currentUIState = LoginUIState.SignIn;
    login = '';
    password = '';
    verificationCode = '';
    newPassword = '';
    confirmNewPassword = '';
    notificationMessage = '';

    private newPasswordField: DxButtonComponent;
    private confirmNewPasswordField: DxButtonComponent;
    private user: CognitoUser;

    @ViewChild('newPasswordField') set _newPasswordField(
        content: DxButtonComponent
    ) {
        if (content) {
            this.newPasswordField = content;
        }
    }
    @ViewChild('confirmNewPasswordField') set _confirmNewPasswordField(
        content: DxButtonComponent
    ) {
        if (content) {
            this.confirmNewPasswordField = content;
        }
    }

    constructor(
        private authService: AuthService,
        public appInfo: AppInfoService,
        private router: Router
    ) {}

    onLoginClick(args) {
        this.clearNotificationMessage();
        if (!args.validationGroup.validate().isValid) {
            return;
        }
        this.authService
            .logIn(this.login, this.password)
            .then((user: any | CognitoUser) => {
                this.user = user;
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    this.currentUIState = LoginUIState.CompleteNewPassword;
                } else {
                    args.validationGroup.reset();
                    this.onSignedIn();
                }
            })
            .catch((error) => {
                if (error && error.code === 'PasswordResetRequiredException') {
                    this.currentUIState =
                        LoginUIState.RequestForgotPasswordCode;
                } else if (error && error.message) {
                    this.notifyError(error.message);
                }
            });
    }

    requestResetPasswordVerificationCode(username: string) {
        this.clearNotificationMessage();
        this.authService
            .requestResetPasswordVerificationCode(username)
            .then(() => {
                this.currentUIState = LoginUIState.CompleteForgotPassword;
            })
            .catch((error) => {
                if (error && error.code === 'UserNotFoundException') {
                    this.notifyError('User you provided does not exist.');
                } else if (error && error.message) {
                    this.notifyError(error.message);
                }
            });
    }

    onResetPasswordClick(args) {
        this.clearNotificationMessage();
        if (!args.validationGroup.validate().isValid) {
            return;
        }
        this.authService
            .confirmForgotPassword(
                this.verificationCode,
                this.login,
                this.newPassword
            )
            .then(() => {
                this.currentUIState = LoginUIState.SignIn;
                notify({
                    message: 'Your password has been reset successfully.',
                    type: 'success',
                    displayTime: 10000,
                    position: 'top center',
                });
            })
            .catch((error) => {
                if (error && error.message) {
                    this.notifyError(error.message);
                }
            });
    }

    completeNewPassword(args) {
        this.clearNotificationMessage();
        if (!args.validationGroup.validate().isValid) {
            return;
        }
        this.authService
            .completeNewPassword(this.user, this.newPassword)
            .then((user: CognitoUser) => {
                this.user = user;
                this.onSignedIn();
            });
    }

    onSendVerificationCodeButtonClicked(args) {
        this.clearNotificationMessage();
        if (!args.validationGroup.validate().isValid) {
            return;
        }
        this.requestResetPasswordVerificationCode(this.login);
    }

    onSignedIn() {
        this.router.navigate(['/']);
    }

    onForgotPasswordClicked() {
        this.clearNotificationMessage();
        this.currentUIState = LoginUIState.RequestForgotPasswordCode;
    }

    onBackToSignInClicked() {
        this.clearNotificationMessage();
        this.currentUIState = LoginUIState.SignIn;
    }

    validateConfirmPassword() {
        return this.newPasswordField.text === this.confirmNewPasswordField.text;
    }

    notifyError(errorMessage: string) {
        this.notificationMessage = errorMessage;
    }

    clearNotificationMessage() {
        this.notificationMessage = '';
    }
}

export enum LoginUIState {
    SignIn,
    CompleteNewPassword,
    CompleteForgotPassword,
    RequestForgotPasswordCode,
}
@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DxButtonModule,
        DxCheckBoxModule,
        DxTextBoxModule,
        DxValidatorModule,
        DxValidationGroupModule,
        DxValidationSummaryModule,
    ],
    declarations: [LoginFormComponent],
    exports: [LoginFormComponent],
})
export class LoginFormModule {}
