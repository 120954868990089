import { Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    private messageSubject: Subject<Notification> = new Subject<Notification>();

    constructor() {
        this.messageSubject.subscribe((notification: Notification) => {
            let displayTime: number = notification.displayTime;
            if (!displayTime) {
                displayTime =
                    notification.messageType === 'error' ? 8000 : 4000;
            }
            notify(
                {
                    message: notification.message,
                    type: notification.messageType,
                    width: 350,
                    displayTime,
                    closeOnClick: true,
                },
                {
                    direction: 'down-push',
                    position: 'top right',
                }
            );
        });
    }

    pushMessage(notification: Notification) {
        this.messageSubject.next(notification);
    }

    getMessageSubject() {
        return this.messageSubject;
    }
}

export interface Notification {
    message: string;
    messageType: MessageType;
    displayTime?: number;
}

export enum MessageType {
    info = 'info',
    error = 'error',
    success = 'success',
    warning = 'warning',
    custom = 'custom',
}
