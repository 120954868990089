import { Component, EventEmitter, Output } from '@angular/core';
import {
    get as _get,
    map as _map,
    isEmpty as _isEmpty,
    clone as _clone,
    find as _find,
} from 'lodash';
import {
    MessageType,
    NotificationService,
    ShopeeOpenServiceV2,
    UtilService,
} from '../../../../shared/services';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-shopee-delivery-popup',
    templateUrl: './shopee-delivery-popup.component.html',
    styleUrls: ['./shopee-delivery-popup.component.scss'],
})
export class ShopeeDeliveryPopupComponent {
    @Output() popupClose = new EventEmitter();

    isPickupScheduled = false;
    loadingVisible = false;
    popupVisible = false;

    orders = null;

    constructor(
        private shopeeOpenService: ShopeeOpenServiceV2,
        private notificationService: NotificationService,
        private utilService: UtilService
    ) {}

    popup(orderList: any[]): void {
        this.orders = _clone(orderList);
        this.popupVisible = true;
        this.isPickupScheduled = false;
    }

    getTimeSlots(options) {
        const timeSlots = _get(options, 'data.$timeSlotList');
        return timeSlots ? timeSlots : [];
    }

    schedulePickup(): void {
        if (!_isEmpty(this.orders)) {
            this.loadingVisible = true;
            this.isPickupScheduled = true;
            const shipOrderObsList = _map(this.orders, (order) => {
                const orderSn = _get(order, 'order_sn');
                const addressId = _get(order, '$addressId');
                const pickUpSlotId = _get(order, '$selectedTimeSlotId');
                return this.shopeeOpenService.shipOrder(
                    orderSn,
                    addressId,
                    pickUpSlotId
                );
            });
            forkJoin(shipOrderObsList).subscribe(
                (results: string[]) => {
                    this.loadingVisible = false;
                    this.notificationService.pushMessage({
                        displayTime: 4000,
                        message: `${results.length} order(s) sheduled.`,
                        messageType: MessageType.success,
                    });
                    this.loadingVisible = false;
                    this.closePopup();
                },
                (error) => {
                    this.loadingVisible = false;
                    console.error(error);
                    this.notificationService.pushMessage({
                        displayTime: 6000,
                        message: this.utilService.errorToString(error),
                        messageType: MessageType.error,
                    });
                }
            );
        }
    }

    getTimeSlotDisplay(data) {
        const timeSlotObj = _find(data.$timeSlotList, {
            pickup_time_id: data.$selectedTimeSlotId,
        });
        if (timeSlotObj) {
            return timeSlotObj.displayValue || '';
        }
        return '';
    }

    closePopup(): void {
        this.popupVisible = false;
    }

    onPopupClose(event: any) {
        event.isPickupScheduled = this.isPickupScheduled;
        console.log(event);
        this.popupClose.emit(event);
    }

    onToolbarPreparingOnlyPickup(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                locateInMenu: 'auto',
                widget: 'dxButton',
                options: {
                    icon: 'return',
                    text: 'Back',
                    onClick: this.closePopup.bind(this, e),
                },
            },
            {
                location: 'center',
                template: 'pickupHeaderText',
            },
            {
                location: 'after',
                widget: 'dxButton',
                locateInMenu: 'auto',
                options: {
                    icon: 'box',
                    text: 'Schedule Pickup',
                    type: 'default',
                    onClick: this.schedulePickup.bind(this),
                },
            }
        );
    }
}
