import { Injectable } from '@angular/core';
import {
    map as _map,
    each as _each,
    find as _find,
    endsWith as _endsWith,
} from 'lodash';
@Injectable()
export class DistChartService {
    constructor() {}

    getProductDistributionChartData(values: number[]): ChartPoint[] {
        const chartArgMap = this.constructChartArgMap(50, 900);
        const distributionChartData: ChartPoint[] = _map(
            [...chartArgMap.keys()],
            (chartKey: string) => {
                return {
                    range: chartKey,
                    value: 0,
                };
            }
        );

        _each(values, (value: number) => {
            this.addDistributionPoint(
                chartArgMap,
                distributionChartData,
                value
            );
        });
        return distributionChartData;
    }

    private constructChartArgMap(
        range: number,
        maxValue: number
    ): Map<string, number> {
        const chartRange = new Map<string, number>();
        for (let lower = 0; lower < maxValue; lower = lower + range - 1) {
            const upper = lower + range - 1;
            chartRange.set(`${lower}-${upper}`, upper);
            lower++;
        }
        if (chartRange.size > 0) {
            const lastValue = [...chartRange.values()][chartRange.size - 1];
            chartRange.set(`${lastValue + 1}+`, lastValue + 1);
        }
        return chartRange;
    }

    private addDistributionPoint(
        chartArgMap: Map<string, number>,
        chartData: ChartPoint[],
        valueToCount: number
    ) {
        const keys = [...chartArgMap.keys()];
        _each(keys, (key) => {
            const value = chartArgMap.get(key);
            if (
                valueToCount <= value ||
                (_endsWith(key, '+') && valueToCount >= value)
            ) {
                const chartPoint: ChartPoint = _find(chartData, {
                    range: key,
                });
                if (chartPoint) {
                    chartPoint.value++;
                } else {
                    console.error(
                        `Chart construction error: Cannot find data point key ${key}`
                    );
                }
                return false;
            }
        });
    }
}

export interface ChartPoint {
    range: string;
    value: number;
}
