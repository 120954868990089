import { Injectable } from '@angular/core';
import { BaseRequester } from './common/base-requester';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
@Injectable({
    providedIn: 'root',
})
export class ReorderingService extends BaseRequester {
    constructor() {
        super();
    }

    public fetch(
        filter: object,
        calculationDays: number,
        orderingLeadTimeDays: number
    ): Observable<any> {
        const payload: ReorderingRequest = {
            filter,
            calculationDays,
            orderingLeadTimeDays,
        };
        return this.internalPostRequest(
            environment.reorderingEndpoint,
            payload
        ).pipe(
            map((response: AjaxResponse) => {
                if (response.response.errorMessage) {
                    throw new Error(
                        `[${response.response.errorType}]: ${response.response.errorMessage}`
                    );
                } else {
                    return response.response;
                }
            })
        );
    }
}

interface ReorderingRequest {
    filter: object;
    calculationDays: number;
    orderingLeadTimeDays: number;
}
