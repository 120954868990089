import {
    Component,
    ViewChild,
    AfterViewInit,
    ChangeDetectorRef,
} from '@angular/core';
import 'devextreme/data/odata/store';
import { DxDataGridComponent } from 'devextreme-angular';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ShopeeDeliveryPopupComponent } from 'src/app/pages/orders/shopee-orders/shopee-delivery-popup/shopee-delivery-popup.component';
import { OrderStatus } from 'src/app/shared/services/shopee/enums/order-status-v2';
import { ShopeeFormatDateTimePipe } from 'src/app/shared/pipes';
import {
    set as _set,
    get as _get,
    isEmpty as _isEmpty,
    filter as _filter,
    includes as _includes,
    each as _each,
    find as _find,
    reduce as _reduce,
    map as _map,
    toLower as _toLower,
    isArray as _isArray,
    isEmpty,
} from 'lodash';
import {
    LastPrintPlatform,
    LastPrintResult,
    LastPrintTimeService,
    MessageType,
    NotificationService,
    ShopeeOpenServiceV2,
    UtilService,
} from 'src/app/shared/services';
import moment from 'moment';
import { environment } from 'src/environments/environment';

import { InvoiceParameter } from 'src/app/shared/services/print-sheet/interfaces/invoice-param';
import { ShopeeShippingLabelParameter } from 'src/app/shared/services/print-sheet/interfaces/shopee-shipping-label-param';
import { PrintSheetService } from 'src/app/shared/services/print-sheet/print-sheet.service';
import { PrintSheetUtils } from 'src/app/shared/services/print-sheet/utils';
import { ShopeeShippingDocDataInfo } from 'src/app/shared/services/shopee/interfaces/shopee-shipping-doc-data';

@Component({
    selector: 'app-shopee-orders',
    templateUrl: './shopee-orders.component.html',
    styleUrls: ['./shopee-orders.component.scss'],
})
export class ShopeeOrdersComponent implements AfterViewInit {
    @ViewChild('dataGrid', { static: true })
    private dataGrid: DxDataGridComponent;
    @ViewChild('deliveryPopup', { static: true })
    private deliveryPopup: ShopeeDeliveryPopupComponent;

    OrderStatus = OrderStatus; // eslint-disable-line @typescript-eslint/naming-convention
    isDataLoading = false;
    selectedRowKeys: string[] = [];
    hasSellerNotes = false;
    deferSelectedOrderStatus: OrderStatus = OrderStatus.ReadyToShip;
    orderStatuses = [
        {
            key: OrderStatus.Unpaid,
            text: 'Unpaid',
        },
        {
            key: OrderStatus.ReadyToShip,
            text: 'Ready to ship',
        },
        {
            key: OrderStatus.Processed,
            text: 'Processed',
        },
        {
            key: OrderStatus.Completed,
            text: 'Completed',
        },
        {
            key: OrderStatus.InCancel,
            text: 'In cancel',
        },
        {
            key: OrderStatus.Cacelled,
            text: 'Cancelled',
        },
        {
            key: OrderStatus.ToReturn,
            text: 'To Return',
        },
    ];
    selectedTabItem: {
        key: OrderStatus;
        text: string;
    } = this.getTabByStatus(OrderStatus.ReadyToShip);
    orderSource = [];

    confirmDeliverButtonOptions = {
        text: 'Confirm Delivery',
        icon: 'box',
        type: 'default',
        stylingMode: 'contained',
        onClick: this.confirmDeliver.bind(this),
    };
    printInvoiceButtonOptions = {
        text: 'Print Invoices',
        icon: 'print',
        stylingMode: 'outlined',
        onClick: this.onPrintInvoiceClicked.bind(this),
    };
    printShippingLabelButtonOptions = {
        text: 'Print Shipping Labels',
        icon: 'print',
        stylingMode: 'outlined',
        onClick: this.onPrintShippingLabelClicked.bind(this),
    };
    printBothButtonOptions = {
        text: 'Print Both',
        icon: 'print',
        type: 'default',
        stylingMode: 'outlined',
        onClick: this.onPrintBothClicked.bind(this),
    };
    refreshButtonOptions = {
        text: '',
        icon: 'refresh',
        onClick: (): void => {
            this.updateOrderSubject.next();
        },
    };

    private updateOrderSubject = new Subject();

    constructor(
        private cdref: ChangeDetectorRef,
        private shopeeFormatDateTimePipe: ShopeeFormatDateTimePipe,
        private shopeeOpenServiceV2: ShopeeOpenServiceV2,
        private printSheetService: PrintSheetService,
        private notificationService: NotificationService,
        private lastprintTimeService: LastPrintTimeService,
        private utilService: UtilService
    ) {}

    ngAfterViewInit(): void {
        this.updateOrderSubject
            .pipe(
                switchMap((orderStatus: OrderStatus) => {
                    this.dataGridBeginLoading();
                    if (!orderStatus) {
                        orderStatus = this.selectedTabItem.key;
                    }
                    this.cdref.detectChanges();
                    return forkJoin([
                        this.shopeeOpenServiceV2.getOrderByStatusIncludingDetails(
                            moment().subtract(14, 'days'),
                            moment(),
                            orderStatus
                        ),
                        orderStatus === OrderStatus.Processed ||
                        orderStatus === OrderStatus.Completed
                            ? this.lastprintTimeService.get(
                                  LastPrintPlatform.shopee
                              )
                            : of([]),
                    ]).pipe(
                        switchMap((responses: any[]) => {
                            return forkJoin([
                                of(responses[0]),
                                of(responses[1]),
                                orderStatus === OrderStatus.Processed ||
                                orderStatus === OrderStatus.Completed
                                    ? this.shopeeOpenServiceV2.getTrackingNumbers(
                                          _map(responses[0], 'order_sn')
                                      )
                                    : of([]),
                            ]);
                        })
                    );
                })
            )
            .subscribe(
                (responses: any[]) => {
                    const orderByStatusResponse: any[] = responses[0];
                    const lastPrintResponse: any[] = responses[1];
                    const trackingNumberResponse: any[] = responses[2];
                    this.fillMatchingNumber(orderByStatusResponse);
                    this.fillTrackingNumber(
                        orderByStatusResponse,
                        trackingNumberResponse
                    );
                    this.checkSellerNotes(orderByStatusResponse);
                    this.fillDateFields(orderByStatusResponse);
                    this.mergeLastPrintData(
                        orderByStatusResponse,
                        lastPrintResponse
                    );

                    if (this.selectedTabItem.key === OrderStatus.ReadyToShip) {
                        const readyToShipTab = this.getTabByStatus(
                            OrderStatus.ReadyToShip
                        );
                        if (readyToShipTab) {
                            readyToShipTab.text = `Ready to ship (${orderByStatusResponse.length})`;
                        }
                        const processedTab = this.getTabByStatus(
                            OrderStatus.Processed
                        );
                        if (processedTab) {
                            processedTab.text = `Processed`;
                        }
                    } else if (
                        this.selectedTabItem.key === OrderStatus.Processed
                    ) {
                        const processedTab = this.getTabByStatus(
                            OrderStatus.Processed
                        );
                        if (processedTab) {
                            processedTab.text = `Processed (${orderByStatusResponse.length})`;
                        }
                        const readyToShipTab = this.getTabByStatus(
                            OrderStatus.ReadyToShip
                        );
                        if (readyToShipTab) {
                            readyToShipTab.text = `Ready to ship`;
                        }
                    }
                    this.deferSelectedOrderStatus = this.selectedTabItem.key;
                    this.orderSource = orderByStatusResponse;
                    this.selectRowsByTab();
                    this.dataGridEndLoading();
                },
                (error) => {
                    console.error(error);
                    this.dataGridEndLoading();
                    this.notificationService.pushMessage({
                        displayTime: 6000,
                        message: this.utilService.errorToString(error),
                        messageType: MessageType.error,
                    });
                }
            );
        this.updateOrderSubject.next();
    }

    orderStatusSelectionChanged(event: any): void {
        const orderStatusKey: OrderStatus = _get(event, 'addedItems[0].key');
        this.updateOrderSubject.next(orderStatusKey);
    }

    confirmDeliver(): void {
        if (!_isEmpty(this.selectedRowKeys)) {
            const orders = this.getSelectedRowsData();
            if (!_isEmpty(orders)) {
                this.dataGridBeginLoading();
                const fillPickupTimeSlotObservers: Observable<any>[] = _map(
                    orders,
                    (order) => {
                        return this.fillPickupTimeSlot(order);
                    }
                );
                forkJoin(fillPickupTimeSlotObservers).subscribe(
                    () => {
                        this.deliveryPopup.popup(orders);
                        this.dataGridEndLoading();
                    },
                    (error) => {
                        this.dataGridEndLoading();
                        console.error(error);
                        this.notificationService.pushMessage({
                            displayTime: 6000,
                            message:
                                'Timeslot loading failed: ' +
                                this.utilService.errorToString(error),
                            messageType: MessageType.error,
                        });
                    }
                );
            }
        }
        this.lastprintTimeService.get(LastPrintPlatform.shopee).subscribe();
    }

    fillPickupTimeSlot(order: any): Observable<any> {
        const orderSn = _get(order, 'order_sn');
        if (orderSn) {
            return this.shopeeOpenServiceV2.getShippingParam(orderSn).pipe(
                map((response) => {
                    const availableAddressLists = _get(
                        response,
                        'pickup.address_list'
                    );
                    const selectedPickupAddress = _find(
                        availableAddressLists,
                        (addressObj) => {
                            return _includes(
                                addressObj.address_flag,
                                'pickup_address'
                            );
                        }
                    );
                    let timeSlotList: any[] = _get(
                        selectedPickupAddress,
                        'time_slot_list'
                    );
                    if (_isArray(timeSlotList) && !_isEmpty(timeSlotList)) {
                        timeSlotList = _map(timeSlotList, (timeSlot) => {
                            let displayValue = '';
                            if (timeSlot.date) {
                                const momentDate = moment.unix(timeSlot.date);
                                if (momentDate.isValid()) {
                                    displayValue = displayValue.concat(
                                        momentDate.format('dddd D, MMM')
                                    );
                                }
                            }
                            if (timeSlot.time_text) {
                                displayValue = displayValue.concat(
                                    ` (${timeSlot.time_text})`
                                );
                            }
                            if (_isEmpty(displayValue)) {
                                displayValue = timeSlot.pickup_time_id;
                            }
                            return _set(timeSlot, 'displayValue', displayValue);
                        });
                        _set(order, `$timeSlotList`, timeSlotList);
                        _set(
                            order,
                            `$selectedTimeSlotId`,
                            timeSlotList[0].pickup_time_id
                        );
                    }
                    const addressId: number = _get(
                        selectedPickupAddress,
                        'address_id'
                    );
                    if (addressId) {
                        _set(order, '$addressId', addressId);
                    }
                    return order;
                })
            );
        }
        return of(order);
    }

    private printShippingLabels(
        dataRows: any,
        saveLastPrint = true
    ): Observable<any> {
        const selectedRowKeys = _map(dataRows, 'order_sn');
        if (!_isEmpty(dataRows)) {
            const shippingInfoObservables: Observable<ShopeeShippingDocDataInfo>[] =
                _map(dataRows, (dataRow) => {
                    return this.shopeeOpenServiceV2.getShippingDocInfo(
                        dataRow['order_sn'],
                        10,
                        15
                    );
                });

            return forkJoin(shippingInfoObservables).pipe(
                map((docDataResponses: ShopeeShippingDocDataInfo[]) => {
                    _each(
                        docDataResponses,
                        (docData: ShopeeShippingDocDataInfo) => {
                            const row = _find(dataRows, {
                                order_sn: docData.orderSn,
                            });
                            _set(row, '$shippingDocData', docData);
                        }
                    );
                }),
                map(() => {
                    const shippingLabelParams: ShopeeShippingLabelParameter[] =
                        this.constructShippingLabelPrintingParams(dataRows);
                    return shippingLabelParams;
                }),
                switchMap((shippingLabelParams) =>
                    forkJoin([
                        this.printSheetService.printShippingLabel(
                            shippingLabelParams,
                            environment.shopeeeShippingLabelReportTemplateName,
                            0.335,
                            'Shopee Shipping Labels'
                        ),
                        saveLastPrint
                            ? this.lastprintTimeService.put(
                                  LastPrintPlatform.shopee,
                                  selectedRowKeys
                              )
                            : of([]),
                    ])
                )
            );
        }
        return of([]);
    }

    private printInvoice(
        dataRows: any[],
        saveLastPrint = true
    ): Observable<any> {
        const selectedRowKeys = _map(dataRows, 'order_sn');
        if (!_isEmpty(dataRows)) {
            const shippingInfoObservables: Observable<ShopeeShippingDocDataInfo>[] =
                _map(dataRows, (dataRow) => {
                    return this.shopeeOpenServiceV2.getShippingDocInfo(
                        dataRow['order_sn'],
                        10,
                        7,
                        8
                    );
                });

            return forkJoin(shippingInfoObservables).pipe(
                map((docDataResponses: ShopeeShippingDocDataInfo[]) => {
                    _each(
                        docDataResponses,
                        (docData: ShopeeShippingDocDataInfo) => {
                            const row = _find(dataRows, {
                                order_sn: docData.orderSn,
                            });
                            _set(row, '$shippingDocData', docData);
                        }
                    );
                }),
                map(() => {
                    const invoiceParams: InvoiceParameter[] =
                        this.constructInvoicePrintingParams(dataRows);
                    return invoiceParams;
                }),
                switchMap((invoiceParams) => {
                    return forkJoin([
                        this.printSheetService.printInvoice(
                            invoiceParams,
                            'Shopee Invoices'
                        ),
                        saveLastPrint
                            ? this.lastprintTimeService.put(
                                  LastPrintPlatform.shopee,
                                  selectedRowKeys
                              )
                            : of([]),
                    ]);
                })
            );
        }
        return of([]);
    }

    onPrintBothClicked(): void {
        const selectRows: any[] = this.getSelectedRowsData();
        if (!isEmpty(selectRows)) {
            this.dataGridBeginLoading();
            forkJoin([
                this.printInvoice(selectRows),
                this.printShippingLabels(selectRows, false),
            ]).subscribe(
                () => {
                    this.dataGridEndLoading();
                    this.notificationService.pushMessage({
                        displayTime: 6000,
                        message: `Printed invoices and shipping labels for ${selectRows.length} order(s)`,
                        messageType: MessageType.success,
                    });
                    this.updateOrderSubject.next();
                },
                (error) => {
                    this.dataGridEndLoading();
                    console.error(error);
                    this.notificationService.pushMessage({
                        displayTime: 6000,
                        message:
                            'Printing failed: ' +
                            this.utilService.errorToString(error),
                        messageType: MessageType.error,
                    });
                }
            );
        }
    }

    onPrintShippingLabelClicked(): void {
        const selectRows: any[] = this.getSelectedRowsData();
        if (!isEmpty(selectRows)) {
            this.dataGridBeginLoading();
            this.printShippingLabels(selectRows).subscribe(
                () => {
                    this.dataGridEndLoading();
                    this.notificationService.pushMessage({
                        displayTime: 6000,
                        message: `Printed shipping labels for ${selectRows.length} order(s)`,
                        messageType: MessageType.success,
                    });
                    this.updateOrderSubject.next();
                },
                (error) => {
                    this.dataGridEndLoading();
                    console.error(error);
                    this.notificationService.pushMessage({
                        displayTime: 6000,
                        message:
                            'Shipping label printing failed: ' +
                            this.utilService.errorToString(error),
                        messageType: MessageType.error,
                    });
                }
            );
        }
    }

    onPrintInvoiceClicked(): void {
        const selectRows: any[] = this.getSelectedRowsData();
        if (!isEmpty(selectRows)) {
            this.dataGridBeginLoading();
            this.printInvoice(selectRows).subscribe(
                () => {
                    this.dataGridEndLoading();
                    this.notificationService.pushMessage({
                        displayTime: 6000,
                        message: `Printed invoices for ${selectRows.length} order(s)`,
                        messageType: MessageType.success,
                    });
                    this.updateOrderSubject.next();
                },
                (error) => {
                    this.dataGridEndLoading();
                    console.error(error);
                    this.notificationService.pushMessage({
                        displayTime: 6000,
                        message:
                            'Invoice printing failed: ' +
                            this.utilService.errorToString(error),
                        messageType: MessageType.error,
                    });
                }
            );
        }
    }

    onConfirmDeliverPopupClosed(event): void {
        if (event.isPickupScheduled) {
            this.selectedTabItem = this.getTabByStatus(OrderStatus.Processed);
        }
    }

    private constructInvoicePrintingParams(orders: any[]): InvoiceParameter[] {
        return _map(orders, (order) => {
            const totalItemPrice = _reduce(
                order.item_list,
                (accumulator: any, item) => {
                    return (
                        accumulator +
                        this.utilService.localStringToNumber(
                            item.model_original_price
                        ) *
                            this.utilService.localStringToNumber(
                                item.model_quantity_purchased
                            )
                    );
                },
                0
            );
            const totalDiscount =
                totalItemPrice +
                (order.actual_shipping_fee_confirmed
                    ? this.utilService.localStringToNumber(
                          order.actual_shipping_fee
                      )
                    : this.utilService.localStringToNumber(
                          order.estimated_shipping_fee
                      )) -
                this.utilService.localStringToNumber(order.total_amount);
            return {
                sellerInfo: {
                    name: environment.reportSenderName,
                    website: environment.shopShopeeUrl,
                    address: environment.reportSenderAddress,
                    logoUrl: environment.shopLogoUrl,
                },
                customerInfo: {
                    name: order.recipient_address.name,
                    name_img_src: order['$shippingDocData'].recipientNameBase64,
                    address_img_src:
                        order['$shippingDocData'].recipientFullAddrBase64,
                    orderNo: order.order_sn,
                    date: this.shopeeFormatDateTimePipe.transform(
                        order.create_time,
                        environment.printDateFormat
                    ),
                },
                orderInfo: {
                    orderNo: order.order_sn,
                    matchingNumber: order.matching,
                    total: this.utilService.localStringToNumber(totalItemPrice),
                    discount:
                        this.utilService.localStringToNumber(totalDiscount),
                    shippingCost: order.actual_shipping_fee_confirmed
                        ? this.utilService.localStringToNumber(
                              order.actual_shipping_fee
                          )
                        : this.utilService.localStringToNumber(
                              order.estimated_shipping_fee
                          ),
                    netPrice: this.utilService.localStringToNumber(
                        order.total_amount
                    ),
                },
                items: _map(order.item_list, (item, index) => {
                    return {
                        itemNo: index + 1,
                        description: PrintSheetUtils.constructItemDescription(
                            item.item_sku,
                            item.item_name,
                            item.model_quantity_purchased,
                            item.model_name,
                            item.model_sku
                        ),
                        unitPrice: this.utilService.localStringToNumber(
                            item.model_original_price
                        ),
                        quantity: this.utilService.localStringToNumber(
                            item.model_quantity_purchased
                        ),
                        amount:
                            this.utilService.localStringToNumber(
                                item.model_original_price
                            ) *
                            this.utilService.localStringToNumber(
                                item.model_quantity_purchased
                            ),
                    };
                }) as any,
            };
        });
    }

    private constructShippingLabelPrintingParams(
        orders: any[]
    ): ShopeeShippingLabelParameter[] {
        return _map(orders, (order): ShopeeShippingLabelParameter => {
            return {
                matchingNumber: order.matching,
                trackingNumber: order.trackingNumber,
                shippingCarrier: _get(order, 'shipping_carrier'),
                shippingCarrierLogoUrl: this.getShippingCarrierLogo(
                    _get(order, 'shipping_carrier')
                ),
                sender: {
                    name: environment.reportSenderName,
                    address: environment.reportSenderAddress,
                },
                receiver: {
                    name: order.recipient_address.name,
                    name_img_src: order['$shippingDocData'].recipientNameBase64,
                    address_img_src:
                        order['$shippingDocData'].recipientFullAddrBase64,
                    district: order.recipient_address.city,
                    first_recipient_sort_code:
                        order['$shippingDocData'].firstRecipientSortCode || '',
                },
                orderInfo: {
                    // eslint-disable-next-line id-blacklist
                    number: order.order_sn,
                    creationDate: this.shopeeFormatDateTimePipe.transform(
                        order.create_time,
                        environment.printDateFormat
                    ),
                    shipBy: this.shopeeFormatDateTimePipe.transform(
                        order.ship_by_date,
                        environment.printDateFormat
                    ),
                },
                codDesc: order.cod ? 'เก็บเงินปลายทาง' : 'ไม่ต้องเก็บเงิน',
            };
        });
    }

    private getShippingCarrierLogo(shippingCarrier: string) {
        const shipingCarrier = _toLower(shippingCarrier);
        if (_includes(shipingCarrier, 'kerry')) {
            return environment.kerryLogoUrl;
        } else if (_includes(shipingCarrier, 'ninja van')) {
            return environment.ninjaLogoUrl;
        } else if (_includes(shipingCarrier, 'spx express')) {
            return environment.spxLogoUrl;
        } else if (_includes(shipingCarrier, 'shopee xpress')) {
            return environment.shopeeExpressLogoUrl;
        } else if (_includes(shipingCarrier, 'j&t express')) {
            return environment.jtLogoUrl;
        } else if (_includes(shipingCarrier, 'flash express')) {
            return environment.flashLogoUrl;
        } 
        return '';
    }

    private fillMatchingNumber(orders: any[]) {
        _each(orders, (order) => {
            if (order.order_sn) {
                order.matching = PrintSheetUtils.generateMatchingNumber(
                    `${order.order_sn}`
                );
            } else {
                order.matching = '';
            }
        });
    }

    private fillTrackingNumber(orders: any[], trackingNumbers: any[]) {
        if (!_isEmpty(trackingNumbers)) {
            _each(orders, (order) => {
                _set(
                    order,
                    'trackingNumber',
                    _get(
                        _find(trackingNumbers, { orderSn: order.order_sn }),
                        'trackingNumber'
                    )
                );
            });
        }
    }

    private checkSellerNotes(orders: any[]) {
        this.hasSellerNotes = false;
        _each(orders, (order) => {
            if (order.message_to_seller) {
                this.hasSellerNotes = true;
                return false;
            }
        });
    }

    private fillDateFields(orders: any[]) {
        const dateFields: string[] = [
            'create_time',
            'ship_by_date',
            'update_time',
        ];
        _each(orders, (order) => {
            _each(dateFields, (field: string) => {
                if (order[field]) {
                    const momentObj =
                        this.shopeeFormatDateTimePipe.tryParseToMoment(
                            order[field]
                        );
                    if (moment.isMoment(momentObj)) {
                        _set(order, `$${field}_date`, momentObj.toDate());
                    }
                }
            });
        });
    }

    private mergeLastPrintData(orders: any[], lastPrints: LastPrintResult[]) {
        _each(lastPrints, (lastPrint: LastPrintResult) => {
            const order = _find(orders, { order_sn: lastPrint.orderSn });
            _set(order, 'lastPrintMoment', lastPrint.time);
        });
    }

    private getTabByStatus(orderStatus: OrderStatus): any {
        return _filter(this.orderStatuses, {
            key: orderStatus,
        })[0];
    }

    private selectRowsByTab(): void {
        this.dataGrid.instance.refresh();
        this.dataGrid.instance.clearSelection();
        if (this.selectedTabItem.key === OrderStatus.ReadyToShip) {
            this.dataGrid.instance.selectAll();
        } else if (this.selectedTabItem.key === OrderStatus.Processed) {
            this.selectedRowKeys = _reduce<string[], any>(
                this.orderSource,
                (accum: string[], row: any): string[] => {
                    if (!_get(row, 'lastPrintMoment')) {
                        accum.push(row.order_sn);
                    }
                    return accum;
                },
                []
            );
        }
    }

    private dataGridBeginLoading(): void {
        this.dataGrid.instance.beginCustomLoading(null);
        this.isDataLoading = true;
    }

    private dataGridEndLoading(): void {
        this.dataGrid.instance.endCustomLoading();
        this.isDataLoading = false;
    }

    private getSelectedRowsData() {
        const selectedRowKeys = this.dataGrid.instance.getSelectedRowKeys();
        const selectedRows = _filter(this.orderSource, (order) => {
            return _includes(selectedRowKeys, order.order_sn);
        });
        return selectedRows;
    }
}
