import { Component } from '@angular/core';
import {
    MessageType,
    NotificationService,
    SsmService,
    UnifiedExecService,
    UtilService,
} from 'src/app/shared/services';
import { map } from 'rxjs/operators';
import { get as _get } from 'lodash';

@Component({
    selector: 'app-api-auth-settings',
    templateUrl: './api-auth-settings.component.html',
    styleUrls: ['./api-auth-settings.component.scss'],
})
export class ApiAuthSettingsComponent {
    isLoading = false;

    constructor(
        private ssmService: SsmService,
        private notificationService: NotificationService,
        private unifiedExecService: UnifiedExecService,
        private utilService: UtilService
    ) {}

    navigateLazadaAuth(): void {
        const redirectUriKey = '/arduino4/lazada/callback_url';
        const clientIdKey = '/arduino4/lazada/app_key';
        this.isLoading = true;
        this.ssmService
            .getParameters([redirectUriKey, clientIdKey])
            .pipe(
                map((response) => {
                    const redirectUri = _get(response, redirectUriKey);
                    const clientId = _get(response, clientIdKey);
                    if (redirectUri && clientId) {
                        window.open(
                            `https://auth.lazada.com/oauth/authorize?response_type=code&force_auth=true&redirect_uri=${redirectUri}&client_id=${clientId}`,
                            '_blank'
                        );
                    }
                    return [];
                })
            )
            .subscribe(() => {
                this.isLoading = false;
            });
    }

    getAccessToken(): void {
        this.isLoading = true;
        this.unifiedExecService
            .requestExec(
                'arn:aws:lambda:ap-southeast-1:707498625607:function:lazada-shopee-functions-v-GetLazadaAccessTokenFunc-eiMYfHFZLx5m'
            )
            .subscribe(
                (res) => {
                    this.isLoading = false;
                    if (_get(res, 'response.StatusCode') === 200) {
                        this.notificationService.pushMessage({
                            message: 'Access token saved successfully',
                            messageType: MessageType.success,
                        });
                    } else {
                        const errMessage =
                            this.utilService.errorToString(
                                _get(res, 'response')
                            ) || 'Getting access token failed';
                        this.notificationService.pushMessage({
                            message: JSON.stringify(errMessage),
                            messageType: MessageType.error,
                        });
                    }
                },
                (err) => {
                    this.isLoading = false;
                    this.notificationService.pushMessage({
                        message: this.utilService.errorToString(err),
                        messageType: MessageType.error,
                    });
                }
            );
    }
}
