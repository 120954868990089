import { Pipe, PipeTransform } from '@angular/core';
import { FormatDateTimePipe } from './format-datetime.pipe';
import moment from 'moment';
import { parseInt as _parseInt, isNaN as _isNaN } from 'lodash';

@Pipe({
    name: 'shopeeFormatDateTime',
})
export class ShopeeFormatDateTimePipe
    extends FormatDateTimePipe
    implements PipeTransform
{
    transform(value: any, ...args: any[]): any {
        const parsedObj = this.tryParseToMoment(value);
        if (moment.isMoment(parsedObj)) {
            return this.formatMoment(parsedObj, ...args);
        }
        return parsedObj;
    }

    tryParseToMoment(value: any) {
        if (moment.isMoment(value)) {
            return value;
        } else if (Object.prototype.toString.call(value) === '[object Date]') {
            return moment(value);
        } else {
            let int = _parseInt(value);
            if (!_isNaN(int)) {
                if (int < 10000000000) {
                    int = int * 1000;
                }
                return moment(int);
            }
        }
        return value;
    }
}
