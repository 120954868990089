<div class="dx-card view-wrapper">
    <!-- <div
        style="
            display: flex;
            gap: 20px 10px;
            padding: 0px 10px 10px 10px;
            align-items: baseline;
        "
    >
        <div>
            <dx-button
                stylingMode="contained"
                icon="exportxlsx"
                text="เพิ่มคู่ค้า"
            ></dx-button>
        </div>
        <div>
            <dx-number-box
                [showSpinButtons]="false"
                format="#,##0 Days"
                [min]="2"
                [max]="360"
                label="Calc. Period"
                labelMode="floating"
                [width]="100"
            ></dx-number-box>
        </div>
        <div>
            <dx-number-box
                [showSpinButtons]="false"
                format="#,##0 Days"
                [min]="2"
                [max]="360"
                label="Reordering Lead Time"
                labelMode="floating"
                [width]="150"
            ></dx-number-box>
        </div>
        <div>
            <dx-number-box
                [showSpinButtons]="false"
                format="#,##0 Days"
                [min]="2"
                [max]="360"
                label="Demand for"
                labelMode="floating"
                [width]="100"
            ></dx-number-box>
        </div>
    </div> -->
    <dx-data-grid
        #dataGrid
        [showBorders]="true"
        keyExpr="_id"
        [focusedRowEnabled]="false"
        [columnAutoWidth]="false"
        [allowColumnResizing]="true"
        [showRowLines]="true"
        [rowAlternationEnabled]="false"
        [dataSource]="counterpartyData"
        [hoverStateEnabled]="true"
        [selectedRowKeys]="[]"
    >
        <dxo-selection mode="single"></dxo-selection>
        <dxo-search-panel
            [visible]="true"
            placeholder="ค้นหา"
        ></dxo-search-panel>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
        <dxo-paging [pageSize]="20"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
        <dxo-toolbar>
            <dxi-item location="before">
                <div class="grid-header">ทะเบียนคู่ค้า</div>
            </dxi-item>
            <dxi-item location="before" locateInMenu="auto">
                <dx-drop-down-button
                    stylingMode="text"
                    [useSelectMode]="true"
                    [dropDownOptions]="{ width: 'auto' }"
                ></dx-drop-down-button>
            </dxi-item>
            <dxi-item location="after" locateInMenu="auto">
                <dx-button
                    text="{{ isShowOrderData ? 'Hide' : 'Show' }} Order Data"
                    type="default"
                    stylingMode="contained"
                    (onClick)="loadOrderData()"
                    [disabled]="this.counterpartyData.length === 0"
                ></dx-button>
            </dxi-item>
            <dxi-item location="after" locateInMenu="auto">
                <dx-button
                    text="Add Counterparty"
                    icon="plus"
                    type="default"
                    stylingMode="contained"
                    (onClick)="onOpenDetailClicked()"
                ></dx-button>
            </dxi-item>
            <dxi-item
                location="after"
                locateInMenu="auto"
                showText="inMenu"
                widget="dxButton"
                [options]="{
                    text: 'Refresh',
                    icon: 'refresh',
                    stylingMode: 'text'
                }"
            ></dxi-item>
            <dxi-item location="after" locateInMenu="auto">
                <div class="separator"></div>
            </dxi-item>
            <dxi-item name="exportButton"></dxi-item>
            <dxi-item location="after" locateInMenu="auto">
                <div class="separator"></div>
            </dxi-item>
            <dxi-item name="columnChooserButton" locateInMenu="auto"></dxi-item>
            <dxi-item name="searchPanel" locateInMenu="auto"></dxi-item>
        </dxo-toolbar>
        <div *dxTemplate="let cellData of 'relativeDatetimeCellTemplate'">
            {{ cellData.value | formatRelativeDatetime }}
        </div>
        <div *dxTemplate="let cellData of 'priceTemplate'">
            {{ cellData.value | formatNumber : 2 }}
        </div>

        <dxi-column
            *ngIf="isShowOrderData"
            dataField="lastOrderMoment"
            [width]="140"
            caption="สั่งซื้อล่าสุดเมือ"
            cellTemplate="relativeDatetimeCellTemplate"
        ></dxi-column>
        <dxi-column
            dataField="taxId"
            caption="เลขที่ผู้เสียภาษี"
            [width]="160"
        ></dxi-column>
        <dxi-column
            caption="ประเภทกิจการ"
            [width]="140"
            dataField="dbdType"
        ></dxi-column>
        <dxi-column
            dataField="name"
            caption="ชื่อ"
            [minWidth]="120"
        ></dxi-column>
        <dxi-column
            dataField="branchId"
            [width]="100"
            caption="สาขา"
        ></dxi-column>
        <dxi-column
            *ngIf="isShowOrderData"
            dataField="totalOrderValue"
            [width]="170"
            caption="มูลค่าคำสั่งซื้อรวม"
            dataType="number"
            cellTemplate="priceTemplate"
        ></dxi-column>
        <dxi-column
            *ngIf="isShowOrderData"
            dataField="orderCount"
            [width]="170"
            caption="จำนวนรายการสั่งซื้อ"
            dataType="number"
        ></dxi-column>
    </dx-data-grid>
    <div>
        <app-counterparty-detail [isOpened]="openDetail" [pinned]="true">
        </app-counterparty-detail>
    </div>
</div>
