import { Component, HostBinding } from '@angular/core';
import { AuthService, ScreenService, AppInfoService } from './shared/services';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    @HostBinding('class') get getClass() {
        return Object.keys(this.screen.sizes)
            .filter((cl) => this.screen.sizes[cl])
            .join(' ');
    }

    public isAutorized = false;

    constructor(
        private authService: AuthService,
        private screen: ScreenService,
        public appInfo: AppInfoService,
        private router: Router
    ) {
        this.authService.isLoggedIn.then((isLoggedIn: boolean) => {
            this.isAutorized = isLoggedIn;
            if (!isLoggedIn) {
                this.router.navigate(['/login']);
            }
        });
    }
}
