import {
    Component,
    ViewChild,
    AfterViewInit,
    ChangeDetectorRef,
} from '@angular/core';
import 'devextreme/data/odata/store';
import { LazadaOpenService } from 'src/app/shared/services/lazada/lazada-open.service';
import { OrderStatus } from 'src/app/shared/services/lazada/enums/order-status';
import {
    set as _set,
    get as _get,
    filter as _filter,
    each as _each,
    find as _find,
    trim as _trim,
    map as _map,
    includes as _includes,
    toString as _toString,
    groupBy as _groupBy,
    isEmpty as _isEmpty,
    reduce as _reduce,
} from 'lodash';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { switchMap, mergeMap } from 'rxjs/operators';
import { DxDataGridComponent } from 'devextreme-angular';
import {
    LastPrintPlatform,
    LastPrintResult,
    LastPrintTimeService,
    LexDocService,
    MessageType,
    UtilService,
    NotificationService,
} from 'src/app/shared/services';
import { InvoiceParameter } from 'src/app/shared/services/print-sheet/interfaces/invoice-param';
import { PrintSheetService } from 'src/app/shared/services/print-sheet/print-sheet.service';

import { LazadaFormatDateTimePipe } from 'src/app/shared/pipes';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { PrintSheetUtils } from 'src/app/shared/services/print-sheet/utils';

@Component({
    selector: 'app-lazada-orders',
    templateUrl: './lazada-orders.component.html',
    styleUrls: ['./lazada-orders.component.scss'],
})
export class LazadaOrdersComponent implements AfterViewInit {
    @ViewChild('dataGrid', { static: true })
    private dataGrid: DxDataGridComponent;
    deferSelectedOrderStatus: OrderStatus = OrderStatus.Pending;
    OrderStatus = OrderStatus; // eslint-disable-line @typescript-eslint/naming-convention
    isDataLoading = false;
    hasRemarks = false;
    selectedRowKeys: string[] = [];
    orderSource = [];
    orderStatuses = [
        {
            key: OrderStatus.Unpaid,
            text: 'Unpaid',
        },
        {
            key: OrderStatus.Pending,
            text: 'Pending',
        },
        {
            key: OrderStatus.ReadyToShip,
            text: 'Ready to ship',
        },
        {
            key: OrderStatus.Shipped,
            text: 'Shipped',
        },
        {
            key: OrderStatus.Delivered,
            text: 'Delivered',
        },
        {
            key: OrderStatus.Returned,
            text: 'Returned',
        },
        {
            key: OrderStatus.Failed,
            text: 'Failed',
        },
    ];
    selectedTabItem: {
        key: OrderStatus;
        text: string;
    } = this.getTabByStatus(OrderStatus.Pending);
    readyToShipButtonOptions = {
        text: 'Ready to Ship',
        icon: 'box',
        type: 'default',
        stylingMode: 'contained',
        onClick: this.setReadyToShip.bind(this),
    };
    printInvoiceButtonOptions = {
        text: 'Print Invoices',
        icon: 'print',
        stylingMode: 'outlined',
        onClick: this.printInvoices.bind(this),
    };
    printShippingLabelButtonOptions = {
        text: 'Print Shipping Labels',
        icon: 'print',
        stylingMode: 'outlined',
        onClick: this.printShippingLabels.bind(this),
    };
    printBothButtonOptions = {
        text: 'Print Both',
        icon: 'print',
        type: 'default',
        stylingMode: 'outlined',
        onClick: this.printBoth.bind(this),
    };
    refreshButtonOptions = {
        text: '',
        icon: 'refresh',
        onClick: (): void => {
            this.updateOrderSubject.next();
        },
    };

    private updateOrderSubject = new Subject();

    constructor(
        private cdref: ChangeDetectorRef,
        private lazadaFormatDateTimePipe: LazadaFormatDateTimePipe,
        private lazadaOpenService: LazadaOpenService,
        private lexDocService: LexDocService,
        private printSheetService: PrintSheetService,
        private notificationService: NotificationService,
        private lastprintTimeService: LastPrintTimeService,
        private utilService: UtilService
    ) {}

    ngAfterViewInit(): void {
        this.updateOrderSubject
            .pipe(
                switchMap((orderStatus: OrderStatus) => {
                    this.dataGridBeginLoading();
                    if (!orderStatus) {
                        orderStatus = this.selectedTabItem.key;
                        this.cdref.detectChanges();
                    }
                    return forkJoin([
                        this.lazadaOpenService.getOrderByStatus(orderStatus),
                        orderStatus === OrderStatus.ReadyToShip ||
                        orderStatus === OrderStatus.Shipped ||
                        orderStatus === OrderStatus.Delivered
                            ? this.lastprintTimeService.get(
                                  LastPrintPlatform.lazada
                              )
                            : of([]),
                    ]);
                }),
                switchMap((responses: any[]) => {
                    const orderIds: number[] = _map(
                        responses[0].orders,
                        'order_id'
                    );
                    return forkJoin([
                        of(responses[0]),
                        of(responses[1]),
                        this.lazadaOpenService.getOrderItems(orderIds),
                    ]);
                })
            )
            .subscribe(
                (responses: any[]) => {
                    const orders = responses[0].orders;
                    const lastPrints = responses[1];
                    const orderItemList = responses[2];
                    this.fillOrderItems(orders, orderItemList);
                    this.fillLastPrintData(orders, lastPrints);
                    this.fillMatchingNumber(orders);
                    this.fillDateFields(orders);
                    this.fillNameAddresses(orders);
                    this.computeHasRemark(orders);
                    this.deferSelectedOrderStatus = this.selectedTabItem.key;
                    this.orderSource = orders;
                    this.selectRowsByTab();
                    this.dataGridEndLoading();
                },
                (error) => {
                    console.error(error);
                    this.notificationService.pushMessage({
                        displayTime: 6000,
                        message: this.utilService.errorToString(error),
                        messageType: MessageType.error,
                    });
                    this.dataGridEndLoading();
                }
            );
        this.updateOrderSubject.next();
    }

    orderStatusSelectionChanged(event: any): void {
        const orderStatusKey: OrderStatus = _get(event, 'addedItems[0].key');
        this.updateOrderSubject.next(orderStatusKey);
    }

    private setReadyToShip(): void {
        const selectedOrders = this.getSelectedRowsData();
        if (!_isEmpty(selectedOrders)) {
            this.dataGridBeginLoading();
            of(...selectedOrders)
                .pipe(
                    mergeMap((selectedOrder) => {
                        const orderItemIds: number[] = _map(
                            selectedOrder.orderItems,
                            'order_item_id'
                        );
                        return this.lazadaOpenService.setStatusToPackedByMarketplace(
                            orderItemIds
                        );
                    }),
                    mergeMap((response) => {
                        const responseOrderItems: any[] = _get(
                            response,
                            'order_items'
                        );
                        const trackGroups = _groupBy(
                            responseOrderItems,
                            'tracking_number'
                        );
                        const rtsObservable: Observable<any>[] = _reduce(
                            trackGroups,
                            (accumulator, objList, key) => {
                                accumulator.push(
                                    this.lazadaOpenService.setStatusToReadyToShip(
                                        _map(objList, 'order_item_id'),
                                        _get(objList[0], 'shipment_provider'),
                                        key
                                    )
                                );
                                return accumulator;
                            },
                            []
                        );
                        return forkJoin(rtsObservable);
                    })
                )
                //     concatMap((responses) => {
                //         const responseOrderItems: any[] = [];
                //         _each(responses, (response) => {
                //             _each(
                //                 _get(response, 'order_items'),
                //                 (orderItem) => {
                //                     responseOrderItems.push(orderItem);
                //                 }
                //             );
                //         });
                //         const orderItemIds: number[] = _map(
                //             responseOrderItems,
                //             'order_item_id'
                //         );
                //         const setInvoiceObservables: Observable<any>[] = [];
                //         _each(orderItemIds, (orderItemId) => {
                //             _get(responseOrderItems, 'order_item_id');
                //             setInvoiceObservables.push(
                //                 this.lazadaOpenService.setInvoiceNumber(
                //                     orderItemId
                //                 )
                //             );
                //         });
                //         return forkJoin(setInvoiceObservables);
                //     })
                // )
                .subscribe(
                    () => {},
                    (error) => {
                        this.dataGridEndLoading();
                        this.updateOrderSubject.next();
                        console.error(error);
                        this.notificationService.pushMessage({
                            displayTime: 6000,
                            message: this.utilService.errorToString(error),
                            messageType: MessageType.error,
                        });
                    },
                    () => {
                        this.dataGridEndLoading();
                        this.selectedTabItem = this.getTabByStatus(
                            OrderStatus.ReadyToShip
                        );
                        this.notificationService.pushMessage({
                            displayTime: 6000,
                            message: `Setting ready to ship completed`,
                            messageType: MessageType.success,
                        });
                    }
                );
        }
    }

    private selectRowsByTab(): void {
        this.dataGrid.instance.refresh();
        this.dataGrid.instance.clearSelection();
        if (this.selectedTabItem.key === OrderStatus.Pending) {
            this.dataGrid.instance.selectAll();
        } else if (this.selectedTabItem.key === OrderStatus.ReadyToShip) {
            this.selectedRowKeys = _reduce<string[], any>(
                this.orderSource,
                (accum: string[], row: any): string[] => {
                    if (!_get(row, 'lastPrintMoment')) {
                        accum.push(row.order_id);
                    }
                    return accum;
                },
                []
            );
        }
    }

    private printInvoices(): void {
        this.requestInvoicePrinting(this.getSelectedRowsData());
    }

    private printShippingLabels(): void {
        this.requestShippingLabelPrinting(this.getSelectedRowsData());
    }

    private printBoth(): void {
        const selectedOrders = this.getSelectedRowsData();
        this.requestInvoicePrinting(selectedOrders);
        this.requestShippingLabelPrinting(selectedOrders, false);
    }

    private requestInvoicePrinting(
        selectedOrders: any[],
        saveLastPrint = true
    ): void {
        if (!_isEmpty(selectedOrders)) {
            this.dataGridBeginLoading();
            const invoiceParams: InvoiceParameter[] =
                this.constructInvoicePrintingParams(selectedOrders);
            const selectedOrderIds: number[] = _map(selectedOrders, 'order_id');
            const selectedOrderIdStrings: string[] = _map(
                selectedOrderIds,
                (selectedOrderId) => _toString(selectedOrderId)
            );
            forkJoin([
                this.printSheetService.printInvoice(
                    invoiceParams,
                    'Lazada Invoices'
                ),
                saveLastPrint
                    ? this.lastprintTimeService.put(
                          LastPrintPlatform.lazada,
                          selectedOrderIdStrings
                      )
                    : of([]),
            ]).subscribe(
                () => {
                    this.dataGridEndLoading();
                    this.notificationService.pushMessage({
                        displayTime: 6000,
                        message: `Printed invoices for ${selectedOrders.length} order(s)`,
                        messageType: MessageType.success,
                    });
                    if (saveLastPrint) {
                        this.updateOrderSubject.next();
                    }
                },
                (error) => {
                    this.dataGridEndLoading();
                    console.error(error);
                    this.notificationService.pushMessage({
                        displayTime: 6000,
                        message: this.utilService.errorToString(error),
                        messageType: MessageType.error,
                    });
                }
            );
        }
    }

    private requestShippingLabelPrinting(
        selectedOrders: any[],
        saveLastPrint = true
    ): void {
        if (!_isEmpty(selectedOrders)) {
            this.dataGridBeginLoading();
            const requestShippingLabelHtmlRequests: Observable<string>[] = _map(
                selectedOrders,
                (order): Observable<string> => {
                    const orderItemIds: number[] = _map(
                        order.orderItems,
                        'order_item_id'
                    );
                    return this.lexDocService.requestShippingLabelHtml(
                        orderItemIds,
                        order.matching
                    );
                }
            );
            forkJoin(requestShippingLabelHtmlRequests)
                .pipe(
                    switchMap((shippingLabelHtmlList: string[]) => {
                        return this.printSheetService.printLazadaShippingLabelTemplate(
                            shippingLabelHtmlList,
                            'Lazada Shipping Labels'
                        );
                    }),
                    switchMap((isPrintCompleted: boolean) => {
                        if (!saveLastPrint || !isPrintCompleted) {
                            return of([]);
                        } else {
                            const selectedOrderIds: number[] = _map(
                                selectedOrders,
                                'order_id'
                            );
                            const selectedOrderIdStrings: string[] = _map(
                                selectedOrderIds,
                                (selectedOrderId) => _toString(selectedOrderId)
                            );
                            return this.lastprintTimeService.put(
                                LastPrintPlatform.lazada,
                                selectedOrderIdStrings
                            );
                        }
                    })
                )
                .subscribe(
                    () => {
                        this.dataGridEndLoading();
                        this.notificationService.pushMessage({
                            displayTime: 6000,
                            message: `Printed shipping labels for ${selectedOrders.length} order(s)`,
                            messageType: MessageType.success,
                        });
                        if (saveLastPrint) {
                            this.updateOrderSubject.next();
                        }
                    },
                    (error) => {
                        this.dataGridEndLoading();
                        console.error(error);
                        this.notificationService.pushMessage({
                            displayTime: 6000,
                            message: this.utilService.errorToString(error),
                            messageType: MessageType.error,
                        });
                    }
                );
        }
    }

    private constructInvoicePrintingParams(orders: any): InvoiceParameter[] {
        return _map(orders, (order) => {
            const originalTotalItemPrice = this.utilService.localStringToNumber(
                order.price
            );
            const totalDiscount = this.utilService.localStringToNumber(
                order.voucher
            );
            const netItemPrice =
                originalTotalItemPrice + order.shipping_fee - totalDiscount;
            return {
                sellerInfo: {
                    name: environment.reportSenderName,
                    website: environment.shopLazadaUrl,
                    address: environment.reportSenderAddress,
                    logoUrl: environment.shopLogoUrl,
                },
                customerInfo: {
                    name: order.billingFullName,
                    address: order.billingAddress,
                    orderNo: order.order_id,
                    date: this.lazadaFormatDateTimePipe.transform(
                        order.created_at,
                        environment.printDateFormat
                    ),
                },
                orderInfo: {
                    orderNo: order.order_id,
                    matchingNumber: order.matching,
                    total: originalTotalItemPrice,
                    discount: totalDiscount,
                    shippingCost: order.shipping_fee,
                    netPrice: netItemPrice,
                },
                items: order.groupedOrderItems,
            };
        });
    }

    private getFullAddressString(addressObj: any) {
        const addressFields: string[] = [
            'address1',
            'address2',
            'address3',
            'address4',
            'address5',
            'country',
        ];
        let fullAddressString = '';
        _each(addressFields, (addressField) => {
            const address = _trim(_get(addressObj, addressField));
            if (!_isEmpty(address)) {
                if (_isEmpty(fullAddressString)) {
                    fullAddressString = address;
                } else {
                    fullAddressString = `${fullAddressString}, ${address}`;
                }
            }
        });
        return fullAddressString;
    }

    private getSelectedRowsData() {
        const selectedRowKeys = this.dataGrid.instance.getSelectedRowKeys();
        const selectedRows = _filter(this.orderSource, (order) => {
            return _includes(selectedRowKeys, order.order_id);
        });
        return selectedRows;
    }

    private getOrderFullName(addressObj: any) {
        return `${_get(addressObj, 'first_name')} ${_get(
            addressObj,
            'last_name'
        )}`.trim();
    }

    private fillNameAddresses(orders) {
        _each(orders, (order: any) => {
            _set(
                order,
                'billingFullName',
                this.getOrderFullName(order.address_billing)
            );
            _set(
                order,
                'billingAddress',
                this.getFullAddressString(order.address_billing)
            );
            _set(
                order,
                'shippingFullName',
                this.getOrderFullName(order.address_shipping)
            );
            _set(
                order,
                'shippingAddress',
                this.getFullAddressString(order.address_shipping)
            );
        });
    }

    private fillOrderItems(orders: any[], orderItemList: any[]) {
        _each(orders, (order: any) => {
            const orderItem = _find(orderItemList, {
                order_id: order.order_id, // eslint-disable-line @typescript-eslint/naming-convention
            });

            if (orderItem) {
                _set(order, 'orderItems', orderItem.order_items);
            }

            let itemNo = 0;
            const groupedOrderItems = _groupBy(order.orderItems, 'sku');
            _set(
                order,
                'groupedOrderItems',
                _map(groupedOrderItems, (_orderItems: any[], sku) => {
                    const candidateItem = _orderItems[0];
                    const quantity = _orderItems.length;
                    return {
                        itemNo: ++itemNo,
                        description: PrintSheetUtils.constructItemDescription(
                            sku,
                            candidateItem.name,
                            quantity,
                            _includes(candidateItem.variation, 'เป็นกลาง')
                                ? ''
                                : candidateItem.variation
                        ),
                        unitPrice: this.utilService.localStringToNumber(
                            candidateItem.item_price
                        ),
                        quantity,
                        amount:
                            this.utilService.localStringToNumber(
                                candidateItem.item_price
                            ) * this.utilService.localStringToNumber(quantity),
                    };
                })
            );
        });
    }

    private fillLastPrintData(orders: any[], lastPrints: LastPrintResult[]) {
        _each(orders, (order: any) => {
            const lastPrint = _find(lastPrints, {
                orderSn: _toString(order.order_id),
            });
            _set(order, 'lastPrintMoment', _get(lastPrint, 'time'));
        });
    }

    private fillDateFields(orders: any[]) {
        const dateFields: string[] = ['created_at', 'updated_at'];
        _each(orders, (order) => {
            _each(dateFields, (field: string) => {
                if (order[field]) {
                    const momentObj =
                        this.lazadaFormatDateTimePipe.tryParseToMoment(
                            order[field]
                        );
                    if (moment.isMoment(momentObj)) {
                        _set(order, `$${field}_date`, momentObj.toDate());
                    }
                }
            });
        });
    }

    private fillMatchingNumber(orders: any[]) {
        _each(orders, (order) => {
            if (order.order_id) {
                order.matching = PrintSheetUtils.generateMatchingNumber(
                    `${order.order_id}`
                );
            } else {
                order.matching = '';
            }
        });
    }

    private computeHasRemark(orders: any[]) {
        this.hasRemarks = false;
        _each(orders, (order) => {
            if (_trim(order.remarks)) {
                this.hasRemarks = true;
            }
        });
    }

    private getTabByStatus(orderStatus: OrderStatus): any {
        return _filter(this.orderStatuses, {
            key: orderStatus,
        })[0];
    }

    private dataGridBeginLoading(): void {
        this.dataGrid.instance.beginCustomLoading(null);
        this.isDataLoading = true;
        this.cdref.detectChanges();
    }

    private dataGridEndLoading(): void {
        this.dataGrid.instance.endCustomLoading();
        this.isDataLoading = false;
        this.cdref.detectChanges();
    }
}
