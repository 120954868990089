<div class="dx-card padding-content">
    <dx-toolbar #toolbar>
        <dxi-item location="center" locateInMenu="never">
            <div *dxTemplate>
                <div>
                    <b>&nbsp;Video URL :&nbsp;</b>
                </div>
            </div>
        </dxi-item>
        <dxi-item location="center" locateInMenu="never">
            <div *dxTemplate>
                <dx-text-box
                    #videoUrlTextBox
                    [width]="500"
                    [disabled]="isDataLoading"
                    value=""
                ></dx-text-box>
            </div>
        </dxi-item>
        <dxi-item
            location="center"
            widget="dxButton"
            [options]="saveButtonOptions"
        ></dxi-item>
        <dxi-item location="center" locateInMenu="never">
            <div *dxTemplate>
                <div *ngIf="selectedRowKeys.length > 0" class="toolbar-label">
                    <b>{{ selectedRowKeys.length }}&nbsp;selected</b>
                </div>
            </div>
        </dxi-item>
    </dx-toolbar>
    <dx-tabs
        keyExpr="key"
        [scrollByContent]="false"
        [showNavButtons]="true"
        [items]="tabs"
        (onSelectionChanged)="onTabChanged($event)"
        [(selectedItem)]="selectedTab"
    ></dx-tabs>
    <dx-data-grid
        #dataGrid
        [dataSource]="currentSource"
        [hoverStateEnabled]="true"
        [(selectedRowKeys)]="selectedRowKeys"
        keyExpr="item_id"
    >
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-selection
            mode="multiple"
            selectAllMode="allPages"
            showCheckBoxesMode="onClick"
        ></dxo-selection>
        <dxo-paging [pageSize]="50"> </dxo-paging>
        <dxo-pager
            [visible]="true"
            allowedPageSizes="50"
            displayMode="full"
            [showInfo]="true"
            [showNavigationButtons]="true"
        >
        </dxo-pager>
        <dxo-editing mode="cell" [allowUpdating]="true"> </dxo-editing>
        <dxi-column
            dataField="item_id"
            dataType="number"
            caption="Item ID"
            [width]="120"
            alignment="left"
        ></dxi-column>
        <dxi-column dataField="attributes.name" caption="Name"></dxi-column>
        <dxi-column
            dataField="attributes.video"
            caption="Current Video URL"
            *ngIf="selectedTab.key === 'hasVideo'"
        ></dxi-column>
    </dx-data-grid>
</div>
<dx-load-panel
    #loadPanel
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: '#dataGrid' }"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
>
</dx-load-panel>
