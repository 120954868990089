import {
    Component,
    ViewChild,
    AfterViewInit,
    ChangeDetectorRef,
} from '@angular/core';
import 'devextreme/data/odata/store';
import { exportDataGrid } from 'devextreme/excel_exporter';
import {
    MessageType,
    NotificationService,
    UtilService,
    UnifiedQueryV2Service,
} from 'src/app/shared/services';
import { Workbook } from 'exceljs';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import {
    concat as _concat,
    each as _each,
    filter as _filter,
    get as _get,
} from 'lodash';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'app-price-list',
    templateUrl: './inventory-list.component.html',
    styleUrls: ['./inventory-list.component.scss'],
})
export class InventoryListComponent implements AfterViewInit {
    @ViewChild('dataGrid', { static: true })
    private dataGrid: DxDataGridComponent;

    pricelistSource: any[] = [];
    isDataLoading = false;
    isHideDiscontinued = true;
    discontinuedItemNum = 0;
    hideDiscontinuedProductCheckBoxOptions = {
        text: 'Hide Discontinued',
        value: this.isHideDiscontinued,
        onValueChanged: (e) => {
            this.isHideDiscontinued = e.value;
            this.fetchInventory();
        },
    };
    refreshInventoryButtonOptions = {
        icon: 'refresh',
        stylingMode: 'outlined',
        onClick: this.fetchInventory.bind(this),
    };
    exportExcelButtonOptions = {
        text: 'Export Excel',
        icon: 'exportxlsx',
        stylingMode: 'outlined',
        onClick: this.exportExcel.bind(this),
    };

    constructor(
        private cdref: ChangeDetectorRef,
        private notificationService: NotificationService,
        private utilService: UtilService,
        private unifiedQueryV2Service: UnifiedQueryV2Service
    ) {}

    ngAfterViewInit(): void {
        this.fetchInventory();
    }

    getItemLastUpdated(data): string {
        return moment(data.crawler_last_updated).fromNow();
    }

    private fetchInventory(): void {
        if (!this.isDataLoading) {
            this.isDataLoading = true;
            this.cdref.detectChanges();
            this.dataGrid.instance.beginCustomLoading(null);
            this.pricelistSource.length = 0;
            this.discontinuedItemNum = 0;

            this.unifiedQueryV2Service
                .query({
                    db: 'arduino4',
                    col: 'inventory',
                    query: {
                        is_show: true,
                    },
                    options: {
                        projection: {
                            sku: 1,
                            title: 1,
                            price: 1,
                            stock_status: 1,
                            is_show: 1,
                            remain: 1,
                            sell: 1,
                            crawler_last_updated: 1,
                        },
                    },
                })
                .subscribe(
                    (results: any[]) => {
                        let inventoryRecords =
                            this.decorateInventoryRecord(results);
                        if (this.isHideDiscontinued) {
                            inventoryRecords = _filter(
                                inventoryRecords,
                                (result) => {
                                    return !result.is_discontinued;
                                }
                            );
                        }

                        this.pricelistSource = _concat(
                            this.pricelistSource,
                            inventoryRecords
                        );
                    },
                    (err) => {
                        console.error(err);
                        this.notificationService.pushMessage({
                            displayTime: 6000,
                            message: this.utilService.errorToString(err),
                            messageType: MessageType.error,
                        });
                    }
                )
                .add(() => {
                    this.dataGrid.instance.endCustomLoading();
                    this.isDataLoading = false;
                    this.cdref.detectChanges();
                });
        }
    }

    private decorateInventoryRecord(records: any[]) {
        _each(records, (record) => {
            if (record.stock_status === 'discontinued') {
                record.is_discontinued = true;
                this.discontinuedItemNum++;
            } else {
                record.is_discontinued = false;
            }

            record['pid'] = record['_id'];
            record['price'] = _get(record, 'price.$numberDecimal');
        });
        return records;
    }

    private exportExcel(): void {
        const workbook = new Workbook();
        const sheet = workbook.addWorksheet('Inventory');
        exportDataGrid({
            component: this.dataGrid.instance,
            worksheet: sheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
                FileSaver.saveAs(
                    new Blob([buffer], { type: 'application/octet-stream' }),
                    `Inventory ${moment().format(
                        'YYYY_MM_DD-HH_mm_ss_SS'
                    )}.xlsx`
                );
            });
        });
    }
}
