/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
    UnifiedQueryService,
    UnifiedQueryRequest,
} from './unified-query.service';
import { reduce as _reduce, keys as _keys, get as _get } from 'lodash';
import { map } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PriceListService {
    constructor(private unifiedQueryService: UnifiedQueryService) {}

    public getScanPricelist(attrs: string[]): Observable<object[]> {
        const expAttrNames = this.getAttributeNameExpressions(attrs);
        const request: UnifiedQueryRequest = {
            operation: 'scan',
            parameter: {
                TableName: 'arduino4.inventory',
                ExpressionAttributeNames: expAttrNames,
                ProjectionExpression: _keys(expAttrNames).join(', '),
            },
        };
        return this.unifiedQueryService
            .externalQuery(request)
            .pipe<object[]>(
                map((response: AjaxResponse) =>
                    _get(response.response, 'Items')
                )
            );
    }

    public getProductDetail(pid: number): Observable<any> {
        const request: UnifiedQueryRequest = {
            operation: 'query',
            parameter: {
                TableName: 'arduino4.inventory',
                ExpressionAttributeValues: {
                    ':pid': pid,
                },
                KeyConditionExpression: 'pid = :pid',
            },
        };
        return this.unifiedQueryService
            .externalQuery(request)
            .pipe<object[]>(
                map((response: AjaxResponse) =>
                    _get(response.response, 'Items[0]')
                )
            );
    }

    private getAttributeNameExpressions(attrs: string[]): object {
        const expAttrNames = _reduce<string, object>(
            attrs,
            (accumulator, attribute) => {
                accumulator[`#${attribute}`] = attribute;
                return accumulator;
            },
            {}
        );
        return expAttrNames;
    }
}
