<div class="dx-card padding-content">
    <dx-toolbar #toolbar>
        <dxi-item
            *ngIf="deferSelectedOrderStatus === OrderStatus.ReadyToShip"
            location="before"
            widget="dxButton"
            [options]="confirmDeliverButtonOptions"
            [disabled]="isDataLoading || selectedRowKeys.length === 0"
        ></dxi-item>
        <dxi-item
            *ngIf="
                deferSelectedOrderStatus === OrderStatus.Processed ||
                deferSelectedOrderStatus === OrderStatus.Completed
            "
            location="before"
            widget="dxButton"
            [options]="printBothButtonOptions"
            [disabled]="isDataLoading || selectedRowKeys.length === 0"
        ></dxi-item>
        <dxi-item
            *ngIf="
                deferSelectedOrderStatus === OrderStatus.Processed ||
                deferSelectedOrderStatus === OrderStatus.Completed
            "
            location="before"
            widget="dxButton"
            [options]="printInvoiceButtonOptions"
            [disabled]="isDataLoading || selectedRowKeys.length === 0"
        ></dxi-item>
        <dxi-item
            *ngIf="
                deferSelectedOrderStatus === OrderStatus.Processed ||
                deferSelectedOrderStatus === OrderStatus.Completed
            "
            location="before"
            widget="dxButton"
            [options]="printShippingLabelButtonOptions"
            [disabled]="isDataLoading || selectedRowKeys.length === 0"
        ></dxi-item>
        <dxi-item location="center" text="Shopee"></dxi-item>
        <dxi-item widget="dxButton" location="before" locateInMenu="never">
            <div *dxTemplate>
                <div
                    *ngIf="selectedRowKeys.length > 0 && !isDataLoading"
                    class="toolbar-label"
                >
                    <b>{{ selectedRowKeys.length }}&nbsp;selected</b>
                </div>
            </div>
        </dxi-item>
        <dxi-item
            location="after"
            widget="dxButton"
            [options]="refreshButtonOptions"
            [disabled]="isDataLoading"
        ></dxi-item>
    </dx-toolbar>
    <dx-tabs
        #statusTabs
        [items]="orderStatuses"
        [scrollByContent]="true"
        [showNavButtons]="true"
        [disabled]="isDataLoading"
        (onSelectionChanged)="orderStatusSelectionChanged($event)"
        [(selectedItem)]="selectedTabItem"
        keyExpr="key"
    ></dx-tabs>
    <dx-data-grid
        #dataGrid
        id="shopeeOrderDataGrid"
        [showBorders]="true"
        [dataSource]="orderSource"
        [(selectedRowKeys)]="selectedRowKeys"
        [masterDetail]="{ enabled: true, template: 'shopeeOrderDetails' }"
        [hoverStateEnabled]="true"
        [disabled]="isDataLoading"
        keyExpr="order_sn"
    >
        <div *dxTemplate="let cellData of 'relativeDatetimeCellTemplate'">
            {{ cellData.value | formatRelativeDatetime }}
        </div>
        <div *dxTemplate="let cellData of 'shopeeDatetimeCellTemplate'">
            {{ cellData.value | shopeeFormatDateTime }}
        </div>
        <div *dxTemplate="let row of 'shopeeOrderDetails'">
            <dx-form id="form" [colCount]="4">
                <dxi-item itemType="group" caption="Order Details">
                    <div class="dx-field">
                        <div class="dx-field-label">Order SN</div>
                        <div class="dx-field-value-static">
                            {{ row.data.order_sn }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="dx-field-label">Status</div>
                        <div class="dx-field-value-static">
                            {{ row.data.order_status }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="dx-field-label">Shipping Carrier</div>
                        <div class="dx-field-value-static">
                            {{ row.data.shipping_carrier }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="dx-field-label">Message to seller</div>
                        <div class="dx-field-value-static">
                            {{ row.data.message_to_seller }}
                        </div>
                    </div>
                </dxi-item>
                <dxi-item itemType="group" caption="Date">
                    <div class="dx-field">
                        <div class="dx-field-label">Creation Time</div>
                        <div class="dx-field-value-static">
                            {{ row.data.create_time | shopeeFormatDateTime }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="dx-field-label">Update Time</div>
                        <div class="dx-field-value-static">
                            {{ row.data.update_time | shopeeFormatDateTime }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="dx-field-label">Ship By</div>
                        <div class="dx-field-value-static">
                            {{ row.data.ship_by_date | shopeeFormatDateTime }}
                        </div>
                    </div>
                </dxi-item>
                <dxi-item itemType="group" caption="Buyer Info">
                    <div class="dx-field">
                        <div class="dx-field-label">Buyer User</div>
                        <div class="dx-field-value-static">
                            {{ row.data.buyer_username }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="dx-field-label">Recipient</div>
                        <div class="dx-field-value-static">
                            {{ row.data.recipient_address.name }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="dx-field-label">Delivery Address</div>
                        <div class="dx-field-value-static">
                            {{ row.data.recipient_address.full_address }}
                        </div>
                    </div>
                </dxi-item>
                <dxi-item itemType="group" caption="Payment">
                    <div class="dx-field">
                        <div class="dx-field-label">Payment Method</div>
                        <div class="dx-field-value-static">
                            {{ row.data.payment_method }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="dx-field-label">Total Amount</div>
                        <div class="dx-field-value-static">
                            {{ row.data.total_amount | formatNumber : 2 }}
                        </div>
                    </div>
                </dxi-item>
            </dx-form>
            <dx-data-grid
                [showBorders]="true"
                [dataSource]="row.data.item_list"
            >
                <dxi-column
                    dataField="item_sku"
                    caption="SKU"
                    [width]="120"
                ></dxi-column>
                <dxi-column
                    dataField="item_name"
                    caption="Name"
                    [minWidth]="120"
                ></dxi-column>
                <dxi-column
                    dataField="model_name"
                    caption="Variant"
                    [width]="120"
                ></dxi-column>
                <dxi-column
                    dataField="weight"
                    caption="Weight"
                    [width]="120"
                ></dxi-column>
                <dxi-column
                    dataField="model_quantity_purchased"
                    caption="Quantity"
                    [width]="120"
                ></dxi-column>
                <dxi-column
                    dataField="model_original_price"
                    caption="Original Price"
                    [width]="120"
                    dataType="number"
                    cellTemplate="priceTemplate"
                ></dxi-column>
                <dxi-column
                    dataField="model_discounted_price"
                    caption="Discounted Price"
                    [width]="120"
                    dataType="number"
                    cellTemplate="priceTemplate"
                ></dxi-column>
                <div *dxTemplate="let cellData of 'priceTemplate'">
                    {{ cellData.value | formatNumber : 2 }}
                </div>
            </dx-data-grid>
        </div>

        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-selection
            selectAllMode="allPages"
            showCheckBoxesMode="onClick"
            mode="{{
                deferSelectedOrderStatus === OrderStatus.ReadyToShip ||
                deferSelectedOrderStatus === OrderStatus.Processed ||
                deferSelectedOrderStatus === OrderStatus.Completed
                    ? 'multiple'
                    : 'none'
            }}"
        ></dxo-selection>
        <dxo-paging [pageSize]="18"> </dxo-paging>
        <dxo-pager
            [visible]="true"
            [allowedPageSizes]="18"
            displayMode="full"
            [showInfo]="true"
            [showNavigationButtons]="true"
        >
        </dxo-pager>
        <dxi-column
            dataField="lastPrintMoment"
            caption="Last Print"
            [width]="140"
            cellTemplate="relativeDatetimeCellTemplate"
            *ngIf="
                deferSelectedOrderStatus === OrderStatus.Processed ||
                deferSelectedOrderStatus === OrderStatus.Completed
            "
        ></dxi-column>
        <dxi-column
            *ngIf="hasSellerNotes"
            caption="Seller Note"
            dataField="message_to_seller"
            sortOrder="desc"
            [minWidth]="200"
        ></dxi-column>
        <dxi-column
            dataField="$create_time_date"
            dataType="datetime"
            caption="Creation Time"
            [width]="150"
            cellTemplate="shopeeDatetimeCellTemplate"
            alignment="left"
        ></dxi-column>
        <dxi-column
            dataField="order_sn"
            caption="Order SN"
            [width]="150"
        ></dxi-column>
        <dxi-column
            dataField="matching"
            caption="Matching"
            [width]="100"
        ></dxi-column>
        <dxi-column
            dataField="buyer_username"
            caption="Buyer"
            [minWidth]="200"
        ></dxi-column>
        <dxi-column
            dataField="payment_method"
            caption="Payment Method"
            [width]="200"
        ></dxi-column>
        <dxi-column
            dataField="total_amount"
            caption="Amount"
            [width]="110"
            dataType="number"
            cellTemplate="priceTemplate"
        ></dxi-column>
        <div *dxTemplate="let cellData of 'priceTemplate'">
            {{ cellData.value | formatNumber : 2 }}
        </div>
        <dxi-column
            dataField="shipping_carrier"
            caption="Carrier"
            [width]="120"
        ></dxi-column>
        <dxi-column
            *ngIf="
                !(
                    deferSelectedOrderStatus === OrderStatus.Unpaid ||
                    deferSelectedOrderStatus === OrderStatus.ReadyToShip
                )
            "
            dataField="trackingNumber"
            caption="Tracking No"
            [width]="150"
        ></dxi-column>
        <dxi-column
            dataField="$ship_by_date_date"
            dataType="datetime"
            caption="Ship by"
            [width]="160"
            cellTemplate="shopeeDatetimeCellTemplate"
            alignment="left"
        >
        </dxi-column>
        <dxo-summary>
            <dxi-total-item
                column="order_sn"
                summaryType="count"
                valueFormat="#,##0"
                displayFormat="Total {0} Order(s)"
            >
            </dxi-total-item>
            <dxi-total-item
                column="total_amount"
                summaryType="sum"
                valueFormat="฿ #,##0.00"
                displayFormat="{0}"
            >
            </dxi-total-item>
        </dxo-summary>
    </dx-data-grid>
</div>
<app-shopee-delivery-popup
    #deliveryPopup
    (popupClose)="onConfirmDeliverPopupClosed($event)"
></app-shopee-delivery-popup>
