import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocType } from './enums/doc-type';
import { LazadaOpenService } from './lazada-open.service';
import { get as _get } from 'lodash';
@Injectable({
    providedIn: 'root',
})
export class LexDocService {
    constructor(private lazadaOpenService: LazadaOpenService) {}

    private static b64DecodeUnicode(base64: string): string {
        const text = atob(base64);
        const length = text.length;
        const bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
            bytes[i] = text.charCodeAt(i);
        }
        const decoder = new TextDecoder(); // default is utf-8
        return decoder.decode(bytes);
    }

    public requestShippingLabelHtml(
        orderItemIds: number[],
        matchingNumber: string
    ): Observable<string> {
        return this.lazadaOpenService
            .getDocument(DocType.shippingLabel, orderItemIds)
            .pipe(
                map<unknown, string>((response: unknown) => {
                    const htmlBase64String = _get(response, 'document.file');
                    if (htmlBase64String) {
                        const fullHtml: string =
                            LexDocService.b64DecodeUnicode(htmlBase64String);
                        const shippingLabelHtml =
                            this.extractShippingLabelHtml(fullHtml);
                        const shippingLabelWithMatching =
                            this.addMatchingNumber(
                                shippingLabelHtml,
                                matchingNumber
                            );
                        return shippingLabelWithMatching;
                    } else {
                        throw new Error(JSON.stringify(response));
                    }
                })
            );
    }

    private extractShippingLabelHtml(fullHtml: string): string {
        const element = document.createElement('html');
        element.innerHTML = fullHtml;
        const shippingLabelElement = element.querySelector(
            'body div:first-child'
        );
        if (shippingLabelElement) {
            return shippingLabelElement.innerHTML;
        }
        throw new Error('Cannot find shipping label element');
    }

    private addMatchingNumber(html: string, matchingNumber: string): string {
        const matchingElementString = `<div class="brother-div" data-custom="Variable" data-align="middle" style=" height: 8mm; word-break: keep-all; zindex: 16; position: absolute; left: 106mm; font-size: 20pt; z-index: 16; font-weight: bold; color: black; font-family: Tahoma; text-align: center; display: flex; align-items: center; justify-content: center; width: 20.76mm; line-height: 28pt; top: 86.3mm; border-style: solid;"> <div>${matchingNumber}</div></div>`;
        return html + matchingElementString;
    }
}
