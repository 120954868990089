import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    DxButtonModule,
    DxDataGridModule,
    DxDropDownButtonModule,
    DxFormModule,
    DxLoadIndicatorModule,
    DxLoadPanelModule,
    DxScrollViewModule,
    DxSwitchModule,
    DxTextBoxModule,
    DxToolbarModule,
    DxValidationGroupModule,
} from 'devextreme-angular';
import {
    FormatNumberPipeModule,
    FormatRelativeDatetimePipeModule,
} from 'src/app/shared/pipes';
import { CounterpartyListComponent } from './components/counterparty-list/counterparty-list.component';
import { CounterpartyDetailComponent } from './components/counterparty-detail/counterparty-detail.component';
import { CounterpartyService } from './services/counterparty.service';
import { CounterpartyFinderComponent } from './components/counterparty-finder/counterparty-finder.component';
import { CounterpartyCardComponent } from './components/library/counterparty-card/counterparty-card.component';

@NgModule({
    imports: [
        DxButtonModule,
        DxDataGridModule,
        DxDropDownButtonModule,
        DxLoadPanelModule,
        DxTextBoxModule,
        DxToolbarModule,
        DxScrollViewModule,
        DxValidationGroupModule,
        DxSwitchModule,
        DxLoadIndicatorModule,
        DxFormModule,
        CommonModule,
        FormatRelativeDatetimePipeModule,
        FormatNumberPipeModule,
    ],
    declarations: [
        CounterpartyListComponent,
        CounterpartyDetailComponent,
        CounterpartyFinderComponent,
        CounterpartyCardComponent,
    ],
    exports: [CounterpartyFinderComponent],
    providers: [CounterpartyService],
})
export class CounterpartyModule {}
