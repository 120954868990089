import { Injectable } from '@angular/core';
import { AjaxResponse } from 'rxjs/ajax';
import { environment } from '../../../environments/environment';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseRequester } from './common/base-requester';
import moment, { Moment } from 'moment';
import { UnifiedQueryV2Service } from './unified-query-v2.service';
import {
    get as _get,
    set as _set,
    find as _find,
    each as _each,
    isNil as _isNil,
    isNil,
} from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class SaleAnalyticService extends BaseRequester {
    constructor(private unifiedQueryV2Service: UnifiedQueryV2Service) {
        super();
    }

    get(platforms: AnalyticPlatform[]): Observable<any> {
        const inventoryDataObservable = this.unifiedQueryV2Service.query({
            db: 'arduino4',
            col: 'inventory',
            query: {
                is_show: true,
                stock_status: { $ne: 'discontinued' },
            },
            options: {
                projection: {
                    sku: 1,
                    title: 1,
                    price: 1,
                    remain: 1,
                },
            },
        });

        const currentDate = moment();
        const saleSinceObservables = {
            last1MDate: this.getSaleSince(
                currentDate.subtract(1, 'month'),
                platforms
            ),
            last3MDate: this.getSaleSince(
                currentDate.subtract(3, 'months'),
                platforms
            ),
            last6MDate: this.getSaleSince(
                currentDate.subtract(6, 'month'),
                platforms
            ),
            lastYtdDate: this.getSaleSince(
                currentDate.startOf('year'),
                platforms
            ),
            last1YDate: this.getSaleSince(
                currentDate.subtract(1, 'year'),
                platforms
            ),
            last2YDate: this.getSaleSince(
                currentDate.subtract(2, 'years'),
                platforms
            ),
            last3YDate: this.getSaleSince(
                currentDate.subtract(3, 'years'),
                platforms
            ),
            last10YDate: this.getSaleSince(
                currentDate.subtract(10, 'years'),
                platforms
            ),
        };
        const saleSinceData = forkJoin(saleSinceObservables);
        const lastSaleDateData = this.getAllProductLastSaleDate(platforms);
        return forkJoin({
            inventoryData: inventoryDataObservable,
            saleSinceData: saleSinceData,
            lastSaleDateData: lastSaleDateData,
        }).pipe(
            map((result: AnalyticCombine) => {
                const data = [];
                _each(result.inventoryData, (inventoryRecord) => {
                    const saleSince = {};
                    _each(saleSinceObservables, (_, key) => {
                        const saleNumber: number = this.getSale(
                            result.saleSinceData[key],
                            inventoryRecord['_id']
                        );
                        _set(saleSince, key, saleNumber);
                    });
                    inventoryRecord['price'] = parseFloat(
                        _get(inventoryRecord, 'price.$numberDecimal')
                    );
                    inventoryRecord['saleSince'] = saleSince;
                    inventoryRecord['lastSaleDate'] = this.getLastSaleDate(
                        result.lastSaleDateData,
                        inventoryRecord['_id']
                    );
                    data.push(inventoryRecord);
                });
                return data;
            })
        );
    }

    private getSale(saleSinceData: SaleSinceRecord[], pid: number): number {
        const saleRecord: SaleSinceRecord = _find(saleSinceData, {
            _id: pid,
        });
        if (saleRecord) {
            const sale: string | undefined = _get(
                saleRecord,
                'total_sale.$numberDecimal'
            );
            if (!_isNil(sale)) {
                return parseFloat(sale);
            }
        }
        return 0;
    }

    private getLastSaleDate(
        lastSaleDateData: LastSaleRecord[],
        pid: number
    ): Moment | null {
        const saleDateObj: LastSaleRecord = _find(lastSaleDateData, {
            _id: pid,
        });
        const saleDateIso: string = _get(saleDateObj, 'last_sale');
        if (!isNil(saleDateIso)) {
            const saleDate: Moment = moment(saleDateIso);
            if (saleDate.isValid()) {
                return saleDate;
            }
        }
        return null;
    }

    private getSaleSince(
        date: Moment,
        platforms: AnalyticPlatform[]
    ): Observable<any> {
        return this.internalPostRequest<SaleSinceRequest>(
            environment.saleSinceEndpoint,
            {
                after_iso_date: date.toISOString(),
                platforms: platforms,
            }
        ).pipe(map(this.serviceHandler));
    }

    private getAllProductLastSaleDate(
        platforms: AnalyticPlatform[]
    ): Observable<any> {
        return this.internalPostRequest<LastSaleRequest>(
            environment.lastSaleEndpoint,
            { platforms }
        ).pipe(map(this.serviceHandler));
    }

    private serviceHandler(response: AjaxResponse): any {
        if (response.response.errorMessage) {
            throw new Error(
                `[${response.response.errorType}]: ${response.response.errorMessage}`
            );
        } else {
            return response.response;
        }
    }
}

export enum AnalyticPlatform {
    LnwShop = 'LnwShop',
    Lazada = 'Lazada',
    Shopee = 'Shopee',
}

interface SaleSinceRequest {
    after_iso_date: string;
    query?: object;
    platforms: ('LnwShop' | 'Lazada' | 'Shopee')[];
}

interface LastSaleRequest {
    query?: object;
    platforms: ('LnwShop' | 'Lazada' | 'Shopee')[];
}

interface AnalyticCombine {
    inventoryData: any;
    saleSinceData: SaleSinceRecord[];
    lastSaleDateData: LastSaleRecord[];
}

interface SaleSinceRecord {
    _id: number;
    total_sale: {
        $numberDecimal: string;
    };
}

interface LastSaleRecord {
    _id: number;
    last_sale: string;
}
