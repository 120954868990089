<header>
    <dx-toolbar class="header-toolbar">
        <dxi-item
            *ngIf="menuToggleEnabled"
            location="before"
            widget="dxButton"
            cssClass="menu-button"
            [options]="{
                icon: 'menu',
                stylingMode: 'text',
                onClick: toggleMenu
            }"
        >
        </dxi-item>
        <dxi-item
            location="before"
            cssClass="header-title"
            *ngIf="title"
            [text]="title"
        >
        </dxi-item>
        <dxi-item location="before" text="v{{ packageInfo.version }}"> </dxi-item>
        <dxi-item
            location="after"
            locateInMenu="auto"
            menuItemTemplate="menuItem"
        >
            <div *dxTemplate="let data of 'item'">
                <dx-button
                    class="user-button authorization"
                    width="210px"
                    height="100%"
                    stylingMode="text"
                >
                    <div *dxTemplate="let data of 'content'">
                        <app-user-panel
                            [menuItems]="userMenuItems"
                            menuMode="context"
                        ></app-user-panel>
                    </div>
                </dx-button>
            </div>
        </dxi-item>
        <div *dxTemplate="let data of 'menuItem'">
            <app-user-panel
                [menuItems]="userMenuItems"
                menuMode="list"
            ></app-user-panel>
        </div>
    </dx-toolbar>
</header>
