<div class="dx-card padding-content">
    <div
        style="
            display: flex;
            gap: 20px 10px;
            padding: 10px 10px 10px 10px;
            align-items: baseline;
        "
    >
        <div>
            <dx-number-box
                [disabled]="dataLoading"
                [showSpinButtons]="false"
                format="#,##0 Days"
                [min]="2"
                [max]="360"
                label="Calc. Period"
                labelMode="floating"
                [(value)]="calculationDays"
                [width]="100"
                (onEnterKey)="loadReorderingData()"
            ></dx-number-box>
        </div>
        <div>
            <dx-number-box
                [disabled]="dataLoading"
                [showSpinButtons]="false"
                format="#,##0 Days"
                [min]="2"
                [max]="360"
                label="Reordering Lead Time"
                labelMode="floating"
                [(value)]="reorderingLeadTimeDays"
                [width]="150"
                (onEnterKey)="loadReorderingData()"
            ></dx-number-box>
        </div>
        <div>
            <dx-number-box
                [disabled]="dataLoading"
                [showSpinButtons]="false"
                format="#,##0 Days"
                [min]="2"
                [max]="360"
                label="Demand for"
                labelMode="floating"
                [(value)]="demandForDays"
                [width]="100"
                (onEnterKey)="loadReorderingData()"
            ></dx-number-box>
        </div>

        <div
            style="margin-left: auto; margin-right: auto; text-align: center"
            *ngIf="belowRopItemCount > 0"
        >
            <span style="font-weight: medium"
                >Low Stock {{ belowRopItemCount }} Items</span
            >
        </div>
        <div style="margin-left: auto; margin-right: 10px">
            <dx-check-box
                [disabled]="dataLoading"
                [(value)]="hideDesisted"
                (onValueChanged)="onFilterSelectionChanged()"
                text="Hide desisted products ({{
                    desistedItemCount
                }}&nbsp;items)"
            ></dx-check-box>
        </div>
        <div style="margin-right: 10px">
            <dx-check-box
                [disabled]="dataLoading"
                [(value)]="showOnlyBelowRop"
                (onValueChanged)="onFilterSelectionChanged()"
                text="Show only stock below ROP ({{
                    belowRopItemCount
                }}&nbsp;items)"
            ></dx-check-box>
        </div>
        <div>
            <dx-button
                [disabled]="dataLoading"
                stylingMode="contained"
                icon="exportxlsx"
                (onClick)="exportExcel()"
            ></dx-button>
        </div>
        <div>
            <dx-button
                [disabled]="dataLoading"
                stylingMode="contained"
                icon="download"
                type="default"
                text="Update"
                (onClick)="loadReorderingData()"
            ></dx-button>
        </div>
    </div>
    <dx-data-grid
        #dataGrid
        [dataSource]="dataSource"
        [showBorders]="true"
        keyExpr="_id"
        [focusedRowEnabled]="false"
        [columnAutoWidth]="false"
        [allowColumnResizing]="true"
        [showRowLines]="true"
        [rowAlternationEnabled]="true"
        (onCellPrepared)="onCellPrepared($event)"
    >
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-filter-builder> </dxo-filter-builder>
        <dxo-filter-builder-popup [width]="400" title="Inventory Filter">
        </dxo-filter-builder-popup>
        <dxo-paging [pageSize]="20"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <div *dxTemplate="let cellData of 'relativeDatetimeCellTemplate'">
            {{ cellData.value | formatRelativeDatetime }}
        </div>
        <dxi-column
            [width]="120"
            caption="Last Updated"
            dataField="crawler_last_updated"
            [calculateCellValue]="convertLastUpdateDatetimeToMoment"
            cellTemplate="relativeDatetimeCellTemplate"
            ><dxo-format formatter="formatLastUpdated"> </dxo-format
        ></dxi-column>
        <dxi-column dataField="sku" caption="SKU" [width]="100"></dxi-column>
        <dxi-column
            dataField="title"
            caption="Product Title"
            [minWidth]="120"
        ></dxi-column>
        <dxi-column
            dataField="class"
            caption="Class"
            [width]="85"
            alignment="center"
        ></dxi-column>
        <dxi-column
            dataField="price.$numberDecimal"
            [width]="95"
            caption="Unit Price"
            dataType="number"
            cellTemplate="priceTemplate"
        ></dxi-column>
        <dxi-column
            dataField="avg_sale_per_day"
            [width]="160"
            caption="Avg Sale / Day ({{ calculationDays }}D)"
            dataType="number"
            cellTemplate="priceTemplate"
        ></dxi-column>
        <dxi-column
            dataField="total_sale"
            caption="1Y Rev."
            [width]="90"
            dataType="number"
            cellTemplate="priceTemplate"
        ></dxi-column>
        <dxi-column
            dataField="demand"
            [width]="125"
            caption="Demand ({{ demandForDays }}D)"
            dataType="number"
            cellTemplate="numTemplate"
        ></dxi-column>
        <dxi-column
            dataField="remain"
            [width]="120"
            caption="Current Stock"
            dataType="number"
            cellTemplate="numTemplate"
        ></dxi-column>
        <dxi-column
            dataField="rop"
            [width]="100"
            caption="ROP"
            dataType="number"
            cellTemplate="priceTemplate"
            sortOrder="desc"
        ></dxi-column>
        <div *dxTemplate="let cellData of 'priceTemplate'">
            {{ cellData.value | formatNumber : 2 }}
        </div>
        <div *dxTemplate="let cellData of 'numTemplate'">
            {{ cellData.value | formatNumber : 0 }}
        </div>
    </dx-data-grid>
</div>
