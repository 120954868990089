import { Injectable } from '@angular/core';
import {
    map as _map,
    toNumber as _toNumber,
    get as _get,
    isFinite as _isFinite,
} from 'lodash';
import moment from 'moment';
import { ProductInfo, TaxInvoiceInput } from '../models/invoice-input';
import { branchList } from '../models/constants';

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    private dateTimeMessageMomentFormat = 'YYYY-MM-DD HH:mm:ss';

    constructor() {}

    fillInputFromMessage(message: any, target: TaxInvoiceInput): void {
        this.fillOrderInfo(message, target);
        this.fillCustomerInfo(message, target);
        this.fillShippingInfo(message, target);
        this.fillProducts(message, target);
    }

    private fillOrderInfo(message, target: TaxInvoiceInput): void {
        target.invoiceInfo.refOrderNum = _get(message, 'id', '');

        const orderDtFromMsg: string = _get(message, 'order_time', '');
        if (orderDtFromMsg) {
            target.invoiceInfo.date = this.parseMessageDateTime(orderDtFromMsg);
        }

        const discount: number = _toNumber(_get(message, 'discount_price', ''));
        if (_isFinite(discount)) {
            target.invoiceInfo.discount = discount;
        }
    }

    private fillCustomerInfo(message, target: TaxInvoiceInput): void {
        const receiptInfo = _get(message, 'receipt');
        if (receiptInfo) {
            target.customerInfo.name = _get(receiptInfo, 'full_name', '');
            target.customerInfo.taxId = _get(receiptInfo, 'tax_id', '');

            const branchIdFromMsg = _get(receiptInfo, 'branch_id', NaN);
            const branchIdNum: number = _toNumber(branchIdFromMsg);
            if (_isFinite(branchIdNum)) {
                if (branchIdNum < branchList.length) {
                    target.customerInfo.branch = branchList[branchIdNum];
                } else {
                    target.customerInfo.branch = `สาขาที่ ${branchIdFromMsg}`;
                }
            }

            target.customerInfo.useCustomAddress = true;
            target.customerInfo.customAddress = _get(
                receiptInfo,
                'address',
                ''
            );
        } else {
            const receiverInfo = _get(message, 'receiver');
            if (receiverInfo) {
                target.customerInfo.name = _get(receiverInfo, 'full_name', '');

                target.customerInfo.useCustomAddress = false;
                target.customerInfo.postCode = _get(
                    receiverInfo,
                    'zipcode',
                    ''
                );
                target.customerInfo.province = _get(
                    receiverInfo,
                    'province',
                    ''
                );
                target.customerInfo.district = _get(
                    receiverInfo,
                    'district',
                    ''
                );
                target.customerInfo.subDistrict = _get(
                    receiverInfo,
                    'subdistrict',
                    ''
                );
                target.customerInfo.address1 = _get(
                    receiverInfo,
                    'address',
                    ''
                );
            }
        }
    }

    private fillShippingInfo(message, target: TaxInvoiceInput): void {
        const discount: number = _toNumber(_get(message, 'shipping_price', ''));
        if (_isFinite(discount)) {
            target.invoiceInfo.shippingCost = discount;
        }
    }

    private fillProducts(message, target: TaxInvoiceInput): void {
        const productsFromMsg: string = _get(message, 'products', '');
        if (productsFromMsg) {
            target.productItems = _map<any, ProductInfo>(
                productsFromMsg,
                (msgItem, index: number) => {
                    let title: string = _get(msgItem, 'title', '');
                    const sku: string = _get(msgItem, 'code', '');
                    if (sku) {
                        title = `[${sku}] ${title}`;
                    }
                    const quantity: string = _get(msgItem, 'quantity', '');
                    const price: string = _get(msgItem, 'price', '');
                    const amount: string = _get(msgItem, 'amount', '');
                    const id: string = _get(msgItem, 'id', '');
                    return {
                        sku,
                        itemNo: index + 1,
                        pid: _toNumber(id),
                        description: title,
                        quantity: _toNumber(quantity),
                        unitPrice: _toNumber(price),
                        amount: _toNumber(amount),
                    };
                }
            );
        }
    }

    private parseMessageDateTime(dtFromMsg: string): Date {
        const momentObj = moment(dtFromMsg, this.dateTimeMessageMomentFormat);
        if (momentObj.isValid) {
            return momentObj.toDate();
        }
        return null;
    }
}
