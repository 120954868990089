import { from } from 'rxjs';
import { first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Auth } from 'aws-amplify';
import { CognitoUserSession, CognitoUser } from 'amazon-cognito-identity-js';

@Injectable()
export class AuthService {
    constructor(private router: Router) {}

    logIn(login: string, passord: string): Promise<CognitoUser> {
        return Auth.signIn(login, passord);
    }

    logOut() {
        from(Auth.signOut())
            .pipe(first())
            .subscribe((a) => {
                this.router.navigate(['/login']);
            });
    }

    changePassword(oldPassword: string, newPassword: string) {
        return Auth.currentAuthenticatedUser().then((user) => {
            return Auth.changePassword(user, oldPassword, newPassword);
        });
    }

    completeNewPassword(user: CognitoUser, newPassword: string) {
        return Auth.completeNewPassword(user, newPassword, {});
    }

    requestResetPasswordVerificationCode(username: string) {
        return Auth.forgotPassword(username);
    }

    confirmForgotPassword(code: string, username: string, newPassword: string) {
        return Auth.forgotPasswordSubmit(username, code, newPassword);
    }

    get isLoggedIn(): Promise<boolean> {
        return Auth.currentSession()
            .then((session: CognitoUserSession) => {
                return true;
            })
            .catch((error) => {
                console.warn(error);
                return false;
            });
    }

    get userInfo(): Promise<any> {
        return Auth.currentUserInfo();
    }
}

@Injectable()
export class AuthGuardService  {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        const isLoginForm = route.routeConfig.path === 'login';
        return this.authService.isLoggedIn.then((isLoggedIn: boolean) => {
            if (isLoggedIn && isLoginForm) {
                this.router.navigate(['/']);
                return false;
            }
            if (!isLoggedIn && !isLoginForm) {
                this.router.navigate(['/login']);
            }
            return isLoggedIn || isLoginForm;
        });
    }
}
