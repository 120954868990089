import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseRequester } from './common/base-requester';
import { get as _get, map as _map } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class LastPrintTimeService extends BaseRequester {
    constructor() {
        super();
    }

    get(lastPrintPlatform: LastPrintPlatform) {
        return this.internalPostRequest<LastPrintTimeRequest>(
            environment.lastPrintEndPoint,
            {
                operation: 'get',
                parameter: { platform: lastPrintPlatform },
            }
        ).pipe(
            map((response) => {
                const responseItems = _get(response, 'response');
                return _map<any, LastPrintResult>(responseItems, (item) => {
                    return {
                        time: moment.unix(item.v1),
                        orderSn: item.u_sort_key,
                        platformKey: item.u_hash_key,
                    };
                });
            })
        );
    }

    put(lastPrintPlatform: LastPrintPlatform, orderSns: string[]) {
        return this.internalPostRequest<LastPrintTimeRequest>(
            environment.lastPrintEndPoint,
            {
                operation: 'put',
                parameter: {
                    platform: lastPrintPlatform,
                    orderSnList: orderSns,
                },
            }
        );
    }
}

export interface LastPrintTimeRequest {
    operation: 'get' | 'put';
    parameter: {
        platform: LastPrintPlatform;
        orderSnList?: string[];
    };
}

export enum LastPrintPlatform {
    shopee = 'shopee',
    lazada = 'lazada',
}

export interface LastPrintResult {
    time: Moment;
    orderSn: string;
    platformKey: string;
}
