import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-quick-orders',
    templateUrl: './quick-orders.component.html',
    styleUrls: ['./quick-orders.component.scss'],
})
export class QuickOrdersComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
