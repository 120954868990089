import { Injectable } from '@angular/core';
import { AjaxResponse } from 'rxjs/ajax';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseRequester } from './common/base-requester';
import { Moment } from 'moment';

@Injectable({
    providedIn: 'root',
})
export class AbcAnalysisService extends BaseRequester {
    constructor() {
        super();
    }

    get(afterDate: Moment): Observable<any> {
        return this.internalPostRequest<AbcAnalysisRequest>(
            environment.abcAnalysisEndpoint,
            {
                after_iso_date: afterDate.toISOString(),
            }
        ).pipe(map(this.serviceHandler));
    }

    private serviceHandler(response: AjaxResponse): AbcAnalysisResponse {
        if (response.response.errorMessage) {
            throw new Error(
                `[${response.response.errorType}]: ${response.response.errorMessage}`
            );
        } else {
            return response.response;
        }
    }
}

interface AbcAnalysisRequest {
    after_iso_date: string;
}

export interface AbcAnalysisResponse {
    pid: number;
    class: 'A' | 'B' | 'C' | 'D';
    total_sale: number;
}
