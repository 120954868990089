<div class="card">
    <div class="company-name">{{ ctpVm.companyName }}</div>
    <div class="tax-branch">
        <span class="tax-id">{{ ctpVm.taxId }}</span
        >&nbsp;&nbsp;&nbsp;
        <span class="branch">{{ ctpVm.branch }}</span>
    </div>
    <div class="address">
        {{ ctpVm.address }}
    </div>

    <div class="foot-panel">
        <div>
            <dx-button
                text="เลือก"
                type="default"
                stylingMode="outlined"
                (onClick)="onSelectButtonClick()"
            ></dx-button>
        </div>
        <div>
            <img
                *ngIf="ctpVm.isDbdSource"
                width="55px"
                src="assets/dbd_logo.png"
            />
        </div>
    </div>
</div>
