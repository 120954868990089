<div #content class="content-frame">
    <div class="title">Lazada</div>
    <div style="margin-top: 5px">
        <dx-button
            text="Get Authorization Code"
            width="200"
            (onClick)="navigateLazadaAuth()"
        ></dx-button>
    </div>
    <div style="margin-top: 10px; width: 300px">
        <dx-button
            text="Get Access Token"
            width="200"
            (onClick)="getAccessToken()"
        ></dx-button>
    </div>
</div>

<dx-load-panel
    #loadPanel
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: '#content' }"
    [(visible)]="isLoading"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
>
</dx-load-panel>
