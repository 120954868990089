import { Component, AfterViewInit, ViewChild } from '@angular/core';
import {
    LazadaOpenService,
    MessageType,
    NotificationService,
    UtilService,
} from 'src/app/shared/services';
import { ProductStatus } from 'src/app/shared/services/lazada/enums/product-status';
import {
    get as _get,
    each as _each,
    find as _find,
    toNumber as _toNumber,
    isString as _isString,
    isEmpty as _isEmpty,
} from 'lodash';
import { DxDataGridComponent, DxTextBoxComponent } from 'devextreme-angular';

@Component({
    selector: 'app-laz-vdo-adder',
    templateUrl: './laz-vdo-adder.component.html',
    styleUrls: ['./laz-vdo-adder.component.scss'],
})
export class LazVdoAdderComponent implements AfterViewInit {
    @ViewChild('dataGrid', { static: true })
    private dataGrid: DxDataGridComponent;
    @ViewChild('videoUrlTextBox', { static: false })
    private videoUrlTextBox: DxTextBoxComponent;

    isDataLoading = false;

    originalSource: any[] = [];
    currentSource: any[] = [];
    noVideoSource: any[] = [];
    hasVideoSource: any[] = [];

    selectedRowKeys: string[] = [];

    videoTextBoxOptions = {
        text: '',
        width: 500,
    };
    saveButtonOptions = {
        text: 'Save',
        icon: 'save',
        type: 'default',
        onClick: this.fillVideoSelected.bind(this),
    };

    tabs = [
        {
            key: 'noVideo',
            text: 'No Video',
        },
        {
            key: 'hasVideo',
            text: 'Has Video',
        },
    ];

    selectedTab: {
        key: string;
        text: string;
    } = {
        key: 'noVideo',
        text: 'No Video',
    };

    constructor(
        private lazadaOpenService: LazadaOpenService,
        private notificationService: NotificationService,
        private utilService: UtilService
    ) {}

    ngAfterViewInit(): void {
        this.currentSource = this.noVideoSource;
        this.getProductSource();
    }

    onTabChanged(event: any): void {
        const tabKey: string = _get(event, 'addedItems[0].key');
        this.updateTabDataSource(tabKey);
    }

    private updateTabDataSource(tabKey: string): void {
        this.selectedRowKeys = [];
        this.currentSource =
            tabKey === 'noVideo' ? this.noVideoSource : this.hasVideoSource;
    }

    private getProductSource(): void {
        this.dataGridBeginLoading();
        this.lazadaOpenService.getProducts(ProductStatus.Live).subscribe(
            (items: any[]) => {
                this.processItems(items);
                this.updateTabDataSource(this.selectedTab.key);
                this.dataGridEndLoading();
            },
            (error) => {
                console.error(error);
                this.notificationService.pushMessage({
                    displayTime: 6000,
                    message: this.utilService.errorToString(error),
                    messageType: MessageType.error,
                });
                this.dataGridEndLoading();
            }
        );
    }

    private async fillVideoSelected() {
        const videoUrl: string = this.videoUrlTextBox.text;
        if (_isString(videoUrl) && !_isEmpty(videoUrl)) {
            this.dataGridBeginLoading();
            for (const itemId of this.selectedRowKeys) {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const item = _find(this.originalSource, { item_id: itemId });
                if (item) {
                    const sellerSku = _get(item, 'skus[0].SellerSku');
                    if (sellerSku) {
                        this.lazadaOpenService
                            .updateProductVideo(
                                _toNumber(itemId),
                                videoUrl,
                                sellerSku
                            )
                            .subscribe(
                                () => {
                                    this.notificationService.pushMessage({
                                        displayTime: 6000,
                                        message: `Update completed ${_get(
                                            item,
                                            'attributes.name'
                                        )}`,
                                        messageType: MessageType.success,
                                    });
                                },
                                (error) => {
                                    console.error(error);
                                    this.notificationService.pushMessage({
                                        displayTime: 6000,
                                        message:
                                            this.utilService.errorToString(
                                                error
                                            ),
                                        messageType: MessageType.error,
                                    });
                                }
                            );
                    }
                }
            }
            this.dataGridEndLoading();
            this.getProductSource();
        }
    }

    private processItems(items: any): void {
        this.noVideoSource = [];
        this.hasVideoSource = [];
        this.selectedRowKeys = [];
        this.originalSource = items;
        _each(items, (item) => {
            const videoUrl = _get(item, 'attributes.video');
            if (videoUrl) {
                this.hasVideoSource.push(item);
            } else {
                this.noVideoSource.push(item);
            }
        });
    }

    private dataGridBeginLoading(): void {
        this.dataGrid.instance.beginCustomLoading(null);
        this.isDataLoading = true;
    }

    private dataGridEndLoading(): void {
        this.dataGrid.instance.endCustomLoading();
        this.isDataLoading = false;
    }
}
