import { Component, NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DxListModule } from 'devextreme-angular/ui/list';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { AuthService } from '../../services';

@Component({
    selector: 'app-user-panel',
    templateUrl: 'user-panel.component.html',
    styleUrls: ['./user-panel.component.scss'],
})
export class UserPanelComponent {
    @Input()
    menuItems: any;

    @Input()
    menuMode: string;

    email = '';
    constructor(private authService: AuthService) {
        this.authService.userInfo.then((user) => {
            this.email = user.attributes.email;
        });
    }
}

@NgModule({
    imports: [DxListModule, DxContextMenuModule, CommonModule],
    declarations: [UserPanelComponent],
    exports: [UserPanelComponent],
})
export class UserPanelModule {}
