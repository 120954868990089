import { Component, OnInit, ViewChild } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular';
import { PriceListService } from '../../services';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { get as _get } from 'lodash';

@Component({
    selector: 'app-product-detail-popup',
    templateUrl: './product-detail-popup.component.html',
    styleUrls: ['./product-detail-popup.component.scss'],
})
export class ProductDetailPopupComponent implements OnInit {
    @ViewChild('popupComponent', { static: true })
    private popupComponent: DxPopupComponent;

    get = _get;
    loadingVisible = false;
    productDetail: any;
    images: string[] = [];
    popupVisible = false;

    private onRequestProductDetail: Subject<number> = new Subject<number>();

    constructor(private priceListService: PriceListService) {}

    ngOnInit(): void {
        this.onRequestProductDetail
            .pipe(
                switchMap((pid) => {
                    this.loadingVisible = true;
                    return this.priceListService.getProductDetail(pid);
                })
            )
            .subscribe(
                (itemResponse) => {
                    this.productDetail = itemResponse;
                    this.loadingVisible = false;
                },
                (error) => {
                    this.loadingVisible = false;
                    console.error(error);
                }
            );
    }

    popup(pid: number): void {
        this.onRequestProductDetail.next(pid);
        this.popupVisible = true;
    }
}
