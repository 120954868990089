// Do not change order.
const branchList: string[] = [
    'สำนักงานใหญ่',
    'สาขาที่ 00001',
    'สาขาที่ 00002',
    'สาขาที่ 00003',
    'สาขาที่ 00004',
    'สาขาที่ 00005',
    'สาขาที่ 00006',
    'สาขาที่ 00007',
    'สาขาที่ 00008',
    'สาขาที่ 00009',
    'สาขาที่ 00010',
];

// Do not change order.
const deliveryCourierList: string[] = ['EMS', 'ไปรษณีย์ลงทะเบียน', 'Kerry'];

const deliveryTermList: string[] = [
    'ภายใน 3 วันหลังสั่งซื้อ',
    'รอนำเข้าสินค้า 3-4 สัปดาห์',
];

export { branchList, deliveryCourierList, deliveryTermList };
