import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Output,
    ViewChild,
} from '@angular/core';
import { CounterpartyService } from '../../services/counterparty.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CounterpartyCardViewModel } from '../library/counterparty-card/counterparty-card.component';
import {
    each as _each,
    find as _find,
    map as _map,
    get as _get,
    trim as _trim,
    isFinite as _isFinite,
    isEmpty as _isEmpty,
    parseInt as _parseInt,
} from 'lodash';
import { ThAddressSuggestorService } from 'src/app/shared/services';
import { DxTextBoxComponent } from 'devextreme-angular';

@Component({
    selector: 'app-counterparty-finder',
    templateUrl: './counterparty-finder.component.html',
    styleUrls: ['./counterparty-finder.component.scss'],
})
export class CounterpartyFinderComponent {
    @Output() selectedCtp: Counterparty;
    @Output() ctpSelectionChangeEvent = new EventEmitter<Counterparty>();

    @ViewChild('inputTextBox', { static: true })
    taxIdInput: DxTextBoxComponent;

    inputTaxId = '';
    statusMsg = '';
    isLoaded = false;
    ctpList: Counterparty[] = [];
    ctpVms: CounterpartyCardViewModel[] = [];
    isLoading: boolean;
    isOnlyDbd: false;

    constructor(
        private cdref: ChangeDetectorRef,
        private counterpartyService: CounterpartyService,
        private thAddressSuggestor: ThAddressSuggestorService
    ) {}

    onSearchTaxIdClick(): void {
        const input: string = _trim(this.inputTaxId);
        if (input) {
            this.findCtp(input);
        }
    }

    onSelectButtonsClicked(ctpVm: CounterpartyCardViewModel): void {
        const selectedCtp = _find<Counterparty>(this.ctpList, { id: ctpVm.id });
        if (selectedCtp) {
            this.selectedCtp = selectedCtp;
            this.ctpSelectionChangeEvent.emit(selectedCtp);
        }
    }

    normalizeTaxIdInput(): void {
        if (this.taxIdInput) {
            let input: string = _trim(this.inputTaxId);
            input = input.replace(/\D+/g, '');
            this.taxIdInput.value = input;
        }
    }

    private findCtp(taxId: string): void {
        this.isLoading = true;
        this.ctpList = [];
        const obsservable = this.isOnlyDbd
            ? this.findDbdCtp(taxId)
            : this.findLocalCtp(taxId);

        obsservable.subscribe(
            () => {
                this.constructCardViewModels();
                this.isLoading = false;
                this.isLoaded = true;
                this.cdref.detectChanges();
            },
            () => {
                this.isLoading = false;
                this.isLoaded = true;
            }
        );
    }

    private findLocalCtp(taxId: string): Observable<unknown> {
        this.statusMsg = 'กำลังค้นหาคู่ค้าในฐานข้อมูลภายใน ...';
        return this.counterpartyService
            .getCounterparties({
                tax_id: taxId,
            })
            .pipe(
                switchMap((localDbResult) => {
                    if (_isEmpty(localDbResult)) {
                        return this.findDbdCtp(taxId);
                    } else {
                        this.extractLocalSelectableCtps(localDbResult);
                        return of([]);
                    }
                })
            );
    }

    private findDbdCtp(taxId: string): Observable<any> {
        this.statusMsg = 'กำลังค้นหาคู่ค้าจาก dataforthai ...';
        return this.counterpartyService.getDbdCounterpartyData(taxId).pipe(
            switchMap((dbdResult) => {
                const branchId = '00000';
                const _taxId: string = _get(dbdResult, 'taxId');
                const companyName: string = _get(dbdResult, 'companyName');
                const address: string = _get(dbdResult, 'address');
                if (_taxId && companyName && address) {
                    this.ctpList.push({
                        id: `${_taxId}|${branchId}`,
                        companyName,
                        taxId: _taxId,
                        branchId,
                        branchDisplayText:
                            this.getBranchDisplayTextFromId(branchId),
                        address,
                        addrSegment: this.getAddrSegment(address),
                        isDbdSource: true,
                    });
                    return of([]);
                }
                this.statusMsg = 'กำลังค้นหาคู่ค้าจาก DBD ...';
                return this.counterpartyService
                    .getDbdCounterpartyDataAlt(taxId)
                    .pipe(
                        map((res) => {
                            return this.extractDbdSelectableCtps(res);
                        })
                    );
            })
        );
    }

    private extractDbdSelectableCtps(dbdResults): void {
        _each(dbdResults, (result) => {
            const person = _get(result, 'cd:OrganizationJuristicPerson');
            const companyName = _get(person, 'cd:OrganizationJuristicNameTH');
            const taxId = _get(person, 'cd:OrganizationJuristicID');
            const branchDisplayText = _get(
                person,
                'cd:OrganizationJuristicBranchName'
            );
            const address: string = this.constructAddrFromDbdData(person);
            const branchId = this.getBranchIdFromDisplayText(branchDisplayText);
            this.ctpList.push({
                id: `${taxId}|${branchId}`,
                companyName,
                taxId,
                branchId,
                branchDisplayText,
                address,
                addrSegment: this.getAddrSegment(address),
                isDbdSource: true,
            });
        });
    }

    private extractLocalSelectableCtps(localDbResults): void {
        _each(localDbResults, (result) => {
            const isDbdFound: boolean = result['dbd_found'];
            const companyName = isDbdFound
                ? _get(result, 'dbd_JuristicNameTH')
                : _get(result, 'name');
            const taxId = isDbdFound
                ? _get(result, 'dbd_JuristicID')
                : _get(result, 'tax_id');
            const branchId = _get(result, 'branch_id');
            const address = _get(result, 'address');
            this.ctpList.push({
                id: `${taxId}|${branchId}`,
                companyName,
                taxId,
                branchId,
                branchDisplayText: this.getBranchDisplayTextFromId(branchId),
                address,
                addrSegment: this.getAddrSegment(address),
                isDbdSource: false,
            });
        });
    }

    private constructCardViewModels(): void {
        this.ctpVms = _map<Counterparty, CounterpartyCardViewModel>(
            this.ctpList,
            (ctp: Counterparty) => {
                return {
                    id: `${ctp.taxId}|${ctp.branchId}`,
                    companyName: ctp.companyName,
                    taxId: ctp.taxId,
                    branch: ctp.branchDisplayText,
                    address: ctp.address,
                    isDbdSource: ctp.isDbdSource,
                };
            }
        );
    }

    private getBranchDisplayTextFromId(id: any): string {
        const idInt: number = _parseInt(id);
        if (_isFinite(idInt)) {
            if (idInt === 0) {
                return 'สำนักงานใหญ่';
            } else {
                return `สาขาที่ ${idInt.toString().padStart(5, '0')}`;
            }
        }
        return '';
    }

    private getBranchIdFromDisplayText(input: string): string {
        const dspTxt: string = _trim(input);
        if (!_isEmpty(dspTxt)) {
            if (dspTxt === 'สำนักงานใหญ่') {
                return '00000';
            } else {
                const regexp = /\D*(\d+)\D*/;
                const match: RegExpExecArray = regexp.exec(dspTxt);
                if (match && match.length > 1) {
                    const matchDigits: string = match[2];
                    const idInt: number = _parseInt(matchDigits);
                    if (_isFinite(idInt)) {
                        return `${idInt.toString().padStart(5, '0')}`;
                    }
                }
            }
        }
        return '';
    }

    private constructAddrFromDbdData(person: any): string {
        const addrType = _get(
            person,
            'cd:OrganizationJuristicAddress.cr:AddressType'
        );
        let addrLine: string = _trim(_get(addrType, 'cd:Address'));
        addrLine = addrLine.replace(/\s\s+/g, ' ');

        const province: string = _trim(
            _get(addrType, 'cd:CountrySubDivision.cr:CountrySubDivisionTextTH')
        );
        const isBangkok: boolean = province === 'กรุงเทพมหานคร';

        let district: string = _trim(_get(addrType, 'cd:City.cr:CityTextTH'));
        if (district.startsWith('เขต')) {
            district = district.slice('เขต'.length);
        } else if (district.startsWith('อำเภอ')) {
            district = district.slice('อำเภอ'.length);
        }

        let subDistrict: string = _trim(
            _get(addrType, 'cd:CitySubDivision.cr:CitySubDivisionTextTH')
        );
        if (subDistrict.startsWith('แขวง')) {
            subDistrict = subDistrict.slice('แขวง'.length);
        } else if (subDistrict.startsWith('ตำบล')) {
            subDistrict = subDistrict.slice('ตำบล'.length);
        }

        const postCode = _trim(
            this.thAddressSuggestor.getPostCode(province, district, subDistrict)
        );

        const fullAddr = _trim(
            isBangkok
                ? `${addrLine} แขวง${subDistrict} เขต${district} จังหวัด${province} ${postCode}`
                : `${addrLine} ตำบล${subDistrict} อำเภอ${district} จังหวัด${province} ${postCode}`
        );
        return fullAddr;
    }

    private getAddrSegment(fullAddr: string): AddressSegment {
        const postcodeRegex = /(\d{5})$/;
        const provinceRegex = /(จังหวัด|จ\.)(\S+)/;
        const bangkokRegex = /กรุงเทพมหานคร$/;
        const districtRegex = /(เขต|อำเภอ|อ\.)(\S+)/;
        const subDistrictRegex = /(แขวง|ตำบล|ต\.)(\S+)/;
        const houseNoRegex = /^[\d\-/]+/;
        const addrSeg: AddressSegment = {
            houseNo: '',
            addrLine: '',
            subDistrict: '',
            district: '',
            province: '',
            postcode: '',
        };

        let _fullAddr = fullAddr;
        const postCodeMatch: RegExpExecArray = postcodeRegex.exec(_fullAddr);
        if (postCodeMatch && postCodeMatch.length > 1) {
            const postcode: string = postCodeMatch[1];
            addrSeg.postcode = postcode;
            _fullAddr = _fullAddr.replace(postCodeMatch[0], '').trim();
        }

        const bangkokMatch: RegExpExecArray = bangkokRegex.exec(_fullAddr);
        if (bangkokMatch) {
            addrSeg.province = bangkokMatch[0];
            _fullAddr = _fullAddr.replace(bangkokMatch[0], '').trim();
        } else {
            const provinceMatch: RegExpExecArray =
                provinceRegex.exec(_fullAddr);
            if (provinceMatch && provinceMatch.length > 1) {
                const province: string = provinceMatch[2];
                addrSeg.province = province;
                console.log(province);
                _fullAddr = _fullAddr.replace(provinceMatch[0], '').trim();
            }
        }

        const districtMatch: RegExpExecArray = districtRegex.exec(_fullAddr);
        if (districtMatch && districtMatch.length > 1) {
            const district: string = districtMatch[2];
            addrSeg.district = district;
            _fullAddr = _fullAddr.replace(districtMatch[0], '').trim();
        }

        const subDistrictMatch: RegExpExecArray =
            subDistrictRegex.exec(_fullAddr);
        if (subDistrictMatch && subDistrictMatch.length > 1) {
            const subDistrict: string = subDistrictMatch[2];
            addrSeg.subDistrict = subDistrict;
            _fullAddr = _fullAddr.replace(subDistrictMatch[0], '').trim();
        }

        const houseNoMatch: RegExpExecArray = houseNoRegex.exec(_fullAddr);
        if (houseNoMatch && houseNoMatch.length > 0) {
            const houseNo: string = houseNoMatch[0];
            addrSeg.houseNo = houseNo;
            _fullAddr = _fullAddr.replace(houseNoMatch[0], '').trim();
        }
        addrSeg.addrLine = _fullAddr.trim();
        return addrSeg;
    }
}

export interface Counterparty {
    id: string;
    companyName: string;
    taxId: string;
    branchId: string;
    branchDisplayText: string;
    address: string;
    addrSegment: AddressSegment;
    isDbdSource: boolean;
}

export interface AddressSegment {
    houseNo: string;
    addrLine: string;
    subDistrict: string;
    district: string;
    province: string;
    postcode: string;
}
