import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    DxAutocompleteModule,
    DxBoxModule,
    DxButtonModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxDropDownButtonModule,
    DxLoadPanelModule,
    DxNumberBoxModule,
    DxSelectBoxModule,
    DxSwitchModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxTooltipModule,
    DxValidationGroupModule,
    DxValidatorModule,
} from 'devextreme-angular';
import { FormatNumberPipeModule } from 'src/app/shared/pipes';
import { CreateTaxInvoiceComponent } from './components/create-tax-invoice/create-tax-invoice.component';
import { OutputTaxService } from './services/output-tax/tax-output.service';
import { OutputTaxSummaryComponent } from './components/output-tax-summary/output-tax-summary.component';

@NgModule({
    imports: [
        DxButtonModule,
        DxDataGridModule,
        DxDropDownButtonModule,
        DxSwitchModule,
        DxTextBoxModule,
        DxSelectBoxModule,
        DxDateBoxModule,
        DxNumberBoxModule,
        DxTextAreaModule,
        DxBoxModule,
        DxLoadPanelModule,
        DxAutocompleteModule,
        DxTooltipModule,
        CommonModule,
        FormatNumberPipeModule,
        DxValidatorModule,
        DxValidationGroupModule,
    ],
    declarations: [CreateTaxInvoiceComponent, OutputTaxSummaryComponent],
    exports: [],
    providers: [OutputTaxService],
})
export class OutputTaxModule {}
