export interface TaxInvoiceInput {
    invoiceInfo: InvoiceInfo;
    customerInfo: CustomerInfo;
    productItems: ProductInfo[];
}

interface InvoiceInfo {
    invNum: string;
    refOrderNum: number;
    date: Date;
    totalProductPrice: number;
    shippingCost: number;
    tableTotal: number;
    discount: number;
    totalAfterDiscount: number;
    amtBeforeVat: number;
    vatAmount: number;
    netPrice: number;
    remark: string;
    showSignatureFields: boolean;
    showSellerSignature: boolean;
    isEdit: boolean;
    includeCopy: boolean;
    isTaxInvoice: boolean;
    isDeliveryNote: boolean;
    isReceipt: boolean;
    title1: string;
    title2: string;
    title3: string;
}

interface CustomerInfo {
    name: string;
    taxId: string;
    branch: string;
    postCode: string;
    province: string;
    district: string;
    subDistrict: string;
    address1: string;
    fullAddress: string;
    useCustomAddress: boolean;
    customAddress: string;
}

export interface ProductInfo {
    pid: number;
    sku: string;
    itemNo: number;
    description: string;
    unitPrice: number;
    quantity: number;
    amount: number;
}

const initialInvoiceInput: TaxInvoiceInput = {
    invoiceInfo: {
        invNum: null,
        refOrderNum: null,
        date: null,
        totalProductPrice: 0,
        shippingCost: 0,
        tableTotal: 0,
        discount: 0,
        totalAfterDiscount: 0,
        amtBeforeVat: 0,
        vatAmount: 0,
        netPrice: 0,
        remark: '',
        showSignatureFields: true,
        showSellerSignature: false,
        isTaxInvoice: true,
        isDeliveryNote: true,
        isReceipt: true,
        isEdit: false,
        includeCopy: true,
        title1: '',
        title2: '',
        title3: '',
    },
    customerInfo: {
        name: '',
        taxId: '',
        branch: '',
        postCode: '',
        province: '',
        district: '',
        subDistrict: '',
        address1: '',
        fullAddress: '',
        useCustomAddress: false,
        customAddress: '',
    },
    productItems: [],
};
import { cloneDeep } from 'lodash';
const getInitialInvoiceInput = (): TaxInvoiceInput => {
    return cloneDeep(initialInvoiceInput);
};
export { getInitialInvoiceInput };
