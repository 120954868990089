import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { CounterpartyService } from '../../services/counterparty.service';
import {
    each as _each,
    map as _map,
    find as _find,
    get as _get,
    trim as _trim,
    reduce as _reduce,
    toNumber as _toNumber,
    isFinite as _isFinite,
    uniq as _uniq,
    isEmpty as _isEmpty,
} from 'lodash';
import { Counterparty } from '../../models';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import moment, { Moment } from 'moment';
import {
    MessageType,
    NotificationService,
    UtilService,
} from '../../../../shared/services';

@Component({
    selector: 'app-counterparty-list',
    templateUrl: './counterparty-list.component.html',
    styleUrls: ['./counterparty-list.component.scss'],
})
export class CounterpartyListComponent implements AfterViewInit {
    @ViewChild('dataGrid', { static: true })
    private dataGrid: DxDataGridComponent;

    openDetail = true;
    isShowOrderData = false;
    counterpartyData: CounterpartyGridItem[] = [];

    constructor(
        private counterpartyService: CounterpartyService,
        private notificationService: NotificationService,
        private utilService: UtilService
    ) {}

    onOpenDetailClicked() {
        this.openDetail = !this.openDetail;
    }

    ngAfterViewInit(): void {
        this.fetchCounterpartyData().subscribe();
    }

    fetchCounterpartyData(): Observable<void> {
        this.dataGrid.instance.beginCustomLoading(null);
        return this.counterpartyService.getCounterparties({}).pipe(
            map((results: Counterparty[]) => {
                this.counterpartyData = this.decorateFirstDataItems(results);
                this.dataGrid.instance.endCustomLoading();
            })
        );
    }

    decorateFirstDataItems(
        rawDataItems: Counterparty[]
    ): CounterpartyGridItem[] {
        return _map(rawDataItems, (rawDataItem: Counterparty) => {
            const item: CounterpartyGridItem = {
                _id: _get(rawDataItem, '_id'),
                taxId: this.findTaxId(rawDataItem),
                branchId: _trim(_get(rawDataItem, 'branch_id', '')),
                name: this.findCtpName(rawDataItem),
                address: _trim(_get(rawDataItem, 'address', '')),
                dbdType: _trim(_get(rawDataItem, 'dbd_Type', '')),
                isDbdRegistered: _get(rawDataItem, 'dbd_JuristicID')
                    ? true
                    : false,
                totalOrderValue: null,
                orderCount: null,
                lastOrderMoment: null,
                orders: [],
            };
            return item;
        });
    }

    findTaxId(rawDataItem: Counterparty): string {
        const dbdJuristicTaxIdTh: string | undefined = _get(
            rawDataItem,
            'dbd_JuristicID'
        );
        if (dbdJuristicTaxIdTh) {
            return _trim(dbdJuristicTaxIdTh);
        }
        return _trim(_get(rawDataItem, 'tax_id', ''));
    }

    findCtpName(rawDataItem: Counterparty): string {
        const dbdJuristicNameTh: string | undefined = _get(
            rawDataItem,
            'dbd_JuristicNameTH'
        );
        if (dbdJuristicNameTh) {
            return _trim(dbdJuristicNameTh);
        }
        return _trim(_get(rawDataItem, 'name', ''));
    }

    calculateTotalOrderValue(orders: any[]): number {
        return _reduce<any, number>(
            orders,
            (acc, order) => {
                const orderValue = _toNumber(
                    _get(order, 'net_price.$numberDecimal', 0)
                );
                if (_isFinite(orderValue)) {
                    return acc + orderValue;
                }
                return acc;
            },
            0
        );
    }

    getLastOrderMoment(orders: any[]): Moment {
        const rawOrderDatetime = _get(orders[0], 'order_time');
        if (rawOrderDatetime) {
            const orderDatetime: Moment = moment(rawOrderDatetime);
            if (orderDatetime.isValid()) {
                return orderDatetime;
            }
        }
        return null;
    }

    loadOrderData() {
        if (!_isEmpty(this.counterpartyData)) {
            const taxIds: string[] = _uniq(
                _map(this.counterpartyData, 'taxId')
            );
            this.dataGrid.instance.beginCustomLoading(null);
            this.counterpartyService.findOrdersByTaxId(taxIds).subscribe(
                (orderResult: any[]) => {
                    _each(orderResult, (orders: any[], key) => {
                        const ctpItem: any = _find(this.counterpartyData, {
                            taxId: key,
                        });
                        if (ctpItem) {
                            ctpItem['orders'] = orders;
                            ctpItem['totalOrderValue'] =
                                this.calculateTotalOrderValue(orders);
                            ctpItem['orderCount'] = orders.length;
                            ctpItem['lastOrderMoment'] =
                                this.getLastOrderMoment(orders);
                        }
                    });
                    this.dataGrid.instance.endCustomLoading();
                    this.isShowOrderData = true;
                },
                (err) => {
                    this.dataGrid.instance.endCustomLoading();
                    this.isShowOrderData = false;
                    console.error(err);
                    this.notificationService.pushMessage({
                        displayTime: 6000,
                        message: this.utilService.errorToString(err),
                        messageType: MessageType.error,
                    });
                }
            );
        }
    }
}

interface CounterpartyGridItem {
    _id: string;
    taxId: string;
    branchId: string;
    name: string;
    address: string;
    dbdType: string;
    totalOrderValue: number;
    orderCount: number;
    lastOrderMoment: Moment;
    isDbdRegistered: boolean;
    orders: any[];
}
