<div class="main">
    <div class="search-panel">
        <div style="flex-grow: 1">
            <dx-text-box
                #inputTextBox
                label="เลขทะเบียนนิติบุคคล"
                [(value)]="inputTaxId"
                (onEnterKey)="onSearchTaxIdClick()"
                (onBlur)="normalizeTaxIdInput()"
                [disabled]="isLoading"
            ></dx-text-box>
        </div>
        <div class="separator">|</div>
        <div>
            <dx-switch [(value)]="isOnlyDbd" [disabled]="isLoading">
            </dx-switch>
            &nbsp;
            <span>Ext</span>
        </div>
        <div class="separator">|</div>
        <div>
            <dx-button
                text="ค้นหา"
                icon="search"
                stylingMode="outlined"
                type="default"
                (onClick)="onSearchTaxIdClick()"
                [disabled]="isLoading"
            >
            </dx-button>
        </div>
        <div>
            <dx-load-indicator
                *ngIf="isLoading"
                height="30"
                width="30"
            ></dx-load-indicator>
        </div>
    </div>

    <div class="result-panel">
        <div class="msg-box" *ngIf="isLoading">
            {{ statusMsg }}
        </div>
        <div
            *ngIf="!isLoading && isLoaded && ctpVms.length === 0"
            class="msg-box"
        >
            ไม่มีข้อมูล
        </div>
        <ng-container *ngFor="let ctpVm of ctpVms">
            <app-counterparty-card
                [ctpVm]="ctpVm"
                (selectButtonClickEvent)="onSelectButtonsClicked($event)"
            >
            </app-counterparty-card>
        </ng-container>
    </div>
</div>
