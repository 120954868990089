// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    appName: 'Cross Platform Integration Console (Dev)',
    lsiEndpointApiKey: 'o8iyqfj2YM4MEfE4UtrZKak9hs69agZQ6x9FLN8e',
    unifiedExecEndPoint: 'https://api.genlogic.co.th/dev/internal/unified-exec',
    unifiedQueryInternalEndPoint:
        'https://api.genlogic.co.th/dev/internal/unified-query',
    unifiedQueryExternalEndPoint:
        'https://api.genlogic.co.th/dev/external/unified-query',
    unifiedQueryV2EndPoint:
        'https://api.genlogic.co.th/dev/v2/internal/unified-query',
    shopeeDelegateEndPoint:
        'https://api.genlogic.co.th/dev/internal/shopee/delegate',
    shopeeDelegateV2EndPoint:
        'https://api.genlogic.co.th/dev/v2/internal/shopee/delegate',
    lazadaDelegateEndPoint:
        'https://api.genlogic.co.th/dev/internal/lazada/delegate',
    lastPrintEndPoint: 'https://api.genlogic.co.th/dev/internal/last-print',
    renderReportEndpoint:
        'https://api.genlogic.co.th/dev/internal/report/render',
    render2ReportEndpoint:
        'https://api.genlogic.co.th/dev/internal/report/render2',
    outputTaxEndpoint: 'https://api.genlogic.co.th/dev/internal/tax/output-tax',
    ssmGetEndpoint: 'https://api.genlogic.co.th/dev/internal/ssm/get',
    ssmPutEndpoint: 'https://api.genlogic.co.th/dev/internal/ssm/put',
    saleSinceEndpoint:
        'https://api.genlogic.co.th/dev/internal/analytics/sale-since',
    lastSaleEndpoint:
        'https://api.genlogic.co.th/dev/internal/analytics/last-sale',
    abcAnalysisEndpoint:
        'https://api.genlogic.co.th/dev/internal/analytics/abc',
    renderPersistReportEndpoint:
        'https://api.genlogic.co.th/dev/internal/report/render-persist',
    reorderingEndpoint: 'https://api.genlogic.co.th/dev/internal/reordering',
    counterpartyGetOrdersByTaxIdEndpoint:
        'https://api.genlogic.co.th/dev/internal/counterparty/get-orders-by-tax-id',
    getDbdCtpEndpoint:
        'https://api.genlogic.co.th/dev/internal/counterparty/get-dbd-data',
    ninjaLogoUrl:
        'https://arduino4-web-resources.s3-ap-southeast-1.amazonaws.com/jsreport-resources/ninjavan_logo.png',
    kerryLogoUrl:
        'https://arduino4-web-resources.s3-ap-southeast-1.amazonaws.com/jsreport-resources/kerry_logo.png',
    flashLogoUrl:
        'https://arduino4-web-resources.s3-ap-southeast-1.amazonaws.com/jsreport-resources/flash_logo.jpg',
    lelExpressLogoUrl:
        'https://arduino4-web-resources.s3.ap-southeast-1.amazonaws.com/jsreport-resources/lelexpress_logo.png',
    shopeeLogoUrl:
        'https://arduino4-web-resources.s3-ap-southeast-1.amazonaws.com/jsreport-resources/shopee_logo.png',
    lazadaLogoUrl:
        'https://arduino4-web-resources.s3-ap-southeast-1.amazonaws.com/jsreport-resources/lazada_logo.png',
    shopeeExpressLogoUrl:
        'https://arduino4-web-resources.s3.ap-southeast-1.amazonaws.com/jsreport-resources/shopeeexpress_logo.png',
    jtLogoUrl:
        'https://arduino4-web-resources.s3.ap-southeast-1.amazonaws.com/jsreport-resources/jt_logo.png',
    spxLogoUrl:
        'https://arduino4-web-resources.s3.ap-southeast-1.amazonaws.com/jsreport-resources/spx_logo.png',
    invoiceReportTemplateName: 'invoice-main',
    taxInvoiceReportTemplateName: 'tax-invoice',
    outputTaxReportTemplateName: 'output-tax',
    shopeeeShippingLabelReportTemplateName: 'shopee-shipping-label',
    lazadaShipingLabelRenderTemplateName: 'laz-shipping-label-render',
    quoteTemplateName: 'quote-main',
    reportSenderName: 'General4 Group',
    reportSenderAddress:
        '111/12 ซ.โชคชัยร่วมมิตร 16/13-4 ถนนวิภาวดีรังสิต แขวงจอมพล เขตจตุจักร กรุงเทพ 10900',
    shopLogoUrl: 'https://dm.lnwfile.com/_/dm/_resize/200/200/tw/8s/dj.png',
    shopShopeeUrl: 'https://shopee.co.th/general4.group',
    shopLazadaUrl: 'https://www.lazada.co.th/shop/general4-group',
    printDateFormat: 'D MMM YYYY',
    googleApiClientId:
        '292357845356-lr37tkhk6upahdhivud7hk89p5debtqv.apps.googleusercontent.com',
    googleApiClientSecret: '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',
    googleApiKey: 'AIzaSyBHqfJ8332cn2QCJkEczfR75S1ekgnDbmk',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
