<div class="panel" [ngClass]="{ pin: pinned, open: isOpened }">
    <div class="data-wrapper">
        <ng-container *ngIf="!isLoading">
            <dx-toolbar class="panel-toolbar">
                <dxi-item location="before">
                    <div style="margin-left: 10px; color: rgba(77, 77, 77, 1)">
                        C00001
                    </div>
                </dxi-item>
                <dxi-item location="before"> </dxi-item>
                <dxi-item location="after" [visible]="isPinEnabled">
                    <dx-button
                        stylingMode="text"
                        [icon]="pinned ? 'pin' : 'unpin'"
                        (onClick)="onPinClick()"
                    ></dx-button>
                </dxi-item>
                <dxi-item location="after">
                    <dx-button
                        icon="close"
                        stylingMode="text"
                        (onClick)="onClosePanel()"
                    ></dx-button>
                </dxi-item>
            </dx-toolbar>
            <dx-scroll-view class="panel-scroll">
                <dx-validation-group>
                    <div class="data-part border">
                        <dx-form
                            class="plain-styled-form"
                            labelMode="floating"
                            [class.view-mode]="!isEditing"
                        >
                            <dxi-item *ngIf="isEditing" class="dbd-search">
                                <dx-text-box
                                    label="DBD Search"
                                    [buttons]="dbdSearchButtonOptions"
                                >
                                </dx-text-box>
                            </dxi-item>
                            <dxi-item class="header">
                                <div
                                    *ngIf="!isEditing"
                                    style="
                                        text-align: center;
                                        margin-bottom: 10px;
                                        font-size: medium;
                                    "
                                ></div>
                                <div *ngIf="!isEditing">
                                    บริษัท บางซื่อโรงสีไฟเจียเม้งฟห
                                    กดฟหกดฟหกดฟหกด จำกัด ( สาขาที่ 00001 )
                                </div>
                                <div *ngIf="isEditing">
                                    <dx-text-box label="ชื่อกิจการ">
                                    </dx-text-box>
                                </div>
                            </dxi-item>
                            <dxi-item
                                itemType="group"
                                caption="ข้อมูลจดทะเบียนบริษัท"
                            >
                                <dxi-item>
                                    <div *dxTemplate>
                                        <dx-text-box
                                            label="เลขทะเบียนนิติบุคคล"
                                            [readOnly]="isEditing"
                                        ></dx-text-box>
                                    </div>
                                </dxi-item>
                                <dxi-item>
                                    <div *dxTemplate>
                                        <dx-text-box
                                            label="เลขที่สาขา"
                                            [readOnly]="isEditing"
                                        ></dx-text-box>
                                    </div>
                                </dxi-item>
                            </dxi-item>
                            <dxi-item
                                itemType="group"
                                caption="ที่อยู่จดทะเบียน"
                            >
                                <dxi-item>
                                    <div *dxTemplate>
                                        <dx-text-box
                                            label="ที่อยู่ 1"
                                            [readOnly]="isEditing"
                                        ></dx-text-box>
                                    </div>
                                </dxi-item>
                                <dxi-item>
                                    <div *dxTemplate>
                                        <dx-text-box
                                            label="ที่อยู่ 2"
                                            [readOnly]="isEditing"
                                        ></dx-text-box>
                                    </div>
                                </dxi-item>
                                <dxi-item itemType="group" [colCount]="2">
                                    <dxi-item>
                                        <div *dxTemplate>
                                            <dx-text-box
                                                label="รหัสไปรษณีย์"
                                                [readOnly]="isEditing"
                                            ></dx-text-box>
                                        </div>
                                    </dxi-item>
                                    <dxi-item>
                                        <div *dxTemplate>
                                            <dx-text-box
                                                label="จังหวัด"
                                                [readOnly]="isEditing"
                                            ></dx-text-box>
                                        </div>
                                    </dxi-item>
                                </dxi-item>
                                <dxi-item itemType="group" [colCount]="2">
                                    <dxi-item>
                                        <div *dxTemplate>
                                            <dx-text-box
                                                label="เขต"
                                                [readOnly]="isEditing"
                                            ></dx-text-box>
                                        </div>
                                    </dxi-item>
                                    <dxi-item cssClass="accent">
                                        <div *dxTemplate>
                                            <dx-text-box
                                                label="แขวง"
                                                [readOnly]="isEditing"
                                            ></dx-text-box>
                                        </div>
                                    </dxi-item>
                                </dxi-item>

                                <dxi-item class="address-sum">
                                    <div class="address-sum-header">
                                        ที่อยู่เต็ม
                                    </div>
                                    <div class="address-sum-content">
                                        เลขที่ 239 ถนน ห้วยแก้ว ตำบลสุเทพ
                                        อำเภอเมืองเชียงใหม่ จังหวัดเชียงใหม่
                                        50200
                                    </div>
                                </dxi-item>
                            </dxi-item>
                        </dx-form>
                    </div>
                    <div class="data-part data-part-toolbar border">
                        <dx-toolbar>
                            <dxi-item location="before" [visible]="!isEditing">
                                <dx-button
                                    text="Edit"
                                    icon="edit"
                                    stylingMode="outlined"
                                    type="default"
                                    (onClick)="toggleEdit()"
                                >
                                </dx-button>
                            </dxi-item>
                            <dxi-item location="before" [visible]="!isEditing">
                                <dx-button
                                    text="Details"
                                    stylingMode="outlined"
                                    type="default"
                                    (onClick)="navigateToDetails()"
                                >
                                </dx-button>
                            </dxi-item>
                            <dxi-item
                                location="before"
                                locateInMenu="before"
                                [visible]="isEditing"
                            >
                                <dx-button
                                    text="Save"
                                    icon="save"
                                    stylingMode="outlined"
                                    type="default"
                                    (onClick)="onSaveClick($event)"
                                >
                                </dx-button>
                            </dxi-item>
                            <dxi-item
                                location="before"
                                locateInMenu="before"
                                [visible]="isEditing"
                            >
                                <dx-button
                                    text="Cancel"
                                    stylingMode="text"
                                    (onClick)="toggleEdit()"
                                ></dx-button>
                            </dxi-item>
                            <dxi-item
                                location="after"
                                widget="dxDropDownButton"
                                [options]="{
                                    width: 120,
                                    text: 'Actions',
                                    stylingMode: 'contained',
                                    items: [
                                        'Call',
                                        'Send Fax',
                                        'Send Email',
                                        'Make a Meeting'
                                    ]
                                }"
                            ></dxi-item>
                        </dx-toolbar>
                    </div>
                </dx-validation-group>
            </dx-scroll-view>
        </ng-container>
        <dx-load-panel
            container=".panel"
            [width]="300"
            [visible]="isLoading"
            [showPane]="false"
            [position]="{ of: '.panel' }"
        ></dx-load-panel>
    </div>
</div>
