import { Injectable } from '@angular/core';
import { BaseRequester } from '../common/base-requester';
import { mergeMap, map, switchMap } from 'rxjs/operators';
import { AjaxResponse } from 'rxjs/ajax';
import {
    get as _get,
    map as _map,
    set as _set,
    concat as _concat,
    chunk as _chunk,
    isEmpty as _isEmpty,
    find as _find,
} from 'lodash';
import { forkJoin, from, Observable, of, Subject, throwError } from 'rxjs';
import { OrderStatus } from './enums/order-status-v2';
import { ShopeeDelegateRequest } from './interfaces/shopee-delegate-request';
import { environment } from '../../../../environments/environment';
import { Moment } from 'moment';
import { ShopeeShippingDocDataInfo } from './interfaces/shopee-shipping-doc-data';

@Injectable({
    providedIn: 'root',
})
export class ShopeeOpenServiceV2 extends BaseRequester {
    private delegateEndpoint = environment.shopeeDelegateV2EndPoint;

    private ORDER_DETAIL_FIELDS = [
        'buyer_user_id',
        'buyer_username',
        'estimated_shipping_fee',
        'recipient_address',
        'actual_shipping_fee',
        'goods_to_declare',
        'note',
        'note_update_time',
        'item_list',
        'pay_time',
        'dropshipper',
        'dropshipper_phone',
        'split_up',
        'buyer_cancel_reason',
        'cancel_by',
        'cancel_reason',
        'actual_shipping_fee_confirmed',
        'buyer_cpf_id',
        'fulfillment_flag',
        'pickup_done_time',
        'package_list',
        'shipping_carrier',
        'payment_method',
        'total_amount',
        'buyer_username',
        'invoice_data',
        'checkout_shipping_carrier',
        'reverse_shipping_fee',
        'order_chargeable_weight_gram',
        'edt',
        'prescription_images',
        'prescription_check_status',
    ];

    constructor() {
        super();
    }

    getOrderSnsByStatus(
        timeFrom: Moment,
        timeTo: Moment,
        orderStatus?: OrderStatus,
        cursor?: string,
        accumItem?: any[]
    ): Observable<any[]> {
        const request: ShopeeDelegateRequest = {
            servicePath: 'order/get_order_list',
            parameter: {
                time_range_field: 'create_time',
                time_from: timeFrom.unix(),
                time_to: timeTo.unix(),
                page_size: 100,
                response_optional_fields: 'order_status',
            },
        };
        if (orderStatus) {
            _set(request.parameter, 'order_status', orderStatus);
        }
        if (cursor) {
            _set(request.parameter, 'cursor', cursor);
        }
        return this.internalPostRequest<ShopeeDelegateRequest>(
            this.delegateEndpoint,
            request
        ).pipe(
            mergeMap((ajaxRes: AjaxResponse) => {
                const errMsg: string = _get(
                    ajaxRes,
                    'response.response.errorMessage'
                );
                if (errMsg) {
                    throw new Error(errMsg);
                } else {
                    const orderList = _get(
                        ajaxRes,
                        'response.response.order_list'
                    );
                    const orderItems = _map(orderList, (order) => {
                        return {
                            orderSn: order['order_sn'],
                            orderStatus: order['order_status'],
                        };
                    });
                    if (!accumItem) {
                        accumItem = [];
                    }
                    accumItem = _concat(accumItem, orderItems);
                    const hasMore: boolean = _get(
                        ajaxRes,
                        'response.response.more'
                    );
                    if (hasMore && orderItems.length > 0) {
                        const nextCursor = _get(
                            ajaxRes,
                            'response.response.next_cursor'
                        );
                        return this.getOrderSnsByStatus(
                            timeFrom,
                            timeTo,
                            orderStatus,
                            nextCursor,
                            accumItem
                        );
                    } else {
                        return of(accumItem);
                    }
                }
            })
        );
    }

    getOrderDetails(orderSn: string[]): Observable<any[]> {
        const orderSnChunks: string[][] = _chunk(orderSn, 50);
        return from(orderSnChunks).pipe(
            mergeMap((orderSnChunk: string[]) => {
                const request: ShopeeDelegateRequest = {
                    servicePath: 'order/get_order_detail',
                    parameter: {
                        order_sn_list: `${orderSnChunk.toString()}`,
                        response_optional_fields: `${this.ORDER_DETAIL_FIELDS.toString()}`,
                    },
                };
                return this.internalPostRequest<ShopeeDelegateRequest>(
                    this.delegateEndpoint,
                    request
                ).pipe(
                    map<AjaxResponse, any[]>((ajaxRes: AjaxResponse) => {
                        const orderDetails: any[] = _get(
                            ajaxRes,
                            'response.response.order_list'
                        );
                        return orderDetails;
                    })
                );
            })
        );
    }

    getOrderByStatusIncludingDetails(
        timeFrom: Moment,
        timeTo: Moment,
        orderStatus?: OrderStatus
    ): Subject<any[]> {
        const result = new Subject<any[]>();
        let orderDetails: any[] = [];
        this.getOrderSnsByStatus(timeFrom, timeTo, orderStatus)
            .pipe(
                mergeMap((orderSnObjList: any[]) => {
                    return this.getOrderDetails(
                        _map(orderSnObjList, 'orderSn')
                    );
                })
            )
            .subscribe(
                (orderDetailResults: any[]) => {
                    orderDetails = _concat(orderDetails, orderDetailResults);
                },
                (err) => {
                    result.error(err);
                },
                () => {
                    result.next(orderDetails);
                    result.complete();
                }
            );
        return result;
    }

    getAddressList(): Observable<any[]> {
        const request: ShopeeDelegateRequest = {
            servicePath: 'logistics/get_address_list',
            parameter: {},
        };
        return this.internalPostRequest<ShopeeDelegateRequest>(
            this.delegateEndpoint,
            request
        ).pipe(
            map<AjaxResponse, any[]>((ajaxRes: AjaxResponse) => {
                const addressList: any[] = _get(
                    ajaxRes,
                    'response.response.address_list'
                );
                return addressList;
            })
        );
    }

    getShippingParam(orderSn: string): Observable<any> {
        const request: ShopeeDelegateRequest = {
            servicePath: 'logistics/get_shipping_parameter',
            parameter: {
                order_sn: orderSn,
            },
        };
        return this.internalPostRequest<ShopeeDelegateRequest>(
            this.delegateEndpoint,
            request
        ).pipe(
            switchMap((ajaxRes: AjaxResponse) => {
                const error = _get(ajaxRes, 'response.error');
                if (error) {
                    return throwError(_get(ajaxRes, 'response.message'));
                }
                return of(_get(ajaxRes, 'response.response'));
            })
        );
    }

    getTrackingNumbers(orderSnList: string[]): Observable<any[]> {
        if (_isEmpty(orderSnList)) {
            return of([]);
        }
        const getTrackingNumberObsList = _map(
            orderSnList,
            (orderSn: string) => {
                const request: ShopeeDelegateRequest = {
                    servicePath: 'logistics/get_tracking_number',
                    parameter: {
                        order_sn: orderSn,
                    },
                };
                return this.internalPostRequest<ShopeeDelegateRequest>(
                    this.delegateEndpoint,
                    request
                ).pipe(
                    map<AjaxResponse, any>((ajaxRes: AjaxResponse) => {
                        const trackingNumber: string = _get(
                            ajaxRes,
                            'response.response.tracking_number'
                        );
                        return {
                            orderSn: orderSn,
                            trackingNumber: trackingNumber,
                        };
                    })
                );
            }
        );
        return forkJoin(getTrackingNumberObsList);
    }

    shipOrder(
        orderSn: string,
        addressId: number,
        pickupTimeId: string
    ): Observable<string> {
        const request: ShopeeDelegateRequest = {
            servicePath: 'logistics/ship_order',
            method: 'post',
            parameter: {
                order_sn: orderSn,
                pickup: {
                    address_id: addressId,
                    pickup_time_id: pickupTimeId,
                },
            },
        };
        return this.internalPostRequest<ShopeeDelegateRequest>(
            this.delegateEndpoint,
            request
        ).pipe(
            map<AjaxResponse, string>((ajaxRes: AjaxResponse) => {
                const error = _get(ajaxRes, 'response.error');
                if (error) {
                    return throwError(
                        `Error: ${error} ${_get(ajaxRes, 'response.message')}`
                    );
                }
                return _get(ajaxRes, 'response.request_id');
            })
        );
    }

    getShippingDocInfo(
        orderSn: string,
        recipientNameWidth: number,
        recipientAddrWidth: number,
        recipientAddrFontSize: number = null
    ): Observable<ShopeeShippingDocDataInfo> {
        const request: ShopeeDelegateRequest = {
            servicePath: 'logistics/get_shipping_document_data_info',
            method: 'post',
            parameter: {
                order_sn: orderSn,
                recipient_address_info: [
                    {
                        key: 'name',
                        style: {
                            image_width: recipientNameWidth,
                            h_align: 'left',
                        },
                    },
                    {
                        key: 'full_address',
                        style: {
                            image_width: recipientAddrWidth,
                            h_align: 'left',
                            ...(recipientAddrFontSize && {
                                font_size: recipientAddrFontSize,
                            }),
                        },
                    },
                ],
            },
        };
        return this.internalPostRequest<ShopeeDelegateRequest>(
            this.delegateEndpoint,
            request
        ).pipe(
            map<AjaxResponse, ShopeeShippingDocDataInfo>(
                (ajaxRes: AjaxResponse) => {
                    const error = _get(ajaxRes, 'response.error');
                    if (error) {
                        throw new Error(
                            `Error: ${error} ${_get(
                                ajaxRes,
                                'response.message'
                            )}`
                        );
                    }
                    const response = _get(ajaxRes, 'response.response');
                    const docInfo: ShopeeShippingDocDataInfo = {
                        orderSn: orderSn,
                        recipientNameBase64: null,
                        recipientFullAddrBase64: null,
                        firstRecipientSortCode: _get(
                            response,
                            'shipping_document_info.recipient_sort_code.first_recipient_sort_code'
                        ),
                    };
                    // navigator.clipboard
                    //     .writeText(response.recipient_address_info[1].image)
                    //     .then(
                    //         () => {
                    //             console.log('done');
                    //         },
                    //         (e) => {
                    //             console.error(e);
                    //         }
                    //     );

                    const recipientAddrInfo: { key: string; image: string }[] =
                        _get(response, 'recipient_address_info');
                    if (recipientAddrInfo) {
                        const nameObj: { key: string; image: string } = _find(
                            recipientAddrInfo,
                            { key: 'name' }
                        );
                        if (nameObj.image) {
                            docInfo.recipientNameBase64 = nameObj.image;
                        }

                        const addrObj: { key: string; image: string } = _find(
                            recipientAddrInfo,
                            { key: 'full_address' }
                        );
                        if (nameObj.image) {
                            docInfo.recipientFullAddrBase64 = addrObj.image;
                        }
                    }
                    return docInfo;
                }
            )
        );
    }
}
