<div class="dx-card padding-content">
    <div class="main">
        <div>
            <dx-button-group
                #platformSelector
                stylingMode="contained"
                [items]="PLATFORM_OPTIONS"
                keyExpr="id"
                selectionMode="multiple"
                [(selectedItemKeys)]="selectedPlatforms"
                (onSelectionChanged)="platformSelectionChanged()"
                [focusStateEnabled]="false"
            ></dx-button-group>
        </div>
        <dx-data-grid
            #dataGrid
            [dataSource]="saleSource"
            [showBorders]="true"
            keyExpr="sku"
            [focusedRowEnabled]="false"
            [columnAutoWidth]="false"
            [allowColumnResizing]="true"
            [hoverStateEnabled]="true"
        >
            <dxo-paging [pageSize]="20"></dxo-paging>
            <dxo-pager
                [showPageSizeSelector]="true"
                [showInfo]="true"
            ></dxo-pager>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxi-column dataField="sku" caption="SKU" [width]="90"></dxi-column>
            <dxi-column
                dataField="title"
                caption="Product Title"
                [minWidth]="120"
            ></dxi-column>
            <dxi-column
                dataField="lastSaleDate"
                [width]="110"
                caption="Last Sale"
                cellTemplate="relativeDatetimeCellTemplate"
            ></dxi-column>
            <dxi-column
                dataField="remain"
                [width]="70"
                caption="Stock"
                cellTemplate="stockTemplate"
            ></dxi-column>
            <dxi-column
                dataField="price"
                [width]="80"
                caption="Price"
                cellTemplate="priceTemplate"
            ></dxi-column>
            <dxi-column
                dataField="saleSince.last1MDate"
                caption="1M"
                [width]="80"
                cellTemplate="priceTemplate"
            ></dxi-column>
            <dxi-column
                dataField="saleSince.last3MDate"
                caption="3M"
                [width]="80"
                cellTemplate="priceTemplate"
            ></dxi-column>
            <dxi-column
                dataField="saleSince.last6MDate"
                caption="6M"
                [width]="90"
                cellTemplate="priceTemplate"
            ></dxi-column>
            <dxi-column
                dataField="saleSince.lastYtdDate"
                caption="YTD"
                [width]="90"
                cellTemplate="priceTemplate"
            ></dxi-column>
            <dxi-column
                dataField="saleSince.last1YDate"
                caption="1Y"
                [width]="90"
                cellTemplate="priceTemplate"
            ></dxi-column>
            <dxi-column
                dataField="saleSince.last2YDate"
                caption="2Y"
                [width]="90"
                cellTemplate="priceTemplate"
            ></dxi-column>
            <dxi-column
                dataField="saleSince.last3YDate"
                caption="3Y"
                [width]="90"
                cellTemplate="priceTemplate"
            ></dxi-column>
            <dxi-column
                dataField="saleSince.last10YDate"
                caption="10Y"
                [width]="90"
                cellTemplate="priceTemplate"
            ></dxi-column>
            <div *dxTemplate="let cellData of 'priceTemplate'">
                {{ cellData.value | formatNumber : 2 }}
            </div>
            <div *dxTemplate="let cellData of 'stockTemplate'">
                {{ cellData.value | formatNumber : 0 }}
            </div>
            <div *dxTemplate="let cellData of 'relativeDatetimeCellTemplate'">
                {{ cellData.value | formatRelativeDatetime }}
            </div>
        </dx-data-grid>
    </div>
</div>
