import { Injectable } from '@angular/core';
import {
    map as _map,
    isEmpty as _isEmpty,
    isArray as _isArray,
    toNumber as _toNumber,
    isString as _isString,
} from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class UtilService {
    constructor() {}

    errorToString(inputError: any) {
        if (_isArray(inputError) && !_isEmpty(inputError)) {
            return _map(inputError, (error) => {
                return this.errorToString(error);
            }).join(', ');
        }
        return (
            inputError.msg || inputError.message || JSON.stringify(inputError)
        );
    }

    localStringToNumber(value: string | number): number {
        if (_isString(value)) {
            value = value.replace(/,/g, '');
        }
        return _toNumber(value);
    }
}
