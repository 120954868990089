<dx-load-panel
    #loadPanel
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: '#tabPanel' }"
    [(visible)]="loadingVisible"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
>
</dx-load-panel>
<dx-popup
    id="popupComponent"
    #popupComponent
    [showTitle]="false"
    [dragEnabled]="true"
    [hideOnOutsideClick]="true"
    [(visible)]="popupVisible"
    height="95%"
    width="95%"
    (onHiding)="onPopupClose($event)"
>
    <div *dxTemplate="let cellData of 'checkedCellTemplate'">
        <ng-container *ngIf="cellData.value">
            <dx-check-box [value]="true" [disabled]="true"></dx-check-box>
        </ng-container>
    </div>
    <dx-data-grid
        #shopeeDeliveryDataGrid
        id="shopeeDeliveryDataGrid"
        class="header"
        (onToolbarPreparing)="onToolbarPreparingOnlyPickup($event)"
        [dataSource]="orders"
        keyExpr="order_sn"
        [showBorders]="true"
    >
        <dxo-editing mode="row" [allowUpdating]="true"> </dxo-editing>
        <dxi-column
            dataField="$selectedTimeSlotId"
            caption="Pickup Time Slot"
            [calculateDisplayValue]="getTimeSlotDisplay"
            [width]="240"
            [allowEditing]="true"
        >
            <dxo-lookup
                [dataSource]="getTimeSlots"
                valueExpr="pickup_time_id"
                displayExpr="displayValue"
            >
            </dxo-lookup>
        </dxi-column>
        <dxi-column
            dataField="shipping_carrier"
            caption="Shipping Carrier"
            [allowEditing]="false"
            [width]="150"
            alignment="left"
            sortOrder="asc"
        ></dxi-column>
        <dxi-column
            dataField="create_time"
            caption="Creation Time"
            [allowEditing]="false"
            [width]="150"
            cellTemplate="datetimeCellTemplate"
            alignment="left"
        ></dxi-column>
        <dxi-column
            dataField="order_sn"
            [allowEditing]="false"
            caption="Order SN"
            [width]="150"
        ></dxi-column>
        <dxi-column
            dataField="buyer_username"
            [allowEditing]="false"
            caption="Buyer"
            [minWidth]="200"
        ></dxi-column>
        <dxi-column
            caption="Recipient Name"
            [allowEditing]="false"
            dataField="recipient_address.name"
            [minWidth]="200"
        ></dxi-column>
        <div *dxTemplate="let cellData of 'datetimeCellTemplate'">
            {{ cellData.value | shopeeFormatDateTime }}
        </div>
        <div *dxTemplate="let cellData of 'pickupHeaderText'">
            <span style="font-size: large"
                >Shopee Pickup ({{ orders ? orders.length : 0 }}
                {{ orders && orders.length === 1 ? "Order" : "Orders" }})</span
            >
        </div>
    </dx-data-grid>
</dx-popup>
