import {
    isEmpty as _isEmpty,
    isString as _isString,
    toUpper as _toUpper,
} from 'lodash';
import crc16 from 'crc/crc16';

export class PrintSheetUtils {
    static constructItemDescription(
        sku: string,
        description: string,
        quantity: number,
        variation?: string,
        variationSku?: string
    ) {
        let descString = `[${variationSku || sku}] ${description}`;
        if (quantity > 1) {
            descString = `(${quantity}) ${descString}`;
        }
        if (variation) {
            descString = `${descString} - ${variation}`;
        }
        return descString;
    }

    static constructCodCode(isCod: boolean) {
        return isCod ? 'COD' : 'NON COD';
    }

    static generateMatchingNumber(orderSn: string) {
        if (_isString(orderSn) && !_isEmpty(orderSn)) {
            return _toUpper(crc16(orderSn).toString(16)).padStart(4, '0');
        }
        return '';
    }

    static base64ToBlob(base64: string, type = 'application/octet-stream') {
        const binStr = atob(base64);
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
        }
        return new Blob([arr], { type });
    }
}
