<div class="dx-card padding-content">
    <dx-toolbar #toolbar>
        <dxi-item
            *ngIf="deferSelectedOrderStatus === OrderStatus.Pending"
            location="before"
            widget="dxButton"
            [options]="readyToShipButtonOptions"
            [disabled]="isDataLoading || selectedRowKeys.length === 0"
        ></dxi-item>
        <dxi-item
            *ngIf="
                deferSelectedOrderStatus === OrderStatus.ReadyToShip ||
                deferSelectedOrderStatus === OrderStatus.Shipped ||
                deferSelectedOrderStatus === OrderStatus.Delivered
            "
            location="before"
            widget="dxButton"
            [options]="printBothButtonOptions"
            [disabled]="isDataLoading || selectedRowKeys.length === 0"
        ></dxi-item>
        <dxi-item
            *ngIf="
                deferSelectedOrderStatus === OrderStatus.ReadyToShip ||
                deferSelectedOrderStatus === OrderStatus.Shipped ||
                deferSelectedOrderStatus === OrderStatus.Delivered
            "
            location="before"
            widget="dxButton"
            [options]="printInvoiceButtonOptions"
            [disabled]="isDataLoading || selectedRowKeys.length === 0"
        ></dxi-item>
        <dxi-item
            *ngIf="
                deferSelectedOrderStatus === OrderStatus.ReadyToShip ||
                deferSelectedOrderStatus === OrderStatus.Shipped ||
                deferSelectedOrderStatus === OrderStatus.Delivered
            "
            location="before"
            widget="dxButton"
            [options]="printShippingLabelButtonOptions"
            [disabled]="isDataLoading || selectedRowKeys.length === 0"
        ></dxi-item>
        <dxi-item widget="dxButton" location="before" locateInMenu="never">
            <div *dxTemplate>
                <div
                    *ngIf="selectedRowKeys.length > 0 && !isDataLoading"
                    class="toolbar-label"
                >
                    <b>{{ selectedRowKeys.length }}&nbsp;selected</b>
                </div>
            </div>
        </dxi-item>
        <dxi-item location="center" text="Lazada"></dxi-item>
        <dxi-item
            location="after"
            widget="dxButton"
            [options]="refreshButtonOptions"
            [disabled]="isDataLoading"
        ></dxi-item>
    </dx-toolbar>
    <dx-tabs
        #statusTabs
        keyExpr="key"
        [items]="orderStatuses"
        [scrollByContent]="true"
        [showNavButtons]="true"
        (onSelectionChanged)="orderStatusSelectionChanged($event)"
        [(selectedItem)]="selectedTabItem"
    ></dx-tabs>

    <dx-data-grid
        #dataGrid
        id="lazadaOrderGrid"
        [showBorders]="true"
        [dataSource]="orderSource"
        [(selectedRowKeys)]="selectedRowKeys"
        [masterDetail]="{ enabled: true, template: 'lazadaOrderDetails' }"
        [hoverStateEnabled]="true"
        [disabled]="isDataLoading"
        keyExpr="order_id"
    >
        <div *dxTemplate="let cellData of 'lazadaDatetimeCellTemplate'">
            {{ cellData.value | lazadaFormatDateTime }}
        </div>

        <div *dxTemplate="let cellData of 'relativeDatetimeCellTemplate'">
            {{ cellData.value | formatRelativeDatetime }}
        </div>
        <div *dxTemplate="let row of 'lazadaOrderDetails'">
            <dx-form id="form" [colCount]="5">
                <dxi-item itemType="group" caption="Order Details">
                    <div class="dx-field">
                        <div class="dx-field-label">Order ID</div>
                        <div class="dx-field-value-static">
                            {{ row.data.order_id }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="dx-field-label">Status</div>
                        <div class="dx-field-value-static">
                            {{ row.data.statuses[0] }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="dx-field-label">Remarks</div>
                        <div class="dx-field-value-static">
                            {{ row.data.remarks }}
                        </div>
                    </div>
                </dxi-item>
                <dxi-item itemType="group" caption="Date">
                    <div class="dx-field">
                        <div class="dx-field-label">Creation Time</div>
                        <div class="dx-field-value-static">
                            {{ row.data.created_at | lazadaFormatDateTime }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="dx-field-label">Update Time</div>
                        <div class="dx-field-value-static">
                            {{ row.data.updated_at | lazadaFormatDateTime }}
                        </div>
                    </div>
                </dxi-item>
                <dxi-item itemType="group" caption="Billing Info">
                    <div class="dx-field">
                        <div class="dx-field-label">Name</div>
                        <div class="dx-field-value-static">
                            {{ row.data.billingFullName }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="dx-field-label">Address</div>
                        <div class="dx-field-value-static">
                            {{ row.data.billingAddress }}
                        </div>
                    </div>
                </dxi-item>
                <dxi-item itemType="group" caption="Shipping Info">
                    <div class="dx-field">
                        <div class="dx-field-label">Name</div>
                        <div class="dx-field-value-static">
                            {{ row.data.shippingFullName }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="dx-field-label">Address</div>
                        <div class="dx-field-value-static">
                            {{ row.data.shippingAddress }}
                        </div>
                    </div>
                </dxi-item>
                <dxi-item itemType="group" caption="Payment">
                    <div class="dx-field">
                        <div class="dx-field-label">Payment Method</div>
                        <div class="dx-field-value-static">
                            {{ row.data.payment_method }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="dx-field-label">Total Amount</div>
                        <div class="dx-field-value-static">
                            {{ row.data.price | formatNumber : 2 }}
                        </div>
                    </div>
                </dxi-item>
            </dx-form>
            <dx-data-grid
                [showBorders]="true"
                [dataSource]="row.data.groupedOrderItems"
            >
                <dxi-column
                    dataField="itemNo"
                    caption="Item No."
                    [width]="120"
                    dataType="number"
                ></dxi-column>
                <dxi-column
                    dataField="description"
                    caption="Description"
                    [minWidth]="120"
                ></dxi-column>
                <dxi-column
                    dataField="quantity"
                    caption="Quantity"
                    [width]="120"
                    dataType="number"
                ></dxi-column>
                <dxi-column
                    dataField="unitPrice"
                    caption="Unit Price"
                    [width]="120"
                    dataType="number"
                    cellTemplate="priceTemplate"
                ></dxi-column>
                <dxi-column
                    dataField="amount"
                    caption="Amount"
                    [width]="120"
                    dataType="number"
                    cellTemplate="priceTemplate"
                ></dxi-column>
                <div *dxTemplate="let cellData of 'priceTemplate'">
                    {{ cellData.value | formatNumber : 2 }}
                </div>
            </dx-data-grid>
        </div>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-selection
            selectAllMode="allPages"
            showCheckBoxesMode="onClick"
            mode="{{
                deferSelectedOrderStatus === OrderStatus.Pending ||
                deferSelectedOrderStatus === OrderStatus.ReadyToShip ||
                deferSelectedOrderStatus === OrderStatus.Shipped ||
                deferSelectedOrderStatus === OrderStatus.Delivered
                    ? 'multiple'
                    : 'none'
            }}"
        ></dxo-selection>
        <dxo-paging [pageSize]="18"> </dxo-paging>
        <dxo-pager
            [visible]="true"
            [allowedPageSizes]="18"
            displayMode="full"
            [showInfo]="true"
            [showNavigationButtons]="true"
        >
        </dxo-pager>
        <dxi-column
            dataField="lastPrintMoment"
            caption="Last Print"
            [width]="140"
            cellTemplate="relativeDatetimeCellTemplate"
            *ngIf="
                deferSelectedOrderStatus === OrderStatus.ReadyToShip ||
                deferSelectedOrderStatus === OrderStatus.Shipped ||
                deferSelectedOrderStatus === OrderStatus.Delivered
            "
        ></dxi-column>
        <dxi-column
            dataField="$created_at_date"
            dataType="datetime"
            caption="Creation Time"
            [width]="180"
            cellTemplate="lazadaDatetimeCellTemplate"
            alignment="left"
        >
        </dxi-column>
        <dxi-column
            dataField="order_id"
            caption="Order ID"
            [width]="160"
            alignment="left"
        ></dxi-column>
        <dxi-column
            dataField="matching"
            caption="Matching"
            [width]="100"
        ></dxi-column>
        <dxi-column
            dataField="customer_first_name"
            caption="Customer"
            [minWidth]="150"
        ></dxi-column>
        <dxi-column
            caption="Recipient Name"
            dataField="address_shipping.first_name"
            [minWidth]="150"
        ></dxi-column>
        <dxi-column
            dataField="payment_method"
            caption="Payment Method"
        ></dxi-column>
        <dxi-column
            dataField="price"
            caption="Price"
            [width]="120"
            dataType="number"
            cellTemplate="priceTemplate"
        ></dxi-column>
        <div *dxTemplate="let cellData of 'priceTemplate'">
            {{ cellData.value | formatNumber : 2 }}
        </div>
        <dxi-column
            dataField="items_count"
            caption="Item Count"
            [width]="110"
            dataType="number"
        ></dxi-column>
        <dxi-column
            *ngIf="
                deferSelectedOrderStatus === OrderStatus.ReadyToShip ||
                deferSelectedOrderStatus === OrderStatus.Shipped ||
                deferSelectedOrderStatus === OrderStatus.Delivered
            "
            dataField="orderItems[0].tracking_code"
            caption="Tracking No"
            [width]="150"
        ></dxi-column>
        <dxi-column
            dataField="remarks"
            caption="Remarks"
            *ngIf="hasRemarks"
        ></dxi-column>

        <dxo-summary>
            <dxi-total-item
                column="order_id"
                summaryType="count"
                valueFormat="#,##0"
                displayFormat="Total {0} order(s)"
            >
            </dxi-total-item>
            <dxi-total-item
                column="price"
                summaryType="sum"
                valueFormat="฿ #,##0.00"
                displayFormat="{0}"
            >
            </dxi-total-item>
            <dxi-total-item
                column="items_count"
                summaryType="sum"
                valueFormat="#,##0"
                displayFormat="{0} item(s)"
            >
            </dxi-total-item>
        </dxo-summary>
    </dx-data-grid>
</div>
