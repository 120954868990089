<div class="dx-card padding-content">
    <dx-toolbar #toolbar>
        <dxi-item
            location="before"
            widget="dxButton"
            [disabled]="isDataLoading"
            [options]="exportExcelButtonOptions"
        ></dxi-item>
        <dxi-item
            location="center"
            widget="dxCheckBox"
            [disabled]="isDataLoading"
            [options]="hideDiscontinuedProductCheckBoxOptions"
        ></dxi-item>
        <dxi-item widget="dxButton" location="center" locateInMenu="never">
            <div *dxTemplate>
                <div *ngIf="discontinuedItemNum > 0 && !isDataLoading">
                    <b>({{ discontinuedItemNum }}&nbsp;items)</b>
                </div>
            </div>
        </dxi-item>
        <dxi-item
            location="after"
            widget="dxButton"
            [disabled]="isDataLoading"
            [options]="refreshInventoryButtonOptions"
        ></dxi-item>
    </dx-toolbar>
    <dx-data-grid
        #dataGrid
        [dataSource]="pricelistSource"
        keyExpr="pid"
        [focusedRowEnabled]="false"
        [columnAutoWidth]="false"
        [allowColumnResizing]="true"
    >
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-filter-builder> </dxo-filter-builder>
        <dxo-filter-builder-popup [width]="400" title="Inventory Filter">
        </dxo-filter-builder-popup>
        <dxo-paging [pageSize]="20"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [showInfo]="true"></dxo-pager>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column
            [width]="120"
            caption="Updated"
            [calculateCellValue]="getItemLastUpdated"
            ><dxo-format formatter="formatLastUpdated"> </dxo-format
        ></dxi-column>
        <dxi-column dataField="sku" caption="SKU" [width]="120"></dxi-column>
        <dxi-column
            dataField="title"
            caption="Product Title"
            [minWidth]="120"
        ></dxi-column>
        <dxi-column
            dataField="price"
            [width]="120"
            caption="Price"
            dataType="number"
            cellTemplate="priceTemplate"
        ></dxi-column>
        <dxi-column
            dataField="sell"
            [width]="120"
            caption="Sold"
            dataType="number"
            cellTemplate="numTemplate"
        ></dxi-column>
        <dxi-column
            dataField="remain"
            [width]="120"
            caption="Remain"
            dataType="number"
            cellTemplate="numTemplate"
        ></dxi-column>
        <div *dxTemplate="let cellData of 'priceTemplate'">
            {{ cellData.value | formatNumber : 2 }}
        </div>
        <div *dxTemplate="let cellData of 'numTemplate'">
            {{ cellData.value | formatNumber : 0 }}
        </div>
    </dx-data-grid>
</div>
