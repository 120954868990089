import { Injectable } from '@angular/core';
import {
    map as _map,
    toNumber as _toNumber,
    get as _get,
    isFinite as _isFinite,
    includes as _includes,
} from 'lodash';
import moment from 'moment';
import {
    inputMomentFormat,
    ProductInfo,
    QuoteInput,
} from '../models/quote-input';
import { branchList, deliveryCourierList } from '../models/input-choices';

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    private dateTimeMessageMomentFormat = 'YYYY-MM-DD HH:mm:ss';

    constructor() {}

    fillInputFromMessage(message: any, target: QuoteInput) {
        this.fillQuoteInfo(message, target);
        this.fillCustomerInfo(message, target);
        this.fillShippingInfo(message, target);
        this.fillProducts(message, target);
    }

    private fillQuoteInfo(message, target: QuoteInput): void {
        target.quoteInfo.quoteNo = _get(message, 'id', '');

        const orderDtFromMsg: string = _get(message, 'order_time', '');
        if (orderDtFromMsg) {
            target.quoteInfo.date = this.parseMessageDateTime(orderDtFromMsg);
        }

        // const expireDtFromMsg: string = _get(message, 'expire_time', '');
        // if (expireDtFromMsg) {
        //     target.quoteInfo.validUntil =
        //         this.parseMessageDateTime(expireDtFromMsg);
        // }

        const discount: number = _toNumber(_get(message, 'discount_price', ''));
        if (_isFinite(discount)) {
            target.quoteInfo.discount = discount;
        }
    }

    private fillCustomerInfo(message, target: QuoteInput): void {
        const receiptInfo = _get(message, 'receipt');
        if (receiptInfo) {
            target.customerInfo.name = _get(receiptInfo, 'full_name', '');
            target.customerInfo.taxId = _get(receiptInfo, 'tax_id', '');

            const branchIdFromMsg = _get(receiptInfo, 'branch_id', NaN);
            const branchIdNum: number = _toNumber(branchIdFromMsg);
            if (_isFinite(branchIdNum)) {
                if (branchIdNum < branchList.length) {
                    target.customerInfo.branch = branchList[branchIdNum];
                } else {
                    target.customerInfo.branch = `สาขาที่ ${branchIdFromMsg}`;
                }
            }

            target.customerInfo.useCustomAddress = true;
            target.customerInfo.customAddress = _get(
                receiptInfo,
                'address',
                ''
            );
        } else {
            const receiverInfo = _get(message, 'receiver');
            if (receiverInfo) {
                target.customerInfo.name = _get(receiverInfo, 'full_name', '');

                target.customerInfo.useCustomAddress = false;
                target.customerInfo.postCode = _get(
                    receiverInfo,
                    'zipcode',
                    ''
                );
                target.customerInfo.province = _get(
                    receiverInfo,
                    'province',
                    ''
                );
                target.customerInfo.district = _get(
                    receiverInfo,
                    'district',
                    ''
                );
                target.customerInfo.subDistrict = _get(
                    receiverInfo,
                    'subdistrict',
                    ''
                );
                target.customerInfo.address1 = _get(
                    receiverInfo,
                    'address',
                    ''
                );
            }
        }
    }

    private fillShippingInfo(message, target: QuoteInput): void {
        const shippingStrFromMessage: string = _get(
            message,
            'shipping_type_text',
            ''
        );

        if (shippingStrFromMessage) {
            const shippingStrFromMessageLowerCase =
                shippingStrFromMessage.toLowerCase();
            if (_includes(shippingStrFromMessageLowerCase, 'ems')) {
                target.quoteInfo.shippingCarrier = deliveryCourierList[0];
            } else if (_includes(shippingStrFromMessage, 'ลงทะเบียน')) {
                target.quoteInfo.shippingCarrier = deliveryCourierList[1];
            } else if (_includes(shippingStrFromMessageLowerCase, 'kerry')) {
                target.quoteInfo.shippingCarrier = deliveryCourierList[2];
            } else {
                target.quoteInfo.shippingCarrier = shippingStrFromMessage;
            }
        }

        const discount: number = _toNumber(_get(message, 'shipping_price', ''));
        if (_isFinite(discount)) {
            target.quoteInfo.shippingCost = discount;
        }
    }

    private fillProducts(message, target: QuoteInput) {
        const productsFromMsg = _get(message, 'products', '');
        if (productsFromMsg) {
            target.productItems = _map<any, ProductInfo>(
                productsFromMsg,
                (msgItem, index: number) => {
                    let title: string = _get(msgItem, 'title', '');
                    const sku: string = _get(msgItem, 'code', '');
                    if (sku) {
                        title = `[${sku}] ${title}`;
                    }
                    const quantity: string = _get(msgItem, 'quantity', '');
                    const price: string = _get(msgItem, 'price', '');
                    const amount: string = _get(msgItem, 'amount', '');
                    return {
                        itemNo: index + 1,
                        description: title,
                        quantity: _toNumber(quantity),
                        unitPrice: _toNumber(price),
                        amount: _toNumber(amount),
                    };
                }
            );
        }
    }

    private parseMessageDateTime(dtFromMsg: string): string {
        const momentObj = moment(dtFromMsg, this.dateTimeMessageMomentFormat);
        if (momentObj.isValid) {
            return momentObj.format(inputMomentFormat);
        }
        return '';
    }
}
