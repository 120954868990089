import { Component, HostListener } from '@angular/core';
import { get as _get, isString as _isString } from 'lodash';
import { Counterparty } from 'src/app/pages/counterparty/components/counterparty-finder/counterparty-finder.component';

@Component({
    selector: 'app-receipt-info-filler',
    templateUrl: './receipt-info-filler.component.html',
    styleUrls: ['./receipt-info-filler.component.scss'],
})
export class ReceiptInfoFillerComponent {
    messageSourceWindow: Window = null;
    SCOPE = 'receipt-info-filler';

    @HostListener('window:message', ['$event'])
    onMessage(event): void {
        const scope = _get(event, 'data.scope');
        const status = _get(event, 'data.status');
        const data = _get(event, 'data.data');
        if (
            scope === this.SCOPE &&
            status === 200 &&
            _isString(data) &&
            data === 'ready'
        ) {
            this.messageSourceWindow = event.source;
        }
    }

    onCtpSelectionChanged(ctp: Counterparty) {
        if (this.messageSourceWindow) {
            console.log('posted message');
            this.messageSourceWindow.postMessage(
                {
                    scope: this.SCOPE,
                    action: 'ctp-selected',
                    param: ctp,
                },
                '*'
            );
        } else {
            console.error('No connection');
        }
    }
}
