import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import {
    AvgResult,
    MessageType,
    NotificationService,
    SaleAnalyticResult,
    SaleService,
    UtilService,
} from 'src/app/shared/services';
import {
    get as _get,
    map as _map,
    each as _each,
    uniq as _uniq,
    meanBy as _meanBy,
    sumBy as _sumBy,
} from 'lodash';
import {
    ChartPoint,
    DistChartService,
} from './services/distribution-chart.service';
import { DxChartComponent } from 'devextreme-angular';

@Component({
    selector: 'app-product-performance',
    templateUrl: './product-performance.component.html',
    styleUrls: ['./product-performance.component.scss'],
})
export class ProductPerformanceComponent {
    @ViewChild('productChart', { static: true })
    private productChart: DxChartComponent;
    @ViewChild('distChart', { static: false })
    private distChart: DxChartComponent;
    pieChartData: any[] = [
        {
            region: 'Performing',
            val: 0,
        },
        {
            region: 'Non-performing',
            val: 0,
        },
    ];
    rangeOptions: RangeOption[] = [
        { id: '3m', text: '3M', monthNum: 3 },
        { id: '6m', text: '6M', monthNum: 6 },
        { id: '1y', text: '1Y', monthNum: 12 },
        { id: '2y', text: '2Y', monthNum: 24 },
    ];
    selectedRangeOptions: RangeOption[] = [this.rangeOptions[2]];
    distributionChartData: ChartPoint[];
    performingProductSource: ProductPerformanceGridItem[] = [];
    nonPerformingProductSource: ProductPerformanceGridItem[] = [];
    excludedProductSource: ExcludedProductGridItem[] = [];
    isDataLoading = false;
    performingCutOffValue = 300;
    productAvgMonthly = 0;
    totalAvgMonthly = 0;

    constructor(
        private cdref: ChangeDetectorRef,
        private saleService: SaleService,
        private notificationService: NotificationService,
        private utilService: UtilService,
        private distChartService: DistChartService
    ) {}

    onClick(): void {
        if (this.selectedRangeOptions.length > 0) {
            const monthNum: number = this.selectedRangeOptions[0].monthNum;
            this.isDataLoading = true;
            this.saleService.getProductSaleAnalyticRecord().subscribe(
                (result: SaleAnalyticResult) => {
                    this.fillPerformanceData(result, monthNum);
                },
                (error) => {
                    console.error(error);
                    this.notificationService.pushMessage({
                        message: this.utilService.errorToString(error),
                        messageType: MessageType.error,
                    });
                },
                () => {
                    this.isDataLoading = false;
                }
            );
        }
    }

    customizePieChartLabel(point) {
        return `${point.valueText} (${point.percentText})`;
    }

    private fillPerformanceData(
        result: SaleAnalyticResult,
        monthNum: number
    ): void {
        this.excludedProductSource = _map(
            _uniq(_map(result.noProductDetailSaleRecords, 'pid')),
            (pid: number) => {
                return {
                    product: `PID: ${pid}`,
                    reason: 'Missing product data',
                };
            }
        );

        _each(_uniq(_map(result.invalidSaleRecords, 'pid')), (pid: number) => {
            this.excludedProductSource.push({
                product: `PID: ${pid}`,
                reason: 'Invalid sale record',
            });
        });

        this.performingProductSource = [];
        this.nonPerformingProductSource = [];
        const avgResults: AvgResult[] = [];
        const pidKeys: number[] = [...result.productAnalyticRecordMap.keys()];
        _each(pidKeys, (pid) => {
            const avgResult: AvgResult = result.productAnalyticRecordMap
                .get(pid)
                .getAvgMonthlyRevenue(monthNum);
            avgResults.push(avgResult);

            const sku = _get(result.productDetailMap.get(pid), 'sku');
            const name = _get(result.productDetailMap.get(pid), 'title');
            const remain = _get(result.productDetailMap.get(pid), 'remain');
            const price = _get(result.productDetailMap.get(pid), 'price');
            const stock_status = _get(
                result.productDetailMap.get(pid),
                'stock_status'
            );

            if (stock_status === 'discontinued') {
                this.excludedProductSource.push({
                    product: `[${sku}] ${name}`,
                    reason: 'Discontinued',
                });
            } else {
                const avgSale = avgResult.avg;
                const usingMonths = avgResult.dividedBy;
                if (avgSale >= this.performingCutOffValue) {
                    this.performingProductSource.push({
                        sku,
                        name,
                        price,
                        avgSale,
                        remain,
                        usingMonths,
                    });
                } else {
                    this.nonPerformingProductSource.push({
                        sku,
                        name,
                        price,
                        avgSale,
                        remain,
                        usingMonths,
                    });
                }
            }
        });
        this.productAvgMonthly = _meanBy(avgResults, 'avg');
        this.totalAvgMonthly = _sumBy(avgResults, 'avg');
        this.pieChartData = [
            {
                region: 'Performing',
                val: this.performingProductSource.length,
            },
            {
                region: 'Non-performing',
                val: this.nonPerformingProductSource.length,
            },
        ];

        this.distributionChartData =
            this.distChartService.getProductDistributionChartData(
                _map(avgResults, 'avg')
            );
    }
}

interface ProductPerformanceGridItem {
    sku: string;
    name: string;
    price: string;
    avgSale: number;
    remain: number;
    usingMonths: number;
}

interface ExcludedProductGridItem {
    product: string;
    reason: string;
}

interface RangeOption {
    id: string;
    text: string;
    monthNum: number;
}
