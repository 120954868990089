/* eslint-disable @typescript-eslint/naming-convention */
export enum OrderStatus {
    Unpaid = 'unpaid',
    Pending = 'pending',
    Canceled = 'canceled',
    ReadyToShip = 'ready_to_ship',
    Delivered = 'delivered',
    Returned = 'returned',
    Shipped = 'shipped',
    Failed = 'failed',
}
