// Do not change order.
const branchList: string[] = [
    'สำนักงานใหญ่',
    'สาขาที่ 00001',
    'สาขาที่ 00002',
    'สาขาที่ 00003',
    'สาขาที่ 00004',
    'สาขาที่ 00005',
    'สาขาที่ 00006',
    'สาขาที่ 00007',
    'สาขาที่ 00008',
    'สาขาที่ 00009',
    'สาขาที่ 00010',
];

// Do not change order.
const deliveryCourierList: string[] = ['EMS', 'ไปรษณีย์ลงทะเบียน', 'Kerry'];

const deliveryTermList: string[] = ['ภายใน 3 วันหลังสั่งซื้อ'];

const sellerInfo = {
    name: 'บริษัท เจนเนอรัล ลอจิค จํากัด',
    branch: 'สำนักงานใหญ่',
    taxId: '0105567017277',
    website: 'https://www.genlogic.co.th/',
    address: '111/12 ซ.โชคชัยร่วมมิตร แขวงจอมพล เขตจุจักร กรุงเทพมหานคร 10900',
    email: 'sale@genlogic.co.th',
};

const monthSelection = [
    {
        id: 1,
        text: 'มกราคม',
    },
    {
        id: 2,
        text: 'กุมภาพันธ์',
    },
    {
        id: 3,
        text: 'มีนาคม',
    },
    {
        id: 4,
        text: 'เมษายน',
    },
    {
        id: 5,
        text: 'พฤษภาคม',
    },
    {
        id: 6,
        text: 'มิถุนายน',
    },
    {
        id: 7,
        text: 'กรกฎาคม',
    },
    {
        id: 8,
        text: 'สิงหาคม',
    },
    {
        id: 9,
        text: 'กันยายน',
    },
    {
        id: 10,
        text: 'ตุลาคม',
    },
    {
        id: 11,
        text: 'พฤศจิกายน',
    },
    {
        id: 12,
        text: 'ธันวาคม',
    },
];

export {
    branchList,
    deliveryCourierList,
    deliveryTermList,
    sellerInfo,
    monthSelection,
};
