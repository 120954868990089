import { Component, AfterViewInit, OnInit, ViewChild } from '@angular/core';
import 'devextreme/data/odata/store';
import {
    AnalyticPlatform,
    SaleAnalyticService,
    MessageType,
    NotificationService,
    UtilService,
} from 'src/app/shared/services';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { map as _map, isEmpty as _isEmpty } from 'lodash';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'app-sale-time',
    templateUrl: './sale-time.component.html',
    styleUrls: ['./sale-time.component.scss'],
})
export class SaleTimeComponent implements AfterViewInit, OnInit {
    @ViewChild('dataGrid', { static: true })
    private dataGrid: DxDataGridComponent;

    PLATFORM_OPTIONS = [
        { id: AnalyticPlatform.LnwShop, text: 'LnwShop' },
        { id: AnalyticPlatform.Lazada, text: 'Lazada' },
        { id: AnalyticPlatform.Shopee, text: 'Shopee' },
    ];

    saleSource: object[] = [];
    selectedPlatforms: AnalyticPlatform[] = [
        AnalyticPlatform.LnwShop,
        AnalyticPlatform.Lazada,
        AnalyticPlatform.Shopee,
    ];

    private fetchSaleReportSubject = new Subject();

    constructor(
        private notificationService: NotificationService,
        private saleAnalyticService: SaleAnalyticService,
        private utilService: UtilService
    ) {}

    ngOnInit(): void {
        this.fetchSaleReportSubject
            .pipe(
                switchMap(() => {
                    this.dataGrid.instance.beginCustomLoading(null);
                    return this.getFetchSaleReportObservable();
                })
            )
            .subscribe(
                (results) => {
                    this.saleSource = results;
                    this.dataGrid.instance.endCustomLoading();
                },
                (err) => {
                    console.error(err);
                    this.dataGrid.instance.endCustomLoading();
                    this.notificationService.pushMessage({
                        displayTime: 6000,
                        message: this.utilService.errorToString(err),
                        messageType: MessageType.error,
                    });
                }
            );
    }

    ngAfterViewInit(): void {
        this.fetchSaleReportSubject.next();
    }

    platformSelectionChanged(): void {
        if (_isEmpty(this.selectedPlatforms)) {
            this.selectedPlatforms = _map(this.PLATFORM_OPTIONS, 'id');
        }
        this.fetchSaleReportSubject.next();
    }

    private getFetchSaleReportObservable() {
        return this.saleAnalyticService.get(this.selectedPlatforms);
    }
}
