<dx-popup
    #popupComponent
    [showTitle]="true"
    title="{{ get(productDetail, 'title') }}"
    [dragEnabled]="true"
    [hideOnOutsideClick]="true"
    [(visible)]="popupVisible"
    height="450px"
    width="1000px"
>
    <dx-box direction="row">
        <dxi-item [ratio]="1">
            <dx-gallery
                #gallery
                [stretchImages]="true"
                [dataSource]="get(productDetail, 'images')"
                [loop]="true"
                width="320px"
                height="320px"
                [showIndicator]="true"
            >
                <div *dxTemplate="let image of 'images'">
                    <img src="{{ image }}" />
                </div>
            </dx-gallery>
        </dxi-item>
        <dxi-item class="left-margin" [ratio]="2">
            <dx-box direction="row">
                <dxi-item [ratio]="2">
                    <div class="dx-field">
                        <div class="dx-field-label bold-text">Category</div>
                        <div class="dx-field-value-static">
                            {{ get(productDetail, "cat_name") }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="dx-field-label bold-text">PID</div>
                        <div class="dx-field-value-static">
                            {{ get(productDetail, "pid") }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="dx-field-label bold-text">SKU</div>
                        <div class="dx-field-value-static">
                            {{ get(productDetail, "sku") }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="dx-field-label bold-text">Status</div>
                        <div class="dx-field-value-static">
                            {{ get(productDetail, "is_show") }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="dx-field-label bold-text">
                            Creation Date
                        </div>
                        <div class="dx-field-value-static">
                            {{ get(productDetail, "created_time") }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="dx-field-label bold-text">Modify Date</div>
                        <div class="dx-field-value-static">
                            {{ get(productDetail, "modified_time") }}
                        </div>
                    </div>
                </dxi-item>
                <dxi-item class="left-margin" [ratio]="2">
                    <div class="dx-field">
                        <div class="dx-field-label bold-text">Remain</div>
                        <div class="dx-field-value-static text-align-right">
                            {{ get(productDetail, "remain") }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="dx-field-label bold-text">Sold</div>
                        <div class="dx-field-value-static text-align-right">
                            {{ get(productDetail, "remain") }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <div class="dx-field-label bold-text">Price</div>
                        <div class="dx-field-value-static text-align-right">
                            {{ get(productDetail, "price") }}
                        </div>
                    </div>
                    <div class="dx-field">
                        <dx-button
                            stylingMode="contained"
                            text="Edit Product Detail"
                            type="normal"
                            width="100%"
                        >
                        </dx-button>
                    </div>
                </dxi-item>
            </dx-box>
        </dxi-item>
    </dx-box>
</dx-popup>

<dx-load-panel
    #loadPanel
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: '#popupComponent' }"
    [(visible)]="loadingVisible"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
>
</dx-load-panel>
