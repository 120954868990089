import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
} from '@angular/core';

@Component({
    selector: 'app-counterparty-card',
    templateUrl: './counterparty-card.component.html',
    styleUrls: ['./counterparty-card.component.scss'],
})
export class CounterpartyCardComponent {
    @Input() ctpVm: CounterpartyCardViewModel;

    @Output() selectButtonClickEvent =
        new EventEmitter<CounterpartyCardViewModel>();

    @HostBinding('style.border-color')
    overflowY = 'scroll';

    constructor(public elementRef: ElementRef) {}

    onSelectButtonClick() {
        this.selectButtonClickEvent.emit(this.ctpVm);
    }

    @HostListener('mouseenter') onMouseEnter() {
        this.elementRef.nativeElement.classList.add('card-hover');
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.elementRef.nativeElement.classList.remove('card-hover');
    }
}

export interface CounterpartyCardViewModel {
    id: string;
    companyName: string;
    taxId: string;
    branch: string;
    address: string;
    isDbdSource: boolean;
}
