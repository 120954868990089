<div #mainPanel class="main dx-card padding-content">
    <div class="menu-bar">
        <div class="start">
            <div class="accent-text" style="white-space: nowrap">
                รายงานภาษีขาย
            </div>
        </div>
        <div class="center">
            <div>ปี</div>
            <dx-number-box
                [showSpinButtons]="true"
                [min]="2020"
                [max]="2034"
                [width]="100"
                [(value)]="selectedYear"
                [disabled]="isLoading"
                (onValueChanged)="onEditorValueChanged()"
            ></dx-number-box>
            <div class="separator">|</div>
            <div>เดือน</div>
            <dx-select-box
                [items]="monthSelection"
                [(value)]="selectedMonth"
                [(displayValue)]="selectedMonthDisplay"
                valueExpr="id"
                displayExpr="text"
                [width]="150"
                (onValueChanged)="onEditorValueChanged()"
                [disabled]="isLoading"
            ></dx-select-box>
            <div class="separator">|</div>
            <dx-button
                text="แสดง"
                [type]="isEditorsDirty ? 'default' : 'normal'"
                [stylingMode]="isEditorsDirty ? '' : 'outlined'"
                [disabled]="isLoading"
                (onClick)="loadDataSource()"
            ></dx-button>
        </div>
        <div class="end">
            <div style="display: flex; align-items: center">
                <span>บันทึกเอกสาร &nbsp;&nbsp;</span>
                <dx-switch
                    [(value)]="saveReport"
                    [disabled]="isLoading"
                ></dx-switch>
            </div>
            <div class="separator">|</div>
            <div>
                <dx-button
                    text="แสดงรายงานภาษีขาย"
                    [type]="isEditorsDirty ? 'normal' : 'default'"
                    [stylingMode]="
                        isLoading || isEditorsDirty ? 'outlined' : ''
                    "
                    [disabled]="isLoading || isEditorsDirty"
                    (onClick)="onShowReportClicked()"
                ></dx-button>
            </div>
        </div>
    </div>
    <dx-box
        direction="row"
        crossAlign="center"
        style="margin: 10px 0px"
        *ngIf="shareData"
    >
        <dxi-item [ratio]="2" style="padding-right: 10px">
            <dx-text-box
                width="100%"
                label="File Name"
                [value]="shareData.fileName"
                style="margin-bottom: 7px"
            >
            </dx-text-box>
        </dxi-item>
        <dxi-item [ratio]="8" style="padding-right: 10px">
            <dx-text-box
                width="100%"
                label="Shared URL"
                [value]="shareData.sharedUrl"
                style="margin-bottom: 7px"
            ></dx-text-box>
        </dxi-item>
        <dxi-item [ratio]="1" style="padding-right: 10px">
            <dx-button
                [disabled]="isLoading"
                stylingMode="contained"
                text="Copy"
                icon="copy"
                width="100%"
                (onClick)="copySharedUrlToClibboard()"
            ></dx-button>
        </dxi-item>
        <dxi-item [ratio]="1">
            <dx-button
                [disabled]="isLoading"
                stylingMode="outlined"
                text="Open"
                icon="link"
                width="100%"
                (onClick)="openSharedUrl()"
            ></dx-button>
        </dxi-item>
    </dx-box>
    <dx-data-grid
        #dataGrid
        keyExpr="_id"
        [showBorders]="false"
        [columnAutoWidth]="true"
        [allowColumnResizing]="true"
        [dataSource]="dataSource"
        [disabled]="isLoading"
        (onCellPrepared)="onCellPrepared($event)"
    >
        <dxi-column
            dataField="fileSharedLink"
            type="buttons"
            [width]="40"
            caption=""
        >
            <dxi-button
                icon="eyeopen"
                [onClick]="onOpenFileButtonClicked"
                [disabled]="isLoading"
            >
            </dxi-button>
        </dxi-column>
        <dxi-column
            dataField="orderInfo.date"
            caption="วันที่"
            [width]="100"
            alignment="center"
            [allowEditing]="false"
            [customizeText]="formatDateCell"
        ></dxi-column>
        <dxi-column
            dataField="orderInfo.invNum"
            caption="เลขที่ใบกำกับภาษี"
            alignment="center"
            [width]="130"
            [allowEditing]="false"
        ></dxi-column>
        <dxi-column dataField="customerInfo.name" caption="ลูกค้า"></dxi-column>
        <dxi-column
            dataField="customerInfo.branch"
            caption="สาขา"
            [width]="130"
        ></dxi-column>
        <dxi-column
            dataField="customerInfo.taxId"
            caption="เลขที่ผู้เสียภาษี"
            alignment="center"
            [width]="140"
        ></dxi-column>
        <dxi-column
            dataField="customerInfo.address"
            caption="ที่อยู่"
            [hidingPriority]="1"
        ></dxi-column>
        <dxi-column
            dataField="orderInfo.amtBeforeVat"
            caption="มูลค่าก่อนภาษี"
            dataType="number"
            [width]="100"
            cellTemplate="priceTemplate"
        ></dxi-column>
        <dxi-column
            dataField="orderInfo.totalVat"
            caption="ภาษีมูลค่าเพิ่ม"
            dataType="number"
            [width]="100"
            cellTemplate="priceTemplate"
        ></dxi-column>
        <dxi-column
            dataField="orderInfo.netPrice"
            caption="มูลค่ารวม"
            dataType="number"
            [width]="100"
            cellTemplate="priceTemplate"
        ></dxi-column>
        <div *dxTemplate="let cellData of 'priceTemplate'">
            {{ cellData.value ? (cellData.value | formatNumber : 2) : "" }}
        </div>
        <dxo-summary>
            <dxi-total-item
                column="orderInfo.invNum"
                summaryType="count"
                valueFormat="#,##0"
                displayFormat="ทั้งหมด {0} รายการ"
            >
            </dxi-total-item>
            <dxi-total-item
                column="orderInfo.amtBeforeVat"
                summaryType="sum"
                valueFormat="#,##0.00"
                displayFormat="{0}"
            >
            </dxi-total-item>
            <dxi-total-item
                column="orderInfo.totalVat"
                summaryType="sum"
                valueFormat="#,##0.00"
                displayFormat="{0}"
            >
            </dxi-total-item>
            <dxi-total-item
                column="orderInfo.netPrice"
                summaryType="sum"
                valueFormat="#,##0.00"
                displayFormat="{0}"
            >
            </dxi-total-item>
        </dxo-summary>
    </dx-data-grid>
    <dx-tooltip #sharedLinkButtonTooltip position="right">
        <div *dxTemplate="let data of 'content'">
            <div style="font-weight: bold; text-align: left">เปิดไฟล์</div>
            <div>
                {{ sharedLinkHover }}
            </div>
        </div>
    </dx-tooltip>
</div>
