/* eslint-disable @typescript-eslint/naming-convention */
export enum ProductStatus {
    All = 'all',
    Live = 'live',
    Inactive = 'inactive',
    Deleted = 'deleted',
    ImageMissing = 'image-missing',
    Pending = 'pending',
    Rejected = 'rejected',
    SoldOut = 'sold-out',
}
