import {
    Component,
    Output,
    Input,
    SimpleChanges,
    EventEmitter,
} from '@angular/core';

import { Subject, Subscription } from 'rxjs';

@Component({
    selector: 'app-counterparty-detail',
    templateUrl: './counterparty-detail.component.html',
    styleUrls: ['./counterparty-detail.component.scss'],
})
export class CounterpartyDetailComponent {
    @Input() isOpened = true;
    @Input() pinned = false;

    @Input() userId: number;

    @Output() isOpenedChange = new EventEmitter<boolean>();

    @Output() pinnedChange = new EventEmitter<boolean>();

    private pinEventSubject = new Subject<boolean>();

    isLoading = false;

    isEditing = false;

    isPinEnabled = true;

    userPanelSubscriptions: Subscription[] = [];

    constructor() {}

    dbdSearchButtonOptions = [
        {
            name: 'Search',
            options: {
                icon: 'find',
            },
        },
    ];

    ngOnInit(): void {
        this.calculatePin();
    }

    ngAfterViewChecked(): void {
        this.pinEventSubject.next(this.pinned);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { userId } = changes;

        if (userId?.currentValue) {
            this.loadUserById(userId.currentValue);
        }
    }

    ngOnDestroy(): void {
        this.userPanelSubscriptions.forEach((sub) => sub.unsubscribe());
    }

    loadUserById = (id: number) => {
        this.isLoading = false;
        this.isEditing = false;
    };

    onClosePanel = () => {
        this.isOpened = false;
        this.pinned = false;
        this.isOpenedChange.emit(this.isOpened);
    };

    onPinClick = () => {
        this.pinned = !this.pinned;
    };

    onSaveClick = ({ validationGroup }) => {
        if (!validationGroup.validate().isValid) return;
        this.isEditing = !this.isEditing;
    };

    calculatePin = () => {
        // this.isPinEnabled =
        //     this.screen.sizes['screen-large'] ||
        //     this.screen.sizes['screen-medium'];
        // if (this.pinned && !this.isPinEnabled) {
        //     this.pinned = false;
        // }
    };

    toggleEdit = () => {
        this.isEditing = !this.isEditing;
    };

    navigateToDetails = () => {
        // this.router.navigate(['/crm-contact-details']);
    };
}
