import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './shared/services';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DisplayDataComponent } from './pages/display-data/display-data.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { SecurePageComponent } from './pages/secure-page/secure-page.component';
import { InventoryListComponent } from './pages/inventory-list/inventory-list.component';
import { SalePlatformComponent } from './pages/sale/sale-platform/sale-platform.component';
import { SaleTimeComponent } from './pages/sale/sale-time/sale-time.component';
import { LazadaOrdersComponent } from './pages/orders/lazada-orders/lazada-orders.component';
import { ShopeeOrdersComponent } from './pages/orders/shopee-orders/shopee-orders.component';
import { QuickOrdersComponent } from './pages/orders/quick-orders/quick-orders.component';
import { ApiAuthSettingsComponent } from './pages/settings/api-auth-settings/api-auth-settings.component';
import { LazVdoAdderComponent } from './pages/laz-vdo-adder/laz-vdo-adder.component';
import { QuoteGeneratorComponent } from './pages/quote-generator/quote-generator.component';
import { ProductPerformanceComponent } from './pages/product-performance/product-performance.component';
import { CreateTaxInvoiceComponent } from './pages/tax-invoice/components/create-tax-invoice/create-tax-invoice.component';
import { ServiceMonComponent } from './pages/service-mon/service-mon.component';
import { ReorderingComponent } from './pages/reordering/reordering.component';
import { OutputTaxSummaryComponent } from './pages/tax-invoice/components/output-tax-summary/output-tax-summary.component';
import { EmbededComponent } from './pages/embeded/embeded.component';
import { CounterpartyListComponent } from './pages/counterparty/components/counterparty-list/counterparty-list.component';
import { ReceiptInfoFillerComponent } from './pages/embeded/pages/receipt-info-filler/receipt-info-filler.component';

const routes: Routes = [
    {
        path: 'login',
        canActivate: [AuthGuardService],
        component: LoginPageComponent,
    },
    {
        path: 'secure',
        canActivate: [AuthGuardService],
        component: SecurePageComponent,
        children: [
            {
                path: 'display-data',
                component: DisplayDataComponent,
            },
            {
                path: 'orders/quick',
                component: QuickOrdersComponent,
                data: { title: 'Lazada Orders' },
            },
            {
                path: 'orders/lazada',
                component: LazadaOrdersComponent,
                data: { title: 'Lazada Orders' },
            },
            {
                path: 'orders/shopee',
                component: ShopeeOrdersComponent,
                data: { title: 'Shopee Orders' },
            },
            {
                path: 'inventory/inventory-list',
                component: InventoryListComponent,
            },
            {
                path: 'profile',
                component: ProfileComponent,
            },
            {
                path: 'home',
                component: HomeComponent,
            },
            {
                path: 'sale/platform',
                component: SalePlatformComponent,
            },
            {
                path: 'sale/time',
                component: SaleTimeComponent,
            },
            {
                path: 'settings/api-auth',
                component: ApiAuthSettingsComponent,
            },
            {
                path: 'settings/svc-mon',
                component: ServiceMonComponent,
            },
            {
                path: 'utilities/laz-vdo-adder',
                component: LazVdoAdderComponent,
            },
            {
                path: 'utilities/ctp-list',
                component: CounterpartyListComponent,
            },
            {
                path: 'billings/quote-generator',
                component: QuoteGeneratorComponent,
            },
            {
                path: 'billings/tax-invoice',
                component: CreateTaxInvoiceComponent,
            },
            {
                path: 'utilities/reordering',
                component: ReorderingComponent,
            },
            {
                path: 'tax/output-tax/summary',
                component: OutputTaxSummaryComponent,
            },
            {
                path: 'analytic/product-performance',
                component: ProductPerformanceComponent,
            },
        ],
    },
    {
        path: 'embeded',
        component: EmbededComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: 'ctp-finder',
                component: ReceiptInfoFillerComponent,
            },
        ],
    },
    { path: '**', redirectTo: 'secure/home' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    providers: [AuthGuardService],
    exports: [RouterModule],
})
export class AppRoutingModule {}
