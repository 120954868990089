/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { ajax, AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import { environment } from '../../../../environments/environment';
import { from, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { set as _set } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class BaseRequester {
    constructor() {}

    protected externalPostRequest<T>(
        endpoint: string,
        requestPayload: T,
        responseType?: string
    ): Observable<AjaxResponse> {
        const ajaxRequest: AjaxRequest = {
            url: endpoint,
            body: JSON.stringify(requestPayload),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': environment.lsiEndpointApiKey,
            },
        };
        if (responseType) {
            ajaxRequest.responseType = responseType;
        }
        return ajax(ajaxRequest);
    }

    protected internalPostRequest<T>(
        endpoint: string,
        requestPayload: T,
        responseType?: string
    ): Observable<AjaxResponse> {
        return from(Auth.currentSession()).pipe(
            take(1),
            switchMap((session: CognitoUserSession) => {
                const ajaxRequest: AjaxRequest = {
                    url: endpoint,
                    body: JSON.stringify(requestPayload),
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': environment.lsiEndpointApiKey,
                    },
                };
                if (responseType) {
                    ajaxRequest.responseType = responseType;
                }
                _set(
                    ajaxRequest.headers,
                    'Authorization',
                    `Bearer ${session.getIdToken().getJwtToken()}`
                );
                return ajax(ajaxRequest);
            })
        );
    }
}

export interface UnifiedQueryRequest {
    operation: 'scan' | 'query';
    parameter: any;
}
