declare const gapi;

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class GoogleApiService {
    signInStatus = new BehaviorSubject(false);
    isReady = new BehaviorSubject(false);
    private auth: any;

    constructor() {}

    async initialize(): Promise<any> {
        await new Promise((resolve, reject) => {
            gapi.client.init({
                apiKey: environment.googleApiKey,
                discoveryDocs:
                    'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
                clientId: environment.googleApiClientId,
                scope: '',
            });
            this.auth.isSignedIn.listen((isSignedIn: boolean) => {
                this.signInStatus.next(isSignedIn);
            });
        });
    }

    async upload() {
        gapi.drive({ version: 'v3', auth: this.auth });
        const requestBody = {
            name: 'photo.jpg',
            fields: 'id',
        };
        const media = {
            mimeType: 'image/jpeg',
            body: null,
        };

        const file = await gapi.files.create({
            requestBody,
            media: media,
        });
        console.log('File Id:', file.data.id);
        return file.data.id;
    }

    signIn(): void {
        if (this.auth) {
            this.auth.signIn();
        }
    }

    signOut(): void {
        if (this.auth) {
            this.auth.disconnect();
        }
    }

    searchYoutube(query: string, maxResult = 5): Promise<any> {
        return gapi.client.youtube.search.list({
            part: ['snippet'],
            q: query,
            maxResults: maxResult,
        });
    }
}
