<dx-load-panel
    #loadPanel
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: '#quotePanel' }"
    [visible]="isQuoteExporting"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
>
</dx-load-panel>
<div #quotePanel class="main dx-card padding-content">
    <dx-box direction="row" crossAlign="center">
        <dxi-item [ratio]="4">
            <div style="text-align: start">
                <dx-button
                    [disabled]="isQuoteExporting || !messageSourceWindow"
                    stylingMode="outlined"
                    icon="download"
                    type="normal"
                    text="Load Lnwshop Data"
                    (onClick)="requestMessage()"
                ></dx-button>
            </div>
        </dxi-item>
        <dxi-item [ratio]="9" style="text-align: center">
            <span
                *ngIf="inputValues.quoteInfo.billingNote"
                style="font-size: x-large"
                >วางบิล / แจ้งหนี้
                <span *ngIf="inputValues.quoteInfo.isVat"> (VAT)</span></span
            >
            <span
                *ngIf="!inputValues.quoteInfo.billingNote"
                style="font-size: x-large"
                >เสนอราคา
                <span *ngIf="inputValues.quoteInfo.isVat"> (VAT)</span></span
            >
        </dxi-item>
        <dxi-item [ratio]="3" style="padding-right: 10px; text-align: right">
            <dx-button
                [disabled]="isQuoteExporting"
                stylingMode="outlined"
                text="Clear"
                icon="clear"
                type="danger"
                width="60%"
                (onClick)="clearForm()"
            ></dx-button
        ></dxi-item>
        <dxi-item [ratio]="3" style="padding-right: 10px">
            <dx-button
                [disabled]="isQuoteExporting"
                stylingMode="contained"
                text="Preview PDF"
                icon="pdffile"
                type="normal"
                width="100%"
                (onClick)="exportPdf()"
            ></dx-button>
        </dxi-item>
        <dxi-item [ratio]="3">
            <dx-button
                [disabled]="isQuoteExporting"
                stylingMode="contained"
                text="Save / Share"
                icon="save"
                type="default"
                width="100%"
                (onClick)="saveAndShare()"
            ></dx-button>
        </dxi-item>
    </dx-box>
    <dx-box
        direction="row"
        crossAlign="center"
        style="margin-top: 10px"
        *ngIf="shareData"
    >
        <dxi-item [ratio]="1" style="padding-right: 10px">
            <dx-text-box
                width="100%"
                label="File Name"
                [value]="shareData.fileName"
                style="margin-bottom: 7px"
            >
            </dx-text-box>
        </dxi-item>
        <dxi-item [ratio]="9" style="padding-right: 10px">
            <dx-text-box
                width="100%"
                label="Shared URL"
                [value]="shareData.sharedUrl"
                style="margin-bottom: 7px"
            ></dx-text-box>
        </dxi-item>
        <dxi-item [ratio]="1" style="padding-right: 10px">
            <dx-button
                [disabled]="isQuoteExporting"
                stylingMode="contained"
                text="Copy"
                icon="copy"
                width="100%"
                (onClick)="copySharedUrlToClibboard()"
            ></dx-button>
        </dxi-item>
        <dxi-item [ratio]="1">
            <dx-button
                [disabled]="isQuoteExporting"
                stylingMode="contained"
                text="Open"
                icon="link"
                width="100%"
                (onClick)="openSharedUrl()"
            ></dx-button>
        </dxi-item>
    </dx-box>

    <div class="section-header" style="margin-top: 10px">ข้อมูลผู้ซื้อ</div>
    <div class="flex-container-row">
        <div style="flex-basis: 40%">
            <dx-text-box
                [(value)]="inputValues.quoteInfo.quoteNo"
                [showClearButton]="true"
                labelMode="floating"
                [label]="
                    inputValues.quoteInfo.billingNote
                        ? 'เลขที่ใบวางบิล (I)'
                        : 'เลขที่ใบเสนอราคา (Q)'
                "
                (onValueChanged)="onInputsUpdated()"
            >
            </dx-text-box>
        </div>
        <div style="flex-basis: 20%">
            <dx-date-box
                label="วันที่"
                labelMode="floating"
                displayFormat="dd MMMM yyyy"
                dateSerializationFormat="yyyy-MM-dd"
                [showClearButton]="true"
                [(value)]="inputValues.quoteInfo.date"
                (onValueChanged)="onInputsUpdated()"
                [calendarOptions]="{ showTodayButton: true }"
            >
            </dx-date-box>
        </div>
        <div style="flex-basis: 20%">
            <dx-date-box
                label="ยืนราคาถึง"
                [label]="
                    inputValues.quoteInfo.billingNote
                        ? 'กำหนดชำระ'
                        : 'ยืนราคาถึง'
                "
                labelMode="floating"
                displayFormat="dd MMMM yyyy"
                dateSerializationFormat="yyyy-MM-dd"
                [showClearButton]="true"
                [(value)]="inputValues.quoteInfo.validUntil"
                (onValueChanged)="onInputsUpdated()"
                [calendarOptions]="{ showTodayButton: true }"
            >
            </dx-date-box>
        </div>
        <div style="flex-basis: 10%" class="checkbox-container">
            <dx-switch
                [(value)]="inputValues.quoteInfo.billingNote"
                (onValueChanged)="onInputsUpdated()"
            >
            </dx-switch>
            &nbsp;
            <span
                [ngClass]="{
                    'user-input': inputValues.quoteInfo.billingNote
                }"
                >วางบิล/แจ้งหนี้</span
            >
        </div>
        <div
            style="flex-basis: 10%; justify-content: flex-end"
            class="checkbox-container"
        >
            <dx-switch
                [(value)]="inputValues.quoteInfo.isVat"
                (onValueChanged)="onInputsUpdated()"
            >
            </dx-switch>
            &nbsp;
            <span
                style="margin-right: 10px"
                [ngClass]="{
                    'user-input': inputValues.quoteInfo.isVat
                }"
                >VAT</span
            >
        </div>
    </div>
    <div class="flex-container-row">
        <div style="flex-grow: 5">
            <dx-text-box
                [(value)]="inputValues.customerInfo.name"
                [showClearButton]="true"
                labelMode="floating"
                label="ชื่อ"
                (onValueChanged)="onInputsUpdated()"
            >
            </dx-text-box>
        </div>
        <div style="flex-grow: 1">
            <dx-text-box
                [(value)]="inputValues.customerInfo.taxId"
                [showClearButton]="true"
                labelMode="floating"
                label="เลขประจำตัวผู้เสียภาษี"
                (onValueChanged)="onInputsUpdated()"
            >
            </dx-text-box>
        </div>
        <div style="flex-grow: 1">
            <dx-select-box
                [(value)]="inputValues.customerInfo.branch"
                [items]="branchList"
                placeholder="เลือกสาขา"
                [showClearButton]="true"
                label="สาขา"
                labelMode="floating"
                [acceptCustomValue]="true"
                (onValueChanged)="onInputsUpdated()"
            ></dx-select-box>
        </div>
    </div>

    <div class="section-header">
        <span>ที่อยู่</span>
    </div>

    <div class="checkbox-container" style="margin: 10px; margin-left: 20px">
        <dx-switch
            [(value)]="inputValues.customerInfo.useCustomAddress"
            (onValueChanged)="onInputsUpdated()"
        >
        </dx-switch>
        &nbsp;
        <span
            [ngClass]="{
                'user-input': inputValues.customerInfo.useCustomAddress
            }"
            >กรอกที่อยู่เอง</span
        >
    </div>

    <div
        class="flex-container-row"
        style="align-items: center"
        *ngIf="!inputValues.customerInfo.useCustomAddress"
    >
        <div>
            <dx-autocomplete
                placeholder="พิมพ์รหัสไปรษณีย์ ..."
                [showClearButton]="true"
                [(value)]="inputValues.customerInfo.postCode"
                labelMode="floating"
                label="รหัสไปรษณีย์"
                [dataSource]="postCodeSuggestions"
                [minSearchLength]="2"
                [showDropDownButton]="true"
                searchMode="startswith"
                (onValueChanged)="onInputsUpdated()"
            >
            </dx-autocomplete>
        </div>
        →
        <div>
            <dx-select-box
                [(value)]="inputValues.customerInfo.province"
                placeholder="เลือกจังหวัด"
                [showClearButton]="true"
                label="จังหวัด"
                labelMode="floating"
                [searchEnabled]="true"
                [acceptCustomValue]="true"
                [items]="provinceSuggestions"
                (onValueChanged)="onInputsUpdated()"
            ></dx-select-box>
        </div>
        →
        <div>
            <dx-select-box
                [(value)]="inputValues.customerInfo.district"
                placeholder="เลือกเขต/อำเภอ"
                [showClearButton]="true"
                label="เขต/อำเภอ"
                labelMode="floating"
                [searchEnabled]="true"
                [acceptCustomValue]="true"
                [items]="districtSuggestions"
                (onValueChanged)="onInputsUpdated()"
            ></dx-select-box>
        </div>
        →
        <div>
            <dx-select-box
                [(value)]="inputValues.customerInfo.subDistrict"
                placeholder="เลือกแขวง/ตำบล"
                [showClearButton]="true"
                label="แขวง/ตำบล"
                labelMode="floating"
                [searchEnabled]="true"
                [acceptCustomValue]="true"
                [items]="subDistrictSuggestions"
                (onValueChanged)="onInputsUpdated()"
            ></dx-select-box>
        </div>
    </div>
    <div
        class="flex-container-row"
        *ngIf="!inputValues.customerInfo.useCustomAddress"
    >
        <div>
            <dx-text-box
                [(value)]="inputValues.customerInfo.address1"
                [showClearButton]="true"
                labelMode="floating"
                label="บ้านเลขที่ ซอย ถนน"
                (onValueChanged)="onInputsUpdated()"
            >
            </dx-text-box>
        </div>
    </div>
    <div
        class="flex-container-row"
        *ngIf="!inputValues.customerInfo.useCustomAddress"
    >
        <div>
            <dx-text-area
                [(value)]="inputValues.customerInfo.fullAddress"
                [height]="60"
                labelMode="floating"
                label="ที่อยู่เต็ม"
                (onValueChanged)="onInputsUpdated()"
            >
            </dx-text-area>
        </div>
    </div>

    <div
        class="flex-container-row"
        *ngIf="inputValues.customerInfo.useCustomAddress"
    >
        <div>
            <dx-text-area
                [(value)]="inputValues.customerInfo.customAddress"
                [height]="90"
                labelMode="floating"
                placeholder="กรอกที่อยู่กำหนดเอง"
                label="ที่อยู่กำหนดเอง"
                (onValueChanged)="onInputsUpdated()"
            >
            </dx-text-area>
        </div>
    </div>

    <div *ngIf="!inputValues.quoteInfo.billingNote" class="section-header">
        การจัดส่งสินค้า
    </div>
    <div *ngIf="!inputValues.quoteInfo.billingNote" class="flex-container-row">
        <div>
            <dx-select-box
                [(value)]="inputValues.quoteInfo.shippingCarrier"
                [items]="deliveryCourierList"
                placeholder="เลือกขนส่ง"
                labelMode="floating"
                [showClearButton]="true"
                label="ขนส่ง"
                [searchEnabled]="true"
                [acceptCustomValue]="true"
                (onValueChanged)="onInputsUpdated()"
            ></dx-select-box>
        </div>
        <div>
            <dx-select-box
                [(value)]="inputValues.quoteInfo.shippingTerms"
                [items]="deliveryTermList"
                placeholder="การจัดส่งสินค้า"
                labelMode="floating"
                [showClearButton]="true"
                label="การจัดส่งสินค้า"
                [searchEnabled]="true"
                [acceptCustomValue]="true"
                (onValueChanged)="onInputsUpdated()"
            ></dx-select-box>
        </div>
    </div>

    <div class="section-header">อื่นๆ</div>
    <div class="flex-container-row">
        <div>
            <dx-button
                stylingMode="contained"
                text="+ ฟรีค่าจัดส่ง EMS"
                type="normal"
                (onClick)="onRemarkAutoFillButtonClicked($event)"
            ></dx-button>
            <dx-button
                style="margin-left: 5px"
                stylingMode="contained"
                text="+ ส่วนลดพิเศษ 5%"
                type="normal"
                (onClick)="onRemarkAutoFillButtonClicked($event)"
            ></dx-button>
        </div>
    </div>
    <div class="flex-container-row">
        <div>
            <dx-text-area
                [(value)]="inputValues.quoteInfo.remark"
                [height]="60"
                labelMode="floating"
                label="หมายเหตุ"
                (onValueChanged)="onInputsUpdated()"
            >
            </dx-text-area>
        </div>
    </div>
    <div class="flex-container-row" style="margin-top: 10px">
        <div class="checkbox-container">
            <dx-switch
                [(value)]="inputValues.quoteInfo.hidePaymentMethod"
                (onValueChanged)="onInputsUpdated()"
            >
            </dx-switch>
            &nbsp;<span
                [ngClass]="{
                    'user-input': inputValues.quoteInfo.hidePaymentMethod
                }"
                >ซ่อนบัญชีธนาคาร</span
            >
        </div>
        <div class="checkbox-container">
            <dx-switch
                [(value)]="inputValues.quoteInfo.hideSignatureFields"
                (onValueChanged)="onInputsUpdated()"
            >
            </dx-switch>
            &nbsp;<span
                [ngClass]="{
                    'user-input': inputValues.quoteInfo.hideSignatureFields
                }"
                >ซ่อนช่องลายเซ็น</span
            >
        </div>
        <div
            class="checkbox-container"
            [style.visibility]="
                inputValues.quoteInfo.hideSignatureFields ? 'hidden' : 'visible'
            "
        >
            <dx-switch
                [(value)]="inputValues.quoteInfo.isSigned"
                (onValueChanged)="onInputsUpdated()"
                [style.visibility]="
                    inputValues.quoteInfo.hideSignatureFields
                        ? 'hidden'
                        : 'visible'
                "
            >
            </dx-switch>
            &nbsp;<span
                [style.visibility]="
                    inputValues.quoteInfo.hideSignatureFields
                        ? 'hidden'
                        : 'visible'
                "
                [ngClass]="{
                    'user-input': inputValues.quoteInfo.isSigned
                }"
                >เซ็นเอกสาร</span
            >
        </div>
        <div>
            <dx-text-box
                [(value)]="inputValues.quoteInfo.customTitle"
                [showClearButton]="true"
                labelMode="floating"
                label="เปลี่ยนหัวเอกสาร"
                (onValueChanged)="onInputsUpdated()"
            >
            </dx-text-box>
        </div>
    </div>
    <div class="section-header">รายการสินค้า</div>
    <div class="flex-container-row" style="margin-bottom: 10px">
        <dx-data-grid
            #dataGrid
            class="dx-card"
            style="padding-top: 10px"
            keyExpr="itemNo"
            [showBorders]="false"
            [columnAutoWidth]="false"
            [allowColumnResizing]="true"
            [dataSource]="inputValues.productItems"
            (onSaved)="onSaved()"
        >
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-editing
                mode="batch"
                [allowUpdating]="true"
                [allowDeleting]="true"
                [allowAdding]="true"
                [selectTextOnEditStart]="true"
                newRowPosition="last"
            >
            </dxo-editing>
            <dxo-row-dragging
                [allowReordering]="true"
                [onReorder]="onReorder"
            ></dxo-row-dragging>
            <dxi-column
                dataField="itemNo"
                caption="No."
                dataType="number"
                [width]="80"
                [allowEditing]="false"
            ></dxi-column>
            <dxi-column
                dataField="description"
                caption="Description"
                [minWidth]="120"
            ></dxi-column>
            <dxi-column
                dataField="unitPrice"
                caption="Unit Price"
                dataType="number"
                [width]="120"
                cellTemplate="priceTemplate"
            ></dxi-column>
            <dxi-column
                dataField="quantity"
                caption="Quantity"
                dataType="number"
                [width]="120"
                cellTemplate="numTemplate"
            ></dxi-column>
            <dxi-column
                caption="Amount"
                dataType="number"
                [width]="120"
                [allowEditing]="false"
                [calculateCellValue]="calculateProductsAmount"
                cellTemplate="priceTemplate"
            ></dxi-column>
            <div *dxTemplate="let cellData of 'priceTemplate'">
                {{ cellData.value ? (cellData.value | formatNumber : 2) : "" }}
            </div>
            <div *dxTemplate="let cellData of 'numTemplate'">
                {{ cellData.value ? (cellData.value | formatNumber : 0) : "" }}
            </div>
        </dx-data-grid>
    </div>

    <div class="flex-container-row summary-line">
        <div class="price-container">
            <span class="label">ราคาสินค้า</span>
            <span>
                &nbsp;&nbsp;
                {{ inputValues.quoteInfo.totalProductPrice | formatNumber : 2 }}
                &nbsp;&nbsp;
            </span>
        </div>
        <span style="font-size: xx-large">-</span>
        <div>
            <dx-number-box
                class="align-text-right"
                [(value)]="inputValues.quoteInfo.discount"
                [showSpinButtons]="false"
                [showClearButton]="true"
                [format]="{
                    type: 'fixedPoint',
                    precision: 2
                }"
                label="ส่วนลด"
                labelMode="floating"
                (onValueChanged)="onInputsUpdated()"
            ></dx-number-box>
        </div>
        <span style="font-size: x-large">+</span>
        <div>
            <dx-number-box
                class="align-text-right"
                [(value)]="inputValues.quoteInfo.shippingCost"
                [showSpinButtons]="false"
                [showClearButton]="true"
                [format]="{
                    type: 'fixedPoint',
                    precision: 2
                }"
                label="ค่าจัดส่ง"
                labelMode="floating"
                (onValueChanged)="onInputsUpdated()"
            ></dx-number-box>
        </div>
        <ng-container *ngIf="inputValues.quoteInfo.isVat">
            <span style="font-size: x-large">+</span>
            <div>
                <dx-number-box
                    class="align-text-right"
                    [(value)]="inputValues.quoteInfo.vatAmount"
                    [showSpinButtons]="false"
                    [showClearButton]="true"
                    [format]="{
                        type: 'fixedPoint',
                        precision: 2
                    }"
                    label="VAT"
                    labelMode="floating"
                    (onValueChanged)="onInputsUpdated()"
                ></dx-number-box>
            </div>
        </ng-container>
        <span style="font-size: x-large">=</span>
        <div id="net-price-container" class="price-container">
            <span class="label"> ราคาสุทธิ </span>
            <span>
                &nbsp;&nbsp;
                {{ inputValues.quoteInfo.netPrice | formatNumber : 2 }}
                &nbsp;&nbsp;
            </span>
        </div>
    </div>
</div>
<!-- <div>
    <pre>{{ testData }}</pre>
</div>
<div style="margin-top: 10px">
    <pre>{{ requestData }}</pre>
</div> -->
