<div #mainDiv style="margin: 15px">
    <div
        style="
            display: flex;
            justify-content: space-between;
            gap: 10px;
            height: 275px;
        "
    >
        <div
            style="
                flex-basis: 50%;
                display: flex;
                justify-content: space-between;
                height: 50%;
            "
        >
            <div style="flex-basis: 70%">
                <div style="display: flex; justify-content: space-between">
                    <div>
                        <dx-button
                            stylingMode="contained"
                            text="Load"
                            type="default"
                            icon="download"
                            [width]="120"
                            (onClick)="onClick()"
                            [disabled]="
                                isDataLoading && selectedRangeOptions.length > 0
                            "
                        >
                        </dx-button>
                    </div>
                    <dx-button-group
                        [items]="rangeOptions"
                        keyExpr="id"
                        stylingMode="outlined"
                        selectionMode="single"
                        [(selectedItems)]="selectedRangeOptions"
                        [focusStateEnabled]="false"
                        [disabled]="isDataLoading"
                    ></dx-button-group>
                </div>

                <div
                    style="
                        display: flex;
                        margin-top: 10px;
                        flex-direction: row-reverse;
                    "
                >
                    <dx-number-box
                        [(value)]="performingCutOffValue"
                        [min]="100"
                        [max]="2000"
                        [step]="100"
                        [showSpinButtons]="true"
                        [disabled]="isDataLoading"
                        width="150px"
                    ></dx-number-box>
                    <div
                        style="
                            margin-top: auto;
                            margin-bottom: auto;
                            margin-right: 10px;
                            font-weight: bold;
                        "
                    >
                        Performing Cutoff :
                    </div>
                </div>
                <div
                    style="
                        display: flex;
                        justify-content: space-between;
                        margin-top: 30px;
                    "
                >
                    <div>
                        <div class="field-stat-label">Active/Excl. Count</div>
                        <div class="field-stat-value">
                            {{
                                performingProductSource.length +
                                    nonPerformingProductSource.length
                            }}
                            / {{ excludedProductSource.length }}
                        </div>
                    </div>
                    <div>
                        <div class="field-stat-label">Total Avg. Rev.</div>
                        <div class="field-stat-value">
                            {{ totalAvgMonthly | formatNumber: 2 }}
                        </div>
                    </div>
                    <div>
                        <div class="field-stat-label">Product Avg. Rev.</div>
                        <div class="field-stat-value">
                            {{ productAvgMonthly | formatNumber: 2 }}
                        </div>
                    </div>
                    <div>
                        <div class="field-stat-label">P/N Ratio</div>
                        <div class="field-stat-value">
                            {{ performingProductSource.length }} /
                            {{ nonPerformingProductSource.length }} ({{
                                performingProductSource.length /
                                    nonPerformingProductSource.length
                                    | formatNumber: 2
                            }})
                        </div>
                    </div>
                </div>
            </div>
            <div style="flex-basis: 30%">
                <dx-pie-chart
                    id="pieChart"
                    type="doughnut"
                    palette="Soft Pastel"
                    [dataSource]="pieChartData"
                    style="margin-left: 10px"
                >
                    <dxi-series argumentField="region">
                        <dxo-label
                            [visible]="true"
                            position="columns"
                            [customizeText]="customizePieChartLabel"
                        >
                            <dxo-connector [visible]="true"></dxo-connector>
                        </dxo-label>
                    </dxi-series>
                    <dxo-legend
                        [margin]="0"
                        horizontalAlignment="center"
                        verticalAlignment="bottom"
                    ></dxo-legend>
                    <!-- <dxo-tooltip [enabled]="true"> </dxo-tooltip> -->
                </dx-pie-chart>
            </div>
        </div>
        <div style="flex-basis: 50%">
            <div class="widget-title">Performance Distribution</div>
            <dx-chart
                #distChart
                id="distChart"
                [dataSource]="distributionChartData"
            >
                <dxo-legend [visible]="false"></dxo-legend>
                <dxi-series
                    argumentField="range"
                    valueField="value"
                    name="Count"
                    type="bar"
                    color="#ffaa66"
                >
                </dxi-series>
                <dxo-tooltip [enabled]="true"> </dxo-tooltip>
            </dx-chart>
        </div>
    </div>

    <div
        style="
            display: flex;
            justify-content: space-between;
            gap: 10px;
            margin-top: 10px;
        "
    >
        <div>
            <div class="widget-title">
                Performing ({{ performingProductSource.length }})
            </div>
            <dx-data-grid
                [showBorders]="true"
                [dataSource]="performingProductSource"
                [hoverStateEnabled]="true"
                keyExpr="sku"
            >
                <dxi-column
                    dataField="sku"
                    caption="SKU"
                    [width]="120"
                    alignment="left"
                ></dxi-column>
                <dxi-column dataField="name" caption="Name"></dxi-column>
                <dxi-column
                    dataField="remain"
                    caption="Stock"
                    [width]="80"
                    dataType="number"
                    cellTemplate="stockTemplate"
                ></dxi-column>
                <dxi-column
                    dataField="price"
                    caption="Price"
                    [width]="100"
                    dataType="number"
                    cellTemplate="priceTemplate"
                ></dxi-column>
                <dxi-column
                    dataField="avgSale"
                    caption="Avg Monthly"
                    [width]="120"
                    dataType="number"
                    cellTemplate="priceTemplate"
                    sortOrder="desc"
                ></dxi-column>
                <div *dxTemplate="let cellData of 'priceTemplate'">
                    {{ cellData.value | formatNumber: 2 }}
                </div>
                <div *dxTemplate="let cellData of 'stockTemplate'">
                    {{ cellData.value | formatNumber: 0 }}
                </div>
            </dx-data-grid>
        </div>
        <div>
            <div class="widget-title">
                Non-performing ({{ nonPerformingProductSource.length }})
            </div>
            <dx-data-grid
                [showBorders]="true"
                [dataSource]="nonPerformingProductSource"
                [hoverStateEnabled]="true"
                keyExpr="sku"
            >
                <dxi-column
                    dataField="sku"
                    caption="SKU"
                    [width]="120"
                    alignment="left"
                ></dxi-column>
                <dxi-column dataField="name" caption="Name"></dxi-column>
                <dxi-column
                    dataField="remain"
                    caption="Stock"
                    [width]="80"
                    dataType="number"
                    cellTemplate="stockTemplate"
                ></dxi-column>
                <dxi-column
                    dataField="price"
                    caption="Price"
                    [width]="100"
                    dataType="number"
                    cellTemplate="priceTemplate"
                ></dxi-column>
                <dxi-column
                    dataField="avgSale"
                    caption="Avg Monthly"
                    [width]="120"
                    dataType="number"
                    cellTemplate="priceTemplate"
                    sortOrder="desc"
                ></dxi-column>
                <div *dxTemplate="let cellData of 'priceTemplate'">
                    {{ cellData.value | formatNumber: 2 }}
                </div>
                <div *dxTemplate="let cellData of 'stockTemplate'">
                    {{ cellData.value | formatNumber: 0 }}
                </div>
            </dx-data-grid>
        </div>
    </div>
    <div
        style="
            display: flex;
            justify-content: space-between;
            gap: 10px;
            margin-top: 10px;
        "
    >
        <div>
            <div class="widget-title">
                Excluded ({{ excludedProductSource.length }})
            </div>
            <dx-data-grid
                [showBorders]="true"
                [dataSource]="excludedProductSource"
            >
                <dxi-column
                    dataField="product"
                    caption="Product"
                    alignment="left"
                ></dxi-column>
                <dxi-column
                    dataField="reason"
                    caption="Exclusion Reason"
                    [width]="300"
                ></dxi-column>
            </dx-data-grid>
        </div>
    </div>
</div>
<dx-load-panel
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: '#mainDiv' }"
    [(visible)]="isDataLoading"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
>
</dx-load-panel>
