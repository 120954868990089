import { Component } from '@angular/core';
import { AppInfoService } from '../../shared/services';

@Component({
    selector: 'app-secure-page',
    templateUrl: './secure-page.component.html',
    styleUrls: ['./secure-page.component.scss'],
})
export class SecurePageComponent {
    constructor(public appInfo: AppInfoService) {}
}
