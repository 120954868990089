export interface QuoteInput {
    quoteInfo: QuoteInfo;
    customerInfo: CustomerInfo;
    productItems: ProductInfo[];
}

interface QuoteInfo {
    quoteNo: string;
    date: string;
    validUntil: string;
    shippingCarrier: string;
    shippingCost: number;
    shippingTerms: string;
    remark: string;
    discount: number;
    totalProductPrice: number;
    netPrice: number;
    isVat: boolean;
    vatAmount: number;
    hidePaymentMethod: boolean;
    hideSignatureFields: boolean;
    isSigned: boolean;
    customTitle: string;
    billingNote: boolean;
}

interface CustomerInfo {
    name: string;
    taxId: string;
    branch: string;
    postCode: string;
    province: string;
    district: string;
    subDistrict: string;
    address1: string;
    fullAddress: string;
    useCustomAddress: boolean;
    customAddress: string;
}

export interface ProductInfo {
    itemNo: number;
    description: string;
    unitPrice: number;
    quantity: number;
    amount: number;
}

const inputMomentFormat = 'YYYY-MM-DD';

const initialQuoteInput: QuoteInput = {
    quoteInfo: {
        quoteNo: '',
        date: '',
        validUntil: '',
        shippingCarrier: '',
        shippingCost: null,
        shippingTerms: '',
        remark: '',
        discount: null,
        totalProductPrice: null,
        netPrice: null,
        isVat: false,
        vatAmount: null,
        hidePaymentMethod: false,
        hideSignatureFields: false,
        isSigned: false,
        customTitle: '',
        billingNote: false,
    },
    customerInfo: {
        name: '',
        taxId: '',
        branch: '',
        postCode: '',
        province: '',
        district: '',
        subDistrict: '',
        address1: '',
        fullAddress: '',
        useCustomAddress: false,
        customAddress: '',
    },
    productItems: [],
};
import { cloneDeep } from 'lodash';
const getInitialQuoteInput = (): QuoteInput => {
    return cloneDeep(initialQuoteInput);
};
export { inputMomentFormat, getInitialQuoteInput };
